const value: string[] = [
    'State',
    'County',
    'MSA',
    'CSA',
    'Urban Area',
    'Congressional District',
    'Media Market',
    'Hospital Referral Region',
]
export default value
