export default [
    '2016 Presidential Election',
    '2016-2020 Swing',
    '2020 Presidential Election',
    'Arthritis %',
    'Binge drinking among adults %',
    'High blood pressure %',
    'Cancer (excluding skin cancer) %',
    'Current asthma %',
    'Coronary heart disease %',
    'Attended doctor in last year %',
    'Cholesterol screening in last year %',
    'Cognitive disability %',
    'COPD %',
    'Smoking %',
    'Attended dentist in last year %',
    'Diagnosed diabetes %',
    'Disability %',
    'Fair or poor self-rated health status %',
    'Hearing disability %',
    'Independent living disability %',
    'Chronic kidney disease %',
    'No leisure-time physical activity %',
    'Mobility disability %',
    'Obesity %',
    'Physical health not good for two weeks in last year %',
    'Self-care disability %',
    'Sleeping less than 7 hours %',
    'Stroke %',
    'Vision disability %',
    'PW Density (r=250m)',
    'PW Density (r=250m) (2000)',
    'PW Density (r=250m) (2010)',
    'PW Density (r=250m) Change (2000-2020)',
    'PW Density (r=250m) Change (2010-2020)',
    'PW Density (r=500m)',
    'PW Density (r=500m) (2000)',
    'PW Density (r=500m) (2010)',
    'PW Density (r=500m) Change (2000-2020)',
    'PW Density (r=500m) Change (2010-2020)',
    'PW Density (r=1km)',
    'PW Density (r=1km) (2000)',
    'PW Density (r=1km) (2010)',
    'PW Density (r=1km) Change (2000-2020)',
    'PW Density (r=1km) Change (2010-2020)',
    'PW Density (r=2km)',
    'PW Density (r=2km) (2000)',
    'PW Density (r=2km) (2010)',
    'PW Density (r=2km) Change (2000-2020)',
    'PW Density (r=2km) Change (2010-2020)',
    'PW Density (r=4km)',
    'PW Density (r=4km) (2000)',
    'PW Density (r=4km) (2010)',
    'PW Density (r=4km) Change (2000-2020)',
    'PW Density (r=4km) Change (2010-2020)',
    'Area',
    'Asian %',
    'Asian % (2000)',
    'Asian % (2010)',
    'Born outside US %',
    'Born in us outside state %',
    'Born in state of residence %',
    'Black %',
    'Black % (2000)',
    'Black % (2010)',
    'Citizen by Birth %',
    'Citizen by Naturalization %',
    'Non-citizen %',
    'Compactness',
    'High temperature Above 90\u00b0F %',
    'High temperature Below 40\u00b0F %',
    'High temperature Between 40 and 90\u00b0F %',
    'Dry days (dewpt < 50\u00b0F) %',
    'Non-humid days (50\u00b0F < dewpt < 70\u00b0F) %',
    'Humid days (dewpt > 70\u00b0F) %',
    'PW Density (250m) [StatCan]',
    'PW Density (500m) [StatCan]',
    'PW Density (1km) [StatCan]',
    'PW Density (2km) [StatCan]',
    'PW Density (4km) [StatCan]',
    'Undergrad Business %',
    'Undergrad Humanities %',
    'Undergrad STEM %',
    'Grad %',
    'Graduate degree [25-64] %',
    'High School %',
    'High school diploma [25-64] %',
    'Undergrad %',
    'Bachelor\'s degree [25-64] %',
    '% of women with graduate education - % of men with graduate education',
    '% of women with high school education - % of men with high school education',
    '% of women with undergraduate education - % of men with undergraduate education',
    'Boomer %',
    'Boomer % [StatCan]',
    'Gen Alpha %',
    'Gen Alpha % [StatCan]',
    'Gen X %',
    'Gen X % [StatCan]',
    'Gen Z %',
    'Gen Z % [StatCan]',
    'Millennial %',
    'Millennial % [StatCan]',
    'Silent %',
    'Silent % [StatCan]',
    'AW Density [GHS-POP]',
    'Population [GHS-POP]',
    'PW Density (r=1km) [GHS-POP]',
    'PW Density (r=2km) [GHS-POP]',
    'PW Density (r=4km) [GHS-POP]',
    'PW Mean Elevation',
    'PW Mean Hilliness (Grade)',
    'Hawaiian / PI %',
    'Hawaiian / PI % (2000)',
    'Hawaiian / PI % (2010)',
    'Bottled, tank, or LP gas heating %',
    'Electricity heating %',
    'Fuel oil, kerosene, etc. heating %',
    'No heating %',
    'Other fuel heating %',
    'Utility gas heating %',
    'Hispanic %',
    'Hispanic % (2000)',
    'Hispanic % (2010)',
    'Racial Homogeneity (2000) %',
    'Racial Homogeneity (2010) %',
    'Racial Homogeneity %',
    'Racial Homogeneity Change (2000-2020) %',
    'Racial Homogeneity Change (2010-2020) %',
    'Mean sunny hours',
    'Household income C$50k - C$100k %',
    'Household Income $50k - $100k %',
    'Household income > C$100k %',
    'Household Income > $100k %',
    'Household income < C$50k %',
    'Household Income < $50k %',
    'Housing Units per Adult',
    'Housing Units per Adult (2000)',
    'Housing Units per Adult (2010)',
    'Individual income C$50k - C$100k %',
    'Individual Income $50k - $100k %',
    'Individual income > C$100k %',
    'Individual Income > $100k %',
    'Individual income < C$50k %',
    'Individual Income < $50k %',
    'Accommodation and food services %',
    'Accommodation and food services % [StatCan]',
    'Administrative and support and waste management services %',
    'Administrative and support and waste management services % [StatCan]',
    'Agriculture, forestry, fishing and hunting %',
    'Agriculture, forestry, fishing and hunting % [StatCan]',
    'Arts, entertainment, and recreation %',
    'Arts, entertainment, and recreation % [StatCan]',
    'Construction %',
    'Construction % [StatCan]',
    'Educational services %',
    'Educational services % [StatCan]',
    'Finance and insurance %',
    'Finance and insurance % [StatCan]',
    'Health care and social assistance %',
    'Health care and social assistance % [StatCan]',
    'Information %',
    'Information % [StatCan]',
    'Management of companies and enterprises %',
    'Management of companies and enterprises % [StatCan]',
    'Manufacturing %',
    'Manufacturing % [StatCan]',
    'Mining, quarrying, and oil and gas extraction %',
    'Mining, quarrying, and oil and gas extraction % [StatCan]',
    'Other services, except public administration %',
    'Other services, except public administration % [StatCan]',
    'Professional, scientific, and technical services %',
    'Professional, scientific, and technical services % [StatCan]',
    'Public administration %',
    'Public administration % [StatCan]',
    'Real estate and rental and leasing %',
    'Real estate and rental and leasing % [StatCan]',
    'Retail trade %',
    'Retail trade % [StatCan]',
    'Transportation and warehousing %',
    'Transportation and warehousing % [StatCan]',
    'Utilities %',
    'Utilities % [StatCan]',
    'Wholesale trade %',
    'Wholesale trade % [StatCan]',
    'Public Insurance %',
    'Uninsured %',
    'Private Insurance %',
    'No internet access %',
    'Only English at Home %',
    'Other at Home %',
    'Spanish at Home %',
    'Within 10mi of a grocery store %',
    'Within 1mi of a grocery store %',
    'Within 20mi of a grocery store %',
    'Within 0.5mi of a grocery store %',
    'LICO-AT %',
    'Divorced %',
    'Divorced % [StatCan]',
    'Married (not divorced) %',
    'Married (not divorced) % [StatCan]',
    'Never Married %',
    'Never Married % [StatCan]',
    'Mean distance to nearest Active Superfund Site',
    'Mean distance to nearest Airport',
    'Mean distance to nearest Hospital',
    'Mean distance to nearest Public School',
    'Mean high dewpt',
    'Mean high heat index',
    'Mean high temp',
    'Mean high temperature in fall',
    'Mean high temperature in spring',
    'Mean high temperature in summer',
    'Mean high temperature in winter',
    'Native %',
    'Native % (2000)',
    'Native % (2010)',
    'Architecture and engineering occupations %',
    'Arts, design, entertainment, sports, and media occupations %',
    'Building and grounds cleaning and maintenance occupations %',
    'Business and financial operations occupations %',
    'Community and social service occupations %',
    'Computer and mathematical occupations %',
    'Construction and extraction occupations %',
    'Educational instruction, and library occupations %',
    'Farming, fishing, and forestry occupations %',
    'Firefighting and prevention, and other protective service workers including supervisors %',
    'Food preparation and serving related occupations %',
    'Health diagnosing and treating practitioners and other technical occupations %',
    'Health technologists and technicians %',
    'Healthcare support occupations %',
    'Installation, maintenance, and repair occupations %',
    'Law enforcement workers including supervisors %',
    'Legal occupations %',
    'Life, physical, and social science occupations %',
    'Management occupations %',
    'Material moving occupations %',
    'Office and administrative support occupations %',
    'Personal care and service occupations %',
    'Production occupations %',
    'Sales and related occupations %',
    'Transportation occupations %',
    'Other / Mixed %',
    'Other / Mixed % (2000)',
    'Other / Mixed % (2010)',
    'PW Mean % of parkland within 1km',
    'PW Mean PM2.5 Pollution',
    'Population',
    'Population (2000)',
    'Population (2010)',
    'Population [StatCan]',
    'Population Change (2000-2020)',
    'Population Change (2010-2020)',
    'Poverty %',
    'Rainfall',
    '1BR Rent $750 - $1500 %',
    '1BR Rent > $1500 %',
    '1BR Rent < $750 %',
    '2BR Rent $750 - $1500 %',
    '2BR Rent > $1500 %',
    '2BR Rent < $750 %',
    'Rent/Income 20%-40%',
    'Rent/Income > 40%',
    'Rent/Income < 20%',
    'Renter %',
    'AW Density',
    'AW Density (2000)',
    'AW Density (2010)',
    'Area-weighted Density [StatCan]',
    'Mean Local Segregation (2000) %',
    'Mean Local Segregation (2010) %',
    'Mean Local Segregation %',
    'Mean Local Segregation Change (2000-2020) %',
    'Mean Local Segregation Change (2010-2020) %',
    'Segregation (2000) %',
    'Segregation (2010) %',
    'Segregation %',
    'Segregation Change (2000-2020) %',
    'Segregation Change (2010-2020) %',
    'Snowfall [rain-equivalent]',
    'Living With Parents %',
    'Cohabiting With Partner (Gay) %',
    'Cohabiting With Partner (Straight) %',
    'Other Living Situation %',
    'Not Cohabiting With Partner %',
    'Total Traffic Fatalities In Last Decade',
    'Traffic Fatalities Per Capita Per Year',
    'Total Pedestrian/Cyclist Fatalities In Last Decade',
    'Pedestrian/Cyclist Fatalities Per Capita Per Year',
    'Commute Time 15 - 29 min %',
    'Commute Time 15 - 29 min % [StatCan]',
    'Commute Time 30 - 59 min %',
    'Commute Time 30 - 59 min % [StatCan]',
    'Commute Time > 60 min %',
    'Commute Time > 60 min % [StatCan]',
    'Commute Time < 15 min %',
    'Commute Time < 15 min % [StatCan]',
    'Commute Bike %',
    'Commute Car %',
    'Commute Transit %',
    'Commute Walk %',
    'Commute Work From Home %',
    'Vacancy %',
    'Vacancy % (2000)',
    'Vacancy % (2010)',
    'Households With 1+ Vehicles %',
    'Households With 2+ Vehicles %',
    'Households With no Vehicle %',
    'White %',
    'White % (2000)',
    'White % (2010)',
    'High windspeed (>10mph) days %',
    'Within 10km of Active Superfund Site %',
    'Within 30km of Airport %',
    'Within 10km of Hospital %',
    'Within 2km of Public School %',
    '% units built pre-1970',
    '% units built in 1970s',
    '% units built in 1980s',
    '% units built in 1990s',
    '% units built in 2000s',
    '% units built in 2010s+',
] as const
