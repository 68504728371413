const value: Record<string, number> = {
    'Continent': 0,
    'Country': 1,
    'Subnational Region': 2,
    'County': 3,
    'Urban Center': 4,
    'CA Census Division': 5,
    'CA Population Center': 6,
    'City': 7,
    'CSA': 8,
    'MSA': 9,
    'Urban Area': 10,
    'CA CMA': 11,
    'CCD': 12,
    'CA Census Subdivision': 13,
    'Neighborhood': 14,
    'ZIP': 15,
    'Historical Congressional District': 16,
    'State House District': 17,
    'State Senate District': 18,
    'Congressional District': 19,
    'County Cross CD': 20,
    'CA Riding': 21,
    'Native Area': 22,
    'Native Statistical Area': 23,
    'Native Subdivision': 24,
    'School District': 25,
    'Judicial Circuit': 26,
    'Media Market': 27,
    'USDA County Type': 28,
    'Hospital Referral Region': 29,
    'Judicial District': 30,
    'Hospital Service Area': 31,
    '1B Person Circle': 32,
    '500M Person Circle': 33,
    '200M Person Circle': 34,
    '100M Person Circle': 35,
    '50M Person Circle': 36,
    '20M Person Circle': 37,
    '10M Person Circle': 38,
    '5M Person Circle': 39,
}
export default value
