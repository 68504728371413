const value: Record<string, string> = {
    'County': 'US Subdivision',
    'MSA': 'Census',
    'CSA': 'Census',
    'Urban Area': 'Census',
    'ZIP': 'Small',
    'CCD': 'Census',
    'City': 'US Subdivision',
    'Neighborhood': 'Small',
    'Congressional District': 'Political',
    'State House District': 'Political',
    'State Senate District': 'Political',
    'Historical Congressional District': 'Political',
    'Native Area': 'Native',
    'Native Statistical Area': 'Native',
    'Native Subdivision': 'Native',
    'School District': 'School',
    'Judicial District': 'Oddball',
    'Judicial Circuit': 'Oddball',
    'County Cross CD': 'Political',
    'USDA County Type': 'Oddball',
    'Hospital Referral Region': 'Oddball',
    'Hospital Service Area': 'Oddball',
    'Media Market': 'Oddball',
    'CA Census Division': 'US Subdivision',
    'CA Census Subdivision': 'Census',
    'CA Population Center': 'US Subdivision',
    'CA CMA': 'Census',
    'CA Riding': 'Political',
    'Continent': 'International',
    'Country': 'International',
    'Subnational Region': 'US Subdivision',
    'Urban Center': 'International',
    '5M Person Circle': 'Kavi',
    '10M Person Circle': 'Kavi',
    '20M Person Circle': 'Kavi',
    '50M Person Circle': 'Kavi',
    '100M Person Circle': 'Kavi',
    '200M Person Circle': 'Kavi',
    '500M Person Circle': 'Kavi',
    '1B Person Circle': 'Kavi',
}
export default value
