const value: Record<string, string> = {
    'County': 'US Subdivision',
    'MSA': 'Census',
    'CSA': 'Census',
    'Urban Area': 'Census',
    'ZIP': 'Small',
    'CCD': 'Census',
    'City': 'US Subdivision',
    'Neighborhood': 'Small',
    'Congressional District': 'Political',
    'State House District': 'Political',
    'State Senate District': 'Political',
    'Congressional District (1780s)': 'Political',
    'Congressional District (1790s)': 'Political',
    'Congressional District (1800s)': 'Political',
    'Congressional District (1810s)': 'Political',
    'Congressional District (1820s)': 'Political',
    'Congressional District (1830s)': 'Political',
    'Congressional District (1840s)': 'Political',
    'Congressional District (1850s)': 'Political',
    'Congressional District (1860s)': 'Political',
    'Congressional District (1870s)': 'Political',
    'Congressional District (1880s)': 'Political',
    'Congressional District (1890s)': 'Political',
    'Congressional District (1900s)': 'Political',
    'Congressional District (1910s)': 'Political',
    'Congressional District (1920s)': 'Political',
    'Congressional District (1930s)': 'Political',
    'Congressional District (1940s)': 'Political',
    'Congressional District (1950s)': 'Political',
    'Congressional District (1960s)': 'Political',
    'Congressional District (1970s)': 'Political',
    'Congressional District (1980s)': 'Political',
    'Congressional District (1990s)': 'Political',
    'Congressional District (2000s)': 'Political',
    'Congressional District (2010s)': 'Political',
    'Congressional District (2020s)': 'Political',
    'Native Area': 'Native',
    'Native Statistical Area': 'Native',
    'Native Subdivision': 'Native',
    'School District': 'School',
    'Judicial District': 'Oddball',
    'Judicial Circuit': 'Oddball',
    'County Cross CD': 'Political',
    'USDA County Type': 'Oddball',
    'Hospital Referral Region': 'Oddball',
    'Hospital Service Area': 'Oddball',
    'Media Market': 'Oddball',
    'CA Census Division': 'US Subdivision',
    'CA Census Subdivision': 'Census',
    'CA Population Center': 'US Subdivision',
    'CA CMA': 'Census',
    'CA Riding': 'Political',
    'Continent': 'International',
    'Country': 'International',
    'Subnational Region': 'US Subdivision',
    'Urban Center': 'International',
    '5M Person Circle': 'Kavi',
    '10M Person Circle': 'Kavi',
    '20M Person Circle': 'Kavi',
    '50M Person Circle': 'Kavi',
    '100M Person Circle': 'Kavi',
    '200M Person Circle': 'Kavi',
    '500M Person Circle': 'Kavi',
    '1B Person Circle': 'Kavi',
}
export default value
