const value: Record<string, number> = {
    'world': 1.4981273408239701,
    'Africa': 1.6666666666666667,
    'Antarctica': 1.4981273408239701,
    'Asia': 1.6666666666666667,
    'Europe': 1.6666666666666667,
    'North America': 1.6666666666666667,
    'Oceania': 1.6666666666666667,
    'South America': 1.6666666666666667,
    'Andorra': 1.4285714285714286,
    'United Arab Emirates': 2.0,
    'Afghanistan': 1.4981273408239701,
    'Antigua and Barbuda': 1.4981273408239701,
    'Anguilla': 2.0,
    'Albania': 1.3986013986013985,
    'Armenia': 2.0,
    'Angola': 1.4981273408239701,
    'Argentina': 1.6,
    'Austria': 1.4981273408239701,
    'Australia': 2.0,
    'Aruba': 1.4981273408239701,
    'Azerbaijan': 2.0,
    'Bosnia and Herzegovina': 2.0,
    'Barbados': 1.4981273408239701,
    'Bangladesh': 1.6666666666666667,
    'Belgium': 1.1527377521613833,
    'Burkina Faso': 1.4981273408239701,
    'Bulgaria': 1.6666666666666667,
    'Bahrain': 1.6666666666666667,
    'Burundi': 1.6666666666666667,
    'Benin': 1.4981273408239701,
    'Bermuda': 2.0,
    'Brunei': 2.0,
    'Bolivia': 1.465201465201465,
    'Brazil': 1.4285714285714286,
    'The Bahamas': 2.0,
    'Bhutan': 1.4981273408239701,
    'Bouvet Island': 1.3745704467353952,
    'Botswana': 1.4981273408239701,
    'Belarus': 2.0,
    'Belize': 1.6666666666666667,
    'Canada': 2.0,
    'Cocos (Keeling) Islands': 2.0,
    'Democratic Republic of the Congo': 1.3333333333333333,
    'Central African Republic': 1.4981273408239701,
    'Congo': 1.4981273408239701,
    'Switzerland': 1.0,
    'Ivory Coast': 1.4981273408239701,
    'Cook Islands': 2.0,
    'Chile': 1.4981273408239701,
    'Cameroon': 1.4981273408239701,
    'China': 1.4981273408239701,
    'Colombia': 1.4981273408239701,
    'Costa Rica': 1.6666666666666667,
    'Cuba': 2.0,
    'Cape Verde': 1.702127659574468,
    'Christmas Island': 2.0,
    'Cyprus': 1.4981273408239701,
    'Czech Republic': 1.4981273408239701,
    'Germany': 1.6666666666666667,
    'Djibouti': 1.4981273408239701,
    'Denmark': 1.3201320132013201,
    'Dominica': 2.0,
    'Dominican Republic': 1.4981273408239701,
    'Algeria': 1.4981273408239701,
    'Ecuador': 1.4981273408239701,
    'Estonia': 1.5686274509803921,
    'Egypt': 1.4981273408239701,
    'Eritrea': 2.0,
    'Spain': 1.4981273408239701,
    'Ethiopia': 2.0,
    'Finland': 1.639344262295082,
    'Fiji': 2.0,
    'Falkland Islands': 2.0,
    'Micronesia': 1.8957345971563981,
    'Faroe Islands': 1.3745704467353952,
    'France': 1.4981273408239701,
    'Gabon': 1.3333333333333333,
    'United Kingdom': 2.0,
    'Grenada': 1.6666666666666667,
    'Georgia': 1.4981273408239701,
    'Guernsey': 1.4981273408239701,
    'Ghana': 1.4981273408239701,
    'Gibraltar': 2.0,
    'Greenland': 1.4981273408239701,
    'The Gambia': 1.4981273408239701,
    'Guinea': 1.4981273408239701,
    'Equatorial Guinea': 1.4981273408239701,
    'Greece': 1.4981273408239701,
    'South Georgia and the South Sandwich Islands': 2.0,
    'Guatemala': 1.6,
    'Guinea-Bissau': 2.0,
    'Guyana': 1.6666666666666667,
    'Heard Island and McDonald Islands': 2.0,
    'Honduras': 2.0,
    'Croatia': 2.0,
    'Haiti': 1.6666666666666667,
    'Hungary': 2.0,
    'Indonesia': 1.4981273408239701,
    'Ireland': 2.0,
    'Israel': 1.3745704467353952,
    'Isle of Man': 2.0,
    'India': 1.4981273408239701,
    'British Indian Ocean Territory': 2.0,
    'Iraq': 1.4981273408239701,
    'Iran': 1.7467248908296944,
    'Iceland': 1.3888888888888888,
    'Italy': 1.4981273408239701,
    'Jersey': 1.6666666666666667,
    'Jamaica': 2.0,
    'Jordan': 2.0,
    'Japan': 1.4981273408239701,
    'Kenya': 1.4981273408239701,
    'Kyrgyzstan': 1.6666666666666667,
    'Cambodia': 1.5625,
    'Kiribati': 2.0,
    'Comoros': 1.6666666666666667,
    'Saint Kitts and Nevis': 1.4981273408239701,
    'North Korea': 2.0,
    'South Korea': 1.4981273408239701,
    'Kuwait': 2.0,
    'Cayman Islands': 2.0,
    'Kazakhstan': 2.0,
    'Laos': 1.4981273408239701,
    'Lebanon': 1.4981273408239701,
    'Saint Lucia': 2.0,
    'Liechtenstein': 1.6666666666666667,
    'Sri Lanka': 2.0,
    'Liberia': 1.8957345971563981,
    'Lesotho': 1.4981273408239701,
    'Lithuania': 1.6666666666666667,
    'Luxembourg': 1.6666666666666667,
    'Latvia': 2.0,
    'Libya': 2.0,
    'Morocco': 1.4981273408239701,
    'Monaco': 1.25,
    'Moldova': 2.0,
    'Montenegro': 2.0,
    'Madagascar': 1.4981273408239701,
    'Marshall Islands': 1.8957345971563981,
    'North Macedonia': 2.0,
    'Mali': 1.4981273408239701,
    'Myanmar': 1.4981273408239701,
    'Mongolia': 2.0,
    'Mauritania': 1.4981273408239701,
    'Montserrat': 2.0,
    'Malta': 1.4981273408239701,
    'Mauritius': 1.4981273408239701,
    'Maldives': 1.4981273408239701,
    'Malawi': 1.4981273408239701,
    'Mexico': 1.7467248908296944,
    'Malaysia': 2.0,
    'Mozambique': 1.4981273408239701,
    'Namibia': 1.4981273408239701,
    'Niger': 1.1661807580174928,
    'Norfolk Island': 2.0,
    'Nigeria': 2.0,
    'Nicaragua': 1.6666666666666667,
    'Netherlands': 1.4981273408239701,
    'Norway': 1.3745704467353952,
    'Nepal': 0.819672131147541,
    'Nauru': 2.0,
    'Niue': 2.0,
    'New Zealand': 2.0,
    'Oman': 1.7467248908296944,
    'Panama': 1.4981273408239701,
    'Peru': 1.4981273408239701,
    'Papua New Guinea': 1.3333333333333333,
    'Philippines': 2.0,
    'Pakistan': 1.4981273408239701,
    'Poland': 1.6,
    'Pitcairn Islands': 2.0,
    'State of Palestine': 2.0,
    'Portugal': 1.4981273408239701,
    'Palau': 1.6,
    'Paraguay': 1.8181818181818181,
    'Qatar': 2.5477707006369426,
    'Romania': 1.4981273408239701,
    'Serbia': 1.4981273408239701,
    'Russia': 1.4981273408239701,
    'Rwanda': 1.4981273408239701,
    'Saudi Arabia': 1.4981273408239701,
    'Solomon Islands': 2.0,
    'Seychelles': 2.0,
    'Sudan': 2.0,
    'Sweden': 1.6,
    'Singapore': 1.4981273408239701,
    'Saint Helena, Ascension and Tristan da Cunha': 1.6666666666666667,
    'Slovenia': 2.0,
    'Slovakia': 1.4981273408239701,
    'Sierra Leone': 1.4981273408239701,
    'San Marino': 1.3333333333333333,
    'Senegal': 1.4981273408239701,
    'Somalia': 1.4981273408239701,
    'Suriname': 1.4981273408239701,
    'South Sudan': 2.0,
    'S\u00e3o Tom\u00e9 and Pr\u00edncipe': 2.0,
    'El Salvador': 1.7699115044247788,
    'Syria': 1.4981273408239701,
    'Eswatini': 1.4981273408239701,
    'Turks and Caicos Islands': 2.0,
    'Chad': 1.4981273408239701,
    'Togo': 1.6194331983805668,
    'Thailand': 1.4981273408239701,
    'Tajikistan': 2.0,
    'Tokelau': 2.0,
    'East Timor': 2.0,
    'Turkmenistan': 1.4981273408239701,
    'Tunisia': 1.4981273408239701,
    'Tonga': 2.0,
    'Turkey': 1.4981273408239701,
    'Trinidad and Tobago': 1.6666666666666667,
    'Tuvalu': 2.0,
    'Tanzania': 1.4981273408239701,
    'Ukraine': 1.4981273408239701,
    'Uganda': 1.4981273408239701,
    'United States Minor Outlying Islands': 1.8957345971563981,
    'USA': 1.8957345971563981,
    'Uruguay': 1.4981273408239701,
    'Uzbekistan': 2.0,
    'Vatican City': 1.0,
    'Saint Vincent and the Grenadines': 1.4981273408239701,
    'Venezuela': 1.4981273408239701,
    'British Virgin Islands': 2.0,
    'Vietnam': 1.4981273408239701,
    'Vanuatu': 1.6666666666666667,
    'Samoa': 2.0,
    'Yemen': 1.4981273408239701,
    'South Africa': 1.4981273408239701,
    'Zambia': 1.4981273408239701,
    'Zimbabwe': 2.0,
    'Alabama, USA': 1.4981273408239701,
    'Alaska, USA': 1.4184397163120568,
    'Arizona, USA': 1.4981273408239701,
    'Arkansas, USA': 1.4981273408239701,
    'California, USA': 1.4981273408239701,
    'Colorado, USA': 1.4981273408239701,
    'Connecticut, USA': 1.2698412698412698,
    'Delaware, USA': 1.4981273408239701,
    'Florida, USA': 1.4981273408239701,
    'Georgia, USA': 1.6,
    'Hawaii, USA': 2.0,
    'Idaho, USA': 1.2698412698412698,
    'Illinois, USA': 1.6666666666666667,
    'Indiana, USA': 1.4981273408239701,
    'Iowa, USA': 1.4981273408239701,
    'Kansas, USA': 1.6666666666666667,
    'Kentucky, USA': 1.8957345971563981,
    'Louisiana, USA': 1.5444015444015444,
    'Maine, USA': 1.2698412698412698,
    'Maryland, USA': 1.4981273408239701,
    'Massachusetts, USA': 1.6666666666666667,
    'Michigan, USA': 1.4981273408239701,
    'Minnesota, USA': 1.6666666666666667,
    'Mississippi, USA': 1.6666666666666667,
    'Missouri, USA': 1.7167381974248928,
    'Montana, USA': 1.4981273408239701,
    'Nebraska, USA': 1.6666666666666667,
    'Nevada, USA': 1.4981273408239701,
    'New Hampshire, USA': 1.4981273408239701,
    'New Jersey, USA': 1.6597510373443984,
    'New Mexico, USA': 1.4981273408239701,
    'New York, USA': 2.0,
    'North Carolina, USA': 1.4981273408239701,
    'North Dakota, USA': 1.2698412698412698,
    'Ohio, USA': 1.6260162601626016,
    'Oklahoma, USA': 1.4981273408239701,
    'Oregon, USA': 1.4981273408239701,
    'Pennsylvania, USA': 1.36986301369863,
    'Rhode Island, USA': 1.1363636363636365,
    'South Carolina, USA': 1.4981273408239701,
    'South Dakota, USA': 1.6,
    'Tennessee, USA': 1.6666666666666667,
    'Texas, USA': 1.4981273408239701,
    'Utah, USA': 1.6666666666666667,
    'Vermont, USA': 1.6666666666666667,
    'Virginia, USA': 1.4981273408239701,
    'Washington, USA': 1.680672268907563,
    'West Virginia, USA': 1.8957345971563981,
    'Wisconsin, USA': 1.4981273408239701,
    'Wyoming, USA': 1.4285714285714286,
    'American Samoa, USA': 2.0,
    'Guam, USA': 1.8604651162790697,
    'Northern Mariana Islands, USA': 2.0,
    'Puerto Rico, USA': 1.4981273408239701,
    'US Virgin Islands, USA': 1.4981273408239701,
    'District of Columbia, USA': 2.0,
    'Alberta, Canada': 2.0,
    'British Columbia, Canada': 1.6666666666666667,
    'Manitoba, Canada': 2.0,
    'New Brunswick, Canada': 1.6,
    'Newfoundland and Labrador, Canada': 2.0,
    'Northwest Territories, Canada': 2.0,
    'Nova Scotia, Canada': 2.0,
    'Nunavut, Canada': 1.7777777777777777,
    'Ontario, Canada': 2.0,
    'Prince Edward Island, Canada': 1.4598540145985401,
    'Quebec, Canada': 1.4981273408239701,
    'Saskatchewan, Canada': 2.0,
    'Yukon, Canada': 2.0,
}
export default value
