const value: Record<string, number[]> = {
    'world__10M Person Circle': [
        153,
        123,
    ],
    'world__5M Person Circle': [
        76,
        76,
        76,
        48,
    ],
    'world__CA Census Subdivision': [
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        16,
    ],
    'world__CA Population Center': [
        122,
        122,
        32,
    ],
    'world__CCD': [
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
    ],
    'world__City': [
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
    ],
    'world__County': [
        39,
        39,
        39,
        39,
        39,
        39,
        39,
        3,
    ],
    'world__County Cross CD': [
        34,
        34,
        34,
        34,
        34,
        34,
        34,
        34,
        4,
    ],
    'world__Historical Congressional District': [
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        10,
    ],
    'world__Hospital Service Area': [
        37,
        37,
        37,
        37,
        37,
        37,
        37,
        17,
    ],
    'world__MSA': [
        133,
        133,
        10,
    ],
    'world__Native Subdivision': [
        272,
        4,
    ],
    'world__Neighborhood': [
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        4,
    ],
    'world__School District': [
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        6,
    ],
    'world__State House District': [
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        16,
    ],
    'world__State Senate District': [
        65,
        65,
        65,
        65,
        16,
    ],
    'world__Subnational Region': [
        35,
        35,
        35,
        35,
        35,
        35,
        35,
        31,
    ],
    'world__Urban Area': [
        48,
        48,
        48,
        48,
        48,
        36,
    ],
    'world__Urban Center': [
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        3,
    ],
    'world__ZIP': [
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
    ],
    'Africa__Subnational Region': [
        134,
        134,
        8,
    ],
    'Africa__Urban Center': [
        63,
        63,
        63,
        63,
        24,
    ],
    'Asia__10M Person Circle': [
        254,
        22,
    ],
    'Asia__5M Person Circle': [
        127,
        127,
        22,
    ],
    'Asia__Subnational Region': [
        125,
        125,
        26,
    ],
    'Asia__Urban Center': [
        22,
        22,
        22,
        22,
        22,
        22,
        22,
        22,
        22,
        22,
        22,
        22,
        12,
    ],
    'Europe__Subnational Region': [
        132,
        132,
        12,
    ],
    'Europe__Urban Center': [
        128,
        128,
        20,
    ],
    'North America__CA Census Subdivision': [
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        16,
    ],
    'North America__CA Population Center': [
        122,
        122,
        32,
    ],
    'North America__CCD': [
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
    ],
    'North America__City': [
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
    ],
    'North America__County': [
        39,
        39,
        39,
        39,
        39,
        39,
        39,
        3,
    ],
    'North America__County Cross CD': [
        34,
        34,
        34,
        34,
        34,
        34,
        34,
        34,
        4,
    ],
    'North America__Historical Congressional District': [
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        10,
    ],
    'North America__Hospital Service Area': [
        37,
        37,
        37,
        37,
        37,
        37,
        37,
        17,
    ],
    'North America__MSA': [
        133,
        133,
        10,
    ],
    'North America__Native Subdivision': [
        272,
        4,
    ],
    'North America__Neighborhood': [
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        4,
    ],
    'North America__School District': [
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        6,
    ],
    'North America__State House District': [
        27,
        27,
        27,
        27,
        27,
        27,
        27,
        27,
        27,
        27,
        6,
    ],
    'North America__State Senate District': [
        66,
        66,
        66,
        66,
        12,
    ],
    'North America__Urban Area': [
        48,
        48,
        48,
        48,
        48,
        36,
    ],
    'North America__Urban Center': [
        181,
        95,
    ],
    'North America__ZIP': [
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
    ],
    'South America__Urban Center': [
        173,
        103,
    ],
    'Canada__CA Census Subdivision': [
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        16,
    ],
    'Canada__CA Population Center': [
        122,
        122,
        32,
    ],
    'China__Urban Center': [
        69,
        69,
        69,
        69,
    ],
    'India__Urban Center': [
        68,
        68,
        68,
        68,
        4,
    ],
    'USA__CCD': [
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
    ],
    'USA__City': [
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
    ],
    'USA__County': [
        39,
        39,
        39,
        39,
        39,
        39,
        39,
        3,
    ],
    'USA__County Cross CD': [
        34,
        34,
        34,
        34,
        34,
        34,
        34,
        34,
        4,
    ],
    'USA__Historical Congressional District': [
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        14,
        10,
    ],
    'USA__Hospital Service Area': [
        37,
        37,
        37,
        37,
        37,
        37,
        37,
        17,
    ],
    'USA__MSA': [
        133,
        133,
        10,
    ],
    'USA__Native Subdivision': [
        272,
        4,
    ],
    'USA__Neighborhood': [
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        4,
    ],
    'USA__School District': [
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        6,
    ],
    'USA__State House District': [
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        26,
        16,
    ],
    'USA__State Senate District': [
        65,
        65,
        65,
        65,
        16,
    ],
    'USA__Urban Area': [
        48,
        48,
        48,
        48,
        48,
        36,
    ],
    'USA__ZIP': [
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
    ],
    'Alabama, USA__City': [
        211,
        65,
    ],
    'Alabama, USA__ZIP': [
        195,
        81,
    ],
    'Arizona, USA__City': [
        269,
        7,
    ],
    'Arizona, USA__Neighborhood': [
        160,
        116,
    ],
    'Arkansas, USA__CCD': [
        107,
        107,
        62,
    ],
    'Arkansas, USA__City': [
        200,
        76,
    ],
    'Arkansas, USA__ZIP': [
        210,
        66,
    ],
    'California, USA__City': [
        78,
        78,
        78,
        42,
    ],
    'California, USA__Historical Congressional District': [
        236,
        40,
    ],
    'California, USA__Neighborhood': [
        63,
        63,
        63,
        63,
        24,
    ],
    'California, USA__School District': [
        128,
        128,
        20,
    ],
    'California, USA__ZIP': [
        72,
        72,
        72,
        60,
    ],
    'Colorado, USA__City': [
        261,
        15,
    ],
    'Colorado, USA__Neighborhood': [
        230,
        46,
    ],
    'Colorado, USA__ZIP': [
        241,
        35,
    ],
    'Florida, USA__City': [
        131,
        131,
        14,
    ],
    'Florida, USA__Neighborhood': [
        100,
        100,
        76,
    ],
    'Florida, USA__ZIP': [
        128,
        128,
        20,
    ],
    'Georgia, USA__CCD': [
        214,
        62,
    ],
    'Georgia, USA__City': [
        186,
        90,
    ],
    'Georgia, USA__Neighborhood': [
        260,
        16,
    ],
    'Georgia, USA__ZIP': [
        171,
        105,
    ],
    'Illinois, USA__CCD': [
        74,
        74,
        74,
        54,
    ],
    'Illinois, USA__City': [
        86,
        86,
        86,
        18,
    ],
    'Illinois, USA__School District': [
        145,
        131,
    ],
    'Illinois, USA__ZIP': [
        91,
        91,
        91,
        3,
    ],
    'Indiana, USA__CCD': [
        124,
        124,
        28,
    ],
    'Indiana, USA__City': [
        130,
        130,
        16,
    ],
    'Indiana, USA__Neighborhood': [
        187,
        89,
    ],
    'Indiana, USA__ZIP': [
        162,
        114,
    ],
    'Iowa, USA__CCD': [
        76,
        76,
        76,
        48,
    ],
    'Iowa, USA__City': [
        122,
        122,
        32,
    ],
    'Iowa, USA__ZIP': [
        134,
        134,
        8,
    ],
    'Kansas, USA__CCD': [
        82,
        82,
        82,
        30,
    ],
    'Kansas, USA__City': [
        169,
        107,
    ],
    'Kansas, USA__ZIP': [
        179,
        97,
    ],
    'Kentucky, USA__CCD': [
        254,
        22,
    ],
    'Kentucky, USA__City': [
        226,
        50,
    ],
    'Kentucky, USA__ZIP': [
        164,
        112,
    ],
    'Louisiana, USA__CCD': [
        217,
        59,
    ],
    'Louisiana, USA__City': [
        256,
        20,
    ],
    'Louisiana, USA__ZIP': [
        244,
        32,
    ],
    'Maine, USA__CCD': [
        238,
        38,
    ],
    'Maryland, USA__City': [
        233,
        43,
    ],
    'Maryland, USA__ZIP': [
        268,
        8,
    ],
    'Massachusetts, USA__ZIP': [
        234,
        42,
    ],
    'Michigan, USA__CCD': [
        82,
        82,
        82,
        30,
    ],
    'Michigan, USA__City': [
        168,
        108,
    ],
    'Michigan, USA__School District': [
        231,
        45,
    ],
    'Michigan, USA__ZIP': [
        128,
        128,
        20,
    ],
    'Minnesota, USA__CCD': [
        46,
        46,
        46,
        46,
        46,
        46,
    ],
    'Minnesota, USA__City': [
        137,
        137,
        2,
    ],
    'Minnesota, USA__ZIP': [
        141,
        135,
    ],
    'Missouri, USA__CCD': [
        90,
        90,
        90,
        6,
    ],
    'Missouri, USA__City': [
        116,
        116,
        44,
    ],
    'Missouri, USA__School District': [
        242,
        34,
    ],
    'Missouri, USA__ZIP': [
        122,
        122,
        32,
    ],
    'Montana, USA__City': [
        257,
        19,
    ],
    'Nebraska, USA__CCD': [
        106,
        106,
        64,
    ],
    'Nebraska, USA__City': [
        212,
        64,
    ],
    'Nebraska, USA__ZIP': [
        215,
        61,
    ],
    'New Jersey, USA__CCD': [
        222,
        54,
    ],
    'New Jersey, USA__City': [
        179,
        97,
    ],
    'New Jersey, USA__School District': [
        225,
        51,
    ],
    'New Jersey, USA__ZIP': [
        211,
        65,
    ],
    'New Mexico, USA__City': [
        238,
        38,
    ],
    'New York, USA__CCD': [
        124,
        124,
        28,
    ],
    'New York, USA__City': [
        97,
        97,
        82,
    ],
    'New York, USA__Historical Congressional District': [
        104,
        104,
        68,
    ],
    'New York, USA__Neighborhood': [
        221,
        55,
    ],
    'New York, USA__School District': [
        184,
        92,
    ],
    'New York, USA__ZIP': [
        71,
        71,
        71,
        63,
    ],
    'North Carolina, USA__CCD': [
        121,
        121,
        34,
    ],
    'North Carolina, USA__City': [
        162,
        114,
    ],
    'North Carolina, USA__Neighborhood': [
        214,
        62,
    ],
    'North Carolina, USA__ZIP': [
        156,
        120,
    ],
    'North Dakota, USA__CCD': [
        72,
        72,
        72,
        60,
    ],
    'Ohio, USA__CCD': [
        79,
        79,
        79,
        39,
    ],
    'Ohio, USA__City': [
        99,
        99,
        78,
    ],
    'Ohio, USA__Historical Congressional District': [
        196,
        80,
    ],
    'Ohio, USA__Neighborhood': [
        239,
        37,
    ],
    'Ohio, USA__School District': [
        204,
        72,
    ],
    'Ohio, USA__ZIP': [
        105,
        105,
        66,
    ],
    'Oklahoma, USA__City': [
        149,
        127,
    ],
    'Oklahoma, USA__School District': [
        246,
        30,
    ],
    'Oklahoma, USA__ZIP': [
        193,
        83,
    ],
    'Pennsylvania, USA__CCD': [
        49,
        49,
        49,
        49,
        49,
        31,
    ],
    'Pennsylvania, USA__City': [
        67,
        67,
        67,
        67,
        8,
    ],
    'Pennsylvania, USA__Historical Congressional District': [
        144,
        132,
    ],
    'Pennsylvania, USA__School District': [
        250,
        26,
    ],
    'Pennsylvania, USA__ZIP': [
        70,
        70,
        70,
        66,
    ],
    'South Carolina, USA__City': [
        263,
        13,
    ],
    'South Dakota, USA__CCD': [
        95,
        95,
        86,
    ],
    'South Dakota, USA__City': [
        262,
        14,
    ],
    'Tennessee, USA__CCD': [
        148,
        128,
    ],
    'Tennessee, USA__City': [
        248,
        28,
    ],
    'Tennessee, USA__Neighborhood': [
        206,
        70,
    ],
    'Tennessee, USA__ZIP': [
        199,
        77,
    ],
    'Texas, USA__CCD': [
        145,
        131,
    ],
    'Texas, USA__City': [
        68,
        68,
        68,
        68,
        4,
    ],
    'Texas, USA__Historical Congressional District': [
        196,
        80,
    ],
    'Texas, USA__Neighborhood': [
        95,
        95,
        86,
    ],
    'Texas, USA__School District': [
        123,
        123,
        30,
    ],
    'Texas, USA__ZIP': [
        65,
        65,
        65,
        65,
        16,
    ],
    'Virginia, USA__CCD': [
        227,
        49,
    ],
    'Virginia, USA__City': [
        186,
        90,
    ],
    'Virginia, USA__Neighborhood': [
        211,
        65,
    ],
    'Virginia, USA__ZIP': [
        141,
        135,
    ],
    'Washington, USA__City': [
        196,
        80,
    ],
    'Washington, USA__Neighborhood': [
        220,
        56,
    ],
    'Washington, USA__ZIP': [
        211,
        65,
    ],
    'West Virginia, USA__ZIP': [
        177,
        99,
    ],
    'Wisconsin, USA__CCD': [
        66,
        66,
        66,
        66,
        12,
    ],
    'Wisconsin, USA__City': [
        155,
        121,
    ],
    'Wisconsin, USA__ZIP': [
        162,
        114,
    ],
    'Puerto Rico, USA__CCD': [
        140,
        136,
    ],
    'British Columbia, Canada__CA Census Subdivision': [
        195,
        81,
    ],
    'Ontario, Canada__CA Census Subdivision': [
        224,
        52,
    ],
    'Quebec, Canada__CA Census Subdivision': [
        105,
        105,
        66,
    ],
    'Saskatchewan, Canada__CA Census Subdivision': [
        139,
        137,
    ],
}
export default value
