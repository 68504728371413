const value: Record<string, string> = {
    'United States of America': 'USA',
    'Ab Pakhsh Urban Center, Iran, Islamic Republic of': 'Ab Pakhsh Urban Center, Iran',
    'Abadan Urban Center, Iran, Islamic Republic of': 'Abadan Urban Center, Iran',
    'Abadeh Urban Center, Iran, Islamic Republic of': 'Abadeh Urban Center, Iran',
    'Abakan Urban Center, Russian Federation': 'Abakan Urban Center, Russia',
    'Abengourou Urban Center, C\u00f4te d\'Ivoire': 'Abengourou Urban Center, Ivory Coast',
    'Abhar Urban Center, Iran, Islamic Republic of': 'Abhar Urban Center, Iran',
    'Abidjan 100MPC, Ghana-Burkina Faso-C\u00f4te d\'Ivoire': 'Abidjan 100MPC, Ghana-Burkina Faso-Ivory Coast',
    'Abidjan 10MPC, C\u00f4te d\'Ivoire': 'Abidjan 10MPC, Ivory Coast',
    'Abidjan 200MPC, Ghana-C\u00f4te d\'Ivoire-Burkina Faso': 'Abidjan 200MPC, Ghana-Ivory Coast-Burkina Faso',
    'Abidjan 20MPC, C\u00f4te d\'Ivoire': 'Abidjan 20MPC, Ivory Coast',
    'Abidjan 50MPC, C\u00f4te d\'Ivoire-Burkina Faso-Nigeria': 'Abidjan 50MPC, Ivory Coast-Burkina Faso-Nigeria',
    'Abidjan 5MPC, C\u00f4te d\'Ivoire': 'Abidjan 5MPC, Ivory Coast',
    'Abidjan Urban Center, C\u00f4te d\'Ivoire': 'Abidjan Urban Center, Ivory Coast',
    'Abidjan, C\u00f4te d\'Ivoire': 'Abidjan, Ivory Coast',
    'Aboisso Urban Center, C\u00f4te d\'Ivoire': 'Aboisso Urban Center, Ivory Coast',
    'Abu Kamal Urban Center, Syrian Arab Republic': 'Abu Kamal Urban Center, Syria',
    'Acarigua Urban Center, Venezuela, Bolivarian Republic of': 'Acarigua Urban Center, Venezuela',
    'Achinsk Urban Center, Russian Federation': 'Achinsk Urban Center, Russia',
    'Acklins, Bahamas': 'Acklins, The Bahamas',
    'Adygeya, Respublika, Russian Federation': 'Adygeya, Respublika, Russia',
    'Adzop\u00e9 Urban Center, C\u00f4te d\'Ivoire': 'Adzop\u00e9 Urban Center, Ivory Coast',
    'Agboville Urban Center, C\u00f4te d\'Ivoire': 'Agboville Urban Center, Ivory Coast',
    'Aghghala Urban Center, Iran, Islamic Republic of': 'Aghghala Urban Center, Iran',
    'Agnibil\u00e9krou Urban Center, C\u00f4te d\'Ivoire': 'Agnibil\u00e9krou Urban Center, Ivory Coast',
    'Ahar Urban Center, Iran, Islamic Republic of': 'Ahar Urban Center, Iran',
    'Ahwaz 5MPC, Iran, Islamic Republic of-Iraq': 'Ahwaz 5MPC, Iran-Iraq',
    'Ahwaz Urban Center, Iran, Islamic Republic of': 'Ahwaz Urban Center, Iran',
    'Aileu, Timor-Leste': 'Aileu, East Timor',
    'Ainaro, Timor-Leste': 'Ainaro, East Timor',
    'Aitkenvale 5MPC, Australia-Indonesia-Timor-Leste': 'Aitkenvale 5MPC, Australia-Indonesia-East Timor',
    'Ajab Shir Urban Center, Iran, Islamic Republic of': 'Ajab Shir Urban Center, Iran',
    'Aketi Urban Center, Congo, The Democratic Republic of the': 'Aketi Urban Center, Democratic Republic of the Congo',
    'Aktau 5MPC, Russian Federation-Iran, Islamic Republic of-Kazakhstan': 'Aktau 5MPC, Russia-Iran-Kazakhstan',
    'Ak\u00e7akale Urban Center, Syrian Arab Republic-Turkey': 'Ak\u00e7akale Urban Center, Syria-Turkey',
    'Al L\u0101dhiq\u012byah, Syrian Arab Republic': 'Al L\u0101dhiq\u012byah, Syria',
    'Al Qunay\u0163irah, Syrian Arab Republic': 'Al Qunay\u0163irah, Syria',
    'Al Qurayya Urban Center, Syrian Arab Republic': 'Al Qurayya Urban Center, Syria',
    'Al \u1e28asakah, Syrian Arab Republic': 'Al \u1e28asakah, Syria',
    'Al-Hasakah Urban Center, Syrian Arab Republic': 'Al-Hasakah Urban Center, Syria',
    'Al-Tabqah Urban Center, Syrian Arab Republic': 'Al-Tabqah Urban Center, Syria',
    'Alborz, Iran, Islamic Republic of': 'Alborz, Iran',
    'Aleppo 10MPC, Turkey-Syrian Arab Republic': 'Aleppo 10MPC, Turkey-Syria',
    'Aleppo 5MPC, Syrian Arab Republic-Turkey': 'Aleppo 5MPC, Syria-Turkey',
    'Aleppo Urban Center, Syrian Arab Republic': 'Aleppo Urban Center, Syria',
    'Alexandria 50MPC, Egypt-Israel-Palestine, State of': 'Alexandria 50MPC, Egypt-Israel-State of Palestine',
    'Aliabad-e Katul Urban Center, Iran, Islamic Republic of': 'Aliabad-e Katul Urban Center, Iran',
    'Aligudarz Urban Center, Iran, Islamic Republic of': 'Aligudarz Urban Center, Iran',
    'Almetyevsk Urban Center, Russian Federation': 'Almetyevsk Urban Center, Russia',
    'Altagracia de Orituco Urban Center, Venezuela, Bolivarian Republic of': 'Altagracia de Orituco Urban Center, Venezuela',
    'Altay, Respublika, Russian Federation': 'Altay, Respublika, Russia',
    'Altayskiy kray, Russian Federation': 'Altayskiy kray, Russia',
    'Alvand Urban Center, Iran, Islamic Republic of': 'Alvand Urban Center, Iran',
    'Amazonas, Venezuela, Bolivarian Republic of': 'Amazonas, Venezuela',
    'Amman 10MPC, Jordan-Palestine, State of': 'Amman 10MPC, Jordan-State of Palestine',
    'Amman 20MPC, Jordan-Israel-Palestine, State of': 'Amman 20MPC, Jordan-Israel-State of Palestine',
    'Amol Urban Center, Iran, Islamic Republic of': 'Amol Urban Center, Iran',
    'Amurskaya oblast\', Russian Federation': 'Amurskaya oblast\', Russia',
    'An Chau Urban Center, Viet Nam': 'An Chau Urban Center, Vietnam',
    'An Giang, Viet Nam': 'An Giang, Vietnam',
    'An Kh\u00ea Urban Center, Viet Nam': 'An Kh\u00ea Urban Center, Vietnam',
    'Anaco Urban Center, Venezuela, Bolivarian Republic of': 'Anaco Urban Center, Venezuela',
    'Anak Urban Center, Korea, Democratic People\'s Republic of': 'Anak Urban Center, North Korea',
    'Andimeshk Urban Center, Iran, Islamic Republic of': 'Andimeshk Urban Center, Iran',
    'Anenii Noi, Moldova, Republic of': 'Anenii Noi, Moldova',
    'Angarsk Urban Center, Russian Federation': 'Angarsk Urban Center, Russia',
    'Anju Urban Center, Korea, Democratic People\'s Republic of': 'Anju Urban Center, North Korea',
    'Antananarivo 50MPC, Madagascar-Mozambique-Tanzania, United Republic of': 'Antananarivo 50MPC, Madagascar-Mozambique-Tanzania',
    'Anzhero-Sudzhensk Urban Center, Russian Federation': 'Anzhero-Sudzhensk Urban Center, Russia',
    'Anzo\u00e1tegui, Venezuela, Bolivarian Republic of': 'Anzo\u00e1tegui, Venezuela',
    'Apure, Venezuela, Bolivarian Republic of': 'Apure, Venezuela',
    'Ar Raqqah Urban Center, Syrian Arab Republic': 'Ar Raqqah Urban Center, Syria',
    'Ar Raqqah, Syrian Arab Republic': 'Ar Raqqah, Syria',
    'Aragua, Venezuela, Bolivarian Republic of': 'Aragua, Venezuela',
    'Arak Urban Center, Iran, Islamic Republic of': 'Arak Urban Center, Iran',
    'Arauca Urban Center, Colombia-Venezuela, Bolivarian Republic of': 'Arauca Urban Center, Colombia-Venezuela',
    'Ardabil Urban Center, Iran, Islamic Republic of': 'Ardabil Urban Center, Iran',
    'Ardab\u012bl, Iran, Islamic Republic of': 'Ardab\u012bl, Iran',
    'Ardakan Urban Center, Iran, Islamic Republic of': 'Ardakan Urban Center, Iran',
    'Arequipa 10MPC, Peru-Bolivia, Plurinational State of-Chile': 'Arequipa 10MPC, Peru-Bolivia-Chile',
    'Ariwara Urban Center, Congo, The Democratic Republic of the': 'Ariwara Urban Center, Democratic Republic of the Congo',
    'Arkhangel\'skaya oblast\', Russian Federation': 'Arkhangel\'skaya oblast\', Russia',
    'Arkhangelsk Urban Center, Russian Federation': 'Arkhangelsk Urban Center, Russia',
    'Armavir Urban Center, Russian Federation': 'Armavir Urban Center, Russia',
    'Aru Urban Center, Congo, The Democratic Republic of the': 'Aru Urban Center, Democratic Republic of the Congo',
    'Arusha 20MPC, Tanzania, United Republic of-Kenya': 'Arusha 20MPC, Tanzania-Kenya',
    'Arusha 5MPC, Tanzania, United Republic of-Kenya': 'Arusha 5MPC, Tanzania-Kenya',
    'Arusha Urban Center, Tanzania, United Republic of': 'Arusha Urban Center, Tanzania',
    'Arusha, Tanzania, United Republic of': 'Arusha, Tanzania',
    'Arzamas Urban Center, Russian Federation': 'Arzamas Urban Center, Russia',
    'As Safirah Urban Center, Syrian Arab Republic': 'As Safirah Urban Center, Syria',
    'As Suwayda Urban Center, Syrian Arab Republic': 'As Suwayda Urban Center, Syria',
    'As Suwayd\u0101\', Syrian Arab Republic': 'As Suwayd\u0101\', Syria',
    'Asadabad Urban Center, Iran, Islamic Republic of': 'Asadabad Urban Center, Iran',
    'Asan-si Urban Center, Korea, Republic of': 'Asan-si Urban Center, South Korea',
    'Ashgabat 5MPC, Iran, Islamic Republic of-Turkmenistan': 'Ashgabat 5MPC, Iran-Turkmenistan',
    'Ashkhaneh Urban Center, Iran, Islamic Republic of': 'Ashkhaneh Urban Center, Iran',
    'Astara Urban Center, Azerbaijan-Iran, Islamic Republic of': 'Astara Urban Center, Azerbaijan-Iran',
    'Astrakhan 10MPC, Iran, Islamic Republic of-Russian Federation-Kazakhstan': 'Astrakhan 10MPC, Iran-Russia-Kazakhstan',
    'Astrakhan 5MPC, Russian Federation-Azerbaijan': 'Astrakhan 5MPC, Russia-Azerbaijan',
    'Astrakhan Urban Center, Russian Federation': 'Astrakhan Urban Center, Russia',
    'Astrakhanskaya oblast\', Russian Federation': 'Astrakhanskaya oblast\', Russia',
    'Asyut 50MPC, Egypt-Sudan-Congo, The Democratic Republic of the': 'Asyut 50MPC, Egypt-Sudan-Democratic Republic of the Congo',
    'Athens 100MPC, Russian Federation-United Kingdom-Greece': 'Athens 100MPC, Russia-United Kingdom-Greece',
    'Attapu, Lao People\'s Democratic Republic': 'Attapu, Laos',
    'Azadshahr Urban Center, Iran, Islamic Republic of': 'Azadshahr Urban Center, Iran',
    'Azov Urban Center, Russian Federation': 'Azov Urban Center, Russia',
    'Ba Ria Urban Center, Viet Nam': 'Ba Ria Urban Center, Vietnam',
    'Babati Urban Center, Tanzania, United Republic of': 'Babati Urban Center, Tanzania',
    'Babol Urban Center, Iran, Islamic Republic of': 'Babol Urban Center, Iran',
    'Bagh-e Zahra Urban Center, Iran, Islamic Republic of': 'Bagh-e Zahra Urban Center, Iran',
    'Baghdad 200MPC, Russian Federation-Iraq-Uzbekistan': 'Baghdad 200MPC, Russia-Iraq-Uzbekistan',
    'Baghdad 50MPC, Iran, Islamic Republic of-Iraq': 'Baghdad 50MPC, Iran-Iraq',
    'Bahamas': 'The Bahamas',
    'Baharestan Urban Center, Iran, Islamic Republic of': 'Baharestan Urban Center, Iran',
    'Baksan Urban Center, Russian Federation': 'Baksan Urban Center, Russia',
    'Baku 10MPC, Azerbaijan-Iran, Islamic Republic of': 'Baku 10MPC, Azerbaijan-Iran',
    'Baku 20MPC, Azerbaijan-Russian Federation-Armenia': 'Baku 20MPC, Azerbaijan-Russia-Armenia',
    'Balakovo Urban Center, Russian Federation': 'Balakovo Urban Center, Russia',
    'Balashov Urban Center, Russian Federation': 'Balashov Urban Center, Russia',
    'Bam Urban Center, Iran, Islamic Republic of': 'Bam Urban Center, Iran',
    'Bamako 50MPC, C\u00f4te d\'Ivoire-Burkina Faso-Mali': 'Bamako 50MPC, Ivory Coast-Burkina Faso-Mali',
    'Bandar Abbas Urban Center, Iran, Islamic Republic of': 'Bandar Abbas Urban Center, Iran',
    'Bandar Ganaveh Urban Center, Iran, Islamic Republic of': 'Bandar Ganaveh Urban Center, Iran',
    'Bandar Seri Begawan Urban Center, Brunei Darussalam': 'Bandar Seri Begawan Urban Center, Brunei',
    'Bandar-e Anzali Urban Center, Iran, Islamic Republic of': 'Bandar-e Anzali Urban Center, Iran',
    'Bandar-e Mahshahr Urban Center, Iran, Islamic Republic of': 'Bandar-e Mahshahr Urban Center, Iran',
    'Bandar-e Torkaman Urban Center, Iran, Islamic Republic of': 'Bandar-e Torkaman Urban Center, Iran',
    'Bandundu Urban Center, Congo, The Democratic Republic of the': 'Bandundu Urban Center, Democratic Republic of the Congo',
    'Baneh Urban Center, Iran, Islamic Republic of': 'Baneh Urban Center, Iran',
    'Bangkok 100MPC, Thailand-Viet Nam-Cambodia': 'Bangkok 100MPC, Thailand-Vietnam-Cambodia',
    'Bangui 10MPC, Congo, The Democratic Republic of the-Central African Republic': 'Bangui 10MPC, Democratic Republic of the Congo-Central African Republic',
    'Bangui 20MPC, Congo, The Democratic Republic of the-Central African Republic-Chad': 'Bangui 20MPC, Democratic Republic of the Congo-Central African Republic-Chad',
    'Bangui 5MPC, Congo, The Democratic Republic of the-Central African Republic': 'Bangui 5MPC, Democratic Republic of the Congo-Central African Republic',
    'Bangui Urban Center, Congo, The Democratic Republic of the-Central African Republic': 'Bangui Urban Center, Democratic Republic of the Congo-Central African Republic',
    'Baniyas Urban Center, Syrian Arab Republic': 'Baniyas Urban Center, Syria',
    'Banjul, Gambia': 'Banjul, The Gambia',
    'Baqa al Gharbiyya Urban Center, Israel-Palestine, State of': 'Baqa al Gharbiyya Urban Center, Israel-State of Palestine',
    'Baraka Urban Center, Congo, The Democratic Republic of the': 'Baraka Urban Center, Democratic Republic of the Congo',
    'Barcelona 10MPC, Venezuela, Bolivarian Republic of-Trinidad and Tobago': 'Barcelona 10MPC, Venezuela-Trinidad and Tobago',
    'Barcelona 5MPC, Venezuela, Bolivarian Republic of': 'Barcelona 5MPC, Venezuela',
    'Barcelona Urban Center, Venezuela, Bolivarian Republic of': 'Barcelona Urban Center, Venezuela',
    'Bardaskan Urban Center, Iran, Islamic Republic of': 'Bardaskan Urban Center, Iran',
    'Barinas Urban Center, Venezuela, Bolivarian Republic of': 'Barinas Urban Center, Venezuela',
    'Barinas, Venezuela, Bolivarian Republic of': 'Barinas, Venezuela',
    'Barnaul Urban Center, Russian Federation': 'Barnaul Urban Center, Russia',
    'Barquisimeto Urban Center, Venezuela, Bolivarian Republic of': 'Barquisimeto Urban Center, Venezuela',
    'Barranquilla 20MPC, Colombia-Venezuela, Bolivarian Republic of': 'Barranquilla 20MPC, Colombia-Venezuela',
    'Bas-Sassandra, C\u00f4te d\'Ivoire': 'Bas-Sassandra, Ivory Coast',
    'Bas-U\u00e9l\u00e9, Congo, The Democratic Republic of the': 'Bas-U\u00e9l\u00e9, Democratic Republic of the Congo',
    'Basankusu Urban Center, Congo, The Democratic Republic of the': 'Basankusu Urban Center, Democratic Republic of the Congo',
    'Basarabeasca, Moldova, Republic of': 'Basarabeasca, Moldova',
    'Bashkortostan, Respublika, Russian Federation': 'Bashkortostan, Respublika, Russia',
    'Basra 10MPC, Iraq-Iran, Islamic Republic of': 'Basra 10MPC, Iraq-Iran',
    'Bataysk Urban Center, Russian Federation': 'Bataysk Urban Center, Russia',
    'Baucau, Timor-Leste': 'Baucau, East Timor',
    'Behbahan Urban Center, Iran, Islamic Republic of': 'Behbahan Urban Center, Iran',
    'Behshahr Urban Center, Iran, Islamic Republic of': 'Behshahr Urban Center, Iran',
    'Beijing 200MPC, China-Korea, Democratic People\'s Republic of': 'Beijing 200MPC, China-North Korea',
    'Beirut 5MPC, Syrian Arab Republic-Lebanon': 'Beirut 5MPC, Syria-Lebanon',
    'Belait, Brunei Darussalam': 'Belait, Brunei',
    'Belgorod Urban Center, Russian Federation': 'Belgorod Urban Center, Russia',
    'Belgorodskaya oblast\', Russian Federation': 'Belgorodskaya oblast\', Russia',
    'Ben Cat Urban Center, Viet Nam': 'Ben Cat Urban Center, Vietnam',
    'Bena Tshishimbi Urban Center, Congo, The Democratic Republic of the': 'Bena Tshishimbi Urban Center, Democratic Republic of the Congo',
    'Bender Urban Center, Moldova, Republic of': 'Bender Urban Center, Moldova',
    'Bender, Moldova, Republic of': 'Bender, Moldova',
    'Beni 10MPC, Uganda-Congo, The Democratic Republic of the': 'Beni 10MPC, Uganda-Democratic Republic of the Congo',
    'Beni 20MPC, Uganda-Congo, The Democratic Republic of the-Tanzania, United Republic of': 'Beni 20MPC, Uganda-Democratic Republic of the Congo-Tanzania',
    'Beni 50MPC, Rwanda-Uganda-Congo, The Democratic Republic of the': 'Beni 50MPC, Rwanda-Uganda-Democratic Republic of the Congo',
    'Beni 5MPC, Congo, The Democratic Republic of the-Uganda': 'Beni 5MPC, Democratic Republic of the Congo-Uganda',
    'Beni Urban Center, Congo, The Democratic Republic of the': 'Beni Urban Center, Democratic Republic of the Congo',
    'Berdsk Urban Center, Russian Federation': 'Berdsk Urban Center, Russia',
    'Berezniki Urban Center, Russian Federation': 'Berezniki Urban Center, Russia',
    'Berlin 100MPC, Poland-Germany-Czechia': 'Berlin 100MPC, Poland-Germany-Czech Republic',
    'Berlin 50MPC, Poland-Germany-Czechia': 'Berlin 50MPC, Poland-Germany-Czech Republic',
    'Berry Islands, Bahamas': 'Berry Islands, The Bahamas',
    'Bethlehem, Palestine, State of': 'Bethlehem, State of Palestine',
    'Bijar Urban Center, Iran, Islamic Republic of': 'Bijar Urban Center, Iran',
    'Bimini, Bahamas': 'Bimini, The Bahamas',
    'Birjand Urban Center, Iran, Islamic Republic of': 'Birjand Urban Center, Iran',
    'Birobidzhan Urban Center, Russian Federation': 'Birobidzhan Urban Center, Russia',
    'Biruaca Urban Center, Venezuela, Bolivarian Republic of': 'Biruaca Urban Center, Venezuela',
    'Biysk Urban Center, Russian Federation': 'Biysk Urban Center, Russia',
    'Black Point, Bahamas': 'Black Point, The Bahamas',
    'Blagoveshchensk 10MPC, China-Russian Federation': 'Blagoveshchensk 10MPC, China-Russia',
    'Blagoveshchensk 5MPC, China-Russian Federation': 'Blagoveshchensk 5MPC, China-Russia',
    'Blagoveshchensk Urban Center, China-Russian Federation': 'Blagoveshchensk Urban Center, China-Russia',
    'Boa Vista, Cabo Verde': 'Boa Vista, Cape Verde',
    'Bobo-Dioulasso 10MPC, Burkina Faso-C\u00f4te d\'Ivoire-Mali': 'Bobo-Dioulasso 10MPC, Burkina Faso-Ivory Coast-Mali',
    'Bobonaro, Timor-Leste': 'Bobonaro, East Timor',
    'Bocon\u00f3 Urban Center, Venezuela, Bolivarian Republic of': 'Bocon\u00f3 Urban Center, Venezuela',
    'Boende Urban Center, Congo, The Democratic Republic of the': 'Boende Urban Center, Democratic Republic of the Congo',
    'Bogota 100MPC, Colombia-Venezuela, Bolivarian Republic of-Haiti': 'Bogota 100MPC, Colombia-Venezuela-Haiti',
    'Bogota 200MPC, Colombia-Peru-Venezuela, Bolivarian Republic of': 'Bogota 200MPC, Colombia-Peru-Venezuela',
    'Bogota 50MPC, Colombia-Venezuela, Bolivarian Republic of': 'Bogota 50MPC, Colombia-Venezuela',
    'Bojnurd Urban Center, Iran, Islamic Republic of': 'Bojnurd Urban Center, Iran',
    'Bok\u00e8o, Lao People\'s Democratic Republic': 'Bok\u00e8o, Laos',
    'Bolikhamxai, Lao People\'s Democratic Republic': 'Bolikhamxai, Laos',
    'Bolivia, Plurinational State of': 'Bolivia',
    'Bol\u00edvar, Venezuela, Bolivarian Republic of': 'Bol\u00edvar, Venezuela',
    'Boma Urban Center, Congo, The Democratic Republic of the': 'Boma Urban Center, Democratic Republic of the Congo',
    'Bonab Urban Center, Iran, Islamic Republic of': 'Bonab Urban Center, Iran',
    'Bondo Urban Center, Congo, The Democratic Republic of the': 'Bondo Urban Center, Democratic Republic of the Congo',
    'Bondoukou Urban Center, C\u00f4te d\'Ivoire': 'Bondoukou Urban Center, Ivory Coast',
    'Bonon Urban Center, C\u00f4te d\'Ivoire': 'Bonon Urban Center, Ivory Coast',
    'Borujerd Urban Center, Iran, Islamic Republic of': 'Borujerd Urban Center, Iran',
    'Bouafl\u00e9 Urban Center, C\u00f4te d\'Ivoire': 'Bouafl\u00e9 Urban Center, Ivory Coast',
    'Bouak\u00e9 10MPC, C\u00f4te d\'Ivoire': 'Bouak\u00e9 10MPC, Ivory Coast',
    'Bouak\u00e9 5MPC, C\u00f4te d\'Ivoire': 'Bouak\u00e9 5MPC, Ivory Coast',
    'Bouak\u00e9 Urban Center, C\u00f4te d\'Ivoire': 'Bouak\u00e9 Urban Center, Ivory Coast',
    'Bouna Urban Center, C\u00f4te d\'Ivoire': 'Bouna Urban Center, Ivory Coast',
    'Bratsk Urban Center, Russian Federation': 'Bratsk Urban Center, Russia',
    'Brava, Cabo Verde': 'Brava, Cape Verde',
    'Brazzaville (Center) 10MPC, Congo, The Democratic Republic of the-Congo-Angola': 'Brazzaville (Center) 10MPC, Democratic Republic of the Congo-Congo-Angola',
    'Brazzaville (Outer) 10MPC, Congo, The Democratic Republic of the-Central African Republic-Congo': 'Brazzaville (Outer) 10MPC, Democratic Republic of the Congo-Central African Republic-Congo',
    'Brazzaville 5MPC, Congo, The Democratic Republic of the-Congo': 'Brazzaville 5MPC, Democratic Republic of the Congo-Congo',
    'Briceni, Moldova, Republic of': 'Briceni, Moldova',
    'Brikama Urban Center, Gambia': 'Brikama Urban Center, The Gambia',
    'Brno Urban Center, Czechia': 'Brno Urban Center, Czech Republic',
    'Brunei Darussalam': 'Brunei',
    'Brunei-Muara, Brunei Darussalam': 'Brunei-Muara, Brunei',
    'Bryansk Urban Center, Russian Federation': 'Bryansk Urban Center, Russia',
    'Bryanskaya oblast\', Russian Federation': 'Bryanskaya oblast\', Russia',
    'Bucaramanga 5MPC, Colombia-Venezuela, Bolivarian Republic of': 'Bucaramanga 5MPC, Colombia-Venezuela',
    'Budapest 20MPC, Poland-Slovakia-Czechia': 'Budapest 20MPC, Poland-Slovakia-Czech Republic',
    'Budyonnovsk Urban Center, Russian Federation': 'Budyonnovsk Urban Center, Russia',
    'Bugulma Urban Center, Russian Federation': 'Bugulma Urban Center, Russia',
    'Bujumbura (South) 5MPC, Burundi-Tanzania, United Republic of': 'Bujumbura (South) 5MPC, Burundi-Tanzania',
    'Bujumbura 10MPC, Burundi-Rwanda-Congo, The Democratic Republic of the': 'Bujumbura 10MPC, Burundi-Rwanda-Democratic Republic of the Congo',
    'Bujumbura 20MPC, Tanzania, United Republic of-Burundi': 'Bujumbura 20MPC, Tanzania-Burundi',
    'Bukala Urban Center, Tanzania, United Republic of': 'Bukala Urban Center, Tanzania',
    'Bukavu 5MPC, Congo, The Democratic Republic of the-Rwanda': 'Bukavu 5MPC, Democratic Republic of the Congo-Rwanda',
    'Bukavu Urban Center, Congo, The Democratic Republic of the-Rwanda': 'Bukavu Urban Center, Democratic Republic of the Congo-Rwanda',
    'Bukoba Urban Center, Tanzania, United Republic of': 'Bukoba Urban Center, Tanzania',
    'Bumba 5MPC, Congo, The Democratic Republic of the': 'Bumba 5MPC, Democratic Republic of the Congo',
    'Bumba Urban Center, Congo, The Democratic Republic of the': 'Bumba Urban Center, Democratic Republic of the Congo',
    'Bunda Urban Center, Tanzania, United Republic of': 'Bunda Urban Center, Tanzania',
    'Bunia 10MPC, Uganda-Congo, The Democratic Republic of the': 'Bunia 10MPC, Uganda-Democratic Republic of the Congo',
    'Bunia 5MPC, Congo, The Democratic Republic of the-Uganda': 'Bunia 5MPC, Democratic Republic of the Congo-Uganda',
    'Bunia Urban Center, Congo, The Democratic Republic of the': 'Bunia Urban Center, Democratic Republic of the Congo',
    'Buryatiya, Respublika, Russian Federation': 'Buryatiya, Respublika, Russia',
    'Busan 10MPC, Korea, Republic of': 'Busan 10MPC, South Korea',
    'Busan 20MPC, Korea, Republic of': 'Busan 20MPC, South Korea',
    'Busan 5MPC, Korea, Republic of': 'Busan 5MPC, South Korea',
    'Busan Urban Center, Korea, Republic of': 'Busan Urban Center, South Korea',
    'Busan-gwangyeoksi, Korea, Republic of': 'Busan-gwangyeoksi, South Korea',
    'Bushehr Urban Center, Iran, Islamic Republic of': 'Bushehr Urban Center, Iran',
    'Businga Urban Center, Congo, The Democratic Republic of the': 'Businga Urban Center, Democratic Republic of the Congo',
    'Buta Urban Center, Congo, The Democratic Republic of the': 'Buta Urban Center, Democratic Republic of the Congo',
    'Butembo Urban Center, Congo, The Democratic Republic of the': 'Butembo Urban Center, Democratic Republic of the Congo',
    'Butondo Urban Center, Congo, The Democratic Republic of the': 'Butondo Urban Center, Democratic Republic of the Congo',
    'Butusande Urban Center, Congo, The Democratic Republic of the': 'Butusande Urban Center, Democratic Republic of the Congo',
    'Buynaksk Urban Center, Russian Federation': 'Buynaksk Urban Center, Russia',
    'Buzuluk Urban Center, Russian Federation': 'Buzuluk Urban Center, Russia',
    'Bu\u00f4n Ma Thu\u1ed9t Urban Center, Viet Nam': 'Bu\u00f4n Ma Thu\u1ed9t Urban Center, Vietnam',
    'Bza`a Urban Center, Syrian Arab Republic': 'Bza`a Urban Center, Syria',
    'B\u00e0 R\u1ecba - V\u0169ng T\u00e0u, Viet Nam': 'B\u00e0 R\u1ecba - V\u0169ng T\u00e0u, Vietnam',
    'B\u00ecnh D\u01b0\u01a1ng, Viet Nam': 'B\u00ecnh D\u01b0\u01a1ng, Vietnam',
    'B\u00ecnh Ph\u01b0\u1edbc, Viet Nam': 'B\u00ecnh Ph\u01b0\u1edbc, Vietnam',
    'B\u00ecnh Thu\u1eadn, Viet Nam': 'B\u00ecnh Thu\u1eadn, Vietnam',
    'B\u00ecnh \u0110\u1ecbnh, Viet Nam': 'B\u00ecnh \u0110\u1ecbnh, Vietnam',
    'B\u00fat S\u01a1n Urban Center, Viet Nam': 'B\u00fat S\u01a1n Urban Center, Vietnam',
    'B\u0103l\u021bi Urban Center, Moldova, Republic of': 'B\u0103l\u021bi Urban Center, Moldova',
    'B\u0103l\u021bi, Moldova, Republic of': 'B\u0103l\u021bi, Moldova',
    'B\u016bshehr, Iran, Islamic Republic of': 'B\u016bshehr, Iran',
    'B\u1ea1c Li\u00eau Urban Center, Viet Nam': 'B\u1ea1c Li\u00eau Urban Center, Vietnam',
    'B\u1ea1c Li\u00eau, Viet Nam': 'B\u1ea1c Li\u00eau, Vietnam',
    'B\u1ea3o L\u1ed9c Urban Center, Viet Nam': 'B\u1ea3o L\u1ed9c Urban Center, Vietnam',
    'B\u1eafc Giang Urban Center, Viet Nam': 'B\u1eafc Giang Urban Center, Vietnam',
    'B\u1eafc Giang, Viet Nam': 'B\u1eafc Giang, Vietnam',
    'B\u1eafc K\u1ea1n, Viet Nam': 'B\u1eafc K\u1ea1n, Vietnam',
    'B\u1eafc Ninh Urban Center, Viet Nam': 'B\u1eafc Ninh Urban Center, Vietnam',
    'B\u1eafc Ninh, Viet Nam': 'B\u1eafc Ninh, Vietnam',
    'B\u1ebfn Tre, Viet Nam': 'B\u1ebfn Tre, Vietnam',
    'Ca Mau Urban Center, Viet Nam': 'Ca Mau Urban Center, Vietnam',
    'Cabimas Urban Center, Venezuela, Bolivarian Republic of': 'Cabimas Urban Center, Venezuela',
    'Cabinda 5MPC, Congo, The Democratic Republic of the-Angola-Congo': 'Cabinda 5MPC, Democratic Republic of the Congo-Angola-Congo',
    'Cabo Verde': 'Cape Verde',
    'Cabudare Urban Center, Venezuela, Bolivarian Republic of': 'Cabudare Urban Center, Venezuela',
    'Cahul, Moldova, Republic of': 'Cahul, Moldova',
    'Cai Be Urban Center, Viet Nam': 'Cai Be Urban Center, Vietnam',
    'Cai L\u1eady Urban Center, Viet Nam': 'Cai L\u1eady Urban Center, Vietnam',
    'Cairo 200MPC, Turkey-Egypt-Syrian Arab Republic': 'Cairo 200MPC, Turkey-Egypt-Syria',
    'Cairo 500MPC, Egypt-Turkey-Iran, Islamic Republic of': 'Cairo 500MPC, Egypt-Turkey-Iran',
    'Calabozo Urban Center, Venezuela, Bolivarian Republic of': 'Calabozo Urban Center, Venezuela',
    'Cam Ranh Urban Center, Viet Nam': 'Cam Ranh Urban Center, Vietnam',
    'Can Tho 10MPC, Viet Nam': 'Can Tho 10MPC, Vietnam',
    'Can Tho 5MPC, Viet Nam': 'Can Tho 5MPC, Vietnam',
    'Can Tho Urban Center, Viet Nam': 'Can Tho Urban Center, Vietnam',
    'Cantagalo, Sao Tome and Principe': 'Cantagalo, S\u00e3o Tom\u00e9 and Pr\u00edncipe',
    'Cantemir, Moldova, Republic of': 'Cantemir, Moldova',
    'Cao B\u1eb1ng, Viet Nam': 'Cao B\u1eb1ng, Vietnam',
    'Cao Lanh Urban Center, Viet Nam': 'Cao Lanh Urban Center, Vietnam',
    'Carabobo, Venezuela, Bolivarian Republic of': 'Carabobo, Venezuela',
    'Caracas 10MPC, Venezuela, Bolivarian Republic of': 'Caracas 10MPC, Venezuela',
    'Caracas 20MPC, Venezuela, Bolivarian Republic of': 'Caracas 20MPC, Venezuela',
    'Caracas 5MPC, Venezuela, Bolivarian Republic of': 'Caracas 5MPC, Venezuela',
    'Caracas Urban Center, Venezuela, Bolivarian Republic of': 'Caracas Urban Center, Venezuela',
    'Caranavi Urban Center, Bolivia, Plurinational State of': 'Caranavi Urban Center, Bolivia',
    'Carora Urban Center, Venezuela, Bolivarian Republic of': 'Carora Urban Center, Venezuela',
    'Car\u00fapano Urban Center, Venezuela, Bolivarian Republic of': 'Car\u00fapano Urban Center, Venezuela',
    'Cat Island, Bahamas': 'Cat Island, The Bahamas',
    'Catia La Mar Urban Center, Venezuela, Bolivarian Republic of': 'Catia La Mar Urban Center, Venezuela',
    'Cau\u00e9, Sao Tome and Principe': 'Cau\u00e9, S\u00e3o Tom\u00e9 and Pr\u00edncipe',
    'Central Abaco, Bahamas': 'Central Abaco, The Bahamas',
    'Central Andros, Bahamas': 'Central Andros, The Bahamas',
    'Central Eleuthera, Bahamas': 'Central Eleuthera, The Bahamas',
    'Central Island, Gambia': 'Central Island, The Gambia',
    'Ceylanp\u0131nar Urban Center, Syrian Arab Republic-Turkey': 'Ceylanp\u0131nar Urban Center, Syria-Turkey',
    'Chabahar Urban Center, Iran, Islamic Republic of': 'Chabahar Urban Center, Iran',
    'Chagang-do [Jakangto], Korea, Democratic People\'s Republic of': 'Chagang-do [Jakangto], North Korea',
    'Chah\u0101r Ma\u1e29\u0101l va Bakht\u012b\u0101r\u012b, Iran, Islamic Republic of': 'Chah\u0101r Ma\u1e29\u0101l va Bakht\u012b\u0101r\u012b, Iran',
    'Chalus Urban Center, Iran, Islamic Republic of': 'Chalus Urban Center, Iran',
    'Champasak, Lao People\'s Democratic Republic': 'Champasak, Laos',
    'Changchun 20MPC, China-Russian Federation': 'Changchun 20MPC, China-Russia',
    'Changwon Urban Center, Korea, Republic of': 'Changwon Urban Center, South Korea',
    'Charallave Urban Center, Venezuela, Bolivarian Republic of': 'Charallave Urban Center, Venezuela',
    'Chaudok City Urban Center, Viet Nam': 'Chaudok City Urban Center, Vietnam',
    'Cheboksary 5MPC, Russian Federation': 'Cheboksary 5MPC, Russia',
    'Cheboksary Urban Center, Russian Federation': 'Cheboksary Urban Center, Russia',
    'Chechenskaya Respublika, Russian Federation': 'Chechenskaya Respublika, Russia',
    'Chelyabinsk Urban Center, Russian Federation': 'Chelyabinsk Urban Center, Russia',
    'Chelyabinskaya oblast\', Russian Federation': 'Chelyabinskaya oblast\', Russia',
    'Chenaran Urban Center, Iran, Islamic Republic of': 'Chenaran Urban Center, Iran',
    'Chengdu 200MPC, China-Viet Nam': 'Chengdu 200MPC, China-Vietnam',
    'Cheonan-si Urban Center, Korea, Republic of': 'Cheonan-si Urban Center, South Korea',
    'Cheongju-si Urban Center, Korea, Republic of': 'Cheongju-si Urban Center, South Korea',
    'Cherepovets 5MPC, Russian Federation-Finland': 'Cherepovets 5MPC, Russia-Finland',
    'Cherepovets Urban Center, Russian Federation': 'Cherepovets Urban Center, Russia',
    'Cherkessk Urban Center, Russian Federation': 'Cherkessk Urban Center, Russia',
    'Chernogorsk Urban Center, Russian Federation': 'Chernogorsk Urban Center, Russia',
    'Chita Urban Center, Russian Federation': 'Chita Urban Center, Russia',
    'Chi\u015fin\u0103u, Moldova, Republic of': 'Chi\u015fin\u0103u, Moldova',
    'Chi\u0219in\u0103u 5MPC, Romania-Moldova, Republic of': 'Chi\u0219in\u0103u 5MPC, Romania-Moldova',
    'Chi\u0219in\u0103u Urban Center, Moldova, Republic of': 'Chi\u0219in\u0103u Urban Center, Moldova',
    'Cholsan Urban Center, Korea, Democratic People\'s Republic of': 'Cholsan Urban Center, North Korea',
    'Chongdan Urban Center, Korea, Democratic People\'s Republic of': 'Chongdan Urban Center, North Korea',
    'Chongnam Urban Center, Korea, Democratic People\'s Republic of': 'Chongnam Urban Center, North Korea',
    'Chonnae Urban Center, Korea, Democratic People\'s Republic of': 'Chonnae Urban Center, North Korea',
    'Chowol-eup Urban Center, Korea, Republic of': 'Chowol-eup Urban Center, South Korea',
    'Chukotskiy avtonomnyy okrug, Russian Federation': 'Chukotskiy avtonomnyy okrug, Russia',
    'Chuncheon-si Urban Center, Korea, Republic of': 'Chuncheon-si Urban Center, South Korea',
    'Chungcheongbuk-do, Korea, Republic of': 'Chungcheongbuk-do, South Korea',
    'Chungcheongnam-do, Korea, Republic of': 'Chungcheongnam-do, South Korea',
    'Chungju-si Urban Center, Korea, Republic of': 'Chungju-si Urban Center, South Korea',
    'Chuquisaca, Bolivia, Plurinational State of': 'Chuquisaca, Bolivia',
    'Chuuk, Micronesia, Federated States of': 'Chuuk, Micronesia',
    'Chuvashskaya Respublika, Russian Federation': 'Chuvashskaya Respublika, Russia',
    'Ch\u014fngjin 10MPC, Japan-China-Korea, Democratic People\'s Republic of': 'Ch\u014fngjin 10MPC, Japan-China-North Korea',
    'Ch\u014fngjin 5MPC, China-Korea, Democratic People\'s Republic of-Russian Federation': 'Ch\u014fngjin 5MPC, China-North Korea-Russia',
    'Ch\u014fngjin Urban Center, Korea, Democratic People\'s Republic of': 'Ch\u014fngjin Urban Center, North Korea',
    'Ch\u0169 Urban Center, Viet Nam': 'Ch\u0169 Urban Center, Vietnam',
    'Ch\u01b0 S\u00ea Urban Center, Viet Nam': 'Ch\u01b0 S\u00ea Urban Center, Vietnam',
    'Cimi\u0219lia, Moldova, Republic of': 'Cimi\u0219lia, Moldova',
    'City of Freeport, Bahamas': 'City of Freeport, The Bahamas',
    'Ciudad Bolivia Urban Center, Venezuela, Bolivarian Republic of': 'Ciudad Bolivia Urban Center, Venezuela',
    'Ciudad Bol\u00edvar Urban Center, Venezuela, Bolivarian Republic of': 'Ciudad Bol\u00edvar Urban Center, Venezuela',
    'Ciudad Guayana Urban Center, Venezuela, Bolivarian Republic of': 'Ciudad Guayana Urban Center, Venezuela',
    'Ciudad Ojeda 5MPC, Venezuela, Bolivarian Republic of-USA-France': 'Ciudad Ojeda 5MPC, Venezuela-USA-France',
    'Ciudad Ojeda Urban Center, Venezuela, Bolivarian Republic of': 'Ciudad Ojeda Urban Center, Venezuela',
    'Cobija Urban Center, Bolivia, Plurinational State of-Brazil': 'Cobija Urban Center, Bolivia-Brazil',
    'Cochabamba Urban Center, Bolivia, Plurinational State of': 'Cochabamba Urban Center, Bolivia',
    'Cochabamba, Bolivia, Plurinational State of': 'Cochabamba, Bolivia',
    'Cojedes, Venezuela, Bolivarian Republic of': 'Cojedes, Venezuela',
    'Como\u00e9, C\u00f4te d\'Ivoire': 'Como\u00e9, Ivory Coast',
    'Congo, The Democratic Republic of the': 'Democratic Republic of the Congo',
    'Coro Urban Center, Venezuela, Bolivarian Republic of': 'Coro Urban Center, Venezuela',
    'Cova Lima, Timor-Leste': 'Cova Lima, East Timor',
    'Criuleni, Moldova, Republic of': 'Criuleni, Moldova',
    'Crooked Island and Long Cay, Bahamas': 'Crooked Island and Long Cay, The Bahamas',
    'Cumana Urban Center, Venezuela, Bolivarian Republic of': 'Cumana Urban Center, Venezuela',
    'Czechia': 'Czech Republic',
    'Cz\u0119stochowa 5MPC, Czechia-Poland-Slovakia': 'Cz\u0119stochowa 5MPC, Czech Republic-Poland-Slovakia',
    'C\u00e0 Mau, Viet Nam': 'C\u00e0 Mau, Vietnam',
    'C\u00e1i N\u01b0\u1edbc Urban Center, Viet Nam': 'C\u00e1i N\u01b0\u1edbc Urban Center, Vietnam',
    'C\u00e1i \u0110\u00f4i V\u00e0m Urban Center, Viet Nam': 'C\u00e1i \u0110\u00f4i V\u00e0m Urban Center, Vietnam',
    'C\u00e2y X\u0103ng Urban Center, Viet Nam': 'C\u00e2y X\u0103ng Urban Center, Vietnam',
    'C\u00f4te d\'Ivoire': 'Ivory Coast',
    'C\u00faa Urban Center, Venezuela, Bolivarian Republic of': 'C\u00faa Urban Center, Venezuela',
    'C\u00facuta Urban Center, Colombia-Venezuela, Bolivarian Republic of': 'C\u00facuta Urban Center, Colombia-Venezuela',
    'C\u0103l\u0103ra\u0219i, Moldova, Republic of': 'C\u0103l\u0103ra\u0219i, Moldova',
    'C\u0103u\u0219eni, Moldova, Republic of': 'C\u0103u\u0219eni, Moldova',
    'C\u1ea7n Th\u01a1, Viet Nam': 'C\u1ea7n Th\u01a1, Vietnam',
    'C\u1ea9m Ph\u1ea3 10MPC, China-Myanmar-Viet Nam': 'C\u1ea9m Ph\u1ea3 10MPC, China-Myanmar-Vietnam',
    'C\u1ea9m Ph\u1ea3 Urban Center, Viet Nam': 'C\u1ea9m Ph\u1ea3 Urban Center, Vietnam',
    'C\u1ee7 Chi Urban Center, Viet Nam': 'C\u1ee7 Chi Urban Center, Vietnam',
    'DAR ES SALAAM Urban Center, Tanzania, United Republic of': 'DAR ES SALAAM Urban Center, Tanzania',
    'Dabou Urban Center, C\u00f4te d\'Ivoire': 'Dabou Urban Center, Ivory Coast',
    'Daegu 5MPC, Korea, Republic of': 'Daegu 5MPC, South Korea',
    'Daegu Urban Center, Korea, Republic of': 'Daegu Urban Center, South Korea',
    'Daegu-gwangyeoksi, Korea, Republic of': 'Daegu-gwangyeoksi, South Korea',
    'Daejeon 10MPC, Korea, Republic of': 'Daejeon 10MPC, South Korea',
    'Daejeon 5MPC, Korea, Republic of': 'Daejeon 5MPC, South Korea',
    'Daejeon Urban Center, Korea, Republic of': 'Daejeon Urban Center, South Korea',
    'Daejeon-gwangyeoksi, Korea, Republic of': 'Daejeon-gwangyeoksi, South Korea',
    'Dagestan, Respublika, Russian Federation': 'Dagestan, Respublika, Russia',
    'Dakar 20MPC, Senegal-Gambia': 'Dakar 20MPC, Senegal-The Gambia',
    'Dalat Urban Center, Viet Nam': 'Dalat Urban Center, Vietnam',
    'Dalian (Outer) 5MPC, China-Korea, Democratic People\'s Republic of': 'Dalian (Outer) 5MPC, China-North Korea',
    'Dalian 50MPC, China-Korea, Democratic People\'s Republic of': 'Dalian 50MPC, China-North Korea',
    'Daloa 5MPC, C\u00f4te d\'Ivoire': 'Daloa 5MPC, Ivory Coast',
    'Daloa Urban Center, C\u00f4te d\'Ivoire': 'Daloa Urban Center, Ivory Coast',
    'Damascus 10MPC, Syrian Arab Republic-Lebanon-Israel': 'Damascus 10MPC, Syria-Lebanon-Israel',
    'Damascus 20MPC, Syrian Arab Republic-Lebanon': 'Damascus 20MPC, Syria-Lebanon',
    'Damascus 50MPC, Syrian Arab Republic-Turkey-Lebanon': 'Damascus 50MPC, Syria-Turkey-Lebanon',
    'Damascus 5MPC, Syrian Arab Republic-Lebanon': 'Damascus 5MPC, Syria-Lebanon',
    'Damascus Urban Center, Syrian Arab Republic': 'Damascus Urban Center, Syria',
    'Danan\u00e9 Urban Center, C\u00f4te d\'Ivoire': 'Danan\u00e9 Urban Center, Ivory Coast',
    'Dandong 10MPC, Korea, Democratic People\'s Republic of-China': 'Dandong 10MPC, North Korea-China',
    'Dandong 20MPC, China-Korea, Democratic People\'s Republic of': 'Dandong 20MPC, China-North Korea',
    'Dandong 5MPC, China-Korea, Democratic People\'s Republic of': 'Dandong 5MPC, China-North Korea',
    'Dandong Urban Center, China-Korea, Democratic People\'s Republic of': 'Dandong Urban Center, China-North Korea',
    'Daoukro Urban Center, C\u00f4te d\'Ivoire': 'Daoukro Urban Center, Ivory Coast',
    'Dar es Salaam (Center) 5MPC, Tanzania, United Republic of': 'Dar es Salaam (Center) 5MPC, Tanzania',
    'Dar es Salaam (Outer) 5MPC, Tanzania, United Republic of': 'Dar es Salaam (Outer) 5MPC, Tanzania',
    'Dar es Salaam 10MPC, Tanzania, United Republic of': 'Dar es Salaam 10MPC, Tanzania',
    'Dar es Salaam 200MPC, Ethiopia-Tanzania, United Republic of-Cameroon': 'Dar es Salaam 200MPC, Ethiopia-Tanzania-Cameroon',
    'Dar es Salaam 20MPC, Tanzania, United Republic of-Kenya': 'Dar es Salaam 20MPC, Tanzania-Kenya',
    'Dar es Salaam 50MPC, South Africa-Angola-Tanzania, United Republic of': 'Dar es Salaam 50MPC, South Africa-Angola-Tanzania',
    'Dar es Salaam Urban Center, Tanzania, United Republic of': 'Dar es Salaam Urban Center, Tanzania',
    'Dar es Salaam, Tanzania, United Republic of': 'Dar es Salaam, Tanzania',
    'Darab Urban Center, Iran, Islamic Republic of': 'Darab Urban Center, Iran',
    'Dargaz Urban Center, Iran, Islamic Republic of': 'Dargaz Urban Center, Iran',
    'Dar\u0670\u0101, Syrian Arab Republic': 'Dar\u0670\u0101, Syria',
    'Dayr az Zawr, Syrian Arab Republic': 'Dayr az Zawr, Syria',
    'Dehdasht Urban Center, Iran, Islamic Republic of': 'Dehdasht Urban Center, Iran',
    'Deir El Balah, Palestine, State of': 'Deir El Balah, State of Palestine',
    'Deir Ez Zor 5MPC, Syrian Arab Republic-Turkey': 'Deir Ez Zor 5MPC, Syria-Turkey',
    'Deir Ez Zor Urban Center, Syrian Arab Republic': 'Deir Ez Zor Urban Center, Syria',
    'Delta Amacuro, Venezuela, Bolivarian Republic of': 'Delta Amacuro, Venezuela',
    'Demba Urban Center, Congo, The Democratic Republic of the': 'Demba Urban Center, Democratic Republic of the Congo',
    'Dengu\u00e9l\u00e9, C\u00f4te d\'Ivoire': 'Dengu\u00e9l\u00e9, Ivory Coast',
    'Denizli 10MPC, Turkey-Syrian Arab Republic-Cyprus': 'Denizli 10MPC, Turkey-Syria-Cyprus',
    'Dependencias Federales, Venezuela, Bolivarian Republic of': 'Dependencias Federales, Venezuela',
    'Derbent Urban Center, Russian Federation': 'Derbent Urban Center, Russia',
    'Dezful Urban Center, Iran, Islamic Republic of': 'Dezful Urban Center, Iran',
    'Dien Bien Phu Urban Center, Viet Nam': 'Dien Bien Phu Urban Center, Vietnam',
    'Dili Urban Center, Timor-Leste': 'Dili Urban Center, East Timor',
    'Dilolo Urban Center, Congo, The Democratic Republic of the': 'Dilolo Urban Center, Democratic Republic of the Congo',
    'Dimashq, Syrian Arab Republic': 'Dimashq, Syria',
    'Dimitrovgrad Urban Center, Russian Federation': 'Dimitrovgrad Urban Center, Russia',
    'Distrito Federal, Venezuela, Bolivarian Republic of': 'Distrito Federal, Venezuela',
    'Divandarreh Urban Center, Iran, Islamic Republic of': 'Divandarreh Urban Center, Iran',
    'Divo Urban Center, C\u00f4te d\'Ivoire': 'Divo Urban Center, Ivory Coast',
    'Di\u00e9gon\u00e9fla Urban Center, C\u00f4te d\'Ivoire': 'Di\u00e9gon\u00e9fla Urban Center, Ivory Coast',
    'Di\u1ec5n Ch\u00e2u Urban Center, Viet Nam': 'Di\u1ec5n Ch\u00e2u Urban Center, Vietnam',
    'Do Luong Urban Center, Viet Nam': 'Do Luong Urban Center, Vietnam',
    'Dodoma 10MPC, Tanzania, United Republic of': 'Dodoma 10MPC, Tanzania',
    'Dodoma 5MPC, Tanzania, United Republic of': 'Dodoma 5MPC, Tanzania',
    'Dodoma Urban Center, Tanzania, United Republic of': 'Dodoma Urban Center, Tanzania',
    'Dodoma, Tanzania, United Republic of': 'Dodoma, Tanzania',
    'Dogonbadan Urban Center, Iran, Islamic Republic of': 'Dogonbadan Urban Center, Iran',
    'Doha 100MPC, Russian Federation-India-Yemen': 'Doha 100MPC, Russia-India-Yemen',
    'Doha 10MPC, Iran, Islamic Republic of-Qatar-Saudi Arabia': 'Doha 10MPC, Iran-Qatar-Saudi Arabia',
    'Doha 20MPC, Iran, Islamic Republic of-Qatar-Bahrain': 'Doha 20MPC, Iran-Qatar-Bahrain',
    'Doha 5MPC, Qatar-Saudi Arabia-Iran, Islamic Republic of': 'Doha 5MPC, Qatar-Saudi Arabia-Iran',
    'Dolatabad Urban Center, Iran, Islamic Republic of': 'Dolatabad Urban Center, Iran',
    'Dondu\u0219eni, Moldova, Republic of': 'Dondu\u0219eni, Moldova',
    'Dong Ha Urban Center, Viet Nam': 'Dong Ha Urban Center, Vietnam',
    'Dongducheon-si Urban Center, Korea, Republic of': 'Dongducheon-si Urban Center, South Korea',
    'Dorud Urban Center, Iran, Islamic Republic of': 'Dorud Urban Center, Iran',
    'Drochia, Moldova, Republic of': 'Drochia, Moldova',
    'Duaca Urban Center, Venezuela, Bolivarian Republic of': 'Duaca Urban Center, Venezuela',
    'Dubai 20MPC, Iran, Islamic Republic of-United Arab Emirates-Oman': 'Dubai 20MPC, Iran-United Arab Emirates-Oman',
    'Dub\u0103sari, Moldova, Republic of': 'Dub\u0103sari, Moldova',
    'Duekoue Urban Center, C\u00f4te d\'Ivoire': 'Duekoue Urban Center, Ivory Coast',
    'Dungu Urban Center, Congo, The Democratic Republic of the': 'Dungu Urban Center, Democratic Republic of the Congo',
    'Dzerzhinsk Urban Center, Russian Federation': 'Dzerzhinsk Urban Center, Russia',
    'D\u00edli, Timor-Leste': 'D\u00edli, East Timor',
    'Ea Kar Urban Center, Viet Nam': 'Ea Kar Urban Center, Vietnam',
    'East Grand Bahama, Bahamas': 'East Grand Bahama, The Bahamas',
    'Edine\u0163, Moldova, Republic of': 'Edine\u0163, Moldova',
    'El Alto [La Paz] 10MPC, Bolivia, Plurinational State of': 'El Alto [La Paz] 10MPC, Bolivia',
    'El Alto [La Paz] 20MPC, Bolivia, Plurinational State of-Peru': 'El Alto [La Paz] 20MPC, Bolivia-Peru',
    'El Alto [La Paz] 5MPC, Bolivia, Plurinational State of': 'El Alto [La Paz] 5MPC, Bolivia',
    'El Alto [La Paz] Urban Center, Bolivia, Plurinational State of': 'El Alto [La Paz] Urban Center, Bolivia',
    'El Beni, Bolivia, Plurinational State of': 'El Beni, Bolivia',
    'El Tocuyo Urban Center, Venezuela, Bolivarian Republic of': 'El Tocuyo Urban Center, Venezuela',
    'El Vig\u00eda Urban Center, Venezuela, Bolivarian Republic of': 'El Vig\u00eda Urban Center, Venezuela',
    'Elektrostal Urban Center, Russian Federation': 'Elektrostal Urban Center, Russia',
    'Elila Urban Center, Congo, The Democratic Republic of the': 'Elila Urban Center, Democratic Republic of the Congo',
    'Elista Urban Center, Russian Federation': 'Elista Urban Center, Russia',
    'Engels Urban Center, Russian Federation': 'Engels Urban Center, Russia',
    'Ermera, Timor-Leste': 'Ermera, East Timor',
    'Esfarayen Urban Center, Iran, Islamic Republic of': 'Esfarayen Urban Center, Iran',
    'Eslamabad-e Gharb Urban Center, Iran, Islamic Republic of': 'Eslamabad-e Gharb Urban Center, Iran',
    'Exuma, Bahamas': 'Exuma, The Bahamas',
    'E\u015ffah\u0101n, Iran, Islamic Republic of': 'E\u015ffah\u0101n, Iran',
    'Falc\u00f3n, Venezuela, Bolivarian Republic of': 'Falc\u00f3n, Venezuela',
    'Falkland Islands (Malvinas)': 'Falkland Islands',
    'Falkland Islands (Malvinas) (UK), Falkland Islands (Malvinas)': 'Falkland Islands (Malvinas) (UK), Falkland Islands',
    'Farafenni Urban Center, Gambia': 'Farafenni Urban Center, The Gambia',
    'Fasa Urban Center, Iran, Islamic Republic of': 'Fasa Urban Center, Iran',
    'Ferk\u00e9ss\u00e9dougou Urban Center, C\u00f4te d\'Ivoire': 'Ferk\u00e9ss\u00e9dougou Urban Center, Ivory Coast',
    'Firuzabad Urban Center, Iran, Islamic Republic of': 'Firuzabad Urban Center, Iran',
    'Flore\u0219ti, Moldova, Republic of': 'Flore\u0219ti, Moldova',
    'Fomboni 5MPC, Madagascar-Tanzania, United Republic of-Comoros': 'Fomboni 5MPC, Madagascar-Tanzania-Comoros',
    'Fryazino Urban Center, Russian Federation': 'Fryazino Urban Center, Russia',
    'Fuladshahr Urban Center, Iran, Islamic Republic of': 'Fuladshahr Urban Center, Iran',
    'Fuman Urban Center, Iran, Islamic Republic of': 'Fuman Urban Center, Iran',
    'Fungurume Urban Center, Congo, The Democratic Republic of the': 'Fungurume Urban Center, Democratic Republic of the Congo',
    'F\u0101rs, Iran, Islamic Republic of': 'F\u0101rs, Iran',
    'F\u0103le\u0219ti, Moldova, Republic of': 'F\u0103le\u0219ti, Moldova',
    'Gagnoa Urban Center, C\u00f4te d\'Ivoire': 'Gagnoa Urban Center, Ivory Coast',
    'Gambia': 'The Gambia',
    'Gangneung-si Urban Center, Korea, Republic of': 'Gangneung-si Urban Center, South Korea',
    'Gangwon-do, Korea, Republic of': 'Gangwon-do, South Korea',
    'Ganja 5MPC, Azerbaijan-Iran, Islamic Republic of': 'Ganja 5MPC, Azerbaijan-Iran',
    'Garissa 10MPC, Kenya-Tanzania, United Republic of': 'Garissa 10MPC, Kenya-Tanzania',
    'Gaza 10MPC, Egypt-Palestine, State of-Israel': 'Gaza 10MPC, Egypt-State of Palestine-Israel',
    'Gaza 20MPC, Egypt-Palestine, State of-Turkey': 'Gaza 20MPC, Egypt-State of Palestine-Turkey',
    'Gaza 5MPC, Palestine, State of-Israel': 'Gaza 5MPC, State of Palestine-Israel',
    'Gaza Urban Center, Palestine, State of': 'Gaza Urban Center, State of Palestine',
    'Gaza, Palestine, State of': 'Gaza, State of Palestine',
    'Gbadolite Urban Center, Congo, The Democratic Republic of the': 'Gbadolite Urban Center, Democratic Republic of the Congo',
    'Geita Urban Center, Tanzania, United Republic of': 'Geita Urban Center, Tanzania',
    'Geita, Tanzania, United Republic of': 'Geita, Tanzania',
    'Gemena Urban Center, Congo, The Democratic Republic of the': 'Gemena Urban Center, Democratic Republic of the Congo',
    'Geoje-si Urban Center, Korea, Republic of': 'Geoje-si Urban Center, South Korea',
    'Georgiyevsk Urban Center, Russian Federation': 'Georgiyevsk Urban Center, Russia',
    'Geumseong-myeon Urban Center, Korea, Republic of': 'Geumseong-myeon Urban Center, South Korea',
    'Gheydar Urban Center, Iran, Islamic Republic of': 'Gheydar Urban Center, Iran',
    'Gia Ki\u1ec7m Urban Center, Viet Nam': 'Gia Ki\u1ec7m Urban Center, Vietnam',
    'Gia Lai, Viet Nam': 'Gia Lai, Vietnam',
    'Gia Rai Urban Center, Viet Nam': 'Gia Rai Urban Center, Vietnam',
    'Gilan Tappeh Urban Center, Iran, Islamic Republic of': 'Gilan Tappeh Urban Center, Iran',
    'Gi\u1ed3ng Ri\u1ec1ng Urban Center, Viet Nam': 'Gi\u1ed3ng Ri\u1ec1ng Urban Center, Vietnam',
    'Glazov Urban Center, Russian Federation': 'Glazov Urban Center, Russia',
    'Glodeni, Moldova, Republic of': 'Glodeni, Moldova',
    'Go Cong Urban Center, Viet Nam': 'Go Cong Urban Center, Vietnam',
    'Golest\u0101n, Iran, Islamic Republic of': 'Golest\u0101n, Iran',
    'Goma 10MPC, Congo, The Democratic Republic of the-Tanzania, United Republic of-Burundi': 'Goma 10MPC, Democratic Republic of the Congo-Tanzania-Burundi',
    'Goma 5MPC, Congo, The Democratic Republic of the-Rwanda': 'Goma 5MPC, Democratic Republic of the Congo-Rwanda',
    'Goma Urban Center, Congo, The Democratic Republic of the-Rwanda': 'Goma Urban Center, Democratic Republic of the Congo-Rwanda',
    'Gonbad-e Qabus Urban Center, Iran, Islamic Republic of': 'Gonbad-e Qabus Urban Center, Iran',
    'Gorgan Urban Center, Iran, Islamic Republic of': 'Gorgan Urban Center, Iran',
    'Grand Cay, Bahamas': 'Grand Cay, The Bahamas',
    'Grand-Bassam Urban Center, C\u00f4te d\'Ivoire': 'Grand-Bassam Urban Center, Ivory Coast',
    'Grozny Urban Center, Russian Federation': 'Grozny Urban Center, Russia',
    'Guanare Urban Center, Venezuela, Bolivarian Republic of': 'Guanare Urban Center, Venezuela',
    'Guarenas 5MPC, Venezuela, Bolivarian Republic of': 'Guarenas 5MPC, Venezuela',
    'Guarenas Urban Center, Venezuela, Bolivarian Republic of': 'Guarenas Urban Center, Venezuela',
    'Guiglo Urban Center, C\u00f4te d\'Ivoire': 'Guiglo Urban Center, Ivory Coast',
    'Gumi-si 5MPC, Korea, Republic of': 'Gumi-si 5MPC, South Korea',
    'Gumi-si Urban Center, Korea, Republic of': 'Gumi-si Urban Center, South Korea',
    'Gunsan-si Urban Center, Korea, Republic of': 'Gunsan-si Urban Center, South Korea',
    'Gu\u00e1rico, Venezuela, Bolivarian Republic of': 'Gu\u00e1rico, Venezuela',
    'Gwangju 20MPC, Korea, Republic of-Japan-Korea, Democratic People\'s Republic of': 'Gwangju 20MPC, South Korea-Japan-North Korea',
    'Gwangju 5MPC, Korea, Republic of': 'Gwangju 5MPC, South Korea',
    'Gwangju Urban Center, Korea, Republic of': 'Gwangju Urban Center, South Korea',
    'Gwangju-gwangyeoksi, Korea, Republic of': 'Gwangju-gwangyeoksi, South Korea',
    'Gwangju-si Urban Center, Korea, Republic of': 'Gwangju-si Urban Center, South Korea',
    'Gyeonggi-do, Korea, Republic of': 'Gyeonggi-do, South Korea',
    'Gyeongju-si Urban Center, Korea, Republic of': 'Gyeongju-si Urban Center, South Korea',
    'Gyeongsangbuk-do, Korea, Republic of': 'Gyeongsangbuk-do, South Korea',
    'Gyeongsangnam-do, Korea, Republic of': 'Gyeongsangnam-do, South Korea',
    'G\u00e0nh H\u00e0o Urban Center, Viet Nam': 'G\u00e0nh H\u00e0o Urban Center, Vietnam',
    'G\u00f4h-Djiboua, C\u00f4te d\'Ivoire': 'G\u00f4h-Djiboua, Ivory Coast',
    'G\u00fcig\u00fce Urban Center, Venezuela, Bolivarian Republic of': 'G\u00fcig\u00fce Urban Center, Venezuela',
    'G\u0103g\u0103uzia, Unitate Teritorial\u0103 Autonom\u0103 (UTAG), Moldova, Republic of': 'G\u0103g\u0103uzia, Unitate Teritorial\u0103 Autonom\u0103 (UTAG), Moldova',
    'G\u012bl\u0101n, Iran, Islamic Republic of': 'G\u012bl\u0101n, Iran',
    'Ha Long Urban Center, Viet Nam': 'Ha Long Urban Center, Vietnam',
    'Haeju 5MPC, Japan-Korea, Republic of-Korea, Democratic People\'s Republic of': 'Haeju 5MPC, Japan-South Korea-North Korea',
    'Haeju Urban Center, Korea, Democratic People\'s Republic of': 'Haeju Urban Center, North Korea',
    'Haifa 5MPC, Israel-Palestine, State of-Jordan': 'Haifa 5MPC, Israel-State of Palestine-Jordan',
    'Haikou 50MPC, Viet Nam-China-Thailand': 'Haikou 50MPC, Vietnam-China-Thailand',
    'Haiphong 10MPC, Viet Nam': 'Haiphong 10MPC, Vietnam',
    'Haiphong 5MPC, Viet Nam': 'Haiphong 5MPC, Vietnam',
    'Haiphong Urban Center, Viet Nam': 'Haiphong Urban Center, Vietnam',
    'Halfaya Urban Center, Syrian Arab Republic': 'Halfaya Urban Center, Syria',
    'Hama Urban Center, Syrian Arab Republic': 'Hama Urban Center, Syria',
    'Hamad\u0101n, Iran, Islamic Republic of': 'Hamad\u0101n, Iran',
    'Hamamatsu 5MPC, Japan-China-Russian Federation': 'Hamamatsu 5MPC, Japan-China-Russia',
    'Hamedan Urban Center, Iran, Islamic Republic of': 'Hamedan Urban Center, Iran',
    'Hamgy\u014fng-bukto [Hamkyeongpukto], Korea, Democratic People\'s Republic of': 'Hamgy\u014fng-bukto [Hamkyeongpukto], North Korea',
    'Hamgy\u014fng-namdo [Hamkyeongnamto], Korea, Democratic People\'s Republic of': 'Hamgy\u014fng-namdo [Hamkyeongnamto], North Korea',
    'Hamhung 10MPC, Korea, Democratic People\'s Republic of-China': 'Hamhung 10MPC, North Korea-China',
    'Hamhung 20MPC, China-Korea, Democratic People\'s Republic of': 'Hamhung 20MPC, China-North Korea',
    'Hamhung 5MPC, Korea, Democratic People\'s Republic of': 'Hamhung 5MPC, North Korea',
    'Hamhung Urban Center, Korea, Democratic People\'s Republic of': 'Hamhung Urban Center, North Korea',
    'Hamju Urban Center, Korea, Democratic People\'s Republic of': 'Hamju Urban Center, North Korea',
    'Hanoi (Center) 5MPC, Viet Nam': 'Hanoi (Center) 5MPC, Vietnam',
    'Hanoi (Outer) 5MPC, Viet Nam': 'Hanoi (Outer) 5MPC, Vietnam',
    'Hanoi 100MPC, China-Viet Nam': 'Hanoi 100MPC, China-Vietnam',
    'Hanoi 10MPC, Viet Nam': 'Hanoi 10MPC, Vietnam',
    'Hanoi 20MPC, Viet Nam': 'Hanoi 20MPC, Vietnam',
    'Hanoi 50MPC, Viet Nam-China': 'Hanoi 50MPC, Vietnam-China',
    'Hanoi Urban Center, Viet Nam': 'Hanoi Urban Center, Vietnam',
    'Harbour Island, Bahamas': 'Harbour Island, The Bahamas',
    'Hashtgerd Urban Center, Iran, Islamic Republic of': 'Hashtgerd Urban Center, Iran',
    'Haut-Katanga, Congo, The Democratic Republic of the': 'Haut-Katanga, Democratic Republic of the Congo',
    'Haut-Lomami, Congo, The Democratic Republic of the': 'Haut-Lomami, Democratic Republic of the Congo',
    'Haut-U\u00e9l\u00e9, Congo, The Democratic Republic of the': 'Haut-U\u00e9l\u00e9, Democratic Republic of the Congo',
    'Hav\u00ed\u0159ov Urban Center, Czechia': 'Hav\u00ed\u0159ov Urban Center, Czech Republic',
    'Hebron Urban Center, Palestine, State of': 'Hebron Urban Center, State of Palestine',
    'Hebron, Palestine, State of': 'Hebron, State of Palestine',
    'Helsinki 5MPC, Finland-Russian Federation-Estonia': 'Helsinki 5MPC, Finland-Russia-Estonia',
    'Herat (South) 5MPC, Afghanistan-Iran, Islamic Republic of': 'Herat (South) 5MPC, Afghanistan-Iran',
    'Hi\u1ec7p Ph\u01b0\u1edbc Urban Center, Viet Nam': 'Hi\u1ec7p Ph\u01b0\u1edbc Urban Center, Vietnam',
    'Ho Chi Minh City (Center) 10MPC, Viet Nam': 'Ho Chi Minh City (Center) 10MPC, Vietnam',
    'Ho Chi Minh City (Center) 5MPC, Viet Nam': 'Ho Chi Minh City (Center) 5MPC, Vietnam',
    'Ho Chi Minh City (Outer) 10MPC, Viet Nam': 'Ho Chi Minh City (Outer) 10MPC, Vietnam',
    'Ho Chi Minh City (Outer) 5MPC, Viet Nam': 'Ho Chi Minh City (Outer) 5MPC, Vietnam',
    'Ho Chi Minh City (Periphery) 5MPC, Viet Nam': 'Ho Chi Minh City (Periphery) 5MPC, Vietnam',
    'Ho Chi Minh City 20MPC, Viet Nam': 'Ho Chi Minh City 20MPC, Vietnam',
    'Ho Chi Minh City 50MPC, Viet Nam-Cambodia': 'Ho Chi Minh City 50MPC, Vietnam-Cambodia',
    'Ho Chi Minh City Urban Center, Viet Nam': 'Ho Chi Minh City Urban Center, Vietnam',
    'Hoechang Urban Center, Korea, Democratic People\'s Republic of': 'Hoechang Urban Center, North Korea',
    'Hoeryong Urban Center, Korea, Democratic People\'s Republic of': 'Hoeryong Urban Center, North Korea',
    'Hoi An Urban Center, Viet Nam': 'Hoi An Urban Center, Vietnam',
    'Holy See (Vatican City State)': 'Vatican City',
    'Holy See (Vatican City State), Holy See (Vatican City State)': 'Holy See (Vatican City State), Vatican City',
    'Homel 10MPC, Russian Federation-Belarus-Ukraine': 'Homel 10MPC, Russia-Belarus-Ukraine',
    'Homel 5MPC, Belarus-Russian Federation-Ukraine': 'Homel 5MPC, Belarus-Russia-Ukraine',
    'Homs 10MPC, Syrian Arab Republic-Turkey-Lebanon': 'Homs 10MPC, Syria-Turkey-Lebanon',
    'Homs 5MPC, Syrian Arab Republic-Lebanon': 'Homs 5MPC, Syria-Lebanon',
    'Homs Urban Center, Syrian Arab Republic': 'Homs Urban Center, Syria',
    'Hong Ngu 5MPC, Cambodia-Viet Nam': 'Hong Ngu 5MPC, Cambodia-Vietnam',
    'Hong Ngu Urban Center, Viet Nam': 'Hong Ngu Urban Center, Vietnam',
    'Hongwon Urban Center, Korea, Democratic People\'s Republic of': 'Hongwon Urban Center, North Korea',
    'Hope Town, Bahamas': 'Hope Town, The Bahamas',
    'Hormozg\u0101n, Iran, Islamic Republic of': 'Hormozg\u0101n, Iran',
    'Houaphan, Lao People\'s Democratic Republic': 'Houaphan, Laos',
    'Ho\u00e0n L\u00e3o Urban Center, Viet Nam': 'Ho\u00e0n L\u00e3o Urban Center, Vietnam',
    'Hradec Kr\u00e1lov\u00e9 Urban Center, Czechia': 'Hradec Kr\u00e1lov\u00e9 Urban Center, Czech Republic',
    'Hue 20MPC, Viet Nam-Myanmar-Thailand': 'Hue 20MPC, Vietnam-Myanmar-Thailand',
    'Hue Urban Center, Viet Nam': 'Hue Urban Center, Vietnam',
    'Huichon Urban Center, Korea, Democratic People\'s Republic of': 'Huichon Urban Center, North Korea',
    'Hwado-eup Urban Center, Korea, Republic of': 'Hwado-eup Urban Center, South Korea',
    'Hwanghae-bukto [Hwanghaipukto], Korea, Democratic People\'s Republic of': 'Hwanghae-bukto [Hwanghaipukto], North Korea',
    'Hwanghae-namdo [Hwanghainamto], Korea, Democratic People\'s Republic of': 'Hwanghae-namdo [Hwanghainamto], North Korea',
    'Hyesan Urban Center, China-Korea, Democratic People\'s Republic of': 'Hyesan Urban Center, China-North Korea',
    'H\u00e0 Giang, Viet Nam': 'H\u00e0 Giang, Vietnam',
    'H\u00e0 Nam, Viet Nam': 'H\u00e0 Nam, Vietnam',
    'H\u00e0 N\u1ed9i, Viet Nam': 'H\u00e0 N\u1ed9i, Vietnam',
    'H\u00e0 T\u0129nh Urban Center, Viet Nam': 'H\u00e0 T\u0129nh Urban Center, Vietnam',
    'H\u00e0 T\u0129nh, Viet Nam': 'H\u00e0 T\u0129nh, Vietnam',
    'H\u00eence\u0219ti, Moldova, Republic of': 'H\u00eence\u0219ti, Moldova',
    'H\u00f2a B\u00ecnh Urban Center, Viet Nam': 'H\u00f2a B\u00ecnh Urban Center, Vietnam',
    'H\u00f2a B\u00ecnh, Viet Nam': 'H\u00f2a B\u00ecnh, Vietnam',
    'H\u01b0ng Y\u00ean Urban Center, Viet Nam': 'H\u01b0ng Y\u00ean Urban Center, Vietnam',
    'H\u01b0ng Y\u00ean, Viet Nam': 'H\u01b0ng Y\u00ean, Vietnam',
    'H\u1ea3i D\u01b0\u01a1ng Urban Center, Viet Nam': 'H\u1ea3i D\u01b0\u01a1ng Urban Center, Vietnam',
    'H\u1ea3i D\u01b0\u01a1ng, Viet Nam': 'H\u1ea3i D\u01b0\u01a1ng, Vietnam',
    'H\u1ea3i Ph\u00f2ng, Viet Nam': 'H\u1ea3i Ph\u00f2ng, Vietnam',
    'H\u1eadu Giang, Viet Nam': 'H\u1eadu Giang, Vietnam',
    'H\u1ed3 Ch\u00ed Minh, Viet Nam': 'H\u1ed3 Ch\u00ed Minh, Vietnam',
    'Ialoveni, Moldova, Republic of': 'Ialoveni, Moldova',
    'Idiofa Urban Center, Congo, The Democratic Republic of the': 'Idiofa Urban Center, Democratic Republic of the Congo',
    'Idlib Urban Center, Syrian Arab Republic': 'Idlib Urban Center, Syria',
    'Idlib, Syrian Arab Republic': 'Idlib, Syria',
    'Ifakara Urban Center, Tanzania, United Republic of': 'Ifakara Urban Center, Tanzania',
    'Iga Barriere Urban Center, Congo, The Democratic Republic of the': 'Iga Barriere Urban Center, Democratic Republic of the Congo',
    'Igunga Urban Center, Tanzania, United Republic of': 'Igunga Urban Center, Tanzania',
    'Iksan-si Urban Center, Korea, Republic of': 'Iksan-si Urban Center, South Korea',
    'Ilam Urban Center, Iran, Islamic Republic of': 'Ilam Urban Center, Iran',
    'Ilebo Urban Center, Congo, The Democratic Republic of the': 'Ilebo Urban Center, Democratic Republic of the Congo',
    'Inagua, Bahamas': 'Inagua, The Bahamas',
    'Incheon-gwangyeoksi, Korea, Republic of': 'Incheon-gwangyeoksi, South Korea',
    'Ingushskaya, Respublika, Russian Federation': 'Ingushskaya, Respublika, Russia',
    'Iran, Islamic Republic of': 'Iran',
    'Iranshahr Urban Center, Iran, Islamic Republic of': 'Iranshahr Urban Center, Iran',
    'Iringa, Tanzania, United Republic of': 'Iringa, Tanzania',
    'Irkutsk Urban Center, Russian Federation': 'Irkutsk Urban Center, Russia',
    'Irkutskaya oblast\', Russian Federation': 'Irkutskaya oblast\', Russia',
    'Isebania Urban Center, Kenya-Tanzania, United Republic of': 'Isebania Urban Center, Kenya-Tanzania',
    'Isfahan 10MPC, Iran, Islamic Republic of': 'Isfahan 10MPC, Iran',
    'Isfahan 5MPC, Iran, Islamic Republic of': 'Isfahan 5MPC, Iran',
    'Isfahan Urban Center, Iran, Islamic Republic of': 'Isfahan Urban Center, Iran',
    'Ishim Urban Center, Russian Federation': 'Ishim Urban Center, Russia',
    'Isiro Urban Center, Congo, The Democratic Republic of the': 'Isiro Urban Center, Democratic Republic of the Congo',
    'Issia Urban Center, C\u00f4te d\'Ivoire': 'Issia Urban Center, Ivory Coast',
    'Istanbul 100MPC, Turkey-Syrian Arab Republic': 'Istanbul 100MPC, Turkey-Syria',
    'Ituri, Congo, The Democratic Republic of the': 'Ituri, Democratic Republic of the Congo',
    'Ivanovo Urban Center, Russian Federation': 'Ivanovo Urban Center, Russia',
    'Ivanovskaya oblast\', Russian Federation': 'Ivanovskaya oblast\', Russia',
    'Iwambi Urban Center, Tanzania, United Republic of': 'Iwambi Urban Center, Tanzania',
    'Izeh Urban Center, Iran, Islamic Republic of': 'Izeh Urban Center, Iran',
    'Izhevsk Urban Center, Russian Federation': 'Izhevsk Urban Center, Russia',
    'Jablah Urban Center, Syrian Arab Republic': 'Jablah Urban Center, Syria',
    'Jaeryong Urban Center, Korea, Democratic People\'s Republic of': 'Jaeryong Urban Center, North Korea',
    'Jahrom Urban Center, Iran, Islamic Republic of': 'Jahrom Urban Center, Iran',
    'Jakarta 500MPC, Indonesia-Philippines-Viet Nam': 'Jakarta 500MPC, Indonesia-Philippines-Vietnam',
    'Jamnagar 10MPC, India-Oman-Iran, Islamic Republic of': 'Jamnagar 10MPC, India-Oman-Iran',
    'Javanrud Urban Center, Iran, Islamic Republic of': 'Javanrud Urban Center, Iran',
    'Jecheon-si Urban Center, Korea, Republic of': 'Jecheon-si Urban Center, South Korea',
    'Jeju-si Urban Center, Korea, Republic of': 'Jeju-si Urban Center, South Korea',
    'Jeju-teukbyeoljachido, Korea, Republic of': 'Jeju-teukbyeoljachido, South Korea',
    'Jenin Urban Center, Palestine, State of': 'Jenin Urban Center, State of Palestine',
    'Jenin, Palestine, State of': 'Jenin, State of Palestine',
    'Jeollabuk-do, Korea, Republic of': 'Jeollabuk-do, South Korea',
    'Jeollanam-do, Korea, Republic of': 'Jeollanam-do, South Korea',
    'Jeonju Urban Center, Korea, Republic of': 'Jeonju Urban Center, South Korea',
    'Jericho - Al Aghwar, Palestine, State of': 'Jericho - Al Aghwar, State of Palestine',
    'Jerusalem Urban Center, Israel-Palestine, State of': 'Jerusalem Urban Center, Israel-State of Palestine',
    'Jerusalem, Palestine, State of': 'Jerusalem, State of Palestine',
    'Ji\'an Urban Center, China-Korea, Democratic People\'s Republic of': 'Ji\'an Urban Center, China-North Korea',
    'Jihomoravsk\u00fd kraj, Czechia': 'Jihomoravsk\u00fd kraj, Czech Republic',
    'Jiho\u010desk\u00fd kraj, Czechia': 'Jiho\u010desk\u00fd kraj, Czech Republic',
    'Jinghong 5MPC, China-Lao People\'s Democratic Republic-Viet Nam': 'Jinghong 5MPC, China-Laos-Vietnam',
    'Jinhae-gu Urban Center, Korea, Republic of': 'Jinhae-gu Urban Center, South Korea',
    'Jinju-si Urban Center, Korea, Republic of': 'Jinju-si Urban Center, South Korea',
    'Jinyeong-eup Urban Center, Korea, Republic of': 'Jinyeong-eup Urban Center, South Korea',
    'Jiroft Urban Center, Iran, Islamic Republic of': 'Jiroft Urban Center, Iran',
    'Jonchon Urban Center, Korea, Democratic People\'s Republic of': 'Jonchon Urban Center, North Korea',
    'Jongju Urban Center, Korea, Democratic People\'s Republic of': 'Jongju Urban Center, North Korea',
    'Jongphyong Urban Center, Korea, Democratic People\'s Republic of': 'Jongphyong Urban Center, North Korea',
    'Juba 5MPC, South Sudan-Congo, The Democratic Republic of the': 'Juba 5MPC, South Sudan-Democratic Republic of the Congo',
    'Jupujuro-sii Urban Center, Congo, The Democratic Republic of the': 'Jupujuro-sii Urban Center, Democratic Republic of the Congo',
    'Kabalo Urban Center, Congo, The Democratic Republic of the': 'Kabalo Urban Center, Democratic Republic of the Congo',
    'Kabardino-Balkarskaya Respublika, Russian Federation': 'Kabardino-Balkarskaya Respublika, Russia',
    'Kabinda Urban Center, Congo, The Democratic Republic of the': 'Kabinda Urban Center, Democratic Republic of the Congo',
    'Kabondo-Dianda Urban Center, Congo, The Democratic Republic of the': 'Kabondo-Dianda Urban Center, Democratic Republic of the Congo',
    'Kabongo (Northern Haut-Lomami) Urban Center, Congo, The Democratic Republic of the': 'Kabongo (Northern Haut-Lomami) Urban Center, Democratic Republic of the Congo',
    'Kabongo (Southern Haut-Lomami) Urban Center, Congo, The Democratic Republic of the': 'Kabongo (Southern Haut-Lomami) Urban Center, Democratic Republic of the Congo',
    'Kaechon Urban Center, Korea, Democratic People\'s Republic of': 'Kaechon Urban Center, North Korea',
    'Kaesong 5MPC, Korea, Democratic People\'s Republic of': 'Kaesong 5MPC, North Korea',
    'Kaesong Industrial Region, Korea, Democratic People\'s Republic of': 'Kaesong Industrial Region, North Korea',
    'Kaesong Urban Center, Korea, Democratic People\'s Republic of': 'Kaesong Urban Center, North Korea',
    'Kagera, Tanzania, United Republic of': 'Kagera, Tanzania',
    'Kagoshima 10MPC, Japan-Korea, Republic of-Korea, Democratic People\'s Republic of': 'Kagoshima 10MPC, Japan-South Korea-North Korea',
    'Kagoshima 5MPC, Japan-Korea, Republic of': 'Kagoshima 5MPC, Japan-South Korea',
    'Kahama Urban Center, Tanzania, United Republic of': 'Kahama Urban Center, Tanzania',
    'Kahinda Urban Center, Congo, The Democratic Republic of the': 'Kahinda Urban Center, Democratic Republic of the Congo',
    'Kahnuj Urban Center, Iran, Islamic Republic of': 'Kahnuj Urban Center, Iran',
    'Kakanda Urban Center, Congo, The Democratic Republic of the': 'Kakanda Urban Center, Democratic Republic of the Congo',
    'Kakenge Urban Center, Congo, The Democratic Republic of the': 'Kakenge Urban Center, Democratic Republic of the Congo',
    'Kalemie 10MPC, Tanzania, United Republic of-Congo, The Democratic Republic of the': 'Kalemie 10MPC, Tanzania-Democratic Republic of the Congo',
    'Kalemie 20MPC, Tanzania, United Republic of-Congo, The Democratic Republic of the': 'Kalemie 20MPC, Tanzania-Democratic Republic of the Congo',
    'Kalemie 5MPC, Tanzania, United Republic of-Congo, The Democratic Republic of the': 'Kalemie 5MPC, Tanzania-Democratic Republic of the Congo',
    'Kalemie Urban Center, Congo, The Democratic Republic of the': 'Kalemie Urban Center, Democratic Republic of the Congo',
    'Kalimva Urban Center, Congo, The Democratic Republic of the': 'Kalimva Urban Center, Democratic Republic of the Congo',
    'Kaliningrad 5MPC, Poland-Lithuania-Russian Federation': 'Kaliningrad 5MPC, Poland-Lithuania-Russia',
    'Kaliningrad Urban Center, Russian Federation': 'Kaliningrad Urban Center, Russia',
    'Kaliningradskaya oblast\', Russian Federation': 'Kaliningradskaya oblast\', Russia',
    'Kalmykiya, Respublika, Russian Federation': 'Kalmykiya, Respublika, Russia',
    'Kaluga Urban Center, Russian Federation': 'Kaluga Urban Center, Russia',
    'Kaluzhskaya oblast\', Russian Federation': 'Kaluzhskaya oblast\', Russia',
    'Kamanyola Urban Center, Congo, The Democratic Republic of the': 'Kamanyola Urban Center, Democratic Republic of the Congo',
    'Kambove Urban Center, Congo, The Democratic Republic of the': 'Kambove Urban Center, Democratic Republic of the Congo',
    'Kamchatskiy kray, Russian Federation': 'Kamchatskiy kray, Russia',
    'Kamensk-Shakhtinsky Urban Center, Russian Federation': 'Kamensk-Shakhtinsky Urban Center, Russia',
    'Kamensk-Uralsky Urban Center, Russian Federation': 'Kamensk-Uralsky Urban Center, Russia',
    'Kamina 10MPC, Congo, The Democratic Republic of the': 'Kamina 10MPC, Democratic Republic of the Congo',
    'Kamina Urban Center, Congo, The Democratic Republic of the': 'Kamina Urban Center, Democratic Republic of the Congo',
    'Kamituga Urban Center, Congo, The Democratic Republic of the': 'Kamituga Urban Center, Democratic Republic of the Congo',
    'Kampala 100MPC, Uganda-Tanzania, United Republic of-Kenya': 'Kampala 100MPC, Uganda-Tanzania-Kenya',
    'Kamyaran Urban Center, Iran, Islamic Republic of': 'Kamyaran Urban Center, Iran',
    'Kamyshin Urban Center, Russian Federation': 'Kamyshin Urban Center, Russia',
    'Kananga 5MPC, Congo, The Democratic Republic of the': 'Kananga 5MPC, Democratic Republic of the Congo',
    'Kananga Urban Center, Congo, The Democratic Republic of the': 'Kananga Urban Center, Democratic Republic of the Congo',
    'Kandahar 10MPC, Afghanistan-Iran, Islamic Republic of-China': 'Kandahar 10MPC, Afghanistan-Iran-China',
    'Kangan Seaport Urban Center, Iran, Islamic Republic of': 'Kangan Seaport Urban Center, Iran',
    'Kangdong Urban Center, Korea, Democratic People\'s Republic of': 'Kangdong Urban Center, North Korea',
    'Kanggye Urban Center, Korea, Democratic People\'s Republic of': 'Kanggye Urban Center, North Korea',
    'Kangson Urban Center, Korea, Democratic People\'s Republic of': 'Kangson Urban Center, North Korea',
    'Kangwon-do [Kangweonto], Korea, Democratic People\'s Republic of': 'Kangwon-do [Kangweonto], North Korea',
    'Kangw\u014fn-do [Kangweonto], Korea, Democratic People\'s Republic of': 'Kangw\u014fn-do [Kangweonto], North Korea',
    'Kansk Urban Center, Russian Federation': 'Kansk Urban Center, Russia',
    'Kanyabayonga Urban Center, Congo, The Democratic Republic of the': 'Kanyabayonga Urban Center, Democratic Republic of the Congo',
    'Kanyama Urban Center, Congo, The Democratic Republic of the': 'Kanyama Urban Center, Democratic Republic of the Congo',
    'Kaohsiung 50MPC, China-Russian Federation-India': 'Kaohsiung 50MPC, China-Russia-India',
    'Karachayevo-Cherkesskaya Respublika, Russian Federation': 'Karachayevo-Cherkesskaya Respublika, Russia',
    'Karachi 100MPC, Pakistan-Iran, Islamic Republic of-Afghanistan': 'Karachi 100MPC, Pakistan-Iran-Afghanistan',
    'Karachi 200MPC, Iran, Islamic Republic of-Afghanistan-Pakistan': 'Karachi 200MPC, Iran-Afghanistan-Pakistan',
    'Karachi 500MPC, Russian Federation-Morocco-Algeria': 'Karachi 500MPC, Russia-Morocco-Algeria',
    'Karatu Urban Center, Tanzania, United Republic of': 'Karatu Urban Center, Tanzania',
    'Karawa Urban Center, Congo, The Democratic Republic of the': 'Karawa Urban Center, Democratic Republic of the Congo',
    'Kareliya, Respublika, Russian Federation': 'Kareliya, Respublika, Russia',
    'Karlovarsk\u00fd kraj, Czechia': 'Karlovarsk\u00fd kraj, Czech Republic',
    'Kasaji Urban Center, Congo, The Democratic Republic of the': 'Kasaji Urban Center, Democratic Republic of the Congo',
    'Kasa\u00ef Central, Congo, The Democratic Republic of the': 'Kasa\u00ef Central, Democratic Republic of the Congo',
    'Kasa\u00ef Oriental, Congo, The Democratic Republic of the': 'Kasa\u00ef Oriental, Democratic Republic of the Congo',
    'Kasa\u00ef, Congo, The Democratic Republic of the': 'Kasa\u00ef, Democratic Republic of the Congo',
    'Kasenga Urban Center, Congo, The Democratic Republic of the-Zambia': 'Kasenga Urban Center, Democratic Republic of the Congo-Zambia',
    'Kashan Urban Center, Iran, Islamic Republic of': 'Kashan Urban Center, Iran',
    'Kashmar Urban Center, Iran, Islamic Republic of': 'Kashmar Urban Center, Iran',
    'Kaskazini Pemba, Tanzania, United Republic of': 'Kaskazini Pemba, Tanzania',
    'Kaskazini Unguja, Tanzania, United Republic of': 'Kaskazini Unguja, Tanzania',
    'Kasongo Urban Center, Congo, The Democratic Republic of the': 'Kasongo Urban Center, Democratic Republic of the Congo',
    'Kasongo-Lunda Urban Center, Congo, The Democratic Republic of the': 'Kasongo-Lunda Urban Center, Democratic Republic of the Congo',
    'Kaspiysk Urban Center, Russian Federation': 'Kaspiysk Urban Center, Russia',
    'Kasumbalesa Urban Center, Congo, The Democratic Republic of the-Zambia': 'Kasumbalesa Urban Center, Democratic Republic of the Congo-Zambia',
    'Katavi, Tanzania, United Republic of': 'Katavi, Tanzania',
    'Katoro Urban Center, Tanzania, United Republic of': 'Katoro Urban Center, Tanzania',
    'Katowice 10MPC, Poland-Czechia': 'Katowice 10MPC, Poland-Czech Republic',
    'Kazan 10MPC, Russian Federation': 'Kazan 10MPC, Russia',
    'Kazan 5MPC, Russian Federation': 'Kazan 5MPC, Russia',
    'Kazan Urban Center, Russian Federation': 'Kazan Urban Center, Russia',
    'Kazerun Urban Center, Iran, Islamic Republic of': 'Kazerun Urban Center, Iran',
    'Kemerovo Urban Center, Russian Federation': 'Kemerovo Urban Center, Russia',
    'Kemerovskaya oblast\', Russian Federation': 'Kemerovskaya oblast\', Russia',
    'Kenge Urban Center, Congo, The Democratic Republic of the': 'Kenge Urban Center, Democratic Republic of the Congo',
    'Kerman 10MPC, Iran, Islamic Republic of': 'Kerman 10MPC, Iran',
    'Kerman 5MPC, Iran, Islamic Republic of': 'Kerman 5MPC, Iran',
    'Kerman Urban Center, Iran, Islamic Republic of': 'Kerman Urban Center, Iran',
    'Kermanshah 10MPC, Iran, Islamic Republic of-Iraq': 'Kermanshah 10MPC, Iran-Iraq',
    'Kermanshah 20MPC, Iran, Islamic Republic of-Turkey-Iraq': 'Kermanshah 20MPC, Iran-Turkey-Iraq',
    'Kermanshah 5MPC, Iran, Islamic Republic of': 'Kermanshah 5MPC, Iran',
    'Kermanshah Urban Center, Iran, Islamic Republic of': 'Kermanshah Urban Center, Iran',
    'Kerm\u0101n, Iran, Islamic Republic of': 'Kerm\u0101n, Iran',
    'Kerm\u0101nsh\u0101h, Iran, Islamic Republic of': 'Kerm\u0101nsh\u0101h, Iran',
    'Khabarovsk 5MPC, China-Russian Federation': 'Khabarovsk 5MPC, China-Russia',
    'Khabarovsk Urban Center, Russian Federation': 'Khabarovsk Urban Center, Russia',
    'Khabarovskiy kray, Russian Federation': 'Khabarovskiy kray, Russia',
    'Khaf Urban Center, Iran, Islamic Republic of': 'Khaf Urban Center, Iran',
    'Khakasiya, Respublika, Russian Federation': 'Khakasiya, Respublika, Russia',
    'Khammouan, Lao People\'s Democratic Republic': 'Khammouan, Laos',
    'Khan Yunis, Palestine, State of': 'Khan Yunis, State of Palestine',
    'Khanty-Mansiyskiy avtonomnyy okrug, Russian Federation': 'Khanty-Mansiyskiy avtonomnyy okrug, Russia',
    'Kharkiv 20MPC, Ukraine-Russian Federation': 'Kharkiv 20MPC, Ukraine-Russia',
    'Kharkiv 5MPC, Ukraine-Russian Federation': 'Kharkiv 5MPC, Ukraine-Russia',
    'Khasavyurt Urban Center, Russian Federation': 'Khasavyurt Urban Center, Russia',
    'Khash Urban Center, Iran, Islamic Republic of': 'Khash Urban Center, Iran',
    'Khomein Urban Center, Iran, Islamic Republic of': 'Khomein Urban Center, Iran',
    'Khorramabad Urban Center, Iran, Islamic Republic of': 'Khorramabad Urban Center, Iran',
    'Khorramdarreh Urban Center, Iran, Islamic Republic of': 'Khorramdarreh Urban Center, Iran',
    'Khorramshahr Urban Center, Iran, Islamic Republic of': 'Khorramshahr Urban Center, Iran',
    'Khor\u0101s\u0101n-e Jon\u016bb\u012b, Iran, Islamic Republic of': 'Khor\u0101s\u0101n-e Jon\u016bb\u012b, Iran',
    'Khor\u0101s\u0101n-e Ra\u1e95av\u012b, Iran, Islamic Republic of': 'Khor\u0101s\u0101n-e Ra\u1e95av\u012b, Iran',
    'Khor\u0101s\u0101n-e Shom\u0101l\u012b, Iran, Islamic Republic of': 'Khor\u0101s\u0101n-e Shom\u0101l\u012b, Iran',
    'Khoy Urban Center, Iran, Islamic Republic of': 'Khoy Urban Center, Iran',
    'Kho\u00e1i Ch\u00e2u Urban Center, Viet Nam': 'Kho\u00e1i Ch\u00e2u Urban Center, Vietnam',
    'Kh\u00e1nh H\u00f2a, Viet Nam': 'Kh\u00e1nh H\u00f2a, Vietnam',
    'Kh\u016bzest\u0101n, Iran, Islamic Republic of': 'Kh\u016bzest\u0101n, Iran',
    'Kibomango Urban Center, Congo, The Democratic Republic of the': 'Kibomango Urban Center, Democratic Republic of the Congo',
    'Kigali 20MPC, Rwanda-Burundi-Congo, The Democratic Republic of the': 'Kigali 20MPC, Rwanda-Burundi-Democratic Republic of the Congo',
    'Kigoma, Tanzania, United Republic of': 'Kigoma, Tanzania',
    'Kikondja Urban Center, Congo, The Democratic Republic of the': 'Kikondja Urban Center, Democratic Republic of the Congo',
    'Kikwit 5MPC, Congo, The Democratic Republic of the': 'Kikwit 5MPC, Democratic Republic of the Congo',
    'Kikwit Urban Center, Congo, The Democratic Republic of the': 'Kikwit Urban Center, Democratic Republic of the Congo',
    'Kilima Hewa Urban Center, Tanzania, United Republic of': 'Kilima Hewa Urban Center, Tanzania',
    'Kilimanjaro, Tanzania, United Republic of': 'Kilimanjaro, Tanzania',
    'Kilwa 5MPC, Zambia-Congo, The Democratic Republic of the-Tanzania, United Republic of': 'Kilwa 5MPC, Zambia-Democratic Republic of the Congo-Tanzania',
    'Kilwa Urban Center, Congo, The Democratic Republic of the': 'Kilwa Urban Center, Democratic Republic of the Congo',
    'Kimch\'aek Urban Center, Korea, Democratic People\'s Republic of': 'Kimch\'aek Urban Center, North Korea',
    'Kimpese Urban Center, Congo, The Democratic Republic of the': 'Kimpese Urban Center, Democratic Republic of the Congo',
    'Kindu 5MPC, Congo, The Democratic Republic of the-Uganda-Central African Republic': 'Kindu 5MPC, Democratic Republic of the Congo-Uganda-Central African Republic',
    'Kindu Urban Center, Congo, The Democratic Republic of the': 'Kindu Urban Center, Democratic Republic of the Congo',
    'Kineshma Urban Center, Russian Federation': 'Kineshma Urban Center, Russia',
    'Kingombe Urban Center, Congo, The Democratic Republic of the': 'Kingombe Urban Center, Democratic Republic of the Congo',
    'Kinshasa (Center) 5MPC, Congo, The Democratic Republic of the': 'Kinshasa (Center) 5MPC, Democratic Republic of the Congo',
    'Kinshasa (Outer) 5MPC, Congo, The Democratic Republic of the-Congo': 'Kinshasa (Outer) 5MPC, Democratic Republic of the Congo-Congo',
    'Kinshasa 100MPC, Congo, The Democratic Republic of the-Angola': 'Kinshasa 100MPC, Democratic Republic of the Congo-Angola',
    'Kinshasa 10MPC, Congo, The Democratic Republic of the': 'Kinshasa 10MPC, Democratic Republic of the Congo',
    'Kinshasa 20MPC, Congo, The Democratic Republic of the-Congo-Angola': 'Kinshasa 20MPC, Democratic Republic of the Congo-Congo-Angola',
    'Kinshasa 500MPC, Nigeria-Congo, The Democratic Republic of the-Uganda': 'Kinshasa 500MPC, Nigeria-Democratic Republic of the Congo-Uganda',
    'Kinshasa 50MPC, Congo, The Democratic Republic of the-Angola': 'Kinshasa 50MPC, Democratic Republic of the Congo-Angola',
    'Kinshasa Urban Center, Congo, The Democratic Republic of the': 'Kinshasa Urban Center, Democratic Republic of the Congo',
    'Kinshasa, Congo, The Democratic Republic of the': 'Kinshasa, Democratic Republic of the Congo',
    'Kinzao Urban Center, Congo, The Democratic Republic of the': 'Kinzao Urban Center, Democratic Republic of the Congo',
    'Kipushi Urban Center, Congo, The Democratic Republic of the': 'Kipushi Urban Center, Democratic Republic of the Congo',
    'Kirov 10MPC, Russian Federation-Finland': 'Kirov 10MPC, Russia-Finland',
    'Kirov Urban Center, Russian Federation': 'Kirov Urban Center, Russia',
    'Kirova Urban Center, Russian Federation': 'Kirova Urban Center, Russia',
    'Kirovo-Chepetsk Urban Center, Russian Federation': 'Kirovo-Chepetsk Urban Center, Russia',
    'Kirovskaya oblast\', Russian Federation': 'Kirovskaya oblast\', Russia',
    'Kirumba 10MPC, Uganda-Tanzania, United Republic of-Congo, The Democratic Republic of the': 'Kirumba 10MPC, Uganda-Tanzania-Democratic Republic of the Congo',
    'Kirumba Urban Center, Congo, The Democratic Republic of the': 'Kirumba Urban Center, Democratic Republic of the Congo',
    'Kisangani 10MPC, Congo, The Democratic Republic of the-South Sudan': 'Kisangani 10MPC, Democratic Republic of the Congo-South Sudan',
    'Kisangani 20MPC, Congo, The Democratic Republic of the': 'Kisangani 20MPC, Democratic Republic of the Congo',
    'Kisangani 5MPC, Congo, The Democratic Republic of the': 'Kisangani 5MPC, Democratic Republic of the Congo',
    'Kisangani Urban Center, Congo, The Democratic Republic of the': 'Kisangani Urban Center, Democratic Republic of the Congo',
    'Kisantu Urban Center, Congo, The Democratic Republic of the': 'Kisantu Urban Center, Democratic Republic of the Congo',
    'Kislovodsk Urban Center, Russian Federation': 'Kislovodsk Urban Center, Russia',
    'Kismayo 10MPC, South Africa-Mozambique-Tanzania, United Republic of': 'Kismayo 10MPC, South Africa-Mozambique-Tanzania',
    'Kitenge Urban Center, Congo, The Democratic Republic of the': 'Kitenge Urban Center, Democratic Republic of the Congo',
    'Kitwe 5MPC, Zambia-Congo, The Democratic Republic of the': 'Kitwe 5MPC, Zambia-Democratic Republic of the Congo',
    'Kizlyar Urban Center, Russian Federation': 'Kizlyar Urban Center, Russia',
    'Ki\u1ebfn Giang, Viet Nam': 'Ki\u1ebfn Giang, Vietnam',
    'Kladno Urban Center, Czechia': 'Kladno Urban Center, Czech Republic',
    'Kobani Urban Center, Syrian Arab Republic': 'Kobani Urban Center, Syria',
    'Kohg\u012bl\u016byeh va Bowyer A\u1e29mad, Iran, Islamic Republic of': 'Kohg\u012bl\u016byeh va Bowyer A\u1e29mad, Iran',
    'Koksan Urban Center, Korea, Democratic People\'s Republic of': 'Koksan Urban Center, North Korea',
    'Kolomna Urban Center, Russian Federation': 'Kolomna Urban Center, Russia',
    'Kolpino Urban Center, Russian Federation': 'Kolpino Urban Center, Russia',
    'Kolwezi 5MPC, Zambia-Mozambique-Congo, The Democratic Republic of the': 'Kolwezi 5MPC, Zambia-Mozambique-Democratic Republic of the Congo',
    'Kolwezi Urban Center, Congo, The Democratic Republic of the': 'Kolwezi Urban Center, Democratic Republic of the Congo',
    'Komi, Respublika, Russian Federation': 'Komi, Respublika, Russia',
    'Komsomolsk-on-Amur Urban Center, Russian Federation': 'Komsomolsk-on-Amur Urban Center, Russia',
    'Kon Tum Urban Center, Viet Nam': 'Kon Tum Urban Center, Vietnam',
    'Kon Tum, Viet Nam': 'Kon Tum, Vietnam',
    'Kongo Central, Congo, The Democratic Republic of the': 'Kongo Central, Democratic Republic of the Congo',
    'Kongolo 5MPC, Congo, The Democratic Republic of the': 'Kongolo 5MPC, Democratic Republic of the Congo',
    'Kongolo Urban Center, Congo, The Democratic Republic of the': 'Kongolo Urban Center, Democratic Republic of the Congo',
    'Kordest\u0101n, Iran, Islamic Republic of': 'Kordest\u0101n, Iran',
    'Korea, Democratic People\'s Republic of': 'North Korea',
    'Korea, Republic of': 'South Korea',
    'Korhogo 5MPC, C\u00f4te d\'Ivoire-Burkina Faso-Ghana': 'Korhogo 5MPC, Ivory Coast-Burkina Faso-Ghana',
    'Korhogo Urban Center, C\u00f4te d\'Ivoire': 'Korhogo Urban Center, Ivory Coast',
    'Kosan Urban Center, Korea, Democratic People\'s Republic of': 'Kosan Urban Center, North Korea',
    'Kosrae, Micronesia, Federated States of': 'Kosrae, Micronesia',
    'Kostroma Urban Center, Russian Federation': 'Kostroma Urban Center, Russia',
    'Kostromskaya oblast\', Russian Federation': 'Kostromskaya oblast\', Russia',
    'Kovrov Urban Center, Russian Federation': 'Kovrov Urban Center, Russia',
    'Kowon Urban Center, Korea, Democratic People\'s Republic of': 'Kowon Urban Center, North Korea',
    'Krasnodar 20MPC, Russian Federation-Ukraine-Turkey': 'Krasnodar 20MPC, Russia-Ukraine-Turkey',
    'Krasnodar Urban Center, Russian Federation': 'Krasnodar Urban Center, Russia',
    'Krasnodyarskiy kray, Russian Federation': 'Krasnodyarskiy kray, Russia',
    'Krasnoyarsk 5MPC, Russian Federation': 'Krasnoyarsk 5MPC, Russia',
    'Krasnoyarsk Urban Center, Russian Federation': 'Krasnoyarsk Urban Center, Russia',
    'Krasnoyarskiy kray, Russian Federation': 'Krasnoyarskiy kray, Russia',
    'Kr\u00e1lov\u00e9hradeck\u00fd kraj, Czechia': 'Kr\u00e1lov\u00e9hradeck\u00fd kraj, Czech Republic',
    'Kuhdasht Urban Center, Iran, Islamic Republic of': 'Kuhdasht Urban Center, Iran',
    'Kujang Urban Center, Korea, Democratic People\'s Republic of': 'Kujang Urban Center, North Korea',
    'Kultaevo Urban Center, Russian Federation': 'Kultaevo Urban Center, Russia',
    'Kumya Urban Center, Korea, Democratic People\'s Republic of': 'Kumya Urban Center, North Korea',
    'Kupang 10MPC, Indonesia-Timor-Leste': 'Kupang 10MPC, Indonesia-East Timor',
    'Kupang 5MPC, Indonesia-Timor-Leste': 'Kupang 5MPC, Indonesia-East Timor',
    'Kurgan Urban Center, Russian Federation': 'Kurgan Urban Center, Russia',
    'Kurganskaya oblast\', Russian Federation': 'Kurganskaya oblast\', Russia',
    'Kursk Urban Center, Russian Federation': 'Kursk Urban Center, Russia',
    'Kurskaya oblast\', Russian Federation': 'Kurskaya oblast\', Russia',
    'Kusini Pemba, Tanzania, United Republic of': 'Kusini Pemba, Tanzania',
    'Kusini Unguja, Tanzania, United Republic of': 'Kusini Unguja, Tanzania',
    'Kusong Urban Center, Korea, Democratic People\'s Republic of': 'Kusong Urban Center, North Korea',
    'Kuwait City (Outer) 5MPC, Iran, Islamic Republic of-Kuwait-Iraq': 'Kuwait City (Outer) 5MPC, Iran-Kuwait-Iraq',
    'Kuwait City 100MPC, Egypt-Russian Federation-Iran, Islamic Republic of': 'Kuwait City 100MPC, Egypt-Russia-Iran',
    'Kuwait City 20MPC, Iran, Islamic Republic of-Iraq-Kuwait': 'Kuwait City 20MPC, Iran-Iraq-Kuwait',
    'Kuznetsk Urban Center, Russian Federation': 'Kuznetsk Urban Center, Russia',
    'Kwaksan Urban Center, Korea, Democratic People\'s Republic of': 'Kwaksan Urban Center, North Korea',
    'Kwango, Congo, The Democratic Republic of the': 'Kwango, Democratic Republic of the Congo',
    'Kwilu, Congo, The Democratic Republic of the': 'Kwilu, Democratic Republic of the Congo',
    'Kwilu-Ngongo Urban Center, Congo, The Democratic Republic of the': 'Kwilu-Ngongo Urban Center, Democratic Republic of the Congo',
    'Kyela Urban Center, Tanzania, United Republic of': 'Kyela Urban Center, Tanzania',
    'Kyiv 20MPC, Ukraine-Romania-Moldova, Republic of': 'Kyiv 20MPC, Ukraine-Romania-Moldova',
    'Kyolo Urban Center, Congo, The Democratic Republic of the': 'Kyolo Urban Center, Democratic Republic of the Congo',
    'Kyonghung Urban Center, Korea, Democratic People\'s Republic of': 'Kyonghung Urban Center, North Korea',
    'Kyzyl Urban Center, Russian Federation': 'Kyzyl Urban Center, Russia',
    'La Concepci\u00f3n Urban Center, Venezuela, Bolivarian Republic of': 'La Concepci\u00f3n Urban Center, Venezuela',
    'La Gi Urban Center, Viet Nam': 'La Gi Urban Center, Vietnam',
    'La Guaira, Venezuela, Bolivarian Republic of': 'La Guaira, Venezuela',
    'La Paz, Bolivia, Plurinational State of': 'La Paz, Bolivia',
    'La Victoria Urban Center, Venezuela, Bolivarian Republic of': 'La Victoria Urban Center, Venezuela',
    'Labinsk Urban Center, Russian Federation': 'Labinsk Urban Center, Russia',
    'Lacs, C\u00f4te d\'Ivoire': 'Lacs, Ivory Coast',
    'Lagos 1BPC, Nigeria-Ethiopia-Congo, The Democratic Republic of the': 'Lagos 1BPC, Nigeria-Ethiopia-Democratic Republic of the Congo',
    'Lagos 500MPC, Nigeria-Ethiopia-Tanzania, United Republic of': 'Lagos 500MPC, Nigeria-Ethiopia-Tanzania',
    'Lagunes, C\u00f4te d\'Ivoire': 'Lagunes, Ivory Coast',
    'Lahijan Urban Center, Iran, Islamic Republic of': 'Lahijan Urban Center, Iran',
    'Lai Ch\u00e2u, Viet Nam': 'Lai Ch\u00e2u, Vietnam',
    'Lakota Urban Center, C\u00f4te d\'Ivoire': 'Lakota Urban Center, Ivory Coast',
    'Lao People\'s Democratic Republic': 'Laos',
    'Lara, Venezuela, Bolivarian Republic of': 'Lara, Venezuela',
    'Latakia Urban Center, Syrian Arab Republic': 'Latakia Urban Center, Syria',
    'Laut\u00e9m, Timor-Leste': 'Laut\u00e9m, East Timor',
    'Lemba Village Urban Center, Congo, The Democratic Republic of the': 'Lemba Village Urban Center, Democratic Republic of the Congo',
    'Lemb\u00e1, Sao Tome and Principe': 'Lemb\u00e1, S\u00e3o Tom\u00e9 and Pr\u00edncipe',
    'Leningradskaya oblast\', Russian Federation': 'Leningradskaya oblast\', Russia',
    'Leova, Moldova, Republic of': 'Leova, Moldova',
    'Liberec 5MPC, Poland-Czechia': 'Liberec 5MPC, Poland-Czech Republic',
    'Liberec Urban Center, Czechia': 'Liberec Urban Center, Czech Republic',
    'Libereck\u00fd kraj, Czechia': 'Libereck\u00fd kraj, Czech Republic',
    'Libreville 20MPC, Chad-Congo, The Democratic Republic of the-Kenya': 'Libreville 20MPC, Chad-Democratic Republic of the Congo-Kenya',
    'Lichinga 5MPC, Tanzania, United Republic of-Mozambique': 'Lichinga 5MPC, Tanzania-Mozambique',
    'Likasi Urban Center, Congo, The Democratic Republic of the': 'Likasi Urban Center, Democratic Republic of the Congo',
    'Lindi, Tanzania, United Republic of': 'Lindi, Tanzania',
    'Linz 5MPC, Austria-Czechia-Germany': 'Linz 5MPC, Austria-Czech Republic-Germany',
    'Lipetsk 5MPC, Russian Federation': 'Lipetsk 5MPC, Russia',
    'Lipetsk Urban Center, Russian Federation': 'Lipetsk Urban Center, Russia',
    'Lipetskaya oblast\', Russian Federation': 'Lipetskaya oblast\', Russia',
    'Liqui\u00e7a, Timor-Leste': 'Liqui\u00e7a, East Timor',
    'Lisala Urban Center, Congo, The Democratic Republic of the': 'Lisala Urban Center, Democratic Republic of the Congo',
    'Livny Urban Center, Russian Federation': 'Livny Urban Center, Russia',
    'Li\u00ean Kh\u01b0\u01a1ng Urban Center, Viet Nam': 'Li\u00ean Kh\u01b0\u01a1ng Urban Center, Vietnam',
    'Lobata, Sao Tome and Principe': 'Lobata, S\u00e3o Tom\u00e9 and Pr\u00edncipe',
    'Lobnya Urban Center, Russian Federation': 'Lobnya Urban Center, Russia',
    'Lomami, Congo, The Democratic Republic of the': 'Lomami, Democratic Republic of the Congo',
    'Long An, Viet Nam': 'Long An, Vietnam',
    'Long Island, Bahamas': 'Long Island, The Bahamas',
    'Long Khanh Urban Center, Viet Nam': 'Long Khanh Urban Center, Vietnam',
    'Long M\u1ef9 Urban Center, Viet Nam': 'Long M\u1ef9 Urban Center, Vietnam',
    'Long Xuyen Urban Center, Viet Nam': 'Long Xuyen Urban Center, Vietnam',
    'Lordegan Urban Center, Iran, Islamic Republic of': 'Lordegan Urban Center, Iran',
    'Lorest\u0101n, Iran, Islamic Republic of': 'Lorest\u0101n, Iran',
    'Los Tanques Urban Center, Venezuela, Bolivarian Republic of': 'Los Tanques Urban Center, Venezuela',
    'Los Teques Urban Center, Venezuela, Bolivarian Republic of': 'Los Teques Urban Center, Venezuela',
    'Louang Namtha, Lao People\'s Democratic Republic': 'Louang Namtha, Laos',
    'Louangphabang, Lao People\'s Democratic Republic': 'Louangphabang, Laos',
    'Lower River, Gambia': 'Lower River, The Gambia',
    'Lualaba, Congo, The Democratic Republic of the': 'Lualaba, Democratic Republic of the Congo',
    'Luanda 200MPC, Congo, The Democratic Republic of the-Angola-Malawi': 'Luanda 200MPC, Democratic Republic of the Congo-Angola-Malawi',
    'Luanda 50MPC, Congo, The Democratic Republic of the-Angola-Congo': 'Luanda 50MPC, Democratic Republic of the Congo-Angola-Congo',
    'Luang Prabang Urban Center, Lao People\'s Democratic Republic': 'Luang Prabang Urban Center, Laos',
    'Lubango 10MPC, Angola-Zambia-Congo, The Democratic Republic of the': 'Lubango 10MPC, Angola-Zambia-Democratic Republic of the Congo',
    'Lubao Urban Center, Congo, The Democratic Republic of the': 'Lubao Urban Center, Democratic Republic of the Congo',
    'Lubumbashi 10MPC, Congo, The Democratic Republic of the-Zambia': 'Lubumbashi 10MPC, Democratic Republic of the Congo-Zambia',
    'Lubumbashi 20MPC, Congo, The Democratic Republic of the-Zambia': 'Lubumbashi 20MPC, Democratic Republic of the Congo-Zambia',
    'Lubumbashi 5MPC, Congo, The Democratic Republic of the': 'Lubumbashi 5MPC, Democratic Republic of the Congo',
    'Lubumbashi Urban Center, Congo, The Democratic Republic of the': 'Lubumbashi Urban Center, Democratic Republic of the Congo',
    'Lubutu Urban Center, Congo, The Democratic Republic of the': 'Lubutu Urban Center, Democratic Republic of the Congo',
    'Luena Urban Center, Congo, The Democratic Republic of the': 'Luena Urban Center, Democratic Republic of the Congo',
    'Luiza Urban Center, Congo, The Democratic Republic of the': 'Luiza Urban Center, Democratic Republic of the Congo',
    'Lukalaba Urban Center, Congo, The Democratic Republic of the': 'Lukalaba Urban Center, Democratic Republic of the Congo',
    'Lwambo Urban Center, Congo, The Democratic Republic of the': 'Lwambo Urban Center, Democratic Republic of the Congo',
    'Lwebo Urban Center, Congo, The Democratic Republic of the': 'Lwebo Urban Center, Democratic Republic of the Congo',
    'Lysva Urban Center, Russian Federation': 'Lysva Urban Center, Russia',
    'L\u00e0o Cai Urban Center, China-Viet Nam': 'L\u00e0o Cai Urban Center, China-Vietnam',
    'L\u00e0o Cai, Viet Nam': 'L\u00e0o Cai, Vietnam',
    'L\u00e2m \u00d0\u1ed3ng, Viet Nam': 'L\u00e2m \u00d0\u1ed3ng, Vietnam',
    'L\u01b0u Gi\u00e1o Urban Center, Viet Nam': 'L\u01b0u Gi\u00e1o Urban Center, Vietnam',
    'L\u1ea1ng S\u01a1n 5MPC, Viet Nam': 'L\u1ea1ng S\u01a1n 5MPC, Vietnam',
    'L\u1ea1ng S\u01a1n Urban Center, Viet Nam': 'L\u1ea1ng S\u01a1n Urban Center, Vietnam',
    'L\u1ea1ng S\u01a1n, Viet Nam': 'L\u1ea1ng S\u01a1n, Vietnam',
    'L\u1ecbch H\u1ed9i Th\u01b0\u1ee3ng Urban Center, Viet Nam': 'L\u1ecbch H\u1ed9i Th\u01b0\u1ee3ng Urban Center, Vietnam',
    'Macap\u00e1 10MPC, Brazil-Venezuela, Bolivarian Republic of-France': 'Macap\u00e1 10MPC, Brazil-Venezuela-France',
    'Machiques Urban Center, Venezuela, Bolivarian Republic of': 'Machiques Urban Center, Venezuela',
    'Magadan Urban Center, Russian Federation': 'Magadan Urban Center, Russia',
    'Magadanskaya oblast\', Russian Federation': 'Magadanskaya oblast\', Russia',
    'Magnitogorsk Urban Center, Russian Federation': 'Magnitogorsk Urban Center, Russia',
    'Mahabad Urban Center, Iran, Islamic Republic of': 'Mahabad Urban Center, Iran',
    'Mahagi Urban Center, Congo, The Democratic Republic of the': 'Mahagi Urban Center, Democratic Republic of the Congo',
    'Mahdasht Urban Center, Iran, Islamic Republic of': 'Mahdasht Urban Center, Iran',
    'Mai-Ndombe, Congo, The Democratic Republic of the': 'Mai-Ndombe, Democratic Republic of the Congo',
    'Maio, Cabo Verde': 'Maio, Cape Verde',
    'Makambako Urban Center, Tanzania, United Republic of': 'Makambako Urban Center, Tanzania',
    'Makanza Urban Center, Congo, The Democratic Republic of the': 'Makanza Urban Center, Democratic Republic of the Congo',
    'Makhachkala 5MPC, Russian Federation': 'Makhachkala 5MPC, Russia',
    'Makhachkala Urban Center, Russian Federation': 'Makhachkala Urban Center, Russia',
    'Makosa Urban Center, Congo, The Democratic Republic of the': 'Makosa Urban Center, Democratic Republic of the Congo',
    'Malanje 10MPC, Angola-Congo, The Democratic Republic of the': 'Malanje 10MPC, Angola-Democratic Republic of the Congo',
    'Malanje 5MPC, Angola-Congo, The Democratic Republic of the': 'Malanje 5MPC, Angola-Democratic Republic of the Congo',
    'Malayer Urban Center, Iran, Islamic Republic of': 'Malayer Urban Center, Iran',
    'Malekan Urban Center, Iran, Islamic Republic of': 'Malekan Urban Center, Iran',
    'Malemba Nkulu 5MPC, Congo, The Democratic Republic of the': 'Malemba Nkulu 5MPC, Democratic Republic of the Congo',
    'Malemba Nkulu Urban Center, Congo, The Democratic Republic of the': 'Malemba Nkulu Urban Center, Democratic Republic of the Congo',
    'Mambasa Urban Center, Congo, The Democratic Republic of the': 'Mambasa Urban Center, Democratic Republic of the Congo',
    'Man Urban Center, C\u00f4te d\'Ivoire': 'Man Urban Center, Ivory Coast',
    'Manatutu, Timor-Leste': 'Manatutu, East Timor',
    'Manaus 50MPC, Brazil-Peru-Bolivia, Plurinational State of': 'Manaus 50MPC, Brazil-Peru-Bolivia',
    'Manbij Urban Center, Syrian Arab Republic': 'Manbij Urban Center, Syria',
    'Mangrove Cay, Bahamas': 'Mangrove Cay, The Bahamas',
    'Maniema, Congo, The Democratic Republic of the': 'Maniema, Democratic Republic of the Congo',
    'Manono 5MPC, Congo, The Democratic Republic of the': 'Manono 5MPC, Democratic Republic of the Congo',
    'Manono Urban Center, Congo, The Democratic Republic of the': 'Manono Urban Center, Democratic Republic of the Congo',
    'Manufahi, Timor-Leste': 'Manufahi, East Timor',
    'Manyara, Tanzania, United Republic of': 'Manyara, Tanzania',
    'Mara, Tanzania, United Republic of': 'Mara, Tanzania',
    'Maracaibo 10MPC, Venezuela, Bolivarian Republic of': 'Maracaibo 10MPC, Venezuela',
    'Maracaibo 5MPC, Venezuela, Bolivarian Republic of-Colombia': 'Maracaibo 5MPC, Venezuela-Colombia',
    'Maracaibo Urban Center, Venezuela, Bolivarian Republic of': 'Maracaibo Urban Center, Venezuela',
    'Maracay 5MPC, Venezuela, Bolivarian Republic of': 'Maracay 5MPC, Venezuela',
    'Maracay Urban Center, Venezuela, Bolivarian Republic of': 'Maracay Urban Center, Venezuela',
    'Maragheh Urban Center, Iran, Islamic Republic of': 'Maragheh Urban Center, Iran',
    'Marand Urban Center, Iran, Islamic Republic of': 'Marand Urban Center, Iran',
    'Mariara Urban Center, Venezuela, Bolivarian Republic of': 'Mariara Urban Center, Venezuela',
    'Mariupol 5MPC, Ukraine-Russian Federation': 'Mariupol 5MPC, Ukraine-Russia',
    'Marivan Urban Center, Iran, Islamic Republic of': 'Marivan Urban Center, Iran',
    'Mariy El, Respublika, Russian Federation': 'Mariy El, Respublika, Russia',
    'Markaz\u012b, Iran, Islamic Republic of': 'Markaz\u012b, Iran',
    'Marvdasht Urban Center, Iran, Islamic Republic of': 'Marvdasht Urban Center, Iran',
    'Mary 5MPC, Turkmenistan-Iran, Islamic Republic of-Afghanistan': 'Mary 5MPC, Turkmenistan-Iran-Afghanistan',
    'Mar\u00edn Urban Center, Venezuela, Bolivarian Republic of': 'Mar\u00edn Urban Center, Venezuela',
    'Mashhad 10MPC, Iran, Islamic Republic of-Turkmenistan': 'Mashhad 10MPC, Iran-Turkmenistan',
    'Mashhad 20MPC, Iran, Islamic Republic of-Afghanistan-Turkmenistan': 'Mashhad 20MPC, Iran-Afghanistan-Turkmenistan',
    'Mashhad 50MPC, Afghanistan-Iran, Islamic Republic of-Turkmenistan': 'Mashhad 50MPC, Afghanistan-Iran-Turkmenistan',
    'Mashhad 5MPC, Iran, Islamic Republic of': 'Mashhad 5MPC, Iran',
    'Mashhad Urban Center, Iran, Islamic Republic of': 'Mashhad Urban Center, Iran',
    'Masjed Soleyman Urban Center, Iran, Islamic Republic of': 'Masjed Soleyman Urban Center, Iran',
    'Matadi Urban Center, Congo, The Democratic Republic of the': 'Matadi Urban Center, Democratic Republic of the Congo',
    'Maturin Urban Center, Venezuela, Bolivarian Republic of': 'Maturin Urban Center, Venezuela',
    'Mayaguana, Bahamas': 'Mayaguana, The Bahamas',
    'Maykop Urban Center, Russian Federation': 'Maykop Urban Center, Russia',
    'Mbandaka 5MPC, Congo, The Democratic Republic of the-Congo-Gabon': 'Mbandaka 5MPC, Democratic Republic of the Congo-Congo-Gabon',
    'Mbandaka Urban Center, Congo, The Democratic Republic of the': 'Mbandaka Urban Center, Democratic Republic of the Congo',
    'Mbanza-Ngungu Urban Center, Congo, The Democratic Republic of the': 'Mbanza-Ngungu Urban Center, Democratic Republic of the Congo',
    'Mbarara 5MPC, Tanzania, United Republic of-Uganda-Rwanda': 'Mbarara 5MPC, Tanzania-Uganda-Rwanda',
    'Mbeya 10MPC, Tanzania, United Republic of-Malawi-Zambia': 'Mbeya 10MPC, Tanzania-Malawi-Zambia',
    'Mbeya 5MPC, Tanzania, United Republic of-Malawi': 'Mbeya 5MPC, Tanzania-Malawi',
    'Mbeya Urban Center, Tanzania, United Republic of': 'Mbeya Urban Center, Tanzania',
    'Mbeya, Tanzania, United Republic of': 'Mbeya, Tanzania',
    'Mbuji-Mayi 10MPC, Congo, The Democratic Republic of the': 'Mbuji-Mayi 10MPC, Democratic Republic of the Congo',
    'Mbuji-Mayi 20MPC, Congo, The Democratic Republic of the': 'Mbuji-Mayi 20MPC, Democratic Republic of the Congo',
    'Mbuji-Mayi 50MPC, Congo, The Democratic Republic of the-Tanzania, United Republic of-Zambia': 'Mbuji-Mayi 50MPC, Democratic Republic of the Congo-Tanzania-Zambia',
    'Mbuji-Mayi 5MPC, Congo, The Democratic Republic of the': 'Mbuji-Mayi 5MPC, Democratic Republic of the Congo',
    'Mbuji-Mayi Urban Center, Congo, The Democratic Republic of the': 'Mbuji-Mayi Urban Center, Democratic Republic of the Congo',
    'Mehrestan Urban Center, Iran, Islamic Republic of': 'Mehrestan Urban Center, Iran',
    'Meshginshahr Urban Center, Iran, Islamic Republic of': 'Meshginshahr Urban Center, Iran',
    'Mezhdurechensk Urban Center, Russian Federation': 'Mezhdurechensk Urban Center, Russia',
    'Miandoab Urban Center, Iran, Islamic Republic of': 'Miandoab Urban Center, Iran',
    'Mianeh Urban Center, Iran, Islamic Republic of': 'Mianeh Urban Center, Iran',
    'Miass Urban Center, Russian Federation': 'Miass Urban Center, Russia',
    'Michurinsk Urban Center, Russian Federation': 'Michurinsk Urban Center, Russia',
    'Micronesia, Federated States of': 'Micronesia',
    'Mindelo Urban Center, Cabo Verde': 'Mindelo Urban Center, Cape Verde',
    'Mineralnye Vody Urban Center, Russian Federation': 'Mineralnye Vody Urban Center, Russia',
    'Minh T\u00e2n Urban Center, Viet Nam': 'Minh T\u00e2n Urban Center, Vietnam',
    'Minsk 20MPC, Russian Federation-Belarus-Ukraine': 'Minsk 20MPC, Russia-Belarus-Ukraine',
    'Minusinsk Urban Center, Russian Federation': 'Minusinsk Urban Center, Russia',
    'Miranda, Venezuela, Bolivarian Republic of': 'Miranda, Venezuela',
    'Mirerani 5MPC, Tanzania, United Republic of': 'Mirerani 5MPC, Tanzania',
    'Mirerani Urban Center, Tanzania, United Republic of': 'Mirerani Urban Center, Tanzania',
    'Misisi Urban Center, Congo, The Democratic Republic of the': 'Misisi Urban Center, Democratic Republic of the Congo',
    'Mjini Magharibi, Tanzania, United Republic of': 'Mjini Magharibi, Tanzania',
    'Moanda Urban Center, Congo, The Democratic Republic of the': 'Moanda Urban Center, Democratic Republic of the Congo',
    'Modi\'in Illit Urban Center, Israel-Palestine, State of': 'Modi\'in Illit Urban Center, Israel-State of Palestine',
    'Mokpo-si Urban Center, Korea, Republic of': 'Mokpo-si Urban Center, South Korea',
    'Moldova, Republic of': 'Moldova',
    'Mombasa 10MPC, Tanzania, United Republic of-Kenya': 'Mombasa 10MPC, Tanzania-Kenya',
    'Mombasa 5MPC, Kenya-Tanzania, United Republic of': 'Mombasa 5MPC, Kenya-Tanzania',
    'Monagas, Venezuela, Bolivarian Republic of': 'Monagas, Venezuela',
    'Mong Cai 10MPC, China-Viet Nam': 'Mong Cai 10MPC, China-Vietnam',
    'Mong Cai Urban Center, China-Viet Nam': 'Mong Cai Urban Center, China-Vietnam',
    'Mongala, Congo, The Democratic Republic of the': 'Mongala, Democratic Republic of the Congo',
    'Mongbwalu 5MPC, Uganda-Congo, The Democratic Republic of the': 'Mongbwalu 5MPC, Uganda-Democratic Republic of the Congo',
    'Mongbwalu Urban Center, Congo, The Democratic Republic of the': 'Mongbwalu Urban Center, Democratic Republic of the Congo',
    'Montagnes, C\u00f4te d\'Ivoire': 'Montagnes, Ivory Coast',
    'Montero Urban Center, Bolivia, Plurinational State of': 'Montero Urban Center, Bolivia',
    'Moores Island, Bahamas': 'Moores Island, The Bahamas',
    'Moravskoslezsk\u00fd kraj, Czechia': 'Moravskoslezsk\u00fd kraj, Czech Republic',
    'Mordoviya, Respublika, Russian Federation': 'Mordoviya, Respublika, Russia',
    'Morogoro 10MPC, Tanzania, United Republic of-Mozambique-Zambia': 'Morogoro 10MPC, Tanzania-Mozambique-Zambia',
    'Morogoro Urban Center, Tanzania, United Republic of': 'Morogoro Urban Center, Tanzania',
    'Morogoro, Tanzania, United Republic of': 'Morogoro, Tanzania',
    'Moscow (Center North) 5MPC, Russian Federation': 'Moscow (Center North) 5MPC, Russia',
    'Moscow (Center South) 5MPC, Russian Federation': 'Moscow (Center South) 5MPC, Russia',
    'Moscow (Center) 10MPC, Russian Federation': 'Moscow (Center) 10MPC, Russia',
    'Moscow (Outer) 10MPC, Russian Federation': 'Moscow (Outer) 10MPC, Russia',
    'Moscow (Outer) 5MPC, Russian Federation': 'Moscow (Outer) 5MPC, Russia',
    'Moscow (Periphery) 5MPC, Russian Federation': 'Moscow (Periphery) 5MPC, Russia',
    'Moscow 100MPC, Ukraine-Russian Federation-Belarus': 'Moscow 100MPC, Ukraine-Russia-Belarus',
    'Moscow 200MPC, Ukraine-Russian Federation-Poland': 'Moscow 200MPC, Ukraine-Russia-Poland',
    'Moscow 20MPC, Russian Federation': 'Moscow 20MPC, Russia',
    'Moscow 50MPC, Russian Federation-Ukraine-Belarus': 'Moscow 50MPC, Russia-Ukraine-Belarus',
    'Moscow Urban Center, Russian Federation': 'Moscow Urban Center, Russia',
    'Moshi Urban Center, Tanzania, United Republic of': 'Moshi Urban Center, Tanzania',
    'Moskovskaya oblast\', Russian Federation': 'Moskovskaya oblast\', Russia',
    'Moskva, Russian Federation': 'Moskva, Russia',
    'Mosteiros, Cabo Verde': 'Mosteiros, Cape Verde',
    'Mosul 20MPC, Iraq-Iran, Islamic Republic of-Turkey': 'Mosul 20MPC, Iraq-Iran-Turkey',
    'Mpanda Urban Center, Tanzania, United Republic of': 'Mpanda Urban Center, Tanzania',
    'Mpose Urban Center, Congo, The Democratic Republic of the': 'Mpose Urban Center, Democratic Republic of the Congo',
    'Mtwara Urban Center, Tanzania, United Republic of': 'Mtwara Urban Center, Tanzania',
    'Mtwara, Tanzania, United Republic of': 'Mtwara, Tanzania',
    'Mudanjiang 10MPC, China-Korea, Democratic People\'s Republic of': 'Mudanjiang 10MPC, China-North Korea',
    'Mukanga Urban Center, Congo, The Democratic Republic of the': 'Mukanga Urban Center, Democratic Republic of the Congo',
    'Mukubu Urban Center, Congo, The Democratic Republic of the': 'Mukubu Urban Center, Democratic Republic of the Congo',
    'Mulongo Urban Center, Congo, The Democratic Republic of the': 'Mulongo Urban Center, Democratic Republic of the Congo',
    'Mululu Urban Center, Congo, The Democratic Republic of the': 'Mululu Urban Center, Democratic Republic of the Congo',
    'Munchon Urban Center, Korea, Democratic People\'s Republic of': 'Munchon Urban Center, North Korea',
    'Mundok Urban Center, Korea, Democratic People\'s Republic of': 'Mundok Urban Center, North Korea',
    'Munich 20MPC, Germany-Czechia': 'Munich 20MPC, Germany-Czech Republic',
    'Murmansk 10MPC, Russian Federation-Ukraine-Sweden': 'Murmansk 10MPC, Russia-Ukraine-Sweden',
    'Murmansk Urban Center, Russian Federation': 'Murmansk Urban Center, Russia',
    'Murmanskaya oblast\', Russian Federation': 'Murmanskaya oblast\', Russia',
    'Murom Urban Center, Russian Federation': 'Murom Urban Center, Russia',
    'Murubona Urban Center, Tanzania, United Republic of': 'Murubona Urban Center, Tanzania',
    'Musan Urban Center, China-Korea, Democratic People\'s Republic of': 'Musan Urban Center, China-North Korea',
    'Musoma 5MPC, Tanzania, United Republic of': 'Musoma 5MPC, Tanzania',
    'Musoma Urban Center, Tanzania, United Republic of': 'Musoma Urban Center, Tanzania',
    'Mutabi Urban Center, Congo, The Democratic Republic of the': 'Mutabi Urban Center, Democratic Republic of the Congo',
    'Mwadingusha Urban Center, Congo, The Democratic Republic of the': 'Mwadingusha Urban Center, Democratic Republic of the Congo',
    'Mwanza 10MPC, Tanzania, United Republic of': 'Mwanza 10MPC, Tanzania',
    'Mwanza 5MPC, Tanzania, United Republic of': 'Mwanza 5MPC, Tanzania',
    'Mwanza Urban Center, Tanzania, United Republic of': 'Mwanza Urban Center, Tanzania',
    'Mwanza, Tanzania, United Republic of': 'Mwanza, Tanzania',
    'Mweka Urban Center, Congo, The Democratic Republic of the': 'Mweka Urban Center, Democratic Republic of the Congo',
    'Mwene-Ditu Urban Center, Congo, The Democratic Republic of the': 'Mwene-Ditu Urban Center, Democratic Republic of the Congo',
    'My Tho 5MPC, Viet Nam': 'My Tho 5MPC, Vietnam',
    'My Tho Urban Center, Viet Nam': 'My Tho Urban Center, Vietnam',
    'Myeik 10MPC, Thailand-Myanmar-Viet Nam': 'Myeik 10MPC, Thailand-Myanmar-Vietnam',
    'M\u00e9-Z\u00f3chi, Sao Tome and Principe': 'M\u00e9-Z\u00f3chi, S\u00e3o Tom\u00e9 and Pr\u00edncipe',
    'M\u00e9agui Urban Center, C\u00f4te d\'Ivoire': 'M\u00e9agui Urban Center, Ivory Coast',
    'M\u00e9rida Urban Center, Venezuela, Bolivarian Republic of': 'M\u00e9rida Urban Center, Venezuela',
    'M\u00e9rida, Venezuela, Bolivarian Republic of': 'M\u00e9rida, Venezuela',
    'M\u0101zandar\u0101n, Iran, Islamic Republic of': 'M\u0101zandar\u0101n, Iran',
    'M\u1ef9 Xuy\u00ean Urban Center, Viet Nam': 'M\u1ef9 Xuy\u00ean Urban Center, Vietnam',
    'Naberezhnye Chelny Urban Center, Russian Federation': 'Naberezhnye Chelny Urban Center, Russia',
    'Nablus Urban Center, Palestine, State of': 'Nablus Urban Center, State of Palestine',
    'Nablus, Palestine, State of': 'Nablus, State of Palestine',
    'Naha (Outer) 5MPC, Russian Federation-Fiji-Japan': 'Naha (Outer) 5MPC, Russia-Fiji-Japan',
    'Naha 20MPC, China-Viet Nam-Philippines': 'Naha 20MPC, China-Vietnam-Philippines',
    'Nahavand Urban Center, Iran, Islamic Republic of': 'Nahavand Urban Center, Iran',
    'Nahodka Urban Center, Russian Federation': 'Nahodka Urban Center, Russia',
    'Nairobi 100MPC, Kenya-Tanzania, United Republic of-Congo, The Democratic Republic of the': 'Nairobi 100MPC, Kenya-Tanzania-Democratic Republic of the Congo',
    'Nairobi 50MPC, Tanzania, United Republic of-Kenya': 'Nairobi 50MPC, Tanzania-Kenya',
    'Najafabad Urban Center, Iran, Islamic Republic of': 'Najafabad Urban Center, Iran',
    'Nalchik Urban Center, Russian Federation': 'Nalchik Urban Center, Russia',
    'Nam \u00d0\u1ecbnh, Viet Nam': 'Nam \u00d0\u1ecbnh, Vietnam',
    'Nam \u0110\u1ecbnh 5MPC, Viet Nam': 'Nam \u0110\u1ecbnh 5MPC, Vietnam',
    'Nam \u0110\u1ecbnh Urban Center, Viet Nam': 'Nam \u0110\u1ecbnh Urban Center, Vietnam',
    'Namibe 5MPC, Congo, The Democratic Republic of the-Angola-Namibia': 'Namibe 5MPC, Democratic Republic of the Congo-Angola-Namibia',
    'Nampo Urban Center, Korea, Democratic People\'s Republic of': 'Nampo Urban Center, North Korea',
    'Nampula 20MPC, Mozambique-Tanzania, United Republic of-Malawi': 'Nampula 20MPC, Mozambique-Tanzania-Malawi',
    'Namp\u2019o [Nampho], Korea, Democratic People\'s Republic of': 'Namp\u2019o [Nampho], North Korea',
    'Nansio Urban Center, Tanzania, United Republic of': 'Nansio Urban Center, Tanzania',
    'Naqadeh Urban Center, Iran, Islamic Republic of': 'Naqadeh Urban Center, Iran',
    'Nasiriyah 5MPC, Iran, Islamic Republic of-Iraq-Saudi Arabia': 'Nasiriyah 5MPC, Iran-Iraq-Saudi Arabia',
    'Nassau Urban Center, Bahamas': 'Nassau Urban Center, The Bahamas',
    'Nazarabad Urban Center, Iran, Islamic Republic of': 'Nazarabad Urban Center, Iran',
    'Nazran Urban Center, Russian Federation': 'Nazran Urban Center, Russia',
    'Ndalama Urban Center, Congo, The Democratic Republic of the': 'Ndalama Urban Center, Democratic Republic of the Congo',
    'Ndiuka 5MPC, Tanzania, United Republic of': 'Ndiuka 5MPC, Tanzania',
    'Ndiuka Urban Center, Tanzania, United Republic of': 'Ndiuka Urban Center, Tanzania',
    'Neftekamsk Urban Center, Russian Federation': 'Neftekamsk Urban Center, Russia',
    'Neka Urban Center, Iran, Islamic Republic of': 'Neka Urban Center, Iran',
    'Nenetskiy avtonomnyy okrug, Russian Federation': 'Nenetskiy avtonomnyy okrug, Russia',
    'Nevinnomyssk Urban Center, Russian Federation': 'Nevinnomyssk Urban Center, Russia',
    'New Providence, Bahamas': 'New Providence, The Bahamas',
    'New Taipei [Taipei] 100MPC, China-Japan-Russian Federation': 'New Taipei [Taipei] 100MPC, China-Japan-Russia',
    'Neyshabur Urban Center, Iran, Islamic Republic of': 'Neyshabur Urban Center, Iran',
    'Nga S\u01a1n Urban Center, Viet Nam': 'Nga S\u01a1n Urban Center, Vietnam',
    'Ngai Giao Urban Center, Viet Nam': 'Ngai Giao Urban Center, Vietnam',
    'Ngandajika Urban Center, Congo, The Democratic Republic of the': 'Ngandajika Urban Center, Democratic Republic of the Congo',
    'Ngh\u1ec7 An, Viet Nam': 'Ngh\u1ec7 An, Vietnam',
    'Nha Trang 10MPC, Viet Nam': 'Nha Trang 10MPC, Vietnam',
    'Nha Trang 5MPC, Viet Nam': 'Nha Trang 5MPC, Vietnam',
    'Nha Trang Urban Center, Viet Nam': 'Nha Trang Urban Center, Vietnam',
    'Nicosia 5MPC, Syrian Arab Republic-Cyprus-Turkey': 'Nicosia 5MPC, Syria-Cyprus-Turkey',
    'Nimule 20MPC, Uganda-South Sudan-Congo, The Democratic Republic of the': 'Nimule 20MPC, Uganda-South Sudan-Democratic Republic of the Congo',
    'Ninh B\u00ecnh Urban Center, Viet Nam': 'Ninh B\u00ecnh Urban Center, Vietnam',
    'Ninh B\u00ecnh, Viet Nam': 'Ninh B\u00ecnh, Vietnam',
    'Ninh H\u00f2a Urban Center, Viet Nam': 'Ninh H\u00f2a Urban Center, Vietnam',
    'Ninh Thu\u1eadn, Viet Nam': 'Ninh Thu\u1eadn, Vietnam',
    'Nisporeni, Moldova, Republic of': 'Nisporeni, Moldova',
    'Nizhegorodskaya oblast\', Russian Federation': 'Nizhegorodskaya oblast\', Russia',
    'Nizhnekamsk Urban Center, Russian Federation': 'Nizhnekamsk Urban Center, Russia',
    'Nizhnevartovsk 5MPC, Russian Federation-Kazakhstan': 'Nizhnevartovsk 5MPC, Russia-Kazakhstan',
    'Nizhnevartovsk Urban Center, Russian Federation': 'Nizhnevartovsk Urban Center, Russia',
    'Nizhny Novgorod 10MPC, Russian Federation': 'Nizhny Novgorod 10MPC, Russia',
    'Nizhny Novgorod 20MPC, Russian Federation': 'Nizhny Novgorod 20MPC, Russia',
    'Nizhny Novgorod 50MPC, Russian Federation': 'Nizhny Novgorod 50MPC, Russia',
    'Nizhny Novgorod 5MPC, Russian Federation': 'Nizhny Novgorod 5MPC, Russia',
    'Nizhny Novgorod Urban Center, Russian Federation': 'Nizhny Novgorod Urban Center, Russia',
    'Nizhny Tagil Urban Center, Russian Federation': 'Nizhny Tagil Urban Center, Russia',
    'Njombe, Tanzania, United Republic of': 'Njombe, Tanzania',
    'Nong Khai Urban Center, Lao People\'s Democratic Republic-Thailand': 'Nong Khai Urban Center, Laos-Thailand',
    'Nord-Kivu, Congo, The Democratic Republic of the': 'Nord-Kivu, Democratic Republic of the Congo',
    'Nord-Ubangi, Congo, The Democratic Republic of the': 'Nord-Ubangi, Democratic Republic of the Congo',
    'Norilsk Urban Center, Russian Federation': 'Norilsk Urban Center, Russia',
    'North Abaco, Bahamas': 'North Abaco, The Bahamas',
    'North Andros, Bahamas': 'North Andros, The Bahamas',
    'North Bank, Gambia': 'North Bank, The Gambia',
    'North Eleuthera, Bahamas': 'North Eleuthera, The Bahamas',
    'North Gaza, Palestine, State of': 'North Gaza, State of Palestine',
    'Novgorodskaya oblast\', Russian Federation': 'Novgorodskaya oblast\', Russia',
    'Novocheboksarsk Urban Center, Russian Federation': 'Novocheboksarsk Urban Center, Russia',
    'Novocherkassk Urban Center, Russian Federation': 'Novocherkassk Urban Center, Russia',
    'Novokuybyshevsk Urban Center, Russian Federation': 'Novokuybyshevsk Urban Center, Russia',
    'Novokuznetsk Urban Center, Russian Federation': 'Novokuznetsk Urban Center, Russia',
    'Novorossiysk Urban Center, Russian Federation': 'Novorossiysk Urban Center, Russia',
    'Novosibirsk 10MPC, Russian Federation': 'Novosibirsk 10MPC, Russia',
    'Novosibirsk 20MPC, Russian Federation-China-Kazakhstan': 'Novosibirsk 20MPC, Russia-China-Kazakhstan',
    'Novosibirsk 5MPC, Russian Federation': 'Novosibirsk 5MPC, Russia',
    'Novosibirsk Urban Center, Russian Federation': 'Novosibirsk Urban Center, Russia',
    'Novosibirskaya oblast\', Russian Federation': 'Novosibirskaya oblast\', Russia',
    'Novotroitsk Urban Center, Russian Federation': 'Novotroitsk Urban Center, Russia',
    'Novouralsk Urban Center, Russian Federation': 'Novouralsk Urban Center, Russia',
    'Novy Urengoy Urban Center, Russian Federation': 'Novy Urengoy Urban Center, Russia',
    'Nueva Esparta, Venezuela, Bolivarian Republic of': 'Nueva Esparta, Venezuela',
    'Nukus 10MPC, Turkmenistan-Uzbekistan-Iran, Islamic Republic of': 'Nukus 10MPC, Turkmenistan-Uzbekistan-Iran',
    'Nurabad Mamasani Urban Center, Iran, Islamic Republic of': 'Nurabad Mamasani Urban Center, Iran',
    'Nurabad Urban Center, Iran, Islamic Republic of': 'Nurabad Urban Center, Iran',
    'Nyamilima Urban Center, Congo, The Democratic Republic of the': 'Nyamilima Urban Center, Democratic Republic of the Congo',
    'Nyunzu Urban Center, Congo, The Democratic Republic of the': 'Nyunzu Urban Center, Democratic Republic of the Congo',
    'Nzega Urban Center, Tanzania, United Republic of': 'Nzega Urban Center, Tanzania',
    'Nz\u00e9r\u00e9kor\u00e9 5MPC, C\u00f4te d\'Ivoire-Guinea': 'Nz\u00e9r\u00e9kor\u00e9 5MPC, Ivory Coast-Guinea',
    'N\u00fai S\u1eadp Urban Center, Viet Nam': 'N\u00fai S\u1eadp Urban Center, Vietnam',
    'Obninsk Urban Center, Russian Federation': 'Obninsk Urban Center, Russia',
    'Ocni\u021ba, Moldova, Republic of': 'Ocni\u021ba, Moldova',
    'Ocumare del Tuy Urban Center, Venezuela, Bolivarian Republic of': 'Ocumare del Tuy Urban Center, Venezuela',
    'Odesa 10MPC, Ukraine-Moldova, Republic of': 'Odesa 10MPC, Ukraine-Moldova',
    'Oicha Urban Center, Congo, The Democratic Republic of the': 'Oicha Urban Center, Democratic Republic of the Congo',
    'Okpo Urban Center, Korea, Republic of': 'Okpo Urban Center, South Korea',
    'Oktjabrski Urban Center, Russian Federation': 'Oktjabrski Urban Center, Russia',
    'Olomouc Urban Center, Czechia': 'Olomouc Urban Center, Czech Republic',
    'Olomouck\u00fd kraj, Czechia': 'Olomouck\u00fd kraj, Czech Republic',
    'Omidieh Urban Center, Iran, Islamic Republic of': 'Omidieh Urban Center, Iran',
    'Omong-ri Urban Center, Korea, Democratic People\'s Republic of': 'Omong-ri Urban Center, North Korea',
    'Omsk 10MPC, Kazakhstan-Russian Federation': 'Omsk 10MPC, Kazakhstan-Russia',
    'Omsk 5MPC, Kazakhstan-Russian Federation': 'Omsk 5MPC, Kazakhstan-Russia',
    'Omsk Urban Center, Russian Federation': 'Omsk Urban Center, Russia',
    'Omskaya oblast\', Russian Federation': 'Omskaya oblast\', Russia',
    'Onchon Urban Center, Korea, Democratic People\'s Republic of': 'Onchon Urban Center, North Korea',
    'Ongjin Urban Center, Korea, Democratic People\'s Republic of': 'Ongjin Urban Center, North Korea',
    'Ont\u2019an-nodongjagu Urban Center, Korea, Democratic People\'s Republic of': 'Ont\u2019an-nodongjagu Urban Center, North Korea',
    'Orekhovo-Zou\u00efevo Urban Center, Russian Federation': 'Orekhovo-Zou\u00efevo Urban Center, Russia',
    'Orenburg 5MPC, Russian Federation-Kazakhstan': 'Orenburg 5MPC, Russia-Kazakhstan',
    'Orenburg Urban Center, Russian Federation': 'Orenburg Urban Center, Russia',
    'Orenburgskaya oblast\', Russian Federation': 'Orenburgskaya oblast\', Russia',
    'Orhei, Moldova, Republic of': 'Orhei, Moldova',
    'Orlovskaya oblast\', Russian Federation': 'Orlovskaya oblast\', Russia',
    'Orsk Urban Center, Russian Federation': 'Orsk Urban Center, Russia',
    'Oruro Urban Center, Bolivia, Plurinational State of': 'Oruro Urban Center, Bolivia',
    'Oruro, Bolivia, Plurinational State of': 'Oruro, Bolivia',
    'Oryol Urban Center, Russian Federation': 'Oryol Urban Center, Russia',
    'Osaka [Kyoto] 200MPC, Japan-China-Korea, Republic of': 'Osaka [Kyoto] 200MPC, Japan-China-South Korea',
    'Oslo 20MPC, Russian Federation-Norway-Finland': 'Oslo 20MPC, Russia-Norway-Finland',
    'Ostrava Urban Center, Czechia': 'Ostrava Urban Center, Czech Republic',
    'Ouangolodougou Urban Center, C\u00f4te d\'Ivoire': 'Ouangolodougou Urban Center, Ivory Coast',
    'Oud\u00f4mxai, Lao People\'s Democratic Republic': 'Oud\u00f4mxai, Laos',
    'Oum\u00e9 Urban Center, C\u00f4te d\'Ivoire': 'Oum\u00e9 Urban Center, Ivory Coast',
    'O\u00e9-Cusse Ambeno, Timor-Leste': 'O\u00e9-Cusse Ambeno, East Timor',
    'P\'y\u014fnghwa-ri Urban Center, Korea, Democratic People\'s Republic of': 'P\'y\u014fnghwa-ri Urban Center, North Korea',
    'P\'y\u014fngs\u014fng 5MPC, Korea, Democratic People\'s Republic of': 'P\'y\u014fngs\u014fng 5MPC, North Korea',
    'P\'y\u014fngs\u014fng Urban Center, Korea, Democratic People\'s Republic of': 'P\'y\u014fngs\u014fng Urban Center, North Korea',
    'Paechon Urban Center, Korea, Democratic People\'s Republic of': 'Paechon Urban Center, North Korea',
    'Pakdasht Urban Center, Iran, Islamic Republic of': 'Pakdasht Urban Center, Iran',
    'Pakse Urban Center, Lao People\'s Democratic Republic': 'Pakse Urban Center, Laos',
    'Palestine, State of': 'State of Palestine',
    'Pando, Bolivia, Plurinational State of': 'Pando, Bolivia',
    'Pardis Urban Center, Iran, Islamic Republic of': 'Pardis Urban Center, Iran',
    'Pardisan Urban Center, Iran, Islamic Republic of': 'Pardisan Urban Center, Iran',
    'Pardubice Urban Center, Czechia': 'Pardubice Urban Center, Czech Republic',
    'Pardubick\u00fd kraj, Czechia': 'Pardubick\u00fd kraj, Czech Republic',
    'Pars Abad Urban Center, Iran, Islamic Republic of': 'Pars Abad Urban Center, Iran',
    'Paul, Cabo Verde': 'Paul, Cape Verde',
    'Paya Urban Center, Venezuela, Bolivarian Republic of': 'Paya Urban Center, Venezuela',
    'Penza Urban Center, Russian Federation': 'Penza Urban Center, Russia',
    'Penzenskaya oblast\', Russian Federation': 'Penzenskaya oblast\', Russia',
    'Perm Urban Center, Russian Federation': 'Perm Urban Center, Russia',
    'Permskiy kray, Russian Federation': 'Permskiy kray, Russia',
    'Perth 10MPC, Australia-Russian Federation-Japan': 'Perth 10MPC, Australia-Russia-Japan',
    'Peterhof Urban Center, Russian Federation': 'Peterhof Urban Center, Russia',
    'Petropavlovsk-Kamchatsky Urban Center, Russian Federation': 'Petropavlovsk-Kamchatsky Urban Center, Russia',
    'Petrozavodsk Urban Center, Russian Federation': 'Petrozavodsk Urban Center, Russia',
    'Phan Rang - Th\u00e1p Ch\u00e0m Urban Center, Viet Nam': 'Phan Rang - Th\u00e1p Ch\u00e0m Urban Center, Vietnam',
    'Phan R\u00ed C\u1eeda Urban Center, Viet Nam': 'Phan R\u00ed C\u1eeda Urban Center, Vietnam',
    'Phan Thiet 5MPC, Viet Nam-Cambodia': 'Phan Thiet 5MPC, Vietnam-Cambodia',
    'Phan Thiet Urban Center, Viet Nam': 'Phan Thiet Urban Center, Vietnam',
    'Phat Diem Urban Center, Viet Nam': 'Phat Diem Urban Center, Vietnam',
    'Phnom Penh 10MPC, Cambodia-Viet Nam': 'Phnom Penh 10MPC, Cambodia-Vietnam',
    'Phnom Penh 20MPC, Viet Nam-Cambodia': 'Phnom Penh 20MPC, Vietnam-Cambodia',
    'Phnom Penh 5MPC, Cambodia-Viet Nam': 'Phnom Penh 5MPC, Cambodia-Vietnam',
    'Phyongsan Urban Center, Korea, Democratic People\'s Republic of': 'Phyongsan Urban Center, North Korea',
    'Phyongwon Urban Center, Korea, Democratic People\'s Republic of': 'Phyongwon Urban Center, North Korea',
    'Ph\u00f4ngsali, Lao People\'s Democratic Republic': 'Ph\u00f4ngsali, Laos',
    'Ph\u00f9 Y\u00ean Urban Center, Viet Nam': 'Ph\u00f9 Y\u00ean Urban Center, Vietnam',
    'Ph\u00f9ng Urban Center, Viet Nam': 'Ph\u00f9ng Urban Center, Vietnam',
    'Ph\u00fa Minh Urban Center, Viet Nam': 'Ph\u00fa Minh Urban Center, Vietnam',
    'Ph\u00fa M\u1ef9 Urban Center, Viet Nam': 'Ph\u00fa M\u1ef9 Urban Center, Vietnam',
    'Ph\u00fa Phong Urban Center, Viet Nam': 'Ph\u00fa Phong Urban Center, Vietnam',
    'Ph\u00fa Th\u1ecd, Viet Nam': 'Ph\u00fa Th\u1ecd, Vietnam',
    'Ph\u00fa Y\u00ean, Viet Nam': 'Ph\u00fa Y\u00ean, Vietnam',
    'Ph\u00fac Th\u1ecd Urban Center, Viet Nam': 'Ph\u00fac Th\u1ecd Urban Center, Vietnam',
    'Ph\u1ee7 L\u00fd Urban Center, Viet Nam': 'Ph\u1ee7 L\u00fd Urban Center, Vietnam',
    'Picha Ya Ndege Urban Center, Tanzania, United Republic of': 'Picha Ya Ndege Urban Center, Tanzania',
    'Pilsen Urban Center, Czechia': 'Pilsen Urban Center, Czech Republic',
    'Piranshahr Urban Center, Iran, Islamic Republic of': 'Piranshahr Urban Center, Iran',
    'Pitcairn': 'Pitcairn Islands',
    'Pitcairn (UK), Pitcairn': 'Pitcairn (UK), Pitcairn Islands',
    'Pleiku Urban Center, Viet Nam': 'Pleiku Urban Center, Vietnam',
    'Plze\u0148sk\u00fd kraj, Czechia': 'Plze\u0148sk\u00fd kraj, Czech Republic',
    'Podstepnovka Urban Center, Russian Federation': 'Podstepnovka Urban Center, Russia',
    'Pohang-si Urban Center, Korea, Republic of': 'Pohang-si Urban Center, South Korea',
    'Pohnpei, Micronesia, Federated States of': 'Pohnpei, Micronesia',
    'Porlamar Urban Center, Venezuela, Bolivarian Republic of': 'Porlamar Urban Center, Venezuela',
    'Porto Novo, Cabo Verde': 'Porto Novo, Cape Verde',
    'Portuguesa, Venezuela, Bolivarian Republic of': 'Portuguesa, Venezuela',
    'Potos\u00ed Urban Center, Bolivia, Plurinational State of': 'Potos\u00ed Urban Center, Bolivia',
    'Potos\u00ed, Bolivia, Plurinational State of': 'Potos\u00ed, Bolivia',
    'Prague 10MPC, Czechia-Poland': 'Prague 10MPC, Czech Republic-Poland',
    'Prague 5MPC, Czechia-Germany': 'Prague 5MPC, Czech Republic-Germany',
    'Prague Urban Center, Czechia': 'Prague Urban Center, Czech Republic',
    'Praha, Hlavn\u00ed m\u011bsto, Czechia': 'Praha, Hlavn\u00ed m\u011bsto, Czech Republic',
    'Praia, Cabo Verde': 'Praia, Cape Verde',
    'Primorskiy kray, Russian Federation': 'Primorskiy kray, Russia',
    'Prokhladny Urban Center, Russian Federation': 'Prokhladny Urban Center, Russia',
    'Pr\u00edncipe, Sao Tome and Principe': 'Pr\u00edncipe, S\u00e3o Tom\u00e9 and Pr\u00edncipe',
    'Pskov Urban Center, Russian Federation': 'Pskov Urban Center, Russia',
    'Pskovskaya oblast\', Russian Federation': 'Pskovskaya oblast\', Russia',
    'Pu\'er 10MPC, China-Lao People\'s Democratic Republic-Thailand': 'Pu\'er 10MPC, China-Laos-Thailand',
    'Puerto Ayacucho Urban Center, Venezuela, Bolivarian Republic of': 'Puerto Ayacucho Urban Center, Venezuela',
    'Puerto Cabello Urban Center, Venezuela, Bolivarian Republic of': 'Puerto Cabello Urban Center, Venezuela',
    'Punia Urban Center, Congo, The Democratic Republic of the': 'Punia Urban Center, Democratic Republic of the Congo',
    'Punta de Mata Urban Center, Venezuela, Bolivarian Republic of': 'Punta de Mata Urban Center, Venezuela',
    'Punto Fijo Urban Center, Venezuela, Bolivarian Republic of': 'Punto Fijo Urban Center, Venezuela',
    'Pushkin Urban Center, Russian Federation': 'Pushkin Urban Center, Russia',
    'Pwani, Tanzania, United Republic of': 'Pwani, Tanzania',
    'Pweto Urban Center, Congo, The Democratic Republic of the-Zambia': 'Pweto Urban Center, Democratic Republic of the Congo-Zambia',
    'Pyatigorsk Urban Center, Russian Federation': 'Pyatigorsk Urban Center, Russia',
    'Pyeongtaek-si Urban Center, Korea, Republic of': 'Pyeongtaek-si Urban Center, South Korea',
    'Pyoksong Urban Center, Korea, Democratic People\'s Republic of': 'Pyoksong Urban Center, North Korea',
    'Pyongyang 10MPC, Korea, Democratic People\'s Republic of': 'Pyongyang 10MPC, North Korea',
    'Pyongyang 20MPC, Korea, Democratic People\'s Republic of-Korea, Republic of': 'Pyongyang 20MPC, North Korea-South Korea',
    'Pyongyang 5MPC, Korea, Democratic People\'s Republic of': 'Pyongyang 5MPC, North Korea',
    'Pyongyang Urban Center, Korea, Democratic People\'s Republic of': 'Pyongyang Urban Center, North Korea',
    'P\u2019y\u014fngan-bukto [Phyeonganpukto], Korea, Democratic People\'s Republic of': 'P\u2019y\u014fngan-bukto [Phyeonganpukto], North Korea',
    'P\u2019y\u014fngan-namdo [Phyeongannamto], Korea, Democratic People\'s Republic of': 'P\u2019y\u014fngan-namdo [Phyeongannamto], North Korea',
    'P\u2019y\u014fngyang [Phyeongyang], Korea, Democratic People\'s Republic of': 'P\u2019y\u014fngyang [Phyeongyang], North Korea',
    'Qabatiya Urban Center, Palestine, State of': 'Qabatiya Urban Center, State of Palestine',
    'Qaem Shahr Urban Center, Iran, Islamic Republic of': 'Qaem Shahr Urban Center, Iran',
    'Qaen Urban Center, Iran, Islamic Republic of': 'Qaen Urban Center, Iran',
    'Qalqilya, Palestine, State of': 'Qalqilya, State of Palestine',
    'Qamishli Urban Center, Syrian Arab Republic-Turkey': 'Qamishli Urban Center, Syria-Turkey',
    'Qarchak Urban Center, Iran, Islamic Republic of': 'Qarchak Urban Center, Iran',
    'Qazvin Urban Center, Iran, Islamic Republic of': 'Qazvin Urban Center, Iran',
    'Qazv\u012bn, Iran, Islamic Republic of': 'Qazv\u012bn, Iran',
    'Qeshm Urban Center, Iran, Islamic Republic of': 'Qeshm Urban Center, Iran',
    'Qom 5MPC, Iran, Islamic Republic of': 'Qom 5MPC, Iran',
    'Qom Urban Center, Iran, Islamic Republic of': 'Qom Urban Center, Iran',
    'Qom, Iran, Islamic Republic of': 'Qom, Iran',
    'Quang Minh Urban Center, Viet Nam': 'Quang Minh Urban Center, Vietnam',
    'Quchan Urban Center, Iran, Islamic Republic of': 'Quchan Urban Center, Iran',
    'Quelimane 20MPC, Madagascar-Mozambique-Tanzania, United Republic of': 'Quelimane 20MPC, Madagascar-Mozambique-Tanzania',
    'Quezon City [Manila] 200MPC, Philippines-Viet Nam-China': 'Quezon City [Manila] 200MPC, Philippines-Vietnam-China',
    'Quy Nh\u01a1n 5MPC, Viet Nam-Thailand-Cambodia': 'Quy Nh\u01a1n 5MPC, Vietnam-Thailand-Cambodia',
    'Quy Nh\u01a1n Urban Center, Viet Nam': 'Quy Nh\u01a1n Urban Center, Vietnam',
    'Qu\u00edbor Urban Center, Venezuela, Bolivarian Republic of': 'Qu\u00edbor Urban Center, Venezuela',
    'Qu\u1ea3ng B\u00ecnh, Viet Nam': 'Qu\u1ea3ng B\u00ecnh, Vietnam',
    'Qu\u1ea3ng Nam, Viet Nam': 'Qu\u1ea3ng Nam, Vietnam',
    'Qu\u1ea3ng Ng\u00e3i 5MPC, Viet Nam': 'Qu\u1ea3ng Ng\u00e3i 5MPC, Vietnam',
    'Qu\u1ea3ng Ng\u00e3i Urban Center, Viet Nam': 'Qu\u1ea3ng Ng\u00e3i Urban Center, Vietnam',
    'Qu\u1ea3ng Ng\u00e3i, Viet Nam': 'Qu\u1ea3ng Ng\u00e3i, Vietnam',
    'Qu\u1ea3ng Ninh, Viet Nam': 'Qu\u1ea3ng Ninh, Vietnam',
    'Qu\u1ea3ng Ph\u00fa Urban Center, Viet Nam': 'Qu\u1ea3ng Ph\u00fa Urban Center, Vietnam',
    'Qu\u1ea3ng Tr\u1ecb, Viet Nam': 'Qu\u1ea3ng Tr\u1ecb, Vietnam',
    'Qu\u1ed1c Oai Urban Center, Viet Nam': 'Qu\u1ed1c Oai Urban Center, Vietnam',
    'Rafah, Palestine, State of': 'Rafah, State of Palestine',
    'Rafsanjan Urban Center, Iran, Islamic Republic of': 'Rafsanjan Urban Center, Iran',
    'Ragged Island, Bahamas': 'Ragged Island, The Bahamas',
    'Rah\u016dng Urban Center, Korea, Democratic People\'s Republic of': 'Rah\u016dng Urban Center, North Korea',
    'Ramadi 5MPC, Iraq-Iran, Islamic Republic of': 'Ramadi 5MPC, Iraq-Iran',
    'Ramallah, Palestine, State of': 'Ramallah, State of Palestine',
    'Ramhormoz Urban Center, Iran, Islamic Republic of': 'Ramhormoz Urban Center, Iran',
    'Rasht 5MPC, Iran, Islamic Republic of': 'Rasht 5MPC, Iran',
    'Rasht Urban Center, Iran, Islamic Republic of': 'Rasht Urban Center, Iran',
    'Rason Urban Center, Korea, Democratic People\'s Republic of': 'Rason Urban Center, North Korea',
    'Ras\u014fn (Najin-S\u014fnbong) [Raseon], Korea, Democratic People\'s Republic of': 'Ras\u014fn (Najin-S\u014fnbong) [Raseon], North Korea',
    'Regensburg 5MPC, Germany-Austria-Czechia': 'Regensburg 5MPC, Germany-Austria-Czech Republic',
    'Rezina, Moldova, Republic of': 'Rezina, Moldova',
    'Ribeira Brava, Cabo Verde': 'Ribeira Brava, Cape Verde',
    'Ribeira Grande de Santiago, Cabo Verde': 'Ribeira Grande de Santiago, Cape Verde',
    'Ribeira Grande, Cabo Verde': 'Ribeira Grande, Cape Verde',
    'Riberalta Urban Center, Bolivia, Plurinational State of': 'Riberalta Urban Center, Bolivia',
    'Riga 5MPC, Finland-Latvia-Russian Federation': 'Riga 5MPC, Finland-Latvia-Russia',
    'Rio Branco 5MPC, Peru-Bolivia, Plurinational State of-Brazil': 'Rio Branco 5MPC, Peru-Bolivia-Brazil',
    'Riwon Urban Center, Korea, Democratic People\'s Republic of': 'Riwon Urban Center, North Korea',
    'Riyadh 50MPC, Iran, Islamic Republic of-Saudi Arabia-United Arab Emirates': 'Riyadh 50MPC, Iran-Saudi Arabia-United Arab Emirates',
    'Rooh Abad Urban Center, Iran, Islamic Republic of': 'Rooh Abad Urban Center, Iran',
    'Rostov-on-Don 10MPC, Russian Federation-Ukraine': 'Rostov-on-Don 10MPC, Russia-Ukraine',
    'Rostov-on-Don 50MPC, Russian Federation-Ukraine-Turkey': 'Rostov-on-Don 50MPC, Russia-Ukraine-Turkey',
    'Rostov-on-Don 5MPC, Russian Federation': 'Rostov-on-Don 5MPC, Russia',
    'Rostov-on-Don Urban Center, Russian Federation': 'Rostov-on-Don Urban Center, Russia',
    'Rostovskaya oblast\', Russian Federation': 'Rostovskaya oblast\', Russia',
    'Roudan Urban Center, Iran, Islamic Republic of': 'Roudan Urban Center, Iran',
    'Rubaya Urban Center, Congo, The Democratic Republic of the': 'Rubaya Urban Center, Democratic Republic of the Congo',
    'Rubio Urban Center, Venezuela, Bolivarian Republic of': 'Rubio Urban Center, Venezuela',
    'Rubtsovsk Urban Center, Russian Federation': 'Rubtsovsk Urban Center, Russia',
    'Rukwa, Tanzania, United Republic of': 'Rukwa, Tanzania',
    'Rum Cay, Bahamas': 'Rum Cay, The Bahamas',
    'Russian Federation': 'Russia',
    'Ruvuma, Tanzania, United Republic of': 'Ruvuma, Tanzania',
    'Ryanggang-do [Ryangkangto], Korea, Democratic People\'s Republic of': 'Ryanggang-do [Ryangkangto], North Korea',
    'Ryazan Urban Center, Russian Federation': 'Ryazan Urban Center, Russia',
    'Ryazanskaya oblast\', Russian Federation': 'Ryazanskaya oblast\', Russia',
    'Rybnitsa Urban Center, Moldova, Republic of': 'Rybnitsa Urban Center, Moldova',
    'Ryongchon Urban Center, Korea, Democratic People\'s Republic of': 'Ryongchon Urban Center, North Korea',
    'R\u00edo Piedras [San Juan] 20MPC, Venezuela, Bolivarian Republic of-USA-Colombia': 'R\u00edo Piedras [San Juan] 20MPC, Venezuela-USA-Colombia',
    'R\u00ee\u0219cani, Moldova, Republic of': 'R\u00ee\u0219cani, Moldova',
    'R\u012bf Dimashq, Syrian Arab Republic': 'R\u012bf Dimashq, Syria',
    'R\u1ea1ch Gi\u00e1 5MPC, Viet Nam': 'R\u1ea1ch Gi\u00e1 5MPC, Vietnam',
    'R\u1ea1ch Gi\u00e1 Urban Center, Viet Nam': 'R\u1ea1ch Gi\u00e1 Urban Center, Vietnam',
    'Sa Dec City Urban Center, Viet Nam': 'Sa Dec City Urban Center, Vietnam',
    'Sabana de Parra Urban Center, Venezuela, Bolivarian Republic of': 'Sabana de Parra Urban Center, Venezuela',
    'Sabzevar Urban Center, Iran, Islamic Republic of': 'Sabzevar Urban Center, Iran',
    'Safadasht Urban Center, Iran, Islamic Republic of': 'Safadasht Urban Center, Iran',
    'Saint Petersburg 100MPC, Romania-Russian Federation-Germany': 'Saint Petersburg 100MPC, Romania-Russia-Germany',
    'Saint Petersburg 10MPC, Russian Federation-Finland': 'Saint Petersburg 10MPC, Russia-Finland',
    'Saint Petersburg 200MPC, Morocco-United Kingdom-Russian Federation': 'Saint Petersburg 200MPC, Morocco-United Kingdom-Russia',
    'Saint Petersburg 20MPC, Russian Federation-Lithuania-Latvia': 'Saint Petersburg 20MPC, Russia-Lithuania-Latvia',
    'Saint Petersburg 50MPC, Ukraine-Russian Federation-Bulgaria': 'Saint Petersburg 50MPC, Ukraine-Russia-Bulgaria',
    'Saint Petersburg 5MPC, Russian Federation': 'Saint Petersburg 5MPC, Russia',
    'Saint Petersburg Urban Center, Russian Federation': 'Saint Petersburg Urban Center, Russia',
    'Sake Urban Center, Congo, The Democratic Republic of the': 'Sake Urban Center, Democratic Republic of the Congo',
    'Sakha, Respublika, Russian Federation': 'Sakha, Respublika, Russia',
    'Sakhalinskaya oblast\', Russian Federation': 'Sakhalinskaya oblast\', Russia',
    'Sal, Cabo Verde': 'Sal, Cape Verde',
    'Salavan, Lao People\'s Democratic Republic': 'Salavan, Laos',
    'Salavat Urban Center, Russian Federation': 'Salavat Urban Center, Russia',
    'Salfit, Palestine, State of': 'Salfit, State of Palestine',
    'Salmas Urban Center, Iran, Islamic Republic of': 'Salmas Urban Center, Iran',
    'Samara 20MPC, Russian Federation-Kazakhstan': 'Samara 20MPC, Russia-Kazakhstan',
    'Samara 5MPC, Russian Federation': 'Samara 5MPC, Russia',
    'Samara Urban Center, Russian Federation': 'Samara Urban Center, Russia',
    'Samarskaya oblast\', Russian Federation': 'Samarskaya oblast\', Russia',
    'Samba Urban Center, Congo, The Democratic Republic of the': 'Samba Urban Center, Democratic Republic of the Congo',
    'Samsun 10MPC, Russian Federation-Turkey-Georgia': 'Samsun 10MPC, Russia-Turkey-Georgia',
    'San Carlos Urban Center, Venezuela, Bolivarian Republic of': 'San Carlos Urban Center, Venezuela',
    'San Crist\u00f3bal Urban Center, Venezuela, Bolivarian Republic of': 'San Crist\u00f3bal Urban Center, Venezuela',
    'San Fernando de Apure Urban Center, Venezuela, Bolivarian Republic of': 'San Fernando de Apure Urban Center, Venezuela',
    'San F\u00e9lix Urban Center, Venezuela, Bolivarian Republic of': 'San F\u00e9lix Urban Center, Venezuela',
    'San Juan de Col\u00f3n Urban Center, Venezuela, Bolivarian Republic of': 'San Juan de Col\u00f3n Urban Center, Venezuela',
    'San Juan de Los Morros Urban Center, Venezuela, Bolivarian Republic of': 'San Juan de Los Morros Urban Center, Venezuela',
    'San Mateo Urban Center, Venezuela, Bolivarian Republic of': 'San Mateo Urban Center, Venezuela',
    'San Rafael del Mojan Urban Center, Venezuela, Bolivarian Republic of': 'San Rafael del Mojan Urban Center, Venezuela',
    'San Salvador, Bahamas': 'San Salvador, The Bahamas',
    'San-P\u00e9dro 5MPC, C\u00f4te d\'Ivoire-Liberia': 'San-P\u00e9dro 5MPC, Ivory Coast-Liberia',
    'San-P\u00e9dro Urban Center, C\u00f4te d\'Ivoire': 'San-P\u00e9dro Urban Center, Ivory Coast',
    'Sanandaj Urban Center, Iran, Islamic Republic of': 'Sanandaj Urban Center, Iran',
    'Sandoa Urban Center, Congo, The Democratic Republic of the': 'Sandoa Urban Center, Democratic Republic of the Congo',
    'Sankt-Peterburg, Russian Federation': 'Sankt-Peterburg, Russia',
    'Sankuru, Congo, The Democratic Republic of the': 'Sankuru, Democratic Republic of the Congo',
    'Santa B\u00e1rbara del Zulia Urban Center, Venezuela, Bolivarian Republic of': 'Santa B\u00e1rbara del Zulia Urban Center, Venezuela',
    'Santa Catarina do Fogo, Cabo Verde': 'Santa Catarina do Fogo, Cape Verde',
    'Santa Catarina, Cabo Verde': 'Santa Catarina, Cape Verde',
    'Santa Cruz Urban Center, Venezuela, Bolivarian Republic of': 'Santa Cruz Urban Center, Venezuela',
    'Santa Cruz de la Sierra 5MPC, Bolivia, Plurinational State of': 'Santa Cruz de la Sierra 5MPC, Bolivia',
    'Santa Cruz de la Sierra Urban Center, Bolivia, Plurinational State of': 'Santa Cruz de la Sierra Urban Center, Bolivia',
    'Santa Cruz, Bolivia, Plurinational State of': 'Santa Cruz, Bolivia',
    'Santa Cruz, Cabo Verde': 'Santa Cruz, Cape Verde',
    'Santa Rita Urban Center, Venezuela, Bolivarian Republic of': 'Santa Rita Urban Center, Venezuela',
    'Santa Teresa del Tuy Urban Center, Venezuela, Bolivarian Republic of': 'Santa Teresa del Tuy Urban Center, Venezuela',
    'Santo Domingo 50MPC, Venezuela, Bolivarian Republic of-USA-Costa Rica': 'Santo Domingo 50MPC, Venezuela-USA-Costa Rica',
    'Sao Tome and Principe': 'S\u00e3o Tom\u00e9 and Pr\u00edncipe',
    'Sapporo 10MPC, Japan-Russian Federation-China': 'Sapporo 10MPC, Japan-Russia-China',
    'Sapporo 50MPC, Japan-China-Russian Federation': 'Sapporo 50MPC, Japan-China-Russia',
    'Saqqez Urban Center, Iran, Islamic Republic of': 'Saqqez Urban Center, Iran',
    'Sarajevo 5MPC, Russian Federation-Norway-Bosnia and Herzegovina': 'Sarajevo 5MPC, Russia-Norway-Bosnia and Herzegovina',
    'Sarakhs Urban Center, Iran, Islamic Republic of': 'Sarakhs Urban Center, Iran',
    'Saransk Urban Center, Russian Federation': 'Saransk Urban Center, Russia',
    'Sarapul Urban Center, Russian Federation': 'Sarapul Urban Center, Russia',
    'Saratov 10MPC, Russian Federation': 'Saratov 10MPC, Russia',
    'Saratov Urban Center, Russian Federation': 'Saratov Urban Center, Russia',
    'Saratovskaya oblast\', Russian Federation': 'Saratovskaya oblast\', Russia',
    'Saravan Urban Center, Iran, Islamic Republic of': 'Saravan Urban Center, Iran',
    'Sardasht Urban Center, Iran, Islamic Republic of': 'Sardasht Urban Center, Iran',
    'Sari 5MPC, Iran, Islamic Republic of': 'Sari 5MPC, Iran',
    'Sari Urban Center, Iran, Islamic Republic of': 'Sari Urban Center, Iran',
    'Sariw\u014fn Urban Center, Korea, Democratic People\'s Republic of': 'Sariw\u014fn Urban Center, North Korea',
    'Sarpol-e Zahab Urban Center, Iran, Islamic Republic of': 'Sarpol-e Zahab Urban Center, Iran',
    'Sassandra-Marahou\u00e9, C\u00f4te d\'Ivoire': 'Sassandra-Marahou\u00e9, Ivory Coast',
    'Savanes, C\u00f4te d\'Ivoire': 'Savanes, Ivory Coast',
    'Savannakhet Urban Center, Lao People\'s Democratic Republic-Thailand': 'Savannakhet Urban Center, Laos-Thailand',
    'Savannakh\u00e9t, Lao People\'s Democratic Republic': 'Savannakh\u00e9t, Laos',
    'Saveh Urban Center, Iran, Islamic Republic of': 'Saveh Urban Center, Iran',
    'Seeb 5MPC, Oman-Iran, Islamic Republic of-Pakistan': 'Seeb 5MPC, Oman-Iran-Pakistan',
    'Sejong, Korea, Republic of': 'Sejong, South Korea',
    'Semey 5MPC, China-Kazakhstan-Russian Federation': 'Semey 5MPC, China-Kazakhstan-Russia',
    'Semnan Urban Center, Iran, Islamic Republic of': 'Semnan Urban Center, Iran',
    'Semn\u0101n, Iran, Islamic Republic of': 'Semn\u0101n, Iran',
    'Seoul (Center East) 5MPC, Korea, Republic of': 'Seoul (Center East) 5MPC, South Korea',
    'Seoul (Center North) 5MPC, Korea, Republic of': 'Seoul (Center North) 5MPC, South Korea',
    'Seoul (Center West) 5MPC, Korea, Republic of': 'Seoul (Center West) 5MPC, South Korea',
    'Seoul (Center) 100MPC, China-Korea, Democratic People\'s Republic of-Korea, Republic of': 'Seoul (Center) 100MPC, China-North Korea-South Korea',
    'Seoul (Center) 10MPC, Korea, Republic of': 'Seoul (Center) 10MPC, South Korea',
    'Seoul (Outer) 100MPC, China-Korea, Republic of': 'Seoul (Outer) 100MPC, China-South Korea',
    'Seoul (Outer) 10MPC, Korea, Republic of': 'Seoul (Outer) 10MPC, South Korea',
    'Seoul (Outer) 5MPC, Korea, Republic of': 'Seoul (Outer) 5MPC, South Korea',
    'Seoul (Periphery) 10MPC, Korea, Republic of': 'Seoul (Periphery) 10MPC, South Korea',
    'Seoul (Periphery) 5MPC, Korea, Republic of': 'Seoul (Periphery) 5MPC, South Korea',
    'Seoul 20MPC, Korea, Republic of': 'Seoul 20MPC, South Korea',
    'Seoul 500MPC, China-Japan-Korea, Republic of': 'Seoul 500MPC, China-Japan-South Korea',
    'Seoul 50MPC, Korea, Republic of': 'Seoul 50MPC, South Korea',
    'Seoul Urban Center, Korea, Republic of': 'Seoul Urban Center, South Korea',
    'Seoul-teukbyeolsi, Korea, Republic of': 'Seoul-teukbyeolsi, South Korea',
    'Serov Urban Center, Russian Federation': 'Serov Urban Center, Russia',
    'Serpukhov Urban Center, Russian Federation': 'Serpukhov Urban Center, Russia',
    'Serrekunda 10MPC, Senegal-Gambia': 'Serrekunda 10MPC, Senegal-The Gambia',
    'Serrekunda 5MPC, Gambia-Senegal-Guinea-Bissau': 'Serrekunda 5MPC, The Gambia-Senegal-Guinea-Bissau',
    'Serrekunda Urban Center, Gambia': 'Serrekunda Urban Center, The Gambia',
    'Severnaya Osetiya-Alaniya, Respublika, Russian Federation': 'Severnaya Osetiya-Alaniya, Respublika, Russia',
    'Severodvinsk Urban Center, Russian Federation': 'Severodvinsk Urban Center, Russia',
    'Seville 10MPC, Spain-C\u00f4te d\'Ivoire-Algeria': 'Seville 10MPC, Spain-Ivory Coast-Algeria',
    'Shabunda Urban Center, Congo, The Democratic Republic of the': 'Shabunda Urban Center, Democratic Republic of the Congo',
    'Shadegan Urban Center, Iran, Islamic Republic of': 'Shadegan Urban Center, Iran',
    'Shahin Shahr Urban Center, Iran, Islamic Republic of': 'Shahin Shahr Urban Center, Iran',
    'Shahindezh Urban Center, Iran, Islamic Republic of': 'Shahindezh Urban Center, Iran',
    'Shahr-e Babak Urban Center, Iran, Islamic Republic of': 'Shahr-e Babak Urban Center, Iran',
    'Shahr-e Kord Urban Center, Iran, Islamic Republic of': 'Shahr-e Kord Urban Center, Iran',
    'Shahreza Urban Center, Iran, Islamic Republic of': 'Shahreza Urban Center, Iran',
    'Shahrud Urban Center, Iran, Islamic Republic of': 'Shahrud Urban Center, Iran',
    'Shakhty Urban Center, Russian Federation': 'Shakhty Urban Center, Russia',
    'Shenyang 50MPC, China-Korea, Democratic People\'s Republic of': 'Shenyang 50MPC, China-North Korea',
    'Shinyanga Urban Center, Tanzania, United Republic of': 'Shinyanga Urban Center, Tanzania',
    'Shinyanga, Tanzania, United Republic of': 'Shinyanga, Tanzania',
    'Shiraz (Center) 5MPC, Iran, Islamic Republic of': 'Shiraz (Center) 5MPC, Iran',
    'Shiraz (Outer) 5MPC, Iran, Islamic Republic of': 'Shiraz (Outer) 5MPC, Iran',
    'Shiraz 10MPC, Iran, Islamic Republic of': 'Shiraz 10MPC, Iran',
    'Shiraz Urban Center, Iran, Islamic Republic of': 'Shiraz Urban Center, Iran',
    'Shirvan Urban Center, Iran, Islamic Republic of': 'Shirvan Urban Center, Iran',
    'Shoushtar Urban Center, Iran, Islamic Republic of': 'Shoushtar Urban Center, Iran',
    'Shush Urban Center, Iran, Islamic Republic of': 'Shush Urban Center, Iran',
    'Siguiri 5MPC, Mali-Guinea-C\u00f4te d\'Ivoire': 'Siguiri 5MPC, Mali-Guinea-Ivory Coast',
    'Sillyeonpo Urban Center, Korea, Democratic People\'s Republic of': 'Sillyeonpo Urban Center, North Korea',
    'Simiyu, Tanzania, United Republic of': 'Simiyu, Tanzania',
    'Sinchon Urban Center, Korea, Democratic People\'s Republic of': 'Sinchon Urban Center, North Korea',
    'Sinfra Urban Center, C\u00f4te d\'Ivoire': 'Sinfra Urban Center, Ivory Coast',
    'Singida Urban Center, Tanzania, United Republic of': 'Singida Urban Center, Tanzania',
    'Singida, Tanzania, United Republic of': 'Singida, Tanzania',
    'Sinhung Urban Center, Korea, Democratic People\'s Republic of': 'Sinhung Urban Center, North Korea',
    'Sinpho Urban Center, Korea, Democratic People\'s Republic of': 'Sinpho Urban Center, North Korea',
    'Sinpyong Urban Center, Korea, Democratic People\'s Republic of': 'Sinpyong Urban Center, North Korea',
    'Sinuiju Special Administrative Region, Korea, Democratic People\'s Republic of': 'Sinuiju Special Administrative Region, North Korea',
    'Sirjan Urban Center, Iran, Islamic Republic of': 'Sirjan Urban Center, Iran',
    'Slavyansk-na-Kubani Urban Center, Russian Federation': 'Slavyansk-na-Kubani Urban Center, Russia',
    'Smolensk Urban Center, Russian Federation': 'Smolensk Urban Center, Russia',
    'Smolenskaya oblast\', Russian Federation': 'Smolenskaya oblast\', Russia',
    'Sochi Urban Center, Russian Federation': 'Sochi Urban Center, Russia',
    'Socop\u00f3 Urban Center, Venezuela, Bolivarian Republic of': 'Socop\u00f3 Urban Center, Venezuela',
    'Sokcho-si Urban Center, Korea, Republic of': 'Sokcho-si Urban Center, South Korea',
    'Sonchon Urban Center, Korea, Democratic People\'s Republic of': 'Sonchon Urban Center, North Korea',
    'Songea Urban Center, Tanzania, United Republic of': 'Songea Urban Center, Tanzania',
    'Songhor Urban Center, Iran, Islamic Republic of': 'Songhor Urban Center, Iran',
    'Songnim Urban Center, Korea, Democratic People\'s Republic of': 'Songnim Urban Center, North Korea',
    'Songwe, Tanzania, United Republic of': 'Songwe, Tanzania',
    'Sora-myeon Urban Center, Korea, Republic of': 'Sora-myeon Urban Center, South Korea',
    'Soroca, Moldova, Republic of': 'Soroca, Moldova',
    'Soubr\u00e9 Urban Center, C\u00f4te d\'Ivoire': 'Soubr\u00e9 Urban Center, Ivory Coast',
    'South Abaco, Bahamas': 'South Abaco, The Bahamas',
    'South Andros, Bahamas': 'South Andros, The Bahamas',
    'South Eleuthera, Bahamas': 'South Eleuthera, The Bahamas',
    'Spanish Wells, Bahamas': 'Spanish Wells, The Bahamas',
    'Stavropol 5MPC, Russian Federation': 'Stavropol 5MPC, Russia',
    'Stavropol Urban Center, Russian Federation': 'Stavropol Urban Center, Russia',
    'Stavropol\'skiy kray, Russian Federation': 'Stavropol\'skiy kray, Russia',
    'Sterlitamak Urban Center, Russian Federation': 'Sterlitamak Urban Center, Russia',
    'Stockholm 20MPC, Sweden-Poland-Czechia': 'Stockholm 20MPC, Sweden-Poland-Czech Republic',
    'Str\u0103\u0219eni, Moldova, Republic of': 'Str\u0103\u0219eni, Moldova',
    'St\u00eenga Nistrului, unitatea teritorial\u0103 din, Moldova, Republic of': 'St\u00eenga Nistrului, unitatea teritorial\u0103 din, Moldova',
    'St\u0159edo\u010desk\u00fd kraj, Czechia': 'St\u0159edo\u010desk\u00fd kraj, Czech Republic',
    'Sucre Urban Center, Bolivia, Plurinational State of': 'Sucre Urban Center, Bolivia',
    'Sucre, Venezuela, Bolivarian Republic of': 'Sucre, Venezuela',
    'Sud-Kivu, Congo, The Democratic Republic of the': 'Sud-Kivu, Democratic Republic of the Congo',
    'Sud-Ubangi, Congo, The Democratic Republic of the': 'Sud-Ubangi, Democratic Republic of the Congo',
    'Sukchon Urban Center, Korea, Democratic People\'s Republic of': 'Sukchon Urban Center, North Korea',
    'Sulaymaniyah 5MPC, Iraq-Iran, Islamic Republic of': 'Sulaymaniyah 5MPC, Iraq-Iran',
    'Sumbawanga Urban Center, Tanzania, United Republic of': 'Sumbawanga Urban Center, Tanzania',
    'Suncheon-si Urban Center, Korea, Republic of': 'Suncheon-si Urban Center, South Korea',
    'Sunzha Urban Center, Russian Federation': 'Sunzha Urban Center, Russia',
    'Susangerd Urban Center, Iran, Islamic Republic of': 'Susangerd Urban Center, Iran',
    'Sverdlovskaya oblast\', Russian Federation': 'Sverdlovskaya oblast\', Russia',
    'Syktyvkar Urban Center, Russian Federation': 'Syktyvkar Urban Center, Russia',
    'Syrian Arab Republic': 'Syria',
    'Syzran Urban Center, Russian Federation': 'Syzran Urban Center, Russia',
    'S\u00e3o Domingos, Cabo Verde': 'S\u00e3o Domingos, Cape Verde',
    'S\u00e3o Filipe, Cabo Verde': 'S\u00e3o Filipe, Cape Verde',
    'S\u00e3o Louren\u00e7o dos \u00d3rg\u00e3os, Cabo Verde': 'S\u00e3o Louren\u00e7o dos \u00d3rg\u00e3os, Cape Verde',
    'S\u00e3o Miguel, Cabo Verde': 'S\u00e3o Miguel, Cape Verde',
    'S\u00e3o Paulo 200MPC, Brazil-Argentina-Bolivia, Plurinational State of': 'S\u00e3o Paulo 200MPC, Brazil-Argentina-Bolivia',
    'S\u00e3o Salvador do Mundo, Cabo Verde': 'S\u00e3o Salvador do Mundo, Cape Verde',
    'S\u00e3o Tom\u00e9 Urban Center, Sao Tome and Principe': 'S\u00e3o Tom\u00e9 Urban Center, S\u00e3o Tom\u00e9 and Pr\u00edncipe',
    'S\u00e3o Vicente, Cabo Verde': 'S\u00e3o Vicente, Cape Verde',
    'S\u00eengerei, Moldova, Republic of': 'S\u00eengerei, Moldova',
    'S\u00f3c Tr\u0103ng Urban Center, Viet Nam': 'S\u00f3c Tr\u0103ng Urban Center, Vietnam',
    'S\u00f3c Tr\u0103ng, Viet Nam': 'S\u00f3c Tr\u0103ng, Vietnam',
    'S\u00f4ng M\u00e3 Urban Center, Viet Nam': 'S\u00f4ng M\u00e3 Urban Center, Vietnam',
    'S\u00f4ng \u0110\u1ed1c Urban Center, Viet Nam': 'S\u00f4ng \u0110\u1ed1c Urban Center, Vietnam',
    'S\u012bst\u0101n va Bal\u016bchest\u0101n, Iran, Islamic Republic of': 'S\u012bst\u0101n va Bal\u016bchest\u0101n, Iran',
    'S\u01a1n La Urban Center, Viet Nam': 'S\u01a1n La Urban Center, Vietnam',
    'S\u01a1n La, Viet Nam': 'S\u01a1n La, Vietnam',
    'Tabas Urban Center, Iran, Islamic Republic of': 'Tabas Urban Center, Iran',
    'Tabora 5MPC, Tanzania, United Republic of': 'Tabora 5MPC, Tanzania',
    'Tabora Urban Center, Tanzania, United Republic of': 'Tabora Urban Center, Tanzania',
    'Tabora, Tanzania, United Republic of': 'Tabora, Tanzania',
    'Tabriz 10MPC, Iran, Islamic Republic of-Armenia-Turkey': 'Tabriz 10MPC, Iran-Armenia-Turkey',
    'Tabriz 5MPC, Iran, Islamic Republic of': 'Tabriz 5MPC, Iran',
    'Tabriz Urban Center, Iran, Islamic Republic of': 'Tabriz Urban Center, Iran',
    'Tabuk 10MPC, Egypt-Saudi Arabia-Syrian Arab Republic': 'Tabuk 10MPC, Egypt-Saudi Arabia-Syria',
    'Tachileik 5MPC, Myanmar-Thailand-Lao People\'s Democratic Republic': 'Tachileik 5MPC, Myanmar-Thailand-Laos',
    'Taean Urban Center, Korea, Democratic People\'s Republic of': 'Taean Urban Center, North Korea',
    'Taechon Urban Center, Korea, Democratic People\'s Republic of': 'Taechon Urban Center, North Korea',
    'Taganrog Urban Center, Russian Federation': 'Taganrog Urban Center, Russia',
    'Takestan Urban Center, Iran, Islamic Republic of': 'Takestan Urban Center, Iran',
    'Takoradi [Sekondi-Takoradi] (Center) 5MPC, C\u00f4te d\'Ivoire-Ghana': 'Takoradi [Sekondi-Takoradi] (Center) 5MPC, Ivory Coast-Ghana',
    'Takoradi [Sekondi-Takoradi] 10MPC, Ghana-C\u00f4te d\'Ivoire': 'Takoradi [Sekondi-Takoradi] 10MPC, Ghana-Ivory Coast',
    'Talesh Urban Center, Iran, Islamic Republic of': 'Talesh Urban Center, Iran',
    'Tam K\u1ef3 Urban Center, Viet Nam': 'Tam K\u1ef3 Urban Center, Vietnam',
    'Tam Quan Urban Center, Viet Nam': 'Tam Quan Urban Center, Vietnam',
    'Tamale 20MPC, Ghana-C\u00f4te d\'Ivoire-Mali': 'Tamale 20MPC, Ghana-Ivory Coast-Mali',
    'Tambov Urban Center, Russian Federation': 'Tambov Urban Center, Russia',
    'Tambovskaya oblast\', Russian Federation': 'Tambovskaya oblast\', Russia',
    'Tan An Urban Center, Viet Nam': 'Tan An Urban Center, Vietnam',
    'Tan Hiep Urban Center, Viet Nam': 'Tan Hiep Urban Center, Vietnam',
    'Tan Phu Urban Center, Viet Nam': 'Tan Phu Urban Center, Vietnam',
    'Tanchon Urban Center, Korea, Democratic People\'s Republic of': 'Tanchon Urban Center, North Korea',
    'Tanga Urban Center, Tanzania, United Republic of': 'Tanga Urban Center, Tanzania',
    'Tanga, Tanzania, United Republic of': 'Tanga, Tanzania',
    'Tanganyika, Congo, The Democratic Republic of the': 'Tanganyika, Democratic Republic of the Congo',
    'Tanzania, United Republic of': 'Tanzania',
    'Taraclia, Moldova, Republic of': 'Taraclia, Moldova',
    'Tarija Urban Center, Bolivia, Plurinational State of': 'Tarija Urban Center, Bolivia',
    'Tarija, Bolivia, Plurinational State of': 'Tarija, Bolivia',
    'Tarime Urban Center, Tanzania, United Republic of': 'Tarime Urban Center, Tanzania',
    'Tarrafal de S\u00e3o Nicolau, Cabo Verde': 'Tarrafal de S\u00e3o Nicolau, Cape Verde',
    'Tarrafal, Cabo Verde': 'Tarrafal, Cape Verde',
    'Tartus Urban Center, Syrian Arab Republic': 'Tartus Urban Center, Syria',
    'Tatarstan, Respublika, Russian Federation': 'Tatarstan, Respublika, Russia',
    'Taybad Urban Center, Iran, Islamic Republic of': 'Taybad Urban Center, Iran',
    'Tbilisi 10MPC, Russian Federation-Georgia': 'Tbilisi 10MPC, Russia-Georgia',
    'Techiman 5MPC, Ghana-C\u00f4te d\'Ivoire': 'Techiman 5MPC, Ghana-Ivory Coast',
    'Tehran (Center East) 5MPC, Iran, Islamic Republic of': 'Tehran (Center East) 5MPC, Iran',
    'Tehran (Center West) 5MPC, Iran, Islamic Republic of': 'Tehran (Center West) 5MPC, Iran',
    'Tehran (Center) 10MPC, Iran, Islamic Republic of': 'Tehran (Center) 10MPC, Iran',
    'Tehran (Outer) 10MPC, Iran, Islamic Republic of': 'Tehran (Outer) 10MPC, Iran',
    'Tehran (Outer) 5MPC, Iran, Islamic Republic of': 'Tehran (Outer) 5MPC, Iran',
    'Tehran 100MPC, Iran, Islamic Republic of-Iraq-Azerbaijan': 'Tehran 100MPC, Iran-Iraq-Azerbaijan',
    'Tehran 20MPC, Iran, Islamic Republic of': 'Tehran 20MPC, Iran',
    'Tehran 50MPC, Iran, Islamic Republic of-Iraq': 'Tehran 50MPC, Iran-Iraq',
    'Tehran Urban Center, Iran, Islamic Republic of': 'Tehran Urban Center, Iran',
    'Tehr\u0101n, Iran, Islamic Republic of': 'Tehr\u0101n, Iran',
    'Tel Aviv 10MPC, Israel-Jordan-Palestine, State of': 'Tel Aviv 10MPC, Israel-Jordan-State of Palestine',
    'Tel Aviv 5MPC, Israel-Palestine, State of': 'Tel Aviv 5MPC, Israel-State of Palestine',
    'Telene\u0219ti, Moldova, Republic of': 'Telene\u0219ti, Moldova',
    'Temburong, Brunei Darussalam': 'Temburong, Brunei',
    'Tengr\u00e9la Urban Center, C\u00f4te d\'Ivoire': 'Tengr\u00e9la Urban Center, Ivory Coast',
    'Thaethan Urban Center, Korea, Democratic People\'s Republic of': 'Thaethan Urban Center, North Korea',
    'Thakhek Urban Center, Lao People\'s Democratic Republic-Thailand': 'Thakhek Urban Center, Laos-Thailand',
    'Thanh H\u00f3a 10MPC, Viet Nam': 'Thanh H\u00f3a 10MPC, Vietnam',
    'Thanh H\u00f3a 5MPC, Viet Nam': 'Thanh H\u00f3a 5MPC, Vietnam',
    'Thanh H\u00f3a Urban Center, Viet Nam': 'Thanh H\u00f3a Urban Center, Vietnam',
    'Thanh H\u00f3a, Viet Nam': 'Thanh H\u00f3a, Vietnam',
    'Th\u00e1i B\u00ecnh (Ph\u00fa Th\u1ecd-H\u00e0 N\u1ed9i) Urban Center, Viet Nam': 'Th\u00e1i B\u00ecnh (Ph\u00fa Th\u1ecd-H\u00e0 N\u1ed9i) Urban Center, Vietnam',
    'Th\u00e1i B\u00ecnh (Th\u00e1i B\u00ecnh) Urban Center, Viet Nam': 'Th\u00e1i B\u00ecnh (Th\u00e1i B\u00ecnh) Urban Center, Vietnam',
    'Th\u00e1i B\u00ecnh, Viet Nam': 'Th\u00e1i B\u00ecnh, Vietnam',
    'Th\u00e1i Nguy\u00ean 20MPC, Viet Nam-China': 'Th\u00e1i Nguy\u00ean 20MPC, Vietnam-China',
    'Th\u00e1i Nguy\u00ean 5MPC, Viet Nam': 'Th\u00e1i Nguy\u00ean 5MPC, Vietnam',
    'Th\u00e1i Nguy\u00ean Urban Center, Viet Nam': 'Th\u00e1i Nguy\u00ean Urban Center, Vietnam',
    'Th\u00e1i Nguy\u00ean, Viet Nam': 'Th\u00e1i Nguy\u00ean, Vietnam',
    'Th\u1ecd Xu\u00e2n Urban Center, Viet Nam': 'Th\u1ecd Xu\u00e2n Urban Center, Vietnam',
    'Th\u1ed5 Tang Urban Center, Viet Nam': 'Th\u1ed5 Tang Urban Center, Vietnam',
    'Th\u1edbi Lai Urban Center, Viet Nam': 'Th\u1edbi Lai Urban Center, Vietnam',
    'Th\u1eeba Thi\u00ean-Hu\u1ebf, Viet Nam': 'Th\u1eeba Thi\u00ean-Hu\u1ebf, Vietnam',
    'Tikab Urban Center, Iran, Islamic Republic of': 'Tikab Urban Center, Iran',
    'Timor-Leste': 'East Timor',
    'Tinaquillo Urban Center, Venezuela, Bolivarian Republic of': 'Tinaquillo Urban Center, Venezuela',
    'Tiraspol Urban Center, Moldova, Republic of': 'Tiraspol Urban Center, Moldova',
    'Ti\u1ec1n Giang, Viet Nam': 'Ti\u1ec1n Giang, Vietnam',
    'Tokchon Urban Center, Korea, Democratic People\'s Republic of': 'Tokchon Urban Center, North Korea',
    'Tokyo 1BPC, Indonesia-China-Iran, Islamic Republic of': 'Tokyo 1BPC, Indonesia-China-Iran',
    'Tolyatti Urban Center, Russian Federation': 'Tolyatti Urban Center, Russia',
    'Tomsk Urban Center, Russian Federation': 'Tomsk Urban Center, Russia',
    'Tomskaya oblast\', Russian Federation': 'Tomskaya oblast\', Russia',
    'Tongrim Urban Center, Korea, Democratic People\'s Republic of': 'Tongrim Urban Center, North Korea',
    'Torbat-e Jam Urban Center, Iran, Islamic Republic of': 'Torbat-e Jam Urban Center, Iran',
    'Torbate Heydariye Urban Center, Iran, Islamic Republic of': 'Torbate Heydariye Urban Center, Iran',
    'Toyserkan Urban Center, Iran, Islamic Republic of': 'Toyserkan Urban Center, Iran',
    'Tra On Urban Center, Viet Nam': 'Tra On Urban Center, Vietnam',
    'Trabzon 5MPC, Turkey-Syrian Arab Republic-Iran, Islamic Republic of': 'Trabzon 5MPC, Turkey-Syria-Iran',
    'Trang Bang Urban Center, Viet Nam': 'Trang Bang Urban Center, Vietnam',
    'Trinidad Urban Center, Bolivia, Plurinational State of': 'Trinidad Urban Center, Bolivia',
    'Trujillo, Venezuela, Bolivarian Republic of': 'Trujillo, Venezuela',
    'Tr\u00e0 Vinh City Urban Center, Viet Nam': 'Tr\u00e0 Vinh City Urban Center, Vietnam',
    'Tr\u00e0 Vinh, Viet Nam': 'Tr\u00e0 Vinh, Vietnam',
    'Tshela Urban Center, Congo, The Democratic Republic of the': 'Tshela Urban Center, Democratic Republic of the Congo',
    'Tshikapa (Center) 5MPC, Congo, The Democratic Republic of the': 'Tshikapa (Center) 5MPC, Democratic Republic of the Congo',
    'Tshikapa (Outer) 5MPC, Congo, The Democratic Republic of the-Angola': 'Tshikapa (Outer) 5MPC, Democratic Republic of the Congo-Angola',
    'Tshikapa 10MPC, Congo, The Democratic Republic of the': 'Tshikapa 10MPC, Democratic Republic of the Congo',
    'Tshikapa Urban Center, Congo, The Democratic Republic of the': 'Tshikapa Urban Center, Democratic Republic of the Congo',
    'Tshimbulu Urban Center, Congo, The Democratic Republic of the': 'Tshimbulu Urban Center, Democratic Republic of the Congo',
    'Tshopo, Congo, The Democratic Republic of the': 'Tshopo, Democratic Republic of the Congo',
    'Tshuapa, Congo, The Democratic Republic of the': 'Tshuapa, Democratic Republic of the Congo',
    'Tubas, Palestine, State of': 'Tubas, State of Palestine',
    'Tucupita Urban Center, Venezuela, Bolivarian Republic of': 'Tucupita Urban Center, Venezuela',
    'Tujmazy Urban Center, Russian Federation': 'Tujmazy Urban Center, Russia',
    'Tul\'skaya oblast\', Russian Federation': 'Tul\'skaya oblast\', Russia',
    'Tula Urban Center, Russian Federation': 'Tula Urban Center, Russia',
    'Tulkarm Urban Center, Israel-Palestine, State of': 'Tulkarm Urban Center, Israel-State of Palestine',
    'Tulkarm, Palestine, State of': 'Tulkarm, State of Palestine',
    'Tumen Urban Center, China-Korea, Democratic People\'s Republic of': 'Tumen Urban Center, China-North Korea',
    'Tunduma Urban Center, Tanzania, United Republic of-Zambia': 'Tunduma Urban Center, Tanzania-Zambia',
    'Tutong, Brunei Darussalam': 'Tutong, Brunei',
    'Tuy H\u00f2a Urban Center, Viet Nam': 'Tuy H\u00f2a Urban Center, Vietnam',
    'Tuy\u00ean Quang Urban Center, Viet Nam': 'Tuy\u00ean Quang Urban Center, Vietnam',
    'Tuy\u00ean Quang, Viet Nam': 'Tuy\u00ean Quang, Vietnam',
    'Tver 5MPC, Russian Federation': 'Tver 5MPC, Russia',
    'Tver Urban Center, Russian Federation': 'Tver Urban Center, Russia',
    'Tverskaya oblast\', Russian Federation': 'Tverskaya oblast\', Russia',
    'Tyumen 10MPC, Russian Federation': 'Tyumen 10MPC, Russia',
    'Tyumen 5MPC, Russian Federation': 'Tyumen 5MPC, Russia',
    'Tyumen Urban Center, Russian Federation': 'Tyumen Urban Center, Russia',
    'Tyumenskaya oblast\', Russian Federation': 'Tyumenskaya oblast\', Russia',
    'Tyva, Respublika, Russian Federation': 'Tyva, Respublika, Russia',
    'T\u00e1chira, Venezuela, Bolivarian Republic of': 'T\u00e1chira, Venezuela',
    'T\u00e2n Ch\u00e2u Urban Center, Viet Nam': 'T\u00e2n Ch\u00e2u Urban Center, Vietnam',
    'T\u00e2n Uy\u00ean Urban Center, Viet Nam': 'T\u00e2n Uy\u00ean Urban Center, Vietnam',
    'T\u00e2y Ninh 5MPC, Viet Nam': 'T\u00e2y Ninh 5MPC, Vietnam',
    'T\u00e2y Ninh Urban Center, Viet Nam': 'T\u00e2y Ninh Urban Center, Vietnam',
    'T\u00e2y Ninh, Viet Nam': 'T\u00e2y Ninh, Vietnam',
    'T\u00e2y \u0110\u1eb1ng Urban Center, Viet Nam': 'T\u00e2y \u0110\u1eb1ng Urban Center, Vietnam',
    'T\u01d2ktaedonggok Urban Center, Korea, Democratic People\'s Republic of': 'T\u01d2ktaedonggok Urban Center, North Korea',
    'Ubon Ratchathani 5MPC, Thailand-Lao People\'s Democratic Republic': 'Ubon Ratchathani 5MPC, Thailand-Laos',
    'Udmurtskaya Respublika, Russian Federation': 'Udmurtskaya Respublika, Russia',
    'Ufa 5MPC, Russian Federation': 'Ufa 5MPC, Russia',
    'Ufa Urban Center, Russian Federation': 'Ufa Urban Center, Russia',
    'Ul\'yanovskaya oblast\', Russian Federation': 'Ul\'yanovskaya oblast\', Russia',
    'Ulaanbaatar 10MPC, Russian Federation-China-Mongolia': 'Ulaanbaatar 10MPC, Russia-China-Mongolia',
    'Ulaanbaatar 5MPC, Russian Federation-Mongolia': 'Ulaanbaatar 5MPC, Russia-Mongolia',
    'Ulan-Ude Urban Center, Russian Federation': 'Ulan-Ude Urban Center, Russia',
    'Ulsan Urban Center, Korea, Republic of': 'Ulsan Urban Center, South Korea',
    'Ulsan-gwangyeoksi, Korea, Republic of': 'Ulsan-gwangyeoksi, South Korea',
    'Ulyanovsk Urban Center, Russian Federation': 'Ulyanovsk Urban Center, Russia',
    'Unchon Urban Center, Korea, Democratic People\'s Republic of': 'Unchon Urban Center, North Korea',
    'Ungheni, Moldova, Republic of': 'Ungheni, Moldova',
    'Unjon Urban Center, Korea, Democratic People\'s Republic of': 'Unjon Urban Center, North Korea',
    'Unpha Urban Center, Korea, Democratic People\'s Republic of': 'Unpha Urban Center, North Korea',
    'Unsan Urban Center, Korea, Democratic People\'s Republic of': 'Unsan Urban Center, North Korea',
    'Upata Urban Center, Venezuela, Bolivarian Republic of': 'Upata Urban Center, Venezuela',
    'Upper River, Gambia': 'Upper River, The Gambia',
    'Urgench 20MPC, Afghanistan-Uzbekistan-Iran, Islamic Republic of': 'Urgench 20MPC, Afghanistan-Uzbekistan-Iran',
    'Urmia Urban Center, Iran, Islamic Republic of': 'Urmia Urban Center, Iran',
    'Ussurijsk Urban Center, Russian Federation': 'Ussurijsk Urban Center, Russia',
    'Uvira 10MPC, Burundi-Congo, The Democratic Republic of the-Rwanda': 'Uvira 10MPC, Burundi-Democratic Republic of the Congo-Rwanda',
    'Uvira 5MPC, Tanzania, United Republic of-Burundi-Congo, The Democratic Republic of the': 'Uvira 5MPC, Tanzania-Burundi-Democratic Republic of the Congo',
    'Uvira Urban Center, Congo, The Democratic Republic of the': 'Uvira Urban Center, Democratic Republic of the Congo',
    'U\u00f4ng B\u00ed Urban Center, Viet Nam': 'U\u00f4ng B\u00ed Urban Center, Vietnam',
    'Valencia 5MPC, Venezuela, Bolivarian Republic of': 'Valencia 5MPC, Venezuela',
    'Valencia Urban Center, Venezuela, Bolivarian Republic of': 'Valencia Urban Center, Venezuela',
    'Valera Urban Center, Venezuela, Bolivarian Republic of': 'Valera Urban Center, Venezuela',
    'Valle de la Pascua Urban Center, Venezuela, Bolivarian Republic of': 'Valle de la Pascua Urban Center, Venezuela',
    'Vall\u00e9e du Bandama, C\u00f4te d\'Ivoire': 'Vall\u00e9e du Bandama, Ivory Coast',
    'Van 5MPC, Turkey-Syrian Arab Republic': 'Van 5MPC, Turkey-Syria',
    'Varamin Urban Center, Iran, Islamic Republic of': 'Varamin Urban Center, Iran',
    'Varna 5MPC, Bulgaria-Ukraine-Moldova, Republic of': 'Varna 5MPC, Bulgaria-Ukraine-Moldova',
    'Vavoua Urban Center, C\u00f4te d\'Ivoire': 'Vavoua Urban Center, Ivory Coast',
    'Velikiye Luki Urban Center, Russian Federation': 'Velikiye Luki Urban Center, Russia',
    'Veliky Novgorod Urban Center, Russian Federation': 'Veliky Novgorod Urban Center, Russia',
    'Venezuela, Bolivarian Republic of': 'Venezuela',
    'Vesyoloye Urban Center, Russian Federation': 'Vesyoloye Urban Center, Russia',
    'Viangchan, Lao People\'s Democratic Republic': 'Viangchan, Laos',
    'Vienna 5MPC, Austria-Czechia-Slovakia': 'Vienna 5MPC, Austria-Czech Republic-Slovakia',
    'Vientiane 5MPC, Thailand-Lao People\'s Democratic Republic': 'Vientiane 5MPC, Thailand-Laos',
    'Vientiane Urban Center, Lao People\'s Democratic Republic': 'Vientiane Urban Center, Laos',
    'Viet Nam': 'Vietnam',
    'Villa del Rosario Urban Center, Colombia-Venezuela, Bolivarian Republic of': 'Villa del Rosario Urban Center, Colombia-Venezuela',
    'Villa del Rosario Urban Center, Venezuela, Bolivarian Republic of': 'Villa del Rosario Urban Center, Venezuela',
    'Villaz\u00f3n Urban Center, Argentina-Bolivia, Plurinational State of': 'Villaz\u00f3n Urban Center, Argentina-Bolivia',
    'Vinh 10MPC, Viet Nam-Thailand-Lao People\'s Democratic Republic': 'Vinh 10MPC, Vietnam-Thailand-Laos',
    'Vinh 20MPC, Thailand-Viet Nam-Lao People\'s Democratic Republic': 'Vinh 20MPC, Thailand-Vietnam-Laos',
    'Vinh 5MPC, Viet Nam-Lao People\'s Democratic Republic-Thailand': 'Vinh 5MPC, Vietnam-Laos-Thailand',
    'Vinh Urban Center, Viet Nam': 'Vinh Urban Center, Vietnam',
    'Viqueque, Timor-Leste': 'Viqueque, East Timor',
    'Virgin Islands, British': 'British Virgin Islands',
    'Virgin Islands, British (UK), Virgin Islands, British': 'Virgin Islands, British (UK), British Virgin Islands',
    'Vitebsk 5MPC, Ukraine-Belarus-Russian Federation': 'Vitebsk 5MPC, Ukraine-Belarus-Russia',
    'Vi\u1ec7t Tr\u00ec Urban Center, Viet Nam': 'Vi\u1ec7t Tr\u00ec Urban Center, Vietnam',
    'Vladikavkaz Urban Center, Russian Federation': 'Vladikavkaz Urban Center, Russia',
    'Vladimir Urban Center, Russian Federation': 'Vladimir Urban Center, Russia',
    'Vladimirskaya oblast\', Russian Federation': 'Vladimirskaya oblast\', Russia',
    'Vladivostok Urban Center, Russian Federation': 'Vladivostok Urban Center, Russia',
    'Volgodonsk Urban Center, Russian Federation': 'Volgodonsk Urban Center, Russia',
    'Volgograd 20MPC, Russian Federation-China-India': 'Volgograd 20MPC, Russia-China-India',
    'Volgograd 5MPC, Russian Federation': 'Volgograd 5MPC, Russia',
    'Volgograd Urban Center, Russian Federation': 'Volgograd Urban Center, Russia',
    'Volgogradskaya oblast\', Russian Federation': 'Volgogradskaya oblast\', Russia',
    'Vologda Urban Center, Russian Federation': 'Vologda Urban Center, Russia',
    'Vologodskaya oblast\', Russian Federation': 'Vologodskaya oblast\', Russia',
    'Volzhskiy Urban Center, Russian Federation': 'Volzhskiy Urban Center, Russia',
    'Voronezh 10MPC, Russian Federation': 'Voronezh 10MPC, Russia',
    'Voronezh 5MPC, Russian Federation': 'Voronezh 5MPC, Russia',
    'Voronezh Urban Center, Russian Federation': 'Voronezh Urban Center, Russia',
    'Voronezhskaya oblast\', Russian Federation': 'Voronezhskaya oblast\', Russia',
    'Votkinsk Urban Center, Russian Federation': 'Votkinsk Urban Center, Russia',
    'Vung Tau 10MPC, Viet Nam-Cambodia': 'Vung Tau 10MPC, Vietnam-Cambodia',
    'Vung Tau 5MPC, Cambodia-Viet Nam': 'Vung Tau 5MPC, Cambodia-Vietnam',
    'Vung Tau Urban Center, Viet Nam': 'Vung Tau Urban Center, Vietnam',
    'Vwawa Urban Center, Tanzania, United Republic of': 'Vwawa Urban Center, Tanzania',
    'Vyso\u010dina, Czechia': 'Vyso\u010dina, Czech Republic',
    'V\u0129nh Long, Viet Nam': 'V\u0129nh Long, Vietnam',
    'V\u0129nh Ph\u00fac, Viet Nam': 'V\u0129nh Ph\u00fac, Vietnam',
    'V\u0129nh Y\u00ean Urban Center, Viet Nam': 'V\u0129nh Y\u00ean Urban Center, Vietnam',
    'V\u1ea1n Gi\u00e3 Urban Center, Viet Nam': 'V\u1ea1n Gi\u00e3 Urban Center, Vietnam',
    'Walikale Urban Center, Congo, The Democratic Republic of the': 'Walikale Urban Center, Democratic Republic of the Congo',
    'Wamaza Urban Center, Congo, The Democratic Republic of the': 'Wamaza Urban Center, Democratic Republic of the Congo',
    'Wenshan 5MPC, China-Viet Nam': 'Wenshan 5MPC, China-Vietnam',
    'West Grand Bahama, Bahamas': 'West Grand Bahama, The Bahamas',
    'Western, Gambia': 'Western, The Gambia',
    'Wonju-si Urban Center, Korea, Republic of': 'Wonju-si Urban Center, South Korea',
    'Wonsan Urban Center, Korea, Democratic People\'s Republic of': 'Wonsan Urban Center, North Korea',
    'Woroba, C\u00f4te d\'Ivoire': 'Woroba, Ivory Coast',
    'Xaignabouli, Lao People\'s Democratic Republic': 'Xaignabouli, Laos',
    'Xais\u00f4mboun, Lao People\'s Democratic Republic': 'Xais\u00f4mboun, Laos',
    'Xiamen 100MPC, China-Viet Nam-Thailand': 'Xiamen 100MPC, China-Vietnam-Thailand',
    'Xiangkhouang, Lao People\'s Democratic Republic': 'Xiangkhouang, Laos',
    'Xilinhot 5MPC, China-Russian Federation': 'Xilinhot 5MPC, China-Russia',
    'Xu\u00e2n H\u00f2a Urban Center, Viet Nam': 'Xu\u00e2n H\u00f2a Urban Center, Vietnam',
    'Xu\u00e2n Tr\u01b0\u1eddng Urban Center, Viet Nam': 'Xu\u00e2n Tr\u01b0\u1eddng Urban Center, Vietnam',
    'X\u00e9kong, Lao People\'s Democratic Republic': 'X\u00e9kong, Laos',
    'Yaaroubia Urban Center, Iraq-Syrian Arab Republic': 'Yaaroubia Urban Center, Iraq-Syria',
    'Yacuiba Urban Center, Argentina-Bolivia, Plurinational State of': 'Yacuiba Urban Center, Argentina-Bolivia',
    'Yakoma Urban Center, Congo, The Democratic Republic of the': 'Yakoma Urban Center, Democratic Republic of the Congo',
    'Yakutsk 5MPC, Russian Federation-Mongolia': 'Yakutsk 5MPC, Russia-Mongolia',
    'Yakutsk Urban Center, Russian Federation': 'Yakutsk Urban Center, Russia',
    'Yamalo-Nenentskiy avtonomnyy okrug, Russian Federation': 'Yamalo-Nenentskiy avtonomnyy okrug, Russia',
    'Yamoussoukro Urban Center, C\u00f4te d\'Ivoire': 'Yamoussoukro Urban Center, Ivory Coast',
    'Yamoussoukro, C\u00f4te d\'Ivoire': 'Yamoussoukro, Ivory Coast',
    'Yangdok Urban Center, Korea, Democratic People\'s Republic of': 'Yangdok Urban Center, North Korea',
    'Yangsan Urban Center, Korea, Republic of': 'Yangsan Urban Center, South Korea',
    'Yap, Micronesia, Federated States of': 'Yap, Micronesia',
    'Yaracuy, Venezuela, Bolivarian Republic of': 'Yaracuy, Venezuela',
    'Yaritagua Urban Center, Venezuela, Bolivarian Republic of': 'Yaritagua Urban Center, Venezuela',
    'Yaroslavl Urban Center, Russian Federation': 'Yaroslavl Urban Center, Russia',
    'Yaroslavskaya oblast\', Russian Federation': 'Yaroslavskaya oblast\', Russia',
    'Yasuj Urban Center, Iran, Islamic Republic of': 'Yasuj Urban Center, Iran',
    'Yazd 5MPC, Iran, Islamic Republic of': 'Yazd 5MPC, Iran',
    'Yazd Urban Center, Iran, Islamic Republic of': 'Yazd Urban Center, Iran',
    'Yazd, Iran, Islamic Republic of': 'Yazd, Iran',
    'Yekaterinburg 10MPC, Russian Federation': 'Yekaterinburg 10MPC, Russia',
    'Yekaterinburg 20MPC, Russian Federation': 'Yekaterinburg 20MPC, Russia',
    'Yekaterinburg 5MPC, Russian Federation': 'Yekaterinburg 5MPC, Russia',
    'Yekaterinburg Urban Center, Russian Federation': 'Yekaterinburg Urban Center, Russia',
    'Yelets Urban Center, Russian Federation': 'Yelets Urban Center, Russia',
    'Yessentuki Urban Center, Russian Federation': 'Yessentuki Urban Center, Russia',
    'Yeveryskaya avtonomnaya oblast\', Russian Federation': 'Yeveryskaya avtonomnaya oblast\', Russia',
    'Yeysk Urban Center, Russian Federation': 'Yeysk Urban Center, Russia',
    'Yomju Urban Center, Korea, Democratic People\'s Republic of': 'Yomju Urban Center, North Korea',
    'Yonan Urban Center, Korea, Democratic People\'s Republic of': 'Yonan Urban Center, North Korea',
    'Yoshkar-Ola Urban Center, Russian Federation': 'Yoshkar-Ola Urban Center, Russia',
    'Young Leninist Urban Center, Russian Federation': 'Young Leninist Urban Center, Russia',
    'Yugorsk Urban Center, Russian Federation': 'Yugorsk Urban Center, Russia',
    'Yundum Urban Center, Gambia': 'Yundum Urban Center, The Gambia',
    'Yurga Urban Center, Russian Federation': 'Yurga Urban Center, Russia',
    'Yuxi 10MPC, China-Viet Nam': 'Yuxi 10MPC, China-Vietnam',
    'Yuzhno-Sakhalinsk Urban Center, Russian Federation': 'Yuzhno-Sakhalinsk Urban Center, Russia',
    'Y\u00ean B\u00e1i Urban Center, Viet Nam': 'Y\u00ean B\u00e1i Urban Center, Vietnam',
    'Y\u00ean B\u00e1i, Viet Nam': 'Y\u00ean B\u00e1i, Vietnam',
    'Zabaykal\'skiy kray, Russian Federation': 'Zabaykal\'skiy kray, Russia',
    'Zabol Urban Center, Iran, Islamic Republic of': 'Zabol Urban Center, Iran',
    'Zagreb 10MPC, Germany-Czechia-Austria': 'Zagreb 10MPC, Germany-Czech Republic-Austria',
    'Zahedan 5MPC, Egypt-Russian Federation-Iran, Islamic Republic of': 'Zahedan 5MPC, Egypt-Russia-Iran',
    'Zahedan Urban Center, Iran, Islamic Republic of': 'Zahedan Urban Center, Iran',
    'Zakho 10MPC, Iraq-Turkey-Iran, Islamic Republic of': 'Zakho 10MPC, Iraq-Turkey-Iran',
    'Zanjan Urban Center, Iran, Islamic Republic of': 'Zanjan Urban Center, Iran',
    'Zanj\u0101n, Iran, Islamic Republic of': 'Zanj\u0101n, Iran',
    'Zanzan, C\u00f4te d\'Ivoire': 'Zanzan, Ivory Coast',
    'Zanzibar City Urban Center, Tanzania, United Republic of': 'Zanzibar City Urban Center, Tanzania',
    'Zarand Urban Center, Iran, Islamic Republic of': 'Zarand Urban Center, Iran',
    'Zaraza Urban Center, Venezuela, Bolivarian Republic of': 'Zaraza Urban Center, Venezuela',
    'Zarrinshahr Urban Center, Iran, Islamic Republic of': 'Zarrinshahr Urban Center, Iran',
    'Zehak Urban Center, Iran, Islamic Republic of': 'Zehak Urban Center, Iran',
    'Zelenograd Urban Center, Russian Federation': 'Zelenograd Urban Center, Russia',
    'Zhukovsky Urban Center, Russian Federation': 'Zhukovsky Urban Center, Russia',
    'Zlatoust Urban Center, Russian Federation': 'Zlatoust Urban Center, Russia',
    'Zl\u00ednsk\u00fd kraj, Czechia': 'Zl\u00ednsk\u00fd kraj, Czech Republic',
    'Zuata Urban Center, Venezuela, Bolivarian Republic of': 'Zuata Urban Center, Venezuela',
    'Zulia, Venezuela, Bolivarian Republic of': 'Zulia, Venezuela',
    '`Uthman Urban Center, Syrian Arab Republic': '`Uthman Urban Center, Syria',
    'kangavar Urban Center, Iran, Islamic Republic of': 'kangavar Urban Center, Iran',
    '\u00c1gua Grande, Sao Tome and Principe': '\u00c1gua Grande, S\u00e3o Tom\u00e9 and Pr\u00edncipe',
    '\u00c9quateur, Congo, The Democratic Republic of the': '\u00c9quateur, Democratic Republic of the Congo',
    '\u00d0\u1ed3ng Nai, Viet Nam': '\u00d0\u1ed3ng Nai, Vietnam',
    '\u00d0\u1ed3ng Th\u00e1p, Viet Nam': '\u00d0\u1ed3ng Th\u00e1p, Vietnam',
    '\u00dasteck\u00fd kraj, Czechia': '\u00dasteck\u00fd kraj, Czech Republic',
    '\u00dast\u00ed nad Labem Urban Center, Czechia': '\u00dast\u00ed nad Labem Urban Center, Czech Republic',
    '\u00dcr\u00fcmqi 20MPC, China-Russian Federation-Mongolia': '\u00dcr\u00fcmqi 20MPC, China-Russia-Mongolia',
    '\u00dcr\u00fcmqi 50MPC, China-Russian Federation-Kazakhstan': '\u00dcr\u00fcmqi 50MPC, China-Russia-Kazakhstan',
    '\u0100z\u0304arb\u0101yj\u0101n-e Gharb\u012b, Iran, Islamic Republic of': '\u0100z\u0304arb\u0101yj\u0101n-e Gharb\u012b, Iran',
    '\u0100z\u0304arb\u0101yj\u0101n-e Sharq\u012b, Iran, Islamic Republic of': '\u0100z\u0304arb\u0101yj\u0101n-e Sharq\u012b, Iran',
    '\u010cesk\u00e9 Bud\u011bjovice Urban Center, Czechia': '\u010cesk\u00e9 Bud\u011bjovice Urban Center, Czech Republic',
    '\u0110inh V\u0103n Urban Center, Viet Nam': '\u0110inh V\u0103n Urban Center, Vietnam',
    '\u0110i\u1ec7n Bi\u00ean, Viet Nam': '\u0110i\u1ec7n Bi\u00ean, Vietnam',
    '\u0110\u00e0 N\u1eb5ng 10MPC, Viet Nam-Lao People\'s Democratic Republic-Thailand': '\u0110\u00e0 N\u1eb5ng 10MPC, Vietnam-Laos-Thailand',
    '\u0110\u00e0 N\u1eb5ng 20MPC, Viet Nam-Cambodia-Lao People\'s Democratic Republic': '\u0110\u00e0 N\u1eb5ng 20MPC, Vietnam-Cambodia-Laos',
    '\u0110\u00e0 N\u1eb5ng 5MPC, Viet Nam-Lao People\'s Democratic Republic': '\u0110\u00e0 N\u1eb5ng 5MPC, Vietnam-Laos',
    '\u0110\u00e0 N\u1eb5ng Urban Center, Viet Nam': '\u0110\u00e0 N\u1eb5ng Urban Center, Vietnam',
    '\u0110\u00e0 N\u1eb5ng, Viet Nam': '\u0110\u00e0 N\u1eb5ng, Vietnam',
    '\u0110\u00f4ng H\u01b0ng Urban Center, Viet Nam': '\u0110\u00f4ng H\u01b0ng Urban Center, Vietnam',
    '\u0110\u0103k Mil Urban Center, Viet Nam': '\u0110\u0103k Mil Urban Center, Vietnam',
    '\u0110\u1ea1i Ngh\u0129a Urban Center, Viet Nam': '\u0110\u1ea1i Ngh\u0129a Urban Center, Vietnam',
    '\u0110\u1ea7m D\u01a1i Urban Center, Viet Nam': '\u0110\u1ea7m D\u01a1i Urban Center, Vietnam',
    '\u0110\u1eafk L\u1eafk, Viet Nam': '\u0110\u1eafk L\u1eafk, Vietnam',
    '\u0110\u1eafk N\u00f4ng, Viet Nam': '\u0110\u1eafk N\u00f4ng, Vietnam',
    '\u0110\u1ecbnh Qu\u00e1n Urban Center, Viet Nam': '\u0110\u1ecbnh Qu\u00e1n Urban Center, Vietnam',
    '\u0110\u1ed3ng Xo\u00e0i Urban Center, Viet Nam': '\u0110\u1ed3ng Xo\u00e0i Urban Center, Vietnam',
    '\u0110\u1ee9c H\u00f2a Urban Center, Viet Nam': '\u0110\u1ee9c H\u00f2a Urban Center, Vietnam',
    '\u012al\u0101m, Iran, Islamic Republic of': '\u012al\u0101m, Iran',
    '\u015eanl\u0131urfa 10MPC, Turkey-Syrian Arab Republic': '\u015eanl\u0131urfa 10MPC, Turkey-Syria',
    '\u015eanl\u0131urfa 20MPC, Iran, Islamic Republic of-Syrian Arab Republic-Iraq': '\u015eanl\u0131urfa 20MPC, Iran-Syria-Iraq',
    '\u0162ar\u0163\u016bs, Syrian Arab Republic': '\u0162ar\u0163\u016bs, Syria',
    '\u0218old\u0103ne\u0219ti, Moldova, Republic of': '\u0218old\u0103ne\u0219ti, Moldova',
    '\u0218tefan Vod\u0103, Moldova, Republic of': '\u0218tefan Vod\u0103, Moldova',
    '\u0412\u0435\u0441\u0435\u043d\u043d\u0438\u0439 Urban Center, Russian Federation': '\u0412\u0435\u0441\u0435\u043d\u043d\u0438\u0439 Urban Center, Russia',
    '\u0412\u043e\u0434\u043d\u0438\u043a Urban Center, Russian Federation': '\u0412\u043e\u0434\u043d\u0438\u043a Urban Center, Russia',
    '\u0412\u043e\u0440\u043e\u0448\u043d\u0435\u0432\u043e Urban Center, Russian Federation': '\u0412\u043e\u0440\u043e\u0448\u043d\u0435\u0432\u043e Urban Center, Russia',
    '\u0416\u0443\u043a\u043e\u0432\u043e Urban Center, Russian Federation': '\u0416\u0443\u043a\u043e\u0432\u043e Urban Center, Russia',
    '\u0417\u0430\u0440\u0435\u0447\u043d\u044b\u0439 Urban Center, Russian Federation': '\u0417\u0430\u0440\u0435\u0447\u043d\u044b\u0439 Urban Center, Russia',
    '\u041a\u0430\u0438\u043d\u0441\u043a\u0430\u044f \u0417\u0430\u0438\u043c\u043a\u0430 Urban Center, Russian Federation': '\u041a\u0430\u0438\u043d\u0441\u043a\u0430\u044f \u0417\u0430\u0438\u043c\u043a\u0430 Urban Center, Russia',
    '\u041b\u0435\u043d\u0438\u043d\u0441\u043a\u0438\u0439 Urban Center, Russian Federation': '\u041b\u0435\u043d\u0438\u043d\u0441\u043a\u0438\u0439 Urban Center, Russia',
    '\u041b\u043e\u0431\u0430\u043d\u043e\u0432\u043e Urban Center, Russian Federation': '\u041b\u043e\u0431\u0430\u043d\u043e\u0432\u043e Urban Center, Russia',
    '\u041c\u0430\u043b\u0430\u044f \u0429\u0435\u0434\u0440\u0443\u0445\u0430 Urban Center, Russian Federation': '\u041c\u0430\u043b\u0430\u044f \u0429\u0435\u0434\u0440\u0443\u0445\u0430 Urban Center, Russia',
    '\u041c\u0430\u043c\u043e\u043d\u044b Urban Center, Russian Federation': '\u041c\u0430\u043c\u043e\u043d\u044b Urban Center, Russia',
    '\u041f\u0440\u043e\u043c\u0437\u043e\u043d\u0430 Urban Center, Russian Federation': '\u041f\u0440\u043e\u043c\u0437\u043e\u043d\u0430 Urban Center, Russia',
    '\u0421\u0435\u043c\u044c \u041a\u043b\u044e\u0447\u0435\u0439 Urban Center, Russian Federation': '\u0421\u0435\u043c\u044c \u041a\u043b\u044e\u0447\u0435\u0439 Urban Center, Russia',
    '\u0421\u0442\u0430\u0440\u043e\u043a\u0438\u0440\u043f\u0438\u0447\u043d\u044b\u0439 Urban Center, Russian Federation': '\u0421\u0442\u0430\u0440\u043e\u043a\u0438\u0440\u043f\u0438\u0447\u043d\u044b\u0439 Urban Center, Russia',
    '\u0421\u044b\u043b\u0432\u0430 Urban Center, Russian Federation': '\u0421\u044b\u043b\u0432\u0430 Urban Center, Russia',
    '\u0428\u0430\u0445\u043c\u0430\u043d\u043e\u0432\u043e Urban Center, Russian Federation': '\u0428\u0430\u0445\u043c\u0430\u043d\u043e\u0432\u043e Urban Center, Russia',
    '\u0628\u0648\u06a9\u0627\u0646 Urban Center, Iran, Islamic Republic of': '\u0628\u0648\u06a9\u0627\u0646 Urban Center, Iran',
    '\u0645\u0647\u0631\u06cc\u0627\u0646 Urban Center, Iran, Islamic Republic of': '\u0645\u0647\u0631\u06cc\u0627\u0646 Urban Center, Iran',
    '\u1e28alab, Syrian Arab Republic': '\u1e28alab, Syria',
    '\u1e28am\u0101h, Syrian Arab Republic': '\u1e28am\u0101h, Syria',
    '\u1e28im\u015f, Syrian Arab Republic': '\u1e28im\u015f, Syria',
    '\uc6c5\uc0c1 Urban Center, Korea, Republic of': '\uc6c5\uc0c1 Urban Center, South Korea',
    '\uccad\ud558\uc5ed Urban Center, Korea, Democratic People\'s Republic of': '\uccad\ud558\uc5ed Urban Center, North Korea',
    'Ciudad Ojeda 5MPC, Venezuela-USA-France': 'Ciudad Ojeda 5MPC, Venezuela-France-USA',
    'Acapulco 20MPC, Canada-USA-Mexico': 'Acapulco 20MPC, Canada-Mexico-USA',
    'Virginia Beach 20MPC, USA': 'Virginia Beach 20MPC, USA-Canada',
}
export default value
