const value: Record<string, number> = {
    'Continent': 0,
    'Country': 1,
    'Subnational Region': 2,
    'County': 3,
    'Urban Center': 4,
    'CA Census Division': 5,
    'CA Population Center': 6,
    'City': 7,
    'CSA': 8,
    'MSA': 9,
    'Urban Area': 10,
    'CA CMA': 11,
    'CCD': 12,
    'CA Census Subdivision': 13,
    'Neighborhood': 14,
    'ZIP': 15,
    'Congressional District (1780s)': 16,
    'Congressional District (1790s)': 17,
    'Congressional District (1800s)': 18,
    'Congressional District (1810s)': 19,
    'Congressional District (1820s)': 20,
    'Congressional District (1830s)': 21,
    'Congressional District (1840s)': 22,
    'Congressional District (1850s)': 23,
    'Congressional District (1860s)': 24,
    'Congressional District (1870s)': 25,
    'Congressional District (1880s)': 26,
    'Congressional District (1890s)': 27,
    'Congressional District (1900s)': 28,
    'Congressional District (1910s)': 29,
    'Congressional District (1920s)': 30,
    'Congressional District (1930s)': 31,
    'Congressional District (1940s)': 32,
    'Congressional District (1950s)': 33,
    'Congressional District (1960s)': 34,
    'Congressional District (1970s)': 35,
    'Congressional District (1980s)': 36,
    'Congressional District (1990s)': 37,
    'Congressional District (2000s)': 38,
    'Congressional District (2010s)': 39,
    'Congressional District (2020s)': 40,
    'State House District': 41,
    'State Senate District': 42,
    'Congressional District': 43,
    'County Cross CD': 44,
    'CA Riding': 45,
    'Native Area': 46,
    'Native Statistical Area': 47,
    'Native Subdivision': 48,
    'School District': 49,
    'Judicial Circuit': 50,
    'Media Market': 51,
    'USDA County Type': 52,
    'Hospital Referral Region': 53,
    'Judicial District': 54,
    'Hospital Service Area': 55,
    '1B Person Circle': 56,
    '500M Person Circle': 57,
    '200M Person Circle': 58,
    '100M Person Circle': 59,
    '50M Person Circle': 60,
    '20M Person Circle': 61,
    '10M Person Circle': 62,
    '5M Person Circle': 63,
}
export default value
