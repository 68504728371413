// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hoverable_elements {
    background-color: var(--slightly-different-background);
}

.hoverable_elements:hover {
    background-color: var(--slightly-different-background-focused);
}`, "",{"version":3,"sources":["webpack://./src/components/header.css"],"names":[],"mappings":"AAAA;IACI,sDAAsD;AAC1D;;AAEA;IACI,8DAA8D;AAClE","sourcesContent":[".hoverable_elements {\n    background-color: var(--slightly-different-background);\n}\n\n.hoverable_elements:hover {\n    background-color: var(--slightly-different-background-focused);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
