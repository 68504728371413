// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.related_areas {
    display: flex;
    flex-direction: column;
    align-items: start;
    flex-grow: 1;
    flex-basis: 0;
    margin: 1em;
}

.list_of_lists {
    /* display: inline-block; */
    padding-inline-start: 0px;
    list-style-type: none;
    flex-grow: 1;
    margin: 0;
}

.linklist {
    display: inline-block;
    padding-inline-start: 0px;
    list-style-type: none;
    flex-grow: 1;
    margin: 0.1em;
}

.linklistel {
    min-height: 13pt;
    float: left;
    padding-right: 0.3em;
    vertical-align: middle;
    margin-top: 1px;
    margin-bottom: 1px;
}

.linklistel_mobile {
    min-height: 20pt;
    margin-top: 0px;
    margin-bottom: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/related.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,WAAW;AACf;;AAEA;IACI,2BAA2B;IAC3B,yBAAyB;IACzB,qBAAqB;IACrB,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,qBAAqB;IACrB,yBAAyB;IACzB,qBAAqB;IACrB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,oBAAoB;IACpB,sBAAsB;IACtB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".related_areas {\n    display: flex;\n    flex-direction: column;\n    align-items: start;\n    flex-grow: 1;\n    flex-basis: 0;\n    margin: 1em;\n}\n\n.list_of_lists {\n    /* display: inline-block; */\n    padding-inline-start: 0px;\n    list-style-type: none;\n    flex-grow: 1;\n    margin: 0;\n}\n\n.linklist {\n    display: inline-block;\n    padding-inline-start: 0px;\n    list-style-type: none;\n    flex-grow: 1;\n    margin: 0.1em;\n}\n\n.linklistel {\n    min-height: 13pt;\n    float: left;\n    padding-right: 0.3em;\n    vertical-align: middle;\n    margin-top: 1px;\n    margin-bottom: 1px;\n}\n\n.linklistel_mobile {\n    min-height: 20pt;\n    margin-top: 0px;\n    margin-bottom: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
