const value: Record<string, number[]> = {
    'world__overall': [
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
    ],
    'world__CA Census Subdivision': [
        106,
        106,
        64,
    ],
    'world__CCD': [
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        1,
    ],
    'world__City': [
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        3,
    ],
    'world__County': [
        159,
        117,
    ],
    'world__County Cross CD': [
        137,
        137,
        2,
    ],
    'world__Historical Congressional District': [
        54,
        54,
        54,
        54,
        54,
        6,
    ],
    'world__Hospital Service Area': [
        149,
        127,
    ],
    'world__Neighborhood': [
        31,
        31,
        31,
        31,
        31,
        31,
        31,
        31,
        28,
    ],
    'world__School District': [
        38,
        38,
        38,
        38,
        38,
        38,
        38,
        10,
    ],
    'world__State House District': [
        105,
        105,
        66,
    ],
    'world__State Senate District': [
        267,
        9,
    ],
    'world__Subnational Region': [
        140,
        136,
    ],
    'world__Urban Area': [
        195,
        81,
    ],
    'world__Urban Center': [
        50,
        50,
        50,
        50,
        50,
        26,
    ],
    'world__ZIP': [
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        3,
    ],
    'Africa__overall': [
        143,
        133,
    ],
    'Africa__Urban Center': [
        257,
        19,
    ],
    'Asia__overall': [
        59,
        59,
        59,
        59,
        40,
    ],
    'Asia__Urban Center': [
        89,
        89,
        89,
        9,
    ],
    'Europe__overall': [
        226,
        50,
    ],
    'North America__overall': [
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
    ],
    'North America__CA Census Subdivision': [
        106,
        106,
        64,
    ],
    'North America__CCD': [
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        1,
    ],
    'North America__City': [
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        3,
    ],
    'North America__County': [
        159,
        117,
    ],
    'North America__County Cross CD': [
        137,
        137,
        2,
    ],
    'North America__Historical Congressional District': [
        54,
        54,
        54,
        54,
        54,
        6,
    ],
    'North America__Hospital Service Area': [
        149,
        127,
    ],
    'North America__Neighborhood': [
        31,
        31,
        31,
        31,
        31,
        31,
        31,
        31,
        28,
    ],
    'North America__School District': [
        38,
        38,
        38,
        38,
        38,
        38,
        38,
        10,
    ],
    'North America__State House District': [
        106,
        106,
        64,
    ],
    'North America__State Senate District': [
        270,
        6,
    ],
    'North America__Urban Area': [
        196,
        80,
    ],
    'North America__ZIP': [
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        3,
    ],
    'Canada__overall': [
        75,
        75,
        75,
        51,
    ],
    'Canada__CA Census Subdivision': [
        106,
        106,
        64,
    ],
    'China__overall': [
        207,
        69,
    ],
    'India__overall': [
        205,
        71,
    ],
    'USA__overall': [
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
    ],
    'USA__CCD': [
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        1,
    ],
    'USA__City': [
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        3,
    ],
    'USA__County': [
        159,
        117,
    ],
    'USA__County Cross CD': [
        137,
        137,
        2,
    ],
    'USA__Historical Congressional District': [
        54,
        54,
        54,
        54,
        54,
        6,
    ],
    'USA__Hospital Service Area': [
        149,
        127,
    ],
    'USA__Neighborhood': [
        31,
        31,
        31,
        31,
        31,
        31,
        31,
        31,
        28,
    ],
    'USA__School District': [
        38,
        38,
        38,
        38,
        38,
        38,
        38,
        10,
    ],
    'USA__State House District': [
        105,
        105,
        66,
    ],
    'USA__State Senate District': [
        267,
        9,
    ],
    'USA__Urban Area': [
        195,
        81,
    ],
    'USA__ZIP': [
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        3,
    ],
    'Alabama, USA__overall': [
        190,
        86,
    ],
    'Arizona, USA__overall': [
        215,
        61,
    ],
    'Arkansas, USA__overall': [
        157,
        119,
    ],
    'California, USA__overall': [
        61,
        61,
        61,
        61,
        32,
    ],
    'California, USA__Neighborhood': [
        258,
        18,
    ],
    'Colorado, USA__overall': [
        215,
        61,
    ],
    'Florida, USA__overall': [
        114,
        114,
        48,
    ],
    'Georgia, USA__overall': [
        134,
        134,
        8,
    ],
    'Illinois, USA__overall': [
        73,
        73,
        73,
        57,
    ],
    'Indiana, USA__overall': [
        111,
        111,
        54,
    ],
    'Iowa, USA__overall': [
        106,
        106,
        64,
    ],
    'Kansas, USA__overall': [
        124,
        124,
        28,
    ],
    'Kentucky, USA__overall': [
        175,
        101,
    ],
    'Louisiana, USA__overall': [
        214,
        62,
    ],
    'Maryland, USA__overall': [
        249,
        27,
    ],
    'Massachusetts, USA__overall': [
        207,
        69,
    ],
    'Michigan, USA__overall': [
        100,
        100,
        76,
    ],
    'Minnesota, USA__overall': [
        85,
        85,
        85,
        21,
    ],
    'Minnesota, USA__CCD': [
        187,
        89,
    ],
    'Mississippi, USA__overall': [
        236,
        40,
    ],
    'Missouri, USA__overall': [
        97,
        97,
        82,
    ],
    'Montana, USA__overall': [
        274,
        2,
    ],
    'Nebraska, USA__overall': [
        166,
        110,
    ],
    'New Jersey, USA__overall': [
        165,
        111,
    ],
    'New York, USA__overall': [
        70,
        70,
        70,
        66,
    ],
    'North Carolina, USA__overall': [
        117,
        117,
        42,
    ],
    'North Dakota, USA__overall': [
        166,
        110,
    ],
    'Ohio, USA__overall': [
        78,
        78,
        78,
        42,
    ],
    'Oklahoma, USA__overall': [
        153,
        123,
    ],
    'Oregon, USA__overall': [
        272,
        4,
    ],
    'Pennsylvania, USA__overall': [
        58,
        58,
        58,
        58,
        44,
    ],
    'Pennsylvania, USA__CCD': [
        200,
        76,
    ],
    'Pennsylvania, USA__City': [
        274,
        2,
    ],
    'South Carolina, USA__overall': [
        251,
        25,
    ],
    'South Dakota, USA__overall': [
        188,
        88,
    ],
    'Tennessee, USA__overall': [
        143,
        133,
    ],
    'Texas, USA__overall': [
        56,
        56,
        56,
        56,
        52,
    ],
    'Texas, USA__ZIP': [
        268,
        8,
    ],
    'Virginia, USA__overall': [
        131,
        131,
        14,
    ],
    'Washington, USA__overall': [
        180,
        96,
    ],
    'West Virginia, USA__overall': [
        266,
        10,
    ],
    'Wisconsin, USA__overall': [
        100,
        100,
        76,
    ],
    'Wisconsin, USA__CCD': [
        273,
        3,
    ],
}
export default value
