const value: Record<string, [number, string][]> = {
    Afmhot: [
        [
            0.0,
            '#000000',
        ],
        [
            0.02040816326530612,
            '#0a0000',
        ],
        [
            0.04081632653061224,
            '#140000',
        ],
        [
            0.061224489795918366,
            '#1e0000',
        ],
        [
            0.08163265306122448,
            '#280000',
        ],
        [
            0.1020408163265306,
            '#340000',
        ],
        [
            0.12244897959183673,
            '#3e0000',
        ],
        [
            0.14285714285714285,
            '#480000',
        ],
        [
            0.16326530612244897,
            '#510000',
        ],
        [
            0.18367346938775508,
            '#5e0000',
        ],
        [
            0.2040816326530612,
            '#680000',
        ],
        [
            0.22448979591836732,
            '#710000',
        ],
        [
            0.24489795918367346,
            '#7c0000',
        ],
        [
            0.26530612244897955,
            '#860600',
        ],
        [
            0.2857142857142857,
            '#921200',
        ],
        [
            0.3061224489795918,
            '#9c1c00',
        ],
        [
            0.32653061224489793,
            '#a62600',
        ],
        [
            0.3469387755102041,
            '#b03000',
        ],
        [
            0.36734693877551017,
            '#bc3c00',
        ],
        [
            0.3877551020408163,
            '#c64600',
        ],
        [
            0.4081632653061224,
            '#d05000',
        ],
        [
            0.42857142857142855,
            '#da5a00',
        ],
        [
            0.44897959183673464,
            '#e36400',
        ],
        [
            0.4693877551020408,
            '#f07000',
        ],
        [
            0.4897959183673469,
            '#fa7a00',
        ],
        [
            0.5102040816326531,
            '#ff8404',
        ],
        [
            0.5306122448979591,
            '#ff8e0f',
        ],
        [
            0.5510204081632653,
            '#ff9a1b',
        ],
        [
            0.5714285714285714,
            '#ffa424',
        ],
        [
            0.5918367346938775,
            '#ffae2f',
        ],
        [
            0.6122448979591836,
            '#ffb839',
        ],
        [
            0.6326530612244897,
            '#ffc242',
        ],
        [
            0.6530612244897959,
            '#ffce4f',
        ],
        [
            0.673469387755102,
            '#ffd859',
        ],
        [
            0.6938775510204082,
            '#ffe262',
        ],
        [
            0.7142857142857142,
            '#ffec6d',
        ],
        [
            0.7346938775510203,
            '#fff879',
        ],
        [
            0.7551020408163265,
            '#ffff83',
        ],
        [
            0.7755102040816326,
            '#ffff8d',
        ],
        [
            0.7959183673469387,
            '#ffff96',
        ],
        [
            0.8163265306122448,
            '#ffffa1',
        ],
        [
            0.836734693877551,
            '#ffffad',
        ],
        [
            0.8571428571428571,
            '#ffffb6',
        ],
        [
            0.8775510204081632,
            '#ffffc1',
        ],
        [
            0.8979591836734693,
            '#ffffcb',
        ],
        [
            0.9183673469387754,
            '#ffffd6',
        ],
        [
            0.9387755102040816,
            '#ffffe1',
        ],
        [
            0.9591836734693877,
            '#ffffeb',
        ],
        [
            0.9795918367346939,
            '#fffff4',
        ],
        [
            1.0,
            '#ffffff',
        ],
    ],
    Autumn: [
        [
            0.0,
            '#ff0000',
        ],
        [
            0.02040816326530612,
            '#ff0500',
        ],
        [
            0.04081632653061224,
            '#ff0a00',
        ],
        [
            0.061224489795918366,
            '#ff0f00',
        ],
        [
            0.08163265306122448,
            '#ff1400',
        ],
        [
            0.1020408163265306,
            '#ff1a00',
        ],
        [
            0.12244897959183673,
            '#ff1f00',
        ],
        [
            0.14285714285714285,
            '#ff2400',
        ],
        [
            0.16326530612244897,
            '#ff2800',
        ],
        [
            0.18367346938775508,
            '#ff2f00',
        ],
        [
            0.2040816326530612,
            '#ff3400',
        ],
        [
            0.22448979591836732,
            '#ff3800',
        ],
        [
            0.24489795918367346,
            '#ff3e00',
        ],
        [
            0.26530612244897955,
            '#ff4300',
        ],
        [
            0.2857142857142857,
            '#ff4900',
        ],
        [
            0.3061224489795918,
            '#ff4e00',
        ],
        [
            0.32653061224489793,
            '#ff5300',
        ],
        [
            0.3469387755102041,
            '#ff5800',
        ],
        [
            0.36734693877551017,
            '#ff5e00',
        ],
        [
            0.3877551020408163,
            '#ff6300',
        ],
        [
            0.4081632653061224,
            '#ff6800',
        ],
        [
            0.42857142857142855,
            '#ff6d00',
        ],
        [
            0.44897959183673464,
            '#ff7100',
        ],
        [
            0.4693877551020408,
            '#ff7800',
        ],
        [
            0.4897959183673469,
            '#ff7d00',
        ],
        [
            0.5102040816326531,
            '#ff8200',
        ],
        [
            0.5306122448979591,
            '#ff8700',
        ],
        [
            0.5510204081632653,
            '#ff8d00',
        ],
        [
            0.5714285714285714,
            '#ff9200',
        ],
        [
            0.5918367346938775,
            '#ff9700',
        ],
        [
            0.6122448979591836,
            '#ff9c00',
        ],
        [
            0.6326530612244897,
            '#ffa100',
        ],
        [
            0.6530612244897959,
            '#ffa700',
        ],
        [
            0.673469387755102,
            '#ffac00',
        ],
        [
            0.6938775510204082,
            '#ffb100',
        ],
        [
            0.7142857142857142,
            '#ffb600',
        ],
        [
            0.7346938775510203,
            '#ffbc00',
        ],
        [
            0.7551020408163265,
            '#ffc100',
        ],
        [
            0.7755102040816326,
            '#ffc600',
        ],
        [
            0.7959183673469387,
            '#ffcb00',
        ],
        [
            0.8163265306122448,
            '#ffd000',
        ],
        [
            0.836734693877551,
            '#ffd600',
        ],
        [
            0.8571428571428571,
            '#ffdb00',
        ],
        [
            0.8775510204081632,
            '#ffe000',
        ],
        [
            0.8979591836734693,
            '#ffe500',
        ],
        [
            0.9183673469387754,
            '#ffeb00',
        ],
        [
            0.9387755102040816,
            '#fff000',
        ],
        [
            0.9591836734693877,
            '#fff500',
        ],
        [
            0.9795918367346939,
            '#fffa00',
        ],
        [
            1.0,
            '#ffff00',
        ],
    ],
    Binary: [
        [
            0.0,
            '#ffffff',
        ],
        [
            0.02040816326530612,
            '#fafafa',
        ],
        [
            0.04081632653061224,
            '#f5f5f5',
        ],
        [
            0.061224489795918366,
            '#f0f0f0',
        ],
        [
            0.08163265306122448,
            '#ebebeb',
        ],
        [
            0.1020408163265306,
            '#e5e5e5',
        ],
        [
            0.12244897959183673,
            '#e0e0e0',
        ],
        [
            0.14285714285714285,
            '#dbdbdb',
        ],
        [
            0.16326530612244897,
            '#d6d6d6',
        ],
        [
            0.18367346938775508,
            '#d0d0d0',
        ],
        [
            0.2040816326530612,
            '#cbcbcb',
        ],
        [
            0.22448979591836732,
            '#c6c6c6',
        ],
        [
            0.24489795918367346,
            '#c1c1c1',
        ],
        [
            0.26530612244897955,
            '#bcbcbc',
        ],
        [
            0.2857142857142857,
            '#b6b6b6',
        ],
        [
            0.3061224489795918,
            '#b1b1b1',
        ],
        [
            0.32653061224489793,
            '#acacac',
        ],
        [
            0.3469387755102041,
            '#a7a7a7',
        ],
        [
            0.36734693877551017,
            '#a1a1a1',
        ],
        [
            0.3877551020408163,
            '#9c9c9c',
        ],
        [
            0.4081632653061224,
            '#979797',
        ],
        [
            0.42857142857142855,
            '#929292',
        ],
        [
            0.44897959183673464,
            '#8d8d8d',
        ],
        [
            0.4693877551020408,
            '#878787',
        ],
        [
            0.4897959183673469,
            '#828282',
        ],
        [
            0.5102040816326531,
            '#7d7d7d',
        ],
        [
            0.5306122448979591,
            '#787878',
        ],
        [
            0.5510204081632653,
            '#717171',
        ],
        [
            0.5714285714285714,
            '#6d6d6d',
        ],
        [
            0.5918367346938775,
            '#686868',
        ],
        [
            0.6122448979591836,
            '#626262',
        ],
        [
            0.6326530612244897,
            '#5e5e5e',
        ],
        [
            0.6530612244897959,
            '#585858',
        ],
        [
            0.673469387755102,
            '#525252',
        ],
        [
            0.6938775510204082,
            '#4e4e4e',
        ],
        [
            0.7142857142857142,
            '#494949',
        ],
        [
            0.7346938775510203,
            '#424242',
        ],
        [
            0.7551020408163265,
            '#3e3e3e',
        ],
        [
            0.7755102040816326,
            '#383838',
        ],
        [
            0.7959183673469387,
            '#343434',
        ],
        [
            0.8163265306122448,
            '#2f2f2f',
        ],
        [
            0.836734693877551,
            '#282828',
        ],
        [
            0.8571428571428571,
            '#242424',
        ],
        [
            0.8775510204081632,
            '#1f1f1f',
        ],
        [
            0.8979591836734693,
            '#191919',
        ],
        [
            0.9183673469387754,
            '#141414',
        ],
        [
            0.9387755102040816,
            '#0f0f0f',
        ],
        [
            0.9591836734693877,
            '#090909',
        ],
        [
            0.9795918367346939,
            '#050505',
        ],
        [
            1.0,
            '#000000',
        ],
    ],
    Blues: [
        [
            0.0,
            '#f7fbff',
        ],
        [
            0.02040816326530612,
            '#f3f8fd',
        ],
        [
            0.04081632653061224,
            '#eff5fc',
        ],
        [
            0.061224489795918366,
            '#ebf3fb',
        ],
        [
            0.08163265306122448,
            '#e7f0f9',
        ],
        [
            0.1020408163265306,
            '#e2edf8',
        ],
        [
            0.12244897959183673,
            '#deebf7',
        ],
        [
            0.14285714285714285,
            '#dae8f5',
        ],
        [
            0.16326530612244897,
            '#d7e6f4',
        ],
        [
            0.18367346938775508,
            '#d2e3f3',
        ],
        [
            0.2040816326530612,
            '#cee0f1',
        ],
        [
            0.22448979591836732,
            '#cbdef0',
        ],
        [
            0.24489795918367346,
            '#c7dbef',
        ],
        [
            0.26530612244897955,
            '#c1d9ed',
        ],
        [
            0.2857142857142857,
            '#bad6ea',
        ],
        [
            0.3061224489795918,
            '#b4d3e8',
        ],
        [
            0.32653061224489793,
            '#add0e6',
        ],
        [
            0.3469387755102041,
            '#a7cee4',
        ],
        [
            0.36734693877551017,
            '#a0cae1',
        ],
        [
            0.3877551020408163,
            '#98c7df',
        ],
        [
            0.4081632653061224,
            '#90c2de',
        ],
        [
            0.42857142857142855,
            '#88bedc',
        ],
        [
            0.44897959183673464,
            '#80b9da',
        ],
        [
            0.4693877551020408,
            '#77b4d8',
        ],
        [
            0.4897959183673469,
            '#6fb0d6',
        ],
        [
            0.5102040816326531,
            '#67abd4',
        ],
        [
            0.5306122448979591,
            '#61a7d2',
        ],
        [
            0.5510204081632653,
            '#59a2cf',
        ],
        [
            0.5714285714285714,
            '#539dcc',
        ],
        [
            0.5918367346938775,
            '#4c99ca',
        ],
        [
            0.6122448979591836,
            '#4694c7',
        ],
        [
            0.6326530612244897,
            '#4090c5',
        ],
        [
            0.6530612244897959,
            '#3a8ac1',
        ],
        [
            0.673469387755102,
            '#3484bf',
        ],
        [
            0.6938775510204082,
            '#2f7fbc',
        ],
        [
            0.7142857142857142,
            '#2a7ab9',
        ],
        [
            0.7346938775510203,
            '#2474b6',
        ],
        [
            0.7551020408163265,
            '#1f6fb3',
        ],
        [
            0.7755102040816326,
            '#1b6aaf',
        ],
        [
            0.7959183673469387,
            '#1765ab',
        ],
        [
            0.8163265306122448,
            '#1360a7',
        ],
        [
            0.836734693877551,
            '#0f5aa3',
        ],
        [
            0.8571428571428571,
            '#0b559f',
        ],
        [
            0.8775510204081632,
            '#08509a',
        ],
        [
            0.8979591836734693,
            '#084a92',
        ],
        [
            0.9183673469387754,
            '#084489',
        ],
        [
            0.9387755102040816,
            '#083f82',
        ],
        [
            0.9591836734693877,
            '#083a7a',
        ],
        [
            0.9795918367346939,
            '#083572',
        ],
        [
            1.0,
            '#08306b',
        ],
    ],
    Bone: [
        [
            0.0,
            '#000000',
        ],
        [
            0.02040816326530612,
            '#040406',
        ],
        [
            0.04081632653061224,
            '#08080c',
        ],
        [
            0.061224489795918366,
            '#0d0d12',
        ],
        [
            0.08163265306122448,
            '#111118',
        ],
        [
            0.1020408163265306,
            '#16161f',
        ],
        [
            0.12244897959183673,
            '#1b1b25',
        ],
        [
            0.14285714285714285,
            '#1f1f2b',
        ],
        [
            0.16326530612244897,
            '#232331',
        ],
        [
            0.18367346938775508,
            '#292939',
        ],
        [
            0.2040816326530612,
            '#2d2d3f',
        ],
        [
            0.22448979591836732,
            '#313145',
        ],
        [
            0.24489795918367346,
            '#36364b',
        ],
        [
            0.26530612244897955,
            '#3a3a51',
        ],
        [
            0.2857142857142857,
            '#3f3f58',
        ],
        [
            0.3061224489795918,
            '#44445e',
        ],
        [
            0.32653061224489793,
            '#484865',
        ],
        [
            0.3469387755102041,
            '#4d4c6b',
        ],
        [
            0.36734693877551017,
            '#525272',
        ],
        [
            0.3877551020408163,
            '#565876',
        ],
        [
            0.4081632653061224,
            '#5b5e7a',
        ],
        [
            0.42857142857142855,
            '#5f647f',
        ],
        [
            0.44897959183673464,
            '#636a83',
        ],
        [
            0.4693877551020408,
            '#697188',
        ],
        [
            0.4897959183673469,
            '#6d778d',
        ],
        [
            0.5102040816326531,
            '#717d91',
        ],
        [
            0.5306122448979591,
            '#768395',
        ],
        [
            0.5510204081632653,
            '#7b8b9b',
        ],
        [
            0.5714285714285714,
            '#7f919f',
        ],
        [
            0.5918367346938775,
            '#8497a3',
        ],
        [
            0.6122448979591836,
            '#889da8',
        ],
        [
            0.6326530612244897,
            '#8ca3ac',
        ],
        [
            0.6530612244897959,
            '#92aab1',
        ],
        [
            0.673469387755102,
            '#96b0b6',
        ],
        [
            0.6938775510204082,
            '#9ab6ba',
        ],
        [
            0.7142857142857142,
            '#9fbcbf',
        ],
        [
            0.7346938775510203,
            '#a4c3c4',
        ],
        [
            0.7551020408163265,
            '#aac8c8',
        ],
        [
            0.7755102040816326,
            '#b1cdcd',
        ],
        [
            0.7959183673469387,
            '#b7d1d1',
        ],
        [
            0.8163265306122448,
            '#bed5d5',
        ],
        [
            0.836734693877551,
            '#c6dbdb',
        ],
        [
            0.8571428571428571,
            '#cddfdf',
        ],
        [
            0.8775510204081632,
            '#d4e3e3',
        ],
        [
            0.8979591836734693,
            '#dbe8e8',
        ],
        [
            0.9183673469387754,
            '#e3eded',
        ],
        [
            0.9387755102040816,
            '#eaf1f1',
        ],
        [
            0.9591836734693877,
            '#f1f6f6',
        ],
        [
            0.9795918367346939,
            '#f8fafa',
        ],
        [
            1.0,
            '#ffffff',
        ],
    ],
    BrBG: [
        [
            0.0,
            '#543005',
        ],
        [
            0.1,
            '#8a5009',
        ],
        [
            0.2,
            '#bf812d',
        ],
        [
            0.30000000000000004,
            '#dec07b',
        ],
        [
            0.4,
            '#f6e8c3',
        ],
        [
            0.5,
            '#f4f4f4',
        ],
        [
            0.6000000000000001,
            '#c7eae5',
        ],
        [
            0.7000000000000001,
            '#7ecbc0',
        ],
        [
            0.8,
            '#35978f',
        ],
        [
            0.9,
            '#00655d',
        ],
        [
            1.0,
            '#003c30',
        ],
    ],
    Brg: [
        [
            0.0,
            '#0000ff',
        ],
        [
            0.02040816326530612,
            '#0a00f5',
        ],
        [
            0.04081632653061224,
            '#1400eb',
        ],
        [
            0.061224489795918366,
            '#1e00e1',
        ],
        [
            0.08163265306122448,
            '#2800d7',
        ],
        [
            0.1020408163265306,
            '#3400cb',
        ],
        [
            0.12244897959183673,
            '#3e00c1',
        ],
        [
            0.14285714285714285,
            '#4800b7',
        ],
        [
            0.16326530612244897,
            '#5100ad',
        ],
        [
            0.18367346938775508,
            '#5e00a1',
        ],
        [
            0.2040816326530612,
            '#680097',
        ],
        [
            0.22448979591836732,
            '#71008d',
        ],
        [
            0.24489795918367346,
            '#7c0083',
        ],
        [
            0.26530612244897955,
            '#860079',
        ],
        [
            0.2857142857142857,
            '#92006d',
        ],
        [
            0.3061224489795918,
            '#9c0062',
        ],
        [
            0.32653061224489793,
            '#a60059',
        ],
        [
            0.3469387755102041,
            '#b0004f',
        ],
        [
            0.36734693877551017,
            '#bc0042',
        ],
        [
            0.3877551020408163,
            '#c60038',
        ],
        [
            0.4081632653061224,
            '#d0002f',
        ],
        [
            0.42857142857142855,
            '#da0025',
        ],
        [
            0.44897959183673464,
            '#e3001b',
        ],
        [
            0.4693877551020408,
            '#f0000f',
        ],
        [
            0.4897959183673469,
            '#fa0005',
        ],
        [
            0.5102040816326531,
            '#fa0500',
        ],
        [
            0.5306122448979591,
            '#f00f00',
        ],
        [
            0.5510204081632653,
            '#e41b00',
        ],
        [
            0.5714285714285714,
            '#da2500',
        ],
        [
            0.5918367346938775,
            '#d02f00',
        ],
        [
            0.6122448979591836,
            '#c63900',
        ],
        [
            0.6326530612244897,
            '#bc4300',
        ],
        [
            0.6530612244897959,
            '#b04f00',
        ],
        [
            0.673469387755102,
            '#a65900',
        ],
        [
            0.6938775510204082,
            '#9c6300',
        ],
        [
            0.7142857142857142,
            '#926d00',
        ],
        [
            0.7346938775510203,
            '#867900',
        ],
        [
            0.7551020408163265,
            '#7c8300',
        ],
        [
            0.7755102040816326,
            '#718d00',
        ],
        [
            0.7959183673469387,
            '#689700',
        ],
        [
            0.8163265306122448,
            '#5ea100',
        ],
        [
            0.836734693877551,
            '#51ad00',
        ],
        [
            0.8571428571428571,
            '#48b700',
        ],
        [
            0.8775510204081632,
            '#3ec100',
        ],
        [
            0.8979591836734693,
            '#34cb00',
        ],
        [
            0.9183673469387754,
            '#28d700',
        ],
        [
            0.9387755102040816,
            '#1ee100',
        ],
        [
            0.9591836734693877,
            '#14eb00',
        ],
        [
            0.9795918367346939,
            '#09f500',
        ],
        [
            1.0,
            '#00ff00',
        ],
    ],
    BuGn: [
        [
            0.0,
            '#f7fcfd',
        ],
        [
            0.02040816326530612,
            '#f4fafc',
        ],
        [
            0.04081632653061224,
            '#f1f9fb',
        ],
        [
            0.061224489795918366,
            '#eef8fb',
        ],
        [
            0.08163265306122448,
            '#ebf7fa',
        ],
        [
            0.1020408163265306,
            '#e8f6f9',
        ],
        [
            0.12244897959183673,
            '#e5f5f9',
        ],
        [
            0.14285714285714285,
            '#e1f3f6',
        ],
        [
            0.16326530612244897,
            '#ddf2f3',
        ],
        [
            0.18367346938775508,
            '#d9f0ef',
        ],
        [
            0.2040816326530612,
            '#d5efed',
        ],
        [
            0.22448979591836732,
            '#d1edea',
        ],
        [
            0.24489795918367346,
            '#cdece7',
        ],
        [
            0.26530612244897955,
            '#c6e9e3',
        ],
        [
            0.2857142857142857,
            '#bde6dd',
        ],
        [
            0.3061224489795918,
            '#b5e3d9',
        ],
        [
            0.32653061224489793,
            '#addfd4',
        ],
        [
            0.3469387755102041,
            '#a5dccf',
        ],
        [
            0.36734693877551017,
            '#9bd9ca',
        ],
        [
            0.3877551020408163,
            '#93d5c5',
        ],
        [
            0.4081632653061224,
            '#8bd2bf',
        ],
        [
            0.42857142857142855,
            '#83ceb9',
        ],
        [
            0.44897959183673464,
            '#7bcbb3',
        ],
        [
            0.4693877551020408,
            '#72c7ac',
        ],
        [
            0.4897959183673469,
            '#6ac3a6',
        ],
        [
            0.5102040816326531,
            '#63c0a0',
        ],
        [
            0.5306122448979591,
            '#5dbd99',
        ],
        [
            0.5510204081632653,
            '#56b990',
        ],
        [
            0.5714285714285714,
            '#50b689',
        ],
        [
            0.5918367346938775,
            '#4ab382',
        ],
        [
            0.6122448979591836,
            '#44b07a',
        ],
        [
            0.6326530612244897,
            '#3fac73',
        ],
        [
            0.6530612244897959,
            '#39a56a',
        ],
        [
            0.673469387755102,
            '#35a062',
        ],
        [
            0.6938775510204082,
            '#309a5a',
        ],
        [
            0.7142857142857142,
            '#2b9553',
        ],
        [
            0.7346938775510203,
            '#268e49',
        ],
        [
            0.7551020408163265,
            '#218943',
        ],
        [
            0.7755102040816326,
            '#1b843f',
        ],
        [
            0.7959183673469387,
            '#167f3b',
        ],
        [
            0.8163265306122448,
            '#107b37',
        ],
        [
            0.836734693877551,
            '#0a7533',
        ],
        [
            0.8571428571428571,
            '#04702f',
        ],
        [
            0.8775510204081632,
            '#006b2b',
        ],
        [
            0.8979591836734693,
            '#006528',
        ],
        [
            0.9183673469387754,
            '#005d25',
        ],
        [
            0.9387755102040816,
            '#005723',
        ],
        [
            0.9591836734693877,
            '#005020',
        ],
        [
            0.9795918367346939,
            '#004a1d',
        ],
        [
            1.0,
            '#00441b',
        ],
    ],
    BuPu: [
        [
            0.0,
            '#f7fcfd',
        ],
        [
            0.02040816326530612,
            '#f3f9fb',
        ],
        [
            0.04081632653061224,
            '#eff6fa',
        ],
        [
            0.061224489795918366,
            '#ecf4f8',
        ],
        [
            0.08163265306122448,
            '#e8f1f7',
        ],
        [
            0.1020408163265306,
            '#e4eef5',
        ],
        [
            0.12244897959183673,
            '#e0ecf4',
        ],
        [
            0.14285714285714285,
            '#dbe8f2',
        ],
        [
            0.16326530612244897,
            '#d6e4ef',
        ],
        [
            0.18367346938775508,
            '#d0e0ed',
        ],
        [
            0.2040816326530612,
            '#cbdceb',
        ],
        [
            0.22448979591836732,
            '#c5d8e8',
        ],
        [
            0.24489795918367346,
            '#c0d4e6',
        ],
        [
            0.26530612244897955,
            '#bbd0e4',
        ],
        [
            0.2857142857142857,
            '#b5cce2',
        ],
        [
            0.3061224489795918,
            '#b0c8e0',
        ],
        [
            0.32653061224489793,
            '#abc5de',
        ],
        [
            0.3469387755102041,
            '#a5c1dc',
        ],
        [
            0.36734693877551017,
            '#9fbdda',
        ],
        [
            0.3877551020408163,
            '#9cb7d7',
        ],
        [
            0.4081632653061224,
            '#99b2d4',
        ],
        [
            0.42857142857142855,
            '#96acd1',
        ],
        [
            0.44897959183673464,
            '#93a6ce',
        ],
        [
            0.4693877551020408,
            '#909eca',
        ],
        [
            0.4897959183673469,
            '#8d98c7',
        ],
        [
            0.5102040816326531,
            '#8c92c4',
        ],
        [
            0.5306122448979591,
            '#8c8bc1',
        ],
        [
            0.5510204081632653,
            '#8c83bd',
        ],
        [
            0.5714285714285714,
            '#8c7db9',
        ],
        [
            0.5918367346938775,
            '#8c76b6',
        ],
        [
            0.6122448979591836,
            '#8c6fb3',
        ],
        [
            0.6326530612244897,
            '#8b68af',
        ],
        [
            0.6530612244897959,
            '#8b60ac',
        ],
        [
            0.673469387755102,
            '#8a5aa9',
        ],
        [
            0.6938775510204082,
            '#8953a5',
        ],
        [
            0.7142857142857142,
            '#894da2',
        ],
        [
            0.7346938775510203,
            '#88459f',
        ],
        [
            0.7551020408163265,
            '#873e9b',
        ],
        [
            0.7755102040816326,
            '#863696',
        ],
        [
            0.7959183673469387,
            '#852e90',
        ],
        [
            0.8163265306122448,
            '#84268b',
        ],
        [
            0.836734693877551,
            '#831d85',
        ],
        [
            0.8571428571428571,
            '#811580',
        ],
        [
            0.8775510204081632,
            '#7f0e7a',
        ],
        [
            0.8979591836734693,
            '#770c72',
        ],
        [
            0.9183673469387754,
            '#6d0969',
        ],
        [
            0.9387755102040816,
            '#650762',
        ],
        [
            0.9591836734693877,
            '#5d045a',
        ],
        [
            0.9795918367346939,
            '#550252',
        ],
        [
            1.0,
            '#4d004b',
        ],
    ],
    Bwr: [
        [
            0.0,
            '#0000ff',
        ],
        [
            0.02040816326530612,
            '#0a0aff',
        ],
        [
            0.04081632653061224,
            '#1414ff',
        ],
        [
            0.061224489795918366,
            '#1e1eff',
        ],
        [
            0.08163265306122448,
            '#2828ff',
        ],
        [
            0.1020408163265306,
            '#3434ff',
        ],
        [
            0.12244897959183673,
            '#3e3eff',
        ],
        [
            0.14285714285714285,
            '#4848ff',
        ],
        [
            0.16326530612244897,
            '#5151ff',
        ],
        [
            0.18367346938775508,
            '#5e5eff',
        ],
        [
            0.2040816326530612,
            '#6868ff',
        ],
        [
            0.22448979591836732,
            '#7171ff',
        ],
        [
            0.24489795918367346,
            '#7c7cff',
        ],
        [
            0.26530612244897955,
            '#8686ff',
        ],
        [
            0.2857142857142857,
            '#9292ff',
        ],
        [
            0.3061224489795918,
            '#9c9cff',
        ],
        [
            0.32653061224489793,
            '#a6a6ff',
        ],
        [
            0.3469387755102041,
            '#b0b0ff',
        ],
        [
            0.36734693877551017,
            '#bcbcff',
        ],
        [
            0.3877551020408163,
            '#c6c6ff',
        ],
        [
            0.4081632653061224,
            '#d0d0ff',
        ],
        [
            0.42857142857142855,
            '#dadaff',
        ],
        [
            0.44897959183673464,
            '#e3e3ff',
        ],
        [
            0.4693877551020408,
            '#f0f0ff',
        ],
        [
            0.4897959183673469,
            '#fafaff',
        ],
        [
            0.5102040816326531,
            '#fffafa',
        ],
        [
            0.5306122448979591,
            '#fff0f0',
        ],
        [
            0.5510204081632653,
            '#ffe4e4',
        ],
        [
            0.5714285714285714,
            '#ffdada',
        ],
        [
            0.5918367346938775,
            '#ffd0d0',
        ],
        [
            0.6122448979591836,
            '#ffc6c6',
        ],
        [
            0.6326530612244897,
            '#ffbcbc',
        ],
        [
            0.6530612244897959,
            '#ffb0b0',
        ],
        [
            0.673469387755102,
            '#ffa6a6',
        ],
        [
            0.6938775510204082,
            '#ff9c9c',
        ],
        [
            0.7142857142857142,
            '#ff9292',
        ],
        [
            0.7346938775510203,
            '#ff8686',
        ],
        [
            0.7551020408163265,
            '#ff7c7c',
        ],
        [
            0.7755102040816326,
            '#ff7171',
        ],
        [
            0.7959183673469387,
            '#ff6868',
        ],
        [
            0.8163265306122448,
            '#ff5e5e',
        ],
        [
            0.836734693877551,
            '#ff5151',
        ],
        [
            0.8571428571428571,
            '#ff4848',
        ],
        [
            0.8775510204081632,
            '#ff3e3e',
        ],
        [
            0.8979591836734693,
            '#ff3434',
        ],
        [
            0.9183673469387754,
            '#ff2828',
        ],
        [
            0.9387755102040816,
            '#ff1e1e',
        ],
        [
            0.9591836734693877,
            '#ff1414',
        ],
        [
            0.9795918367346939,
            '#ff0909',
        ],
        [
            1.0,
            '#ff0000',
        ],
    ],
    CMRmap: [
        [
            0.0,
            '#000000',
        ],
        [
            0.02040816326530612,
            '#060614',
        ],
        [
            0.04081632653061224,
            '#0c0c28',
        ],
        [
            0.061224489795918366,
            '#12123c',
        ],
        [
            0.08163265306122448,
            '#181850',
        ],
        [
            0.1020408163265306,
            '#1f1f68',
        ],
        [
            0.12244897959183673,
            '#25257c',
        ],
        [
            0.14285714285714285,
            '#2b2687',
        ],
        [
            0.16326530612244897,
            '#312691',
        ],
        [
            0.18367346938775508,
            '#38269d',
        ],
        [
            0.2040816326530612,
            '#3e26a7',
        ],
        [
            0.22448979591836732,
            '#4426b1',
        ],
        [
            0.24489795918367346,
            '#4a26bb',
        ],
        [
            0.26530612244897955,
            '#5427b8',
        ],
        [
            0.2857142857142857,
            '#6229ac',
        ],
        [
            0.3061224489795918,
            '#6e2ba2',
        ],
        [
            0.32653061224489793,
            '#7a2d98',
        ],
        [
            0.3469387755102041,
            '#862f8e',
        ],
        [
            0.36734693877551017,
            '#953282',
        ],
        [
            0.3877551020408163,
            '#a33476',
        ],
        [
            0.4081632653061224,
            '#b33668',
        ],
        [
            0.42857142857142855,
            '#c3385a',
        ],
        [
            0.44897959183673464,
            '#d33a4c',
        ],
        [
            0.4693877551020408,
            '#e73c3b',
        ],
        [
            0.4897959183673469,
            '#f73e2d',
        ],
        [
            0.5102040816326531,
            '#fd4423',
        ],
        [
            0.5306122448979591,
            '#f94e1d',
        ],
        [
            0.5510204081632653,
            '#f45a16',
        ],
        [
            0.5714285714285714,
            '#f06410',
        ],
        [
            0.5918367346938775,
            '#ec6e0a',
        ],
        [
            0.6122448979591836,
            '#e87804',
        ],
        [
            0.6326530612244897,
            '#e58201',
        ],
        [
            0.6530612244897959,
            '#e58e06',
        ],
        [
            0.673469387755102,
            '#e5980a',
        ],
        [
            0.6938775510204082,
            '#e5a20e',
        ],
        [
            0.7142857142857142,
            '#e5ac12',
        ],
        [
            0.7346938775510203,
            '#e5b816',
        ],
        [
            0.7551020408163265,
            '#e5c11f',
        ],
        [
            0.7755102040816326,
            '#e5c72f',
        ],
        [
            0.7959183673469387,
            '#e5cd3f',
        ],
        [
            0.8163265306122448,
            '#e5d34f',
        ],
        [
            0.836734693877551,
            '#e5da62',
        ],
        [
            0.8571428571428571,
            '#e5e072',
        ],
        [
            0.8775510204081632,
            '#e6e683',
        ],
        [
            0.8979591836734693,
            '#eaea97',
        ],
        [
            0.9183673469387754,
            '#efefaf',
        ],
        [
            0.9387755102040816,
            '#f3f3c3',
        ],
        [
            0.9591836734693877,
            '#f7f7d7',
        ],
        [
            0.9795918367346939,
            '#fbfbeb',
        ],
        [
            1.0,
            '#ffffff',
        ],
    ],
    Cool: [
        [
            0.0,
            '#00ffff',
        ],
        [
            0.02040816326530612,
            '#05faff',
        ],
        [
            0.04081632653061224,
            '#0af5ff',
        ],
        [
            0.061224489795918366,
            '#0ff0ff',
        ],
        [
            0.08163265306122448,
            '#14ebff',
        ],
        [
            0.1020408163265306,
            '#1ae5ff',
        ],
        [
            0.12244897959183673,
            '#1fe0ff',
        ],
        [
            0.14285714285714285,
            '#24dbff',
        ],
        [
            0.16326530612244897,
            '#28d6ff',
        ],
        [
            0.18367346938775508,
            '#2fd0ff',
        ],
        [
            0.2040816326530612,
            '#34cbff',
        ],
        [
            0.22448979591836732,
            '#38c6ff',
        ],
        [
            0.24489795918367346,
            '#3ec1ff',
        ],
        [
            0.26530612244897955,
            '#43bcff',
        ],
        [
            0.2857142857142857,
            '#49b6ff',
        ],
        [
            0.3061224489795918,
            '#4eb1ff',
        ],
        [
            0.32653061224489793,
            '#53acff',
        ],
        [
            0.3469387755102041,
            '#58a7ff',
        ],
        [
            0.36734693877551017,
            '#5ea1ff',
        ],
        [
            0.3877551020408163,
            '#639cff',
        ],
        [
            0.4081632653061224,
            '#6897ff',
        ],
        [
            0.42857142857142855,
            '#6d92ff',
        ],
        [
            0.44897959183673464,
            '#718dff',
        ],
        [
            0.4693877551020408,
            '#7887ff',
        ],
        [
            0.4897959183673469,
            '#7d82ff',
        ],
        [
            0.5102040816326531,
            '#827dff',
        ],
        [
            0.5306122448979591,
            '#8778ff',
        ],
        [
            0.5510204081632653,
            '#8d71ff',
        ],
        [
            0.5714285714285714,
            '#926dff',
        ],
        [
            0.5918367346938775,
            '#9768ff',
        ],
        [
            0.6122448979591836,
            '#9c62ff',
        ],
        [
            0.6326530612244897,
            '#a15eff',
        ],
        [
            0.6530612244897959,
            '#a758ff',
        ],
        [
            0.673469387755102,
            '#ac52ff',
        ],
        [
            0.6938775510204082,
            '#b14eff',
        ],
        [
            0.7142857142857142,
            '#b649ff',
        ],
        [
            0.7346938775510203,
            '#bc42ff',
        ],
        [
            0.7551020408163265,
            '#c13eff',
        ],
        [
            0.7755102040816326,
            '#c638ff',
        ],
        [
            0.7959183673469387,
            '#cb34ff',
        ],
        [
            0.8163265306122448,
            '#d02fff',
        ],
        [
            0.836734693877551,
            '#d628ff',
        ],
        [
            0.8571428571428571,
            '#db24ff',
        ],
        [
            0.8775510204081632,
            '#e01fff',
        ],
        [
            0.8979591836734693,
            '#e519ff',
        ],
        [
            0.9183673469387754,
            '#eb14ff',
        ],
        [
            0.9387755102040816,
            '#f00fff',
        ],
        [
            0.9591836734693877,
            '#f509ff',
        ],
        [
            0.9795918367346939,
            '#fa05ff',
        ],
        [
            1.0,
            '#ff00ff',
        ],
    ],
    Coolwarm: [
        [
            0.0,
            '#3a4cc0',
        ],
        [
            0.03125,
            '#435acc',
        ],
        [
            0.0625,
            '#4d67d7',
        ],
        [
            0.09375,
            '#5775e1',
        ],
        [
            0.125,
            '#6182ea',
        ],
        [
            0.15625,
            '#6c8ef1',
        ],
        [
            0.1875,
            '#779af6',
        ],
        [
            0.21875,
            '#82a5fb',
        ],
        [
            0.25,
            '#8daffd',
        ],
        [
            0.28125,
            '#98b9fe',
        ],
        [
            0.3125,
            '#a3c1fe',
        ],
        [
            0.34375,
            '#aec9fc',
        ],
        [
            0.375,
            '#b8cff8',
        ],
        [
            0.40625,
            '#c2d4f3',
        ],
        [
            0.4375,
            '#ccd8ed',
        ],
        [
            0.46875,
            '#d5dbe5',
        ],
        [
            0.5,
            '#dddcdb',
        ],
        [
            0.53125,
            '#e5d8d0',
        ],
        [
            0.5625,
            '#ecd2c4',
        ],
        [
            0.59375,
            '#f1cbb8',
        ],
        [
            0.625,
            '#f4c3ab',
        ],
        [
            0.65625,
            '#f6ba9f',
        ],
        [
            0.6875,
            '#f7b092',
        ],
        [
            0.71875,
            '#f6a486',
        ],
        [
            0.75,
            '#f39879',
        ],
        [
            0.78125,
            '#f08b6d',
        ],
        [
            0.8125,
            '#ea7d61',
        ],
        [
            0.84375,
            '#e46e56',
        ],
        [
            0.875,
            '#dc5e4b',
        ],
        [
            0.90625,
            '#d34d40',
        ],
        [
            0.9375,
            '#c93b37',
        ],
        [
            0.96875,
            '#be232d',
        ],
        [
            1.0,
            '#b30326',
        ],
    ],
    Copper: [
        [
            0.0,
            '#000000',
        ],
        [
            0.02040816326530612,
            '#060302',
        ],
        [
            0.04081632653061224,
            '#0c0704',
        ],
        [
            0.061224489795918366,
            '#120b07',
        ],
        [
            0.08163265306122448,
            '#180f09',
        ],
        [
            0.1020408163265306,
            '#20140c',
        ],
        [
            0.12244897959183673,
            '#26180f',
        ],
        [
            0.14285714285714285,
            '#2c1c11',
        ],
        [
            0.16326530612244897,
            '#322014',
        ],
        [
            0.18367346938775508,
            '#3a2417',
        ],
        [
            0.2040816326530612,
            '#402819',
        ],
        [
            0.22448979591836732,
            '#462c1c',
        ],
        [
            0.24489795918367346,
            '#4c301e',
        ],
        [
            0.26530612244897955,
            '#523421',
        ],
        [
            0.2857142857142857,
            '#5a3924',
        ],
        [
            0.3061224489795918,
            '#603c26',
        ],
        [
            0.32653061224489793,
            '#664029',
        ],
        [
            0.3469387755102041,
            '#6c442b',
        ],
        [
            0.36734693877551017,
            '#74492e',
        ],
        [
            0.3877551020408163,
            '#7a4d31',
        ],
        [
            0.4081632653061224,
            '#805133',
        ],
        [
            0.42857142857142855,
            '#865536',
        ],
        [
            0.44897959183673464,
            '#8c5938',
        ],
        [
            0.4693877551020408,
            '#945d3b',
        ],
        [
            0.4897959183673469,
            '#9a613e',
        ],
        [
            0.5102040816326531,
            '#a06540',
        ],
        [
            0.5306122448979591,
            '#a66943',
        ],
        [
            0.5510204081632653,
            '#ae6e46',
        ],
        [
            0.5714285714285714,
            '#b47248',
        ],
        [
            0.5918367346938775,
            '#ba754b',
        ],
        [
            0.6122448979591836,
            '#c0794d',
        ],
        [
            0.6326530612244897,
            '#c67d50',
        ],
        [
            0.6530612244897959,
            '#ce8253',
        ],
        [
            0.673469387755102,
            '#d48655',
        ],
        [
            0.6938775510204082,
            '#da8a58',
        ],
        [
            0.7142857142857142,
            '#e08e5a',
        ],
        [
            0.7346938775510203,
            '#e8925d',
        ],
        [
            0.7551020408163265,
            '#ee9660',
        ],
        [
            0.7755102040816326,
            '#f49a62',
        ],
        [
            0.7959183673469387,
            '#fa9e64',
        ],
        [
            0.8163265306122448,
            '#ffa267',
        ],
        [
            0.836734693877551,
            '#ffa76a',
        ],
        [
            0.8571428571428571,
            '#ffab6c',
        ],
        [
            0.8775510204081632,
            '#ffae6f',
        ],
        [
            0.8979591836734693,
            '#ffb271',
        ],
        [
            0.9183673469387754,
            '#ffb774',
        ],
        [
            0.9387755102040816,
            '#ffbb77',
        ],
        [
            0.9591836734693877,
            '#ffbf79',
        ],
        [
            0.9795918367346939,
            '#ffc37c',
        ],
        [
            1.0,
            '#ffc77e',
        ],
    ],
    Cubehelix: [
        [
            0.0,
            '#000000',
        ],
        [
            0.02040816326530612,
            '#080208',
        ],
        [
            0.04081632653061224,
            '#0e0611',
        ],
        [
            0.061224489795918366,
            '#140a1b',
        ],
        [
            0.08163265306122448,
            '#170e25',
        ],
        [
            0.1020408163265306,
            '#1a1530',
        ],
        [
            0.12244897959183673,
            '#1a1c39',
        ],
        [
            0.14285714285714285,
            '#1a2341',
        ],
        [
            0.16326530612244897,
            '#182b47',
        ],
        [
            0.18367346938775508,
            '#17354c',
        ],
        [
            0.2040816326530612,
            '#153e4e',
        ],
        [
            0.22448979591836732,
            '#14474e',
        ],
        [
            0.24489795918367346,
            '#154f4c',
        ],
        [
            0.26530612244897955,
            '#165849',
        ],
        [
            0.2857142857142857,
            '#1b6144',
        ],
        [
            0.3061224489795918,
            '#206740',
        ],
        [
            0.32653061224489793,
            '#276d3a',
        ],
        [
            0.3469387755102041,
            '#317236',
        ],
        [
            0.36734693877551017,
            '#3e7631',
        ],
        [
            0.3877551020408163,
            '#4b782f',
        ],
        [
            0.4081632653061224,
            '#59792e',
        ],
        [
            0.42857142857142855,
            '#687a30',
        ],
        [
            0.44897959183673464,
            '#787a33',
        ],
        [
            0.4693877551020408,
            '#8a793b',
        ],
        [
            0.4897959183673469,
            '#997943',
        ],
        [
            0.5102040816326531,
            '#a6784e',
        ],
        [
            0.5306122448979591,
            '#b2785b',
        ],
        [
            0.5510204081632653,
            '#bf796c',
        ],
        [
            0.5714285714285714,
            '#c77a7c',
        ],
        [
            0.5918367346938775,
            '#cd7d8c',
        ],
        [
            0.6122448979591836,
            '#d1809c',
        ],
        [
            0.6326530612244897,
            '#d484ac',
        ],
        [
            0.6530612244897959,
            '#d48bbd',
        ],
        [
            0.673469387755102,
            '#d292cb',
        ],
        [
            0.6938775510204082,
            '#d09ad6',
        ],
        [
            0.7142857142857142,
            '#cda2e0',
        ],
        [
            0.7346938775510203,
            '#c9ace9',
        ],
        [
            0.7551020408163265,
            '#c5b6ee',
        ],
        [
            0.7755102040816326,
            '#c3bff1',
        ],
        [
            0.7959183673469387,
            '#c1c8f3',
        ],
        [
            0.8163265306122448,
            '#c1d0f3',
        ],
        [
            0.836734693877551,
            '#c3daf2',
        ],
        [
            0.8571428571428571,
            '#c6e1f1',
        ],
        [
            0.8775510204081632,
            '#cbe7ef',
        ],
        [
            0.8979591836734693,
            '#d1edee',
        ],
        [
            0.9183673469387754,
            '#daf2ee',
        ],
        [
            0.9387755102040816,
            '#e3f6f0',
        ],
        [
            0.9591836734693877,
            '#ecf9f3',
        ],
        [
            0.9795918367346939,
            '#f5fcf8',
        ],
        [
            1.0,
            '#ffffff',
        ],
    ],
    Gist_earth: [
        [
            0.0,
            '#000000',
        ],
        [
            0.02040816326530612,
            '#030058',
        ],
        [
            0.04081632653061224,
            '#060674',
        ],
        [
            0.061224489795918366,
            '#091275',
        ],
        [
            0.08163265306122448,
            '#0d1d76',
        ],
        [
            0.1020408163265306,
            '#112b77',
        ],
        [
            0.12244897959183673,
            '#143678',
        ],
        [
            0.14285714285714285,
            '#174079',
        ],
        [
            0.16326530612244897,
            '#1b4b79',
        ],
        [
            0.18367346938775508,
            '#1f567b',
        ],
        [
            0.2040816326530612,
            '#22607b',
        ],
        [
            0.22448979591836732,
            '#25687c',
        ],
        [
            0.24489795918367346,
            '#29707d',
        ],
        [
            0.26530612244897955,
            '#2c787e',
        ],
        [
            0.2857142857142857,
            '#30807e',
        ],
        [
            0.3061224489795918,
            '#328378',
        ],
        [
            0.32653061224489793,
            '#358672',
        ],
        [
            0.3469387755102041,
            '#37896c',
        ],
        [
            0.36734693877551017,
            '#3a8c64',
        ],
        [
            0.3877551020408163,
            '#3c8f5e',
        ],
        [
            0.4081632653061224,
            '#3f9258',
        ],
        [
            0.42857142857142855,
            '#419552',
        ],
        [
            0.44897959183673464,
            '#43984c',
        ],
        [
            0.4693877551020408,
            '#4b9b46',
        ],
        [
            0.4897959183673469,
            '#579e49',
        ],
        [
            0.5102040816326531,
            '#62a14c',
        ],
        [
            0.5306122448979591,
            '#6da34f',
        ],
        [
            0.5510204081632653,
            '#79a652',
        ],
        [
            0.5714285714285714,
            '#82a853',
        ],
        [
            0.5918367346938775,
            '#8aab55',
        ],
        [
            0.6122448979591836,
            '#92ad57',
        ],
        [
            0.6326530612244897,
            '#9aaf58',
        ],
        [
            0.6530612244897959,
            '#a4b25a',
        ],
        [
            0.673469387755102,
            '#adb45c',
        ],
        [
            0.6938775510204082,
            '#b5b65d',
        ],
        [
            0.7142857142857142,
            '#b8b35f',
        ],
        [
            0.7346938775510203,
            '#bbae61',
        ],
        [
            0.7551020408163265,
            '#bda962',
        ],
        [
            0.7755102040816326,
            '#bfa564',
        ],
        [
            0.7959183673469387,
            '#c2a36c',
        ],
        [
            0.8163265306122448,
            '#c8a678',
        ],
        [
            0.836734693877551,
            '#cfac87',
        ],
        [
            0.8571428571428571,
            '#d4b093',
        ],
        [
            0.8775510204081632,
            '#dab69f',
        ],
        [
            0.8979591836734693,
            '#dfbdac',
        ],
        [
            0.9183673469387754,
            '#e6c9be',
        ],
        [
            0.9387755102040816,
            '#ecd3cd',
        ],
        [
            0.9591836734693877,
            '#f1dedc',
        ],
        [
            0.9795918367346939,
            '#f7eceb',
        ],
        [
            1.0,
            '#fdfafa',
        ],
    ],
    Gist_gray: [
        [
            0.0,
            '#000000',
        ],
        [
            0.02040816326530612,
            '#050505',
        ],
        [
            0.04081632653061224,
            '#0a0a0a',
        ],
        [
            0.061224489795918366,
            '#0f0f0f',
        ],
        [
            0.08163265306122448,
            '#141414',
        ],
        [
            0.1020408163265306,
            '#1a1a1a',
        ],
        [
            0.12244897959183673,
            '#1f1f1f',
        ],
        [
            0.14285714285714285,
            '#242424',
        ],
        [
            0.16326530612244897,
            '#282828',
        ],
        [
            0.18367346938775508,
            '#2f2f2f',
        ],
        [
            0.2040816326530612,
            '#343434',
        ],
        [
            0.22448979591836732,
            '#383838',
        ],
        [
            0.24489795918367346,
            '#3e3e3e',
        ],
        [
            0.26530612244897955,
            '#434343',
        ],
        [
            0.2857142857142857,
            '#494949',
        ],
        [
            0.3061224489795918,
            '#4e4e4e',
        ],
        [
            0.32653061224489793,
            '#535353',
        ],
        [
            0.3469387755102041,
            '#585858',
        ],
        [
            0.36734693877551017,
            '#5e5e5e',
        ],
        [
            0.3877551020408163,
            '#636363',
        ],
        [
            0.4081632653061224,
            '#686868',
        ],
        [
            0.42857142857142855,
            '#6d6d6d',
        ],
        [
            0.44897959183673464,
            '#717171',
        ],
        [
            0.4693877551020408,
            '#787878',
        ],
        [
            0.4897959183673469,
            '#7d7d7d',
        ],
        [
            0.5102040816326531,
            '#828282',
        ],
        [
            0.5306122448979591,
            '#878787',
        ],
        [
            0.5510204081632653,
            '#8d8d8d',
        ],
        [
            0.5714285714285714,
            '#929292',
        ],
        [
            0.5918367346938775,
            '#979797',
        ],
        [
            0.6122448979591836,
            '#9c9c9c',
        ],
        [
            0.6326530612244897,
            '#a1a1a1',
        ],
        [
            0.6530612244897959,
            '#a7a7a7',
        ],
        [
            0.673469387755102,
            '#acacac',
        ],
        [
            0.6938775510204082,
            '#b1b1b1',
        ],
        [
            0.7142857142857142,
            '#b6b6b6',
        ],
        [
            0.7346938775510203,
            '#bcbcbc',
        ],
        [
            0.7551020408163265,
            '#c1c1c1',
        ],
        [
            0.7755102040816326,
            '#c6c6c6',
        ],
        [
            0.7959183673469387,
            '#cbcbcb',
        ],
        [
            0.8163265306122448,
            '#d0d0d0',
        ],
        [
            0.836734693877551,
            '#d6d6d6',
        ],
        [
            0.8571428571428571,
            '#dbdbdb',
        ],
        [
            0.8775510204081632,
            '#e0e0e0',
        ],
        [
            0.8979591836734693,
            '#e5e5e5',
        ],
        [
            0.9183673469387754,
            '#ebebeb',
        ],
        [
            0.9387755102040816,
            '#f0f0f0',
        ],
        [
            0.9591836734693877,
            '#f5f5f5',
        ],
        [
            0.9795918367346939,
            '#fafafa',
        ],
        [
            1.0,
            '#ffffff',
        ],
    ],
    Gist_heat: [
        [
            0.0,
            '#000000',
        ],
        [
            0.02040816326530612,
            '#070000',
        ],
        [
            0.04081632653061224,
            '#0f0000',
        ],
        [
            0.061224489795918366,
            '#160000',
        ],
        [
            0.08163265306122448,
            '#1e0000',
        ],
        [
            0.1020408163265306,
            '#270000',
        ],
        [
            0.12244897959183673,
            '#2e0000',
        ],
        [
            0.14285714285714285,
            '#360000',
        ],
        [
            0.16326530612244897,
            '#3d0000',
        ],
        [
            0.18367346938775508,
            '#460000',
        ],
        [
            0.2040816326530612,
            '#4e0000',
        ],
        [
            0.22448979591836732,
            '#550000',
        ],
        [
            0.24489795918367346,
            '#5d0000',
        ],
        [
            0.26530612244897955,
            '#640000',
        ],
        [
            0.2857142857142857,
            '#6d0000',
        ],
        [
            0.3061224489795918,
            '#750000',
        ],
        [
            0.32653061224489793,
            '#7c0000',
        ],
        [
            0.3469387755102041,
            '#830000',
        ],
        [
            0.36734693877551017,
            '#8d0000',
        ],
        [
            0.3877551020408163,
            '#940000',
        ],
        [
            0.4081632653061224,
            '#9c0000',
        ],
        [
            0.42857142857142855,
            '#a30000',
        ],
        [
            0.44897959183673464,
            '#ab0000',
        ],
        [
            0.4693877551020408,
            '#b30000',
        ],
        [
            0.4897959183673469,
            '#bb0000',
        ],
        [
            0.5102040816326531,
            '#c30400',
        ],
        [
            0.5306122448979591,
            '#ca0f00',
        ],
        [
            0.5510204081632653,
            '#d31b00',
        ],
        [
            0.5714285714285714,
            '#db2400',
        ],
        [
            0.5918367346938775,
            '#e22f00',
        ],
        [
            0.6122448979591836,
            '#ea3900',
        ],
        [
            0.6326530612244897,
            '#f14200',
        ],
        [
            0.6530612244897959,
            '#fa4f00',
        ],
        [
            0.673469387755102,
            '#ff5900',
        ],
        [
            0.6938775510204082,
            '#ff6200',
        ],
        [
            0.7142857142857142,
            '#ff6d00',
        ],
        [
            0.7346938775510203,
            '#ff7900',
        ],
        [
            0.7551020408163265,
            '#ff8306',
        ],
        [
            0.7755102040816326,
            '#ff8d1b',
        ],
        [
            0.7959183673469387,
            '#ff962e',
        ],
        [
            0.8163265306122448,
            '#ffa142',
        ],
        [
            0.836734693877551,
            '#ffad5b',
        ],
        [
            0.8571428571428571,
            '#ffb66e',
        ],
        [
            0.8775510204081632,
            '#ffc183',
        ],
        [
            0.8979591836734693,
            '#ffcb97',
        ],
        [
            0.9183673469387754,
            '#ffd6ae',
        ],
        [
            0.9387755102040816,
            '#ffe1c3',
        ],
        [
            0.9591836734693877,
            '#ffebd7',
        ],
        [
            0.9795918367346939,
            '#fff4ea',
        ],
        [
            1.0,
            '#ffffff',
        ],
    ],
    Gist_ncar: [
        [
            0.0,
            '#000080',
        ],
        [
            0.02040816326530612,
            '#002450',
        ],
        [
            0.04081632653061224,
            '#004821',
        ],
        [
            0.061224489795918366,
            '#005126',
        ],
        [
            0.08163265306122448,
            '#002f79',
        ],
        [
            0.1020408163265306,
            '#0006dd',
        ],
        [
            0.12244897959183673,
            '#0038ff',
        ],
        [
            0.14285714285714285,
            '#007fff',
        ],
        [
            0.16326530612244897,
            '#00c0ff',
        ],
        [
            0.18367346938775508,
            '#00dbff',
        ],
        [
            0.2040816326530612,
            '#00f1fe',
        ],
        [
            0.22448979591836732,
            '#00fede',
        ],
        [
            0.24489795918367346,
            '#00fbbd',
        ],
        [
            0.26530612244897955,
            '#00fa9c',
        ],
        [
            0.2857142857142857,
            '#00fb5d',
        ],
        [
            0.3061224489795918,
            '#00fd29',
        ],
        [
            0.32653061224489793,
            '#19f700',
        ],
        [
            0.3469387755102041,
            '#39e400',
        ],
        [
            0.36734693877551017,
            '#5fce00',
        ],
        [
            0.3877551020408163,
            '#6dde00',
        ],
        [
            0.4081632653061224,
            '#77ee00',
        ],
        [
            0.42857142857142855,
            '#84fe0b',
        ],
        [
            0.44897959183673464,
            '#9aff1f',
        ],
        [
            0.4693877551020408,
            '#b6ff37',
        ],
        [
            0.4897959183673469,
            '#ccff2b',
        ],
        [
            0.5102040816326531,
            '#e3ff17',
        ],
        [
            0.5306122448979591,
            '#fafa03',
        ],
        [
            0.5510204081632653,
            '#ffeb00',
        ],
        [
            0.5714285714285714,
            '#ffde00',
        ],
        [
            0.5918367346938775,
            '#ffd203',
        ],
        [
            0.6122448979591836,
            '#ffc608',
        ],
        [
            0.6326530612244897,
            '#ffb90d',
        ],
        [
            0.6530612244897959,
            '#ff8808',
        ],
        [
            0.673469387755102,
            '#ff5f03',
        ],
        [
            0.6938775510204082,
            '#ff3d00',
        ],
        [
            0.7142857142857142,
            '#ff2600',
        ],
        [
            0.7346938775510203,
            '#ff0900',
        ],
        [
            0.7551020408163265,
            '#ff0058',
        ],
        [
            0.7755102040816326,
            '#ff00b1',
        ],
        [
            0.7959183673469387,
            '#f803fb',
        ],
        [
            0.8163265306122448,
            '#d514ff',
        ],
        [
            0.836734693877551,
            '#ac29ff',
        ],
        [
            0.8571428571428571,
            '#b044fa',
        ],
        [
            0.8775510204081632,
            '#cd61f4',
        ],
        [
            0.8979591836734693,
            '#eb7fee',
        ],
        [
            0.9183673469387754,
            '#ef9bf1',
        ],
        [
            0.9387755102040816,
            '#f3b2f4',
        ],
        [
            0.9591836734693877,
            '#f6c9f7',
        ],
        [
            0.9795918367346939,
            '#fae0fa',
        ],
        [
            1.0,
            '#fef7fe',
        ],
    ],
    Gist_rainbow: [
        [
            0.0,
            '#ff0028',
        ],
        [
            0.02040816326530612,
            '#ff000e',
        ],
        [
            0.04081632653061224,
            '#ff0c00',
        ],
        [
            0.061224489795918366,
            '#ff2700',
        ],
        [
            0.08163265306122448,
            '#ff4200',
        ],
        [
            0.1020408163265306,
            '#ff6300',
        ],
        [
            0.12244897959183673,
            '#ff7e00',
        ],
        [
            0.14285714285714285,
            '#ff9900',
        ],
        [
            0.16326530612244897,
            '#ffb400',
        ],
        [
            0.18367346938775508,
            '#ffd400',
        ],
        [
            0.2040816326530612,
            '#ffef00',
        ],
        [
            0.22448979591836732,
            '#f3ff00',
        ],
        [
            0.24489795918367346,
            '#d8ff00',
        ],
        [
            0.26530612244897955,
            '#bdff00',
        ],
        [
            0.2857142857142857,
            '#9cff00',
        ],
        [
            0.3061224489795918,
            '#81ff00',
        ],
        [
            0.32653061224489793,
            '#66ff00',
        ],
        [
            0.3469387755102041,
            '#4bff00',
        ],
        [
            0.36734693877551017,
            '#2bff00',
        ],
        [
            0.3877551020408163,
            '#10ff00',
        ],
        [
            0.4081632653061224,
            '#00ff0a',
        ],
        [
            0.42857142857142855,
            '#00ff25',
        ],
        [
            0.44897959183673464,
            '#00ff40',
        ],
        [
            0.4693877551020408,
            '#00ff60',
        ],
        [
            0.4897959183673469,
            '#00ff7b',
        ],
        [
            0.5102040816326531,
            '#00ff96',
        ],
        [
            0.5306122448979591,
            '#00ffb1',
        ],
        [
            0.5510204081632653,
            '#00ffd1',
        ],
        [
            0.5714285714285714,
            '#00ffec',
        ],
        [
            0.5918367346938775,
            '#00f6ff',
        ],
        [
            0.6122448979591836,
            '#00dbff',
        ],
        [
            0.6326530612244897,
            '#00c0ff',
        ],
        [
            0.6530612244897959,
            '#009fff',
        ],
        [
            0.673469387755102,
            '#0084ff',
        ],
        [
            0.6938775510204082,
            '#0069ff',
        ],
        [
            0.7142857142857142,
            '#004dff',
        ],
        [
            0.7346938775510203,
            '#002dff',
        ],
        [
            0.7551020408163265,
            '#0012ff',
        ],
        [
            0.7755102040816326,
            '#0800ff',
        ],
        [
            0.7959183673469387,
            '#2400ff',
        ],
        [
            0.8163265306122448,
            '#3f00ff',
        ],
        [
            0.836734693877551,
            '#5f00ff',
        ],
        [
            0.8571428571428571,
            '#7b00ff',
        ],
        [
            0.8775510204081632,
            '#9600ff',
        ],
        [
            0.8979591836734693,
            '#b100ff',
        ],
        [
            0.9183673469387754,
            '#d200ff',
        ],
        [
            0.9387755102040816,
            '#ed00ff',
        ],
        [
            0.9591836734693877,
            '#ff00f5',
        ],
        [
            0.9795918367346939,
            '#ff00da',
        ],
        [
            1.0,
            '#ff00bf',
        ],
    ],
    Gist_stern: [
        [
            0.0,
            '#000000',
        ],
        [
            0.02040816326530612,
            '#5b050a',
        ],
        [
            0.04081632653061224,
            '#b60a14',
        ],
        [
            0.061224489795918366,
            '#f90f1e',
        ],
        [
            0.08163265306122448,
            '#e01428',
        ],
        [
            0.1020408163265306,
            '#c21a34',
        ],
        [
            0.12244897959183673,
            '#aa1f3e',
        ],
        [
            0.14285714285714285,
            '#912448',
        ],
        [
            0.16326530612244897,
            '#782851',
        ],
        [
            0.18367346938775508,
            '#5a2f5e',
        ],
        [
            0.2040816326530612,
            '#413468',
        ],
        [
            0.22448979591836732,
            '#283871',
        ],
        [
            0.24489795918367346,
            '#0f3e7c',
        ],
        [
            0.26530612244897955,
            '#434386',
        ],
        [
            0.2857142857142857,
            '#494992',
        ],
        [
            0.3061224489795918,
            '#4e4e9c',
        ],
        [
            0.32653061224489793,
            '#5353a6',
        ],
        [
            0.3469387755102041,
            '#5858b0',
        ],
        [
            0.36734693877551017,
            '#5e5ebc',
        ],
        [
            0.3877551020408163,
            '#6363c6',
        ],
        [
            0.4081632653061224,
            '#6868d0',
        ],
        [
            0.42857142857142855,
            '#6d6dda',
        ],
        [
            0.44897959183673464,
            '#7171e3',
        ],
        [
            0.4693877551020408,
            '#7878f0',
        ],
        [
            0.4897959183673469,
            '#7d7dfa',
        ],
        [
            0.5102040816326531,
            '#8282f4',
        ],
        [
            0.5306122448979591,
            '#8787df',
        ],
        [
            0.5510204081632653,
            '#8d8dc5',
        ],
        [
            0.5714285714285714,
            '#9292b0',
        ],
        [
            0.5918367346938775,
            '#97979a',
        ],
        [
            0.6122448979591836,
            '#9c9c85',
        ],
        [
            0.6326530612244897,
            '#a1a170',
        ],
        [
            0.6530612244897959,
            '#a7a756',
        ],
        [
            0.673469387755102,
            '#acac41',
        ],
        [
            0.6938775510204082,
            '#b1b12c',
        ],
        [
            0.7142857142857142,
            '#b6b617',
        ],
        [
            0.7346938775510203,
            '#bcbc02',
        ],
        [
            0.7551020408163265,
            '#c1c115',
        ],
        [
            0.7755102040816326,
            '#c5c627',
        ],
        [
            0.7959183673469387,
            '#cbcb3a',
        ],
        [
            0.8163265306122448,
            '#d0d04d',
        ],
        [
            0.836734693877551,
            '#d6d664',
        ],
        [
            0.8571428571428571,
            '#dbdb77',
        ],
        [
            0.8775510204081632,
            '#e0e08a',
        ],
        [
            0.8979591836734693,
            '#e5e59c',
        ],
        [
            0.9183673469387754,
            '#ebebb3',
        ],
        [
            0.9387755102040816,
            '#f0f0c6',
        ],
        [
            0.9591836734693877,
            '#f4f5d9',
        ],
        [
            0.9795918367346939,
            '#fafaec',
        ],
        [
            1.0,
            '#ffffff',
        ],
    ],
    Gist_yarg: [
        [
            0.0,
            '#ffffff',
        ],
        [
            0.02040816326530612,
            '#fafafa',
        ],
        [
            0.04081632653061224,
            '#f5f5f5',
        ],
        [
            0.061224489795918366,
            '#f0f0f0',
        ],
        [
            0.08163265306122448,
            '#ebebeb',
        ],
        [
            0.1020408163265306,
            '#e5e5e5',
        ],
        [
            0.12244897959183673,
            '#e0e0e0',
        ],
        [
            0.14285714285714285,
            '#dbdbdb',
        ],
        [
            0.16326530612244897,
            '#d6d6d6',
        ],
        [
            0.18367346938775508,
            '#d0d0d0',
        ],
        [
            0.2040816326530612,
            '#cbcbcb',
        ],
        [
            0.22448979591836732,
            '#c6c6c6',
        ],
        [
            0.24489795918367346,
            '#c1c1c1',
        ],
        [
            0.26530612244897955,
            '#bcbcbc',
        ],
        [
            0.2857142857142857,
            '#b6b6b6',
        ],
        [
            0.3061224489795918,
            '#b1b1b1',
        ],
        [
            0.32653061224489793,
            '#acacac',
        ],
        [
            0.3469387755102041,
            '#a7a7a7',
        ],
        [
            0.36734693877551017,
            '#a1a1a1',
        ],
        [
            0.3877551020408163,
            '#9c9c9c',
        ],
        [
            0.4081632653061224,
            '#979797',
        ],
        [
            0.42857142857142855,
            '#929292',
        ],
        [
            0.44897959183673464,
            '#8d8d8d',
        ],
        [
            0.4693877551020408,
            '#878787',
        ],
        [
            0.4897959183673469,
            '#828282',
        ],
        [
            0.5102040816326531,
            '#7d7d7d',
        ],
        [
            0.5306122448979591,
            '#787878',
        ],
        [
            0.5510204081632653,
            '#717171',
        ],
        [
            0.5714285714285714,
            '#6d6d6d',
        ],
        [
            0.5918367346938775,
            '#686868',
        ],
        [
            0.6122448979591836,
            '#626262',
        ],
        [
            0.6326530612244897,
            '#5e5e5e',
        ],
        [
            0.6530612244897959,
            '#585858',
        ],
        [
            0.673469387755102,
            '#525252',
        ],
        [
            0.6938775510204082,
            '#4e4e4e',
        ],
        [
            0.7142857142857142,
            '#494949',
        ],
        [
            0.7346938775510203,
            '#424242',
        ],
        [
            0.7551020408163265,
            '#3e3e3e',
        ],
        [
            0.7755102040816326,
            '#383838',
        ],
        [
            0.7959183673469387,
            '#343434',
        ],
        [
            0.8163265306122448,
            '#2f2f2f',
        ],
        [
            0.836734693877551,
            '#282828',
        ],
        [
            0.8571428571428571,
            '#242424',
        ],
        [
            0.8775510204081632,
            '#1f1f1f',
        ],
        [
            0.8979591836734693,
            '#191919',
        ],
        [
            0.9183673469387754,
            '#141414',
        ],
        [
            0.9387755102040816,
            '#0f0f0f',
        ],
        [
            0.9591836734693877,
            '#090909',
        ],
        [
            0.9795918367346939,
            '#050505',
        ],
        [
            1.0,
            '#000000',
        ],
    ],
    GnBu: [
        [
            0.0,
            '#f7fcf0',
        ],
        [
            0.02040816326530612,
            '#f3faec',
        ],
        [
            0.04081632653061224,
            '#eff9e9',
        ],
        [
            0.061224489795918366,
            '#ecf7e6',
        ],
        [
            0.08163265306122448,
            '#e8f6e2',
        ],
        [
            0.1020408163265306,
            '#e4f4de',
        ],
        [
            0.12244897959183673,
            '#e0f3db',
        ],
        [
            0.14285714285714285,
            '#ddf1d8',
        ],
        [
            0.16326530612244897,
            '#daf0d4',
        ],
        [
            0.18367346938775508,
            '#d6efd0',
        ],
        [
            0.2040816326530612,
            '#d3edcd',
        ],
        [
            0.22448979591836732,
            '#d0ecc9',
        ],
        [
            0.24489795918367346,
            '#cdebc6',
        ],
        [
            0.26530612244897955,
            '#c8e9c3',
        ],
        [
            0.2857142857142857,
            '#c1e6c0',
        ],
        [
            0.3061224489795918,
            '#bbe4bd',
        ],
        [
            0.32653061224489793,
            '#b6e2bb',
        ],
        [
            0.3469387755102041,
            '#b0e0b8',
        ],
        [
            0.36734693877551017,
            '#a9ddb5',
        ],
        [
            0.3877551020408163,
            '#a3dbb6',
        ],
        [
            0.4081632653061224,
            '#9cd8b8',
        ],
        [
            0.42857142857142855,
            '#95d5bb',
        ],
        [
            0.44897959183673464,
            '#8ed3bd',
        ],
        [
            0.4693877551020408,
            '#85d0c0',
        ],
        [
            0.4897959183673469,
            '#7ecdc2',
        ],
        [
            0.5102040816326531,
            '#77cac5',
        ],
        [
            0.5306122448979591,
            '#70c6c7',
        ],
        [
            0.5510204081632653,
            '#67c1ca',
        ],
        [
            0.5714285714285714,
            '#60bdcc',
        ],
        [
            0.5918367346938775,
            '#59b9cf',
        ],
        [
            0.6122448979591836,
            '#52b5d1',
        ],
        [
            0.6326530612244897,
            '#4cb1d1',
        ],
        [
            0.6530612244897959,
            '#45a9cd',
        ],
        [
            0.673469387755102,
            '#40a3ca',
        ],
        [
            0.6938775510204082,
            '#3a9dc7',
        ],
        [
            0.7142857142857142,
            '#3597c4',
        ],
        [
            0.7346938775510203,
            '#2e8fc0',
        ],
        [
            0.7551020408163265,
            '#298abd',
        ],
        [
            0.7755102040816326,
            '#2384ba',
        ],
        [
            0.7959183673469387,
            '#1e7eb7',
        ],
        [
            0.8163265306122448,
            '#1879b4',
        ],
        [
            0.836734693877551,
            '#1272b1',
        ],
        [
            0.8571428571428571,
            '#0c6cae',
        ],
        [
            0.8775510204081632,
            '#0866aa',
        ],
        [
            0.8979591836734693,
            '#0860a4',
        ],
        [
            0.9183673469387754,
            '#08599b',
        ],
        [
            0.9387755102040816,
            '#085295',
        ],
        [
            0.9591836734693877,
            '#084c8e',
        ],
        [
            0.9795918367346939,
            '#084687',
        ],
        [
            1.0,
            '#084081',
        ],
    ],
    Gnuplot: [
        [
            0.0,
            '#000000',
        ],
        [
            0.02040816326530612,
            '#23001f',
        ],
        [
            0.04081632653061224,
            '#32003e',
        ],
        [
            0.061224489795918366,
            '#3d005c',
        ],
        [
            0.08163265306122448,
            '#470078',
        ],
        [
            0.1020408163265306,
            '#510098',
        ],
        [
            0.12244897959183673,
            '#5800b0',
        ],
        [
            0.14285714285714285,
            '#5f00c5',
        ],
        [
            0.16326530612244897,
            '#6601d7',
        ],
        [
            0.18367346938775508,
            '#6d01e9',
        ],
        [
            0.2040816326530612,
            '#7302f4',
        ],
        [
            0.22448979591836732,
            '#7802fb',
        ],
        [
            0.24489795918367346,
            '#7d03fe',
        ],
        [
            0.26530612244897955,
            '#8204fe',
        ],
        [
            0.2857142857142857,
            '#8805f8',
        ],
        [
            0.3061224489795918,
            '#8d07ef',
        ],
        [
            0.32653061224489793,
            '#9108e2',
        ],
        [
            0.3469387755102041,
            '#950ad2',
        ],
        [
            0.36734693877551017,
            '#9a0cbb',
        ],
        [
            0.3877551020408163,
            '#9e0ea4',
        ],
        [
            0.4081632653061224,
            '#a2118b',
        ],
        [
            0.42857142857142855,
            '#a61370',
        ],
        [
            0.44897959183673464,
            '#aa1653',
        ],
        [
            0.4693877551020408,
            '#ae1a2e',
        ],
        [
            0.4897959183673469,
            '#b21e0f',
        ],
        [
            0.5102040816326531,
            '#b62100',
        ],
        [
            0.5306122448979591,
            '#b92500',
        ],
        [
            0.5510204081632653,
            '#bd2b00',
        ],
        [
            0.5714285714285714,
            '#c02f00',
        ],
        [
            0.5918367346938775,
            '#c43400',
        ],
        [
            0.6122448979591836,
            '#c73a00',
        ],
        [
            0.6326530612244897,
            '#ca4000',
        ],
        [
            0.6530612244897959,
            '#ce4700',
        ],
        [
            0.673469387755102,
            '#d14e00',
        ],
        [
            0.6938775510204082,
            '#d45500',
        ],
        [
            0.7142857142857142,
            '#d75c00',
        ],
        [
            0.7346938775510203,
            '#da6600',
        ],
        [
            0.7551020408163265,
            '#dd6e00',
        ],
        [
            0.7755102040816326,
            '#e07700',
        ],
        [
            0.7959183673469387,
            '#e38000',
        ],
        [
            0.8163265306122448,
            '#e68a00',
        ],
        [
            0.836734693877551,
            '#e99600',
        ],
        [
            0.8571428571428571,
            '#eca100',
        ],
        [
            0.8775510204081632,
            '#eeac00',
        ],
        [
            0.8979591836734693,
            '#f1b800',
        ],
        [
            0.9183673469387754,
            '#f4c700',
        ],
        [
            0.9387755102040816,
            '#f7d400',
        ],
        [
            0.9591836734693877,
            '#f9e200',
        ],
        [
            0.9795918367346939,
            '#fcf000',
        ],
        [
            1.0,
            '#ffff00',
        ],
    ],
    Gnuplot2: [
        [
            0.0,
            '#000000',
        ],
        [
            0.02040816326530612,
            '#000014',
        ],
        [
            0.04081632653061224,
            '#000028',
        ],
        [
            0.061224489795918366,
            '#00003c',
        ],
        [
            0.08163265306122448,
            '#000050',
        ],
        [
            0.1020408163265306,
            '#000068',
        ],
        [
            0.12244897959183673,
            '#00007c',
        ],
        [
            0.14285714285714285,
            '#000090',
        ],
        [
            0.16326530612244897,
            '#0000a3',
        ],
        [
            0.18367346938775508,
            '#0000bc',
        ],
        [
            0.2040816326530612,
            '#0000d0',
        ],
        [
            0.22448979591836732,
            '#0000e3',
        ],
        [
            0.24489795918367346,
            '#0000f8',
        ],
        [
            0.26530612244897955,
            '#0a00ff',
        ],
        [
            0.2857142857142857,
            '#1c00ff',
        ],
        [
            0.3061224489795918,
            '#2c00ff',
        ],
        [
            0.32653061224489793,
            '#3c00ff',
        ],
        [
            0.3469387755102041,
            '#4b00ff',
        ],
        [
            0.36734693877551017,
            '#5e00ff',
        ],
        [
            0.3877551020408163,
            '#6e00ff',
        ],
        [
            0.4081632653061224,
            '#7d00ff',
        ],
        [
            0.42857142857142855,
            '#8d03fb',
        ],
        [
            0.44897959183673464,
            '#9d0df1',
        ],
        [
            0.4693877551020408,
            '#af19e5',
        ],
        [
            0.4897959183673469,
            '#bf23db',
        ],
        [
            0.5102040816326531,
            '#cf2dd1',
        ],
        [
            0.5306122448979591,
            '#de37c7',
        ],
        [
            0.5510204081632653,
            '#f143bb',
        ],
        [
            0.5714285714285714,
            '#ff4db1',
        ],
        [
            0.5918367346938775,
            '#ff57a7',
        ],
        [
            0.6122448979591836,
            '#ff619d',
        ],
        [
            0.6326530612244897,
            '#ff6b93',
        ],
        [
            0.6530612244897959,
            '#ff7787',
        ],
        [
            0.673469387755102,
            '#ff817d',
        ],
        [
            0.6938775510204082,
            '#ff8b73',
        ],
        [
            0.7142857142857142,
            '#ff9569',
        ],
        [
            0.7346938775510203,
            '#ffa15d',
        ],
        [
            0.7551020408163265,
            '#ffab53',
        ],
        [
            0.7755102040816326,
            '#ffb549',
        ],
        [
            0.7959183673469387,
            '#ffbf3f',
        ],
        [
            0.8163265306122448,
            '#ffc935',
        ],
        [
            0.836734693877551,
            '#ffd529',
        ],
        [
            0.8571428571428571,
            '#ffdf1f',
        ],
        [
            0.8775510204081632,
            '#ffe915',
        ],
        [
            0.8979591836734693,
            '#fff30b',
        ],
        [
            0.9183673469387754,
            '#ffff04',
        ],
        [
            0.9387755102040816,
            '#ffff43',
        ],
        [
            0.9591836734693877,
            '#ffff82',
        ],
        [
            0.9795918367346939,
            '#ffffc0',
        ],
        [
            1.0,
            '#ffffff',
        ],
    ],
    Gray: [
        [
            0.0,
            '#000000',
        ],
        [
            0.02040816326530612,
            '#050505',
        ],
        [
            0.04081632653061224,
            '#0a0a0a',
        ],
        [
            0.061224489795918366,
            '#0f0f0f',
        ],
        [
            0.08163265306122448,
            '#141414',
        ],
        [
            0.1020408163265306,
            '#1a1a1a',
        ],
        [
            0.12244897959183673,
            '#1f1f1f',
        ],
        [
            0.14285714285714285,
            '#242424',
        ],
        [
            0.16326530612244897,
            '#282828',
        ],
        [
            0.18367346938775508,
            '#2f2f2f',
        ],
        [
            0.2040816326530612,
            '#343434',
        ],
        [
            0.22448979591836732,
            '#383838',
        ],
        [
            0.24489795918367346,
            '#3e3e3e',
        ],
        [
            0.26530612244897955,
            '#434343',
        ],
        [
            0.2857142857142857,
            '#494949',
        ],
        [
            0.3061224489795918,
            '#4e4e4e',
        ],
        [
            0.32653061224489793,
            '#535353',
        ],
        [
            0.3469387755102041,
            '#585858',
        ],
        [
            0.36734693877551017,
            '#5e5e5e',
        ],
        [
            0.3877551020408163,
            '#636363',
        ],
        [
            0.4081632653061224,
            '#686868',
        ],
        [
            0.42857142857142855,
            '#6d6d6d',
        ],
        [
            0.44897959183673464,
            '#717171',
        ],
        [
            0.4693877551020408,
            '#787878',
        ],
        [
            0.4897959183673469,
            '#7d7d7d',
        ],
        [
            0.5102040816326531,
            '#828282',
        ],
        [
            0.5306122448979591,
            '#878787',
        ],
        [
            0.5510204081632653,
            '#8d8d8d',
        ],
        [
            0.5714285714285714,
            '#929292',
        ],
        [
            0.5918367346938775,
            '#979797',
        ],
        [
            0.6122448979591836,
            '#9c9c9c',
        ],
        [
            0.6326530612244897,
            '#a1a1a1',
        ],
        [
            0.6530612244897959,
            '#a7a7a7',
        ],
        [
            0.673469387755102,
            '#acacac',
        ],
        [
            0.6938775510204082,
            '#b1b1b1',
        ],
        [
            0.7142857142857142,
            '#b6b6b6',
        ],
        [
            0.7346938775510203,
            '#bcbcbc',
        ],
        [
            0.7551020408163265,
            '#c1c1c1',
        ],
        [
            0.7755102040816326,
            '#c6c6c6',
        ],
        [
            0.7959183673469387,
            '#cbcbcb',
        ],
        [
            0.8163265306122448,
            '#d0d0d0',
        ],
        [
            0.836734693877551,
            '#d6d6d6',
        ],
        [
            0.8571428571428571,
            '#dbdbdb',
        ],
        [
            0.8775510204081632,
            '#e0e0e0',
        ],
        [
            0.8979591836734693,
            '#e5e5e5',
        ],
        [
            0.9183673469387754,
            '#ebebeb',
        ],
        [
            0.9387755102040816,
            '#f0f0f0',
        ],
        [
            0.9591836734693877,
            '#f5f5f5',
        ],
        [
            0.9795918367346939,
            '#fafafa',
        ],
        [
            1.0,
            '#ffffff',
        ],
    ],
    Greens: [
        [
            0.0,
            '#f7fcf5',
        ],
        [
            0.02040816326530612,
            '#f4faf1',
        ],
        [
            0.04081632653061224,
            '#f1f9ee',
        ],
        [
            0.061224489795918366,
            '#eef8eb',
        ],
        [
            0.08163265306122448,
            '#ebf7e7',
        ],
        [
            0.1020408163265306,
            '#e8f6e3',
        ],
        [
            0.12244897959183673,
            '#e5f5e0',
        ],
        [
            0.14285714285714285,
            '#e1f3db',
        ],
        [
            0.16326530612244897,
            '#dcf1d6',
        ],
        [
            0.18367346938775508,
            '#d6efd0',
        ],
        [
            0.2040816326530612,
            '#d2edcb',
        ],
        [
            0.22448979591836732,
            '#cdebc6',
        ],
        [
            0.24489795918367346,
            '#c8e9c1',
        ],
        [
            0.26530612244897955,
            '#c3e7bc',
        ],
        [
            0.2857142857142857,
            '#bbe4b5',
        ],
        [
            0.3061224489795918,
            '#b6e1af',
        ],
        [
            0.32653061224489793,
            '#b0dfa9',
        ],
        [
            0.3469387755102041,
            '#aadca3',
        ],
        [
            0.36734693877551017,
            '#a2d99c',
        ],
        [
            0.3877551020408163,
            '#9cd697',
        ],
        [
            0.4081632653061224,
            '#95d391',
        ],
        [
            0.42857142857142855,
            '#8ed08b',
        ],
        [
            0.44897959183673464,
            '#87cc85',
        ],
        [
            0.4693877551020408,
            '#7ec87e',
        ],
        [
            0.4897959183673469,
            '#77c578',
        ],
        [
            0.5102040816326531,
            '#70c274',
        ],
        [
            0.5306122448979591,
            '#68be70',
        ],
        [
            0.5510204081632653,
            '#5eb96b',
        ],
        [
            0.5714285714285714,
            '#56b567',
        ],
        [
            0.5918367346938775,
            '#4eb163',
        ],
        [
            0.6122448979591836,
            '#46ad5f',
        ],
        [
            0.6326530612244897,
            '#3fa95b',
        ],
        [
            0.6530612244897959,
            '#39a357',
        ],
        [
            0.673469387755102,
            '#359e53',
        ],
        [
            0.6938775510204082,
            '#30994f',
        ],
        [
            0.7142857142857142,
            '#2b944b',
        ],
        [
            0.7346938775510203,
            '#268e47',
        ],
        [
            0.7551020408163265,
            '#218943',
        ],
        [
            0.7755102040816326,
            '#1b843f',
        ],
        [
            0.7959183673469387,
            '#167f3b',
        ],
        [
            0.8163265306122448,
            '#107b37',
        ],
        [
            0.836734693877551,
            '#0a7533',
        ],
        [
            0.8571428571428571,
            '#04702f',
        ],
        [
            0.8775510204081632,
            '#006b2b',
        ],
        [
            0.8979591836734693,
            '#006528',
        ],
        [
            0.9183673469387754,
            '#005d25',
        ],
        [
            0.9387755102040816,
            '#005723',
        ],
        [
            0.9591836734693877,
            '#005020',
        ],
        [
            0.9795918367346939,
            '#004a1d',
        ],
        [
            1.0,
            '#00441b',
        ],
    ],
    Greys: [
        [
            0.0,
            '#ffffff',
        ],
        [
            0.02040816326530612,
            '#fcfcfc',
        ],
        [
            0.04081632653061224,
            '#fafafa',
        ],
        [
            0.061224489795918366,
            '#f7f7f7',
        ],
        [
            0.08163265306122448,
            '#f5f5f5',
        ],
        [
            0.1020408163265306,
            '#f2f2f2',
        ],
        [
            0.12244897959183673,
            '#f0f0f0',
        ],
        [
            0.14285714285714285,
            '#ededed',
        ],
        [
            0.16326530612244897,
            '#e9e9e9',
        ],
        [
            0.18367346938775508,
            '#e5e5e5',
        ],
        [
            0.2040816326530612,
            '#e1e1e1',
        ],
        [
            0.22448979591836732,
            '#dddddd',
        ],
        [
            0.24489795918367346,
            '#dadada',
        ],
        [
            0.26530612244897955,
            '#d6d6d6',
        ],
        [
            0.2857142857142857,
            '#d0d0d0',
        ],
        [
            0.3061224489795918,
            '#cccccc',
        ],
        [
            0.32653061224489793,
            '#c8c8c8',
        ],
        [
            0.3469387755102041,
            '#c3c3c3',
        ],
        [
            0.36734693877551017,
            '#bebebe',
        ],
        [
            0.3877551020408163,
            '#b8b8b8',
        ],
        [
            0.4081632653061224,
            '#b2b2b2',
        ],
        [
            0.42857142857142855,
            '#acacac',
        ],
        [
            0.44897959183673464,
            '#a6a6a6',
        ],
        [
            0.4693877551020408,
            '#9f9f9f',
        ],
        [
            0.4897959183673469,
            '#999999',
        ],
        [
            0.5102040816326531,
            '#939393',
        ],
        [
            0.5306122448979591,
            '#8d8d8d',
        ],
        [
            0.5510204081632653,
            '#878787',
        ],
        [
            0.5714285714285714,
            '#818181',
        ],
        [
            0.5918367346938775,
            '#7c7c7c',
        ],
        [
            0.6122448979591836,
            '#767676',
        ],
        [
            0.6326530612244897,
            '#717171',
        ],
        [
            0.6530612244897959,
            '#6b6b6b',
        ],
        [
            0.673469387755102,
            '#656565',
        ],
        [
            0.6938775510204082,
            '#606060',
        ],
        [
            0.7142857142857142,
            '#5b5b5b',
        ],
        [
            0.7346938775510203,
            '#555555',
        ],
        [
            0.7551020408163265,
            '#4f4f4f',
        ],
        [
            0.7755102040816326,
            '#484848',
        ],
        [
            0.7959183673469387,
            '#414141',
        ],
        [
            0.8163265306122448,
            '#3a3a3a',
        ],
        [
            0.836734693877551,
            '#313131',
        ],
        [
            0.8571428571428571,
            '#2a2a2a',
        ],
        [
            0.8775510204081632,
            '#232323',
        ],
        [
            0.8979591836734693,
            '#1e1e1e',
        ],
        [
            0.9183673469387754,
            '#171717',
        ],
        [
            0.9387755102040816,
            '#111111',
        ],
        [
            0.9591836734693877,
            '#0b0b0b',
        ],
        [
            0.9795918367346939,
            '#050505',
        ],
        [
            1.0,
            '#000000',
        ],
    ],
    Hot: [
        [
            0.0,
            '#0a0000',
        ],
        [
            0.02040816326530612,
            '#170000',
        ],
        [
            0.04081632653061224,
            '#240000',
        ],
        [
            0.061224489795918366,
            '#310000',
        ],
        [
            0.08163265306122448,
            '#3f0000',
        ],
        [
            0.1020408163265306,
            '#4e0000',
        ],
        [
            0.12244897959183673,
            '#5b0000',
        ],
        [
            0.14285714285714285,
            '#690000',
        ],
        [
            0.16326530612244897,
            '#760000',
        ],
        [
            0.18367346938775508,
            '#850000',
        ],
        [
            0.2040816326530612,
            '#930000',
        ],
        [
            0.22448979591836732,
            '#a00000',
        ],
        [
            0.24489795918367346,
            '#ad0000',
        ],
        [
            0.26530612244897955,
            '#ba0000',
        ],
        [
            0.2857142857142857,
            '#ca0000',
        ],
        [
            0.3061224489795918,
            '#d70000',
        ],
        [
            0.32653061224489793,
            '#e40000',
        ],
        [
            0.3469387755102041,
            '#f10000',
        ],
        [
            0.36734693877551017,
            '#ff0200',
        ],
        [
            0.3877551020408163,
            '#ff0f00',
        ],
        [
            0.4081632653061224,
            '#ff1c00',
        ],
        [
            0.42857142857142855,
            '#ff2900',
        ],
        [
            0.44897959183673464,
            '#ff3600',
        ],
        [
            0.4693877551020408,
            '#ff4600',
        ],
        [
            0.4897959183673469,
            '#ff5300',
        ],
        [
            0.5102040816326531,
            '#ff6000',
        ],
        [
            0.5306122448979591,
            '#ff6e00',
        ],
        [
            0.5510204081632653,
            '#ff7d00',
        ],
        [
            0.5714285714285714,
            '#ff8a00',
        ],
        [
            0.5918367346938775,
            '#ff9700',
        ],
        [
            0.6122448979591836,
            '#ffa500',
        ],
        [
            0.6326530612244897,
            '#ffb200',
        ],
        [
            0.6530612244897959,
            '#ffc100',
        ],
        [
            0.673469387755102,
            '#ffcf00',
        ],
        [
            0.6938775510204082,
            '#ffdc00',
        ],
        [
            0.7142857142857142,
            '#ffe900',
        ],
        [
            0.7346938775510203,
            '#fff900',
        ],
        [
            0.7551020408163265,
            '#ffff0a',
        ],
        [
            0.7755102040816326,
            '#ffff1e',
        ],
        [
            0.7959183673469387,
            '#ffff32',
        ],
        [
            0.8163265306122448,
            '#ffff45',
        ],
        [
            0.836734693877551,
            '#ffff5d',
        ],
        [
            0.8571428571428571,
            '#ffff71',
        ],
        [
            0.8775510204081632,
            '#ffff84',
        ],
        [
            0.8979591836734693,
            '#ffff98',
        ],
        [
            0.9183673469387754,
            '#ffffb0',
        ],
        [
            0.9387755102040816,
            '#ffffc3',
        ],
        [
            0.9591836734693877,
            '#ffffd7',
        ],
        [
            0.9795918367346939,
            '#ffffeb',
        ],
        [
            1.0,
            '#ffffff',
        ],
    ],
    Hsv: [
        [
            0.0,
            '#ff0000',
        ],
        [
            0.15873,
            '#ffec00',
        ],
        [
            0.174603,
            '#f8fc00',
        ],
        [
            0.333333,
            '#07ff00',
        ],
        [
            0.349206,
            '#00ff0f',
        ],
        [
            0.507937,
            '#00fcff',
        ],
        [
            0.666667,
            '#000fff',
        ],
        [
            0.68254,
            '#0700ff',
        ],
        [
            0.84127,
            '#f700fd',
        ],
        [
            0.857143,
            '#ff00ec',
        ],
        [
            1.0,
            '#ff0017',
        ],
    ],
    Jet: [
        [
            0.0,
            '#00007f',
        ],
        [
            0.02040816326530612,
            '#000096',
        ],
        [
            0.04081632653061224,
            '#0000ac',
        ],
        [
            0.061224489795918366,
            '#0000c3',
        ],
        [
            0.08163265306122448,
            '#0000da',
        ],
        [
            0.1020408163265306,
            '#0000f5',
        ],
        [
            0.12244897959183673,
            '#0000ff',
        ],
        [
            0.14285714285714285,
            '#0010ff',
        ],
        [
            0.16326530612244897,
            '#0024ff',
        ],
        [
            0.18367346938775508,
            '#003cff',
        ],
        [
            0.2040816326530612,
            '#0050ff',
        ],
        [
            0.22448979591836732,
            '#0064ff',
        ],
        [
            0.24489795918367346,
            '#0078ff',
        ],
        [
            0.26530612244897955,
            '#008cff',
        ],
        [
            0.2857142857142857,
            '#00a4ff',
        ],
        [
            0.3061224489795918,
            '#00b8ff',
        ],
        [
            0.32653061224489793,
            '#00ccff',
        ],
        [
            0.3469387755102041,
            '#00e0fa',
        ],
        [
            0.36734693877551017,
            '#0ff8e7',
        ],
        [
            0.3877551020408163,
            '#1fffd7',
        ],
        [
            0.4081632653061224,
            '#2fffc7',
        ],
        [
            0.42857142857142855,
            '#3fffb7',
        ],
        [
            0.44897959183673464,
            '#4fffa6',
        ],
        [
            0.4693877551020408,
            '#63ff93',
        ],
        [
            0.4897959183673469,
            '#73ff83',
        ],
        [
            0.5102040816326531,
            '#83ff73',
        ],
        [
            0.5306122448979591,
            '#93ff63',
        ],
        [
            0.5510204081632653,
            '#a6ff4f',
        ],
        [
            0.5714285714285714,
            '#b7ff3f',
        ],
        [
            0.5918367346938775,
            '#c7ff2f',
        ],
        [
            0.6122448979591836,
            '#d7ff1f',
        ],
        [
            0.6326530612244897,
            '#e7ff0f',
        ],
        [
            0.6530612244897959,
            '#faf000',
        ],
        [
            0.673469387755102,
            '#ffde00',
        ],
        [
            0.6938775510204082,
            '#ffcb00',
        ],
        [
            0.7142857142857142,
            '#ffb900',
        ],
        [
            0.7346938775510203,
            '#ffa300',
        ],
        [
            0.7551020408163265,
            '#ff9000',
        ],
        [
            0.7755102040816326,
            '#ff7e00',
        ],
        [
            0.7959183673469387,
            '#ff6b00',
        ],
        [
            0.8163265306122448,
            '#ff5900',
        ],
        [
            0.836734693877551,
            '#ff4200',
        ],
        [
            0.8571428571428571,
            '#ff3000',
        ],
        [
            0.8775510204081632,
            '#ff1d00',
        ],
        [
            0.8979591836734693,
            '#f50b00',
        ],
        [
            0.9183673469387754,
            '#da0000',
        ],
        [
            0.9387755102040816,
            '#c30000',
        ],
        [
            0.9591836734693877,
            '#ac0000',
        ],
        [
            0.9795918367346939,
            '#960000',
        ],
        [
            1.0,
            '#7f0000',
        ],
    ],
    Nipy_spectral: [
        [
            0.0,
            '#000000',
        ],
        [
            0.05,
            '#70007f',
        ],
        [
            0.1,
            '#870098',
        ],
        [
            0.15,
            '#0200a9',
        ],
        [
            0.2,
            '#0000dd',
        ],
        [
            0.25,
            '#0077dd',
        ],
        [
            0.3,
            '#0097dd',
        ],
        [
            0.35,
            '#00a9ab',
        ],
        [
            0.4,
            '#00aa87',
        ],
        [
            0.45,
            '#009900',
        ],
        [
            0.5,
            '#00bc00',
        ],
        [
            0.55,
            '#00dc00',
        ],
        [
            0.6,
            '#00ff00',
        ],
        [
            0.65,
            '#bbfe00',
        ],
        [
            0.7,
            '#eeec00',
        ],
        [
            0.75,
            '#ffc900',
        ],
        [
            0.8,
            '#ff9900',
        ],
        [
            0.85,
            '#fe0000',
        ],
        [
            0.9,
            '#dc0000',
        ],
        [
            0.95,
            '#cc0c0c',
        ],
        [
            1.0,
            '#cccccc',
        ],
    ],
    Ocean: [
        [
            0.0,
            '#007f00',
        ],
        [
            0.02040816326530612,
            '#007805',
        ],
        [
            0.04081632653061224,
            '#00700a',
        ],
        [
            0.061224489795918366,
            '#00690f',
        ],
        [
            0.08163265306122448,
            '#006114',
        ],
        [
            0.1020408163265306,
            '#00581a',
        ],
        [
            0.12244897959183673,
            '#00511f',
        ],
        [
            0.14285714285714285,
            '#004924',
        ],
        [
            0.16326530612244897,
            '#004228',
        ],
        [
            0.18367346938775508,
            '#00382f',
        ],
        [
            0.2040816326530612,
            '#003134',
        ],
        [
            0.22448979591836732,
            '#002a38',
        ],
        [
            0.24489795918367346,
            '#00223e',
        ],
        [
            0.26530612244897955,
            '#001a43',
        ],
        [
            0.2857142857142857,
            '#001249',
        ],
        [
            0.3061224489795918,
            '#000a4e',
        ],
        [
            0.32653061224489793,
            '#000253',
        ],
        [
            0.3469387755102041,
            '#000458',
        ],
        [
            0.36734693877551017,
            '#000d5e',
        ],
        [
            0.3877551020408163,
            '#001563',
        ],
        [
            0.4081632653061224,
            '#001c68',
        ],
        [
            0.42857142857142855,
            '#00236d',
        ],
        [
            0.44897959183673464,
            '#002b71',
        ],
        [
            0.4693877551020408,
            '#003478',
        ],
        [
            0.4897959183673469,
            '#003b7d',
        ],
        [
            0.5102040816326531,
            '#004382',
        ],
        [
            0.5306122448979591,
            '#004b87',
        ],
        [
            0.5510204081632653,
            '#00548d',
        ],
        [
            0.5714285714285714,
            '#005b92',
        ],
        [
            0.5918367346938775,
            '#006297',
        ],
        [
            0.6122448979591836,
            '#006a9c',
        ],
        [
            0.6326530612244897,
            '#0071a1',
        ],
        [
            0.6530612244897959,
            '#007ba7',
        ],
        [
            0.673469387755102,
            '#0582ac',
        ],
        [
            0.6938775510204082,
            '#1489b1',
        ],
        [
            0.7142857142857142,
            '#2391b6',
        ],
        [
            0.7346938775510203,
            '#369abc',
        ],
        [
            0.7551020408163265,
            '#44a2c1',
        ],
        [
            0.7755102040816326,
            '#54a9c6',
        ],
        [
            0.7959183673469387,
            '#62b1cb',
        ],
        [
            0.8163265306122448,
            '#72b8d0',
        ],
        [
            0.836734693877551,
            '#83c1d6',
        ],
        [
            0.8571428571428571,
            '#92c8db',
        ],
        [
            0.8775510204081632,
            '#a2d0e0',
        ],
        [
            0.8979591836734693,
            '#b1d8e5',
        ],
        [
            0.9183673469387754,
            '#c3e1eb',
        ],
        [
            0.9387755102040816,
            '#d1e8f0',
        ],
        [
            0.9591836734693877,
            '#e1f0f5',
        ],
        [
            0.9795918367346939,
            '#eff7fa',
        ],
        [
            1.0,
            '#ffffff',
        ],
    ],
    Oranges: [
        [
            0.0,
            '#fff5eb',
        ],
        [
            0.02040816326530612,
            '#fef2e6',
        ],
        [
            0.04081632653061224,
            '#fef0e1',
        ],
        [
            0.061224489795918366,
            '#feeddd',
        ],
        [
            0.08163265306122448,
            '#feebd8',
        ],
        [
            0.1020408163265306,
            '#fee8d3',
        ],
        [
            0.12244897959183673,
            '#fee6ce',
        ],
        [
            0.14285714285714285,
            '#fde3c8',
        ],
        [
            0.16326530612244897,
            '#fddfc1',
        ],
        [
            0.18367346938775508,
            '#fddbb9',
        ],
        [
            0.2040816326530612,
            '#fdd8b2',
        ],
        [
            0.22448979591836732,
            '#fdd4ab',
        ],
        [
            0.24489795918367346,
            '#fdd1a4',
        ],
        [
            0.26530612244897955,
            '#fdcc9c',
        ],
        [
            0.2857142857142857,
            '#fdc692',
        ],
        [
            0.3061224489795918,
            '#fdc089',
        ],
        [
            0.32653061224489793,
            '#fdbb80',
        ],
        [
            0.3469387755102041,
            '#fdb678',
        ],
        [
            0.36734693877551017,
            '#fdaf6d',
        ],
        [
            0.3877551020408163,
            '#fdaa66',
        ],
        [
            0.4081632653061224,
            '#fda55e',
        ],
        [
            0.42857142857142855,
            '#fda057',
        ],
        [
            0.44897959183673464,
            '#fd9a4f',
        ],
        [
            0.4693877551020408,
            '#fd9447',
        ],
        [
            0.4897959183673469,
            '#fd8f3f',
        ],
        [
            0.5102040816326531,
            '#fc8a38',
        ],
        [
            0.5306122448979591,
            '#fa8432',
        ],
        [
            0.5510204081632653,
            '#f77d2a',
        ],
        [
            0.5714285714285714,
            '#f67824',
        ],
        [
            0.5918367346938775,
            '#f4721d',
        ],
        [
            0.6122448979591836,
            '#f26c17',
        ],
        [
            0.6326530612244897,
            '#ef6712',
        ],
        [
            0.6530612244897959,
            '#eb610e',
        ],
        [
            0.673469387755102,
            '#e75b0b',
        ],
        [
            0.6938775510204082,
            '#e35609',
        ],
        [
            0.7142857142857142,
            '#df5106',
        ],
        [
            0.7346938775510203,
            '#db4b02',
        ],
        [
            0.7551020408163265,
            '#d64701',
        ],
        [
            0.7755102040816326,
            '#ce4401',
        ],
        [
            0.7959183673469387,
            '#c64101',
        ],
        [
            0.8163265306122448,
            '#be3e02',
        ],
        [
            0.836734693877551,
            '#b43b02',
        ],
        [
            0.8571428571428571,
            '#ac3802',
        ],
        [
            0.8775510204081632,
            '#a43503',
        ],
        [
            0.8979591836734693,
            '#9e3303',
        ],
        [
            0.9183673469387754,
            '#973003',
        ],
        [
            0.9387755102040816,
            '#912e03',
        ],
        [
            0.9591836734693877,
            '#8b2b03',
        ],
        [
            0.9795918367346939,
            '#852903',
        ],
        [
            1.0,
            '#7f2704',
        ],
    ],
    OrRd: [
        [
            0.0,
            '#fff7ec',
        ],
        [
            0.02040816326530612,
            '#fef4e6',
        ],
        [
            0.04081632653061224,
            '#fef2e0',
        ],
        [
            0.061224489795918366,
            '#feefdb',
        ],
        [
            0.08163265306122448,
            '#feedd5',
        ],
        [
            0.1020408163265306,
            '#feeace',
        ],
        [
            0.12244897959183673,
            '#fee8c8',
        ],
        [
            0.14285714285714285,
            '#fde5c2',
        ],
        [
            0.16326530612244897,
            '#fde2bb',
        ],
        [
            0.18367346938775508,
            '#fddeb4',
        ],
        [
            0.2040816326530612,
            '#fddbad',
        ],
        [
            0.22448979591836732,
            '#fdd8a6',
        ],
        [
            0.24489795918367346,
            '#fdd5a0',
        ],
        [
            0.26530612244897955,
            '#fdd19b',
        ],
        [
            0.2857142857142857,
            '#fdcc96',
        ],
        [
            0.3061224489795918,
            '#fdc892',
        ],
        [
            0.32653061224489793,
            '#fdc48e',
        ],
        [
            0.3469387755102041,
            '#fdc08a',
        ],
        [
            0.36734693877551017,
            '#fdbc85',
        ],
        [
            0.3877551020408163,
            '#fcb67f',
        ],
        [
            0.4081632653061224,
            '#fcae78',
        ],
        [
            0.42857142857142855,
            '#fca771',
        ],
        [
            0.44897959183673464,
            '#fca06b',
        ],
        [
            0.4693877551020408,
            '#fc9763',
        ],
        [
            0.4897959183673469,
            '#fc905c',
        ],
        [
            0.5102040816326531,
            '#fa8957',
        ],
        [
            0.5306122448979591,
            '#f88355',
        ],
        [
            0.5510204081632653,
            '#f67c51',
        ],
        [
            0.5714285714285714,
            '#f4754f',
        ],
        [
            0.5918367346938775,
            '#f26f4c',
        ],
        [
            0.6122448979591836,
            '#f06949',
        ],
        [
            0.6326530612244897,
            '#ed6245',
        ],
        [
            0.6530612244897959,
            '#e9583e',
        ],
        [
            0.673469387755102,
            '#e55037',
        ],
        [
            0.6938775510204082,
            '#e14731',
        ],
        [
            0.7142857142857142,
            '#dd3f2a',
        ],
        [
            0.7346938775510203,
            '#d93523',
        ],
        [
            0.7551020408163265,
            '#d52d1d',
        ],
        [
            0.7755102040816326,
            '#cf2518',
        ],
        [
            0.7959183673469387,
            '#c91e13',
        ],
        [
            0.8163265306122448,
            '#c4160e',
        ],
        [
            0.836734693877551,
            '#bd0d08',
        ],
        [
            0.8571428571428571,
            '#b70604',
        ],
        [
            0.8775510204081632,
            '#b10000',
        ],
        [
            0.8979591836734693,
            '#a90000',
        ],
        [
            0.9183673469387754,
            '#9f0000',
        ],
        [
            0.9387755102040816,
            '#970000',
        ],
        [
            0.9591836734693877,
            '#8f0000',
        ],
        [
            0.9795918367346939,
            '#870000',
        ],
        [
            1.0,
            '#7f0000',
        ],
    ],
    Pink: [
        [
            0.0,
            '#1e0000',
        ],
        [
            0.015873,
            '#311919',
        ],
        [
            0.031746,
            '#3f2424',
        ],
        [
            0.047619,
            '#4a2d2d',
        ],
        [
            0.063492,
            '#543434',
        ],
        [
            0.079365,
            '#5d3a3a',
        ],
        [
            0.095238,
            '#653f3f',
        ],
        [
            0.111111,
            '#6d4444',
        ],
        [
            0.126984,
            '#744949',
        ],
        [
            0.142857,
            '#7a4e4e',
        ],
        [
            0.15873,
            '#805252',
        ],
        [
            0.174603,
            '#865656',
        ],
        [
            0.190476,
            '#8c5a5a',
        ],
        [
            0.206349,
            '#925e5e',
        ],
        [
            0.222222,
            '#976161',
        ],
        [
            0.238095,
            '#9c6464',
        ],
        [
            0.253968,
            '#a26969',
        ],
        [
            0.269841,
            '#a76c6c',
        ],
        [
            0.285714,
            '#ac6f6f',
        ],
        [
            0.301587,
            '#b07272',
        ],
        [
            0.31746,
            '#b47575',
        ],
        [
            0.333333,
            '#b97878',
        ],
        [
            0.349206,
            '#bd7b7b',
        ],
        [
            0.365079,
            '#c17d7d',
        ],
        [
            0.380952,
            '#c38380',
        ],
        [
            0.396825,
            '#c58983',
        ],
        [
            0.412698,
            '#c68f85',
        ],
        [
            0.428571,
            '#c89488',
        ],
        [
            0.444444,
            '#ca998a',
        ],
        [
            0.460317,
            '#cb9e8d',
        ],
        [
            0.47619,
            '#cda38f',
        ],
        [
            0.492063,
            '#cfa891',
        ],
        [
            0.507937,
            '#d1ae94',
        ],
        [
            0.52381,
            '#d2b296',
        ],
        [
            0.539683,
            '#d4b699',
        ],
        [
            0.555556,
            '#d6bb9b',
        ],
        [
            0.571429,
            '#d7bf9d',
        ],
        [
            0.587302,
            '#d9c39f',
        ],
        [
            0.603175,
            '#dac7a1',
        ],
        [
            0.619048,
            '#dccba3',
        ],
        [
            0.634921,
            '#ddcfa5',
        ],
        [
            0.650794,
            '#dfd2a7',
        ],
        [
            0.666667,
            '#e0d6aa',
        ],
        [
            0.68254,
            '#e2daab',
        ],
        [
            0.698413,
            '#e3ddad',
        ],
        [
            0.714286,
            '#e5e1af',
        ],
        [
            0.730159,
            '#e6e4b1',
        ],
        [
            0.746032,
            '#e8e8b3',
        ],
        [
            0.761905,
            '#eaeaba',
        ],
        [
            0.777778,
            '#ebebbf',
        ],
        [
            0.793651,
            '#ededc4',
        ],
        [
            0.809524,
            '#eeeec9',
        ],
        [
            0.825397,
            '#efefce',
        ],
        [
            0.84127,
            '#f1f1d3',
        ],
        [
            0.857143,
            '#f2f2d8',
        ],
        [
            0.873016,
            '#f4f4dd',
        ],
        [
            0.888889,
            '#f5f5e1',
        ],
        [
            0.904762,
            '#f6f6e6',
        ],
        [
            0.920635,
            '#f8f8ea',
        ],
        [
            0.936508,
            '#f9f9ee',
        ],
        [
            0.952381,
            '#fafaf2',
        ],
        [
            0.968254,
            '#fcfcf6',
        ],
        [
            0.984127,
            '#fdfdfb',
        ],
        [
            1.0,
            '#ffffff',
        ],
    ],
    PiYG: [
        [
            0.0,
            '#8e0152',
        ],
        [
            0.1,
            '#c31a7c',
        ],
        [
            0.2,
            '#de77ae',
        ],
        [
            0.30000000000000004,
            '#f0b4d9',
        ],
        [
            0.4,
            '#fde0ef',
        ],
        [
            0.5,
            '#f6f6f6',
        ],
        [
            0.6000000000000001,
            '#e6f5d0',
        ],
        [
            0.7000000000000001,
            '#b6e084',
        ],
        [
            0.8,
            '#7fbc41',
        ],
        [
            0.9,
            '#4c9120',
        ],
        [
            1.0,
            '#276419',
        ],
    ],
    PRGn: [
        [
            0.0,
            '#40004b',
        ],
        [
            0.1,
            '#742981',
        ],
        [
            0.2,
            '#9970ab',
        ],
        [
            0.30000000000000004,
            '#c1a3ce',
        ],
        [
            0.4,
            '#e7d4e8',
        ],
        [
            0.5,
            '#f6f6f6',
        ],
        [
            0.6000000000000001,
            '#d9f0d3',
        ],
        [
            0.7000000000000001,
            '#a4da9e',
        ],
        [
            0.8,
            '#5aae61',
        ],
        [
            0.9,
            '#1a7636',
        ],
        [
            1.0,
            '#00441b',
        ],
    ],
    PuBu: [
        [
            0.0,
            '#fff7fb',
        ],
        [
            0.02040816326530612,
            '#fcf4f9',
        ],
        [
            0.04081632653061224,
            '#f9f1f8',
        ],
        [
            0.061224489795918366,
            '#f6eff6',
        ],
        [
            0.08163265306122448,
            '#f3ecf5',
        ],
        [
            0.1020408163265306,
            '#efe9f3',
        ],
        [
            0.12244897959183673,
            '#ece7f2',
        ],
        [
            0.14285714285714285,
            '#e8e4f0',
        ],
        [
            0.16326530612244897,
            '#e3e0ee',
        ],
        [
            0.18367346938775508,
            '#dedcec',
        ],
        [
            0.2040816326530612,
            '#dad9ea',
        ],
        [
            0.22448979591836732,
            '#d5d5e8',
        ],
        [
            0.24489795918367346,
            '#d1d2e6',
        ],
        [
            0.26530612244897955,
            '#cbcee4',
        ],
        [
            0.2857142857142857,
            '#c3cbe2',
        ],
        [
            0.3061224489795918,
            '#bdc8e1',
        ],
        [
            0.32653061224489793,
            '#b6c4df',
        ],
        [
            0.3469387755102041,
            '#b0c1dd',
        ],
        [
            0.36734693877551017,
            '#a8bedb',
        ],
        [
            0.3877551020408163,
            '#a0bad9',
        ],
        [
            0.4081632653061224,
            '#98b7d7',
        ],
        [
            0.42857142857142855,
            '#91b4d5',
        ],
        [
            0.44897959183673464,
            '#89b1d4',
        ],
        [
            0.4693877551020408,
            '#7fadd1',
        ],
        [
            0.4897959183673469,
            '#77aacf',
        ],
        [
            0.5102040816326531,
            '#6fa7cd',
        ],
        [
            0.5306122448979591,
            '#65a3cb',
        ],
        [
            0.5510204081632653,
            '#599ec8',
        ],
        [
            0.5714285714285714,
            '#509ac6',
        ],
        [
            0.5918367346938775,
            '#4696c3',
        ],
        [
            0.6122448979591836,
            '#3c92c1',
        ],
        [
            0.6326530612244897,
            '#338ebf',
        ],
        [
            0.6530612244897959,
            '#2a88bc',
        ],
        [
            0.673469387755102,
            '#2283b9',
        ],
        [
            0.6938775510204082,
            '#1a7eb7',
        ],
        [
            0.7142857142857142,
            '#1379b4',
        ],
        [
            0.7346938775510203,
            '#0973b1',
        ],
        [
            0.7551020408163265,
            '#046eae',
        ],
        [
            0.7755102040816326,
            '#046ba8',
        ],
        [
            0.7959183673469387,
            '#0467a3',
        ],
        [
            0.8163265306122448,
            '#04649d',
        ],
        [
            0.836734693877551,
            '#046097',
        ],
        [
            0.8571428571428571,
            '#045c91',
        ],
        [
            0.8775510204081632,
            '#03598b',
        ],
        [
            0.8979591836734693,
            '#035383',
        ],
        [
            0.9183673469387754,
            '#034d79',
        ],
        [
            0.9387755102040816,
            '#024870',
        ],
        [
            0.9591836734693877,
            '#024268',
        ],
        [
            0.9795918367346939,
            '#023d60',
        ],
        [
            1.0,
            '#023858',
        ],
    ],
    PuBuGn: [
        [
            0.0,
            '#fff7fb',
        ],
        [
            0.02040816326530612,
            '#fcf3f9',
        ],
        [
            0.04081632653061224,
            '#f9f0f7',
        ],
        [
            0.061224489795918366,
            '#f6edf5',
        ],
        [
            0.08163265306122448,
            '#f3e9f4',
        ],
        [
            0.1020408163265306,
            '#efe5f2',
        ],
        [
            0.12244897959183673,
            '#ece2f0',
        ],
        [
            0.14285714285714285,
            '#e8dfee',
        ],
        [
            0.16326530612244897,
            '#e3dded',
        ],
        [
            0.18367346938775508,
            '#ded9eb',
        ],
        [
            0.2040816326530612,
            '#dad7e9',
        ],
        [
            0.22448979591836732,
            '#d5d4e8',
        ],
        [
            0.24489795918367346,
            '#d1d1e6',
        ],
        [
            0.26530612244897955,
            '#cbcee4',
        ],
        [
            0.2857142857142857,
            '#c3cbe2',
        ],
        [
            0.3061224489795918,
            '#bdc8e1',
        ],
        [
            0.32653061224489793,
            '#b6c4df',
        ],
        [
            0.3469387755102041,
            '#b0c1dd',
        ],
        [
            0.36734693877551017,
            '#a8bedb',
        ],
        [
            0.3877551020408163,
            '#9fbad9',
        ],
        [
            0.4081632653061224,
            '#95b7d7',
        ],
        [
            0.42857142857142855,
            '#8bb4d5',
        ],
        [
            0.44897959183673464,
            '#81b1d4',
        ],
        [
            0.4693877551020408,
            '#75add1',
        ],
        [
            0.4897959183673469,
            '#6baacf',
        ],
        [
            0.5102040816326531,
            '#63a7cd',
        ],
        [
            0.5306122448979591,
            '#5ba3cb',
        ],
        [
            0.5510204081632653,
            '#529ec8',
        ],
        [
            0.5714285714285714,
            '#4a9ac6',
        ],
        [
            0.5918367346938775,
            '#4296c3',
        ],
        [
            0.6122448979591836,
            '#3b92c1',
        ],
        [
            0.6326530612244897,
            '#338fbd',
        ],
        [
            0.6530612244897959,
            '#298cb3',
        ],
        [
            0.673469387755102,
            '#218aaa',
        ],
        [
            0.6938775510204082,
            '#1987a2',
        ],
        [
            0.7142857142857142,
            '#118599',
        ],
        [
            0.7346938775510203,
            '#07828f',
        ],
        [
            0.7551020408163265,
            '#017f87',
        ],
        [
            0.7755102040816326,
            '#017c7f',
        ],
        [
            0.7959183673469387,
            '#017977',
        ],
        [
            0.8163265306122448,
            '#017570',
        ],
        [
            0.836734693877551,
            '#017267',
        ],
        [
            0.8571428571428571,
            '#016e5f',
        ],
        [
            0.8775510204081632,
            '#016a58',
        ],
        [
            0.8979591836734693,
            '#016452',
        ],
        [
            0.9183673469387754,
            '#015d4b',
        ],
        [
            0.9387755102040816,
            '#015746',
        ],
        [
            0.9591836734693877,
            '#015140',
        ],
        [
            0.9795918367346939,
            '#014b3b',
        ],
        [
            1.0,
            '#014636',
        ],
    ],
    PuOr: [
        [
            0.0,
            '#7f3b08',
        ],
        [
            0.1,
            '#b15706',
        ],
        [
            0.2,
            '#e08214',
        ],
        [
            0.30000000000000004,
            '#fcb661',
        ],
        [
            0.4,
            '#fee0b6',
        ],
        [
            0.5,
            '#f6f6f6',
        ],
        [
            0.6000000000000001,
            '#d8daeb',
        ],
        [
            0.7000000000000001,
            '#b1a9d1',
        ],
        [
            0.8,
            '#8073ac',
        ],
        [
            0.9,
            '#532686',
        ],
        [
            1.0,
            '#2d004b',
        ],
    ],
    PuRd: [
        [
            0.0,
            '#f7f4f9',
        ],
        [
            0.02040816326530612,
            '#f4f1f7',
        ],
        [
            0.04081632653061224,
            '#f1eef5',
        ],
        [
            0.061224489795918366,
            '#efebf4',
        ],
        [
            0.08163265306122448,
            '#ece8f2',
        ],
        [
            0.1020408163265306,
            '#e9e4f0',
        ],
        [
            0.12244897959183673,
            '#e7e1ef',
        ],
        [
            0.14285714285714285,
            '#e4dbec',
        ],
        [
            0.16326530612244897,
            '#e1d5e8',
        ],
        [
            0.18367346938775508,
            '#ddcee5',
        ],
        [
            0.2040816326530612,
            '#dbc7e1',
        ],
        [
            0.22448979591836732,
            '#d8c1de',
        ],
        [
            0.24489795918367346,
            '#d5bbdb',
        ],
        [
            0.26530612244897955,
            '#d2b5d8',
        ],
        [
            0.2857142857142857,
            '#d0aed4',
        ],
        [
            0.3061224489795918,
            '#cfa8d1',
        ],
        [
            0.32653061224489793,
            '#cda2ce',
        ],
        [
            0.3469387755102041,
            '#cb9ccb',
        ],
        [
            0.36734693877551017,
            '#c995c7',
        ],
        [
            0.3877551020408163,
            '#cb8fc4',
        ],
        [
            0.4081632653061224,
            '#ce87c0',
        ],
        [
            0.42857142857142855,
            '#d280bd',
        ],
        [
            0.44897959183673464,
            '#d578b9',
        ],
        [
            0.4693877551020408,
            '#d970b5',
        ],
        [
            0.4897959183673469,
            '#dd68b1',
        ],
        [
            0.5102040816326531,
            '#df60ad',
        ],
        [
            0.5306122448979591,
            '#e056a7',
        ],
        [
            0.5510204081632653,
            '#e24b9f',
        ],
        [
            0.5714285714285714,
            '#e34299',
        ],
        [
            0.5918367346938775,
            '#e43893',
        ],
        [
            0.6122448979591836,
            '#e62f8e',
        ],
        [
            0.6326530612244897,
            '#e52787',
        ],
        [
            0.6530612244897959,
            '#e1237d',
        ],
        [
            0.673469387755102,
            '#dd1f75',
        ],
        [
            0.6938775510204082,
            '#d91c6d',
        ],
        [
            0.7142857142857142,
            '#d51865',
        ],
        [
            0.7346938775510203,
            '#d0145b',
        ],
        [
            0.7551020408163265,
            '#cb1154',
        ],
        [
            0.7755102040816326,
            '#c20e51',
        ],
        [
            0.7959183673469387,
            '#ba0b4e',
        ],
        [
            0.8163265306122448,
            '#b1084c',
        ],
        [
            0.836734693877551,
            '#a70548',
        ],
        [
            0.8571428571428571,
            '#9e0245',
        ],
        [
            0.8775510204081632,
            '#960042',
        ],
        [
            0.8979591836734693,
            '#8e003c',
        ],
        [
            0.9183673469387754,
            '#850035',
        ],
        [
            0.9387755102040816,
            '#7e002f',
        ],
        [
            0.9591836734693877,
            '#76002a',
        ],
        [
            0.9795918367346939,
            '#6e0024',
        ],
        [
            1.0,
            '#67001f',
        ],
    ],
    Purples: [
        [
            0.0,
            '#fcfbfd',
        ],
        [
            0.02040816326530612,
            '#f9f8fb',
        ],
        [
            0.04081632653061224,
            '#f7f6fa',
        ],
        [
            0.061224489795918366,
            '#f5f4f9',
        ],
        [
            0.08163265306122448,
            '#f3f2f7',
        ],
        [
            0.1020408163265306,
            '#f1eff6',
        ],
        [
            0.12244897959183673,
            '#efedf5',
        ],
        [
            0.14285714285714285,
            '#eceaf3',
        ],
        [
            0.16326530612244897,
            '#e8e7f2',
        ],
        [
            0.18367346938775508,
            '#e5e3f0',
        ],
        [
            0.2040816326530612,
            '#e1e1ee',
        ],
        [
            0.22448979591836732,
            '#dedeed',
        ],
        [
            0.24489795918367346,
            '#dbdbeb',
        ],
        [
            0.26530612244897955,
            '#d6d7e9',
        ],
        [
            0.2857142857142857,
            '#d1d1e6',
        ],
        [
            0.3061224489795918,
            '#cccde4',
        ],
        [
            0.32653061224489793,
            '#c7c8e1',
        ],
        [
            0.3469387755102041,
            '#c3c3df',
        ],
        [
            0.36734693877551017,
            '#bdbedc',
        ],
        [
            0.3877551020408163,
            '#b8b9d9',
        ],
        [
            0.4081632653061224,
            '#b4b3d6',
        ],
        [
            0.42857142857142855,
            '#afaed3',
        ],
        [
            0.44897959183673464,
            '#aaa8d0',
        ],
        [
            0.4693877551020408,
            '#a5a2cc',
        ],
        [
            0.4897959183673469,
            '#a09cc9',
        ],
        [
            0.5102040816326531,
            '#9b97c6',
        ],
        [
            0.5306122448979591,
            '#9693c4',
        ],
        [
            0.5510204081632653,
            '#918dc2',
        ],
        [
            0.5714285714285714,
            '#8c89bf',
        ],
        [
            0.5918367346938775,
            '#8784bd',
        ],
        [
            0.6122448979591836,
            '#8380bb',
        ],
        [
            0.6326530612244897,
            '#7e7ab8',
        ],
        [
            0.6530612244897959,
            '#7a72b4',
        ],
        [
            0.673469387755102,
            '#776bb0',
        ],
        [
            0.6938775510204082,
            '#7364ad',
        ],
        [
            0.7142857142857142,
            '#705da9',
        ],
        [
            0.7346938775510203,
            '#6c55a5',
        ],
        [
            0.7551020408163265,
            '#684ea1',
        ],
        [
            0.7755102040816326,
            '#65489e',
        ],
        [
            0.7959183673469387,
            '#61419b',
        ],
        [
            0.8163265306122448,
            '#5e3a98',
        ],
        [
            0.836734693877551,
            '#5a3394',
        ],
        [
            0.8571428571428571,
            '#562c91',
        ],
        [
            0.8775510204081632,
            '#53258e',
        ],
        [
            0.8979591836734693,
            '#501f8b',
        ],
        [
            0.9183673469387754,
            '#4c1888',
        ],
        [
            0.9387755102040816,
            '#481285',
        ],
        [
            0.9591836734693877,
            '#450c82',
        ],
        [
            0.9795918367346939,
            '#42067f',
        ],
        [
            1.0,
            '#3f007d',
        ],
    ],
    Rainbow: [
        [
            0.0,
            '#7f00ff',
        ],
        [
            0.02040816326530612,
            '#750ffe',
        ],
        [
            0.04081632653061224,
            '#6b1ffe',
        ],
        [
            0.061224489795918366,
            '#612efd',
        ],
        [
            0.08163265306122448,
            '#573efd',
        ],
        [
            0.1020408163265306,
            '#4b50fb',
        ],
        [
            0.12244897959183673,
            '#415ffa',
        ],
        [
            0.14285714285714285,
            '#376df8',
        ],
        [
            0.16326530612244897,
            '#2d7bf6',
        ],
        [
            0.18367346938775508,
            '#218bf4',
        ],
        [
            0.2040816326530612,
            '#1798f2',
        ],
        [
            0.22448979591836732,
            '#0da4ef',
        ],
        [
            0.24489795918367346,
            '#03b0ec',
        ],
        [
            0.26530612244897955,
            '#06bbe9',
        ],
        [
            0.2857142857142857,
            '#12c7e5',
        ],
        [
            0.3061224489795918,
            '#1cd1e2',
        ],
        [
            0.32653061224489793,
            '#26d9de',
        ],
        [
            0.3469387755102041,
            '#30e1da',
        ],
        [
            0.36734693877551017,
            '#3ce9d5',
        ],
        [
            0.3877551020408163,
            '#46efd1',
        ],
        [
            0.4081632653061224,
            '#50f4cc',
        ],
        [
            0.42857142857142855,
            '#5af8c7',
        ],
        [
            0.44897959183673464,
            '#64fbc2',
        ],
        [
            0.4693877551020408,
            '#70fdbc',
        ],
        [
            0.4897959183673469,
            '#7afeb7',
        ],
        [
            0.5102040816326531,
            '#84feb1',
        ],
        [
            0.5306122448979591,
            '#8efdab',
        ],
        [
            0.5510204081632653,
            '#9afba4',
        ],
        [
            0.5714285714285714,
            '#a4f89e',
        ],
        [
            0.5918367346938775,
            '#aef498',
        ],
        [
            0.6122448979591836,
            '#b8ef92',
        ],
        [
            0.6326530612244897,
            '#c2e98b',
        ],
        [
            0.6530612244897959,
            '#cee183',
        ],
        [
            0.673469387755102,
            '#d8d97c',
        ],
        [
            0.6938775510204082,
            '#e2d175',
        ],
        [
            0.7142857142857142,
            '#ecc76e',
        ],
        [
            0.7346938775510203,
            '#f8bb66',
        ],
        [
            0.7551020408163265,
            '#ffb05f',
        ],
        [
            0.7755102040816326,
            '#ffa457',
        ],
        [
            0.7959183673469387,
            '#ff9850',
        ],
        [
            0.8163265306122448,
            '#ff8b48',
        ],
        [
            0.836734693877551,
            '#ff7b3f',
        ],
        [
            0.8571428571428571,
            '#ff6d38',
        ],
        [
            0.8775510204081632,
            '#ff5f30',
        ],
        [
            0.8979591836734693,
            '#ff5028',
        ],
        [
            0.9183673469387754,
            '#ff3e1f',
        ],
        [
            0.9387755102040816,
            '#ff2e17',
        ],
        [
            0.9591836734693877,
            '#ff1f0f',
        ],
        [
            0.9795918367346939,
            '#ff0f07',
        ],
        [
            1.0,
            '#ff0000',
        ],
    ],
    RdBu: [
        [
            0.0,
            '#67001f',
        ],
        [
            0.1,
            '#b0172a',
        ],
        [
            0.2,
            '#d6604d',
        ],
        [
            0.30000000000000004,
            '#f3a380',
        ],
        [
            0.4,
            '#fddbc7',
        ],
        [
            0.5,
            '#f6f6f6',
        ],
        [
            0.6000000000000001,
            '#d1e5f0',
        ],
        [
            0.7000000000000001,
            '#90c4dd',
        ],
        [
            0.8,
            '#4393c3',
        ],
        [
            0.9,
            '#2064aa',
        ],
        [
            1.0,
            '#053061',
        ],
    ],
    RdGy: [
        [
            0.0,
            '#67001f',
        ],
        [
            0.1,
            '#b0172a',
        ],
        [
            0.2,
            '#d6604d',
        ],
        [
            0.30000000000000004,
            '#f3a380',
        ],
        [
            0.4,
            '#fddbc7',
        ],
        [
            0.5,
            '#fefefe',
        ],
        [
            0.6000000000000001,
            '#e0e0e0',
        ],
        [
            0.7000000000000001,
            '#b9b9b9',
        ],
        [
            0.8,
            '#878787',
        ],
        [
            0.9,
            '#4c4c4c',
        ],
        [
            1.0,
            '#1a1a1a',
        ],
    ],
    RdPu: [
        [
            0.0,
            '#fff7f3',
        ],
        [
            0.02040816326530612,
            '#fef3ef',
        ],
        [
            0.04081632653061224,
            '#feefec',
        ],
        [
            0.061224489795918366,
            '#feece8',
        ],
        [
            0.08163265306122448,
            '#fde8e5',
        ],
        [
            0.1020408163265306,
            '#fde4e1',
        ],
        [
            0.12244897959183673,
            '#fde0dd',
        ],
        [
            0.14285714285714285,
            '#fcdcd9',
        ],
        [
            0.16326530612244897,
            '#fcd8d4',
        ],
        [
            0.18367346938775508,
            '#fcd3cf',
        ],
        [
            0.2040816326530612,
            '#fcceca',
        ],
        [
            0.22448979591836732,
            '#fccac6',
        ],
        [
            0.24489795918367346,
            '#fcc6c1',
        ],
        [
            0.26530612244897955,
            '#fbc1be',
        ],
        [
            0.2857142857142857,
            '#fbb9bc',
        ],
        [
            0.3061224489795918,
            '#fbb4bb',
        ],
        [
            0.32653061224489793,
            '#faaeb9',
        ],
        [
            0.3469387755102041,
            '#faa8b7',
        ],
        [
            0.36734693877551017,
            '#faa0b5',
        ],
        [
            0.3877551020408163,
            '#f999b2',
        ],
        [
            0.4081632653061224,
            '#f990af',
        ],
        [
            0.42857142857142855,
            '#f887ac',
        ],
        [
            0.44897959183673464,
            '#f87fa9',
        ],
        [
            0.4693877551020408,
            '#f774a5',
        ],
        [
            0.4897959183673469,
            '#f76ca2',
        ],
        [
            0.5102040816326531,
            '#f463a0',
        ],
        [
            0.5306122448979591,
            '#f05b9e',
        ],
        [
            0.5510204081632653,
            '#eb519c',
        ],
        [
            0.5714285714285714,
            '#e7499b',
        ],
        [
            0.5918367346938775,
            '#e34199',
        ],
        [
            0.6122448979591836,
            '#df3998',
        ],
        [
            0.6326530612244897,
            '#da3195',
        ],
        [
            0.6530612244897959,
            '#d12791',
        ],
        [
            0.673469387755102,
            '#ca1f8d',
        ],
        [
            0.6938775510204082,
            '#c31789',
        ],
        [
            0.7142857142857142,
            '#bb0f85',
        ],
        [
            0.7346938775510203,
            '#b20680',
        ],
        [
            0.7551020408163265,
            '#ab017d',
        ],
        [
            0.7755102040816326,
            '#a2017c',
        ],
        [
            0.7959183673469387,
            '#9a017b',
        ],
        [
            0.8163265306122448,
            '#92017a',
        ],
        [
            0.836734693877551,
            '#880179',
        ],
        [
            0.8571428571428571,
            '#800177',
        ],
        [
            0.8775510204081632,
            '#780076',
        ],
        [
            0.8979591836734693,
            '#700074',
        ],
        [
            0.9183673469387754,
            '#670072',
        ],
        [
            0.9387755102040816,
            '#600070',
        ],
        [
            0.9591836734693877,
            '#58006e',
        ],
        [
            0.9795918367346939,
            '#50006c',
        ],
        [
            1.0,
            '#49006a',
        ],
    ],
    RdYlBu: [
        [
            0.0,
            '#a50026',
        ],
        [
            0.1,
            '#d62f26',
        ],
        [
            0.2,
            '#f46d43',
        ],
        [
            0.30000000000000004,
            '#fcac60',
        ],
        [
            0.4,
            '#fee090',
        ],
        [
            0.5,
            '#fefec0',
        ],
        [
            0.6000000000000001,
            '#e0f3f7',
        ],
        [
            0.7000000000000001,
            '#a9d8e8',
        ],
        [
            0.8,
            '#74add1',
        ],
        [
            0.9,
            '#4473b3',
        ],
        [
            1.0,
            '#313695',
        ],
    ],
    RdYlGn: [
        [
            0.0,
            '#a50026',
        ],
        [
            0.1,
            '#d62f26',
        ],
        [
            0.2,
            '#f46d43',
        ],
        [
            0.30000000000000004,
            '#fcac60',
        ],
        [
            0.4,
            '#fee08b',
        ],
        [
            0.5,
            '#fefebd',
        ],
        [
            0.6000000000000001,
            '#d9ef8b',
        ],
        [
            0.7000000000000001,
            '#a4d869',
        ],
        [
            0.8,
            '#66bd63',
        ],
        [
            0.9,
            '#19974f',
        ],
        [
            1.0,
            '#006837',
        ],
    ],
    Reds: [
        [
            0.0,
            '#fff5f0',
        ],
        [
            0.02040816326530612,
            '#fef1eb',
        ],
        [
            0.04081632653061224,
            '#feeee6',
        ],
        [
            0.061224489795918366,
            '#feebe1',
        ],
        [
            0.08163265306122448,
            '#fee7dd',
        ],
        [
            0.1020408163265306,
            '#fee3d7',
        ],
        [
            0.12244897959183673,
            '#fee0d2',
        ],
        [
            0.14285714285714285,
            '#fddbcb',
        ],
        [
            0.16326530612244897,
            '#fdd5c3',
        ],
        [
            0.18367346938775508,
            '#fdceba',
        ],
        [
            0.2040816326530612,
            '#fcc8b3',
        ],
        [
            0.22448979591836732,
            '#fcc2ab',
        ],
        [
            0.24489795918367346,
            '#fcbda3',
        ],
        [
            0.26530612244897955,
            '#fcb69c',
        ],
        [
            0.2857142857142857,
            '#fcaf93',
        ],
        [
            0.3061224489795918,
            '#fca88b',
        ],
        [
            0.32653061224489793,
            '#fca284',
        ],
        [
            0.3469387755102041,
            '#fc9b7d',
        ],
        [
            0.36734693877551017,
            '#fc9474',
        ],
        [
            0.3877551020408163,
            '#fb8d6d',
        ],
        [
            0.4081632653061224,
            '#fb8767',
        ],
        [
            0.42857142857142855,
            '#fb8161',
        ],
        [
            0.44897959183673464,
            '#fb7a5a',
        ],
        [
            0.4693877551020408,
            '#fb7353',
        ],
        [
            0.4897959183673469,
            '#fb6d4d',
        ],
        [
            0.5102040816326531,
            '#fa6647',
        ],
        [
            0.5306122448979591,
            '#f85e42',
        ],
        [
            0.5510204081632653,
            '#f5563d',
        ],
        [
            0.5714285714285714,
            '#f44e38',
        ],
        [
            0.5918367346938775,
            '#f24733',
        ],
        [
            0.6122448979591836,
            '#f03f2f',
        ],
        [
            0.6326530612244897,
            '#ed392b',
        ],
        [
            0.6530612244897959,
            '#e63228',
        ],
        [
            0.673469387755102,
            '#e02d26',
        ],
        [
            0.6938775510204082,
            '#db2723',
        ],
        [
            0.7142857142857142,
            '#d52221',
        ],
        [
            0.7346938775510203,
            '#ce1b1e',
        ],
        [
            0.7551020408163265,
            '#c8171c',
        ],
        [
            0.7755102040816326,
            '#c2161b',
        ],
        [
            0.7959183673469387,
            '#bc141a',
        ],
        [
            0.8163265306122448,
            '#b71318',
        ],
        [
            0.836734693877551,
            '#af1117',
        ],
        [
            0.8571428571428571,
            '#a91016',
        ],
        [
            0.8775510204081632,
            '#a30e14',
        ],
        [
            0.8979591836734693,
            '#990c13',
        ],
        [
            0.9183673469387754,
            '#8d0912',
        ],
        [
            0.9387755102040816,
            '#840710',
        ],
        [
            0.9591836734693877,
            '#7a040f',
        ],
        [
            0.9795918367346939,
            '#70020e',
        ],
        [
            1.0,
            '#67000c',
        ],
    ],
    Seismic: [
        [
            0.0,
            '#00004c',
        ],
        [
            0.02040816326530612,
            '#00005a',
        ],
        [
            0.04081632653061224,
            '#000068',
        ],
        [
            0.061224489795918366,
            '#000076',
        ],
        [
            0.08163265306122448,
            '#000084',
        ],
        [
            0.1020408163265306,
            '#000095',
        ],
        [
            0.12244897959183673,
            '#0000a3',
        ],
        [
            0.14285714285714285,
            '#0000b1',
        ],
        [
            0.16326530612244897,
            '#0000bf',
        ],
        [
            0.18367346938775508,
            '#0000d0',
        ],
        [
            0.2040816326530612,
            '#0000de',
        ],
        [
            0.22448979591836732,
            '#0000ec',
        ],
        [
            0.24489795918367346,
            '#0000fa',
        ],
        [
            0.26530612244897955,
            '#0d0dff',
        ],
        [
            0.2857142857142857,
            '#2525ff',
        ],
        [
            0.3061224489795918,
            '#3939ff',
        ],
        [
            0.32653061224489793,
            '#4d4dff',
        ],
        [
            0.3469387755102041,
            '#6161ff',
        ],
        [
            0.36734693877551017,
            '#7979ff',
        ],
        [
            0.3877551020408163,
            '#8d8dff',
        ],
        [
            0.4081632653061224,
            '#a1a1ff',
        ],
        [
            0.42857142857142855,
            '#b5b5ff',
        ],
        [
            0.44897959183673464,
            '#c8c8ff',
        ],
        [
            0.4693877551020408,
            '#e1e1ff',
        ],
        [
            0.4897959183673469,
            '#f5f5ff',
        ],
        [
            0.5102040816326531,
            '#fff5f5',
        ],
        [
            0.5306122448979591,
            '#ffe1e1',
        ],
        [
            0.5510204081632653,
            '#ffc9c9',
        ],
        [
            0.5714285714285714,
            '#ffb4b4',
        ],
        [
            0.5918367346938775,
            '#ffa1a1',
        ],
        [
            0.6122448979591836,
            '#ff8d8d',
        ],
        [
            0.6326530612244897,
            '#ff7979',
        ],
        [
            0.6530612244897959,
            '#ff6161',
        ],
        [
            0.673469387755102,
            '#ff4d4d',
        ],
        [
            0.6938775510204082,
            '#ff3838',
        ],
        [
            0.7142857142857142,
            '#ff2525',
        ],
        [
            0.7346938775510203,
            '#ff0d0d',
        ],
        [
            0.7551020408163265,
            '#fb0000',
        ],
        [
            0.7755102040816326,
            '#f10000',
        ],
        [
            0.7959183673469387,
            '#e70000',
        ],
        [
            0.8163265306122448,
            '#dd0000',
        ],
        [
            0.836734693877551,
            '#d10000',
        ],
        [
            0.8571428571428571,
            '#c70000',
        ],
        [
            0.8775510204081632,
            '#bd0000',
        ],
        [
            0.8979591836734693,
            '#b30000',
        ],
        [
            0.9183673469387754,
            '#a70000',
        ],
        [
            0.9387755102040816,
            '#9d0000',
        ],
        [
            0.9591836734693877,
            '#930000',
        ],
        [
            0.9795918367346939,
            '#890000',
        ],
        [
            1.0,
            '#7f0000',
        ],
    ],
    Spectral: [
        [
            0.0,
            '#9e0142',
        ],
        [
            0.1,
            '#d33c4e',
        ],
        [
            0.2,
            '#f46d43',
        ],
        [
            0.30000000000000004,
            '#fcac60',
        ],
        [
            0.4,
            '#fee08b',
        ],
        [
            0.5,
            '#fefebe',
        ],
        [
            0.6000000000000001,
            '#e6f598',
        ],
        [
            0.7000000000000001,
            '#a9dca4',
        ],
        [
            0.8,
            '#66c2a5',
        ],
        [
            0.9,
            '#3286bc',
        ],
        [
            1.0,
            '#5e4fa2',
        ],
    ],
    Spring: [
        [
            0.0,
            '#ff00ff',
        ],
        [
            0.02040816326530612,
            '#ff05fa',
        ],
        [
            0.04081632653061224,
            '#ff0af5',
        ],
        [
            0.061224489795918366,
            '#ff0ff0',
        ],
        [
            0.08163265306122448,
            '#ff14eb',
        ],
        [
            0.1020408163265306,
            '#ff1ae5',
        ],
        [
            0.12244897959183673,
            '#ff1fe0',
        ],
        [
            0.14285714285714285,
            '#ff24db',
        ],
        [
            0.16326530612244897,
            '#ff28d6',
        ],
        [
            0.18367346938775508,
            '#ff2fd0',
        ],
        [
            0.2040816326530612,
            '#ff34cb',
        ],
        [
            0.22448979591836732,
            '#ff38c6',
        ],
        [
            0.24489795918367346,
            '#ff3ec1',
        ],
        [
            0.26530612244897955,
            '#ff43bc',
        ],
        [
            0.2857142857142857,
            '#ff49b6',
        ],
        [
            0.3061224489795918,
            '#ff4eb1',
        ],
        [
            0.32653061224489793,
            '#ff53ac',
        ],
        [
            0.3469387755102041,
            '#ff58a7',
        ],
        [
            0.36734693877551017,
            '#ff5ea1',
        ],
        [
            0.3877551020408163,
            '#ff639c',
        ],
        [
            0.4081632653061224,
            '#ff6897',
        ],
        [
            0.42857142857142855,
            '#ff6d92',
        ],
        [
            0.44897959183673464,
            '#ff718d',
        ],
        [
            0.4693877551020408,
            '#ff7887',
        ],
        [
            0.4897959183673469,
            '#ff7d82',
        ],
        [
            0.5102040816326531,
            '#ff827d',
        ],
        [
            0.5306122448979591,
            '#ff8778',
        ],
        [
            0.5510204081632653,
            '#ff8d71',
        ],
        [
            0.5714285714285714,
            '#ff926d',
        ],
        [
            0.5918367346938775,
            '#ff9768',
        ],
        [
            0.6122448979591836,
            '#ff9c62',
        ],
        [
            0.6326530612244897,
            '#ffa15e',
        ],
        [
            0.6530612244897959,
            '#ffa758',
        ],
        [
            0.673469387755102,
            '#ffac52',
        ],
        [
            0.6938775510204082,
            '#ffb14e',
        ],
        [
            0.7142857142857142,
            '#ffb649',
        ],
        [
            0.7346938775510203,
            '#ffbc42',
        ],
        [
            0.7551020408163265,
            '#ffc13e',
        ],
        [
            0.7755102040816326,
            '#ffc638',
        ],
        [
            0.7959183673469387,
            '#ffcb34',
        ],
        [
            0.8163265306122448,
            '#ffd02f',
        ],
        [
            0.836734693877551,
            '#ffd628',
        ],
        [
            0.8571428571428571,
            '#ffdb24',
        ],
        [
            0.8775510204081632,
            '#ffe01f',
        ],
        [
            0.8979591836734693,
            '#ffe519',
        ],
        [
            0.9183673469387754,
            '#ffeb14',
        ],
        [
            0.9387755102040816,
            '#fff00f',
        ],
        [
            0.9591836734693877,
            '#fff509',
        ],
        [
            0.9795918367346939,
            '#fffa05',
        ],
        [
            1.0,
            '#ffff00',
        ],
    ],
    Summer: [
        [
            0.0,
            '#007f66',
        ],
        [
            0.02040816326530612,
            '#058266',
        ],
        [
            0.04081632653061224,
            '#0a8466',
        ],
        [
            0.061224489795918366,
            '#0f8766',
        ],
        [
            0.08163265306122448,
            '#148966',
        ],
        [
            0.1020408163265306,
            '#1a8c66',
        ],
        [
            0.12244897959183673,
            '#1f8f66',
        ],
        [
            0.14285714285714285,
            '#249166',
        ],
        [
            0.16326530612244897,
            '#289366',
        ],
        [
            0.18367346938775508,
            '#2f9766',
        ],
        [
            0.2040816326530612,
            '#349966',
        ],
        [
            0.22448979591836732,
            '#389c66',
        ],
        [
            0.24489795918367346,
            '#3e9e66',
        ],
        [
            0.26530612244897955,
            '#43a166',
        ],
        [
            0.2857142857142857,
            '#49a366',
        ],
        [
            0.3061224489795918,
            '#4ea666',
        ],
        [
            0.32653061224489793,
            '#53a966',
        ],
        [
            0.3469387755102041,
            '#58ab66',
        ],
        [
            0.36734693877551017,
            '#5eae66',
        ],
        [
            0.3877551020408163,
            '#63b166',
        ],
        [
            0.4081632653061224,
            '#68b366',
        ],
        [
            0.42857142857142855,
            '#6db666',
        ],
        [
            0.44897959183673464,
            '#71b866',
        ],
        [
            0.4693877551020408,
            '#78bb66',
        ],
        [
            0.4897959183673469,
            '#7dbe66',
        ],
        [
            0.5102040816326531,
            '#82c066',
        ],
        [
            0.5306122448979591,
            '#87c366',
        ],
        [
            0.5510204081632653,
            '#8dc666',
        ],
        [
            0.5714285714285714,
            '#92c866',
        ],
        [
            0.5918367346938775,
            '#97cb66',
        ],
        [
            0.6122448979591836,
            '#9ccd66',
        ],
        [
            0.6326530612244897,
            '#a1d066',
        ],
        [
            0.6530612244897959,
            '#a7d366',
        ],
        [
            0.673469387755102,
            '#acd566',
        ],
        [
            0.6938775510204082,
            '#b1d866',
        ],
        [
            0.7142857142857142,
            '#b6da66',
        ],
        [
            0.7346938775510203,
            '#bcdd66',
        ],
        [
            0.7551020408163265,
            '#c1e066',
        ],
        [
            0.7755102040816326,
            '#c6e266',
        ],
        [
            0.7959183673469387,
            '#cbe466',
        ],
        [
            0.8163265306122448,
            '#d0e766',
        ],
        [
            0.836734693877551,
            '#d6ea66',
        ],
        [
            0.8571428571428571,
            '#dbed66',
        ],
        [
            0.8775510204081632,
            '#e0ef66',
        ],
        [
            0.8979591836734693,
            '#e5f266',
        ],
        [
            0.9183673469387754,
            '#ebf466',
        ],
        [
            0.9387755102040816,
            '#f0f766',
        ],
        [
            0.9591836734693877,
            '#f5fa66',
        ],
        [
            0.9795918367346939,
            '#fafc66',
        ],
        [
            1.0,
            '#ffff66',
        ],
    ],
    Terrain: [
        [
            0.0,
            '#333399',
        ],
        [
            0.02040816326530612,
            '#2c40a6',
        ],
        [
            0.04081632653061224,
            '#254db3',
        ],
        [
            0.061224489795918366,
            '#1f5bc1',
        ],
        [
            0.08163265306122448,
            '#1868ce',
        ],
        [
            0.1020408163265306,
            '#1078de',
        ],
        [
            0.12244897959183673,
            '#0985eb',
        ],
        [
            0.14285714285714285,
            '#0393f9',
        ],
        [
            0.16326530612244897,
            '#009eee',
        ],
        [
            0.18367346938775508,
            '#00aaca',
        ],
        [
            0.2040816326530612,
            '#00b4ac',
        ],
        [
            0.22448979591836732,
            '#00be8e',
        ],
        [
            0.24489795918367346,
            '#00c870',
        ],
        [
            0.26530612244897955,
            '#0dce68',
        ],
        [
            0.2857142857142857,
            '#25d36d',
        ],
        [
            0.3061224489795918,
            '#39d771',
        ],
        [
            0.32653061224489793,
            '#4ddb75',
        ],
        [
            0.3469387755102041,
            '#61df79',
        ],
        [
            0.36734693877551017,
            '#79e47e',
        ],
        [
            0.3877551020408163,
            '#8de882',
        ],
        [
            0.4081632653061224,
            '#a1ec86',
        ],
        [
            0.42857142857142855,
            '#b5f08a',
        ],
        [
            0.44897959183673464,
            '#c8f48e',
        ],
        [
            0.4693877551020408,
            '#e1f993',
        ],
        [
            0.4897959183673469,
            '#f5fd97',
        ],
        [
            0.5102040816326531,
            '#faf896',
        ],
        [
            0.5306122448979591,
            '#f0eb90',
        ],
        [
            0.5510204081632653,
            '#e4dc8a',
        ],
        [
            0.5714285714285714,
            '#dacf85',
        ],
        [
            0.5918367346938775,
            '#d0c27f',
        ],
        [
            0.6122448979591836,
            '#c6b67a',
        ],
        [
            0.6326530612244897,
            '#bca974',
        ],
        [
            0.6530612244897959,
            '#b0996e',
        ],
        [
            0.673469387755102,
            '#a68d68',
        ],
        [
            0.6938775510204082,
            '#9c8063',
        ],
        [
            0.7142857142857142,
            '#92735e',
        ],
        [
            0.7346938775510203,
            '#866457',
        ],
        [
            0.7551020408163265,
            '#836058',
        ],
        [
            0.7755102040816326,
            '#8d6d66',
        ],
        [
            0.7959183673469387,
            '#977973',
        ],
        [
            0.8163265306122448,
            '#a18681',
        ],
        [
            0.836734693877551,
            '#ad9691',
        ],
        [
            0.8571428571428571,
            '#b7a29e',
        ],
        [
            0.8775510204081632,
            '#c1afab',
        ],
        [
            0.8979591836734693,
            '#cbbcb9',
        ],
        [
            0.9183673469387754,
            '#d7cbc9',
        ],
        [
            0.9387755102040816,
            '#e1d8d6',
        ],
        [
            0.9591836734693877,
            '#ebe5e4',
        ],
        [
            0.9795918367346939,
            '#f4f2f1',
        ],
        [
            1.0,
            '#ffffff',
        ],
    ],
    Winter: [
        [
            0.0,
            '#0000ff',
        ],
        [
            0.02040816326530612,
            '#0005fc',
        ],
        [
            0.04081632653061224,
            '#000afa',
        ],
        [
            0.061224489795918366,
            '#000ff7',
        ],
        [
            0.08163265306122448,
            '#0014f5',
        ],
        [
            0.1020408163265306,
            '#001af2',
        ],
        [
            0.12244897959183673,
            '#001fef',
        ],
        [
            0.14285714285714285,
            '#0024ed',
        ],
        [
            0.16326530612244897,
            '#0028ea',
        ],
        [
            0.18367346938775508,
            '#002fe7',
        ],
        [
            0.2040816326530612,
            '#0034e5',
        ],
        [
            0.22448979591836732,
            '#0038e2',
        ],
        [
            0.24489795918367346,
            '#003ee0',
        ],
        [
            0.26530612244897955,
            '#0043dd',
        ],
        [
            0.2857142857142857,
            '#0049da',
        ],
        [
            0.3061224489795918,
            '#004ed8',
        ],
        [
            0.32653061224489793,
            '#0053d5',
        ],
        [
            0.3469387755102041,
            '#0058d3',
        ],
        [
            0.36734693877551017,
            '#005ed0',
        ],
        [
            0.3877551020408163,
            '#0063cd',
        ],
        [
            0.4081632653061224,
            '#0068cb',
        ],
        [
            0.42857142857142855,
            '#006dc8',
        ],
        [
            0.44897959183673464,
            '#0071c6',
        ],
        [
            0.4693877551020408,
            '#0078c3',
        ],
        [
            0.4897959183673469,
            '#007dc0',
        ],
        [
            0.5102040816326531,
            '#0082be',
        ],
        [
            0.5306122448979591,
            '#0087bb',
        ],
        [
            0.5510204081632653,
            '#008db8',
        ],
        [
            0.5714285714285714,
            '#0092b6',
        ],
        [
            0.5918367346938775,
            '#0097b3',
        ],
        [
            0.6122448979591836,
            '#009cb1',
        ],
        [
            0.6326530612244897,
            '#00a1ae',
        ],
        [
            0.6530612244897959,
            '#00a7ab',
        ],
        [
            0.673469387755102,
            '#00aca9',
        ],
        [
            0.6938775510204082,
            '#00b1a6',
        ],
        [
            0.7142857142857142,
            '#00b6a3',
        ],
        [
            0.7346938775510203,
            '#00bca1',
        ],
        [
            0.7551020408163265,
            '#00c19e',
        ],
        [
            0.7755102040816326,
            '#00c69c',
        ],
        [
            0.7959183673469387,
            '#00cb99',
        ],
        [
            0.8163265306122448,
            '#00d097',
        ],
        [
            0.836734693877551,
            '#00d693',
        ],
        [
            0.8571428571428571,
            '#00db91',
        ],
        [
            0.8775510204081632,
            '#00e08f',
        ],
        [
            0.8979591836734693,
            '#00e58c',
        ],
        [
            0.9183673469387754,
            '#00eb89',
        ],
        [
            0.9387755102040816,
            '#00f087',
        ],
        [
            0.9591836734693877,
            '#00f584',
        ],
        [
            0.9795918367346939,
            '#00fa82',
        ],
        [
            1.0,
            '#00ff7f',
        ],
    ],
    Wistia: [
        [
            0.0,
            '#e4ff7a',
        ],
        [
            0.02040816326530612,
            '#e6fd72',
        ],
        [
            0.04081632653061224,
            '#e8fb6a',
        ],
        [
            0.061224489795918366,
            '#eaf963',
        ],
        [
            0.08163265306122448,
            '#ecf75b',
        ],
        [
            0.1020408163265306,
            '#eff552',
        ],
        [
            0.12244897959183673,
            '#f1f34b',
        ],
        [
            0.14285714285714285,
            '#f3f243',
        ],
        [
            0.16326530612244897,
            '#f5f03c',
        ],
        [
            0.18367346938775508,
            '#f7ee33',
        ],
        [
            0.2040816326530612,
            '#faec2b',
        ],
        [
            0.22448979591836732,
            '#fcea24',
        ],
        [
            0.24489795918367346,
            '#fee81c',
        ],
        [
            0.26530612244897955,
            '#ffe518',
        ],
        [
            0.2857142857142857,
            '#ffe116',
        ],
        [
            0.3061224489795918,
            '#ffde14',
        ],
        [
            0.32653061224489793,
            '#ffdb12',
        ],
        [
            0.3469387755102041,
            '#ffd710',
        ],
        [
            0.36734693877551017,
            '#ffd30d',
        ],
        [
            0.3877551020408163,
            '#ffd00b',
        ],
        [
            0.4081632653061224,
            '#ffcc09',
        ],
        [
            0.42857142857142855,
            '#ffc907',
        ],
        [
            0.44897959183673464,
            '#ffc605',
        ],
        [
            0.4693877551020408,
            '#ffc203',
        ],
        [
            0.4897959183673469,
            '#ffbe01',
        ],
        [
            0.5102040816326531,
            '#ffbb00',
        ],
        [
            0.5306122448979591,
            '#ffb900',
        ],
        [
            0.5510204081632653,
            '#ffb600',
        ],
        [
            0.5714285714285714,
            '#ffb400',
        ],
        [
            0.5918367346938775,
            '#ffb200',
        ],
        [
            0.6122448979591836,
            '#ffb000',
        ],
        [
            0.6326530612244897,
            '#ffad00',
        ],
        [
            0.6530612244897959,
            '#ffab00',
        ],
        [
            0.673469387755102,
            '#ffa800',
        ],
        [
            0.6938775510204082,
            '#ffa600',
        ],
        [
            0.7142857142857142,
            '#ffa400',
        ],
        [
            0.7346938775510203,
            '#ffa100',
        ],
        [
            0.7551020408163265,
            '#fe9f00',
        ],
        [
            0.7755102040816326,
            '#fe9c00',
        ],
        [
            0.7959183673469387,
            '#fe9900',
        ],
        [
            0.8163265306122448,
            '#fe9700',
        ],
        [
            0.836734693877551,
            '#fd9400',
        ],
        [
            0.8571428571428571,
            '#fd9100',
        ],
        [
            0.8775510204081632,
            '#fd8f00',
        ],
        [
            0.8979591836734693,
            '#fd8c00',
        ],
        [
            0.9183673469387754,
            '#fc8900',
        ],
        [
            0.9387755102040816,
            '#fc8600',
        ],
        [
            0.9591836734693877,
            '#fc8400',
        ],
        [
            0.9795918367346939,
            '#fc8100',
        ],
        [
            1.0,
            '#fc7f00',
        ],
    ],
    YlGn: [
        [
            0.0,
            '#ffffe5',
        ],
        [
            0.02040816326530612,
            '#fdfede',
        ],
        [
            0.04081632653061224,
            '#fcfed7',
        ],
        [
            0.061224489795918366,
            '#fbfdd0',
        ],
        [
            0.08163265306122448,
            '#f9fdc9',
        ],
        [
            0.1020408163265306,
            '#f8fcc1',
        ],
        [
            0.12244897959183673,
            '#f7fcba',
        ],
        [
            0.14285714285714285,
            '#f3fab6',
        ],
        [
            0.16326530612244897,
            '#eef8b2',
        ],
        [
            0.18367346938775508,
            '#e8f6ae',
        ],
        [
            0.2040816326530612,
            '#e4f4ab',
        ],
        [
            0.22448979591836732,
            '#dff2a7',
        ],
        [
            0.24489795918367346,
            '#daf0a4',
        ],
        [
            0.26530612244897955,
            '#d4eea0',
        ],
        [
            0.2857142857142857,
            '#ccea9c',
        ],
        [
            0.3061224489795918,
            '#c5e799',
        ],
        [
            0.32653061224489793,
            '#bee496',
        ],
        [
            0.3469387755102041,
            '#b7e193',
        ],
        [
            0.36734693877551017,
            '#afdd8f',
        ],
        [
            0.3877551020408163,
            '#a7da8b',
        ],
        [
            0.4081632653061224,
            '#9fd688',
        ],
        [
            0.42857142857142855,
            '#96d385',
        ],
        [
            0.44897959183673464,
            '#8ecf81',
        ],
        [
            0.4693877551020408,
            '#84cb7d',
        ],
        [
            0.4897959183673469,
            '#7cc77a',
        ],
        [
            0.5102040816326531,
            '#73c376',
        ],
        [
            0.5306122448979591,
            '#6bbf72',
        ],
        [
            0.5510204081632653,
            '#60ba6d',
        ],
        [
            0.5714285714285714,
            '#58b668',
        ],
        [
            0.5918367346938775,
            '#4fb264',
        ],
        [
            0.6122448979591836,
            '#46ad5f',
        ],
        [
            0.6326530612244897,
            '#3fa95b',
        ],
        [
            0.6530612244897959,
            '#39a156',
        ],
        [
            0.673469387755102,
            '#359b52',
        ],
        [
            0.6938775510204082,
            '#30954e',
        ],
        [
            0.7142857142857142,
            '#2b8f4a',
        ],
        [
            0.7346938775510203,
            '#268745',
        ],
        [
            0.7551020408163265,
            '#218242',
        ],
        [
            0.7755102040816326,
            '#1b7e40',
        ],
        [
            0.7959183673469387,
            '#16793e',
        ],
        [
            0.8163265306122448,
            '#10753c',
        ],
        [
            0.836734693877551,
            '#0a703a',
        ],
        [
            0.8571428571428571,
            '#046b38',
        ],
        [
            0.8775510204081632,
            '#006736',
        ],
        [
            0.8979591836734693,
            '#006134',
        ],
        [
            0.9183673469387754,
            '#005a31',
        ],
        [
            0.9387755102040816,
            '#00552f',
        ],
        [
            0.9591836734693877,
            '#004f2d',
        ],
        [
            0.9795918367346939,
            '#004a2b',
        ],
        [
            1.0,
            '#004529',
        ],
    ],
    YlGnBu: [
        [
            0.0,
            '#ffffd9',
        ],
        [
            0.02040816326530612,
            '#fcfdd2',
        ],
        [
            0.04081632653061224,
            '#f9fccc',
        ],
        [
            0.061224489795918366,
            '#f6fbc6',
        ],
        [
            0.08163265306122448,
            '#f3fabf',
        ],
        [
            0.1020408163265306,
            '#f0f9b8',
        ],
        [
            0.12244897959183673,
            '#edf8b2',
        ],
        [
            0.14285714285714285,
            '#e8f6b1',
        ],
        [
            0.16326530612244897,
            '#e2f3b1',
        ],
        [
            0.18367346938775508,
            '#daf0b2',
        ],
        [
            0.2040816326530612,
            '#d5eeb2',
        ],
        [
            0.22448979591836732,
            '#cfecb3',
        ],
        [
            0.24489795918367346,
            '#c9e9b3',
        ],
        [
            0.26530612244897955,
            '#bfe6b4',
        ],
        [
            0.2857142857142857,
            '#b2e0b6',
        ],
        [
            0.3061224489795918,
            '#a6dcb7',
        ],
        [
            0.32653061224489793,
            '#9bd8b8',
        ],
        [
            0.3469387755102041,
            '#90d3b9',
        ],
        [
            0.36734693877551017,
            '#82ceba',
        ],
        [
            0.3877551020408163,
            '#78cabb',
        ],
        [
            0.4081632653061224,
            '#6ec6bd',
        ],
        [
            0.42857142857142855,
            '#64c3be',
        ],
        [
            0.44897959183673464,
            '#5bbfc0',
        ],
        [
            0.4693877551020408,
            '#4fbbc1',
        ],
        [
            0.4897959183673469,
            '#45b7c3',
        ],
        [
            0.5102040816326531,
            '#3eb3c3',
        ],
        [
            0.5306122448979591,
            '#38adc3',
        ],
        [
            0.5510204081632653,
            '#31a6c2',
        ],
        [
            0.5714285714285714,
            '#2ca0c1',
        ],
        [
            0.5918367346938775,
            '#269ac1',
        ],
        [
            0.6122448979591836,
            '#2094c0',
        ],
        [
            0.6326530612244897,
            '#1d8ebe',
        ],
        [
            0.6530612244897959,
            '#1e84ba',
        ],
        [
            0.673469387755102,
            '#1e7cb6',
        ],
        [
            0.6938775510204082,
            '#1f74b2',
        ],
        [
            0.7142857142857142,
            '#206cae',
        ],
        [
            0.7346938775510203,
            '#2163aa',
        ],
        [
            0.7551020408163265,
            '#225ba6',
        ],
        [
            0.7755102040816326,
            '#2255a3',
        ],
        [
            0.7959183673469387,
            '#234ea0',
        ],
        [
            0.8163265306122448,
            '#23479d',
        ],
        [
            0.836734693877551,
            '#244099',
        ],
        [
            0.8571428571428571,
            '#243996',
        ],
        [
            0.8775510204081632,
            '#243392',
        ],
        [
            0.8979591836734693,
            '#1f2f88',
        ],
        [
            0.9183673469387754,
            '#1a2b7d',
        ],
        [
            0.9387755102040816,
            '#152774',
        ],
        [
            0.9591836734693877,
            '#11246a',
        ],
        [
            0.9795918367346939,
            '#0c2061',
        ],
        [
            1.0,
            '#081d58',
        ],
    ],
    YlOrBr: [
        [
            0.0,
            '#ffffe5',
        ],
        [
            0.02040816326530612,
            '#fffdde',
        ],
        [
            0.04081632653061224,
            '#fffcd8',
        ],
        [
            0.061224489795918366,
            '#fffbd1',
        ],
        [
            0.08163265306122448,
            '#fff9cb',
        ],
        [
            0.1020408163265306,
            '#fff8c3',
        ],
        [
            0.12244897959183673,
            '#fff7bd',
        ],
        [
            0.14285714285714285,
            '#fef4b6',
        ],
        [
            0.16326530612244897,
            '#fef1af',
        ],
        [
            0.18367346938775508,
            '#feeda7',
        ],
        [
            0.2040816326530612,
            '#feeaa0',
        ],
        [
            0.22448979591836732,
            '#fee79a',
        ],
        [
            0.24489795918367346,
            '#fee493',
        ],
        [
            0.26530612244897955,
            '#fedf8a',
        ],
        [
            0.2857142857142857,
            '#feda7d',
        ],
        [
            0.3061224489795918,
            '#fed573',
        ],
        [
            0.32653061224489793,
            '#fed069',
        ],
        [
            0.3469387755102041,
            '#fecb5e',
        ],
        [
            0.36734693877551017,
            '#fec552',
        ],
        [
            0.3877551020408163,
            '#febf4a',
        ],
        [
            0.4081632653061224,
            '#feb845',
        ],
        [
            0.42857142857142855,
            '#feb13f',
        ],
        [
            0.44897959183673464,
            '#feab39',
        ],
        [
            0.4693877551020408,
            '#fea331',
        ],
        [
            0.4897959183673469,
            '#fe9c2b',
        ],
        [
            0.5102040816326531,
            '#fc9527',
        ],
        [
            0.5306122448979591,
            '#f98f24',
        ],
        [
            0.5510204081632653,
            '#f68720',
        ],
        [
            0.5714285714285714,
            '#f3811c',
        ],
        [
            0.5918367346938775,
            '#f07a19',
        ],
        [
            0.6122448979591836,
            '#ed7416',
        ],
        [
            0.6326530612244897,
            '#ea6e13',
        ],
        [
            0.6530612244897959,
            '#e4670f',
        ],
        [
            0.673469387755102,
            '#df610c',
        ],
        [
            0.6938775510204082,
            '#da5c0a',
        ],
        [
            0.7142857142857142,
            '#d55607',
        ],
        [
            0.7346938775510203,
            '#cf4f03',
        ],
        [
            0.7551020408163265,
            '#c94a02',
        ],
        [
            0.7755102040816326,
            '#c14602',
        ],
        [
            0.7959183673469387,
            '#b94302',
        ],
        [
            0.8163265306122448,
            '#b13f03',
        ],
        [
            0.836734693877551,
            '#a73a03',
        ],
        [
            0.8571428571428571,
            '#9f3703',
        ],
        [
            0.8775510204081632,
            '#973304',
        ],
        [
            0.8979591836734693,
            '#8f3104',
        ],
        [
            0.9183673469387754,
            '#862e04',
        ],
        [
            0.9387755102040816,
            '#7e2c05',
        ],
        [
            0.9591836734693877,
            '#762905',
        ],
        [
            0.9795918367346939,
            '#6e2705',
        ],
        [
            1.0,
            '#662505',
        ],
    ],
    YlOrRd: [
        [
            0.0,
            '#ffffcc',
        ],
        [
            0.02040816326530612,
            '#fffcc5',
        ],
        [
            0.04081632653061224,
            '#fff9be',
        ],
        [
            0.061224489795918366,
            '#fff6b7',
        ],
        [
            0.08163265306122448,
            '#fff3b0',
        ],
        [
            0.1020408163265306,
            '#fff0a8',
        ],
        [
            0.12244897959183673,
            '#ffeda1',
        ],
        [
            0.14285714285714285,
            '#feea9a',
        ],
        [
            0.16326530612244897,
            '#fee793',
        ],
        [
            0.18367346938775508,
            '#fee38c',
        ],
        [
            0.2040816326530612,
            '#fee085',
        ],
        [
            0.22448979591836732,
            '#fedd7e',
        ],
        [
            0.24489795918367346,
            '#feda78',
        ],
        [
            0.26530612244897955,
            '#fed571',
        ],
        [
            0.2857142857142857,
            '#fecd69',
        ],
        [
            0.3061224489795918,
            '#fec763',
        ],
        [
            0.32653061224489793,
            '#fec15c',
        ],
        [
            0.3469387755102041,
            '#febb56',
        ],
        [
            0.36734693877551017,
            '#feb34e',
        ],
        [
            0.3877551020408163,
            '#fdae4a',
        ],
        [
            0.4081632653061224,
            '#fda847',
        ],
        [
            0.42857142857142855,
            '#fda245',
        ],
        [
            0.44897959183673464,
            '#fd9c42',
        ],
        [
            0.4693877551020408,
            '#fd953f',
        ],
        [
            0.4897959183673469,
            '#fd8f3d',
        ],
        [
            0.5102040816326531,
            '#fc883a',
        ],
        [
            0.5306122448979591,
            '#fc7e37',
        ],
        [
            0.5510204081632653,
            '#fc7234',
        ],
        [
            0.5714285714285714,
            '#fc6831',
        ],
        [
            0.5918367346938775,
            '#fc5e2e',
        ],
        [
            0.6122448979591836,
            '#fc542b',
        ],
        [
            0.6326530612244897,
            '#fa4b29',
        ],
        [
            0.6530612244897959,
            '#f64126',
        ],
        [
            0.673469387755102,
            '#f23924',
        ],
        [
            0.6938775510204082,
            '#ee3122',
        ],
        [
            0.7142857142857142,
            '#ea2920',
        ],
        [
            0.7346938775510203,
            '#e51f1d',
        ],
        [
            0.7551020408163265,
            '#e0181c',
        ],
        [
            0.7755102040816326,
            '#da141e',
        ],
        [
            0.7959183673469387,
            '#d4101f',
        ],
        [
            0.8163265306122448,
            '#cf0c21',
        ],
        [
            0.836734693877551,
            '#c70723',
        ],
        [
            0.8571428571428571,
            '#c10324',
        ],
        [
            0.8775510204081632,
            '#bb0026',
        ],
        [
            0.8979591836734693,
            '#b10026',
        ],
        [
            0.9183673469387754,
            '#a60026',
        ],
        [
            0.9387755102040816,
            '#9c0026',
        ],
        [
            0.9591836734693877,
            '#930026',
        ],
        [
            0.9795918367346939,
            '#890026',
        ],
        [
            1.0,
            '#800026',
        ],
    ],
}
export default value
