export default [
    {
        allGeographies: [
            'AK-00, USA',
            'AL-01, USA',
            'AL-02, USA',
            'AL-03, USA',
            'AL-04, USA',
            'AL-05, USA',
            'AL-06, USA',
            'AL-07, USA',
            'AR-01, USA',
            'AR-02, USA',
            'AR-03, USA',
            'AR-04, USA',
            'AZ-01, USA',
            'AZ-02, USA',
            'AZ-03, USA',
            'AZ-04, USA',
            'AZ-05, USA',
            'AZ-06, USA',
            'AZ-07, USA',
            'AZ-08, USA',
            'AZ-09, USA',
            'Abia, Nigeria',
            'Abidjan Urban Center, Ivory Coast',
            'Abidjan, Ivory Coast',
            'Abilene-Sweetwater TX Media Market, USA',
            'Abuja Federal Capital Territory, Nigeria',
            'Ab\u016b Z\u0327aby, United Arab Emirates',
            'Accra Urban Center, Ghana',
            'Aceh, Indonesia',
            'Ad Daqahl\u012byah, Egypt',
            'Ada County, Idaho, USA',
            'Adamawa, Nigeria',
            'Adams County, Colorado, USA',
            'Addis Ababa Urban Center, Ethiopia',
            'Afghanistan',
            'Agra Urban Center, India',
            'Aguadilla-Isabela MSA, PR, USA',
            'Ahmedabad Urban Center, India',
            'Aiti, Japan',
            'Akron MSA, OH, USA',
            'Akron [Urban Area], OH, USA',
            'Akwa Ibom, Nigeria',
            'Al Ba\u015frah, Iraq',
            'Al Ba\u1e29r al A\u1e29mar, Sudan',
            'Al Buh\u0327ayrah, Egypt',
            'Al Fayy\u016bm, Egypt',
            'Al Gharb\u012byah, Egypt',
            'Al Iskandariyah, Egypt',
            'Al Jaz\u012brah, Sudan',
            'Al J\u012bzah, Egypt',
            'Al Khar\u0163\u016bm, Sudan',
            'Al Miny\u0101, Egypt',
            'Al Min\u016bf\u012byah, Egypt',
            'Al Qaly\u016bb\u012byah, Egypt',
            'Al Q\u0101hirah, Egypt',
            'Al \u1e28udaydah, Yemen',
            'Al \u2018A\u0305\u015fimah, Jordan',
            'Alabama, USA',
            'Alachua County, Florida, USA',
            'Alagoas, Brazil',
            'Alameda County, California, USA',
            'Alaska, USA',
            'Albania',
            'Albany County, New York, USA',
            'Albany GA Media Market, USA',
            'Albany-Schenectady [Urban Area], NY, USA',
            'Albany-Schenectady-Troy MSA, NY, USA',
            'Albany-Schenectady-Troy NY Media Market, USA',
            'Alberta, Canada',
            'Albuquerque MSA, NM, USA',
            'Albuquerque Urban Center, USA',
            'Albuquerque [Urban Area], NM, USA',
            'Albuquerque city, New Mexico, USA',
            'Albuquerque-Santa Fe NM Media Market, USA',
            'Alexandria Urban Center, Egypt',
            'Algeria',
            'Algiers Urban Center, Algeria',
            'Allegheny County, Pennsylvania, USA',
            'Allen County, Indiana, USA',
            'Allentown Urban Center, USA',
            'Allentown-Bethlehem [Urban Area], PA-NJ, USA',
            'Allentown-Bethlehem-Easton MSA, PA-NJ, USA',
            'Almaty oblysy, Kazakhstan',
            'Amarillo MSA, TX, USA',
            'Amarillo TX Media Market, USA',
            'Amazonas, Brazil',
            'Amman Urban Center, Jordan',
            'Am\u0101nat al \u2018\u0100\u015fimah [city], Yemen',
            'An N\u012bl al Abya\u1e11, Sudan',
            'Anaheim city, California, USA',
            'Analamanga, Madagascar',
            'Anambra, Nigeria',
            'Anchorage AK Media Market, USA',
            'Anchorage MSA, AK, USA',
            'Anchorage Municipality, Alaska, USA',
            'Anchorage municipality, Alaska, USA',
            'Andaluc\u00eda, Spain',
            'Andhra Pradesh, India',
            'Andijon, Uzbekistan',
            'Angola',
            'Anhui Sheng, China',
            'Ankara Urban Center, Turkey',
            'Ankara, Turkey',
            'Ann Arbor MSA, MI, USA',
            'Ann Arbor [Urban Area], MI, USA',
            'Anne Arundel County, Maryland, USA',
            'Anoka County, Minnesota, USA',
            'Antalya, Turkey',
            'Antananarivo Urban Center, Madagascar',
            'Antioch Urban Center, USA',
            'Antioch [Urban Area], CA, USA',
            'Antioquia, Colombia',
            'Ar Riy\u0101\u1e11, Saudi Arabia',
            'Arapahoe County, Colorado, USA',
            'Arb\u012bl, Iraq',
            'Argentina',
            'Arizona, USA',
            'Arkansas, USA',
            'Arlington city, Texas, USA',
            'Armenia',
            'Asansol Urban Center, India',
            'Ash Sharq\u012byah, Egypt',
            'Ash Sharq\u012byah, Saudi Arabia',
            'Ashanti, Ghana',
            'Asheville MSA, NC, USA',
            'Asheville [Urban Area], NC, USA',
            'Assam, India',
            'Asy\u016b\u0163, Egypt',
            'Athens Urban Center, Greece',
            'Atlanta GA Media Market, USA',
            'Atlanta Urban Center, USA',
            'Atlanta [Urban Area], GA, USA',
            'Atlanta city, Georgia, USA',
            'Atlanta-Sandy Springs-Roswell MSA, GA, USA',
            'Atlantic City-Hammonton MSA, NJ, USA',
            'Atlantic City-Ocean City-Villas [Urban Area], NJ, USA',
            'Atlantic County, New Jersey, USA',
            'Atl\u00e1ntico, Colombia',
            'Attik\u00ed, Greece',
            'Augusta GA Media Market, USA',
            'Augusta-Richmond County MSA, GA-SC, USA',
            'Augusta-Richmond County [Urban Area], GA-SC, USA',
            'Aurora city, Colorado, USA',
            'Austin TX Media Market, USA',
            'Austin Urban Center, USA',
            'Austin [Urban Area], TX, USA',
            'Austin city, Texas, USA',
            'Austin-Round Rock MSA, TX, USA',
            'Australia',
            'Austria',
            'Auvergne-Rh\u00f4ne-Alpes, France',
            'Ayeyarwady, Myanmar',
            'Azad Jammu and Kashmir, Pakistan',
            'Azerbaijan',
            'Azerbaijan, Azerbaijan',
            'Baden-W\u00fcrttemberg, Germany',
            'Baghdad Urban Center, Iraq',
            'Baghd\u0101d, Iraq',
            'Bago, Myanmar',
            'Bahia, Brazil',
            'Baja California, Mexico',
            'Bakersfield CA Media Market, USA',
            'Bakersfield MSA, CA, USA',
            'Bakersfield Urban Center, USA',
            'Bakersfield [Urban Area], CA, USA',
            'Bakersfield city, California, USA',
            'Bali, Indonesia',
            'Balochistan, Pakistan',
            'Baltimore County, Maryland, USA',
            'Baltimore MD Media Market, USA',
            'Baltimore Urban Center, USA',
            'Baltimore [Urban Area], MD, USA',
            'Baltimore city, Maryland, USA',
            'Baltimore city-County, Maryland, USA',
            'Baltimore-Columbia-Towson MSA, MD, USA',
            'Bamako Urban Center, Mali',
            'Bamako, Mali',
            'Banaadir, Somalia',
            'Bandung Urban Center, Indonesia',
            'Bangkok Urban Center, Thailand',
            'Bangladesh',
            'Bangor ME Media Market, USA',
            'Banten, Indonesia',
            'Ban\u012b Suwayf, Egypt',
            'Barcelona Urban Center, Spain',
            'Barishal, Bangladesh',
            'Barnstable Town [Urban Area], MA, USA',
            'Bas-Sassandra, Ivory Coast',
            'Bashkortostan, Respublika, Russia',
            'Basn\u0101hira pa\u1e37\u0101ta, Sri Lanka',
            'Batangas, Philippines',
            'Baton Rouge LA Media Market, USA',
            'Baton Rouge MSA, LA, USA',
            'Baton Rouge Urban Center, USA',
            'Baton Rouge [Urban Area], LA, USA',
            'Bauchi, Nigeria',
            'Bayelsa, Nigeria',
            'Bayern, Germany',
            'Beaumont-Port Arthur MSA, TX, USA',
            'Beaumont-Port Arthur TX Media Market, USA',
            'Beijing Shi, China',
            'Beijing Urban Center, China',
            'Belarus',
            'Belgium',
            'Bell County, Texas, USA',
            'Belo Horizonte Urban Center, Brazil',
            'Bengaluru Urban Center, India',
            'Benin',
            'Benton County, Arkansas, USA',
            'Benue, Nigeria',
            'Bergen County, New Jersey, USA',
            'Berks County, Pennsylvania, USA',
            'Berlin Urban Center, Germany',
            'Berlin, Germany',
            'Bernalillo County, New Mexico, USA',
            'Bexar County, Texas, USA',
            'Bih\u0101r, India',
            'Billings MT Media Market, USA',
            'Biloxi-Gulfport MS Media Market, USA',
            'Binghamton NY Media Market, USA',
            'Birmingham (Anniston & Tuscaloosa) AL Media Market, USA',
            'Birmingham Urban Center, United Kingdom',
            'Birmingham [Urban Area], AL, USA',
            'Birmingham-Hoover MSA, AL, USA',
            'Bluefield-Beckley-Oak Hill WV Media Market, USA',
            'Bogota Urban Center, Colombia',
            'Boise City MSA, ID, USA',
            'Boise City [Urban Area], ID, USA',
            'Boise ID Media Market, USA',
            'Boise Urban Center, USA',
            'Bolivia',
            'Bonita Springs-Estero [Urban Area], FL, USA',
            'Borno, Nigeria',
            'Bosnia and Herzegovina',
            'Boston MA (Manchester NH) Media Market, USA',
            'Boston Urban Center, USA',
            'Boston [Urban Area], MA-NH, USA',
            'Boston city, Massachusetts, USA',
            'Boston-Cambridge-Newton MSA, MA-NH, USA',
            'Botswana',
            'Boulder County, Colorado, USA',
            'Boulder MSA, CO, USA',
            'Bourgogne-Franche-Comt\u00e9, France',
            'Bradenton Urban Center, USA',
            'Bradenton-Sarasota-Venice [Urban Area], FL, USA',
            'Brampton City, Peel Regional municipality, Ontario, Canada',
            'Brandenburg, Germany',
            'Brazil',
            'Brazoria County, Texas, USA',
            'Brazzaville Urban Center, Congo',
            'Bremerton-Silverdale MSA, WA, USA',
            'Bretagne, France',
            'Brevard County, Florida, USA',
            'Brick Urban Center, USA',
            'Bridgeport Urban Center, USA',
            'Bridgeport-Stamford [Urban Area], CT-NY, USA',
            'Bridgeport-Stamford-Norwalk MSA, CT, USA',
            'Bristol County, Massachusetts, USA',
            'British Columbia, Canada',
            'Bronx County, New York, USA',
            'Broward County, Florida, USA',
            'Brown County, Wisconsin, USA',
            'Brownsville-Harlingen MSA, TX, USA',
            'Bucks County, Pennsylvania, USA',
            'Buenos Aires Urban Center, Argentina',
            'Buenos Aires, Argentina',
            'Buffalo NY Media Market, USA',
            'Buffalo Urban Center, USA',
            'Buffalo [Urban Area], NY, USA',
            'Buffalo city, New York, USA',
            'Buffalo-Cheektowaga-Niagara Falls MSA, NY, USA',
            'Bulacan, Philippines',
            'Bulgaria',
            'Buncombe County, North Carolina, USA',
            'Burkina Faso',
            'Burlington County, New Jersey, USA',
            'Burlington VT-Plattsburgh NY Media Market, USA',
            'Bursa, Turkey',
            'Burundi',
            'Busan Urban Center, South Korea',
            'Busan-gwangyeoksi, South Korea',
            'Butler County, Ohio, USA',
            'B\u00e9ni Mellal-Kh\u00e9nifra, Morocco',
            'B\u0101bil, Iraq',
            'B\u0101gmat\u012b, Nepal',
            'CA-01, USA',
            'CA-02, USA',
            'CA-03, USA',
            'CA-04, USA',
            'CA-05, USA',
            'CA-06, USA',
            'CA-07, USA',
            'CA-08, USA',
            'CA-09, USA',
            'CA-10, USA',
            'CA-11, USA',
            'CA-12, USA',
            'CA-13, USA',
            'CA-14, USA',
            'CA-15, USA',
            'CA-16, USA',
            'CA-17, USA',
            'CA-18, USA',
            'CA-19, USA',
            'CA-20, USA',
            'CA-21, USA',
            'CA-22, USA',
            'CA-23, USA',
            'CA-24, USA',
            'CA-25, USA',
            'CA-26, USA',
            'CA-27, USA',
            'CA-28, USA',
            'CA-29, USA',
            'CA-30, USA',
            'CA-31, USA',
            'CA-32, USA',
            'CA-33, USA',
            'CA-34, USA',
            'CA-35, USA',
            'CA-36, USA',
            'CA-37, USA',
            'CA-38, USA',
            'CA-39, USA',
            'CA-40, USA',
            'CA-41, USA',
            'CA-42, USA',
            'CA-43, USA',
            'CA-44, USA',
            'CA-45, USA',
            'CA-46, USA',
            'CA-47, USA',
            'CA-48, USA',
            'CA-49, USA',
            'CA-50, USA',
            'CA-51, USA',
            'CA-52, USA',
            'CO-01, USA',
            'CO-02, USA',
            'CO-03, USA',
            'CO-04, USA',
            'CO-05, USA',
            'CO-06, USA',
            'CO-07, USA',
            'CO-08, USA',
            'CT-01, USA',
            'CT-02, USA',
            'CT-03, USA',
            'CT-04, USA',
            'CT-05, USA',
            'Cairo Urban Center, Egypt',
            'Calgary CMA, AB, Canada',
            'Calgary City, Division No. 6, Alberta, Canada',
            'Calgary Population Center, AB, Canada',
            'Calgary Urban Center, Canada',
            'Cali Urban Center, Colombia',
            'California, USA',
            'Cambodia',
            'Camden County, New Jersey, USA',
            'Cameron County, Texas, USA',
            'Cameroon',
            'Campania, Italy',
            'Canada',
            'Canton [Urban Area], OH, USA',
            'Canton-Massillon MSA, OH, USA',
            'Cape Coral Urban Center, USA',
            'Cape Coral [Urban Area], FL, USA',
            'Cape Coral-Fort Myers MSA, FL, USA',
            'Cape Town Urban Center, South Africa',
            'Capital Regional district, British Columbia, Canada',
            'Capitol Planning Region, Connecticut, USA',
            'Caracas Urban Center, Venezuela',
            'Casablanca Urban Center, Morocco',
            'Casablanca-Settat, Morocco',
            'Catalunya, Spain',
            'Cavite, Philippines',
            'Cear\u00e1, Brazil',
            'Cebu City Urban Center, Philippines',
            'Cebu, Philippines',
            'Cedar Rapids MSA, IA, USA',
            'Cedar Rapids-Waterloo-Iowa City & Dubuque IA Media Market, USA',
            'Central African Republic',
            'Central, Ghana',
            'Centre, Burkina Faso',
            'Centre, Cameroon',
            'Centre-Val de Loire, France',
            'Chad',
            'Champaign & Springfield-Decatur IL Media Market, USA',
            'Chandler city, Arizona, USA',
            'Changchun Urban Center, China',
            'Changsha Urban Center, China',
            'Changzhou Urban Center, China',
            'Charleston County, South Carolina, USA',
            'Charleston SC Media Market, USA',
            'Charleston [Urban Area], SC, USA',
            'Charleston-Huntington WV Media Market, USA',
            'Charleston-North Charleston MSA, SC, USA',
            'Charlotte NC Media Market, USA',
            'Charlotte Urban Center, USA',
            'Charlotte [Urban Area], NC-SC, USA',
            'Charlotte city, North Carolina, USA',
            'Charlotte-Concord-Gastonia MSA, NC-SC, USA',
            'Chatham County, Georgia, USA',
            'Chattanooga MSA, TN-GA, USA',
            'Chattanooga TN Media Market, USA',
            'Chattanooga [Urban Area], TN-GA, USA',
            'Chattogram Urban Center, Bangladesh',
            'Chattogram, Bangladesh',
            'Chelyabinskaya oblast\', Russia',
            'Chengdu Urban Center, China',
            'Chennai Urban Center, India',
            'Cherokee County, Georgia, USA',
            'Chester County, Pennsylvania, USA',
            'Chesterfield County, Virginia, USA',
            'Chhatt\u012bsgarh, India',
            'Chiapas, Mexico',
            'Chicago IL Media Market, USA',
            'Chicago Urban Center, USA',
            'Chicago [Urban Area], IL-IN, USA',
            'Chicago city, Illinois, USA',
            'Chicago-Naperville-Elgin MSA, IL-IN-WI, USA',
            'Chico-Redding CA Media Market, USA',
            'Chihuahua, Mexico',
            'Chile',
            'China',
            'Chongqing Shi, China',
            'Chongqing Urban Center, China',
            'Chula Vista city, California, USA',
            'Cincinnati MSA, OH-KY-IN, USA',
            'Cincinnati OH Media Market, USA',
            'Cincinnati Urban Center, USA',
            'Cincinnati [Urban Area], OH-KY, USA',
            'Cincinnati city, Ohio, USA',
            'Ciudad Ju\u00e1rez Urban Center, Mexico-USA',
            'Ciudad de M\u00e9xico, Mexico',
            'Clackamas County, Oregon, USA',
            'Clark County, Nevada, USA',
            'Clark County, Washington, USA',
            'Clarksburg-Weston WV Media Market, USA',
            'Clarksville MSA, TN-KY, USA',
            'Clay County, Missouri, USA',
            'Clayton County, Georgia, USA',
            'Cleveland County, Oklahoma, USA',
            'Cleveland Urban Center, USA',
            'Cleveland [Urban Area], OH, USA',
            'Cleveland city, Ohio, USA',
            'Cleveland-Akron (Canton) OH Media Market, USA',
            'Cleveland-Elyria MSA, OH, USA',
            'Coahuila de Zaragoza, Mexico',
            'Cobb County, Georgia, USA',
            'College Station-Bryan MSA, TX, USA',
            'Collier County, Florida, USA',
            'Collin County, Texas, USA',
            'Colombia',
            'Colombo Urban Center, Sri Lanka',
            'Colorado Springs MSA, CO, USA',
            'Colorado Springs Urban Center, USA',
            'Colorado Springs [Urban Area], CO, USA',
            'Colorado Springs city, Colorado, USA',
            'Colorado Springs-Pueblo CO Media Market, USA',
            'Colorado, USA',
            'Columbia MSA, SC, USA',
            'Columbia SC Media Market, USA',
            'Columbia [Urban Area], SC, USA',
            'Columbia-Jefferson City MO Media Market, USA',
            'Columbus (Ohio) Urban Center, USA',
            'Columbus GA Media Market, USA',
            'Columbus MSA, GA-AL, USA',
            'Columbus MSA, OH, USA',
            'Columbus OH Media Market, USA',
            'Columbus [Urban Area], GA-AL, USA',
            'Columbus [Urban Area], OH, USA',
            'Columbus city, Ohio, USA',
            'Columbus-Tupelo-West Point MS Media Market, USA',
            'Comilla Urban Center, Bangladesh',
            'Conakry Urban Center, Guinea',
            'Concord Urban Center, USA',
            'Concord [Urban Area], NC, USA',
            'Concord-Walnut Creek [Urban Area], CA, USA',
            'Congo',
            'Connecticut, USA',
            'Contra Costa County, California, USA',
            'Cook County, Illinois, USA',
            'Copperbelt, Zambia',
            'Corpus Christi MSA, TX, USA',
            'Corpus Christi TX Media Market, USA',
            'Corpus Christi [Urban Area], TX, USA',
            'Corpus Christi city, Texas, USA',
            'Costa Rica',
            'Crestview-Fort Walton Beach-Destin MSA, FL, USA',
            'Croatia',
            'Cross River, Nigeria',
            'Cuba',
            'Cumberland County, Maine, USA',
            'Cumberland County, North Carolina, USA',
            'Cumberland County, Pennsylvania, USA',
            'Cundinamarca, Colombia',
            'Cuyahoga County, Ohio, USA',
            'Czech Republic',
            'C\u00f3rdoba, Argentina',
            'DE-00, USA',
            'Dagestan, Respublika, Russia',
            'Dakar Urban Center, Senegal',
            'Dakar, Senegal',
            'Dakota County, Minnesota, USA',
            'Daku\u1e47u pa\u1e37\u0101ta, Sri Lanka',
            'Dalian Urban Center, China',
            'Dallas County, Texas, USA',
            'Dallas Urban Center, USA',
            'Dallas city, Texas, USA',
            'Dallas-Fort Worth-Arlington MSA, TX, USA',
            'Dallas-Fort Worth-Arlington [Urban Area], TX, USA',
            'Dallas-Ft.Worth TX Media Market, USA',
            'Damascus Urban Center, Syria',
            'Dane County, Wisconsin, USA',
            'Dar es Salaam Urban Center, Tanzania',
            'Dar es Salaam, Tanzania',
            'Dauphin County, Pennsylvania, USA',
            'Davao del Sur, Philippines',
            'Davenport IA-Rock Island-Moline IL Media Market, USA',
            'Davenport [Urban Area], IA-IL, USA',
            'Davenport-Moline-Rock Island MSA, IA-IL, USA',
            'Davidson County, Tennessee, USA',
            'Davis County, Utah, USA',
            'Dayton MSA, OH, USA',
            'Dayton OH Media Market, USA',
            'Dayton Urban Center, USA',
            'Dayton [Urban Area], OH, USA',
            'Daytona Beach-Palm Coast-Port Orange [Urban Area], FL, USA',
            'DeKalb County, Georgia, USA',
            'Delaware County, Pennsylvania, USA',
            'Delaware, USA',
            'Delhi [New Delhi] Urban Center, India',
            'Delhi, India',
            'Delta, Nigeria',
            'Deltona-Daytona Beach-Ormond Beach MSA, FL, USA',
            'Democratic Republic of the Congo',
            'Denmark',
            'Denton County, Texas, USA',
            'Denton-Lewisville [Urban Area], TX, USA',
            'Denver CO Media Market, USA',
            'Denver County, Colorado, USA',
            'Denver Urban Center, USA',
            'Denver city, Colorado, USA',
            'Denver-Aurora [Urban Area], CO, USA',
            'Denver-Aurora-Lakewood MSA, CO, USA',
            'Des Moines Urban Center, USA',
            'Des Moines [Urban Area], IA, USA',
            'Des Moines-Ames IA Media Market, USA',
            'Des Moines-West Des Moines MSA, IA, USA',
            'Detroit MI Media Market, USA',
            'Detroit Urban Center, Canada-USA',
            'Detroit [Urban Area], MI, USA',
            'Detroit city, Michigan, USA',
            'Detroit-Warren-Dearborn MSA, MI, USA',
            'Dhaka Urban Center, Bangladesh',
            'Dhaka, Bangladesh',
            'District of Columbia, District of Columbia, USA',
            'District of Columbia, USA',
            'Distrito Capital de Bogot\u00e1, Colombia',
            'Distrito Federal, Brazil',
            'Division No. 1, Newfoundland and Labrador, Canada',
            'Division No. 11, Alberta, Canada',
            'Division No. 11, Manitoba, Canada',
            'Division No. 11, Saskatchewan, Canada',
            'Division No. 6, Alberta, Canada',
            'Division No. 6, Saskatchewan, Canada',
            'Dnipropetrovska oblast, Ukraine',
            'Dodoma, Tanzania',
            'Dolno\u015bl\u0105skie, Poland',
            'Dominican Republic',
            'Donets\'ka oblast, Ukraine',
            'Dortmund Urban Center, Germany',
            'Dosso, Niger',
            'Dothan AL Media Market, USA',
            'Douala Urban Center, Cameroon',
            'Douglas County, Colorado, USA',
            'Douglas County, Nebraska, USA',
            'DuPage County, Illinois, USA',
            'Dubai Urban Center, United Arab Emirates',
            'Dubayy, United Arab Emirates',
            'Duluth MN-Superior WI Media Market, USA',
            'Duluth MSA, MN-WI, USA',
            'Durban Urban Center, South Africa',
            'Durham County, North Carolina, USA',
            'Durham Regional municipality, Ontario, Canada',
            'Durham [Urban Area], NC, USA',
            'Durham city, North Carolina, USA',
            'Durham-Chapel Hill MSA, NC, USA',
            'Dutchess County, New York, USA',
            'Duval County, Florida, USA',
            'East Baton Rouge Parish, Louisiana, USA',
            'Eastern Cape, South Africa',
            'Eastern Equatoria, South Sudan',
            'Eastern, Ghana',
            'Ebonyi, Nigeria',
            'Ecuador',
            'Edmonton CMA, AB, Canada',
            'Edmonton City, Division No. 11, Alberta, Canada',
            'Edmonton Population Center, AB, Canada',
            'Edmonton Urban Center, Canada',
            'Edo, Nigeria',
            'Egypt',
            'Ekiti, Nigeria',
            'El Paso County, Colorado, USA',
            'El Paso County, Texas, USA',
            'El Paso MSA, TX, USA',
            'El Paso TX (Las Cruces NM) Media Market, USA',
            'El Paso [Urban Area], TX-NM, USA',
            'El Paso city, Texas, USA',
            'El Salvador',
            'Emilia-Romagna, Italy',
            'England, United Kingdom',
            'Enugu, Nigeria',
            'Erie County, New York, USA',
            'Erie County, Pennsylvania, USA',
            'Erie MSA, PA, USA',
            'Erie PA Media Market, USA',
            'Eritrea',
            'Escambia County, Florida, USA',
            'Esp\u00edrito Santo, Brazil',
            'Essex County, Massachusetts, USA',
            'Essex County, New Jersey, USA',
            'Essex County, Ontario, Canada',
            'Est, Rwanda',
            'Ethiopia',
            'Eugene MSA, OR, USA',
            'Eugene OR Media Market, USA',
            'Eugene [Urban Area], OR, USA',
            'Evansville IN Media Market, USA',
            'Evansville MSA, IN-KY, USA',
            'E\u015ffah\u0101n, Iran',
            'FL-01, USA',
            'FL-02, USA',
            'FL-03, USA',
            'FL-04, USA',
            'FL-05, USA',
            'FL-06, USA',
            'FL-07, USA',
            'FL-08, USA',
            'FL-09, USA',
            'FL-10, USA',
            'FL-11, USA',
            'FL-12, USA',
            'FL-13, USA',
            'FL-14, USA',
            'FL-15, USA',
            'FL-16, USA',
            'FL-17, USA',
            'FL-18, USA',
            'FL-19, USA',
            'FL-20, USA',
            'FL-21, USA',
            'FL-22, USA',
            'FL-23, USA',
            'FL-24, USA',
            'FL-25, USA',
            'FL-26, USA',
            'FL-27, USA',
            'FL-28, USA',
            'Fairfax County, Virginia, USA',
            'Faisalabad Urban Center, Pakistan',
            'Far North, Cameroon',
            'Farg\'ona, Uzbekistan',
            'Fargo-Valley City ND Media Market, USA',
            'Fayette County, Kentucky, USA',
            'Fayetteville MSA, NC, USA',
            'Fayetteville [Urban Area], NC, USA',
            'Fayetteville-Springdale-Rogers MSA, AR-MO, USA',
            'Fayetteville-Springdale-Rogers [Urban Area], AR-MO, USA',
            'Finland',
            'Flint MSA, MI, USA',
            'Flint [Urban Area], MI, USA',
            'Flint-Saginaw-Bay City MI Media Market, USA',
            'Florida, USA',
            'Forsyth County, Georgia, USA',
            'Forsyth County, North Carolina, USA',
            'Fort Bend County, Texas, USA',
            'Fort Collins MSA, CO, USA',
            'Fort Collins [Urban Area], CO, USA',
            'Fort Smith MSA, AR-OK, USA',
            'Fort Wayne MSA, IN, USA',
            'Fort Wayne [Urban Area], IN, USA',
            'Fort Wayne city, Indiana, USA',
            'Fort Worth city, Texas, USA',
            'Fortaleza Urban Center, Brazil',
            'France',
            'Franklin County, Ohio, USA',
            'Fraser Valley Regional district, British Columbia, Canada',
            'Frederick County, Maryland, USA',
            'Free State, South Africa',
            'Fresno County, California, USA',
            'Fresno MSA, CA, USA',
            'Fresno Urban Center, USA',
            'Fresno [Urban Area], CA, USA',
            'Fresno city, California, USA',
            'Fresno-Visalia CA Media Market, USA',
            'Ft. Myers-Naples FL Media Market, USA',
            'Ft. Smith-Fayetteville-Springdale-Rogers AR Media Market, USA',
            'Ft. Wayne IN Media Market, USA',
            'Fujian Sheng, China',
            'Fulton County, Georgia, USA',
            'Fuzhou (Fujian Sheng) Urban Center, China',
            'F\u00e8s-Mekn\u00e8s, Morocco',
            'F\u0101rs, Iran',
            'GA-01, USA',
            'GA-02, USA',
            'GA-03, USA',
            'GA-04, USA',
            'GA-05, USA',
            'GA-06, USA',
            'GA-07, USA',
            'GA-08, USA',
            'GA-09, USA',
            'GA-10, USA',
            'GA-11, USA',
            'GA-12, USA',
            'GA-13, USA',
            'GA-14, USA',
            'Gainesville FL Media Market, USA',
            'Gainesville MSA, FL, USA',
            'Gainesville [Urban Area], GA, USA',
            'Galveston County, Texas, USA',
            'Gansu Sheng, China',
            'Gatineau Territory, Quebec, Canada',
            'Gatineau Ville, Gatineau Territory, Quebec, Canada',
            'Gauteng, South Africa',
            'Geita, Tanzania',
            'Genesee County, Michigan, USA',
            'Georgia',
            'Georgia, USA',
            'Germany',
            'Ghana',
            'Gilbert town, Arizona, USA',
            'Gloucester County, New Jersey, USA',
            'Goi\u00e1s, Brazil',
            'Gombe, Nigeria',
            'Grand Est, France',
            'Grand Rapids Urban Center, USA',
            'Grand Rapids [Urban Area], MI, USA',
            'Grand Rapids-Kalamazoo-Battle Creek MI Media Market, USA',
            'Grand Rapids-Wyoming MSA, MI, USA',
            'Greater Accra, Ghana',
            'Greater Bridgeport Planning Region, Connecticut, USA',
            'Greater Vancouver Regional district, British Columbia, Canada',
            'Greece',
            'Greeley MSA, CO, USA',
            'Green Bay MSA, WI, USA',
            'Green Bay-Appleton WI Media Market, USA',
            'Greene County, Missouri, USA',
            'Greensboro [Urban Area], NC, USA',
            'Greensboro city, North Carolina, USA',
            'Greensboro-High Point MSA, NC, USA',
            'Greensboro-High Point-Winston Salem NC Media Market, USA',
            'Greenville County, South Carolina, USA',
            'Greenville [Urban Area], SC, USA',
            'Greenville-Anderson-Mauldin MSA, SC, USA',
            'Greenville-New Bern-Washington NC Media Market, USA',
            'Greenville-Spartanburg SC-Asheville NC-Anderson SC Media Market, USA',
            'Guadalajara Urban Center, Mexico',
            'Guanajuato, Mexico',
            'Guangdong Sheng, China',
            'Guangxi Zhuangzu Zizhiqu, China',
            'Guangzhou Urban Center, China',
            'Guatemala',
            'Guatemala City Urban Center, Guatemala',
            'Guatemala, Guatemala',
            'Guayaquil Urban Center, Ecuador',
            'Guayas, Ecuador',
            'Guerrero, Mexico',
            'Guilford County, North Carolina, USA',
            'Guinea',
            'Guiyang (Guizhou Sheng) Urban Center, China',
            'Guizhou Sheng, China',
            'Gujar\u0101t, India',
            'Gujranwala Urban Center, Pakistan',
            'Gulfport-Biloxi-Pascagoula MSA, MS, USA',
            'Gwinnett County, Georgia, USA',
            'Gyeonggi-do, South Korea',
            'Gyeongsangbuk-do, South Korea',
            'Gyeongsangnam-do, South Korea',
            'HI-01, USA',
            'HI-02, USA',
            'HaMerkaz, Israel',
            'Hagerstown-Martinsburg MSA, MD-WV, USA',
            'Hainan Sheng, China',
            'Haiti',
            'Halifax CMA, NS, Canada',
            'Halifax County, Nova Scotia, Canada',
            'Halifax Population Center, NS, Canada',
            'Halifax Regional Municipality, Halifax County, Nova Scotia, Canada',
            'Halton Regional municipality, Ontario, Canada',
            'Hamgy\u014fng-namdo [Hamkyeongnamto], North Korea',
            'Hamilton CDR, Ontario, Canada',
            'Hamilton CMA, ON, Canada',
            'Hamilton City, Hamilton CDR, Ontario, Canada',
            'Hamilton County, Indiana, USA',
            'Hamilton County, Ohio, USA',
            'Hamilton County, Tennessee, USA',
            'Hamilton Population Center, ON, Canada',
            'Hampden County, Massachusetts, USA',
            'Hangzhou Urban Center, China',
            'Hanoi Urban Center, Vietnam',
            'Harbin Urban Center, China',
            'Harford County, Maryland, USA',
            'Harlingen-Weslaco-Brownsville-Mcallen TX Media Market, USA',
            'Harris County, Texas, USA',
            'Harrisburg [Urban Area], PA, USA',
            'Harrisburg-Carlisle MSA, PA, USA',
            'Harrisburg-Lancaster-Lebanon-York PA Media Market, USA',
            'Harrisonburg VA Media Market, USA',
            'Hartford & New Haven CT Media Market, USA',
            'Hartford Urban Center, USA',
            'Hartford [Urban Area], CT, USA',
            'Hartford-West Hartford-East Hartford MSA, CT, USA',
            'Hary\u0101na, India',
            'Hattiesburg-Laurel MS Media Market, USA',
            'Haut-Katanga, Democratic Republic of the Congo',
            'Haut-Lomami, Democratic Republic of the Congo',
            'Hauts-Bassins, Burkina Faso',
            'Hauts-de-France, France',
            'Hawaii, USA',
            'Hebei Sheng, China',
            'Hefei Urban Center, China',
            'Heilongjiang Sheng, China',
            'Henan Sheng, China',
            'Henderson city, Nevada, USA',
            'Hennepin County, Minnesota, USA',
            'Henrico County, Virginia, USA',
            'Heroica Nogales Urban Center, Mexico-USA',
            'Her\u0101t, Afghanistan',
            'Hessen, Germany',
            'Hickory-Lenoir-Morganton MSA, NC, USA',
            'Hidalgo County, Texas, USA',
            'Hidalgo, Mexico',
            'Hillsborough County, Florida, USA',
            'Hillsborough County, New Hampshire, USA',
            'Him\u0101chal Pradesh, India',
            'Hirosima, Japan',
            'Ho Chi Minh City Urban Center, Vietnam',
            'Hokkaid\u00f4, Japan',
            'Honduras',
            'Hong Kong Urban Center, China',
            'Hong Kong, China',
            'Honolulu County, Hawaii, USA',
            'Honolulu HI Media Market, USA',
            'Honolulu Urban Center, USA',
            'Honolulu [Urban Area], HI, USA',
            'Horry County, South Carolina, USA',
            'Houston TX Media Market, USA',
            'Houston Urban Center, USA',
            'Houston [Urban Area], TX, USA',
            'Houston city, Texas, USA',
            'Houston-The Woodlands-Sugar Land MSA, TX, USA',
            'Howard County, Maryland, USA',
            'Hubei Sheng, China',
            'Hudson County, New Jersey, USA',
            'Hukuoka, Japan',
            'Hunan Sheng, China',
            'Hungary',
            'Huntington-Ashland MSA, WV-KY-OH, USA',
            'Huntsville MSA, AL, USA',
            'Huntsville [Urban Area], AL, USA',
            'Huntsville-Decatur (Florence) AL Media Market, USA',
            'Hu\u00edla, Angola',
            'Hwanghae-namdo [Hwanghainamto], North Korea',
            'Hyderabad Urban Center, India',
            'Hyderabad Urban Center, Pakistan',
            'Hy\u00f4go, Japan',
            'H\u00e0 N\u1ed9i, Vietnam',
            'H\u1ed3 Ch\u00ed Minh, Vietnam',
            'IA-01, USA',
            'IA-02, USA',
            'IA-03, USA',
            'IA-04, USA',
            'ID-01, USA',
            'ID-02, USA',
            'IL-01, USA',
            'IL-02, USA',
            'IL-03, USA',
            'IL-04, USA',
            'IL-05, USA',
            'IL-06, USA',
            'IL-07, USA',
            'IL-08, USA',
            'IL-09, USA',
            'IL-10, USA',
            'IL-11, USA',
            'IL-12, USA',
            'IL-13, USA',
            'IL-14, USA',
            'IL-15, USA',
            'IL-16, USA',
            'IL-17, USA',
            'IN-01, USA',
            'IN-02, USA',
            'IN-03, USA',
            'IN-04, USA',
            'IN-05, USA',
            'IN-06, USA',
            'IN-07, USA',
            'IN-08, USA',
            'IN-09, USA',
            'Ibadan Urban Center, Nigeria',
            'Ibaraki, Japan',
            'Ibb, Yemen',
            'Idaho Falls-Pocatello ID Media Market, USA',
            'Idaho, USA',
            'Illinois, USA',
            'Iloilo, Philippines',
            'Imo, Nigeria',
            'Incheon-gwangyeoksi, South Korea',
            'India',
            'Indiana, USA',
            'Indianapolis IN Media Market, USA',
            'Indianapolis Urban Center, USA',
            'Indianapolis [Urban Area], IN, USA',
            'Indianapolis city (balance), Indiana, USA',
            'Indianapolis-Carmel-Anderson MSA, IN, USA',
            'Indio Urban Center, USA',
            'Indio-Palm Desert-Palm Springs [Urban Area], CA, USA',
            'Indonesia',
            'Indore Urban Center, India',
            'Ingham County, Michigan, USA',
            'Iowa, USA',
            'Iran',
            'Iraq',
            'Ireland',
            'Irvine city, California, USA',
            'Irving city, Texas, USA',
            'Israel',
            'Istanbul Urban Center, Turkey',
            'Italy',
            'Ituri, Democratic Republic of the Congo',
            'Ivory Coast',
            'Izmir Urban Center, Turkey',
            'Jackson County, Missouri, USA',
            'Jackson MS Media Market, USA',
            'Jackson MSA, MS, USA',
            'Jackson [Urban Area], MS, USA',
            'Jacksonville FL Media Market, USA',
            'Jacksonville MSA, FL, USA',
            'Jacksonville Urban Center, USA',
            'Jacksonville [Urban Area], FL, USA',
            'Jacksonville city, Florida, USA',
            'Jaipur Urban Center, India',
            'Jakarta Raya, Indonesia',
            'Jakarta Urban Center, Indonesia',
            'Jalisco, Mexico',
            'Jamaica',
            'Jambi, Indonesia',
            'Jammu and Kashm\u012br, India',
            'Jan\u016bb D\u0101rf\u016br, Sudan',
            'Japan',
            'Jawa Barat, Indonesia',
            'Jawa Tengah, Indonesia',
            'Jawa Timur, Indonesia',
            'Jeddah Urban Center, Saudi Arabia',
            'Jefferson County, Alabama, USA',
            'Jefferson County, Colorado, USA',
            'Jefferson County, Kentucky, USA',
            'Jefferson County, Texas, USA',
            'Jefferson Parish, Louisiana, USA',
            'Jersey City city, New Jersey, USA',
            'Jh\u0101rkhand, India',
            'Jiangsu Sheng, China',
            'Jiangxi Sheng, China',
            'Jieyang Urban Center, China',
            'Jigawa, Nigeria',
            'Jilin Sheng, China',
            'Jinan Urban Center, China',
            'Johannesburg Urban Center, South Africa',
            'Johnson County, Kansas, USA',
            'Johnstown-Altoona PA Media Market, USA',
            'Johor, Malaysia',
            'Joplin MO-Pittsburg KS Media Market, USA',
            'Jordan',
            'KS-01, USA',
            'KS-02, USA',
            'KS-03, USA',
            'KS-04, USA',
            'KY-01, USA',
            'KY-02, USA',
            'KY-03, USA',
            'KY-04, USA',
            'KY-05, USA',
            'KY-06, USA',
            'Kabul Urban Center, Afghanistan',
            'Kaduna, Nigeria',
            'Kafr ash Shaykh, Egypt',
            'Kagera, Tanzania',
            'Kalamazoo County, Michigan, USA',
            'Kalamazoo-Portage MSA, MI, USA',
            'Kalimantan Barat, Indonesia',
            'Kalimantan Selatan, Indonesia',
            'Kalimantan Tengah, Indonesia',
            'Kalimantan Timur, Indonesia',
            'Kampala Urban Center, Uganda',
            'Kanagawa, Japan',
            'Kane County, Illinois, USA',
            'Kankan, Guinea',
            'Kano Urban Center, Nigeria',
            'Kano, Nigeria',
            'Kanpur Urban Center, India',
            'Kansas City MO Media Market, USA',
            'Kansas City MSA, MO-KS, USA',
            'Kansas City Urban Center, USA',
            'Kansas City [Urban Area], MO-KS, USA',
            'Kansas City city, Missouri, USA',
            'Kansas, USA',
            'Karachi Urban Center, Pakistan',
            'Karn\u0101taka, India',
            'Kasa\u00ef Central, Democratic Republic of the Congo',
            'Kasa\u00ef Oriental, Democratic Republic of the Congo',
            'Kasa\u00ef, Democratic Republic of the Congo',
            'Kathmandu Urban Center, Nepal',
            'Katsina, Nigeria',
            'Kayes, Mali',
            'Kazakhstan',
            'Kebbi, Nigeria',
            'Kemerovskaya oblast\', Russia',
            'Kennewick-Richland MSA, WA, USA',
            'Kennewick-Richland-Pasco [Urban Area], WA, USA',
            'Kent County, Michigan, USA',
            'Kentucky, USA',
            'Kenya',
            'Kerala, India',
            'Kerm\u0101n, Iran',
            'Kern County, California, USA',
            'Kharkivska oblast, Ukraine',
            'Khartoum Urban Center, Sudan',
            'Khatlon, Tajikistan',
            'Khor\u0101s\u0101n-e Ra\u1e95av\u012b, Iran',
            'Khulna, Bangladesh',
            'Khyber Pakhtunkhwa, Pakistan',
            'Kh\u016bzest\u0101n, Iran',
            'Kigoma, Tanzania',
            'Killeen [Urban Area], TX, USA',
            'Killeen-Temple MSA, TX, USA',
            'Kindia, Guinea',
            'King County, Washington, USA',
            'Kings County, New York, USA',
            'Kingsport-Bristol-Bristol MSA, TN-VA, USA',
            'Kinshasa Urban Center, Democratic Republic of the Congo',
            'Kinshasa, Democratic Republic of the Congo',
            'Kissimmee-St. Cloud [Urban Area], FL, USA',
            'Kitchener - Cambridge - Waterloo CMA, ON, Canada',
            'Kitchener City, Waterloo Regional municipality, Ontario, Canada',
            'Kitchener Population Center, ON, Canada',
            'Kitchener Urban Center, Canada',
            'Kitsap County, Washington, USA',
            'Knox County, Tennessee, USA',
            'Knoxville MSA, TN, USA',
            'Knoxville TN Media Market, USA',
            'Knoxville [Urban Area], TN, USA',
            'Kochi Urban Center, India',
            'Kogi, Nigeria',
            'Kolkata Urban Center, India',
            'Kollam Urban Center, India',
            'Kongo Central, Democratic Republic of the Congo',
            'Koshi, Nepal',
            'Koulikoro, Mali',
            'Kozhikode Urban Center, India',
            'Krasnodyarskiy kray, Russia',
            'Krasnoyarskiy kray, Russia',
            'Krung Thep Maha Nakhon, Thailand',
            'Kuala Lumpur Urban Center, Malaysia',
            'Kumasi Urban Center, Ghana',
            'Kunming Urban Center, China',
            'Kuwait',
            'Kuwait City Urban Center, Kuwait',
            'Kwara, Nigeria',
            'Kwazulu-Natal, South Africa',
            'Kwilu, Democratic Republic of the Congo',
            'Kyiv Urban Center, Ukraine',
            'Kyiv, Ukraine',
            'Kyrgyzstan',
            'K\u0101bul, Afghanistan',
            'LA-01, USA',
            'LA-02, USA',
            'LA-03, USA',
            'LA-04, USA',
            'LA-05, USA',
            'LA-06, USA',
            'La Crosse-Eau Claire WI Media Market, USA',
            'La Paz, Bolivia',
            'Lafayette LA Media Market, USA',
            'Lafayette MSA, LA, USA',
            'Lagos Urban Center, Nigeria',
            'Lagos, Nigeria',
            'Laguna, Philippines',
            'Lahore Urban Center, Pakistan',
            'Lake Charles LA Media Market, USA',
            'Lake County, Florida, USA',
            'Lake County, Illinois, USA',
            'Lake County, Indiana, USA',
            'Lakeland [Urban Area], FL, USA',
            'Lakeland-Winter Haven MSA, FL, USA',
            'Lampung, Indonesia',
            'Lancaster County, Nebraska, USA',
            'Lancaster County, Pennsylvania, USA',
            'Lancaster MSA, PA, USA',
            'Lancaster-Manheim [Urban Area], PA, USA',
            'Lane County, Oregon, USA',
            'Lansing MI Media Market, USA',
            'Lansing [Urban Area], MI, USA',
            'Lansing-East Lansing MSA, MI, USA',
            'Lanzhou Urban Center, China',
            'Laos',
            'Laredo MSA, TX, USA',
            'Laredo TX Media Market, USA',
            'Laredo [Urban Area], TX, USA',
            'Laredo city, Texas, USA',
            'Larimer County, Colorado, USA',
            'Las Vegas NV Media Market, USA',
            'Las Vegas Urban Center, USA',
            'Las Vegas city, Nevada, USA',
            'Las Vegas-Henderson-Paradise MSA, NV, USA',
            'Las Vegas-Henderson-Paradise [Urban Area], NV, USA',
            'Laval Territory, Quebec, Canada',
            'Laval Ville, Laval Territory, Quebec, Canada',
            'Lazio, Italy',
            'Lebanon',
            'Lee County, Florida, USA',
            'Lehigh County, Pennsylvania, USA',
            'Leinster, Ireland',
            'Leon County, Florida, USA',
            'Lexington County, South Carolina, USA',
            'Lexington KY Media Market, USA',
            'Lexington Urban Center, USA',
            'Lexington-Fayette MSA, KY, USA',
            'Lexington-Fayette [Urban Area], KY, USA',
            'Lexington-Fayette urban county, Kentucky, USA',
            'Liaoning Sheng, China',
            'Liberia',
            'Libya',
            'Lilongwe, Malawi',
            'Lima Urban Center, Peru',
            'Limpopo, South Africa',
            'Lincoln & Hastings-Kearney NE Media Market, USA',
            'Lincoln MSA, NE, USA',
            'Lincoln [Urban Area], NE, USA',
            'Lincoln city, Nebraska, USA',
            'Lithuania',
            'Little Rock [Urban Area], AR, USA',
            'Little Rock-North Little Rock-Conway MSA, AR, USA',
            'Little Rock-Pine Bluff AR Media Market, USA',
            'Littoral, Cameroon',
            'Lomami, Democratic Republic of the Congo',
            'Lombardia, Italy',
            'London CMA, ON, Canada',
            'London City, Middlesex County, Ontario, Canada',
            'London Population Center, ON, Canada',
            'London Urban Center, Canada',
            'London Urban Center, United Kingdom',
            'Long Beach city, California, USA',
            'Longueuil Territory, Quebec, Canada',
            'Longueuil Ville, Longueuil Territory, Quebec, Canada',
            'Lorain County, Ohio, USA',
            'Los Angeles CA Media Market, USA',
            'Los Angeles County, California, USA',
            'Los Angeles Urban Center, USA',
            'Los Angeles city, California, USA',
            'Los Angeles-Long Beach-Anaheim MSA, CA, USA',
            'Los Angeles-Long Beach-Anaheim [Urban Area], CA, USA',
            'Loudoun County, Virginia, USA',
            'Louisiana, USA',
            'Louisville KY Media Market, USA',
            'Louisville Urban Center, USA',
            'Louisville/Jefferson County MSA, KY-IN, USA',
            'Louisville/Jefferson County [Urban Area], KY-IN, USA',
            'Louisville/Jefferson County metro government (balance), Kentucky, USA',
            'Lualaba, Democratic Republic of the Congo',
            'Luanda Urban Center, Angola',
            'Luanda, Angola',
            'Lubbock County, Texas, USA',
            'Lubbock MSA, TX, USA',
            'Lubbock TX Media Market, USA',
            'Lubbock [Urban Area], TX, USA',
            'Lubbock city, Texas, USA',
            'Lucas County, Ohio, USA',
            'Lucknow Urban Center, India',
            'Lumbin\u012b, Nepal',
            'Lusaka Urban Center, Zambia',
            'Lusaka, Zambia',
            'Luzerne County, Pennsylvania, USA',
            'Lynchburg MSA, VA, USA',
            'MA-01, USA',
            'MA-02, USA',
            'MA-03, USA',
            'MA-04, USA',
            'MA-05, USA',
            'MA-06, USA',
            'MA-07, USA',
            'MA-08, USA',
            'MA-09, USA',
            'MD-01, USA',
            'MD-02, USA',
            'MD-03, USA',
            'MD-04, USA',
            'MD-05, USA',
            'MD-06, USA',
            'MD-07, USA',
            'MD-08, USA',
            'ME-01, USA',
            'ME-02, USA',
            'MI-01, USA',
            'MI-02, USA',
            'MI-03, USA',
            'MI-04, USA',
            'MI-05, USA',
            'MI-06, USA',
            'MI-07, USA',
            'MI-08, USA',
            'MI-09, USA',
            'MI-10, USA',
            'MI-11, USA',
            'MI-12, USA',
            'MI-13, USA',
            'MN-01, USA',
            'MN-02, USA',
            'MN-03, USA',
            'MN-04, USA',
            'MN-05, USA',
            'MN-06, USA',
            'MN-07, USA',
            'MN-08, USA',
            'MO-01, USA',
            'MO-02, USA',
            'MO-03, USA',
            'MO-04, USA',
            'MO-05, USA',
            'MO-06, USA',
            'MO-07, USA',
            'MO-08, USA',
            'MS-01, USA',
            'MS-02, USA',
            'MS-03, USA',
            'MS-04, USA',
            'MT-01, USA',
            'MT-02, USA',
            'Macomb County, Michigan, USA',
            'Macon GA Media Market, USA',
            'Madagascar',
            'Madhesh, Nepal',
            'Madhya Pradesh, India',
            'Madhyama pa\u1e37\u0101ta, Sri Lanka',
            'Madison County, Alabama, USA',
            'Madison County, Illinois, USA',
            'Madison MSA, WI, USA',
            'Madison Urban Center, USA',
            'Madison WI Media Market, USA',
            'Madison [Urban Area], WI, USA',
            'Madison city, Wisconsin, USA',
            'Madrid Urban Center, Spain',
            'Madrid, Comunidad de, Spain',
            'Magway, Myanmar',
            'Mah\u0101r\u0101shtra, India',
            'Maine, USA',
            'Makkah al Mukarramah, Saudi Arabia',
            'Malawi',
            'Malaysia',
            'Mali',
            'Manatee County, Florida, USA',
            'Manchester-Nashua MSA, NH, USA',
            'Mandalay, Myanmar',
            'Manipur, India',
            'Manitoba, Canada',
            'Maputo (City), Mozambique',
            'Maputo Urban Center, Mozambique',
            'Maradi, Niger',
            'Maranh\u00e3o, Brazil',
            'Maricopa County, Arizona, USA',
            'Marin County, California, USA',
            'Marion County, Florida, USA',
            'Marion County, Indiana, USA',
            'Marion County, Oregon, USA',
            'Maritime (R\u00e9gion), Togo',
            'Markham City, York Regional municipality, Ontario, Canada',
            'Marrakech-Safi, Morocco',
            'Maryland, USA',
            'Mashhad Urban Center, Iran',
            'Massachusetts, USA',
            'Mato Grosso do Sul, Brazil',
            'Mato Grosso, Brazil',
            'Mauritania',
            'Mazowieckie, Poland',
            'Ma\u0142opolskie, Poland',
            'Mbuji-Mayi Urban Center, Democratic Republic of the Congo',
            'McAllen [Urban Area], TX, USA',
            'McAllen-Edinburg-Mission MSA, TX, USA',
            'McHenry County, Illinois, USA',
            'McKinney-Frisco [Urban Area], TX, USA',
            'McLennan County, Texas, USA',
            'Mecklenburg County, North Carolina, USA',
            'Medan Urban Center, Indonesia',
            'Medell\u00edn Urban Center, Colombia',
            'Medford-Klamath Falls OR Media Market, USA',
            'Megh\u0101laya, India',
            'Melbourne Urban Center, Australia',
            'Memphis MSA, TN-MS-AR, USA',
            'Memphis TN Media Market, USA',
            'Memphis Urban Center, USA',
            'Memphis [Urban Area], TN-MS-AR, USA',
            'Memphis city, Tennessee, USA',
            'Merced County, California, USA',
            'Merced MSA, CA, USA',
            'Mercer County, New Jersey, USA',
            'Mesa city, Arizona, USA',
            'Mexicali Urban Center, Mexico-USA',
            'Mexico',
            'Mexico City Urban Center, Mexico',
            'Miami Urban Center, USA',
            'Miami city, Florida, USA',
            'Miami-Dade County, Florida, USA',
            'Miami-Fort Lauderdale [Urban Area], FL, USA',
            'Miami-Fort Lauderdale-West Palm Beach MSA, FL, USA',
            'Miami-Ft. Lauderdale FL Media Market, USA',
            'Michigan, USA',
            'Michoac\u00e1n de Ocampo, Mexico',
            'Middlesex County, Massachusetts, USA',
            'Middlesex County, New Jersey, USA',
            'Middlesex County, Ontario, Canada',
            'Milan Urban Center, Italy',
            'Milwaukee County, Wisconsin, USA',
            'Milwaukee Urban Center, USA',
            'Milwaukee WI Media Market, USA',
            'Milwaukee [Urban Area], WI, USA',
            'Milwaukee city, Wisconsin, USA',
            'Milwaukee-Waukesha-West Allis MSA, WI, USA',
            'Minas Gerais, Brazil',
            'Minneapolis [Saint Paul] Urban Center, USA',
            'Minneapolis city, Minnesota, USA',
            'Minneapolis-St. Paul MN Media Market, USA',
            'Minneapolis-St. Paul [Urban Area], MN, USA',
            'Minneapolis-St. Paul-Bloomington MSA, MN-WI, USA',
            'Minnesota, USA',
            'Minot-Bismarck-Dickinson ND Media Market, USA',
            'Mission Viejo-Lake Forest-Laguna Niguel [Urban Area], CA, USA',
            'Mississauga City, Peel Regional municipality, Ontario, Canada',
            'Mississippi, USA',
            'Missoula MT Media Market, USA',
            'Missouri, USA',
            'Miyagi, Japan',
            'Mobile AL-Pensacola (Ft. Walton Beach) FL Media Market, USA',
            'Mobile County, Alabama, USA',
            'Mobile MSA, AL, USA',
            'Mobile [Urban Area], AL, USA',
            'Modesto MSA, CA, USA',
            'Modesto Urban Center, USA',
            'Modesto [Urban Area], CA, USA',
            'Mogadishu Urban Center, Somalia',
            'Moldova',
            'Mongolia',
            'Monmouth County, New Jersey, USA',
            'Monroe County, New York, USA',
            'Monroe LA-El Dorado AR Media Market, USA',
            'Montagnes, Ivory Coast',
            'Montana, USA',
            'Monterey County, California, USA',
            'Monterey-Salinas CA Media Market, USA',
            'Monterrey Urban Center, Mexico',
            'Montgomery (Selma) AL Media Market, USA',
            'Montgomery County, Maryland, USA',
            'Montgomery County, Ohio, USA',
            'Montgomery County, Pennsylvania, USA',
            'Montgomery County, Texas, USA',
            'Montgomery MSA, AL, USA',
            'Montgomery [Urban Area], AL, USA',
            'Montreal Urban Center, Canada',
            'Montr\u00e9al CMA, QC, Canada',
            'Montr\u00e9al Population Center, QC, Canada',
            'Montr\u00e9al Territory, Quebec, Canada',
            'Montr\u00e9al Ville, Montr\u00e9al Territory, Quebec, Canada',
            'Mopti, Mali',
            'Morocco',
            'Morogoro, Tanzania',
            'Morris County, New Jersey, USA',
            'Moscow Urban Center, Russia',
            'Moskovskaya oblast\', Russia',
            'Moskva, Russia',
            'Mozambique',
            'Mpumalanga, South Africa',
            'Multan Urban Center, Pakistan',
            'Multnomah County, Oregon, USA',
            'Mumbai Urban Center, India',
            'Municipalidad Metropolitana de Lima, Peru',
            'Mwanza, Tanzania',
            'Myanmar',
            'Mymensingh, Bangladesh',
            'Myrtle Beach-Conway-North Myrtle Beach MSA, SC-NC, USA',
            'Myrtle Beach-Florence SC Media Market, USA',
            'Myrtle Beach-North Myrtle Beach [Urban Area], SC-NC, USA',
            'M\u00e9xico, Mexico',
            'M\u0101zandar\u0101n, Iran',
            'NC-01, USA',
            'NC-02, USA',
            'NC-03, USA',
            'NC-04, USA',
            'NC-05, USA',
            'NC-06, USA',
            'NC-07, USA',
            'NC-08, USA',
            'NC-09, USA',
            'NC-10, USA',
            'NC-11, USA',
            'NC-12, USA',
            'NC-13, USA',
            'NC-14, USA',
            'ND-00, USA',
            'NE-01, USA',
            'NE-02, USA',
            'NE-03, USA',
            'NH-01, USA',
            'NH-02, USA',
            'NJ-01, USA',
            'NJ-02, USA',
            'NJ-03, USA',
            'NJ-04, USA',
            'NJ-05, USA',
            'NJ-06, USA',
            'NJ-07, USA',
            'NJ-08, USA',
            'NJ-09, USA',
            'NJ-10, USA',
            'NJ-11, USA',
            'NJ-12, USA',
            'NM-01, USA',
            'NM-02, USA',
            'NM-03, USA',
            'NV-01, USA',
            'NV-02, USA',
            'NV-03, USA',
            'NV-04, USA',
            'NY-01, USA',
            'NY-02, USA',
            'NY-03, USA',
            'NY-04, USA',
            'NY-05, USA',
            'NY-06, USA',
            'NY-07, USA',
            'NY-08, USA',
            'NY-09, USA',
            'NY-10, USA',
            'NY-11, USA',
            'NY-12, USA',
            'NY-13, USA',
            'NY-14, USA',
            'NY-15, USA',
            'NY-16, USA',
            'NY-17, USA',
            'NY-18, USA',
            'NY-19, USA',
            'NY-20, USA',
            'NY-21, USA',
            'NY-22, USA',
            'NY-23, USA',
            'NY-24, USA',
            'NY-25, USA',
            'NY-26, USA',
            'Nagoya Urban Center, Japan',
            'Nagpur Urban Center, India',
            'Nairobi City, Kenya',
            'Nairobi Urban Center, Kenya',
            'Namangan, Uzbekistan',
            'Nampula, Mozambique',
            'Nanchang (Northern Jiangxi Sheng) Urban Center, China',
            'Nanjing (Jiangsu Sheng) Urban Center, China',
            'Nanning Urban Center, China',
            'Naples Urban Center, Italy',
            'Naples-Immokalee-Marco Island MSA, FL, USA',
            'Nasarawa, Nigeria',
            'Nashville TN Media Market, USA',
            'Nashville Urban Center, USA',
            'Nashville-Davidson [Urban Area], TN, USA',
            'Nashville-Davidson metropolitan government (balance), Tennessee, USA',
            'Nashville-Davidson--Murfreesboro--Franklin MSA, TN, USA',
            'Nassau County, New York, USA',
            'National Capital Region, Philippines',
            'Naugatuck Valley Planning Region, Connecticut, USA',
            'Nebraska, USA',
            'Negros Occidental, Philippines',
            'Nei Mongol Zizhiqu, China',
            'Nepal',
            'Netherlands',
            'Nevada, USA',
            'New Brunswick, Canada',
            'New Castle County, Delaware, USA',
            'New Hampshire, USA',
            'New Haven [Urban Area], CT, USA',
            'New Haven-Milford MSA, CT, USA',
            'New Jersey, USA',
            'New Mexico, USA',
            'New Orleans LA Media Market, USA',
            'New Orleans Urban Center, USA',
            'New Orleans [Urban Area], LA, USA',
            'New Orleans city, Louisiana, USA',
            'New Orleans-Metairie MSA, LA, USA',
            'New South Wales, Australia',
            'New Taipei [Taipei] Urban Center, China',
            'New York County, New York, USA',
            'New York NY Media Market, USA',
            'New York Urban Center, USA',
            'New York city, New York, USA',
            'New York, USA',
            'New York-Jersey City-Newark [Urban Area], NY-NJ, USA',
            'New York-Newark-Jersey City MSA, NY-NJ-PA, USA',
            'New Zealand',
            'Newark city, New Jersey, USA',
            'Newfoundland and Labrador, Canada',
            'Newport News Urban Center, USA',
            'Ngh\u1ec7 An, Vietnam',
            'Niagara Regional municipality, Ontario, Canada',
            'Nicaragua',
            'Niedersachsen, Germany',
            'Niger',
            'Niger, Nigeria',
            'Nigeria',
            'Ningbo Urban Center, China',
            'Ningxia Zizhiiqu, China',
            'Nizhegorodskaya oblast\', Russia',
            'Noord-Brabant, Netherlands',
            'Noord-Holland, Netherlands',
            'Nord-Kivu, Democratic Republic of the Congo',
            'Nordrhein-Westfalen, Germany',
            'Norfolk County, Massachusetts, USA',
            'Norfolk-Portsmouth-Newport News VA Media Market, USA',
            'Normandie, France',
            'North Carolina, USA',
            'North Dakota, USA',
            'North Korea',
            'North Las Vegas city, Nevada, USA',
            'North Port-Sarasota-Bradenton MSA, FL, USA',
            'North, Cameroon',
            'North-West, South Africa',
            'Northampton County, Pennsylvania, USA',
            'Norway',
            'Norwich-New London MSA, CT, USA',
            'Nouvelle-Aquitaine, France',
            'Nova Scotia, Canada',
            'Novosibirskaya oblast\', Russia',
            'Nueces County, Texas, USA',
            'Nuevo Laredo Urban Center, Mexico-USA',
            'Nuevo Le\u00f3n, Mexico',
            'Nusa Tenggara Barat, Indonesia',
            'Nusa Tenggara Timur, Indonesia',
            'N\u012bnaw\u00e1, Iraq',
            'OH-01, USA',
            'OH-02, USA',
            'OH-03, USA',
            'OH-04, USA',
            'OH-05, USA',
            'OH-06, USA',
            'OH-07, USA',
            'OH-08, USA',
            'OH-09, USA',
            'OH-10, USA',
            'OH-11, USA',
            'OH-12, USA',
            'OH-13, USA',
            'OH-14, USA',
            'OH-15, USA',
            'OK-01, USA',
            'OK-02, USA',
            'OK-03, USA',
            'OK-04, USA',
            'OK-05, USA',
            'OR-01, USA',
            'OR-02, USA',
            'OR-03, USA',
            'OR-04, USA',
            'OR-05, USA',
            'OR-06, USA',
            'Oakland County, Michigan, USA',
            'Oakland city, California, USA',
            'Oaxaca, Mexico',
            'Ocala MSA, FL, USA',
            'Occitanie, France',
            'Ocean County, New Jersey, USA',
            'Odessa-Midland TX Media Market, USA',
            'Odisha, India',
            'Ogden Urban Center, USA',
            'Ogden-Clearfield MSA, UT, USA',
            'Ogden-Layton [Urban Area], UT, USA',
            'Ogun, Nigeria',
            'Ohio, USA',
            'Oklahoma City MSA, OK, USA',
            'Oklahoma City OK Media Market, USA',
            'Oklahoma City Urban Center, USA',
            'Oklahoma City [Urban Area], OK, USA',
            'Oklahoma City city, Oklahoma, USA',
            'Oklahoma County, Oklahoma, USA',
            'Oklahoma, USA',
            'Olympia-Tumwater MSA, WA, USA',
            'Omaha NE Media Market, USA',
            'Omaha Urban Center, USA',
            'Omaha [Urban Area], NE-IA, USA',
            'Omaha city, Nebraska, USA',
            'Omaha-Council Bluffs MSA, NE-IA, USA',
            'Oman',
            'Ondo, Nigeria',
            'Onitsha Urban Center, Nigeria',
            'Onondaga County, New York, USA',
            'Ontario, Canada',
            'Orange County, California, USA',
            'Orange County, Florida, USA',
            'Orange County, New York, USA',
            'Oregon, USA',
            'Orlando Urban Center, USA',
            'Orlando [Urban Area], FL, USA',
            'Orlando city, Florida, USA',
            'Orlando-Daytona Beach-Melbourne FL Media Market, USA',
            'Orlando-Kissimmee-Sanford MSA, FL, USA',
            'Orleans Parish, Louisiana, USA',
            'Orom\u012bya, Ethiopia',
            'Osaka [Kyoto] Urban Center, Japan',
            'Osceola County, Florida, USA',
            'Oshawa CMA, ON, Canada',
            'Oshawa Population Center, ON, Canada',
            'Oshawa Urban Center, Canada',
            'Osun, Nigeria',
            'Ottawa - Gatineau CMA, QC-ON, Canada',
            'Ottawa - Gatineau Population Center, QC-ON, Canada',
            'Ottawa CDR, Ontario, Canada',
            'Ottawa City, Ottawa CDR, Ontario, Canada',
            'Ottawa County, Michigan, USA',
            'Ottawa Urban Center, Canada',
            'Ouest, Haiti',
            'Ouest, Rwanda',
            'Oxnard Urban Center, USA',
            'Oxnard-San Buenaventura (Ventura) [Urban Area], CA, USA',
            'Oxnard-Thousand Oaks-Ventura MSA, CA, USA',
            'Oyo, Nigeria',
            'Ozama, Dominican Republic',
            'PA-01, USA',
            'PA-02, USA',
            'PA-03, USA',
            'PA-04, USA',
            'PA-05, USA',
            'PA-06, USA',
            'PA-07, USA',
            'PA-08, USA',
            'PA-09, USA',
            'PA-10, USA',
            'PA-11, USA',
            'PA-12, USA',
            'PA-13, USA',
            'PA-14, USA',
            'PA-15, USA',
            'PA-16, USA',
            'PA-17, USA',
            'Paducah KY-Cape Girardeau MO-Harrisburg IL Media Market, USA',
            'Pakistan',
            'Palm Bay Urban Center, USA',
            'Palm Bay-Melbourne [Urban Area], FL, USA',
            'Palm Bay-Melbourne-Titusville MSA, FL, USA',
            'Palm Beach County, Florida, USA',
            'Palm Springs CA Media Market, USA',
            'Palmdale Urban Center, USA',
            'Palmdale-Lancaster [Urban Area], CA, USA',
            'Pampanga, Philippines',
            'Panama',
            'Panama City FL Media Market, USA',
            'Pangasinan, Philippines',
            'Papua New Guinea',
            'Papua, Indonesia',
            'Paraguay',
            'Paran\u00e1, Brazil',
            'Para\u00edba, Brazil',
            'Paris Urban Center, France',
            'Par\u00e1, Brazil',
            'Pasco County, Florida, USA',
            'Passaic County, New Jersey, USA',
            'Patna Urban Center, India',
            'Pays de la Loire, France',
            'Peel Regional municipality, Ontario, Canada',
            'Pennsylvania, USA',
            'Pensacola [Urban Area], FL-AL, USA',
            'Pensacola-Ferry Pass-Brent MSA, FL, USA',
            'Peoria MSA, IL, USA',
            'Peoria [Urban Area], IL, USA',
            'Peoria-Bloomington IL Media Market, USA',
            'Perak, Malaysia',
            'Permskiy kray, Russia',
            'Pernambuco, Brazil',
            'Peru',
            'Peshawar Urban Center, Pakistan',
            'Philadelphia County, Pennsylvania, USA',
            'Philadelphia PA Media Market, USA',
            'Philadelphia Urban Center, USA',
            'Philadelphia [Urban Area], PA-NJ-DE-MD, USA',
            'Philadelphia city, Pennsylvania, USA',
            'Philadelphia-Camden-Wilmington MSA, PA-NJ-DE-MD, USA',
            'Philippines',
            'Phoenix (Prescott) AZ Media Market, USA',
            'Phoenix Urban Center, USA',
            'Phoenix West-Goodyear-Avondale [Urban Area], AZ, USA',
            'Phoenix city, Arizona, USA',
            'Phoenix-Mesa-Scottsdale MSA, AZ, USA',
            'Phoenix-Mesa-Scottsdale [Urban Area], AZ, USA',
            'Piau\u00ed, Brazil',
            'Pichincha, Ecuador',
            'Piemonte, Italy',
            'Pierce County, Washington, USA',
            'Pima County, Arizona, USA',
            'Pinal County, Arizona, USA',
            'Pinellas County, Florida, USA',
            'Pittsburgh MSA, PA, USA',
            'Pittsburgh PA Media Market, USA',
            'Pittsburgh Urban Center, USA',
            'Pittsburgh [Urban Area], PA, USA',
            'Pittsburgh city, Pennsylvania, USA',
            'Placer County, California, USA',
            'Plano city, Texas, USA',
            'Plateau, Nigeria',
            'Plymouth County, Massachusetts, USA',
            'Poland',
            'Polk County, Florida, USA',
            'Polk County, Iowa, USA',
            'Ponce MSA, PR, USA',
            'Port St. Lucie MSA, FL, USA',
            'Port St. Lucie [Urban Area], FL, USA',
            'Portland (Oregon-Washington) Urban Center, USA',
            'Portland OR Media Market, USA',
            'Portland [Urban Area], OR-WA, USA',
            'Portland city, Oregon, USA',
            'Portland-Auburn ME Media Market, USA',
            'Portland-South Portland MSA, ME, USA',
            'Portland-Vancouver-Hillsboro MSA, OR-WA, USA',
            'Portugal',
            'Poughkeepsie-Newburgh [Urban Area], NY, USA',
            'Prince George\'s County, Maryland, USA',
            'Prince William County, Virginia, USA',
            'Provence-Alpes-C\u00f4te d\'Azur, France',
            'Providence County, Rhode Island, USA',
            'Providence Urban Center, USA',
            'Providence [Urban Area], RI-MA, USA',
            'Providence-Warwick MSA, RI-MA, USA',
            'ProvidenceRi-New Bedford MA Media Market, USA',
            'Provo Urban Center, USA',
            'Provo-Orem MSA, UT, USA',
            'Provo-Orem [Urban Area], UT, USA',
            'Puebla, Mexico',
            'Puerto Rico, USA',
            'Puglia, Italy',
            'Pulaski County, Arkansas, USA',
            'Pune Urban Center, India',
            'Punjab, India',
            'Punjab, Pakistan',
            'Pyongyang Urban Center, North Korea',
            'P\u2019y\u014fngan-bukto [Phyeonganpukto], North Korea',
            'P\u2019y\u014fngan-namdo [Phyeongannamto], North Korea',
            'P\u2019y\u014fngyang [Phyeongyang], North Korea',
            'Qashqadaryo, Uzbekistan',
            'Qatar',
            'Qingdao Urban Center, China',
            'Qinghai Sheng, China',
            'Qin\u0101, Egypt',
            'Quanzhou (Fujian Sheng) Urban Center, China',
            'Quebec Urban Center, Canada',
            'Quebec, Canada',
            'Queens County, New York, USA',
            'Queensland, Australia',
            'Quezon City [Manila] Urban Center, Philippines',
            'QuincyIl-Hannibal MO-Keokuk IA Media Market, USA',
            'Qu\u00e9bec CMA, QC, Canada',
            'Qu\u00e9bec Population Center, QC, Canada',
            'Qu\u00e9bec Territory, Quebec, Canada',
            'Qu\u00e9bec Ville, Qu\u00e9bec Territory, Quebec, Canada',
            'RI-01, USA',
            'RI-02, USA',
            'Rabat-Sal\u00e9-K\u00e9nitra, Morocco',
            'Rajshahi, Bangladesh',
            'Raleigh MSA, NC, USA',
            'Raleigh Urban Center, USA',
            'Raleigh [Urban Area], NC, USA',
            'Raleigh city, North Carolina, USA',
            'Raleigh-Durham (Fayetteville) NC Media Market, USA',
            'Ramsey County, Minnesota, USA',
            'Rangpur, Bangladesh',
            'Rapid City SD Media Market, USA',
            'Rawalpindi [Islamabad] Urban Center, Pakistan',
            'Reading MSA, PA, USA',
            'Reading [Urban Area], PA, USA',
            'Recife Urban Center, Brazil',
            'Regi\u00f3n Metropolitana de Santiago, Chile',
            'Reno MSA, NV, USA',
            'Reno NV Media Market, USA',
            'Reno [Urban Area], NV-CA, USA',
            'Reno city, Nevada, USA',
            'Rheinland-Pfalz, Germany',
            'Rhode Island, USA',
            'Riau, Indonesia',
            'Richland County, South Carolina, USA',
            'Richmond County, New York, USA',
            'Richmond MSA, VA, USA',
            'Richmond Urban Center, USA',
            'Richmond [Urban Area], VA, USA',
            'Richmond-Petersburg VA Media Market, USA',
            'Rio Grande do Norte, Brazil',
            'Rio Grande do Sul, Brazil',
            'Rio de Janeiro Urban Center, Brazil',
            'Rio de Janeiro, Brazil',
            'Rivers, Nigeria',
            'Riverside County, California, USA',
            'Riverside city, California, USA',
            'Riverside-San Bernardino [Urban Area], CA, USA',
            'Riverside-San Bernardino-Ontario MSA, CA, USA',
            'Riyadh Urban Center, Saudi Arabia',
            'Rizal, Philippines',
            'Roanoke MSA, VA, USA',
            'Roanoke-Lynchburg VA Media Market, USA',
            'Rochester (New York) Urban Center, USA',
            'Rochester MN-Mason CityIa-Austin MN Media Market, USA',
            'Rochester MSA, NY, USA',
            'Rochester NY Media Market, USA',
            'Rochester [Urban Area], NY, USA',
            'Rockford IL Media Market, USA',
            'Rockford MSA, IL, USA',
            'Rockford [Urban Area], IL, USA',
            'Rockingham County, New Hampshire, USA',
            'Rockland County, New York, USA',
            'Romania',
            'Rostovskaya oblast\', Russia',
            'Round Lake Beach-McHenry-Grayslake [Urban Area], IL-WI, USA',
            'Russia',
            'Rutherford County, Tennessee, USA',
            'Rwanda',
            'R\u00edo Piedras [San Juan] Urban Center, USA',
            'R\u0101jasth\u0101n, India',
            'R\u012bf Dimashq, Syria',
            'SC-01, USA',
            'SC-02, USA',
            'SC-03, USA',
            'SC-04, USA',
            'SC-05, USA',
            'SC-06, USA',
            'SC-07, USA',
            'SD-00, USA',
            'Sabah, Malaysia',
            'Sachsen, Germany',
            'Sacramento County, California, USA',
            'Sacramento Urban Center, USA',
            'Sacramento [Urban Area], CA, USA',
            'Sacramento city, California, USA',
            'Sacramento--Roseville--Arden-Arcade MSA, CA, USA',
            'Sacramento-Stockton-Modesto CA Media Market, USA',
            'Sagaing, Myanmar',
            'Saint Petersburg Urban Center, Russia',
            'Saitama, Japan',
            'Salem MSA, OR, USA',
            'Salem [Urban Area], OR, USA',
            'Salinas MSA, CA, USA',
            'Salisbury MD Media Market, USA',
            'Salisbury MSA, MD-DE, USA',
            'Salt Lake City MSA, UT, USA',
            'Salt Lake City UT Media Market, USA',
            'Salt Lake City Urban Center, USA',
            'Salt Lake City [Urban Area], UT, USA',
            'Salt Lake County, Utah, USA',
            'Salvador Urban Center, Brazil',
            'Samarqand, Uzbekistan',
            'Samarskaya oblast\', Russia',
            'Samut Prakan, Thailand',
            'San Antonio TX Media Market, USA',
            'San Antonio Urban Center, USA',
            'San Antonio [Urban Area], TX, USA',
            'San Antonio city, Texas, USA',
            'San Antonio-New Braunfels MSA, TX, USA',
            'San Bernardino County, California, USA',
            'San Diego CA Media Market, USA',
            'San Diego County, California, USA',
            'San Diego [Urban Area], CA, USA',
            'San Diego city, California, USA',
            'San Diego-Carlsbad MSA, CA, USA',
            'San Francisco County, California, USA',
            'San Francisco city, California, USA',
            'San Francisco-Oakland [Urban Area], CA, USA',
            'San Francisco-Oakland-Hayward MSA, CA, USA',
            'San Francisco-Oakland-San Jose CA Media Market, USA',
            'San Joaquin County, California, USA',
            'San Jose Urban Center, USA',
            'San Jose [Urban Area], CA, USA',
            'San Jose city, California, USA',
            'San Jose-Sunnyvale-Santa Clara MSA, CA, USA',
            'San Juan Municipio, Puerto Rico, USA',
            'San Juan [Urban Area], PR, USA',
            'San Juan zona urbana, Puerto Rico, USA',
            'San Juan-Carolina-Caguas MSA, PR, USA',
            'San Luis Obispo County, California, USA',
            'San Luis Obispo-Paso Robles-Arroyo Grande MSA, CA, USA',
            'San Luis Potos\u00ed, Mexico',
            'San Mateo County, California, USA',
            'Sana\'a Urban Center, Yemen',
            'Sankt-Peterburg, Russia',
            'Santa Ana city, California, USA',
            'Santa Barbara County, California, USA',
            'Santa Barbara-Santa Maria-San Luis Obispo CA Media Market, USA',
            'Santa Catarina, Brazil',
            'Santa Clara County, California, USA',
            'Santa Clarita [Urban Area], CA, USA',
            'Santa Cruz County, California, USA',
            'Santa Cruz, Bolivia',
            'Santa Cruz-Watsonville MSA, CA, USA',
            'Santa Fe, Argentina',
            'Santa Maria-Santa Barbara MSA, CA, USA',
            'Santa Rosa MSA, CA, USA',
            'Santa Rosa [Urban Area], CA, USA',
            'Santiago Urban Center, Chile',
            'Santo Domingo Urban Center, Dominican Republic',
            'Sarasota County, Florida, USA',
            'Sarawak, Malaysia',
            'Saskatchewan, Canada',
            'Saskatoon CMA, SK, Canada',
            'Saskatoon City, Division No. 11, Saskatchewan, Canada',
            'Saskatoon Population Center, SK, Canada',
            'Sassandra-Marahou\u00e9, Ivory Coast',
            'Saudi Arabia',
            'Savannah GA Media Market, USA',
            'Savannah MSA, GA, USA',
            'Savannah [Urban Area], GA, USA',
            'Schleswig-Holstein, Germany',
            'Scotland, United Kingdom',
            'Scranton [Urban Area], PA, USA',
            'Scranton--Wilkes-Barre--Hazleton MSA, PA, USA',
            'Seattle Urban Center, USA',
            'Seattle city, Washington, USA',
            'Seattle-Tacoma WA Media Market, USA',
            'Seattle-Tacoma [Urban Area], WA, USA',
            'Seattle-Tacoma-Bellevue MSA, WA, USA',
            'Sedgwick County, Kansas, USA',
            'Selangor, Malaysia',
            'Semarang Urban Center, Indonesia',
            'Seminole County, Florida, USA',
            'Senegal',
            'Seoul Urban Center, South Korea',
            'Seoul-teukbyeolsi, South Korea',
            'Serbia',
            'Shaanxi Sheng, China',
            'Sham\u0101l D\u0101rf\u016br, Sudan',
            'Sham\u0101l Kurduf\u0101n, Sudan',
            'Shan, Myanmar',
            'Shandong Sheng, China',
            'Shanghai Shi, China',
            'Shanghai Urban Center, China',
            'Shanxi Sheng, China',
            'Shelby County, Tennessee, USA',
            'Shenyang Urban Center, China',
            'Sherman TX-Ada OK Media Market, USA',
            'Shijiazhuang Urban Center, China',
            'Shreveport LA Media Market, USA',
            'Shreveport [Urban Area], LA, USA',
            'Shreveport-Bossier City MSA, LA, USA',
            'Sichuan Sheng, China',
            'Sicilia, Italy',
            'Sierra Leone',
            'Sikasso, Mali',
            'Simcoe County, Ontario, Canada',
            'Sinaloa, Mexico',
            'Sindh, Pakistan',
            'Singapore',
            'Singapore Urban Center, Malaysia-Singapore',
            'Sioux City IA Media Market, USA',
            'Sioux Falls (Mitchell) SD Media Market, USA',
            'Sioux Falls MSA, SD, USA',
            'Sizuoka, Japan',
            'Slovakia',
            'Snohomish County, Washington, USA',
            'Sokoto, Nigeria',
            'Solano County, California, USA',
            'Somalia',
            'Somerset County, New Jersey, USA',
            'Sonoma County, California, USA',
            'Sonora, Mexico',
            'Souss-Massa, Morocco',
            'South Africa',
            'South Bend [Urban Area], IN-MI, USA',
            'South Bend-Elkhart IN Media Market, USA',
            'South Bend-Mishawaka MSA, IN-MI, USA',
            'South Carolina, USA',
            'South Central Connecticut Planning Region, Connecticut, USA',
            'South Dakota, USA',
            'South Korea',
            'South Sudan',
            'Southeastern Connecticut Planning Region, Connecticut, USA',
            'Spain',
            'Spartanburg County, South Carolina, USA',
            'Spartanburg MSA, SC, USA',
            'Spokane County, Washington, USA',
            'Spokane Urban Center, USA',
            'Spokane WA Media Market, USA',
            'Spokane [Urban Area], WA, USA',
            'Spokane-Spokane Valley MSA, WA, USA',
            'Springfield (Massachusetts) Urban Center, USA',
            'Springfield MO Media Market, USA',
            'Springfield MSA, MA, USA',
            'Springfield MSA, MO, USA',
            'Springfield [Urban Area], MA-CT, USA',
            'Springfield [Urban Area], MO, USA',
            'Springfield-Holyoke MA Media Market, USA',
            'Sri Lanka',
            'St. Catharines - Niagara CMA, ON, Canada',
            'St. Charles County, Missouri, USA',
            'St. Clair County, Illinois, USA',
            'St. Johns County, Florida, USA',
            'St. Joseph County, Indiana, USA',
            'St. Louis County, Missouri, USA',
            'St. Louis MO Media Market, USA',
            'St. Louis MSA, MO-IL, USA',
            'St. Louis Urban Center, USA',
            'St. Louis [Urban Area], MO-IL, USA',
            'St. Louis city, Missouri, USA',
            'St. Louis city-County, Missouri, USA',
            'St. Lucie County, Florida, USA',
            'St. Paul city, Minnesota, USA',
            'St. Petersburg city, Florida, USA',
            'St. Tammany Parish, Louisiana, USA',
            'Stanislaus County, California, USA',
            'Stark County, Ohio, USA',
            'State of Palestine',
            'Stavropol\'skiy kray, Russia',
            'Stockton Urban Center, USA',
            'Stockton [Urban Area], CA, USA',
            'Stockton city, California, USA',
            'Stockton-Lodi MSA, CA, USA',
            'Sud, Rwanda',
            'Sud-Kivu, Democratic Republic of the Congo',
            'Sudan',
            'Sud\u016br Pashchim, Nepal',
            'Suffolk County, Massachusetts, USA',
            'Suffolk County, New York, USA',
            'Sughd, Tajikistan',
            'Sulawesi Selatan, Indonesia',
            'Sulawesi Tengah, Indonesia',
            'Sulawesi Tenggara, Indonesia',
            'Sulawesi Utara, Indonesia',
            'Sumal\u0113, Ethiopia',
            'Sumatera Barat, Indonesia',
            'Sumatera Selatan, Indonesia',
            'Sumatera Utara, Indonesia',
            'Summit County, Ohio, USA',
            'Surabaya Urban Center, Indonesia',
            'Surat Urban Center, India',
            'Surrey City, Greater Vancouver Regional district, British Columbia, Canada',
            'Surxondaryo, Uzbekistan',
            'Suzhou (Jiangsu Sheng) Urban Center, China',
            'Sverdlovskaya oblast\', Russia',
            'Sweden',
            'Switzerland',
            'Sydney Urban Center, Australia',
            'Sylhet, Bangladesh',
            'Syracuse MSA, NY, USA',
            'Syracuse NY Media Market, USA',
            'Syracuse Urban Center, USA',
            'Syracuse [Urban Area], NY, USA',
            'Syria',
            'S\u00e3o Paulo Urban Center, Brazil',
            'S\u00e3o Paulo, Brazil',
            'S\u00e9gou, Mali',
            'S\u012bdama, Ethiopia',
            'S\u012bst\u0101n va Bal\u016bchest\u0101n, Iran',
            'S\u016bh\u0101j, Egypt',
            'TN-01, USA',
            'TN-02, USA',
            'TN-03, USA',
            'TN-04, USA',
            'TN-05, USA',
            'TN-06, USA',
            'TN-07, USA',
            'TN-08, USA',
            'TN-09, USA',
            'TX-01, USA',
            'TX-02, USA',
            'TX-03, USA',
            'TX-04, USA',
            'TX-05, USA',
            'TX-06, USA',
            'TX-07, USA',
            'TX-08, USA',
            'TX-09, USA',
            'TX-10, USA',
            'TX-11, USA',
            'TX-12, USA',
            'TX-13, USA',
            'TX-14, USA',
            'TX-15, USA',
            'TX-16, USA',
            'TX-17, USA',
            'TX-18, USA',
            'TX-19, USA',
            'TX-20, USA',
            'TX-21, USA',
            'TX-22, USA',
            'TX-23, USA',
            'TX-24, USA',
            'TX-25, USA',
            'TX-26, USA',
            'TX-27, USA',
            'TX-28, USA',
            'TX-29, USA',
            'TX-30, USA',
            'TX-31, USA',
            'TX-32, USA',
            'TX-33, USA',
            'TX-34, USA',
            'TX-35, USA',
            'TX-36, USA',
            'TX-37, USA',
            'TX-38, USA',
            'Tabasco, Mexico',
            'Tabora, Tanzania',
            'Tahoua, Niger',
            'Taiwan, China',
            'Taiyuan Urban Center, China',
            'Tajikistan',
            'Tallahassee FL-Thomasville GA Media Market, USA',
            'Tallahassee MSA, FL, USA',
            'Tallahassee [Urban Area], FL, USA',
            'Tamaulipas, Mexico',
            'Tamil N\u0101du, India',
            'Tampa Urban Center, USA',
            'Tampa city, Florida, USA',
            'Tampa-St. Petersburg [Urban Area], FL, USA',
            'Tampa-St. Petersburg-Clearwater MSA, FL, USA',
            'Tampa-St.Petersburg (Sarasota) FL Media Market, USA',
            'Tanga, Tanzania',
            'Tanganyika, Democratic Republic of the Congo',
            'Tanger-T\u00e9touan-Al Hoce\u00efma, Morocco',
            'Tanzania',
            'Taraba, Nigeria',
            'Tarrant County, Texas, USA',
            'Tashkent Urban Center, Uzbekistan',
            'Tasikmalaya Urban Center, Indonesia',
            'Tatarstan, Respublika, Russia',
            'Tehran Urban Center, Iran',
            'Tehr\u0101n, Iran',
            'Tel Aviv Urban Center, Israel',
            'Telang\u0101na, India',
            'Temecula-Murrieta-Menifee [Urban Area], CA, USA',
            'Tennessee, USA',
            'Terre Haute IN Media Market, USA',
            'Tete, Mozambique',
            'Texas, USA',
            'Thailand',
            'Thanh H\u00f3a, Vietnam',
            'The Gambia',
            'The Woodlands-Conroe [Urban Area], TX, USA',
            'Thiruvananthapuram Urban Center, India',
            'Thurston County, Washington, USA',
            'Tianjin Shi, China',
            'Tianjin Urban Center, China',
            'Tiba, Japan',
            'Tigray, Ethiopia',
            'Tijuana Urban Center, Mexico-USA',
            'Tillab\u00e9ri, Niger',
            'Togo',
            'Tokyo Urban Center, Japan',
            'Toledo MSA, OH, USA',
            'Toledo OH Media Market, USA',
            'Toledo [Urban Area], OH-MI, USA',
            'Toledo city, Ohio, USA',
            'Topeka KS Media Market, USA',
            'Toronto CDR, Ontario, Canada',
            'Toronto CMA, ON, Canada',
            'Toronto City, Toronto CDR, Ontario, Canada',
            'Toronto Population Center, ON, Canada',
            'Toronto Urban Center, Canada',
            'Toscana, Italy',
            'Toshkent (Province), Uzbekistan',
            'Traverse City-Cadillac MI Media Market, USA',
            'Travis County, Texas, USA',
            'Trenton MSA, NJ, USA',
            'Trenton [Urban Area], NJ, USA',
            'Tri-Cities TN-VA Media Market, USA',
            'Tripura, India',
            'Tshopo, Democratic Republic of the Congo',
            'Tucson (Sierra Vista) AZ Media Market, USA',
            'Tucson MSA, AZ, USA',
            'Tucson Urban Center, USA',
            'Tucson [Urban Area], AZ, USA',
            'Tucson city, Arizona, USA',
            'Tulare County, California, USA',
            'Tulsa County, Oklahoma, USA',
            'Tulsa MSA, OK, USA',
            'Tulsa OK Media Market, USA',
            'Tulsa Urban Center, USA',
            'Tulsa [Urban Area], OK, USA',
            'Tulsa city, Oklahoma, USA',
            'Tunisia',
            'Turkey',
            'Turkmenistan',
            'Tuscaloosa MSA, AL, USA',
            'Tyler-Longview (Lufkin & Nacogdoches) TX Media Market, USA',
            'T\u00f4ky\u00f4, Japan',
            'T\u0101\u2018izz, Yemen',
            'USA',
            'UT-01, USA',
            'UT-02, USA',
            'UT-03, USA',
            'UT-04, USA',
            'Uganda',
            'Ukraine',
            'Union County, New Jersey, USA',
            'United Arab Emirates',
            'United Kingdom',
            'Urban Honolulu CDP, Hawaii, USA',
            'Urban Honolulu MSA, HI, USA',
            'Uruguay',
            'Utah County, Utah, USA',
            'Utah, USA',
            'Utica NY Media Market, USA',
            'Utica-Rome MSA, NY, USA',
            'Uttar Pradesh, India',
            'Uttar\u0101khand, India',
            'Uzbekistan',
            'VA-01, USA',
            'VA-02, USA',
            'VA-03, USA',
            'VA-04, USA',
            'VA-05, USA',
            'VA-06, USA',
            'VA-07, USA',
            'VA-08, USA',
            'VA-09, USA',
            'VA-10, USA',
            'VA-11, USA',
            'VT-00, USA',
            'Valenciana, Comunidad, Spain',
            'Valle del Cauca, Colombia',
            'Vallejo-Fairfield MSA, CA, USA',
            'Vancouver CMA, BC, Canada',
            'Vancouver City, Greater Vancouver Regional district, British Columbia, Canada',
            'Vancouver Population Center, BC, Canada',
            'Vancouver Urban Center, Canada',
            'Varanasi Urban Center, India',
            'Vaughan City, York Regional municipality, Ontario, Canada',
            'Vayamba pa\u1e37\u0101ta, Sri Lanka',
            'Veneto, Italy',
            'Venezuela',
            'Ventura County, California, USA',
            'Veracruz de Ignacio de la Llave, Mexico',
            'Vermont, USA',
            'Victoria CMA, BC, Canada',
            'Victoria Population Center, BC, Canada',
            'Victoria, Australia',
            'Victorville-Hesperia-Apple Valley [Urban Area], CA, USA',
            'Vietnam',
            'Virginia Beach Urban Center, USA',
            'Virginia Beach city, Virginia, USA',
            'Virginia Beach city-County, Virginia, USA',
            'Virginia Beach-Norfolk [Urban Area], VA, USA',
            'Virginia Beach-Norfolk-Newport News MSA, VA-NC, USA',
            'Virginia, USA',
            'Visalia-Porterville MSA, CA, USA',
            'Vlaamse Gewest, Belgium',
            'Volgogradskaya oblast\', Russia',
            'Volusia County, Florida, USA',
            'WA-01, USA',
            'WA-02, USA',
            'WA-03, USA',
            'WA-04, USA',
            'WA-05, USA',
            'WA-06, USA',
            'WA-07, USA',
            'WA-08, USA',
            'WA-09, USA',
            'WA-10, USA',
            'WI-01, USA',
            'WI-02, USA',
            'WI-03, USA',
            'WI-04, USA',
            'WI-05, USA',
            'WI-06, USA',
            'WI-07, USA',
            'WI-08, USA',
            'WV-01, USA',
            'WV-02, USA',
            'WY-00, USA',
            'Waco MSA, TX, USA',
            'Waco-Temple-Bryan TX Media Market, USA',
            'Wake County, North Carolina, USA',
            'Wakiso, Uganda',
            'Wales, United Kingdom',
            'Washington County, Minnesota, USA',
            'Washington County, Oregon, USA',
            'Washington D.C. Urban Center, USA',
            'Washington DC (Hagerstown MD) Media Market, USA',
            'Washington city, District of Columbia, USA',
            'Washington, USA',
            'Washington-Arlington [Urban Area], DC-VA-MD, USA',
            'Washington-Arlington-Alexandria MSA, DC-VA-MD-WV, USA',
            'Washoe County, Nevada, USA',
            'Washtenaw County, Michigan, USA',
            'Waterloo Regional municipality, Ontario, Canada',
            'Watertown NY Media Market, USA',
            'Waukegan Urban Center, USA',
            'Waukesha County, Wisconsin, USA',
            'Wausau-Rhinelander WI Media Market, USA',
            'Wayne County, Michigan, USA',
            'Webb County, Texas, USA',
            'Weber County, Utah, USA',
            'Weld County, Colorado, USA',
            'Wenzhou Urban Center, China',
            'West Bengal, India',
            'West Palm Beach-Fort Pierce FL Media Market, USA',
            'West Virginia, USA',
            'Westchester County, New York, USA',
            'Western Australia, Australia',
            'Western Cape, South Africa',
            'Western Connecticut Planning Region, Connecticut, USA',
            'Westmoreland County, Pennsylvania, USA',
            'Wheeling WV-Steubenville OH Media Market, USA',
            'Wichita Falls TX & Lawton OK Media Market, USA',
            'Wichita MSA, KS, USA',
            'Wichita Urban Center, USA',
            'Wichita [Urban Area], KS, USA',
            'Wichita city, Kansas, USA',
            'Wichita-Hutchinson KS Media Market, USA',
            'Wielkopolskie, Poland',
            'Wilkes Barre-Scranton PA Media Market, USA',
            'Will County, Illinois, USA',
            'Williamson County, Texas, USA',
            'Wilmington MSA, NC, USA',
            'Wilmington NC Media Market, USA',
            'Wilmington [Urban Area], NC, USA',
            'Windsor CMA, ON, Canada',
            'Windsor Population Center, ON, Canada',
            'Winnebago County, Illinois, USA',
            'Winnipeg CMA, MB, Canada',
            'Winnipeg City, Division No. 11, Manitoba, Canada',
            'Winnipeg Population Center, MB, Canada',
            'Winnipeg Urban Center, Canada',
            'Winston-Salem MSA, NC, USA',
            'Winston-Salem [Urban Area], NC, USA',
            'Winter Haven [Urban Area], FL, USA',
            'Wisconsin, USA',
            'Worcester County, Massachusetts, USA',
            'Worcester MSA, MA-CT, USA',
            'Worcester [Urban Area], MA-CT, USA',
            'Wuhan Urban Center, China',
            'Wyoming, USA',
            'Xi\'an Urban Center, China',
            'Xiamen Urban Center, China',
            'Xinjiang Uygur Zizhiqu, China',
            'Xizang Zizhiqu, China',
            'Yakima County, Washington, USA',
            'Yakima MSA, WA, USA',
            'Yakima-Pasco-Richland-Kennewick WA Media Market, USA',
            'Yangon Urban Center, Myanmar',
            'Yangon, Myanmar',
            'Yaounde Urban Center, Cameroon',
            'YeDebub Bih\u0113roch Bih\u0113reseboch na Hizboch, Ethiopia',
            'Yemen',
            'Yobe, Nigeria',
            'Yogyakarta Urban Center, Indonesia',
            'Yogyakarta, Indonesia',
            'York County, Pennsylvania, USA',
            'York County, South Carolina, USA',
            'York Regional municipality, Ontario, Canada',
            'York-Hanover MSA, PA, USA',
            'Youngstown OH Media Market, USA',
            'Youngstown [Urban Area], OH, USA',
            'Youngstown-Warren-Boardman MSA, OH-PA, USA',
            'Yuma AZ-El Centro CA Media Market, USA',
            'Yunnan Sheng, China',
            'Zambia',
            'Zamb\u00e9zia, Mozambique',
            'Zamfara, Nigeria',
            'Zhejiang Sheng, China',
            'Zhengzhou Urban Center, China',
            'Zimbabwe',
            'Zinder, Niger',
            'Zuid-Holland, Netherlands',
            'Zulia, Venezuela',
            'nohiyahoi tobei jumhur\u00ed, Tajikistan',
            'wallonne, R\u00e9gion, Belgium',
            '\u00cele-de-France, France',
            '\u00d0\u1ed3ng Nai, Vietnam',
            '\u00d4saka, Japan',
            '\u00dcr\u00fcmqi Urban Center, China',
            '\u0100d\u012bs \u0100beba, Ethiopia',
            '\u0100mara, Ethiopia',
            '\u0100z\u0304arb\u0101yj\u0101n-e Gharb\u012b, Iran',
            '\u0100z\u0304arb\u0101yj\u0101n-e Sharq\u012b, Iran',
            '\u0130stanbul, Turkey',
            '\u0130zmir, Turkey',
            '\u015al\u0105skie, Poland',
            '\u015eal\u0101\u1e29 ad D\u012bn, Iraq',
            '\u1e28alab, Syria',
        ],
        allStatNames: [
            '2016 Presidential Election',
            '2016-2020 Swing',
            '2020 Presidential Election',
            'Arthritis %',
            'Binge drinking among adults %',
            'High blood pressure %',
            'Cancer (excluding skin cancer) %',
            'Current asthma %',
            'Coronary heart disease %',
            'Cognitive disability %',
            'COPD %',
            'Smoking %',
            'Diagnosed diabetes %',
            'Disability %',
            'Fair or poor self-rated health status %',
            'Hearing disability %',
            'Independent living disability %',
            'Chronic kidney disease %',
            'No leisure-time physical activity %',
            'Mobility disability %',
            'Obesity %',
            'Self-care disability %',
            'Sleeping less than 7 hours %',
            'Stroke %',
            'Vision disability %',
            'PW Density (r=1km)',
            'PW Density (r=1km) Change (2000-2020)',
            'PW Density (r=1km) Change (2010-2020)',
            'PW Density (r=4km)',
            'Asian %',
            'Born outside US %',
            'Born in us outside state %',
            'Born in state of residence %',
            'Black %',
            'Citizen by Birth %',
            'Citizen by Naturalization %',
            'Non-citizen %',
            'High temperature Above 90\u00b0F %',
            'High temperature Below 40\u00b0F %',
            'Undergrad Business %',
            'Undergrad Humanities %',
            'Undergrad STEM %',
            'Grad %',
            'Graduate degree [25-64] %',
            'High School %',
            'High school diploma [25-64] %',
            'Undergrad %',
            'Bachelor\'s degree [25-64] %',
            'Boomer %',
            'Gen Alpha %',
            'Gen X %',
            'Gen Z %',
            'Millennial %',
            'Silent %',
            'PW Mean Elevation',
            'Electricity heating %',
            'Utility gas heating %',
            'Hispanic %',
            'Racial Homogeneity %',
            'Racial Homogeneity Change (2000-2020) %',
            'Racial Homogeneity Change (2010-2020) %',
            'Mean sunny hours',
            'Household income > C$100k %',
            'Household Income > $100k %',
            'Household income < C$50k %',
            'Household Income < $50k %',
            'Housing Units per Adult',
            'Individual income > C$100k %',
            'Individual Income > $100k %',
            'Individual income < C$50k %',
            'Individual Income < $50k %',
            'Accommodation and food services %',
            'Administrative and support and waste management services %',
            'Agriculture, forestry, fishing and hunting %',
            'Arts, entertainment, and recreation %',
            'Construction %',
            'Educational services %',
            'Finance and insurance %',
            'Health care and social assistance %',
            'Information %',
            'Management of companies and enterprises %',
            'Manufacturing %',
            'Mining, quarrying, and oil and gas extraction %',
            'Other services, except public administration %',
            'Professional, scientific, and technical services %',
            'Public administration %',
            'Real estate and rental and leasing %',
            'Retail trade %',
            'Transportation and warehousing %',
            'Utilities %',
            'Wholesale trade %',
            'Public Insurance %',
            'Uninsured %',
            'Private Insurance %',
            'No internet access %',
            'Only English at Home %',
            'Spanish at Home %',
            'Within 1mi of a grocery store %',
            'LICO-AT %',
            'Divorced %',
            'Mean distance to nearest Active Superfund Site',
            'Mean distance to nearest Airport',
            'Mean distance to nearest Hospital',
            'Mean distance to nearest Public School',
            'Mean high heat index',
            'Mean high temp',
            'Mean high temperature in fall',
            'Mean high temperature in spring',
            'Mean high temperature in summer',
            'Mean high temperature in winter',
            'Architecture and engineering occupations %',
            'Arts, design, entertainment, sports, and media occupations %',
            'Building and grounds cleaning and maintenance occupations %',
            'Business and financial operations occupations %',
            'Community and social service occupations %',
            'Computer and mathematical occupations %',
            'Construction and extraction occupations %',
            'Educational instruction, and library occupations %',
            'Farming, fishing, and forestry occupations %',
            'Firefighting and prevention, and other protective service workers including supervisors %',
            'Food preparation and serving related occupations %',
            'Health diagnosing and treating practitioners and other technical occupations %',
            'Health technologists and technicians %',
            'Healthcare support occupations %',
            'Installation, maintenance, and repair occupations %',
            'Law enforcement workers including supervisors %',
            'Legal occupations %',
            'Life, physical, and social science occupations %',
            'Management occupations %',
            'Material moving occupations %',
            'Office and administrative support occupations %',
            'Personal care and service occupations %',
            'Sales and related occupations %',
            'Transportation occupations %',
            'PW Mean % of parkland within 1km',
            'Population',
            'Population Change (2000-2020)',
            'Population Change (2010-2020)',
            'Poverty %',
            'Rainfall',
            '1BR Rent > $1500 %',
            '1BR Rent < $750 %',
            '2BR Rent > $1500 %',
            '2BR Rent < $750 %',
            'Rent/Income > 40%',
            'Rent/Income < 20%',
            'Renter %',
            'Snowfall [rain-equivalent]',
            'Cohabiting With Partner (Gay) %',
            'Traffic Fatalities Per Capita Per Year',
            'Pedestrian/Cyclist Fatalities Per Capita Per Year',
            'Commute Time > 60 min %',
            'Commute Time < 15 min %',
            'Commute Bike %',
            'Commute Car %',
            'Commute Transit %',
            'Commute Walk %',
            'Commute Work From Home %',
            'Vacancy %',
            'Households With 1+ Vehicles %',
            'White %',
            'High windspeed (>10mph) days %',
            '% units built pre-1970',
            '% units built in 2010s+',
        ],
        statPaths: [
            '2016 Presidential Election-margin',
            '2016-2020 Swing-margin',
            '2020 Presidential Election-margin',
            'ARTHRITIS_cdc_2',
            'BINGE_cdc_2',
            'BPHIGH_cdc_2',
            'CANCER_cdc_2',
            'CASTHMA_cdc_2',
            'CHD_cdc_2',
            'COGNITION_cdc_2',
            'COPD_cdc_2',
            'CSMOKING_cdc_2',
            'DIABETES_cdc_2',
            'DISABILITY_cdc_2',
            'GHLTH_cdc_2',
            'HEARING_cdc_2',
            'INDEPLIVE_cdc_2',
            'KIDNEY_cdc_2',
            'LPA_cdc_2',
            'MOBILITY_cdc_2',
            'OBESITY_cdc_2',
            'SELFCARE_cdc_2',
            'SLEEP_cdc_2',
            'STROKE_cdc_2',
            'VISION_cdc_2',
            'ad_1',
            'ad_1_change_2000',
            'ad_1_change_2010',
            'ad_4',
            'asian',
            'birthplace_non_us',
            'birthplace_us_not_state',
            'birthplace_us_state',
            'black',
            'citizenship_citizen_by_birth',
            'citizenship_citizen_by_naturalization',
            'citizenship_not_citizen',
            'days_above_90_4',
            'days_below_40_4',
            'education_field_business',
            'education_field_humanities',
            'education_field_stem',
            'education_grad',
            'education_grad_canada',
            'education_high_school',
            'education_high_school_canada',
            'education_ugrad',
            'education_ugrad_canada',
            'generation_boomer',
            'generation_genalpha',
            'generation_genx',
            'generation_genz',
            'generation_millenial',
            'generation_silent',
            'gridded_elevation',
            'heating_electricity',
            'heating_utility_gas',
            'hispanic',
            'homogeneity_250_2020',
            'homogeneity_250_diff_2000',
            'homogeneity_250_diff_2010',
            'hours_sunny_4',
            'household_income_above_100_cad',
            'household_income_over_100k',
            'household_income_under_50cad',
            'household_income_under_50k',
            'housing_per_pop',
            'individual_income_above_100_cad',
            'individual_income_over_100k',
            'individual_income_under_50cad',
            'individual_income_under_50k',
            'industry_accommodation_and_food_services',
            'industry_administrative_and_support_and_waste_management_services',
            'industry_agriculture,_forestry,_fishing_and_hunting',
            'industry_arts,_entertainment,_and_recreation',
            'industry_construction',
            'industry_educational_services',
            'industry_finance_and_insurance',
            'industry_health_care_and_social_assistance',
            'industry_information',
            'industry_management_of_companies_and_enterprises',
            'industry_manufacturing',
            'industry_mining,_quarrying,_and_oil_and_gas_extraction',
            'industry_other_services,_except_public_administration',
            'industry_professional,_scientific,_and_technical_services',
            'industry_public_administration',
            'industry_real_estate_and_rental_and_leasing',
            'industry_retail_trade',
            'industry_transportation_and_warehousing',
            'industry_utilities',
            'industry_wholesale_trade',
            'insurance_coverage_govt',
            'insurance_coverage_none',
            'insurance_coverage_private',
            'internet_no_access',
            'language_english_only',
            'language_spanish',
            'lapop1share_usda_fra_1',
            'lico_at_canada',
            'marriage_divorced',
            'mean_dist_Active Superfund Site_updated',
            'mean_dist_Airport_updated',
            'mean_dist_Hospital_updated',
            'mean_dist_Public School_updated',
            'mean_high_heat_index_4',
            'mean_high_temp_4',
            'mean_high_temp_fall_4',
            'mean_high_temp_spring_4',
            'mean_high_temp_summer_4',
            'mean_high_temp_winter_4',
            'occupation_architecture_and_engineering_occupations',
            'occupation_arts,_design,_entertainment,_sports,_and_media_occupations',
            'occupation_building_and_grounds_cleaning_and_maintenance_occupations',
            'occupation_business_and_financial_operations_occupations',
            'occupation_community_and_social_service_occupations',
            'occupation_computer_and_mathematical_occupations',
            'occupation_construction_and_extraction_occupations',
            'occupation_educational_instruction,_and_library_occupations',
            'occupation_farming,_fishing,_and_forestry_occupations',
            'occupation_firefighting_and_prevention,_and_other_protective_service_workers_including_supervisors',
            'occupation_food_preparation_and_serving_related_occupations',
            'occupation_health_diagnosing_and_treating_practitioners_and_other_technical_occupations',
            'occupation_health_technologists_and_technicians',
            'occupation_healthcare_support_occupations',
            'occupation_installation,_maintenance,_and_repair_occupations',
            'occupation_law_enforcement_workers_including_supervisors',
            'occupation_legal_occupations',
            'occupation_life,_physical,_and_social_science_occupations',
            'occupation_management_occupations',
            'occupation_material_moving_occupations',
            'occupation_office_and_administrative_support_occupations',
            'occupation_personal_care_and_service_occupations',
            'occupation_sales_and_related_occupations',
            'occupation_transportation_occupations',
            'park_percent_1km_v2',
            'population',
            'population_change_2000',
            'population_change_2010',
            'poverty_below_line',
            'rainfall_4',
            'rent_1br_over_1500',
            'rent_1br_under_750',
            'rent_2br_over_1500',
            'rent_2br_under_750',
            'rent_burden_over_40',
            'rent_burden_under_20',
            'rent_or_own_rent',
            'snowfall_4',
            'sors_cohabiting_partnered_gay',
            'traffic_fatalities_last_decade_per_capita',
            'traffic_fatalities_ped_last_decade_per_capita',
            'transportation_commute_time_over_60',
            'transportation_commute_time_under_15',
            'transportation_means_bike',
            'transportation_means_car',
            'transportation_means_transit',
            'transportation_means_walk',
            'transportation_means_worked_at_home',
            'vacancy',
            'vehicle_ownership_at_least_1',
            'white',
            'wind_speed_over_10mph_4',
            'year_built_1969_or_earlier',
            'year_built_2010_or_later',
        ],
        statQuestionNames: [
            '!FULL Which voted more for Clinton in the 2016 presidential election?',
            '!FULL Which swung towards Democrats more from 2016 to 2020?',
            '!FULL Which voted more for Biden in the 2020 presidential election?',
            'higher % of adults with arthritis',
            'higher % of adults who binge drink',
            'higher % of adults with high blood pressure',
            'higher % of adults with cancer (excluding skin cancer)',
            'higher % of adults with asthma',
            'higher % of adults with coronary heart disease',
            'higher % of adults with a cognitive disability',
            'higher % of adults with COPD !TOOLTIP (Chronic obstructive pulmonary disease, a lung disease linked to smoking)',
            'higher % of adults who smoke',
            'higher % of adults with diagnosed diabetes',
            'higher % of adults with a disability',
            'higher % of adults with a fair or poor self-rated health status',
            'higher % of adults with a hearing disability',
            'higher % of adults with an independent living disability',
            'higher % of adults with chronic kidney disease',
            'higher % of adults who don\'t exercise (do leisure-time physical activity)',
            'higher % of adults with a mobility disability',
            'higher % of adults with obesity',
            'higher % of adults with a self-care disability',
            'higher % of adults who sleep less than 7 hours',
            'higher % of adults who have had a stroke',
            'higher % of adults with a vision disability',
            'higher population-weighted density (r=1km)!TOOLTIP Population-weighted density is computed by computing the density within the given radius for each person in the region and then averaging the results. This is a better measure of the density that people actually experience.',
            'higher % increase in population-weighted density (r=1km) from 2000 to 2020!TOOLTIP Population-weighted density is computed by computing the density within the given radius for each person in the region and then averaging the results. This is a better measure of the density that people actually experience.',
            'higher % increase in population-weighted density (r=1km) from 2010 to 2020!TOOLTIP Population-weighted density is computed by computing the density within the given radius for each person in the region and then averaging the results. This is a better measure of the density that people actually experience.',
            'higher population-weighted density (r=4km)!TOOLTIP Population-weighted density is computed by computing the density within the given radius for each person in the region and then averaging the results. This is a better measure of the density that people actually experience.',
            'higher % of people who are Asian',
            'higher % of people who were born outside the US',
            'higher % of people who were born in the US and outside their state of residence',
            'higher % of people who were born in their state of residence',
            'higher % of people who are Black',
            'higher % of residents who are citizens by birth',
            'higher % of residents who are citizens by naturalization',
            'higher % of residents who are non-citizens',
            'higher % of hot days (days with high temp over 90\u00b0F, population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher % of cold days (days with high temp under 40\u00b0F, population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            '!FULL Which has more people with a business degree, as a percentage of the overall population?',
            '!FULL Which has more people with a humanities degree, as a percentage of the overall population?',
            '!FULL Which has more people with a STEM degree, as a percentage of the overall population?',
            'higher % of people who have a graduate degree',
            'higher % of people between 25 and 64 who have a graduate degree',
            'higher % of people who have at least a high school diploma',
            'higher % of people between 25 and 64 who have at least a high school diploma',
            'higher % of people who have at least an undergrad degree',
            'higher % of people between 25 and 64 who have at least an undergrad degree',
            'higher % of people who are boomers!TOOLTIP born between 1946 and 1966',
            'higher % of people who are gen alpha!TOOLTIP born between 2012 and 2021',
            'higher % of people who are gen x!TOOLTIP born between 1967 and 1981',
            'higher % of people who are gen z!TOOLTIP born between 1997 and 2011',
            'higher % of people who are millennials!TOOLTIP born between 1982 and 1996',
            'higher % of people who are silent generation!TOOLTIP born before 1946',
            'higher population-weighted mean elevation !TOOLTIP Population weighted elevation/hilliness statistics are calculated by computing the statistic for each person in the region and then averaging the results.',
            '!FULL Which has a larger % of homes heated using electricity?',
            '!FULL Which has a larger % of homes heated using utility gas?',
            'higher % of people who are Hispanic',
            'lower racial diversity!TOOLTIP We define racial diversity as the average probability a person selecting a random person in a 250m radius will select someone of a different race',
            '!FULL Which diversified less between 2000 and 2020?!TOOLTIP We define racial diversity as the average probability a person selecting a random person in a 250m radius will select someone of a different race',
            '!FULL Which diversified less between 2010 and 2020?!TOOLTIP We define racial diversity as the average probability a person selecting a random person in a 250m radius will select someone of a different race',
            '!FULL Which has more hours of sun per day on average? (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher % of households that have household income over C$100k',
            'higher % of households who have household income over $100k',
            'higher % of households that have household income under C$50k',
            'higher % of households who have household income under $50k',
            'higher number of housing units per adult',
            'higher % of people who have individual income over C$100k',
            'higher % of people who have individual income over $100k',
            'higher % of people who have individual income under C$50k',
            'higher % of people who have individual income under $50k',
            'higher % of workers employed in the accommodation and food services industry!TOOLTIP hotels, restaurants, bars, caterers, food services contractors, etc.',
            'higher % of workers employed in the administrative/support/waste management services industries!TOOLTIP office administrative services, employment services, business support services, waste management and remediation services, etc.',
            'higher % of workers employed in the agriculture, forestry, fishing, and hunting industries!TOOLTIP agriculture, forestry, logging, fishing, and hunting',
            'higher % of workers employed in the arts, entertainment, and recreation industry!TOOLTIP performing arts companies, sports, museums, amusement parks, gambling industries, etc.',
            'higher % of workers employed in the construction industry!TOOLTIP construction',
            'higher % of workers employed in the educational services industry!TOOLTIP elementary and secondary schools, colleges, universities,etc.',
            'higher % of workers employed in the finance and insurance industry!TOOLTIP banks, credit unions, insurance carriers, securities and commodity contracts, etc.',
            'higher % of workers employed in the health care and social assistance industry!TOOLTIP hospitals, nursing care facilities, offices of physicians, dentists, day care centers, etc.',
            'higher % of workers employed in the information industry!TOOLTIP publishing industries, software publishers, data processing services, broadcasting, libraries, etc.',
            'higher % of workers employed in the management industry!TOOLTIP management of companies and enterprises',
            'higher % of workers employed in the manufacturing industry!TOOLTIP metal manufacturing, machinery manufacturing, cement and concrete product manufacturing, sawmills, wood product manufacturing, food and beverage manufacturing, textile mills, apparel manufacturing, paper manufacturing, printing, chemical manufacturing, plastics and rubber products manufacturing, etc.',
            'higher % of workers employed in the mining, quarrying, and oil/gas extraction industries!TOOLTIP mining, quarrying, and oil and gas extraction',
            'higher % of workers employed in other service industries, except public administration!TOOLTIP repair and maintenance, personal care services, beauty salons, funeral homes, religious organizations, civic and social organizations, etc.',
            'higher % of workers employed in the professional, scientific, and technical services industry!TOOLTIP legal services, accounting services, architectural services, engineering services, computer systems design services, management consulting services, etc.',
            'higher % of workers employed in public administration!TOOLTIP executive offices, legislative bodies, public finance activities, public order and safety activities, etc.',
            'higher % of workers employed in the real estate and rental and leasing industry!TOOLTIP real estate, rental and leasing, lessors of real estate, etc.',
            'higher % of workers employed in the retail trade industry!TOOLTIP retailers of all kinds of goods, including stores, gas stations, florists, etc.',
            'higher % of workers employed in the transportation and warehousing industry!TOOLTIP transportation by all means, taxis, buses, sightseeing transportation, warehousing, etc.',
            'higher % of workers employed in the utilities industry!TOOLTIP electrical power generation, transmission, and distribution; natural gas distribution; water, sewage, and other systems',
            'higher % of workers employed in the wholesale trade industry!TOOLTIP wholesalers of all kinds of goods',
            'higher % of people who are on public insurance',
            'higher % of people who are uninsured',
            'higher % of people who are on private insurance',
            'higher % of people who have no internet access',
            'higher % of people who only speak english at home',
            'higher % of people who speak spanish at home',
            '!FULL Which has more access to grocery stores (higher % of people within 1mi of a grocery store)?!TOOLTIP The USDA defines a grocery store as a \'supermarket, supercenter, or large grocery store.\'',
            'higher % of people who are low income based on the Low-income cut-offs, after tax (LICO-AT)',
            'higher % of people who are divorced',
            '!FULL Which has less exposure to active EPA superfund sites (higher population-weighted mean distance)?!TOOLTIP EPA superfund sites are hazardous waste sites identified by the Environmental Protection Agency.',
            '!FULL Which has less access to airports (higher population-weighted mean distance)?',
            '!FULL Which has less access to hospitals (higher population-weighted mean distance)?',
            '!FULL Which has less access to public schools (higher population-weighted mean distance)?',
            'higher mean daily high heat index (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher mean daily high temperature (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher mean daily high temperature in fall (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher mean daily high temperature in spring (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher mean daily high temperature in summer (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher mean daily high temperature in winter (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher % of workers employed as architects and engineers!TOOLTIP all kinds of engineers except software engineers, architects, surveyors, drafters, etc.',
            'higher % of workers employed in arts, design, entertainment, sports, and media occupations!TOOLTIP artists, designers, musicians, actors, dancers, athletes, journalists, editors, writers, photographers, etc.',
            'higher % of workers employed in building and grounds cleaning and maintenance occupations!TOOLTIP janitors, maids, groundskeepers (gardeners), pest control workers, etc.',
            'higher % of workers employed in business and financial operations occupations!TOOLTIP sales agents, insurance agents, compliance officers, consultants, fundraisers, accountants, auditors, HR workers, etc.',
            'higher % of workers employed in community and social service occupations!TOOLTIP social workers, therapists, counselors, probation officers, clergy, etc.',
            'higher % of workers employed in computer and mathematical occupations!TOOLTIP software developers, software QA engineers, system administrators, actuaries, operations researchers',
            'higher % of workers employed in construction and extraction occupations!TOOLTIP carpenters, electricians, plumbers, roofers, miners, etc.',
            'higher % of workers employed in educational instruction, and library occupations!TOOLTIP teachers, tutors, professors, librarians, and archivists',
            'higher % of workers employed in farming, fishing, and forestry occupations!TOOLTIP farmers, ranchers, fishers, loggers, forest workers, etc.',
            'higher % of workers employed as firefighting and prevention, and other protective service workers including supervisors!TOOLTIP firefighters, fire inspectors, correctional officers, bailiffs, etc. (effectively, all protective service workers except police officers)',
            'higher % of workers employed as food preparers or servers!TOOLTIP cooks, waiters, bartenders, fast food workers, etc.',
            'higher % of workers employed in health diagnosing and treating practitioners and other technical occupations!TOOLTIP doctors, registered nurses, physical therapists, pharmacists, dietitians, veterinarians, paramedics, etc.',
            'higher % of workers employed as health technologists and technicians!TOOLTIP radiology technicians, lab technicians, hospital machinery technicians, etc.',
            'higher % of workers employed in healthcare support occupations!TOOLTIP nursing assistants, orderlies, home health aides, massage therapists, dental assistants, etc.',
            'higher % of workers employed in installation, maintenance, and repair occupations!TOOLTIP mechanics, HVAC technicians, electricians, plumbers, etc.',
            'higher % of workers employed as law enforcement workers including supervisors!TOOLTIP police officers, detectives, etc.',
            'higher % of workers employed in legal occupations!TOOLTIP lawyers, judges, paralegals, legal assistants, etc.',
            'higher % of workers employed in life, physical, and social science occupations!TOOLTIP biological scientists, chemists, physicists, geologists, food scientists, economists, phychologists, urban planners, sociologists, scientific technicians, etc.',
            'higher % of workers employed as managers!TOOLTIP a variety of roles titled \'managers\'',
            'higher % of workers employed as material movers!TOOLTIP forklift operators, stock clerks, conveyor operators, etc.',
            'higher % of workers employed as office and administrative support workers!TOOLTIP secretaries, receptionists, data entry clerks, office clerks, mail carriers, shipping clerks, etc.',
            'higher % of workers employed in personal care and service occupations!TOOLTIP hairdressers, childcare workers, fitness trainers, funeral service workers, travel guides, animal trainers, etc.',
            'higher % of workers employed in sales and related occupations!TOOLTIP retail salespersons, cashiers, telemarketers, real estate agents, travel agents, travelling salespeople, etc.',
            'higher % of workers employed in transportation occupations!TOOLTIP truck drivers, bus drivers, taxi drivers, pilots, flight attendants, sailors, etc.',
            '!FULL Which has more access to parks (higher % of area within 1km of a park, population weighted)?',
            'higher population',
            'higher % increase in population from 2000 to 2020',
            'higher % increase in population from 2010 to 2020',
            'higher % of people who are in poverty',
            'higher rainfall (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher % of units with 1br rent over $1500',
            'higher % of units with 1br rent under $750',
            'higher % of units with 2br rent over $1500',
            'higher % of units with 2br rent under $750',
            'higher % of people whose rent is greater than 40% of their income',
            'higher % of people whose rent is less than 20% of their income',
            'higher % of people who are renters',
            'higher snowfall (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher % of people who are gay and cohabiting with a partner/spouse',
            'higher traffic fatalities per capita between 2013 and 2022!TOOLTIP traffic fatalities in the region, divided by the population of the region',
            'higher pedestrian/cyclist fatalities per capita between 2013 and 2022!TOOLTIP pedestrian and cyclist fatalities in the region, divided by the population of the region',
            'higher % of people who have commute time over 60 min',
            'higher % of people who have commute time under 15 min',
            'higher % of people who commute by bike',
            'higher % of people who commute by car',
            'higher % of people who commute by transit',
            'higher % of people who commute by walking',
            'higher % of people who work from home',
            'higher % of units that are vacant',
            'higher % of households with at least 1 vehicle',
            'higher % of people who are White',
            'higher % of days with wind speed over 10mph (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher % units built pre-1970',
            'higher % units built in 2010s+',
        ],
        questionDistribution: [
            [
                {
                    path: 'quiz_sampling_info/1/q1/0.gz',
                    totalP: 0.13598239421844482,
                },
                {
                    path: 'quiz_sampling_info/1/q1/1.gz',
                    totalP: 0.09757756441831589,
                },
                {
                    path: 'quiz_sampling_info/1/q1/2.gz',
                    totalP: 0.08843813836574554,
                },
                {
                    path: 'quiz_sampling_info/1/q1/3.gz',
                    totalP: 0.10184188187122345,
                },
                {
                    path: 'quiz_sampling_info/1/q1/4.gz',
                    totalP: 0.11853096634149551,
                },
                {
                    path: 'quiz_sampling_info/1/q1/5.gz',
                    totalP: 0.08597700297832489,
                },
                {
                    path: 'quiz_sampling_info/1/q1/6.gz',
                    totalP: 0.12596189975738525,
                },
                {
                    path: 'quiz_sampling_info/1/q1/7.gz',
                    totalP: 0.10355039685964584,
                },
                {
                    path: 'quiz_sampling_info/1/q1/8.gz',
                    totalP: 0.12694931030273438,
                },
                {
                    path: 'quiz_sampling_info/1/q1/9.gz',
                    totalP: 0.015190508216619492,
                },
            ],
            [
                {
                    path: 'quiz_sampling_info/1/q2/0.gz',
                    totalP: 0.14673025906085968,
                },
                {
                    path: 'quiz_sampling_info/1/q2/1.gz',
                    totalP: 0.09321416169404984,
                },
                {
                    path: 'quiz_sampling_info/1/q2/2.gz',
                    totalP: 0.1077500656247139,
                },
                {
                    path: 'quiz_sampling_info/1/q2/3.gz',
                    totalP: 0.09002871066331863,
                },
                {
                    path: 'quiz_sampling_info/1/q2/4.gz',
                    totalP: 0.17028813064098358,
                },
                {
                    path: 'quiz_sampling_info/1/q2/5.gz',
                    totalP: 0.06415405124425888,
                },
                {
                    path: 'quiz_sampling_info/1/q2/6.gz',
                    totalP: 0.12868370115756989,
                },
                {
                    path: 'quiz_sampling_info/1/q2/7.gz',
                    totalP: 0.1306871622800827,
                },
                {
                    path: 'quiz_sampling_info/1/q2/8.gz',
                    totalP: 0.06846379488706589,
                },
            ],
            [
                {
                    path: 'quiz_sampling_info/1/q3/0.gz',
                    totalP: 0.11694368720054626,
                },
                {
                    path: 'quiz_sampling_info/1/q3/1.gz',
                    totalP: 0.11449010670185089,
                },
                {
                    path: 'quiz_sampling_info/1/q3/2.gz',
                    totalP: 0.08648458868265152,
                },
                {
                    path: 'quiz_sampling_info/1/q3/3.gz',
                    totalP: 0.0778813287615776,
                },
                {
                    path: 'quiz_sampling_info/1/q3/4.gz',
                    totalP: 0.12422139942646027,
                },
                {
                    path: 'quiz_sampling_info/1/q3/5.gz',
                    totalP: 0.11182024329900742,
                },
                {
                    path: 'quiz_sampling_info/1/q3/6.gz',
                    totalP: 0.03959804028272629,
                },
                {
                    path: 'quiz_sampling_info/1/q3/7.gz',
                    totalP: 0.04810687154531479,
                },
                {
                    path: 'quiz_sampling_info/1/q3/8.gz',
                    totalP: 0.11842415481805801,
                },
                {
                    path: 'quiz_sampling_info/1/q3/9.gz',
                    totalP: 0.1262359768152237,
                },
                {
                    path: 'quiz_sampling_info/1/q3/10.gz',
                    totalP: 0.03579362481832504,
                },
            ],
            [
                {
                    path: 'quiz_sampling_info/1/q4/0.gz',
                    totalP: 0.10969769209623337,
                },
                {
                    path: 'quiz_sampling_info/1/q4/1.gz',
                    totalP: 0.12897737324237823,
                },
                {
                    path: 'quiz_sampling_info/1/q4/2.gz',
                    totalP: 0.08638643473386765,
                },
                {
                    path: 'quiz_sampling_info/1/q4/3.gz',
                    totalP: 0.10407009720802307,
                },
                {
                    path: 'quiz_sampling_info/1/q4/4.gz',
                    totalP: 0.12337031215429306,
                },
                {
                    path: 'quiz_sampling_info/1/q4/5.gz',
                    totalP: 0.0716659277677536,
                },
                {
                    path: 'quiz_sampling_info/1/q4/6.gz',
                    totalP: 0.08976385742425919,
                },
                {
                    path: 'quiz_sampling_info/1/q4/7.gz',
                    totalP: 0.031971629709005356,
                },
                {
                    path: 'quiz_sampling_info/1/q4/8.gz',
                    totalP: 0.032241206616163254,
                },
                {
                    path: 'quiz_sampling_info/1/q4/9.gz',
                    totalP: 0.08614720404148102,
                },
                {
                    path: 'quiz_sampling_info/1/q4/10.gz',
                    totalP: 0.07542141526937485,
                },
                {
                    path: 'quiz_sampling_info/1/q4/11.gz',
                    totalP: 0.06028646603226662,
                },
            ],
            [
                {
                    path: 'quiz_sampling_info/1/q5/0.gz',
                    totalP: 0.024335427209734917,
                },
                {
                    path: 'quiz_sampling_info/1/q5/1.gz',
                    totalP: 0.027490895241498947,
                },
                {
                    path: 'quiz_sampling_info/1/q5/2.gz',
                    totalP: 0.027777355164289474,
                },
                {
                    path: 'quiz_sampling_info/1/q5/3.gz',
                    totalP: 0.02645501121878624,
                },
                {
                    path: 'quiz_sampling_info/1/q5/4.gz',
                    totalP: 0.027246614918112755,
                },
                {
                    path: 'quiz_sampling_info/1/q5/5.gz',
                    totalP: 0.031373098492622375,
                },
                {
                    path: 'quiz_sampling_info/1/q5/6.gz',
                    totalP: 0.012537983246147633,
                },
                {
                    path: 'quiz_sampling_info/1/q5/7.gz',
                    totalP: 0.018217990174889565,
                },
                {
                    path: 'quiz_sampling_info/1/q5/8.gz',
                    totalP: 0.01148033607751131,
                },
                {
                    path: 'quiz_sampling_info/1/q5/9.gz',
                    totalP: 0.0190809965133667,
                },
                {
                    path: 'quiz_sampling_info/1/q5/10.gz',
                    totalP: 0.02057676762342453,
                },
                {
                    path: 'quiz_sampling_info/1/q5/11.gz',
                    totalP: 0.023251129314303398,
                },
                {
                    path: 'quiz_sampling_info/1/q5/12.gz',
                    totalP: 0.021215511485934258,
                },
                {
                    path: 'quiz_sampling_info/1/q5/13.gz',
                    totalP: 0.021803798153996468,
                },
                {
                    path: 'quiz_sampling_info/1/q5/14.gz',
                    totalP: 0.020011184737086296,
                },
                {
                    path: 'quiz_sampling_info/1/q5/15.gz',
                    totalP: 0.029656967148184776,
                },
                {
                    path: 'quiz_sampling_info/1/q5/16.gz',
                    totalP: 0.028625892475247383,
                },
                {
                    path: 'quiz_sampling_info/1/q5/17.gz',
                    totalP: 0.024535421282052994,
                },
                {
                    path: 'quiz_sampling_info/1/q5/18.gz',
                    totalP: 0.039453402161598206,
                },
                {
                    path: 'quiz_sampling_info/1/q5/19.gz',
                    totalP: 0.06428781896829605,
                },
                {
                    path: 'quiz_sampling_info/1/q5/20.gz',
                    totalP: 0.01753440499305725,
                },
                {
                    path: 'quiz_sampling_info/1/q5/21.gz',
                    totalP: 0.017197761684656143,
                },
                {
                    path: 'quiz_sampling_info/1/q5/22.gz',
                    totalP: 0.025435293093323708,
                },
                {
                    path: 'quiz_sampling_info/1/q5/23.gz',
                    totalP: 0.019321205094456673,
                },
                {
                    path: 'quiz_sampling_info/1/q5/24.gz',
                    totalP: 0.01782405935227871,
                },
                {
                    path: 'quiz_sampling_info/1/q5/25.gz',
                    totalP: 0.009560183621942997,
                },
                {
                    path: 'quiz_sampling_info/1/q5/26.gz',
                    totalP: 0.045571789145469666,
                },
                {
                    path: 'quiz_sampling_info/1/q5/27.gz',
                    totalP: 0.045103639364242554,
                },
                {
                    path: 'quiz_sampling_info/1/q5/28.gz',
                    totalP: 0.00801666360348463,
                },
                {
                    path: 'quiz_sampling_info/1/q5/29.gz',
                    totalP: 0.01090974546968937,
                },
                {
                    path: 'quiz_sampling_info/1/q5/30.gz',
                    totalP: 0.04098346084356308,
                },
                {
                    path: 'quiz_sampling_info/1/q5/31.gz',
                    totalP: 0.008972760289907455,
                },
                {
                    path: 'quiz_sampling_info/1/q5/32.gz',
                    totalP: 0.007798986975103617,
                },
                {
                    path: 'quiz_sampling_info/1/q5/33.gz',
                    totalP: 0.008513270877301693,
                },
                {
                    path: 'quiz_sampling_info/1/q5/34.gz',
                    totalP: 0.009423915296792984,
                },
                {
                    path: 'quiz_sampling_info/1/q5/35.gz',
                    totalP: 0.009462327696383,
                },
                {
                    path: 'quiz_sampling_info/1/q5/36.gz',
                    totalP: 0.03476234897971153,
                },
                {
                    path: 'quiz_sampling_info/1/q5/37.gz',
                    totalP: 0.02697318233549595,
                },
                {
                    path: 'quiz_sampling_info/1/q5/38.gz',
                    totalP: 0.02597339078783989,
                },
                {
                    path: 'quiz_sampling_info/1/q5/39.gz',
                    totalP: 0.03484708070755005,
                },
                {
                    path: 'quiz_sampling_info/1/q5/40.gz',
                    totalP: 0.05640159174799919,
                },
            ],
        ],
        juxtaVersion: 1,
    },
    {
        allGeographies: [
            'AK-00, USA',
            'AL-01, USA',
            'AL-02, USA',
            'AL-03, USA',
            'AL-04, USA',
            'AL-05, USA',
            'AL-06, USA',
            'AL-07, USA',
            'AR-01, USA',
            'AR-02, USA',
            'AR-03, USA',
            'AR-04, USA',
            'AZ-01, USA',
            'AZ-02, USA',
            'AZ-03, USA',
            'AZ-04, USA',
            'AZ-05, USA',
            'AZ-06, USA',
            'AZ-07, USA',
            'AZ-08, USA',
            'AZ-09, USA',
            'Abia, Nigeria',
            'Abidjan Urban Center, Ivory Coast',
            'Abidjan, Ivory Coast',
            'Abilene-Sweetwater TX Media Market, USA',
            'Abuja Federal Capital Territory, Nigeria',
            'Ab\u016b Z\u0327aby, United Arab Emirates',
            'Accra Urban Center, Ghana',
            'Aceh, Indonesia',
            'Ad Daqahl\u012byah, Egypt',
            'Ada County, Idaho, USA',
            'Adamawa, Nigeria',
            'Adams County, Colorado, USA',
            'Addis Ababa Urban Center, Ethiopia',
            'Afghanistan',
            'Agra Urban Center, India',
            'Aguadilla-Isabela MSA, PR, USA',
            'Ahmedabad Urban Center, India',
            'Aiti, Japan',
            'Akron MSA, OH, USA',
            'Akron [Urban Area], OH, USA',
            'Akwa Ibom, Nigeria',
            'Al Ba\u015frah, Iraq',
            'Al Ba\u1e29r al A\u1e29mar, Sudan',
            'Al Buh\u0327ayrah, Egypt',
            'Al Fayy\u016bm, Egypt',
            'Al Gharb\u012byah, Egypt',
            'Al Iskandariyah, Egypt',
            'Al Jaz\u012brah, Sudan',
            'Al J\u012bzah, Egypt',
            'Al Khar\u0163\u016bm, Sudan',
            'Al Miny\u0101, Egypt',
            'Al Min\u016bf\u012byah, Egypt',
            'Al Qaly\u016bb\u012byah, Egypt',
            'Al Q\u0101hirah, Egypt',
            'Al \u1e28udaydah, Yemen',
            'Al \u2018A\u0305\u015fimah, Jordan',
            'Alabama, USA',
            'Alachua County, Florida, USA',
            'Alagoas, Brazil',
            'Alameda County, California, USA',
            'Alaska, USA',
            'Albania',
            'Albany County, New York, USA',
            'Albany GA Media Market, USA',
            'Albany-Schenectady [Urban Area], NY, USA',
            'Albany-Schenectady-Troy MSA, NY, USA',
            'Albany-Schenectady-Troy NY Media Market, USA',
            'Alberta, Canada',
            'Albuquerque MSA, NM, USA',
            'Albuquerque Urban Center, USA',
            'Albuquerque [Urban Area], NM, USA',
            'Albuquerque city, New Mexico, USA',
            'Albuquerque-Santa Fe NM Media Market, USA',
            'Alexandria Urban Center, Egypt',
            'Algeria',
            'Algiers Urban Center, Algeria',
            'Allegheny County, Pennsylvania, USA',
            'Allen County, Indiana, USA',
            'Allentown Urban Center, USA',
            'Allentown-Bethlehem [Urban Area], PA-NJ, USA',
            'Allentown-Bethlehem-Easton MSA, PA-NJ, USA',
            'Almaty oblysy, Kazakhstan',
            'Amarillo MSA, TX, USA',
            'Amarillo TX Media Market, USA',
            'Amazonas, Brazil',
            'Amman Urban Center, Jordan',
            'Am\u0101nat al \u2018\u0100\u015fimah [city], Yemen',
            'An N\u012bl al Abya\u1e11, Sudan',
            'Anaheim city, California, USA',
            'Analamanga, Madagascar',
            'Anambra, Nigeria',
            'Anchorage AK Media Market, USA',
            'Anchorage MSA, AK, USA',
            'Anchorage Municipality, Alaska, USA',
            'Anchorage municipality, Alaska, USA',
            'Andaluc\u00eda, Spain',
            'Andhra Pradesh, India',
            'Andijon, Uzbekistan',
            'Angola',
            'Anhui Sheng, China',
            'Ankara Urban Center, Turkey',
            'Ankara, Turkey',
            'Ann Arbor MSA, MI, USA',
            'Ann Arbor [Urban Area], MI, USA',
            'Anne Arundel County, Maryland, USA',
            'Anoka County, Minnesota, USA',
            'Antalya, Turkey',
            'Antananarivo Urban Center, Madagascar',
            'Antioch Urban Center, USA',
            'Antioch [Urban Area], CA, USA',
            'Antioquia, Colombia',
            'Ar Riy\u0101\u1e11, Saudi Arabia',
            'Arapahoe County, Colorado, USA',
            'Arb\u012bl, Iraq',
            'Argentina',
            'Arizona, USA',
            'Arkansas, USA',
            'Arlington city, Texas, USA',
            'Armenia',
            'Asansol Urban Center, India',
            'Ash Sharq\u012byah, Egypt',
            'Ash Sharq\u012byah, Saudi Arabia',
            'Ashanti, Ghana',
            'Asheville MSA, NC, USA',
            'Asheville [Urban Area], NC, USA',
            'Assam, India',
            'Asy\u016b\u0163, Egypt',
            'Athens Urban Center, Greece',
            'Atlanta GA Media Market, USA',
            'Atlanta Urban Center, USA',
            'Atlanta [Urban Area], GA, USA',
            'Atlanta city, Georgia, USA',
            'Atlanta-Sandy Springs-Roswell MSA, GA, USA',
            'Atlantic City-Hammonton MSA, NJ, USA',
            'Atlantic City-Ocean City-Villas [Urban Area], NJ, USA',
            'Atlantic County, New Jersey, USA',
            'Atl\u00e1ntico, Colombia',
            'Attik\u00ed, Greece',
            'Augusta GA Media Market, USA',
            'Augusta-Richmond County MSA, GA-SC, USA',
            'Augusta-Richmond County [Urban Area], GA-SC, USA',
            'Aurora city, Colorado, USA',
            'Austin TX Media Market, USA',
            'Austin Urban Center, USA',
            'Austin [Urban Area], TX, USA',
            'Austin city, Texas, USA',
            'Austin-Round Rock MSA, TX, USA',
            'Australia',
            'Austria',
            'Auvergne-Rh\u00f4ne-Alpes, France',
            'Ayeyarwady, Myanmar',
            'Azad Jammu and Kashmir, Pakistan',
            'Azerbaijan',
            'Azerbaijan, Azerbaijan',
            'Baden-W\u00fcrttemberg, Germany',
            'Baghdad Urban Center, Iraq',
            'Baghd\u0101d, Iraq',
            'Bago, Myanmar',
            'Bahia, Brazil',
            'Baja California, Mexico',
            'Bakersfield CA Media Market, USA',
            'Bakersfield MSA, CA, USA',
            'Bakersfield Urban Center, USA',
            'Bakersfield [Urban Area], CA, USA',
            'Bakersfield city, California, USA',
            'Bali, Indonesia',
            'Balochistan, Pakistan',
            'Baltimore County, Maryland, USA',
            'Baltimore MD Media Market, USA',
            'Baltimore Urban Center, USA',
            'Baltimore [Urban Area], MD, USA',
            'Baltimore city, Maryland, USA',
            'Baltimore city-County, Maryland, USA',
            'Baltimore-Columbia-Towson MSA, MD, USA',
            'Bamako Urban Center, Mali',
            'Bamako, Mali',
            'Banaadir, Somalia',
            'Bandung Urban Center, Indonesia',
            'Bangkok Urban Center, Thailand',
            'Bangladesh',
            'Bangor ME Media Market, USA',
            'Banten, Indonesia',
            'Ban\u012b Suwayf, Egypt',
            'Barcelona Urban Center, Spain',
            'Barishal, Bangladesh',
            'Barnstable Town [Urban Area], MA, USA',
            'Bas-Sassandra, Ivory Coast',
            'Bashkortostan, Respublika, Russia',
            'Basn\u0101hira pa\u1e37\u0101ta, Sri Lanka',
            'Batangas, Philippines',
            'Baton Rouge LA Media Market, USA',
            'Baton Rouge MSA, LA, USA',
            'Baton Rouge Urban Center, USA',
            'Baton Rouge [Urban Area], LA, USA',
            'Bauchi, Nigeria',
            'Bayelsa, Nigeria',
            'Bayern, Germany',
            'Beaumont-Port Arthur MSA, TX, USA',
            'Beaumont-Port Arthur TX Media Market, USA',
            'Beijing Shi, China',
            'Beijing Urban Center, China',
            'Belarus',
            'Belgium',
            'Bell County, Texas, USA',
            'Belo Horizonte Urban Center, Brazil',
            'Bengaluru Urban Center, India',
            'Benin',
            'Benton County, Arkansas, USA',
            'Benue, Nigeria',
            'Bergen County, New Jersey, USA',
            'Berks County, Pennsylvania, USA',
            'Berlin Urban Center, Germany',
            'Berlin, Germany',
            'Bernalillo County, New Mexico, USA',
            'Bexar County, Texas, USA',
            'Bih\u0101r, India',
            'Billings MT Media Market, USA',
            'Biloxi-Gulfport MS Media Market, USA',
            'Binghamton NY Media Market, USA',
            'Birmingham (Anniston & Tuscaloosa) AL Media Market, USA',
            'Birmingham Urban Center, United Kingdom',
            'Birmingham [Urban Area], AL, USA',
            'Birmingham-Hoover MSA, AL, USA',
            'Bluefield-Beckley-Oak Hill WV Media Market, USA',
            'Bogota Urban Center, Colombia',
            'Boise City MSA, ID, USA',
            'Boise City [Urban Area], ID, USA',
            'Boise ID Media Market, USA',
            'Boise Urban Center, USA',
            'Bolivia',
            'Bonita Springs-Estero [Urban Area], FL, USA',
            'Borno, Nigeria',
            'Bosnia and Herzegovina',
            'Boston MA (Manchester NH) Media Market, USA',
            'Boston Urban Center, USA',
            'Boston [Urban Area], MA-NH, USA',
            'Boston city, Massachusetts, USA',
            'Boston-Cambridge-Newton MSA, MA-NH, USA',
            'Botswana',
            'Boulder County, Colorado, USA',
            'Boulder MSA, CO, USA',
            'Bourgogne-Franche-Comt\u00e9, France',
            'Bradenton Urban Center, USA',
            'Bradenton-Sarasota-Venice [Urban Area], FL, USA',
            'Brampton City, Peel Regional municipality, Ontario, Canada',
            'Brandenburg, Germany',
            'Brazil',
            'Brazoria County, Texas, USA',
            'Brazzaville Urban Center, Congo',
            'Bremerton-Silverdale MSA, WA, USA',
            'Bretagne, France',
            'Brevard County, Florida, USA',
            'Brick Urban Center, USA',
            'Bridgeport Urban Center, USA',
            'Bridgeport-Stamford [Urban Area], CT-NY, USA',
            'Bridgeport-Stamford-Norwalk MSA, CT, USA',
            'Bristol County, Massachusetts, USA',
            'British Columbia, Canada',
            'Bronx County, New York, USA',
            'Broward County, Florida, USA',
            'Brown County, Wisconsin, USA',
            'Brownsville-Harlingen MSA, TX, USA',
            'Bucks County, Pennsylvania, USA',
            'Buenos Aires Urban Center, Argentina',
            'Buenos Aires, Argentina',
            'Buffalo NY Media Market, USA',
            'Buffalo Urban Center, USA',
            'Buffalo [Urban Area], NY, USA',
            'Buffalo city, New York, USA',
            'Buffalo-Cheektowaga-Niagara Falls MSA, NY, USA',
            'Bulacan, Philippines',
            'Bulgaria',
            'Buncombe County, North Carolina, USA',
            'Burkina Faso',
            'Burlington County, New Jersey, USA',
            'Burlington VT-Plattsburgh NY Media Market, USA',
            'Bursa, Turkey',
            'Burundi',
            'Busan Urban Center, South Korea',
            'Busan-gwangyeoksi, South Korea',
            'Butler County, Ohio, USA',
            'B\u00e9ni Mellal-Kh\u00e9nifra, Morocco',
            'B\u0101bil, Iraq',
            'B\u0101gmat\u012b, Nepal',
            'CA-01, USA',
            'CA-02, USA',
            'CA-03, USA',
            'CA-04, USA',
            'CA-05, USA',
            'CA-06, USA',
            'CA-07, USA',
            'CA-08, USA',
            'CA-09, USA',
            'CA-10, USA',
            'CA-11, USA',
            'CA-12, USA',
            'CA-13, USA',
            'CA-14, USA',
            'CA-15, USA',
            'CA-16, USA',
            'CA-17, USA',
            'CA-18, USA',
            'CA-19, USA',
            'CA-20, USA',
            'CA-21, USA',
            'CA-22, USA',
            'CA-23, USA',
            'CA-24, USA',
            'CA-25, USA',
            'CA-26, USA',
            'CA-27, USA',
            'CA-28, USA',
            'CA-29, USA',
            'CA-30, USA',
            'CA-31, USA',
            'CA-32, USA',
            'CA-33, USA',
            'CA-34, USA',
            'CA-35, USA',
            'CA-36, USA',
            'CA-37, USA',
            'CA-38, USA',
            'CA-39, USA',
            'CA-40, USA',
            'CA-41, USA',
            'CA-42, USA',
            'CA-43, USA',
            'CA-44, USA',
            'CA-45, USA',
            'CA-46, USA',
            'CA-47, USA',
            'CA-48, USA',
            'CA-49, USA',
            'CA-50, USA',
            'CA-51, USA',
            'CA-52, USA',
            'CO-01, USA',
            'CO-02, USA',
            'CO-03, USA',
            'CO-04, USA',
            'CO-05, USA',
            'CO-06, USA',
            'CO-07, USA',
            'CO-08, USA',
            'CT-01, USA',
            'CT-02, USA',
            'CT-03, USA',
            'CT-04, USA',
            'CT-05, USA',
            'Cairo Urban Center, Egypt',
            'Calgary CMA, AB, Canada',
            'Calgary City, Division No. 6, Alberta, Canada',
            'Calgary Population Center, AB, Canada',
            'Calgary Urban Center, Canada',
            'Cali Urban Center, Colombia',
            'California, USA',
            'Cambodia',
            'Camden County, New Jersey, USA',
            'Cameron County, Texas, USA',
            'Cameroon',
            'Campania, Italy',
            'Canada',
            'Canton [Urban Area], OH, USA',
            'Canton-Massillon MSA, OH, USA',
            'Cape Coral Urban Center, USA',
            'Cape Coral [Urban Area], FL, USA',
            'Cape Coral-Fort Myers MSA, FL, USA',
            'Cape Town Urban Center, South Africa',
            'Capital Regional district, British Columbia, Canada',
            'Capitol Planning Region, Connecticut, USA',
            'Caracas Urban Center, Venezuela',
            'Casablanca Urban Center, Morocco',
            'Casablanca-Settat, Morocco',
            'Catalunya, Spain',
            'Cavite, Philippines',
            'Cear\u00e1, Brazil',
            'Cebu City Urban Center, Philippines',
            'Cebu, Philippines',
            'Cedar Rapids MSA, IA, USA',
            'Cedar Rapids-Waterloo-Iowa City & Dubuque IA Media Market, USA',
            'Central African Republic',
            'Central, Ghana',
            'Centre, Burkina Faso',
            'Centre, Cameroon',
            'Centre-Val de Loire, France',
            'Chad',
            'Champaign & Springfield-Decatur IL Media Market, USA',
            'Chandler city, Arizona, USA',
            'Changchun Urban Center, China',
            'Changsha Urban Center, China',
            'Changzhou Urban Center, China',
            'Charleston County, South Carolina, USA',
            'Charleston SC Media Market, USA',
            'Charleston [Urban Area], SC, USA',
            'Charleston-Huntington WV Media Market, USA',
            'Charleston-North Charleston MSA, SC, USA',
            'Charlotte NC Media Market, USA',
            'Charlotte Urban Center, USA',
            'Charlotte [Urban Area], NC-SC, USA',
            'Charlotte city, North Carolina, USA',
            'Charlotte-Concord-Gastonia MSA, NC-SC, USA',
            'Chatham County, Georgia, USA',
            'Chattanooga MSA, TN-GA, USA',
            'Chattanooga TN Media Market, USA',
            'Chattanooga [Urban Area], TN-GA, USA',
            'Chattogram Urban Center, Bangladesh',
            'Chattogram, Bangladesh',
            'Chelyabinskaya oblast\', Russia',
            'Chengdu Urban Center, China',
            'Chennai Urban Center, India',
            'Cherokee County, Georgia, USA',
            'Chester County, Pennsylvania, USA',
            'Chesterfield County, Virginia, USA',
            'Chhatt\u012bsgarh, India',
            'Chiapas, Mexico',
            'Chicago IL Media Market, USA',
            'Chicago Urban Center, USA',
            'Chicago [Urban Area], IL-IN, USA',
            'Chicago city, Illinois, USA',
            'Chicago-Naperville-Elgin MSA, IL-IN-WI, USA',
            'Chico-Redding CA Media Market, USA',
            'Chihuahua, Mexico',
            'Chile',
            'China',
            'Chongqing Shi, China',
            'Chongqing Urban Center, China',
            'Chula Vista city, California, USA',
            'Cincinnati MSA, OH-KY-IN, USA',
            'Cincinnati OH Media Market, USA',
            'Cincinnati Urban Center, USA',
            'Cincinnati [Urban Area], OH-KY, USA',
            'Cincinnati city, Ohio, USA',
            'Ciudad Ju\u00e1rez Urban Center, Mexico-USA',
            'Ciudad de M\u00e9xico, Mexico',
            'Clackamas County, Oregon, USA',
            'Clark County, Nevada, USA',
            'Clark County, Washington, USA',
            'Clarksburg-Weston WV Media Market, USA',
            'Clarksville MSA, TN-KY, USA',
            'Clay County, Missouri, USA',
            'Clayton County, Georgia, USA',
            'Cleveland County, Oklahoma, USA',
            'Cleveland Urban Center, USA',
            'Cleveland [Urban Area], OH, USA',
            'Cleveland city, Ohio, USA',
            'Cleveland-Akron (Canton) OH Media Market, USA',
            'Cleveland-Elyria MSA, OH, USA',
            'Coahuila de Zaragoza, Mexico',
            'Cobb County, Georgia, USA',
            'College Station-Bryan MSA, TX, USA',
            'Collier County, Florida, USA',
            'Collin County, Texas, USA',
            'Colombia',
            'Colombo Urban Center, Sri Lanka',
            'Colorado Springs MSA, CO, USA',
            'Colorado Springs Urban Center, USA',
            'Colorado Springs [Urban Area], CO, USA',
            'Colorado Springs city, Colorado, USA',
            'Colorado Springs-Pueblo CO Media Market, USA',
            'Colorado, USA',
            'Columbia MSA, SC, USA',
            'Columbia SC Media Market, USA',
            'Columbia [Urban Area], SC, USA',
            'Columbia-Jefferson City MO Media Market, USA',
            'Columbus (Ohio) Urban Center, USA',
            'Columbus GA Media Market, USA',
            'Columbus MSA, GA-AL, USA',
            'Columbus MSA, OH, USA',
            'Columbus OH Media Market, USA',
            'Columbus [Urban Area], GA-AL, USA',
            'Columbus [Urban Area], OH, USA',
            'Columbus city, Ohio, USA',
            'Columbus-Tupelo-West Point MS Media Market, USA',
            'Comilla Urban Center, Bangladesh',
            'Conakry Urban Center, Guinea',
            'Concord Urban Center, USA',
            'Concord [Urban Area], NC, USA',
            'Concord-Walnut Creek [Urban Area], CA, USA',
            'Congo',
            'Connecticut, USA',
            'Contra Costa County, California, USA',
            'Cook County, Illinois, USA',
            'Copperbelt, Zambia',
            'Corpus Christi MSA, TX, USA',
            'Corpus Christi TX Media Market, USA',
            'Corpus Christi [Urban Area], TX, USA',
            'Corpus Christi city, Texas, USA',
            'Costa Rica',
            'Crestview-Fort Walton Beach-Destin MSA, FL, USA',
            'Croatia',
            'Cross River, Nigeria',
            'Cuba',
            'Cumberland County, Maine, USA',
            'Cumberland County, North Carolina, USA',
            'Cumberland County, Pennsylvania, USA',
            'Cundinamarca, Colombia',
            'Cuyahoga County, Ohio, USA',
            'Czech Republic',
            'C\u00f3rdoba, Argentina',
            'DE-00, USA',
            'Dagestan, Respublika, Russia',
            'Dakar Urban Center, Senegal',
            'Dakar, Senegal',
            'Dakota County, Minnesota, USA',
            'Daku\u1e47u pa\u1e37\u0101ta, Sri Lanka',
            'Dalian Urban Center, China',
            'Dallas County, Texas, USA',
            'Dallas Urban Center, USA',
            'Dallas city, Texas, USA',
            'Dallas-Fort Worth-Arlington MSA, TX, USA',
            'Dallas-Fort Worth-Arlington [Urban Area], TX, USA',
            'Dallas-Ft.Worth TX Media Market, USA',
            'Damascus Urban Center, Syria',
            'Dane County, Wisconsin, USA',
            'Dar es Salaam Urban Center, Tanzania',
            'Dar es Salaam, Tanzania',
            'Dauphin County, Pennsylvania, USA',
            'Davao del Sur, Philippines',
            'Davenport IA-Rock Island-Moline IL Media Market, USA',
            'Davenport [Urban Area], IA-IL, USA',
            'Davenport-Moline-Rock Island MSA, IA-IL, USA',
            'Davidson County, Tennessee, USA',
            'Davis County, Utah, USA',
            'Dayton MSA, OH, USA',
            'Dayton OH Media Market, USA',
            'Dayton Urban Center, USA',
            'Dayton [Urban Area], OH, USA',
            'Daytona Beach-Palm Coast-Port Orange [Urban Area], FL, USA',
            'DeKalb County, Georgia, USA',
            'Delaware County, Pennsylvania, USA',
            'Delaware, USA',
            'Delhi [New Delhi] Urban Center, India',
            'Delhi, India',
            'Delta, Nigeria',
            'Deltona-Daytona Beach-Ormond Beach MSA, FL, USA',
            'Democratic Republic of the Congo',
            'Denmark',
            'Denton County, Texas, USA',
            'Denton-Lewisville [Urban Area], TX, USA',
            'Denver CO Media Market, USA',
            'Denver County, Colorado, USA',
            'Denver Urban Center, USA',
            'Denver city, Colorado, USA',
            'Denver-Aurora [Urban Area], CO, USA',
            'Denver-Aurora-Lakewood MSA, CO, USA',
            'Des Moines Urban Center, USA',
            'Des Moines [Urban Area], IA, USA',
            'Des Moines-Ames IA Media Market, USA',
            'Des Moines-West Des Moines MSA, IA, USA',
            'Detroit MI Media Market, USA',
            'Detroit Urban Center, Canada-USA',
            'Detroit [Urban Area], MI, USA',
            'Detroit city, Michigan, USA',
            'Detroit-Warren-Dearborn MSA, MI, USA',
            'Dhaka Urban Center, Bangladesh',
            'Dhaka, Bangladesh',
            'District of Columbia, District of Columbia, USA',
            'District of Columbia, USA',
            'Distrito Capital de Bogot\u00e1, Colombia',
            'Distrito Federal, Brazil',
            'Division No. 1, Newfoundland and Labrador, Canada',
            'Division No. 11, Alberta, Canada',
            'Division No. 11, Manitoba, Canada',
            'Division No. 11, Saskatchewan, Canada',
            'Division No. 6, Alberta, Canada',
            'Division No. 6, Saskatchewan, Canada',
            'Dnipropetrovska oblast, Ukraine',
            'Dodoma, Tanzania',
            'Dolno\u015bl\u0105skie, Poland',
            'Dominican Republic',
            'Donets\'ka oblast, Ukraine',
            'Dortmund Urban Center, Germany',
            'Dosso, Niger',
            'Dothan AL Media Market, USA',
            'Douala Urban Center, Cameroon',
            'Douglas County, Colorado, USA',
            'Douglas County, Nebraska, USA',
            'DuPage County, Illinois, USA',
            'Dubai Urban Center, United Arab Emirates',
            'Dubayy, United Arab Emirates',
            'Duluth MN-Superior WI Media Market, USA',
            'Duluth MSA, MN-WI, USA',
            'Durban Urban Center, South Africa',
            'Durham County, North Carolina, USA',
            'Durham Regional municipality, Ontario, Canada',
            'Durham [Urban Area], NC, USA',
            'Durham city, North Carolina, USA',
            'Durham-Chapel Hill MSA, NC, USA',
            'Dutchess County, New York, USA',
            'Duval County, Florida, USA',
            'East Baton Rouge Parish, Louisiana, USA',
            'Eastern Cape, South Africa',
            'Eastern Equatoria, South Sudan',
            'Eastern, Ghana',
            'Ebonyi, Nigeria',
            'Ecuador',
            'Edmonton CMA, AB, Canada',
            'Edmonton City, Division No. 11, Alberta, Canada',
            'Edmonton Population Center, AB, Canada',
            'Edmonton Urban Center, Canada',
            'Edo, Nigeria',
            'Egypt',
            'Ekiti, Nigeria',
            'El Paso County, Colorado, USA',
            'El Paso County, Texas, USA',
            'El Paso MSA, TX, USA',
            'El Paso TX (Las Cruces NM) Media Market, USA',
            'El Paso [Urban Area], TX-NM, USA',
            'El Paso city, Texas, USA',
            'El Salvador',
            'Emilia-Romagna, Italy',
            'England, United Kingdom',
            'Enugu, Nigeria',
            'Erie County, New York, USA',
            'Erie County, Pennsylvania, USA',
            'Erie MSA, PA, USA',
            'Erie PA Media Market, USA',
            'Eritrea',
            'Escambia County, Florida, USA',
            'Esp\u00edrito Santo, Brazil',
            'Essex County, Massachusetts, USA',
            'Essex County, New Jersey, USA',
            'Essex County, Ontario, Canada',
            'Est, Rwanda',
            'Ethiopia',
            'Eugene MSA, OR, USA',
            'Eugene OR Media Market, USA',
            'Eugene [Urban Area], OR, USA',
            'Evansville IN Media Market, USA',
            'Evansville MSA, IN-KY, USA',
            'E\u015ffah\u0101n, Iran',
            'FL-01, USA',
            'FL-02, USA',
            'FL-03, USA',
            'FL-04, USA',
            'FL-05, USA',
            'FL-06, USA',
            'FL-07, USA',
            'FL-08, USA',
            'FL-09, USA',
            'FL-10, USA',
            'FL-11, USA',
            'FL-12, USA',
            'FL-13, USA',
            'FL-14, USA',
            'FL-15, USA',
            'FL-16, USA',
            'FL-17, USA',
            'FL-18, USA',
            'FL-19, USA',
            'FL-20, USA',
            'FL-21, USA',
            'FL-22, USA',
            'FL-23, USA',
            'FL-24, USA',
            'FL-25, USA',
            'FL-26, USA',
            'FL-27, USA',
            'FL-28, USA',
            'Fairfax County, Virginia, USA',
            'Faisalabad Urban Center, Pakistan',
            'Far North, Cameroon',
            'Farg\'ona, Uzbekistan',
            'Fargo-Valley City ND Media Market, USA',
            'Fayette County, Kentucky, USA',
            'Fayetteville MSA, NC, USA',
            'Fayetteville [Urban Area], NC, USA',
            'Fayetteville-Springdale-Rogers MSA, AR-MO, USA',
            'Fayetteville-Springdale-Rogers [Urban Area], AR-MO, USA',
            'Finland',
            'Flint MSA, MI, USA',
            'Flint [Urban Area], MI, USA',
            'Flint-Saginaw-Bay City MI Media Market, USA',
            'Florida, USA',
            'Forsyth County, Georgia, USA',
            'Forsyth County, North Carolina, USA',
            'Fort Bend County, Texas, USA',
            'Fort Collins MSA, CO, USA',
            'Fort Collins [Urban Area], CO, USA',
            'Fort Smith MSA, AR-OK, USA',
            'Fort Wayne MSA, IN, USA',
            'Fort Wayne [Urban Area], IN, USA',
            'Fort Wayne city, Indiana, USA',
            'Fort Worth city, Texas, USA',
            'Fortaleza Urban Center, Brazil',
            'France',
            'Franklin County, Ohio, USA',
            'Fraser Valley Regional district, British Columbia, Canada',
            'Frederick County, Maryland, USA',
            'Free State, South Africa',
            'Fresno County, California, USA',
            'Fresno MSA, CA, USA',
            'Fresno Urban Center, USA',
            'Fresno [Urban Area], CA, USA',
            'Fresno city, California, USA',
            'Fresno-Visalia CA Media Market, USA',
            'Ft. Myers-Naples FL Media Market, USA',
            'Ft. Smith-Fayetteville-Springdale-Rogers AR Media Market, USA',
            'Ft. Wayne IN Media Market, USA',
            'Fujian Sheng, China',
            'Fulton County, Georgia, USA',
            'Fuzhou (Fujian Sheng) Urban Center, China',
            'F\u00e8s-Mekn\u00e8s, Morocco',
            'F\u0101rs, Iran',
            'GA-01, USA',
            'GA-02, USA',
            'GA-03, USA',
            'GA-04, USA',
            'GA-05, USA',
            'GA-06, USA',
            'GA-07, USA',
            'GA-08, USA',
            'GA-09, USA',
            'GA-10, USA',
            'GA-11, USA',
            'GA-12, USA',
            'GA-13, USA',
            'GA-14, USA',
            'Gainesville FL Media Market, USA',
            'Gainesville MSA, FL, USA',
            'Gainesville [Urban Area], GA, USA',
            'Galveston County, Texas, USA',
            'Gansu Sheng, China',
            'Gatineau Territory, Quebec, Canada',
            'Gatineau Ville, Gatineau Territory, Quebec, Canada',
            'Gauteng, South Africa',
            'Geita, Tanzania',
            'Genesee County, Michigan, USA',
            'Georgia',
            'Georgia, USA',
            'Germany',
            'Ghana',
            'Gilbert town, Arizona, USA',
            'Gloucester County, New Jersey, USA',
            'Goi\u00e1s, Brazil',
            'Gombe, Nigeria',
            'Grand Est, France',
            'Grand Rapids Urban Center, USA',
            'Grand Rapids [Urban Area], MI, USA',
            'Grand Rapids-Kalamazoo-Battle Creek MI Media Market, USA',
            'Grand Rapids-Wyoming MSA, MI, USA',
            'Greater Accra, Ghana',
            'Greater Bridgeport Planning Region, Connecticut, USA',
            'Greater Vancouver Regional district, British Columbia, Canada',
            'Greece',
            'Greeley MSA, CO, USA',
            'Green Bay MSA, WI, USA',
            'Green Bay-Appleton WI Media Market, USA',
            'Greene County, Missouri, USA',
            'Greensboro [Urban Area], NC, USA',
            'Greensboro city, North Carolina, USA',
            'Greensboro-High Point MSA, NC, USA',
            'Greensboro-High Point-Winston Salem NC Media Market, USA',
            'Greenville County, South Carolina, USA',
            'Greenville [Urban Area], SC, USA',
            'Greenville-Anderson-Mauldin MSA, SC, USA',
            'Greenville-New Bern-Washington NC Media Market, USA',
            'Greenville-Spartanburg SC-Asheville NC-Anderson SC Media Market, USA',
            'Guadalajara Urban Center, Mexico',
            'Guanajuato, Mexico',
            'Guangdong Sheng, China',
            'Guangxi Zhuangzu Zizhiqu, China',
            'Guangzhou Urban Center, China',
            'Guatemala',
            'Guatemala City Urban Center, Guatemala',
            'Guatemala, Guatemala',
            'Guayaquil Urban Center, Ecuador',
            'Guayas, Ecuador',
            'Guerrero, Mexico',
            'Guilford County, North Carolina, USA',
            'Guinea',
            'Guiyang (Guizhou Sheng) Urban Center, China',
            'Guizhou Sheng, China',
            'Gujar\u0101t, India',
            'Gujranwala Urban Center, Pakistan',
            'Gulfport-Biloxi-Pascagoula MSA, MS, USA',
            'Gwinnett County, Georgia, USA',
            'Gyeonggi-do, South Korea',
            'Gyeongsangbuk-do, South Korea',
            'Gyeongsangnam-do, South Korea',
            'HI-01, USA',
            'HI-02, USA',
            'HaMerkaz, Israel',
            'Hagerstown-Martinsburg MSA, MD-WV, USA',
            'Hainan Sheng, China',
            'Haiti',
            'Halifax CMA, NS, Canada',
            'Halifax County, Nova Scotia, Canada',
            'Halifax Population Center, NS, Canada',
            'Halifax Regional Municipality, Halifax County, Nova Scotia, Canada',
            'Halton Regional municipality, Ontario, Canada',
            'Hamgy\u014fng-namdo [Hamkyeongnamto], North Korea',
            'Hamilton CDR, Ontario, Canada',
            'Hamilton CMA, ON, Canada',
            'Hamilton City, Hamilton CDR, Ontario, Canada',
            'Hamilton County, Indiana, USA',
            'Hamilton County, Ohio, USA',
            'Hamilton County, Tennessee, USA',
            'Hamilton Population Center, ON, Canada',
            'Hampden County, Massachusetts, USA',
            'Hangzhou Urban Center, China',
            'Hanoi Urban Center, Vietnam',
            'Harbin Urban Center, China',
            'Harford County, Maryland, USA',
            'Harlingen-Weslaco-Brownsville-Mcallen TX Media Market, USA',
            'Harris County, Texas, USA',
            'Harrisburg [Urban Area], PA, USA',
            'Harrisburg-Carlisle MSA, PA, USA',
            'Harrisburg-Lancaster-Lebanon-York PA Media Market, USA',
            'Harrisonburg VA Media Market, USA',
            'Hartford & New Haven CT Media Market, USA',
            'Hartford Urban Center, USA',
            'Hartford [Urban Area], CT, USA',
            'Hartford-West Hartford-East Hartford MSA, CT, USA',
            'Hary\u0101na, India',
            'Hattiesburg-Laurel MS Media Market, USA',
            'Haut-Katanga, Democratic Republic of the Congo',
            'Haut-Lomami, Democratic Republic of the Congo',
            'Hauts-Bassins, Burkina Faso',
            'Hauts-de-France, France',
            'Hawaii, USA',
            'Hebei Sheng, China',
            'Hefei Urban Center, China',
            'Heilongjiang Sheng, China',
            'Henan Sheng, China',
            'Henderson city, Nevada, USA',
            'Hennepin County, Minnesota, USA',
            'Henrico County, Virginia, USA',
            'Heroica Nogales Urban Center, Mexico-USA',
            'Her\u0101t, Afghanistan',
            'Hessen, Germany',
            'Hickory-Lenoir-Morganton MSA, NC, USA',
            'Hidalgo County, Texas, USA',
            'Hidalgo, Mexico',
            'Hillsborough County, Florida, USA',
            'Hillsborough County, New Hampshire, USA',
            'Him\u0101chal Pradesh, India',
            'Hirosima, Japan',
            'Ho Chi Minh City Urban Center, Vietnam',
            'Hokkaid\u00f4, Japan',
            'Honduras',
            'Hong Kong Urban Center, China',
            'Hong Kong, China',
            'Honolulu County, Hawaii, USA',
            'Honolulu HI Media Market, USA',
            'Honolulu Urban Center, USA',
            'Honolulu [Urban Area], HI, USA',
            'Horry County, South Carolina, USA',
            'Houston TX Media Market, USA',
            'Houston Urban Center, USA',
            'Houston [Urban Area], TX, USA',
            'Houston city, Texas, USA',
            'Houston-The Woodlands-Sugar Land MSA, TX, USA',
            'Howard County, Maryland, USA',
            'Hubei Sheng, China',
            'Hudson County, New Jersey, USA',
            'Hukuoka, Japan',
            'Hunan Sheng, China',
            'Hungary',
            'Huntington-Ashland MSA, WV-KY-OH, USA',
            'Huntsville MSA, AL, USA',
            'Huntsville [Urban Area], AL, USA',
            'Huntsville-Decatur (Florence) AL Media Market, USA',
            'Hu\u00edla, Angola',
            'Hwanghae-namdo [Hwanghainamto], North Korea',
            'Hyderabad Urban Center, India',
            'Hyderabad Urban Center, Pakistan',
            'Hy\u00f4go, Japan',
            'H\u00e0 N\u1ed9i, Vietnam',
            'H\u1ed3 Ch\u00ed Minh, Vietnam',
            'IA-01, USA',
            'IA-02, USA',
            'IA-03, USA',
            'IA-04, USA',
            'ID-01, USA',
            'ID-02, USA',
            'IL-01, USA',
            'IL-02, USA',
            'IL-03, USA',
            'IL-04, USA',
            'IL-05, USA',
            'IL-06, USA',
            'IL-07, USA',
            'IL-08, USA',
            'IL-09, USA',
            'IL-10, USA',
            'IL-11, USA',
            'IL-12, USA',
            'IL-13, USA',
            'IL-14, USA',
            'IL-15, USA',
            'IL-16, USA',
            'IL-17, USA',
            'IN-01, USA',
            'IN-02, USA',
            'IN-03, USA',
            'IN-04, USA',
            'IN-05, USA',
            'IN-06, USA',
            'IN-07, USA',
            'IN-08, USA',
            'IN-09, USA',
            'Ibadan Urban Center, Nigeria',
            'Ibaraki, Japan',
            'Ibb, Yemen',
            'Idaho Falls-Pocatello ID Media Market, USA',
            'Idaho, USA',
            'Illinois, USA',
            'Iloilo, Philippines',
            'Imo, Nigeria',
            'Incheon-gwangyeoksi, South Korea',
            'India',
            'Indiana, USA',
            'Indianapolis IN Media Market, USA',
            'Indianapolis Urban Center, USA',
            'Indianapolis [Urban Area], IN, USA',
            'Indianapolis city (balance), Indiana, USA',
            'Indianapolis-Carmel-Anderson MSA, IN, USA',
            'Indio Urban Center, USA',
            'Indio-Palm Desert-Palm Springs [Urban Area], CA, USA',
            'Indonesia',
            'Indore Urban Center, India',
            'Ingham County, Michigan, USA',
            'Iowa, USA',
            'Iran',
            'Iraq',
            'Ireland',
            'Irvine city, California, USA',
            'Irving city, Texas, USA',
            'Israel',
            'Istanbul Urban Center, Turkey',
            'Italy',
            'Ituri, Democratic Republic of the Congo',
            'Ivory Coast',
            'Izmir Urban Center, Turkey',
            'Jackson County, Missouri, USA',
            'Jackson MS Media Market, USA',
            'Jackson MSA, MS, USA',
            'Jackson [Urban Area], MS, USA',
            'Jacksonville FL Media Market, USA',
            'Jacksonville MSA, FL, USA',
            'Jacksonville Urban Center, USA',
            'Jacksonville [Urban Area], FL, USA',
            'Jacksonville city, Florida, USA',
            'Jaipur Urban Center, India',
            'Jakarta Raya, Indonesia',
            'Jakarta Urban Center, Indonesia',
            'Jalisco, Mexico',
            'Jamaica',
            'Jambi, Indonesia',
            'Jammu and Kashm\u012br, India',
            'Jan\u016bb D\u0101rf\u016br, Sudan',
            'Japan',
            'Jawa Barat, Indonesia',
            'Jawa Tengah, Indonesia',
            'Jawa Timur, Indonesia',
            'Jeddah Urban Center, Saudi Arabia',
            'Jefferson County, Alabama, USA',
            'Jefferson County, Colorado, USA',
            'Jefferson County, Kentucky, USA',
            'Jefferson County, Texas, USA',
            'Jefferson Parish, Louisiana, USA',
            'Jersey City city, New Jersey, USA',
            'Jh\u0101rkhand, India',
            'Jiangsu Sheng, China',
            'Jiangxi Sheng, China',
            'Jieyang Urban Center, China',
            'Jigawa, Nigeria',
            'Jilin Sheng, China',
            'Jinan Urban Center, China',
            'Johannesburg Urban Center, South Africa',
            'Johnson County, Kansas, USA',
            'Johnstown-Altoona PA Media Market, USA',
            'Johor, Malaysia',
            'Joplin MO-Pittsburg KS Media Market, USA',
            'Jordan',
            'KS-01, USA',
            'KS-02, USA',
            'KS-03, USA',
            'KS-04, USA',
            'KY-01, USA',
            'KY-02, USA',
            'KY-03, USA',
            'KY-04, USA',
            'KY-05, USA',
            'KY-06, USA',
            'Kabul Urban Center, Afghanistan',
            'Kaduna, Nigeria',
            'Kafr ash Shaykh, Egypt',
            'Kagera, Tanzania',
            'Kalamazoo County, Michigan, USA',
            'Kalamazoo-Portage MSA, MI, USA',
            'Kalimantan Barat, Indonesia',
            'Kalimantan Selatan, Indonesia',
            'Kalimantan Tengah, Indonesia',
            'Kalimantan Timur, Indonesia',
            'Kampala Urban Center, Uganda',
            'Kanagawa, Japan',
            'Kane County, Illinois, USA',
            'Kankan, Guinea',
            'Kano Urban Center, Nigeria',
            'Kano, Nigeria',
            'Kanpur Urban Center, India',
            'Kansas City MO Media Market, USA',
            'Kansas City MSA, MO-KS, USA',
            'Kansas City Urban Center, USA',
            'Kansas City [Urban Area], MO-KS, USA',
            'Kansas City city, Missouri, USA',
            'Kansas, USA',
            'Karachi Urban Center, Pakistan',
            'Karn\u0101taka, India',
            'Kasa\u00ef Central, Democratic Republic of the Congo',
            'Kasa\u00ef Oriental, Democratic Republic of the Congo',
            'Kasa\u00ef, Democratic Republic of the Congo',
            'Kathmandu Urban Center, Nepal',
            'Katsina, Nigeria',
            'Kayes, Mali',
            'Kazakhstan',
            'Kebbi, Nigeria',
            'Kemerovskaya oblast\', Russia',
            'Kennewick-Richland MSA, WA, USA',
            'Kennewick-Richland-Pasco [Urban Area], WA, USA',
            'Kent County, Michigan, USA',
            'Kentucky, USA',
            'Kenya',
            'Kerala, India',
            'Kerm\u0101n, Iran',
            'Kern County, California, USA',
            'Kharkivska oblast, Ukraine',
            'Khartoum Urban Center, Sudan',
            'Khatlon, Tajikistan',
            'Khor\u0101s\u0101n-e Ra\u1e95av\u012b, Iran',
            'Khulna, Bangladesh',
            'Khyber Pakhtunkhwa, Pakistan',
            'Kh\u016bzest\u0101n, Iran',
            'Kigoma, Tanzania',
            'Killeen [Urban Area], TX, USA',
            'Killeen-Temple MSA, TX, USA',
            'Kindia, Guinea',
            'King County, Washington, USA',
            'Kings County, New York, USA',
            'Kingsport-Bristol-Bristol MSA, TN-VA, USA',
            'Kinshasa Urban Center, Democratic Republic of the Congo',
            'Kinshasa, Democratic Republic of the Congo',
            'Kissimmee-St. Cloud [Urban Area], FL, USA',
            'Kitchener - Cambridge - Waterloo CMA, ON, Canada',
            'Kitchener City, Waterloo Regional municipality, Ontario, Canada',
            'Kitchener Population Center, ON, Canada',
            'Kitchener Urban Center, Canada',
            'Kitsap County, Washington, USA',
            'Knox County, Tennessee, USA',
            'Knoxville MSA, TN, USA',
            'Knoxville TN Media Market, USA',
            'Knoxville [Urban Area], TN, USA',
            'Kochi Urban Center, India',
            'Kogi, Nigeria',
            'Kolkata Urban Center, India',
            'Kollam Urban Center, India',
            'Kongo Central, Democratic Republic of the Congo',
            'Koshi, Nepal',
            'Koulikoro, Mali',
            'Kozhikode Urban Center, India',
            'Krasnodyarskiy kray, Russia',
            'Krasnoyarskiy kray, Russia',
            'Krung Thep Maha Nakhon, Thailand',
            'Kuala Lumpur Urban Center, Malaysia',
            'Kumasi Urban Center, Ghana',
            'Kunming Urban Center, China',
            'Kuwait',
            'Kuwait City Urban Center, Kuwait',
            'Kwara, Nigeria',
            'Kwazulu-Natal, South Africa',
            'Kwilu, Democratic Republic of the Congo',
            'Kyiv Urban Center, Ukraine',
            'Kyiv, Ukraine',
            'Kyrgyzstan',
            'K\u0101bul, Afghanistan',
            'LA-01, USA',
            'LA-02, USA',
            'LA-03, USA',
            'LA-04, USA',
            'LA-05, USA',
            'LA-06, USA',
            'La Crosse-Eau Claire WI Media Market, USA',
            'La Paz, Bolivia',
            'Lafayette LA Media Market, USA',
            'Lafayette MSA, LA, USA',
            'Lagos Urban Center, Nigeria',
            'Lagos, Nigeria',
            'Laguna, Philippines',
            'Lahore Urban Center, Pakistan',
            'Lake Charles LA Media Market, USA',
            'Lake County, Florida, USA',
            'Lake County, Illinois, USA',
            'Lake County, Indiana, USA',
            'Lakeland [Urban Area], FL, USA',
            'Lakeland-Winter Haven MSA, FL, USA',
            'Lampung, Indonesia',
            'Lancaster County, Nebraska, USA',
            'Lancaster County, Pennsylvania, USA',
            'Lancaster MSA, PA, USA',
            'Lancaster-Manheim [Urban Area], PA, USA',
            'Lane County, Oregon, USA',
            'Lansing MI Media Market, USA',
            'Lansing [Urban Area], MI, USA',
            'Lansing-East Lansing MSA, MI, USA',
            'Lanzhou Urban Center, China',
            'Laos',
            'Laredo MSA, TX, USA',
            'Laredo TX Media Market, USA',
            'Laredo [Urban Area], TX, USA',
            'Laredo city, Texas, USA',
            'Larimer County, Colorado, USA',
            'Las Vegas NV Media Market, USA',
            'Las Vegas Urban Center, USA',
            'Las Vegas city, Nevada, USA',
            'Las Vegas-Henderson-Paradise MSA, NV, USA',
            'Las Vegas-Henderson-Paradise [Urban Area], NV, USA',
            'Laval Territory, Quebec, Canada',
            'Laval Ville, Laval Territory, Quebec, Canada',
            'Lazio, Italy',
            'Lebanon',
            'Lee County, Florida, USA',
            'Lehigh County, Pennsylvania, USA',
            'Leinster, Ireland',
            'Leon County, Florida, USA',
            'Lexington County, South Carolina, USA',
            'Lexington KY Media Market, USA',
            'Lexington Urban Center, USA',
            'Lexington-Fayette MSA, KY, USA',
            'Lexington-Fayette [Urban Area], KY, USA',
            'Lexington-Fayette urban county, Kentucky, USA',
            'Liaoning Sheng, China',
            'Liberia',
            'Libya',
            'Lilongwe, Malawi',
            'Lima Urban Center, Peru',
            'Limpopo, South Africa',
            'Lincoln & Hastings-Kearney NE Media Market, USA',
            'Lincoln MSA, NE, USA',
            'Lincoln [Urban Area], NE, USA',
            'Lincoln city, Nebraska, USA',
            'Lithuania',
            'Little Rock [Urban Area], AR, USA',
            'Little Rock-North Little Rock-Conway MSA, AR, USA',
            'Little Rock-Pine Bluff AR Media Market, USA',
            'Littoral, Cameroon',
            'Lomami, Democratic Republic of the Congo',
            'Lombardia, Italy',
            'London CMA, ON, Canada',
            'London City, Middlesex County, Ontario, Canada',
            'London Population Center, ON, Canada',
            'London Urban Center, Canada',
            'London Urban Center, United Kingdom',
            'Long Beach city, California, USA',
            'Longueuil Territory, Quebec, Canada',
            'Longueuil Ville, Longueuil Territory, Quebec, Canada',
            'Lorain County, Ohio, USA',
            'Los Angeles CA Media Market, USA',
            'Los Angeles County, California, USA',
            'Los Angeles Urban Center, USA',
            'Los Angeles city, California, USA',
            'Los Angeles-Long Beach-Anaheim MSA, CA, USA',
            'Los Angeles-Long Beach-Anaheim [Urban Area], CA, USA',
            'Loudoun County, Virginia, USA',
            'Louisiana, USA',
            'Louisville KY Media Market, USA',
            'Louisville Urban Center, USA',
            'Louisville/Jefferson County MSA, KY-IN, USA',
            'Louisville/Jefferson County [Urban Area], KY-IN, USA',
            'Louisville/Jefferson County metro government (balance), Kentucky, USA',
            'Lualaba, Democratic Republic of the Congo',
            'Luanda Urban Center, Angola',
            'Luanda, Angola',
            'Lubbock County, Texas, USA',
            'Lubbock MSA, TX, USA',
            'Lubbock TX Media Market, USA',
            'Lubbock [Urban Area], TX, USA',
            'Lubbock city, Texas, USA',
            'Lucas County, Ohio, USA',
            'Lucknow Urban Center, India',
            'Lumbin\u012b, Nepal',
            'Lusaka Urban Center, Zambia',
            'Lusaka, Zambia',
            'Luzerne County, Pennsylvania, USA',
            'Lynchburg MSA, VA, USA',
            'MA-01, USA',
            'MA-02, USA',
            'MA-03, USA',
            'MA-04, USA',
            'MA-05, USA',
            'MA-06, USA',
            'MA-07, USA',
            'MA-08, USA',
            'MA-09, USA',
            'MD-01, USA',
            'MD-02, USA',
            'MD-03, USA',
            'MD-04, USA',
            'MD-05, USA',
            'MD-06, USA',
            'MD-07, USA',
            'MD-08, USA',
            'ME-01, USA',
            'ME-02, USA',
            'MI-01, USA',
            'MI-02, USA',
            'MI-03, USA',
            'MI-04, USA',
            'MI-05, USA',
            'MI-06, USA',
            'MI-07, USA',
            'MI-08, USA',
            'MI-09, USA',
            'MI-10, USA',
            'MI-11, USA',
            'MI-12, USA',
            'MI-13, USA',
            'MN-01, USA',
            'MN-02, USA',
            'MN-03, USA',
            'MN-04, USA',
            'MN-05, USA',
            'MN-06, USA',
            'MN-07, USA',
            'MN-08, USA',
            'MO-01, USA',
            'MO-02, USA',
            'MO-03, USA',
            'MO-04, USA',
            'MO-05, USA',
            'MO-06, USA',
            'MO-07, USA',
            'MO-08, USA',
            'MS-01, USA',
            'MS-02, USA',
            'MS-03, USA',
            'MS-04, USA',
            'MT-01, USA',
            'MT-02, USA',
            'Macomb County, Michigan, USA',
            'Macon GA Media Market, USA',
            'Madagascar',
            'Madhesh, Nepal',
            'Madhya Pradesh, India',
            'Madhyama pa\u1e37\u0101ta, Sri Lanka',
            'Madison County, Alabama, USA',
            'Madison County, Illinois, USA',
            'Madison MSA, WI, USA',
            'Madison Urban Center, USA',
            'Madison WI Media Market, USA',
            'Madison [Urban Area], WI, USA',
            'Madison city, Wisconsin, USA',
            'Madrid Urban Center, Spain',
            'Madrid, Comunidad de, Spain',
            'Magway, Myanmar',
            'Mah\u0101r\u0101shtra, India',
            'Maine, USA',
            'Makkah al Mukarramah, Saudi Arabia',
            'Malawi',
            'Malaysia',
            'Mali',
            'Manatee County, Florida, USA',
            'Manchester-Nashua MSA, NH, USA',
            'Mandalay, Myanmar',
            'Manipur, India',
            'Manitoba, Canada',
            'Maputo (City), Mozambique',
            'Maputo Urban Center, Mozambique',
            'Maradi, Niger',
            'Maranh\u00e3o, Brazil',
            'Maricopa County, Arizona, USA',
            'Marin County, California, USA',
            'Marion County, Florida, USA',
            'Marion County, Indiana, USA',
            'Marion County, Oregon, USA',
            'Maritime (R\u00e9gion), Togo',
            'Markham City, York Regional municipality, Ontario, Canada',
            'Marrakech-Safi, Morocco',
            'Maryland, USA',
            'Mashhad Urban Center, Iran',
            'Massachusetts, USA',
            'Mato Grosso do Sul, Brazil',
            'Mato Grosso, Brazil',
            'Mauritania',
            'Mazowieckie, Poland',
            'Ma\u0142opolskie, Poland',
            'Mbuji-Mayi Urban Center, Democratic Republic of the Congo',
            'McAllen [Urban Area], TX, USA',
            'McAllen-Edinburg-Mission MSA, TX, USA',
            'McHenry County, Illinois, USA',
            'McKinney-Frisco [Urban Area], TX, USA',
            'McLennan County, Texas, USA',
            'Mecklenburg County, North Carolina, USA',
            'Medan Urban Center, Indonesia',
            'Medell\u00edn Urban Center, Colombia',
            'Medford-Klamath Falls OR Media Market, USA',
            'Megh\u0101laya, India',
            'Melbourne Urban Center, Australia',
            'Memphis MSA, TN-MS-AR, USA',
            'Memphis TN Media Market, USA',
            'Memphis Urban Center, USA',
            'Memphis [Urban Area], TN-MS-AR, USA',
            'Memphis city, Tennessee, USA',
            'Merced County, California, USA',
            'Merced MSA, CA, USA',
            'Mercer County, New Jersey, USA',
            'Mesa city, Arizona, USA',
            'Mexicali Urban Center, Mexico-USA',
            'Mexico',
            'Mexico City Urban Center, Mexico',
            'Miami Urban Center, USA',
            'Miami city, Florida, USA',
            'Miami-Dade County, Florida, USA',
            'Miami-Fort Lauderdale [Urban Area], FL, USA',
            'Miami-Fort Lauderdale-West Palm Beach MSA, FL, USA',
            'Miami-Ft. Lauderdale FL Media Market, USA',
            'Michigan, USA',
            'Michoac\u00e1n de Ocampo, Mexico',
            'Middlesex County, Massachusetts, USA',
            'Middlesex County, New Jersey, USA',
            'Middlesex County, Ontario, Canada',
            'Milan Urban Center, Italy',
            'Milwaukee County, Wisconsin, USA',
            'Milwaukee Urban Center, USA',
            'Milwaukee WI Media Market, USA',
            'Milwaukee [Urban Area], WI, USA',
            'Milwaukee city, Wisconsin, USA',
            'Milwaukee-Waukesha-West Allis MSA, WI, USA',
            'Minas Gerais, Brazil',
            'Minneapolis [Saint Paul] Urban Center, USA',
            'Minneapolis city, Minnesota, USA',
            'Minneapolis-St. Paul MN Media Market, USA',
            'Minneapolis-St. Paul [Urban Area], MN, USA',
            'Minneapolis-St. Paul-Bloomington MSA, MN-WI, USA',
            'Minnesota, USA',
            'Minot-Bismarck-Dickinson ND Media Market, USA',
            'Mission Viejo-Lake Forest-Laguna Niguel [Urban Area], CA, USA',
            'Mississauga City, Peel Regional municipality, Ontario, Canada',
            'Mississippi, USA',
            'Missoula MT Media Market, USA',
            'Missouri, USA',
            'Miyagi, Japan',
            'Mobile AL-Pensacola (Ft. Walton Beach) FL Media Market, USA',
            'Mobile County, Alabama, USA',
            'Mobile MSA, AL, USA',
            'Mobile [Urban Area], AL, USA',
            'Modesto MSA, CA, USA',
            'Modesto Urban Center, USA',
            'Modesto [Urban Area], CA, USA',
            'Mogadishu Urban Center, Somalia',
            'Moldova',
            'Mongolia',
            'Monmouth County, New Jersey, USA',
            'Monroe County, New York, USA',
            'Monroe LA-El Dorado AR Media Market, USA',
            'Montagnes, Ivory Coast',
            'Montana, USA',
            'Monterey County, California, USA',
            'Monterey-Salinas CA Media Market, USA',
            'Monterrey Urban Center, Mexico',
            'Montgomery (Selma) AL Media Market, USA',
            'Montgomery County, Maryland, USA',
            'Montgomery County, Ohio, USA',
            'Montgomery County, Pennsylvania, USA',
            'Montgomery County, Texas, USA',
            'Montgomery MSA, AL, USA',
            'Montgomery [Urban Area], AL, USA',
            'Montreal Urban Center, Canada',
            'Montr\u00e9al CMA, QC, Canada',
            'Montr\u00e9al Population Center, QC, Canada',
            'Montr\u00e9al Territory, Quebec, Canada',
            'Montr\u00e9al Ville, Montr\u00e9al Territory, Quebec, Canada',
            'Mopti, Mali',
            'Morocco',
            'Morogoro, Tanzania',
            'Morris County, New Jersey, USA',
            'Moscow Urban Center, Russia',
            'Moskovskaya oblast\', Russia',
            'Moskva, Russia',
            'Mozambique',
            'Mpumalanga, South Africa',
            'Multan Urban Center, Pakistan',
            'Multnomah County, Oregon, USA',
            'Mumbai Urban Center, India',
            'Municipalidad Metropolitana de Lima, Peru',
            'Mwanza, Tanzania',
            'Myanmar',
            'Mymensingh, Bangladesh',
            'Myrtle Beach-Conway-North Myrtle Beach MSA, SC-NC, USA',
            'Myrtle Beach-Florence SC Media Market, USA',
            'Myrtle Beach-North Myrtle Beach [Urban Area], SC-NC, USA',
            'M\u00e9xico, Mexico',
            'M\u0101zandar\u0101n, Iran',
            'NC-01, USA',
            'NC-02, USA',
            'NC-03, USA',
            'NC-04, USA',
            'NC-05, USA',
            'NC-06, USA',
            'NC-07, USA',
            'NC-08, USA',
            'NC-09, USA',
            'NC-10, USA',
            'NC-11, USA',
            'NC-12, USA',
            'NC-13, USA',
            'NC-14, USA',
            'ND-00, USA',
            'NE-01, USA',
            'NE-02, USA',
            'NE-03, USA',
            'NH-01, USA',
            'NH-02, USA',
            'NJ-01, USA',
            'NJ-02, USA',
            'NJ-03, USA',
            'NJ-04, USA',
            'NJ-05, USA',
            'NJ-06, USA',
            'NJ-07, USA',
            'NJ-08, USA',
            'NJ-09, USA',
            'NJ-10, USA',
            'NJ-11, USA',
            'NJ-12, USA',
            'NM-01, USA',
            'NM-02, USA',
            'NM-03, USA',
            'NV-01, USA',
            'NV-02, USA',
            'NV-03, USA',
            'NV-04, USA',
            'NY-01, USA',
            'NY-02, USA',
            'NY-03, USA',
            'NY-04, USA',
            'NY-05, USA',
            'NY-06, USA',
            'NY-07, USA',
            'NY-08, USA',
            'NY-09, USA',
            'NY-10, USA',
            'NY-11, USA',
            'NY-12, USA',
            'NY-13, USA',
            'NY-14, USA',
            'NY-15, USA',
            'NY-16, USA',
            'NY-17, USA',
            'NY-18, USA',
            'NY-19, USA',
            'NY-20, USA',
            'NY-21, USA',
            'NY-22, USA',
            'NY-23, USA',
            'NY-24, USA',
            'NY-25, USA',
            'NY-26, USA',
            'Nagoya Urban Center, Japan',
            'Nagpur Urban Center, India',
            'Nairobi City, Kenya',
            'Nairobi Urban Center, Kenya',
            'Namangan, Uzbekistan',
            'Nampula, Mozambique',
            'Nanchang (Northern Jiangxi Sheng) Urban Center, China',
            'Nanjing (Jiangsu Sheng) Urban Center, China',
            'Nanning Urban Center, China',
            'Naples Urban Center, Italy',
            'Naples-Immokalee-Marco Island MSA, FL, USA',
            'Nasarawa, Nigeria',
            'Nashville TN Media Market, USA',
            'Nashville Urban Center, USA',
            'Nashville-Davidson [Urban Area], TN, USA',
            'Nashville-Davidson metropolitan government (balance), Tennessee, USA',
            'Nashville-Davidson--Murfreesboro--Franklin MSA, TN, USA',
            'Nassau County, New York, USA',
            'National Capital Region, Philippines',
            'Naugatuck Valley Planning Region, Connecticut, USA',
            'Nebraska, USA',
            'Negros Occidental, Philippines',
            'Nei Mongol Zizhiqu, China',
            'Nepal',
            'Netherlands',
            'Nevada, USA',
            'New Brunswick, Canada',
            'New Castle County, Delaware, USA',
            'New Hampshire, USA',
            'New Haven [Urban Area], CT, USA',
            'New Haven-Milford MSA, CT, USA',
            'New Jersey, USA',
            'New Mexico, USA',
            'New Orleans LA Media Market, USA',
            'New Orleans Urban Center, USA',
            'New Orleans [Urban Area], LA, USA',
            'New Orleans city, Louisiana, USA',
            'New Orleans-Metairie MSA, LA, USA',
            'New South Wales, Australia',
            'New Taipei [Taipei] Urban Center, China',
            'New York County, New York, USA',
            'New York NY Media Market, USA',
            'New York Urban Center, USA',
            'New York city, New York, USA',
            'New York, USA',
            'New York-Jersey City-Newark [Urban Area], NY-NJ, USA',
            'New York-Newark-Jersey City MSA, NY-NJ-PA, USA',
            'New Zealand',
            'Newark city, New Jersey, USA',
            'Newfoundland and Labrador, Canada',
            'Newport News Urban Center, USA',
            'Ngh\u1ec7 An, Vietnam',
            'Niagara Regional municipality, Ontario, Canada',
            'Nicaragua',
            'Niedersachsen, Germany',
            'Niger',
            'Niger, Nigeria',
            'Nigeria',
            'Ningbo Urban Center, China',
            'Ningxia Zizhiiqu, China',
            'Nizhegorodskaya oblast\', Russia',
            'Noord-Brabant, Netherlands',
            'Noord-Holland, Netherlands',
            'Nord-Kivu, Democratic Republic of the Congo',
            'Nordrhein-Westfalen, Germany',
            'Norfolk County, Massachusetts, USA',
            'Norfolk-Portsmouth-Newport News VA Media Market, USA',
            'Normandie, France',
            'North Carolina, USA',
            'North Dakota, USA',
            'North Korea',
            'North Las Vegas city, Nevada, USA',
            'North Port-Sarasota-Bradenton MSA, FL, USA',
            'North, Cameroon',
            'North-West, South Africa',
            'Northampton County, Pennsylvania, USA',
            'Norway',
            'Norwich-New London MSA, CT, USA',
            'Nouvelle-Aquitaine, France',
            'Nova Scotia, Canada',
            'Novosibirskaya oblast\', Russia',
            'Nueces County, Texas, USA',
            'Nuevo Laredo Urban Center, Mexico-USA',
            'Nuevo Le\u00f3n, Mexico',
            'Nusa Tenggara Barat, Indonesia',
            'Nusa Tenggara Timur, Indonesia',
            'N\u012bnaw\u00e1, Iraq',
            'OH-01, USA',
            'OH-02, USA',
            'OH-03, USA',
            'OH-04, USA',
            'OH-05, USA',
            'OH-06, USA',
            'OH-07, USA',
            'OH-08, USA',
            'OH-09, USA',
            'OH-10, USA',
            'OH-11, USA',
            'OH-12, USA',
            'OH-13, USA',
            'OH-14, USA',
            'OH-15, USA',
            'OK-01, USA',
            'OK-02, USA',
            'OK-03, USA',
            'OK-04, USA',
            'OK-05, USA',
            'OR-01, USA',
            'OR-02, USA',
            'OR-03, USA',
            'OR-04, USA',
            'OR-05, USA',
            'OR-06, USA',
            'Oakland County, Michigan, USA',
            'Oakland city, California, USA',
            'Oaxaca, Mexico',
            'Ocala MSA, FL, USA',
            'Occitanie, France',
            'Ocean County, New Jersey, USA',
            'Odessa-Midland TX Media Market, USA',
            'Odisha, India',
            'Ogden Urban Center, USA',
            'Ogden-Clearfield MSA, UT, USA',
            'Ogden-Layton [Urban Area], UT, USA',
            'Ogun, Nigeria',
            'Ohio, USA',
            'Oklahoma City MSA, OK, USA',
            'Oklahoma City OK Media Market, USA',
            'Oklahoma City Urban Center, USA',
            'Oklahoma City [Urban Area], OK, USA',
            'Oklahoma City city, Oklahoma, USA',
            'Oklahoma County, Oklahoma, USA',
            'Oklahoma, USA',
            'Olympia-Tumwater MSA, WA, USA',
            'Omaha NE Media Market, USA',
            'Omaha Urban Center, USA',
            'Omaha [Urban Area], NE-IA, USA',
            'Omaha city, Nebraska, USA',
            'Omaha-Council Bluffs MSA, NE-IA, USA',
            'Oman',
            'Ondo, Nigeria',
            'Onitsha Urban Center, Nigeria',
            'Onondaga County, New York, USA',
            'Ontario, Canada',
            'Orange County, California, USA',
            'Orange County, Florida, USA',
            'Orange County, New York, USA',
            'Oregon, USA',
            'Orlando Urban Center, USA',
            'Orlando [Urban Area], FL, USA',
            'Orlando city, Florida, USA',
            'Orlando-Daytona Beach-Melbourne FL Media Market, USA',
            'Orlando-Kissimmee-Sanford MSA, FL, USA',
            'Orleans Parish, Louisiana, USA',
            'Orom\u012bya, Ethiopia',
            'Osaka [Kyoto] Urban Center, Japan',
            'Osceola County, Florida, USA',
            'Oshawa CMA, ON, Canada',
            'Oshawa Population Center, ON, Canada',
            'Oshawa Urban Center, Canada',
            'Osun, Nigeria',
            'Ottawa - Gatineau CMA, QC-ON, Canada',
            'Ottawa - Gatineau Population Center, QC-ON, Canada',
            'Ottawa CDR, Ontario, Canada',
            'Ottawa City, Ottawa CDR, Ontario, Canada',
            'Ottawa County, Michigan, USA',
            'Ottawa Urban Center, Canada',
            'Ouest, Haiti',
            'Ouest, Rwanda',
            'Oxnard Urban Center, USA',
            'Oxnard-San Buenaventura (Ventura) [Urban Area], CA, USA',
            'Oxnard-Thousand Oaks-Ventura MSA, CA, USA',
            'Oyo, Nigeria',
            'Ozama, Dominican Republic',
            'PA-01, USA',
            'PA-02, USA',
            'PA-03, USA',
            'PA-04, USA',
            'PA-05, USA',
            'PA-06, USA',
            'PA-07, USA',
            'PA-08, USA',
            'PA-09, USA',
            'PA-10, USA',
            'PA-11, USA',
            'PA-12, USA',
            'PA-13, USA',
            'PA-14, USA',
            'PA-15, USA',
            'PA-16, USA',
            'PA-17, USA',
            'Paducah KY-Cape Girardeau MO-Harrisburg IL Media Market, USA',
            'Pakistan',
            'Palm Bay Urban Center, USA',
            'Palm Bay-Melbourne [Urban Area], FL, USA',
            'Palm Bay-Melbourne-Titusville MSA, FL, USA',
            'Palm Beach County, Florida, USA',
            'Palm Springs CA Media Market, USA',
            'Palmdale Urban Center, USA',
            'Palmdale-Lancaster [Urban Area], CA, USA',
            'Pampanga, Philippines',
            'Panama',
            'Panama City FL Media Market, USA',
            'Pangasinan, Philippines',
            'Papua New Guinea',
            'Papua, Indonesia',
            'Paraguay',
            'Paran\u00e1, Brazil',
            'Para\u00edba, Brazil',
            'Paris Urban Center, France',
            'Par\u00e1, Brazil',
            'Pasco County, Florida, USA',
            'Passaic County, New Jersey, USA',
            'Patna Urban Center, India',
            'Pays de la Loire, France',
            'Peel Regional municipality, Ontario, Canada',
            'Pennsylvania, USA',
            'Pensacola [Urban Area], FL-AL, USA',
            'Pensacola-Ferry Pass-Brent MSA, FL, USA',
            'Peoria MSA, IL, USA',
            'Peoria [Urban Area], IL, USA',
            'Peoria-Bloomington IL Media Market, USA',
            'Perak, Malaysia',
            'Permskiy kray, Russia',
            'Pernambuco, Brazil',
            'Peru',
            'Peshawar Urban Center, Pakistan',
            'Philadelphia County, Pennsylvania, USA',
            'Philadelphia PA Media Market, USA',
            'Philadelphia Urban Center, USA',
            'Philadelphia [Urban Area], PA-NJ-DE-MD, USA',
            'Philadelphia city, Pennsylvania, USA',
            'Philadelphia-Camden-Wilmington MSA, PA-NJ-DE-MD, USA',
            'Philippines',
            'Phoenix (Prescott) AZ Media Market, USA',
            'Phoenix Urban Center, USA',
            'Phoenix West-Goodyear-Avondale [Urban Area], AZ, USA',
            'Phoenix city, Arizona, USA',
            'Phoenix-Mesa-Scottsdale MSA, AZ, USA',
            'Phoenix-Mesa-Scottsdale [Urban Area], AZ, USA',
            'Piau\u00ed, Brazil',
            'Pichincha, Ecuador',
            'Piemonte, Italy',
            'Pierce County, Washington, USA',
            'Pima County, Arizona, USA',
            'Pinal County, Arizona, USA',
            'Pinellas County, Florida, USA',
            'Pittsburgh MSA, PA, USA',
            'Pittsburgh PA Media Market, USA',
            'Pittsburgh Urban Center, USA',
            'Pittsburgh [Urban Area], PA, USA',
            'Pittsburgh city, Pennsylvania, USA',
            'Placer County, California, USA',
            'Plano city, Texas, USA',
            'Plateau, Nigeria',
            'Plymouth County, Massachusetts, USA',
            'Poland',
            'Polk County, Florida, USA',
            'Polk County, Iowa, USA',
            'Ponce MSA, PR, USA',
            'Port St. Lucie MSA, FL, USA',
            'Port St. Lucie [Urban Area], FL, USA',
            'Portland (Oregon-Washington) Urban Center, USA',
            'Portland OR Media Market, USA',
            'Portland [Urban Area], OR-WA, USA',
            'Portland city, Oregon, USA',
            'Portland-Auburn ME Media Market, USA',
            'Portland-South Portland MSA, ME, USA',
            'Portland-Vancouver-Hillsboro MSA, OR-WA, USA',
            'Portugal',
            'Poughkeepsie-Newburgh [Urban Area], NY, USA',
            'Prince George\'s County, Maryland, USA',
            'Prince William County, Virginia, USA',
            'Provence-Alpes-C\u00f4te d\'Azur, France',
            'Providence County, Rhode Island, USA',
            'Providence Urban Center, USA',
            'Providence [Urban Area], RI-MA, USA',
            'Providence-Warwick MSA, RI-MA, USA',
            'ProvidenceRi-New Bedford MA Media Market, USA',
            'Provo Urban Center, USA',
            'Provo-Orem MSA, UT, USA',
            'Provo-Orem [Urban Area], UT, USA',
            'Puebla, Mexico',
            'Puerto Rico, USA',
            'Puglia, Italy',
            'Pulaski County, Arkansas, USA',
            'Pune Urban Center, India',
            'Punjab, India',
            'Punjab, Pakistan',
            'Pyongyang Urban Center, North Korea',
            'P\u2019y\u014fngan-bukto [Phyeonganpukto], North Korea',
            'P\u2019y\u014fngan-namdo [Phyeongannamto], North Korea',
            'P\u2019y\u014fngyang [Phyeongyang], North Korea',
            'Qashqadaryo, Uzbekistan',
            'Qatar',
            'Qingdao Urban Center, China',
            'Qinghai Sheng, China',
            'Qin\u0101, Egypt',
            'Quanzhou (Fujian Sheng) Urban Center, China',
            'Quebec Urban Center, Canada',
            'Quebec, Canada',
            'Queens County, New York, USA',
            'Queensland, Australia',
            'Quezon City [Manila] Urban Center, Philippines',
            'QuincyIl-Hannibal MO-Keokuk IA Media Market, USA',
            'Qu\u00e9bec CMA, QC, Canada',
            'Qu\u00e9bec Population Center, QC, Canada',
            'Qu\u00e9bec Territory, Quebec, Canada',
            'Qu\u00e9bec Ville, Qu\u00e9bec Territory, Quebec, Canada',
            'RI-01, USA',
            'RI-02, USA',
            'Rabat-Sal\u00e9-K\u00e9nitra, Morocco',
            'Rajshahi, Bangladesh',
            'Raleigh MSA, NC, USA',
            'Raleigh Urban Center, USA',
            'Raleigh [Urban Area], NC, USA',
            'Raleigh city, North Carolina, USA',
            'Raleigh-Durham (Fayetteville) NC Media Market, USA',
            'Ramsey County, Minnesota, USA',
            'Rangpur, Bangladesh',
            'Rapid City SD Media Market, USA',
            'Rawalpindi [Islamabad] Urban Center, Pakistan',
            'Reading MSA, PA, USA',
            'Reading [Urban Area], PA, USA',
            'Recife Urban Center, Brazil',
            'Regi\u00f3n Metropolitana de Santiago, Chile',
            'Reno MSA, NV, USA',
            'Reno NV Media Market, USA',
            'Reno [Urban Area], NV-CA, USA',
            'Reno city, Nevada, USA',
            'Rheinland-Pfalz, Germany',
            'Rhode Island, USA',
            'Riau, Indonesia',
            'Richland County, South Carolina, USA',
            'Richmond County, New York, USA',
            'Richmond MSA, VA, USA',
            'Richmond Urban Center, USA',
            'Richmond [Urban Area], VA, USA',
            'Richmond-Petersburg VA Media Market, USA',
            'Rio Grande do Norte, Brazil',
            'Rio Grande do Sul, Brazil',
            'Rio de Janeiro Urban Center, Brazil',
            'Rio de Janeiro, Brazil',
            'Rivers, Nigeria',
            'Riverside County, California, USA',
            'Riverside city, California, USA',
            'Riverside-San Bernardino [Urban Area], CA, USA',
            'Riverside-San Bernardino-Ontario MSA, CA, USA',
            'Riyadh Urban Center, Saudi Arabia',
            'Rizal, Philippines',
            'Roanoke MSA, VA, USA',
            'Roanoke-Lynchburg VA Media Market, USA',
            'Rochester (New York) Urban Center, USA',
            'Rochester MN-Mason CityIa-Austin MN Media Market, USA',
            'Rochester MSA, NY, USA',
            'Rochester NY Media Market, USA',
            'Rochester [Urban Area], NY, USA',
            'Rockford IL Media Market, USA',
            'Rockford MSA, IL, USA',
            'Rockford [Urban Area], IL, USA',
            'Rockingham County, New Hampshire, USA',
            'Rockland County, New York, USA',
            'Romania',
            'Rostovskaya oblast\', Russia',
            'Round Lake Beach-McHenry-Grayslake [Urban Area], IL-WI, USA',
            'Russia',
            'Rutherford County, Tennessee, USA',
            'Rwanda',
            'R\u00edo Piedras [San Juan] Urban Center, USA',
            'R\u0101jasth\u0101n, India',
            'R\u012bf Dimashq, Syria',
            'SC-01, USA',
            'SC-02, USA',
            'SC-03, USA',
            'SC-04, USA',
            'SC-05, USA',
            'SC-06, USA',
            'SC-07, USA',
            'SD-00, USA',
            'Sabah, Malaysia',
            'Sachsen, Germany',
            'Sacramento County, California, USA',
            'Sacramento Urban Center, USA',
            'Sacramento [Urban Area], CA, USA',
            'Sacramento city, California, USA',
            'Sacramento--Roseville--Arden-Arcade MSA, CA, USA',
            'Sacramento-Stockton-Modesto CA Media Market, USA',
            'Sagaing, Myanmar',
            'Saint Petersburg Urban Center, Russia',
            'Saitama, Japan',
            'Salem MSA, OR, USA',
            'Salem [Urban Area], OR, USA',
            'Salinas MSA, CA, USA',
            'Salisbury MD Media Market, USA',
            'Salisbury MSA, MD-DE, USA',
            'Salt Lake City MSA, UT, USA',
            'Salt Lake City UT Media Market, USA',
            'Salt Lake City Urban Center, USA',
            'Salt Lake City [Urban Area], UT, USA',
            'Salt Lake County, Utah, USA',
            'Salvador Urban Center, Brazil',
            'Samarqand, Uzbekistan',
            'Samarskaya oblast\', Russia',
            'Samut Prakan, Thailand',
            'San Antonio TX Media Market, USA',
            'San Antonio Urban Center, USA',
            'San Antonio [Urban Area], TX, USA',
            'San Antonio city, Texas, USA',
            'San Antonio-New Braunfels MSA, TX, USA',
            'San Bernardino County, California, USA',
            'San Diego CA Media Market, USA',
            'San Diego County, California, USA',
            'San Diego [Urban Area], CA, USA',
            'San Diego city, California, USA',
            'San Diego-Carlsbad MSA, CA, USA',
            'San Francisco County, California, USA',
            'San Francisco city, California, USA',
            'San Francisco-Oakland [Urban Area], CA, USA',
            'San Francisco-Oakland-Hayward MSA, CA, USA',
            'San Francisco-Oakland-San Jose CA Media Market, USA',
            'San Joaquin County, California, USA',
            'San Jose Urban Center, USA',
            'San Jose [Urban Area], CA, USA',
            'San Jose city, California, USA',
            'San Jose-Sunnyvale-Santa Clara MSA, CA, USA',
            'San Juan Municipio, Puerto Rico, USA',
            'San Juan [Urban Area], PR, USA',
            'San Juan zona urbana, Puerto Rico, USA',
            'San Juan-Carolina-Caguas MSA, PR, USA',
            'San Luis Obispo County, California, USA',
            'San Luis Obispo-Paso Robles-Arroyo Grande MSA, CA, USA',
            'San Luis Potos\u00ed, Mexico',
            'San Mateo County, California, USA',
            'Sana\'a Urban Center, Yemen',
            'Sankt-Peterburg, Russia',
            'Santa Ana city, California, USA',
            'Santa Barbara County, California, USA',
            'Santa Barbara-Santa Maria-San Luis Obispo CA Media Market, USA',
            'Santa Catarina, Brazil',
            'Santa Clara County, California, USA',
            'Santa Clarita [Urban Area], CA, USA',
            'Santa Cruz County, California, USA',
            'Santa Cruz, Bolivia',
            'Santa Cruz-Watsonville MSA, CA, USA',
            'Santa Fe, Argentina',
            'Santa Maria-Santa Barbara MSA, CA, USA',
            'Santa Rosa MSA, CA, USA',
            'Santa Rosa [Urban Area], CA, USA',
            'Santiago Urban Center, Chile',
            'Santo Domingo Urban Center, Dominican Republic',
            'Sarasota County, Florida, USA',
            'Sarawak, Malaysia',
            'Saskatchewan, Canada',
            'Saskatoon CMA, SK, Canada',
            'Saskatoon City, Division No. 11, Saskatchewan, Canada',
            'Saskatoon Population Center, SK, Canada',
            'Sassandra-Marahou\u00e9, Ivory Coast',
            'Saudi Arabia',
            'Savannah GA Media Market, USA',
            'Savannah MSA, GA, USA',
            'Savannah [Urban Area], GA, USA',
            'Schleswig-Holstein, Germany',
            'Scotland, United Kingdom',
            'Scranton [Urban Area], PA, USA',
            'Scranton--Wilkes-Barre--Hazleton MSA, PA, USA',
            'Seattle Urban Center, USA',
            'Seattle city, Washington, USA',
            'Seattle-Tacoma WA Media Market, USA',
            'Seattle-Tacoma [Urban Area], WA, USA',
            'Seattle-Tacoma-Bellevue MSA, WA, USA',
            'Sedgwick County, Kansas, USA',
            'Selangor, Malaysia',
            'Semarang Urban Center, Indonesia',
            'Seminole County, Florida, USA',
            'Senegal',
            'Seoul Urban Center, South Korea',
            'Seoul-teukbyeolsi, South Korea',
            'Serbia',
            'Shaanxi Sheng, China',
            'Sham\u0101l D\u0101rf\u016br, Sudan',
            'Sham\u0101l Kurduf\u0101n, Sudan',
            'Shan, Myanmar',
            'Shandong Sheng, China',
            'Shanghai Shi, China',
            'Shanghai Urban Center, China',
            'Shanxi Sheng, China',
            'Shelby County, Tennessee, USA',
            'Shenyang Urban Center, China',
            'Sherman TX-Ada OK Media Market, USA',
            'Shijiazhuang Urban Center, China',
            'Shreveport LA Media Market, USA',
            'Shreveport [Urban Area], LA, USA',
            'Shreveport-Bossier City MSA, LA, USA',
            'Sichuan Sheng, China',
            'Sicilia, Italy',
            'Sierra Leone',
            'Sikasso, Mali',
            'Simcoe County, Ontario, Canada',
            'Sinaloa, Mexico',
            'Sindh, Pakistan',
            'Singapore',
            'Singapore Urban Center, Malaysia-Singapore',
            'Sioux City IA Media Market, USA',
            'Sioux Falls (Mitchell) SD Media Market, USA',
            'Sioux Falls MSA, SD, USA',
            'Sizuoka, Japan',
            'Slovakia',
            'Snohomish County, Washington, USA',
            'Sokoto, Nigeria',
            'Solano County, California, USA',
            'Somalia',
            'Somerset County, New Jersey, USA',
            'Sonoma County, California, USA',
            'Sonora, Mexico',
            'Souss-Massa, Morocco',
            'South Africa',
            'South Bend [Urban Area], IN-MI, USA',
            'South Bend-Elkhart IN Media Market, USA',
            'South Bend-Mishawaka MSA, IN-MI, USA',
            'South Carolina, USA',
            'South Central Connecticut Planning Region, Connecticut, USA',
            'South Dakota, USA',
            'South Korea',
            'South Sudan',
            'Southeastern Connecticut Planning Region, Connecticut, USA',
            'Spain',
            'Spartanburg County, South Carolina, USA',
            'Spartanburg MSA, SC, USA',
            'Spokane County, Washington, USA',
            'Spokane Urban Center, USA',
            'Spokane WA Media Market, USA',
            'Spokane [Urban Area], WA, USA',
            'Spokane-Spokane Valley MSA, WA, USA',
            'Springfield (Massachusetts) Urban Center, USA',
            'Springfield MO Media Market, USA',
            'Springfield MSA, MA, USA',
            'Springfield MSA, MO, USA',
            'Springfield [Urban Area], MA-CT, USA',
            'Springfield [Urban Area], MO, USA',
            'Springfield-Holyoke MA Media Market, USA',
            'Sri Lanka',
            'St. Catharines - Niagara CMA, ON, Canada',
            'St. Charles County, Missouri, USA',
            'St. Clair County, Illinois, USA',
            'St. Johns County, Florida, USA',
            'St. Joseph County, Indiana, USA',
            'St. Louis County, Missouri, USA',
            'St. Louis MO Media Market, USA',
            'St. Louis MSA, MO-IL, USA',
            'St. Louis Urban Center, USA',
            'St. Louis [Urban Area], MO-IL, USA',
            'St. Louis city, Missouri, USA',
            'St. Louis city-County, Missouri, USA',
            'St. Lucie County, Florida, USA',
            'St. Paul city, Minnesota, USA',
            'St. Petersburg city, Florida, USA',
            'St. Tammany Parish, Louisiana, USA',
            'Stanislaus County, California, USA',
            'Stark County, Ohio, USA',
            'State of Palestine',
            'Stavropol\'skiy kray, Russia',
            'Stockton Urban Center, USA',
            'Stockton [Urban Area], CA, USA',
            'Stockton city, California, USA',
            'Stockton-Lodi MSA, CA, USA',
            'Sud, Rwanda',
            'Sud-Kivu, Democratic Republic of the Congo',
            'Sudan',
            'Sud\u016br Pashchim, Nepal',
            'Suffolk County, Massachusetts, USA',
            'Suffolk County, New York, USA',
            'Sughd, Tajikistan',
            'Sulawesi Selatan, Indonesia',
            'Sulawesi Tengah, Indonesia',
            'Sulawesi Tenggara, Indonesia',
            'Sulawesi Utara, Indonesia',
            'Sumal\u0113, Ethiopia',
            'Sumatera Barat, Indonesia',
            'Sumatera Selatan, Indonesia',
            'Sumatera Utara, Indonesia',
            'Summit County, Ohio, USA',
            'Surabaya Urban Center, Indonesia',
            'Surat Urban Center, India',
            'Surrey City, Greater Vancouver Regional district, British Columbia, Canada',
            'Surxondaryo, Uzbekistan',
            'Suzhou (Jiangsu Sheng) Urban Center, China',
            'Sverdlovskaya oblast\', Russia',
            'Sweden',
            'Switzerland',
            'Sydney Urban Center, Australia',
            'Sylhet, Bangladesh',
            'Syracuse MSA, NY, USA',
            'Syracuse NY Media Market, USA',
            'Syracuse Urban Center, USA',
            'Syracuse [Urban Area], NY, USA',
            'Syria',
            'S\u00e3o Paulo Urban Center, Brazil',
            'S\u00e3o Paulo, Brazil',
            'S\u00e9gou, Mali',
            'S\u012bdama, Ethiopia',
            'S\u012bst\u0101n va Bal\u016bchest\u0101n, Iran',
            'S\u016bh\u0101j, Egypt',
            'TN-01, USA',
            'TN-02, USA',
            'TN-03, USA',
            'TN-04, USA',
            'TN-05, USA',
            'TN-06, USA',
            'TN-07, USA',
            'TN-08, USA',
            'TN-09, USA',
            'TX-01, USA',
            'TX-02, USA',
            'TX-03, USA',
            'TX-04, USA',
            'TX-05, USA',
            'TX-06, USA',
            'TX-07, USA',
            'TX-08, USA',
            'TX-09, USA',
            'TX-10, USA',
            'TX-11, USA',
            'TX-12, USA',
            'TX-13, USA',
            'TX-14, USA',
            'TX-15, USA',
            'TX-16, USA',
            'TX-17, USA',
            'TX-18, USA',
            'TX-19, USA',
            'TX-20, USA',
            'TX-21, USA',
            'TX-22, USA',
            'TX-23, USA',
            'TX-24, USA',
            'TX-25, USA',
            'TX-26, USA',
            'TX-27, USA',
            'TX-28, USA',
            'TX-29, USA',
            'TX-30, USA',
            'TX-31, USA',
            'TX-32, USA',
            'TX-33, USA',
            'TX-34, USA',
            'TX-35, USA',
            'TX-36, USA',
            'TX-37, USA',
            'TX-38, USA',
            'Tabasco, Mexico',
            'Tabora, Tanzania',
            'Tahoua, Niger',
            'Taiwan, China',
            'Taiyuan Urban Center, China',
            'Tajikistan',
            'Tallahassee FL-Thomasville GA Media Market, USA',
            'Tallahassee MSA, FL, USA',
            'Tallahassee [Urban Area], FL, USA',
            'Tamaulipas, Mexico',
            'Tamil N\u0101du, India',
            'Tampa Urban Center, USA',
            'Tampa city, Florida, USA',
            'Tampa-St. Petersburg [Urban Area], FL, USA',
            'Tampa-St. Petersburg-Clearwater MSA, FL, USA',
            'Tampa-St.Petersburg (Sarasota) FL Media Market, USA',
            'Tanga, Tanzania',
            'Tanganyika, Democratic Republic of the Congo',
            'Tanger-T\u00e9touan-Al Hoce\u00efma, Morocco',
            'Tanzania',
            'Taraba, Nigeria',
            'Tarrant County, Texas, USA',
            'Tashkent Urban Center, Uzbekistan',
            'Tasikmalaya Urban Center, Indonesia',
            'Tatarstan, Respublika, Russia',
            'Tehran Urban Center, Iran',
            'Tehr\u0101n, Iran',
            'Tel Aviv Urban Center, Israel',
            'Telang\u0101na, India',
            'Temecula-Murrieta-Menifee [Urban Area], CA, USA',
            'Tennessee, USA',
            'Terre Haute IN Media Market, USA',
            'Tete, Mozambique',
            'Texas, USA',
            'Thailand',
            'Thanh H\u00f3a, Vietnam',
            'The Gambia',
            'The Woodlands-Conroe [Urban Area], TX, USA',
            'Thiruvananthapuram Urban Center, India',
            'Thurston County, Washington, USA',
            'Tianjin Shi, China',
            'Tianjin Urban Center, China',
            'Tiba, Japan',
            'Tigray, Ethiopia',
            'Tijuana Urban Center, Mexico-USA',
            'Tillab\u00e9ri, Niger',
            'Togo',
            'Tokyo Urban Center, Japan',
            'Toledo MSA, OH, USA',
            'Toledo OH Media Market, USA',
            'Toledo [Urban Area], OH-MI, USA',
            'Toledo city, Ohio, USA',
            'Topeka KS Media Market, USA',
            'Toronto CDR, Ontario, Canada',
            'Toronto CMA, ON, Canada',
            'Toronto City, Toronto CDR, Ontario, Canada',
            'Toronto Population Center, ON, Canada',
            'Toronto Urban Center, Canada',
            'Toscana, Italy',
            'Toshkent (Province), Uzbekistan',
            'Traverse City-Cadillac MI Media Market, USA',
            'Travis County, Texas, USA',
            'Trenton MSA, NJ, USA',
            'Trenton [Urban Area], NJ, USA',
            'Tri-Cities TN-VA Media Market, USA',
            'Tripura, India',
            'Tshopo, Democratic Republic of the Congo',
            'Tucson (Sierra Vista) AZ Media Market, USA',
            'Tucson MSA, AZ, USA',
            'Tucson Urban Center, USA',
            'Tucson [Urban Area], AZ, USA',
            'Tucson city, Arizona, USA',
            'Tulare County, California, USA',
            'Tulsa County, Oklahoma, USA',
            'Tulsa MSA, OK, USA',
            'Tulsa OK Media Market, USA',
            'Tulsa Urban Center, USA',
            'Tulsa [Urban Area], OK, USA',
            'Tulsa city, Oklahoma, USA',
            'Tunisia',
            'Turkey',
            'Turkmenistan',
            'Tuscaloosa MSA, AL, USA',
            'Tyler-Longview (Lufkin & Nacogdoches) TX Media Market, USA',
            'T\u00f4ky\u00f4, Japan',
            'T\u0101\u2018izz, Yemen',
            'USA',
            'UT-01, USA',
            'UT-02, USA',
            'UT-03, USA',
            'UT-04, USA',
            'Uganda',
            'Ukraine',
            'Union County, New Jersey, USA',
            'United Arab Emirates',
            'United Kingdom',
            'Urban Honolulu CDP, Hawaii, USA',
            'Urban Honolulu MSA, HI, USA',
            'Uruguay',
            'Utah County, Utah, USA',
            'Utah, USA',
            'Utica NY Media Market, USA',
            'Utica-Rome MSA, NY, USA',
            'Uttar Pradesh, India',
            'Uttar\u0101khand, India',
            'Uzbekistan',
            'VA-01, USA',
            'VA-02, USA',
            'VA-03, USA',
            'VA-04, USA',
            'VA-05, USA',
            'VA-06, USA',
            'VA-07, USA',
            'VA-08, USA',
            'VA-09, USA',
            'VA-10, USA',
            'VA-11, USA',
            'VT-00, USA',
            'Valenciana, Comunidad, Spain',
            'Valle del Cauca, Colombia',
            'Vallejo-Fairfield MSA, CA, USA',
            'Vancouver CMA, BC, Canada',
            'Vancouver City, Greater Vancouver Regional district, British Columbia, Canada',
            'Vancouver Population Center, BC, Canada',
            'Vancouver Urban Center, Canada',
            'Varanasi Urban Center, India',
            'Vaughan City, York Regional municipality, Ontario, Canada',
            'Vayamba pa\u1e37\u0101ta, Sri Lanka',
            'Veneto, Italy',
            'Venezuela',
            'Ventura County, California, USA',
            'Veracruz de Ignacio de la Llave, Mexico',
            'Vermont, USA',
            'Victoria CMA, BC, Canada',
            'Victoria Population Center, BC, Canada',
            'Victoria, Australia',
            'Victorville-Hesperia-Apple Valley [Urban Area], CA, USA',
            'Vietnam',
            'Virginia Beach Urban Center, USA',
            'Virginia Beach city, Virginia, USA',
            'Virginia Beach city-County, Virginia, USA',
            'Virginia Beach-Norfolk [Urban Area], VA, USA',
            'Virginia Beach-Norfolk-Newport News MSA, VA-NC, USA',
            'Virginia, USA',
            'Visalia-Porterville MSA, CA, USA',
            'Vlaamse Gewest, Belgium',
            'Volgogradskaya oblast\', Russia',
            'Volusia County, Florida, USA',
            'WA-01, USA',
            'WA-02, USA',
            'WA-03, USA',
            'WA-04, USA',
            'WA-05, USA',
            'WA-06, USA',
            'WA-07, USA',
            'WA-08, USA',
            'WA-09, USA',
            'WA-10, USA',
            'WI-01, USA',
            'WI-02, USA',
            'WI-03, USA',
            'WI-04, USA',
            'WI-05, USA',
            'WI-06, USA',
            'WI-07, USA',
            'WI-08, USA',
            'WV-01, USA',
            'WV-02, USA',
            'WY-00, USA',
            'Waco MSA, TX, USA',
            'Waco-Temple-Bryan TX Media Market, USA',
            'Wake County, North Carolina, USA',
            'Wakiso, Uganda',
            'Wales, United Kingdom',
            'Washington County, Minnesota, USA',
            'Washington County, Oregon, USA',
            'Washington D.C. Urban Center, USA',
            'Washington DC (Hagerstown MD) Media Market, USA',
            'Washington city, District of Columbia, USA',
            'Washington, USA',
            'Washington-Arlington [Urban Area], DC-VA-MD, USA',
            'Washington-Arlington-Alexandria MSA, DC-VA-MD-WV, USA',
            'Washoe County, Nevada, USA',
            'Washtenaw County, Michigan, USA',
            'Waterloo Regional municipality, Ontario, Canada',
            'Watertown NY Media Market, USA',
            'Waukegan Urban Center, USA',
            'Waukesha County, Wisconsin, USA',
            'Wausau-Rhinelander WI Media Market, USA',
            'Wayne County, Michigan, USA',
            'Webb County, Texas, USA',
            'Weber County, Utah, USA',
            'Weld County, Colorado, USA',
            'Wenzhou Urban Center, China',
            'West Bengal, India',
            'West Palm Beach-Fort Pierce FL Media Market, USA',
            'West Virginia, USA',
            'Westchester County, New York, USA',
            'Western Australia, Australia',
            'Western Cape, South Africa',
            'Western Connecticut Planning Region, Connecticut, USA',
            'Westmoreland County, Pennsylvania, USA',
            'Wheeling WV-Steubenville OH Media Market, USA',
            'Wichita Falls TX & Lawton OK Media Market, USA',
            'Wichita MSA, KS, USA',
            'Wichita Urban Center, USA',
            'Wichita [Urban Area], KS, USA',
            'Wichita city, Kansas, USA',
            'Wichita-Hutchinson KS Media Market, USA',
            'Wielkopolskie, Poland',
            'Wilkes Barre-Scranton PA Media Market, USA',
            'Will County, Illinois, USA',
            'Williamson County, Texas, USA',
            'Wilmington MSA, NC, USA',
            'Wilmington NC Media Market, USA',
            'Wilmington [Urban Area], NC, USA',
            'Windsor CMA, ON, Canada',
            'Windsor Population Center, ON, Canada',
            'Winnebago County, Illinois, USA',
            'Winnipeg CMA, MB, Canada',
            'Winnipeg City, Division No. 11, Manitoba, Canada',
            'Winnipeg Population Center, MB, Canada',
            'Winnipeg Urban Center, Canada',
            'Winston-Salem MSA, NC, USA',
            'Winston-Salem [Urban Area], NC, USA',
            'Winter Haven [Urban Area], FL, USA',
            'Wisconsin, USA',
            'Worcester County, Massachusetts, USA',
            'Worcester MSA, MA-CT, USA',
            'Worcester [Urban Area], MA-CT, USA',
            'Wuhan Urban Center, China',
            'Wyoming, USA',
            'Xi\'an Urban Center, China',
            'Xiamen Urban Center, China',
            'Xinjiang Uygur Zizhiqu, China',
            'Xizang Zizhiqu, China',
            'Yakima County, Washington, USA',
            'Yakima MSA, WA, USA',
            'Yakima-Pasco-Richland-Kennewick WA Media Market, USA',
            'Yangon Urban Center, Myanmar',
            'Yangon, Myanmar',
            'Yaounde Urban Center, Cameroon',
            'YeDebub Bih\u0113roch Bih\u0113reseboch na Hizboch, Ethiopia',
            'Yemen',
            'Yobe, Nigeria',
            'Yogyakarta Urban Center, Indonesia',
            'Yogyakarta, Indonesia',
            'York County, Pennsylvania, USA',
            'York County, South Carolina, USA',
            'York Regional municipality, Ontario, Canada',
            'York-Hanover MSA, PA, USA',
            'Youngstown OH Media Market, USA',
            'Youngstown [Urban Area], OH, USA',
            'Youngstown-Warren-Boardman MSA, OH-PA, USA',
            'Yuma AZ-El Centro CA Media Market, USA',
            'Yunnan Sheng, China',
            'Zambia',
            'Zamb\u00e9zia, Mozambique',
            'Zamfara, Nigeria',
            'Zhejiang Sheng, China',
            'Zhengzhou Urban Center, China',
            'Zimbabwe',
            'Zinder, Niger',
            'Zuid-Holland, Netherlands',
            'Zulia, Venezuela',
            'nohiyahoi tobei jumhur\u00ed, Tajikistan',
            'wallonne, R\u00e9gion, Belgium',
            '\u00cele-de-France, France',
            '\u00d0\u1ed3ng Nai, Vietnam',
            '\u00d4saka, Japan',
            '\u00dcr\u00fcmqi Urban Center, China',
            '\u0100d\u012bs \u0100beba, Ethiopia',
            '\u0100mara, Ethiopia',
            '\u0100z\u0304arb\u0101yj\u0101n-e Gharb\u012b, Iran',
            '\u0100z\u0304arb\u0101yj\u0101n-e Sharq\u012b, Iran',
            '\u0130stanbul, Turkey',
            '\u0130zmir, Turkey',
            '\u015al\u0105skie, Poland',
            '\u015eal\u0101\u1e29 ad D\u012bn, Iraq',
            '\u1e28alab, Syria',
        ],
        allStatNames: [
            '2016 Presidential Election',
            '2016-2020 Swing',
            '2020 Presidential Election',
            'Arthritis %',
            'Binge drinking among adults %',
            'High blood pressure %',
            'Cancer (excluding skin cancer) %',
            'Current asthma %',
            'Coronary heart disease %',
            'Cognitive disability %',
            'COPD %',
            'Smoking %',
            'Diagnosed diabetes %',
            'Disability %',
            'Fair or poor self-rated health status %',
            'Hearing disability %',
            'Independent living disability %',
            'Chronic kidney disease %',
            'No leisure-time physical activity %',
            'Mobility disability %',
            'Obesity %',
            'Self-care disability %',
            'Sleeping less than 7 hours %',
            'Stroke %',
            'Vision disability %',
            'PW Density (r=1km)',
            'PW Density (r=1km) Change (2000-2020)',
            'PW Density (r=1km) Change (2010-2020)',
            'PW Density (r=4km)',
            'Asian %',
            'Born outside US %',
            'Born in us outside state %',
            'Born in state of residence %',
            'Black %',
            'Citizen by Birth %',
            'Citizen by Naturalization %',
            'Non-citizen %',
            'High temperature Above 90\u00b0F %',
            'High temperature Below 40\u00b0F %',
            'Undergrad Business %',
            'Undergrad Humanities %',
            'Undergrad STEM %',
            'Grad %',
            'Graduate degree [25-64] %',
            'High School %',
            'High school diploma [25-64] %',
            'Undergrad %',
            'Bachelor\'s degree [25-64] %',
            'Boomer %',
            'Gen Alpha %',
            'Gen X %',
            'Gen Z %',
            'Millennial %',
            'Silent %',
            'PW Mean Elevation',
            'Electricity heating %',
            'Utility gas heating %',
            'Hispanic %',
            'Racial Homogeneity %',
            'Racial Homogeneity Change (2000-2020) %',
            'Racial Homogeneity Change (2010-2020) %',
            'Mean sunny hours',
            'Household income > C$100k %',
            'Household Income > $100k %',
            'Household income < C$50k %',
            'Household Income < $50k %',
            'Housing Units per Adult',
            'Individual income > C$100k %',
            'Individual Income > $100k %',
            'Individual income < C$50k %',
            'Individual Income < $50k %',
            'Accommodation and food services %',
            'Administrative and support and waste management services %',
            'Agriculture, forestry, fishing and hunting %',
            'Arts, entertainment, and recreation %',
            'Construction %',
            'Educational services %',
            'Finance and insurance %',
            'Health care and social assistance %',
            'Information %',
            'Management of companies and enterprises %',
            'Manufacturing %',
            'Mining, quarrying, and oil and gas extraction %',
            'Other services, except public administration %',
            'Professional, scientific, and technical services %',
            'Public administration %',
            'Real estate and rental and leasing %',
            'Retail trade %',
            'Transportation and warehousing %',
            'Utilities %',
            'Wholesale trade %',
            'Public Insurance %',
            'Uninsured %',
            'Private Insurance %',
            'No internet access %',
            'Only English at Home %',
            'Spanish at Home %',
            'Within 1mi of a grocery store %',
            'LICO-AT %',
            'Divorced %',
            'Mean distance to nearest Active Superfund Site',
            'Mean distance to nearest Airport',
            'Mean distance to nearest Hospital',
            'Mean distance to nearest Public School',
            'Mean high heat index',
            'Mean high temp',
            'Mean high temperature in fall',
            'Mean high temperature in spring',
            'Mean high temperature in summer',
            'Mean high temperature in winter',
            'Architecture and engineering occupations %',
            'Arts, design, entertainment, sports, and media occupations %',
            'Building and grounds cleaning and maintenance occupations %',
            'Business and financial operations occupations %',
            'Community and social service occupations %',
            'Computer and mathematical occupations %',
            'Construction and extraction occupations %',
            'Educational instruction, and library occupations %',
            'Farming, fishing, and forestry occupations %',
            'Firefighting and prevention, and other protective service workers including supervisors %',
            'Food preparation and serving related occupations %',
            'Health diagnosing and treating practitioners and other technical occupations %',
            'Health technologists and technicians %',
            'Healthcare support occupations %',
            'Installation, maintenance, and repair occupations %',
            'Law enforcement workers including supervisors %',
            'Legal occupations %',
            'Life, physical, and social science occupations %',
            'Management occupations %',
            'Material moving occupations %',
            'Office and administrative support occupations %',
            'Personal care and service occupations %',
            'Sales and related occupations %',
            'Transportation occupations %',
            'PW Mean % of parkland within 1km',
            'PW Mean PM2.5 Pollution',
            'Population',
            'Population Change (2000-2020)',
            'Population Change (2010-2020)',
            'Poverty %',
            'Rainfall',
            '1BR Rent > $1500 %',
            '1BR Rent < $750 %',
            '2BR Rent > $1500 %',
            '2BR Rent < $750 %',
            'Rent/Income > 40%',
            'Rent/Income < 20%',
            'Renter %',
            'Snowfall [rain-equivalent]',
            'Cohabiting With Partner (Gay) %',
            'Traffic Fatalities Per Capita Per Year',
            'Pedestrian/Cyclist Fatalities Per Capita Per Year',
            'Commute Time > 60 min %',
            'Commute Time < 15 min %',
            'Commute Bike %',
            'Commute Car %',
            'Commute Transit %',
            'Commute Walk %',
            'Commute Work From Home %',
            'Vacancy %',
            'Households With 1+ Vehicles %',
            'White %',
            'High windspeed (>10mph) days %',
            '% units built pre-1970',
            '% units built in 2010s+',
        ],
        statPaths: [
            '2016 Presidential Election-margin',
            '2016-2020 Swing-margin',
            '2020 Presidential Election-margin',
            'ARTHRITIS_cdc_2',
            'BINGE_cdc_2',
            'BPHIGH_cdc_2',
            'CANCER_cdc_2',
            'CASTHMA_cdc_2',
            'CHD_cdc_2',
            'COGNITION_cdc_2',
            'COPD_cdc_2',
            'CSMOKING_cdc_2',
            'DIABETES_cdc_2',
            'DISABILITY_cdc_2',
            'GHLTH_cdc_2',
            'HEARING_cdc_2',
            'INDEPLIVE_cdc_2',
            'KIDNEY_cdc_2',
            'LPA_cdc_2',
            'MOBILITY_cdc_2',
            'OBESITY_cdc_2',
            'SELFCARE_cdc_2',
            'SLEEP_cdc_2',
            'STROKE_cdc_2',
            'VISION_cdc_2',
            'ad_1',
            'ad_1_change_2000',
            'ad_1_change_2010',
            'ad_4',
            'asian',
            'birthplace_non_us',
            'birthplace_us_not_state',
            'birthplace_us_state',
            'black',
            'citizenship_citizen_by_birth',
            'citizenship_citizen_by_naturalization',
            'citizenship_not_citizen',
            'days_above_90_4',
            'days_below_40_4',
            'education_field_business',
            'education_field_humanities',
            'education_field_stem',
            'education_grad',
            'education_grad_canada',
            'education_high_school',
            'education_high_school_canada',
            'education_ugrad',
            'education_ugrad_canada',
            'generation_boomer',
            'generation_genalpha',
            'generation_genx',
            'generation_genz',
            'generation_millenial',
            'generation_silent',
            'gridded_elevation',
            'heating_electricity',
            'heating_utility_gas',
            'hispanic',
            'homogeneity_250_2020',
            'homogeneity_250_diff_2000',
            'homogeneity_250_diff_2010',
            'hours_sunny_4',
            'household_income_above_100_cad',
            'household_income_over_100k',
            'household_income_under_50cad',
            'household_income_under_50k',
            'housing_per_pop',
            'individual_income_above_100_cad',
            'individual_income_over_100k',
            'individual_income_under_50cad',
            'individual_income_under_50k',
            'industry_accommodation_and_food_services',
            'industry_administrative_and_support_and_waste_management_services',
            'industry_agriculture,_forestry,_fishing_and_hunting',
            'industry_arts,_entertainment,_and_recreation',
            'industry_construction',
            'industry_educational_services',
            'industry_finance_and_insurance',
            'industry_health_care_and_social_assistance',
            'industry_information',
            'industry_management_of_companies_and_enterprises',
            'industry_manufacturing',
            'industry_mining,_quarrying,_and_oil_and_gas_extraction',
            'industry_other_services,_except_public_administration',
            'industry_professional,_scientific,_and_technical_services',
            'industry_public_administration',
            'industry_real_estate_and_rental_and_leasing',
            'industry_retail_trade',
            'industry_transportation_and_warehousing',
            'industry_utilities',
            'industry_wholesale_trade',
            'insurance_coverage_govt',
            'insurance_coverage_none',
            'insurance_coverage_private',
            'internet_no_access',
            'language_english_only',
            'language_spanish',
            'lapop1share_usda_fra_1',
            'lico_at_canada',
            'marriage_divorced',
            'mean_dist_Active Superfund Site_updated',
            'mean_dist_Airport_updated',
            'mean_dist_Hospital_updated',
            'mean_dist_Public School_updated',
            'mean_high_heat_index_4',
            'mean_high_temp_4',
            'mean_high_temp_fall_4',
            'mean_high_temp_spring_4',
            'mean_high_temp_summer_4',
            'mean_high_temp_winter_4',
            'occupation_architecture_and_engineering_occupations',
            'occupation_arts,_design,_entertainment,_sports,_and_media_occupations',
            'occupation_building_and_grounds_cleaning_and_maintenance_occupations',
            'occupation_business_and_financial_operations_occupations',
            'occupation_community_and_social_service_occupations',
            'occupation_computer_and_mathematical_occupations',
            'occupation_construction_and_extraction_occupations',
            'occupation_educational_instruction,_and_library_occupations',
            'occupation_farming,_fishing,_and_forestry_occupations',
            'occupation_firefighting_and_prevention,_and_other_protective_service_workers_including_supervisors',
            'occupation_food_preparation_and_serving_related_occupations',
            'occupation_health_diagnosing_and_treating_practitioners_and_other_technical_occupations',
            'occupation_health_technologists_and_technicians',
            'occupation_healthcare_support_occupations',
            'occupation_installation,_maintenance,_and_repair_occupations',
            'occupation_law_enforcement_workers_including_supervisors',
            'occupation_legal_occupations',
            'occupation_life,_physical,_and_social_science_occupations',
            'occupation_management_occupations',
            'occupation_material_moving_occupations',
            'occupation_office_and_administrative_support_occupations',
            'occupation_personal_care_and_service_occupations',
            'occupation_sales_and_related_occupations',
            'occupation_transportation_occupations',
            'park_percent_1km_v2',
            'pm_25_2018_2022',
            'population',
            'population_change_2000',
            'population_change_2010',
            'poverty_below_line',
            'rainfall_4',
            'rent_1br_over_1500',
            'rent_1br_under_750',
            'rent_2br_over_1500',
            'rent_2br_under_750',
            'rent_burden_over_40',
            'rent_burden_under_20',
            'rent_or_own_rent',
            'snowfall_4',
            'sors_cohabiting_partnered_gay',
            'traffic_fatalities_last_decade_per_capita',
            'traffic_fatalities_ped_last_decade_per_capita',
            'transportation_commute_time_over_60',
            'transportation_commute_time_under_15',
            'transportation_means_bike',
            'transportation_means_car',
            'transportation_means_transit',
            'transportation_means_walk',
            'transportation_means_worked_at_home',
            'vacancy',
            'vehicle_ownership_at_least_1',
            'white',
            'wind_speed_over_10mph_4',
            'year_built_1969_or_earlier',
            'year_built_2010_or_later',
        ],
        statQuestionNames: [
            '!FULL Which voted more for Clinton in the 2016 presidential election?',
            '!FULL Which swung towards Democrats more from 2016 to 2020?',
            '!FULL Which voted more for Biden in the 2020 presidential election?',
            'higher % of adults with arthritis',
            'higher % of adults who binge drink',
            'higher % of adults with high blood pressure',
            'higher % of adults with cancer (excluding skin cancer)',
            'higher % of adults with asthma',
            'higher % of adults with coronary heart disease',
            'higher % of adults with a cognitive disability',
            'higher % of adults with COPD !TOOLTIP (Chronic obstructive pulmonary disease, a lung disease linked to smoking)',
            'higher % of adults who smoke',
            'higher % of adults with diagnosed diabetes',
            'higher % of adults with a disability',
            'higher % of adults with a fair or poor self-rated health status',
            'higher % of adults with a hearing disability',
            'higher % of adults with an independent living disability',
            'higher % of adults with chronic kidney disease',
            'higher % of adults who don\'t exercise (do leisure-time physical activity)',
            'higher % of adults with a mobility disability',
            'higher % of adults with obesity',
            'higher % of adults with a self-care disability',
            'higher % of adults who sleep less than 7 hours',
            'higher % of adults who have had a stroke',
            'higher % of adults with a vision disability',
            'higher population-weighted density (r=1km)!TOOLTIP Population-weighted density is computed by computing the density within the given radius for each person in the region and then averaging the results. This is a better measure of the density that people actually experience.',
            'higher % increase in population-weighted density (r=1km) from 2000 to 2020!TOOLTIP Population-weighted density is computed by computing the density within the given radius for each person in the region and then averaging the results. This is a better measure of the density that people actually experience.',
            'higher % increase in population-weighted density (r=1km) from 2010 to 2020!TOOLTIP Population-weighted density is computed by computing the density within the given radius for each person in the region and then averaging the results. This is a better measure of the density that people actually experience.',
            'higher population-weighted density (r=4km)!TOOLTIP Population-weighted density is computed by computing the density within the given radius for each person in the region and then averaging the results. This is a better measure of the density that people actually experience.',
            'higher % of people who are Asian',
            'higher % of people who were born outside the US',
            'higher % of people who were born in the US and outside their state of residence',
            'higher % of people who were born in their state of residence',
            'higher % of people who are Black',
            'higher % of residents who are citizens by birth',
            'higher % of residents who are citizens by naturalization',
            'higher % of residents who are non-citizens',
            'higher % of hot days (days with high temp over 90\u00b0F, population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher % of cold days (days with high temp under 40\u00b0F, population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            '!FULL Which has more people with a business degree, as a percentage of the overall population?',
            '!FULL Which has more people with a humanities degree, as a percentage of the overall population?',
            '!FULL Which has more people with a STEM degree, as a percentage of the overall population?',
            'higher % of people who have a graduate degree',
            'higher % of people between 25 and 64 who have a graduate degree',
            'higher % of people who have at least a high school diploma',
            'higher % of people between 25 and 64 who have at least a high school diploma',
            'higher % of people who have at least an undergrad degree',
            'higher % of people between 25 and 64 who have at least an undergrad degree',
            'higher % of people who are boomers!TOOLTIP born between 1946 and 1966',
            'higher % of people who are gen alpha!TOOLTIP born between 2012 and 2021',
            'higher % of people who are gen x!TOOLTIP born between 1967 and 1981',
            'higher % of people who are gen z!TOOLTIP born between 1997 and 2011',
            'higher % of people who are millennials!TOOLTIP born between 1982 and 1996',
            'higher % of people who are silent generation!TOOLTIP born before 1946',
            'higher population-weighted mean elevation !TOOLTIP Population weighted elevation/hilliness statistics are calculated by computing the statistic for each person in the region and then averaging the results.',
            '!FULL Which has a larger % of homes heated using electricity?',
            '!FULL Which has a larger % of homes heated using utility gas?',
            'higher % of people who are Hispanic',
            'lower racial diversity!TOOLTIP We define racial diversity as the average probability a person selecting a random person in a 250m radius will select someone of a different race',
            '!FULL Which diversified less between 2000 and 2020?!TOOLTIP We define racial diversity as the average probability a person selecting a random person in a 250m radius will select someone of a different race',
            '!FULL Which diversified less between 2010 and 2020?!TOOLTIP We define racial diversity as the average probability a person selecting a random person in a 250m radius will select someone of a different race',
            '!FULL Which has more hours of sun per day on average? (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher % of households that have household income over C$100k',
            'higher % of households who have household income over $100k',
            'higher % of households that have household income under C$50k',
            'higher % of households who have household income under $50k',
            'higher number of housing units per adult',
            'higher % of people who have individual income over C$100k',
            'higher % of people who have individual income over $100k',
            'higher % of people who have individual income under C$50k',
            'higher % of people who have individual income under $50k',
            'higher % of workers employed in the accommodation and food services industry!TOOLTIP hotels, restaurants, bars, caterers, food services contractors, etc.',
            'higher % of workers employed in the administrative/support/waste management services industries!TOOLTIP office administrative services, employment services, business support services, waste management and remediation services, etc.',
            'higher % of workers employed in the agriculture, forestry, fishing, and hunting industries!TOOLTIP agriculture, forestry, logging, fishing, and hunting',
            'higher % of workers employed in the arts, entertainment, and recreation industry!TOOLTIP performing arts companies, sports, museums, amusement parks, gambling industries, etc.',
            'higher % of workers employed in the construction industry!TOOLTIP construction',
            'higher % of workers employed in the educational services industry!TOOLTIP elementary and secondary schools, colleges, universities,etc.',
            'higher % of workers employed in the finance and insurance industry!TOOLTIP banks, credit unions, insurance carriers, securities and commodity contracts, etc.',
            'higher % of workers employed in the health care and social assistance industry!TOOLTIP hospitals, nursing care facilities, offices of physicians, dentists, day care centers, etc.',
            'higher % of workers employed in the information industry!TOOLTIP publishing industries, software publishers, data processing services, broadcasting, libraries, etc.',
            'higher % of workers employed in the management industry!TOOLTIP management of companies and enterprises',
            'higher % of workers employed in the manufacturing industry!TOOLTIP metal manufacturing, machinery manufacturing, cement and concrete product manufacturing, sawmills, wood product manufacturing, food and beverage manufacturing, textile mills, apparel manufacturing, paper manufacturing, printing, chemical manufacturing, plastics and rubber products manufacturing, etc.',
            'higher % of workers employed in the mining, quarrying, and oil/gas extraction industries!TOOLTIP mining, quarrying, and oil and gas extraction',
            'higher % of workers employed in other service industries, except public administration!TOOLTIP repair and maintenance, personal care services, beauty salons, funeral homes, religious organizations, civic and social organizations, etc.',
            'higher % of workers employed in the professional, scientific, and technical services industry!TOOLTIP legal services, accounting services, architectural services, engineering services, computer systems design services, management consulting services, etc.',
            'higher % of workers employed in public administration!TOOLTIP executive offices, legislative bodies, public finance activities, public order and safety activities, etc.',
            'higher % of workers employed in the real estate and rental and leasing industry!TOOLTIP real estate, rental and leasing, lessors of real estate, etc.',
            'higher % of workers employed in the retail trade industry!TOOLTIP retailers of all kinds of goods, including stores, gas stations, florists, etc.',
            'higher % of workers employed in the transportation and warehousing industry!TOOLTIP transportation by all means, taxis, buses, sightseeing transportation, warehousing, etc.',
            'higher % of workers employed in the utilities industry!TOOLTIP electrical power generation, transmission, and distribution; natural gas distribution; water, sewage, and other systems',
            'higher % of workers employed in the wholesale trade industry!TOOLTIP wholesalers of all kinds of goods',
            'higher % of people who are on public insurance',
            'higher % of people who are uninsured',
            'higher % of people who are on private insurance',
            'higher % of people who have no internet access',
            'higher % of people who only speak english at home',
            'higher % of people who speak spanish at home',
            '!FULL Which has more access to grocery stores (higher % of people within 1mi of a grocery store)?!TOOLTIP The USDA defines a grocery store as a \'supermarket, supercenter, or large grocery store.\'',
            'higher % of people who are low income based on the Low-income cut-offs, after tax (LICO-AT)',
            'higher % of people who are divorced',
            '!FULL Which has less exposure to active EPA superfund sites (higher population-weighted mean distance)?!TOOLTIP EPA superfund sites are hazardous waste sites identified by the Environmental Protection Agency.',
            '!FULL Which has less access to airports (higher population-weighted mean distance)?',
            '!FULL Which has less access to hospitals (higher population-weighted mean distance)?',
            '!FULL Which has less access to public schools (higher population-weighted mean distance)?',
            'higher mean daily high heat index (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher mean daily high temperature (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher mean daily high temperature in fall (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher mean daily high temperature in spring (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher mean daily high temperature in summer (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher mean daily high temperature in winter (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher % of workers employed as architects and engineers!TOOLTIP all kinds of engineers except software engineers, architects, surveyors, drafters, etc.',
            'higher % of workers employed in arts, design, entertainment, sports, and media occupations!TOOLTIP artists, designers, musicians, actors, dancers, athletes, journalists, editors, writers, photographers, etc.',
            'higher % of workers employed in building and grounds cleaning and maintenance occupations!TOOLTIP janitors, maids, groundskeepers (gardeners), pest control workers, etc.',
            'higher % of workers employed in business and financial operations occupations!TOOLTIP sales agents, insurance agents, compliance officers, consultants, fundraisers, accountants, auditors, HR workers, etc.',
            'higher % of workers employed in community and social service occupations!TOOLTIP social workers, therapists, counselors, probation officers, clergy, etc.',
            'higher % of workers employed in computer and mathematical occupations!TOOLTIP software developers, software QA engineers, system administrators, actuaries, operations researchers',
            'higher % of workers employed in construction and extraction occupations!TOOLTIP carpenters, electricians, plumbers, roofers, miners, etc.',
            'higher % of workers employed in educational instruction, and library occupations!TOOLTIP teachers, tutors, professors, librarians, and archivists',
            'higher % of workers employed in farming, fishing, and forestry occupations!TOOLTIP farmers, ranchers, fishers, loggers, forest workers, etc.',
            'higher % of workers employed as firefighting and prevention, and other protective service workers including supervisors!TOOLTIP firefighters, fire inspectors, correctional officers, bailiffs, etc. (effectively, all protective service workers except police officers)',
            'higher % of workers employed as food preparers or servers!TOOLTIP cooks, waiters, bartenders, fast food workers, etc.',
            'higher % of workers employed in health diagnosing and treating practitioners and other technical occupations!TOOLTIP doctors, registered nurses, physical therapists, pharmacists, dietitians, veterinarians, paramedics, etc.',
            'higher % of workers employed as health technologists and technicians!TOOLTIP radiology technicians, lab technicians, hospital machinery technicians, etc.',
            'higher % of workers employed in healthcare support occupations!TOOLTIP nursing assistants, orderlies, home health aides, massage therapists, dental assistants, etc.',
            'higher % of workers employed in installation, maintenance, and repair occupations!TOOLTIP mechanics, HVAC technicians, electricians, plumbers, etc.',
            'higher % of workers employed as law enforcement workers including supervisors!TOOLTIP police officers, detectives, etc.',
            'higher % of workers employed in legal occupations!TOOLTIP lawyers, judges, paralegals, legal assistants, etc.',
            'higher % of workers employed in life, physical, and social science occupations!TOOLTIP biological scientists, chemists, physicists, geologists, food scientists, economists, phychologists, urban planners, sociologists, scientific technicians, etc.',
            'higher % of workers employed as managers!TOOLTIP a variety of roles titled \'managers\'',
            'higher % of workers employed as material movers!TOOLTIP forklift operators, stock clerks, conveyor operators, etc.',
            'higher % of workers employed as office and administrative support workers!TOOLTIP secretaries, receptionists, data entry clerks, office clerks, mail carriers, shipping clerks, etc.',
            'higher % of workers employed in personal care and service occupations!TOOLTIP hairdressers, childcare workers, fitness trainers, funeral service workers, travel guides, animal trainers, etc.',
            'higher % of workers employed in sales and related occupations!TOOLTIP retail salespersons, cashiers, telemarketers, real estate agents, travel agents, travelling salespeople, etc.',
            'higher % of workers employed in transportation occupations!TOOLTIP truck drivers, bus drivers, taxi drivers, pilots, flight attendants, sailors, etc.',
            '!FULL Which has more access to parks (higher % of area within 1km of a park, population weighted)?',
            'higher population-weighted PM2.5 pollution !TOOLTIP Population weighted pollution statistics are calculated by computing the statistic for each person in the region and then averaging the results.',
            'higher population',
            'higher % increase in population from 2000 to 2020',
            'higher % increase in population from 2010 to 2020',
            'higher % of people who are in poverty',
            'higher rainfall (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher % of units with 1br rent over $1500',
            'higher % of units with 1br rent under $750',
            'higher % of units with 2br rent over $1500',
            'higher % of units with 2br rent under $750',
            'higher % of people whose rent is greater than 40% of their income',
            'higher % of people whose rent is less than 20% of their income',
            'higher % of people who are renters',
            'higher snowfall (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher % of people who are gay and cohabiting with a partner/spouse',
            'higher traffic fatalities per capita between 2013 and 2022!TOOLTIP traffic fatalities in the region, divided by the population of the region',
            'higher pedestrian/cyclist fatalities per capita between 2013 and 2022!TOOLTIP pedestrian and cyclist fatalities in the region, divided by the population of the region',
            'higher % of people who have commute time over 60 min',
            'higher % of people who have commute time under 15 min',
            'higher % of people who commute by bike',
            'higher % of people who commute by car',
            'higher % of people who commute by transit',
            'higher % of people who commute by walking',
            'higher % of people who work from home',
            'higher % of units that are vacant!TOOLTIP Vacancy is the % of housing units that were not occupied on April 1, 2020 (census night). This includes vacation homes but does *not* include units that were not occupied due to the pandemic, the census attempted to account for this.',
            'higher % of households with at least 1 vehicle',
            'higher % of people who are White',
            'higher % of days with wind speed over 10mph (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher % units built pre-1970',
            'higher % units built in 2010s+',
        ],
        questionDistribution: [
            [
                {
                    path: 'quiz_sampling_info/2/q1/0.gz',
                    totalP: 0.13327501714229584,
                },
                {
                    path: 'quiz_sampling_info/2/q1/1.gz',
                    totalP: 0.08408248424530029,
                },
                {
                    path: 'quiz_sampling_info/2/q1/2.gz',
                    totalP: 0.09323830157518387,
                },
                {
                    path: 'quiz_sampling_info/2/q1/3.gz',
                    totalP: 0.0913185179233551,
                },
                {
                    path: 'quiz_sampling_info/2/q1/4.gz',
                    totalP: 0.11703868210315704,
                },
                {
                    path: 'quiz_sampling_info/2/q1/5.gz',
                    totalP: 0.09308502078056335,
                },
                {
                    path: 'quiz_sampling_info/2/q1/6.gz',
                    totalP: 0.07153940200805664,
                },
                {
                    path: 'quiz_sampling_info/2/q1/7.gz',
                    totalP: 0.13186103105545044,
                },
                {
                    path: 'quiz_sampling_info/2/q1/8.gz',
                    totalP: 0.10118448734283447,
                },
                {
                    path: 'quiz_sampling_info/2/q1/9.gz',
                    totalP: 0.08337727189064026,
                },
            ],
            [
                {
                    path: 'quiz_sampling_info/2/q2/0.gz',
                    totalP: 0.11939659714698792,
                },
                {
                    path: 'quiz_sampling_info/2/q2/1.gz',
                    totalP: 0.10214229673147202,
                },
                {
                    path: 'quiz_sampling_info/2/q2/2.gz',
                    totalP: 0.07738789916038513,
                },
                {
                    path: 'quiz_sampling_info/2/q2/3.gz',
                    totalP: 0.10572599619626999,
                },
                {
                    path: 'quiz_sampling_info/2/q2/4.gz',
                    totalP: 0.13947109878063202,
                },
                {
                    path: 'quiz_sampling_info/2/q2/5.gz',
                    totalP: 0.09562033414840698,
                },
                {
                    path: 'quiz_sampling_info/2/q2/6.gz',
                    totalP: 0.11173424869775772,
                },
                {
                    path: 'quiz_sampling_info/2/q2/7.gz',
                    totalP: 0.09565436094999313,
                },
                {
                    path: 'quiz_sampling_info/2/q2/8.gz',
                    totalP: 0.13191796839237213,
                },
                {
                    path: 'quiz_sampling_info/2/q2/9.gz',
                    totalP: 0.0209491066634655,
                },
            ],
            [
                {
                    path: 'quiz_sampling_info/2/q3/0.gz',
                    totalP: 0.09546151012182236,
                },
                {
                    path: 'quiz_sampling_info/2/q3/1.gz',
                    totalP: 0.11585822701454163,
                },
                {
                    path: 'quiz_sampling_info/2/q3/2.gz',
                    totalP: 0.051663871854543686,
                },
                {
                    path: 'quiz_sampling_info/2/q3/3.gz',
                    totalP: 0.09049109369516373,
                },
                {
                    path: 'quiz_sampling_info/2/q3/4.gz',
                    totalP: 0.08840103447437286,
                },
                {
                    path: 'quiz_sampling_info/2/q3/5.gz',
                    totalP: 0.12578904628753662,
                },
                {
                    path: 'quiz_sampling_info/2/q3/6.gz',
                    totalP: 0.07248393446207047,
                },
                {
                    path: 'quiz_sampling_info/2/q3/7.gz',
                    totalP: 0.03781133145093918,
                },
                {
                    path: 'quiz_sampling_info/2/q3/8.gz',
                    totalP: 0.08990193903446198,
                },
                {
                    path: 'quiz_sampling_info/2/q3/9.gz',
                    totalP: 0.08986303955316544,
                },
                {
                    path: 'quiz_sampling_info/2/q3/10.gz',
                    totalP: 0.11391045898199081,
                },
                {
                    path: 'quiz_sampling_info/2/q3/11.gz',
                    totalP: 0.028364667668938637,
                },
            ],
            [
                {
                    path: 'quiz_sampling_info/2/q4/0.gz',
                    totalP: 0.09705943614244461,
                },
                {
                    path: 'quiz_sampling_info/2/q4/1.gz',
                    totalP: 0.12532712519168854,
                },
                {
                    path: 'quiz_sampling_info/2/q4/2.gz',
                    totalP: 0.08089789748191833,
                },
                {
                    path: 'quiz_sampling_info/2/q4/3.gz',
                    totalP: 0.0612775981426239,
                },
                {
                    path: 'quiz_sampling_info/2/q4/4.gz',
                    totalP: 0.127346470952034,
                },
                {
                    path: 'quiz_sampling_info/2/q4/5.gz',
                    totalP: 0.07848260551691055,
                },
                {
                    path: 'quiz_sampling_info/2/q4/6.gz',
                    totalP: 0.058932721614837646,
                },
                {
                    path: 'quiz_sampling_info/2/q4/7.gz',
                    totalP: 0.07839249074459076,
                },
                {
                    path: 'quiz_sampling_info/2/q4/8.gz',
                    totalP: 0.027474295347929,
                },
                {
                    path: 'quiz_sampling_info/2/q4/9.gz',
                    totalP: 0.02980908751487732,
                },
                {
                    path: 'quiz_sampling_info/2/q4/10.gz',
                    totalP: 0.08202313631772995,
                },
                {
                    path: 'quiz_sampling_info/2/q4/11.gz',
                    totalP: 0.0730978474020958,
                },
                {
                    path: 'quiz_sampling_info/2/q4/12.gz',
                    totalP: 0.07803190499544144,
                },
                {
                    path: 'quiz_sampling_info/2/q4/13.gz',
                    totalP: 0.0018478044075891376,
                },
            ],
            [
                {
                    path: 'quiz_sampling_info/2/q5/0.gz',
                    totalP: 0.022105148062109947,
                },
                {
                    path: 'quiz_sampling_info/2/q5/1.gz',
                    totalP: 0.026363184675574303,
                },
                {
                    path: 'quiz_sampling_info/2/q5/2.gz',
                    totalP: 0.02688653953373432,
                },
                {
                    path: 'quiz_sampling_info/2/q5/3.gz',
                    totalP: 0.02549194172024727,
                },
                {
                    path: 'quiz_sampling_info/2/q5/4.gz',
                    totalP: 0.025656815618276596,
                },
                {
                    path: 'quiz_sampling_info/2/q5/5.gz',
                    totalP: 0.0323963426053524,
                },
                {
                    path: 'quiz_sampling_info/2/q5/6.gz',
                    totalP: 0.015396876260638237,
                },
                {
                    path: 'quiz_sampling_info/2/q5/7.gz',
                    totalP: 0.01811441034078598,
                },
                {
                    path: 'quiz_sampling_info/2/q5/8.gz',
                    totalP: 0.013824899680912495,
                },
                {
                    path: 'quiz_sampling_info/2/q5/9.gz',
                    totalP: 0.013987055979669094,
                },
                {
                    path: 'quiz_sampling_info/2/q5/10.gz',
                    totalP: 0.022712815552949905,
                },
                {
                    path: 'quiz_sampling_info/2/q5/11.gz',
                    totalP: 0.018581371754407883,
                },
                {
                    path: 'quiz_sampling_info/2/q5/12.gz',
                    totalP: 0.018335234373807907,
                },
                {
                    path: 'quiz_sampling_info/2/q5/13.gz',
                    totalP: 0.023737147450447083,
                },
                {
                    path: 'quiz_sampling_info/2/q5/14.gz',
                    totalP: 0.019960030913352966,
                },
                {
                    path: 'quiz_sampling_info/2/q5/15.gz',
                    totalP: 0.01720541901886463,
                },
                {
                    path: 'quiz_sampling_info/2/q5/16.gz',
                    totalP: 0.02458079904317856,
                },
                {
                    path: 'quiz_sampling_info/2/q5/17.gz',
                    totalP: 0.029714619740843773,
                },
                {
                    path: 'quiz_sampling_info/2/q5/18.gz',
                    totalP: 0.021151766180992126,
                },
                {
                    path: 'quiz_sampling_info/2/q5/19.gz',
                    totalP: 0.03653767332434654,
                },
                {
                    path: 'quiz_sampling_info/2/q5/20.gz',
                    totalP: 0.026383807882666588,
                },
                {
                    path: 'quiz_sampling_info/2/q5/21.gz',
                    totalP: 0.056394293904304504,
                },
                {
                    path: 'quiz_sampling_info/2/q5/22.gz',
                    totalP: 0.016449768096208572,
                },
                {
                    path: 'quiz_sampling_info/2/q5/23.gz',
                    totalP: 0.024614354595541954,
                },
                {
                    path: 'quiz_sampling_info/2/q5/24.gz',
                    totalP: 0.01937439851462841,
                },
                {
                    path: 'quiz_sampling_info/2/q5/25.gz',
                    totalP: 0.0183571707457304,
                },
                {
                    path: 'quiz_sampling_info/2/q5/26.gz',
                    totalP: 0.012879464775323868,
                },
                {
                    path: 'quiz_sampling_info/2/q5/27.gz',
                    totalP: 0.0342848002910614,
                },
                {
                    path: 'quiz_sampling_info/2/q5/28.gz',
                    totalP: 0.06008770316839218,
                },
                {
                    path: 'quiz_sampling_info/2/q5/29.gz',
                    totalP: 0.00762446504086256,
                },
                {
                    path: 'quiz_sampling_info/2/q5/30.gz',
                    totalP: 0.009171480312943459,
                },
                {
                    path: 'quiz_sampling_info/2/q5/31.gz',
                    totalP: 0.028793953359127045,
                },
                {
                    path: 'quiz_sampling_info/2/q5/32.gz',
                    totalP: 0.024248329922556877,
                },
                {
                    path: 'quiz_sampling_info/2/q5/33.gz',
                    totalP: 0.0072729745879769325,
                },
                {
                    path: 'quiz_sampling_info/2/q5/34.gz',
                    totalP: 0.007555076852440834,
                },
                {
                    path: 'quiz_sampling_info/2/q5/35.gz',
                    totalP: 0.009344673715531826,
                },
                {
                    path: 'quiz_sampling_info/2/q5/36.gz',
                    totalP: 0.008811642415821552,
                },
                {
                    path: 'quiz_sampling_info/2/q5/37.gz',
                    totalP: 0.01731199212372303,
                },
                {
                    path: 'quiz_sampling_info/2/q5/38.gz',
                    totalP: 0.032009996473789215,
                },
                {
                    path: 'quiz_sampling_info/2/q5/39.gz',
                    totalP: 0.023066898807883263,
                },
                {
                    path: 'quiz_sampling_info/2/q5/40.gz',
                    totalP: 0.018673639744520187,
                },
                {
                    path: 'quiz_sampling_info/2/q5/41.gz',
                    totalP: 0.03789661452174187,
                },
                {
                    path: 'quiz_sampling_info/2/q5/42.gz',
                    totalP: 0.04665271192789078,
                },
            ],
        ],
        juxtaVersion: 2,
    },
    {
        allGeographies: [
            'AK-AL (2023), USA',
            'AL-01 (2025), USA',
            'AL-02 (2025), USA',
            'AL-03 (2025), USA',
            'AL-04 (2025), USA',
            'AL-05 (2025), USA',
            'AL-06 (2025), USA',
            'AL-07 (2025), USA',
            'AR-01 (2023), USA',
            'AR-02 (2023), USA',
            'AR-03 (2023), USA',
            'AR-04 (2023), USA',
            'AZ-01 (2023), USA',
            'AZ-02 (2023), USA',
            'AZ-03 (2023), USA',
            'AZ-04 (2023), USA',
            'AZ-05 (2023), USA',
            'AZ-06 (2023), USA',
            'AZ-07 (2023), USA',
            'AZ-08 (2023), USA',
            'AZ-09 (2023), USA',
            'Abia, Nigeria',
            'Abidjan Urban Center, Ivory Coast',
            'Abidjan, Ivory Coast',
            'Abilene-Sweetwater TX Media Market, USA',
            'Abuja Federal Capital Territory, Nigeria',
            'Ab\u016b Z\u0327aby, United Arab Emirates',
            'Accra Urban Center, Ghana',
            'Aceh, Indonesia',
            'Ad Daqahl\u012byah, Egypt',
            'Ada County, Idaho, USA',
            'Adamawa, Nigeria',
            'Adams County, Colorado, USA',
            'Addis Ababa Urban Center, Ethiopia',
            'Afghanistan',
            'Agra Urban Center, India',
            'Aguadilla-Isabela MSA, PR, USA',
            'Ahmedabad Urban Center, India',
            'Aiti, Japan',
            'Akron MSA, OH, USA',
            'Akron [Urban Area], OH, USA',
            'Akwa Ibom, Nigeria',
            'Al Ba\u015frah, Iraq',
            'Al Ba\u1e29r al A\u1e29mar, Sudan',
            'Al Buh\u0327ayrah, Egypt',
            'Al Fayy\u016bm, Egypt',
            'Al Gharb\u012byah, Egypt',
            'Al Iskandariyah, Egypt',
            'Al Jaz\u012brah, Sudan',
            'Al J\u012bzah, Egypt',
            'Al Khar\u0163\u016bm, Sudan',
            'Al Miny\u0101, Egypt',
            'Al Min\u016bf\u012byah, Egypt',
            'Al Qaly\u016bb\u012byah, Egypt',
            'Al Q\u0101hirah, Egypt',
            'Al \u1e28udaydah, Yemen',
            'Al \u2018A\u0305\u015fimah, Jordan',
            'Alabama, USA',
            'Alachua County, Florida, USA',
            'Alagoas, Brazil',
            'Alameda County, California, USA',
            'Alaska, USA',
            'Albania',
            'Albany County, New York, USA',
            'Albany GA Media Market, USA',
            'Albany-Schenectady [Urban Area], NY, USA',
            'Albany-Schenectady-Troy MSA, NY, USA',
            'Albany-Schenectady-Troy NY Media Market, USA',
            'Alberta, Canada',
            'Albuquerque MSA, NM, USA',
            'Albuquerque Urban Center, USA',
            'Albuquerque [Urban Area], NM, USA',
            'Albuquerque city, New Mexico, USA',
            'Albuquerque-Santa Fe NM Media Market, USA',
            'Alexandria Urban Center, Egypt',
            'Algeria',
            'Algiers Urban Center, Algeria',
            'Allegheny County, Pennsylvania, USA',
            'Allen County, Indiana, USA',
            'Allentown Urban Center, USA',
            'Allentown-Bethlehem [Urban Area], PA-NJ, USA',
            'Allentown-Bethlehem-Easton MSA, PA-NJ, USA',
            'Almaty oblysy, Kazakhstan',
            'Amarillo MSA, TX, USA',
            'Amarillo TX Media Market, USA',
            'Amazonas, Brazil',
            'Amman Urban Center, Jordan',
            'Am\u0101nat al \u2018\u0100\u015fimah [city], Yemen',
            'An N\u012bl al Abya\u1e11, Sudan',
            'Anaheim city, California, USA',
            'Analamanga, Madagascar',
            'Anambra, Nigeria',
            'Anchorage AK Media Market, USA',
            'Anchorage MSA, AK, USA',
            'Anchorage Municipality, Alaska, USA',
            'Anchorage municipality, Alaska, USA',
            'Andaluc\u00eda, Spain',
            'Andhra Pradesh, India',
            'Andijon, Uzbekistan',
            'Angola',
            'Anhui Sheng, China',
            'Ankara Urban Center, Turkey',
            'Ankara, Turkey',
            'Ann Arbor MSA, MI, USA',
            'Ann Arbor [Urban Area], MI, USA',
            'Anne Arundel County, Maryland, USA',
            'Anoka County, Minnesota, USA',
            'Antalya, Turkey',
            'Antananarivo Urban Center, Madagascar',
            'Antioch Urban Center, USA',
            'Antioch [Urban Area], CA, USA',
            'Antioquia, Colombia',
            'Ar Riy\u0101\u1e11, Saudi Arabia',
            'Arapahoe County, Colorado, USA',
            'Arb\u012bl, Iraq',
            'Argentina',
            'Arizona, USA',
            'Arkansas, USA',
            'Arlington city, Texas, USA',
            'Armenia',
            'Asansol Urban Center, India',
            'Ash Sharq\u012byah, Egypt',
            'Ash Sharq\u012byah, Saudi Arabia',
            'Ashanti, Ghana',
            'Asheville MSA, NC, USA',
            'Asheville [Urban Area], NC, USA',
            'Assam, India',
            'Asy\u016b\u0163, Egypt',
            'Athens Urban Center, Greece',
            'Atlanta GA Media Market, USA',
            'Atlanta Urban Center, USA',
            'Atlanta [Urban Area], GA, USA',
            'Atlanta city, Georgia, USA',
            'Atlanta-Sandy Springs-Roswell MSA, GA, USA',
            'Atlantic City-Hammonton MSA, NJ, USA',
            'Atlantic City-Ocean City-Villas [Urban Area], NJ, USA',
            'Atlantic County, New Jersey, USA',
            'Atl\u00e1ntico, Colombia',
            'Attik\u00ed, Greece',
            'Augusta GA Media Market, USA',
            'Augusta-Richmond County MSA, GA-SC, USA',
            'Augusta-Richmond County [Urban Area], GA-SC, USA',
            'Aurora city, Colorado, USA',
            'Austin TX Media Market, USA',
            'Austin Urban Center, USA',
            'Austin [Urban Area], TX, USA',
            'Austin city, Texas, USA',
            'Austin-Round Rock MSA, TX, USA',
            'Australia',
            'Austria',
            'Auvergne-Rh\u00f4ne-Alpes, France',
            'Ayeyarwady, Myanmar',
            'Azad Jammu and Kashmir, Pakistan',
            'Azerbaijan',
            'Azerbaijan, Azerbaijan',
            'Baden-W\u00fcrttemberg, Germany',
            'Baghdad Urban Center, Iraq',
            'Baghd\u0101d, Iraq',
            'Bago, Myanmar',
            'Bahia, Brazil',
            'Baja California, Mexico',
            'Bakersfield CA Media Market, USA',
            'Bakersfield MSA, CA, USA',
            'Bakersfield Urban Center, USA',
            'Bakersfield [Urban Area], CA, USA',
            'Bakersfield city, California, USA',
            'Bali, Indonesia',
            'Balochistan, Pakistan',
            'Baltimore County, Maryland, USA',
            'Baltimore MD Media Market, USA',
            'Baltimore Urban Center, USA',
            'Baltimore [Urban Area], MD, USA',
            'Baltimore city, Maryland, USA',
            'Baltimore city-County, Maryland, USA',
            'Baltimore-Columbia-Towson MSA, MD, USA',
            'Bamako Urban Center, Mali',
            'Bamako, Mali',
            'Banaadir, Somalia',
            'Bandung Urban Center, Indonesia',
            'Bangkok Urban Center, Thailand',
            'Bangladesh',
            'Bangor ME Media Market, USA',
            'Banten, Indonesia',
            'Ban\u012b Suwayf, Egypt',
            'Barcelona Urban Center, Spain',
            'Barishal, Bangladesh',
            'Barnstable Town [Urban Area], MA, USA',
            'Bas-Sassandra, Ivory Coast',
            'Bashkortostan, Respublika, Russia',
            'Basn\u0101hira pa\u1e37\u0101ta, Sri Lanka',
            'Batangas, Philippines',
            'Baton Rouge LA Media Market, USA',
            'Baton Rouge MSA, LA, USA',
            'Baton Rouge Urban Center, USA',
            'Baton Rouge [Urban Area], LA, USA',
            'Bauchi, Nigeria',
            'Bayelsa, Nigeria',
            'Bayern, Germany',
            'Beaumont-Port Arthur MSA, TX, USA',
            'Beaumont-Port Arthur TX Media Market, USA',
            'Beijing Shi, China',
            'Beijing Urban Center, China',
            'Belarus',
            'Belgium',
            'Bell County, Texas, USA',
            'Belo Horizonte Urban Center, Brazil',
            'Bengaluru Urban Center, India',
            'Benin',
            'Benton County, Arkansas, USA',
            'Benue, Nigeria',
            'Bergen County, New Jersey, USA',
            'Berks County, Pennsylvania, USA',
            'Berlin Urban Center, Germany',
            'Berlin, Germany',
            'Bernalillo County, New Mexico, USA',
            'Bexar County, Texas, USA',
            'Bih\u0101r, India',
            'Billings MT Media Market, USA',
            'Biloxi-Gulfport MS Media Market, USA',
            'Binghamton NY Media Market, USA',
            'Birmingham (Anniston & Tuscaloosa) AL Media Market, USA',
            'Birmingham Urban Center, United Kingdom',
            'Birmingham [Urban Area], AL, USA',
            'Birmingham-Hoover MSA, AL, USA',
            'Bluefield-Beckley-Oak Hill WV Media Market, USA',
            'Bogota Urban Center, Colombia',
            'Boise City MSA, ID, USA',
            'Boise City [Urban Area], ID, USA',
            'Boise ID Media Market, USA',
            'Boise Urban Center, USA',
            'Bolivia',
            'Bonita Springs-Estero [Urban Area], FL, USA',
            'Borno, Nigeria',
            'Bosnia and Herzegovina',
            'Boston MA (Manchester NH) Media Market, USA',
            'Boston Urban Center, USA',
            'Boston [Urban Area], MA-NH, USA',
            'Boston city, Massachusetts, USA',
            'Boston-Cambridge-Newton MSA, MA-NH, USA',
            'Botswana',
            'Boulder County, Colorado, USA',
            'Boulder MSA, CO, USA',
            'Bourgogne-Franche-Comt\u00e9, France',
            'Bradenton Urban Center, USA',
            'Bradenton-Sarasota-Venice [Urban Area], FL, USA',
            'Brampton City, Peel Regional municipality, Ontario, Canada',
            'Brandenburg, Germany',
            'Brazil',
            'Brazoria County, Texas, USA',
            'Brazzaville Urban Center, Congo',
            'Bremerton-Silverdale MSA, WA, USA',
            'Bretagne, France',
            'Brevard County, Florida, USA',
            'Brick Urban Center, USA',
            'Bridgeport Urban Center, USA',
            'Bridgeport-Stamford [Urban Area], CT-NY, USA',
            'Bridgeport-Stamford-Norwalk MSA, CT, USA',
            'Bristol County, Massachusetts, USA',
            'British Columbia, Canada',
            'Bronx County, New York, USA',
            'Broward County, Florida, USA',
            'Brown County, Wisconsin, USA',
            'Brownsville-Harlingen MSA, TX, USA',
            'Bucks County, Pennsylvania, USA',
            'Buenos Aires Urban Center, Argentina',
            'Buenos Aires, Argentina',
            'Buffalo NY Media Market, USA',
            'Buffalo Urban Center, USA',
            'Buffalo [Urban Area], NY, USA',
            'Buffalo city, New York, USA',
            'Buffalo-Cheektowaga-Niagara Falls MSA, NY, USA',
            'Bulacan, Philippines',
            'Bulgaria',
            'Buncombe County, North Carolina, USA',
            'Burkina Faso',
            'Burlington County, New Jersey, USA',
            'Burlington VT-Plattsburgh NY Media Market, USA',
            'Bursa, Turkey',
            'Burundi',
            'Busan Urban Center, South Korea',
            'Busan-gwangyeoksi, South Korea',
            'Butler County, Ohio, USA',
            'B\u00e9ni Mellal-Kh\u00e9nifra, Morocco',
            'B\u0101bil, Iraq',
            'B\u0101gmat\u012b, Nepal',
            'CA-01 (2023), USA',
            'CA-02 (2023), USA',
            'CA-03 (2023), USA',
            'CA-04 (2023), USA',
            'CA-05 (2023), USA',
            'CA-06 (2023), USA',
            'CA-07 (2023), USA',
            'CA-08 (2023), USA',
            'CA-09 (2023), USA',
            'CA-10 (2023), USA',
            'CA-11 (2023), USA',
            'CA-12 (2023), USA',
            'CA-13 (2023), USA',
            'CA-14 (2023), USA',
            'CA-15 (2023), USA',
            'CA-16 (2023), USA',
            'CA-17 (2023), USA',
            'CA-18 (2023), USA',
            'CA-19 (2023), USA',
            'CA-20 (2023), USA',
            'CA-21 (2023), USA',
            'CA-22 (2023), USA',
            'CA-23 (2023), USA',
            'CA-24 (2023), USA',
            'CA-25 (2023), USA',
            'CA-26 (2023), USA',
            'CA-27 (2023), USA',
            'CA-28 (2023), USA',
            'CA-29 (2023), USA',
            'CA-30 (2023), USA',
            'CA-31 (2023), USA',
            'CA-32 (2023), USA',
            'CA-33 (2023), USA',
            'CA-34 (2023), USA',
            'CA-35 (2023), USA',
            'CA-36 (2023), USA',
            'CA-37 (2023), USA',
            'CA-38 (2023), USA',
            'CA-39 (2023), USA',
            'CA-40 (2023), USA',
            'CA-41 (2023), USA',
            'CA-42 (2023), USA',
            'CA-43 (2023), USA',
            'CA-44 (2023), USA',
            'CA-45 (2023), USA',
            'CA-46 (2023), USA',
            'CA-47 (2023), USA',
            'CA-48 (2023), USA',
            'CA-49 (2023), USA',
            'CA-50 (2023), USA',
            'CA-51 (2023), USA',
            'CA-52 (2023), USA',
            'CO-01 (2023), USA',
            'CO-02 (2023), USA',
            'CO-03 (2023), USA',
            'CO-04 (2023), USA',
            'CO-05 (2023), USA',
            'CO-06 (2023), USA',
            'CO-07 (2023), USA',
            'CO-08 (2023), USA',
            'CT-01 (2023), USA',
            'CT-02 (2023), USA',
            'CT-03 (2023), USA',
            'CT-04 (2023), USA',
            'CT-05 (2023), USA',
            'Cairo Urban Center, Egypt',
            'Calgary CMA, AB, Canada',
            'Calgary City, Division No. 6, Alberta, Canada',
            'Calgary Population Center, AB, Canada',
            'Calgary Urban Center, Canada',
            'Cali Urban Center, Colombia',
            'California, USA',
            'Cambodia',
            'Camden County, New Jersey, USA',
            'Cameron County, Texas, USA',
            'Cameroon',
            'Campania, Italy',
            'Canada',
            'Canton [Urban Area], OH, USA',
            'Canton-Massillon MSA, OH, USA',
            'Cape Coral Urban Center, USA',
            'Cape Coral [Urban Area], FL, USA',
            'Cape Coral-Fort Myers MSA, FL, USA',
            'Cape Town Urban Center, South Africa',
            'Capital Regional district, British Columbia, Canada',
            'Capitol Planning Region, Connecticut, USA',
            'Caracas Urban Center, Venezuela',
            'Casablanca Urban Center, Morocco',
            'Casablanca-Settat, Morocco',
            'Catalunya, Spain',
            'Cavite, Philippines',
            'Cear\u00e1, Brazil',
            'Cebu City Urban Center, Philippines',
            'Cebu, Philippines',
            'Cedar Rapids MSA, IA, USA',
            'Cedar Rapids-Waterloo-Iowa City & Dubuque IA Media Market, USA',
            'Central African Republic',
            'Central, Ghana',
            'Centre, Burkina Faso',
            'Centre, Cameroon',
            'Centre-Val de Loire, France',
            'Chad',
            'Champaign & Springfield-Decatur IL Media Market, USA',
            'Chandler city, Arizona, USA',
            'Changchun Urban Center, China',
            'Changsha Urban Center, China',
            'Changzhou Urban Center, China',
            'Charleston County, South Carolina, USA',
            'Charleston SC Media Market, USA',
            'Charleston [Urban Area], SC, USA',
            'Charleston-Huntington WV Media Market, USA',
            'Charleston-North Charleston MSA, SC, USA',
            'Charlotte NC Media Market, USA',
            'Charlotte Urban Center, USA',
            'Charlotte [Urban Area], NC-SC, USA',
            'Charlotte city, North Carolina, USA',
            'Charlotte-Concord-Gastonia MSA, NC-SC, USA',
            'Chatham County, Georgia, USA',
            'Chattanooga MSA, TN-GA, USA',
            'Chattanooga TN Media Market, USA',
            'Chattanooga [Urban Area], TN-GA, USA',
            'Chattogram Urban Center, Bangladesh',
            'Chattogram, Bangladesh',
            'Chelyabinskaya oblast\', Russia',
            'Chengdu Urban Center, China',
            'Chennai Urban Center, India',
            'Cherokee County, Georgia, USA',
            'Chester County, Pennsylvania, USA',
            'Chesterfield County, Virginia, USA',
            'Chhatt\u012bsgarh, India',
            'Chiapas, Mexico',
            'Chicago IL Media Market, USA',
            'Chicago Urban Center, USA',
            'Chicago [Urban Area], IL-IN, USA',
            'Chicago city, Illinois, USA',
            'Chicago-Naperville-Elgin MSA, IL-IN-WI, USA',
            'Chico-Redding CA Media Market, USA',
            'Chihuahua, Mexico',
            'Chile',
            'China',
            'Chongqing Shi, China',
            'Chongqing Urban Center, China',
            'Chula Vista city, California, USA',
            'Cincinnati MSA, OH-KY-IN, USA',
            'Cincinnati OH Media Market, USA',
            'Cincinnati Urban Center, USA',
            'Cincinnati [Urban Area], OH-KY, USA',
            'Cincinnati city, Ohio, USA',
            'Ciudad Ju\u00e1rez Urban Center, Mexico-USA',
            'Ciudad de M\u00e9xico, Mexico',
            'Clackamas County, Oregon, USA',
            'Clark County, Nevada, USA',
            'Clark County, Washington, USA',
            'Clarksburg-Weston WV Media Market, USA',
            'Clarksville MSA, TN-KY, USA',
            'Clay County, Missouri, USA',
            'Clayton County, Georgia, USA',
            'Cleveland County, Oklahoma, USA',
            'Cleveland Urban Center, USA',
            'Cleveland [Urban Area], OH, USA',
            'Cleveland city, Ohio, USA',
            'Cleveland-Akron (Canton) OH Media Market, USA',
            'Cleveland-Elyria MSA, OH, USA',
            'Coahuila de Zaragoza, Mexico',
            'Cobb County, Georgia, USA',
            'College Station-Bryan MSA, TX, USA',
            'Collier County, Florida, USA',
            'Collin County, Texas, USA',
            'Colombia',
            'Colombo Urban Center, Sri Lanka',
            'Colorado Springs MSA, CO, USA',
            'Colorado Springs Urban Center, USA',
            'Colorado Springs [Urban Area], CO, USA',
            'Colorado Springs city, Colorado, USA',
            'Colorado Springs-Pueblo CO Media Market, USA',
            'Colorado, USA',
            'Columbia MSA, SC, USA',
            'Columbia SC Media Market, USA',
            'Columbia [Urban Area], SC, USA',
            'Columbia-Jefferson City MO Media Market, USA',
            'Columbus (Ohio) Urban Center, USA',
            'Columbus GA Media Market, USA',
            'Columbus MSA, GA-AL, USA',
            'Columbus MSA, OH, USA',
            'Columbus OH Media Market, USA',
            'Columbus [Urban Area], GA-AL, USA',
            'Columbus [Urban Area], OH, USA',
            'Columbus city, Ohio, USA',
            'Columbus-Tupelo-West Point MS Media Market, USA',
            'Comilla Urban Center, Bangladesh',
            'Conakry Urban Center, Guinea',
            'Concord Urban Center, USA',
            'Concord [Urban Area], NC, USA',
            'Concord-Walnut Creek [Urban Area], CA, USA',
            'Congo',
            'Connecticut, USA',
            'Contra Costa County, California, USA',
            'Cook County, Illinois, USA',
            'Copperbelt, Zambia',
            'Corpus Christi MSA, TX, USA',
            'Corpus Christi TX Media Market, USA',
            'Corpus Christi [Urban Area], TX, USA',
            'Corpus Christi city, Texas, USA',
            'Costa Rica',
            'Crestview-Fort Walton Beach-Destin MSA, FL, USA',
            'Croatia',
            'Cross River, Nigeria',
            'Cuba',
            'Cumberland County, Maine, USA',
            'Cumberland County, North Carolina, USA',
            'Cumberland County, Pennsylvania, USA',
            'Cundinamarca, Colombia',
            'Cuyahoga County, Ohio, USA',
            'Czech Republic',
            'C\u00f3rdoba, Argentina',
            'DE-AL (2023), USA',
            'Dagestan, Respublika, Russia',
            'Dakar Urban Center, Senegal',
            'Dakar, Senegal',
            'Dakota County, Minnesota, USA',
            'Daku\u1e47u pa\u1e37\u0101ta, Sri Lanka',
            'Dalian Urban Center, China',
            'Dallas County, Texas, USA',
            'Dallas Urban Center, USA',
            'Dallas city, Texas, USA',
            'Dallas-Fort Worth-Arlington MSA, TX, USA',
            'Dallas-Fort Worth-Arlington [Urban Area], TX, USA',
            'Dallas-Ft.Worth TX Media Market, USA',
            'Damascus Urban Center, Syria',
            'Dane County, Wisconsin, USA',
            'Dar es Salaam Urban Center, Tanzania',
            'Dar es Salaam, Tanzania',
            'Dauphin County, Pennsylvania, USA',
            'Davao del Sur, Philippines',
            'Davenport IA-Rock Island-Moline IL Media Market, USA',
            'Davenport [Urban Area], IA-IL, USA',
            'Davenport-Moline-Rock Island MSA, IA-IL, USA',
            'Davidson County, Tennessee, USA',
            'Davis County, Utah, USA',
            'Dayton MSA, OH, USA',
            'Dayton OH Media Market, USA',
            'Dayton Urban Center, USA',
            'Dayton [Urban Area], OH, USA',
            'Daytona Beach-Palm Coast-Port Orange [Urban Area], FL, USA',
            'DeKalb County, Georgia, USA',
            'Delaware County, Pennsylvania, USA',
            'Delaware, USA',
            'Delhi [New Delhi] Urban Center, India',
            'Delhi, India',
            'Delta, Nigeria',
            'Deltona-Daytona Beach-Ormond Beach MSA, FL, USA',
            'Democratic Republic of the Congo',
            'Denmark',
            'Denton County, Texas, USA',
            'Denton-Lewisville [Urban Area], TX, USA',
            'Denver CO Media Market, USA',
            'Denver County, Colorado, USA',
            'Denver Urban Center, USA',
            'Denver city, Colorado, USA',
            'Denver-Aurora [Urban Area], CO, USA',
            'Denver-Aurora-Lakewood MSA, CO, USA',
            'Des Moines Urban Center, USA',
            'Des Moines [Urban Area], IA, USA',
            'Des Moines-Ames IA Media Market, USA',
            'Des Moines-West Des Moines MSA, IA, USA',
            'Detroit MI Media Market, USA',
            'Detroit Urban Center, Canada-USA',
            'Detroit [Urban Area], MI, USA',
            'Detroit city, Michigan, USA',
            'Detroit-Warren-Dearborn MSA, MI, USA',
            'Dhaka Urban Center, Bangladesh',
            'Dhaka, Bangladesh',
            'District of Columbia, District of Columbia, USA',
            'District of Columbia, USA',
            'Distrito Capital de Bogot\u00e1, Colombia',
            'Distrito Federal, Brazil',
            'Division No. 1, Newfoundland and Labrador, Canada',
            'Division No. 11, Alberta, Canada',
            'Division No. 11, Manitoba, Canada',
            'Division No. 11, Saskatchewan, Canada',
            'Division No. 6, Alberta, Canada',
            'Division No. 6, Saskatchewan, Canada',
            'Dnipropetrovska oblast, Ukraine',
            'Dodoma, Tanzania',
            'Dolno\u015bl\u0105skie, Poland',
            'Dominican Republic',
            'Donets\'ka oblast, Ukraine',
            'Dortmund Urban Center, Germany',
            'Dosso, Niger',
            'Dothan AL Media Market, USA',
            'Douala Urban Center, Cameroon',
            'Douglas County, Colorado, USA',
            'Douglas County, Nebraska, USA',
            'DuPage County, Illinois, USA',
            'Dubai Urban Center, United Arab Emirates',
            'Dubayy, United Arab Emirates',
            'Duluth MN-Superior WI Media Market, USA',
            'Duluth MSA, MN-WI, USA',
            'Durban Urban Center, South Africa',
            'Durham County, North Carolina, USA',
            'Durham Regional municipality, Ontario, Canada',
            'Durham [Urban Area], NC, USA',
            'Durham city, North Carolina, USA',
            'Durham-Chapel Hill MSA, NC, USA',
            'Dutchess County, New York, USA',
            'Duval County, Florida, USA',
            'East Baton Rouge Parish, Louisiana, USA',
            'Eastern Cape, South Africa',
            'Eastern Equatoria, South Sudan',
            'Eastern, Ghana',
            'Ebonyi, Nigeria',
            'Ecuador',
            'Edmonton CMA, AB, Canada',
            'Edmonton City, Division No. 11, Alberta, Canada',
            'Edmonton Population Center, AB, Canada',
            'Edmonton Urban Center, Canada',
            'Edo, Nigeria',
            'Egypt',
            'Ekiti, Nigeria',
            'El Paso County, Colorado, USA',
            'El Paso County, Texas, USA',
            'El Paso MSA, TX, USA',
            'El Paso TX (Las Cruces NM) Media Market, USA',
            'El Paso [Urban Area], TX-NM, USA',
            'El Paso city, Texas, USA',
            'El Salvador',
            'Emilia-Romagna, Italy',
            'England, United Kingdom',
            'Enugu, Nigeria',
            'Erie County, New York, USA',
            'Erie County, Pennsylvania, USA',
            'Erie MSA, PA, USA',
            'Erie PA Media Market, USA',
            'Eritrea',
            'Escambia County, Florida, USA',
            'Esp\u00edrito Santo, Brazil',
            'Essex County, Massachusetts, USA',
            'Essex County, New Jersey, USA',
            'Essex County, Ontario, Canada',
            'Est, Rwanda',
            'Ethiopia',
            'Eugene MSA, OR, USA',
            'Eugene OR Media Market, USA',
            'Eugene [Urban Area], OR, USA',
            'Evansville IN Media Market, USA',
            'Evansville MSA, IN-KY, USA',
            'E\u015ffah\u0101n, Iran',
            'FL-01 (2023), USA',
            'FL-02 (2023), USA',
            'FL-03 (2023), USA',
            'FL-04 (2023), USA',
            'FL-05 (2023), USA',
            'FL-06 (2023), USA',
            'FL-07 (2023), USA',
            'FL-08 (2023), USA',
            'FL-09 (2023), USA',
            'FL-10 (2023), USA',
            'FL-11 (2023), USA',
            'FL-12 (2023), USA',
            'FL-13 (2023), USA',
            'FL-14 (2023), USA',
            'FL-15 (2023), USA',
            'FL-16 (2023), USA',
            'FL-17 (2023), USA',
            'FL-18 (2023), USA',
            'FL-19 (2023), USA',
            'FL-20 (2023), USA',
            'FL-21 (2023), USA',
            'FL-22 (2023), USA',
            'FL-23 (2023), USA',
            'FL-24 (2023), USA',
            'FL-25 (2023), USA',
            'FL-26 (2023), USA',
            'FL-27 (2023), USA',
            'FL-28 (2023), USA',
            'Fairfax County, Virginia, USA',
            'Faisalabad Urban Center, Pakistan',
            'Far North, Cameroon',
            'Farg\'ona, Uzbekistan',
            'Fargo-Valley City ND Media Market, USA',
            'Fayette County, Kentucky, USA',
            'Fayetteville MSA, NC, USA',
            'Fayetteville [Urban Area], NC, USA',
            'Fayetteville-Springdale-Rogers MSA, AR-MO, USA',
            'Fayetteville-Springdale-Rogers [Urban Area], AR-MO, USA',
            'Finland',
            'Flint MSA, MI, USA',
            'Flint [Urban Area], MI, USA',
            'Flint-Saginaw-Bay City MI Media Market, USA',
            'Florida, USA',
            'Forsyth County, Georgia, USA',
            'Forsyth County, North Carolina, USA',
            'Fort Bend County, Texas, USA',
            'Fort Collins MSA, CO, USA',
            'Fort Collins [Urban Area], CO, USA',
            'Fort Smith MSA, AR-OK, USA',
            'Fort Wayne MSA, IN, USA',
            'Fort Wayne [Urban Area], IN, USA',
            'Fort Wayne city, Indiana, USA',
            'Fort Worth city, Texas, USA',
            'Fortaleza Urban Center, Brazil',
            'France',
            'Franklin County, Ohio, USA',
            'Fraser Valley Regional district, British Columbia, Canada',
            'Frederick County, Maryland, USA',
            'Free State, South Africa',
            'Fresno County, California, USA',
            'Fresno MSA, CA, USA',
            'Fresno Urban Center, USA',
            'Fresno [Urban Area], CA, USA',
            'Fresno city, California, USA',
            'Fresno-Visalia CA Media Market, USA',
            'Ft. Myers-Naples FL Media Market, USA',
            'Ft. Smith-Fayetteville-Springdale-Rogers AR Media Market, USA',
            'Ft. Wayne IN Media Market, USA',
            'Fujian Sheng, China',
            'Fulton County, Georgia, USA',
            'Fuzhou (Fujian Sheng) Urban Center, China',
            'F\u00e8s-Mekn\u00e8s, Morocco',
            'F\u0101rs, Iran',
            'GA-01 (2023), USA',
            'GA-02 (2023), USA',
            'GA-03 (2023), USA',
            'GA-04 (2025), USA',
            'GA-05 (2025), USA',
            'GA-06 (2025), USA',
            'GA-07 (2025), USA',
            'GA-08 (2023), USA',
            'GA-09 (2025), USA',
            'GA-10 (2025), USA',
            'GA-11 (2025), USA',
            'GA-12 (2023), USA',
            'GA-13 (2025), USA',
            'GA-14 (2025), USA',
            'Gainesville FL Media Market, USA',
            'Gainesville MSA, FL, USA',
            'Gainesville [Urban Area], GA, USA',
            'Galveston County, Texas, USA',
            'Gansu Sheng, China',
            'Gatineau Territory, Quebec, Canada',
            'Gatineau Ville, Gatineau Territory, Quebec, Canada',
            'Gauteng, South Africa',
            'Geita, Tanzania',
            'Genesee County, Michigan, USA',
            'Georgia',
            'Georgia, USA',
            'Germany',
            'Ghana',
            'Gilbert town, Arizona, USA',
            'Gloucester County, New Jersey, USA',
            'Goi\u00e1s, Brazil',
            'Gombe, Nigeria',
            'Grand Est, France',
            'Grand Rapids Urban Center, USA',
            'Grand Rapids [Urban Area], MI, USA',
            'Grand Rapids-Kalamazoo-Battle Creek MI Media Market, USA',
            'Grand Rapids-Wyoming MSA, MI, USA',
            'Greater Accra, Ghana',
            'Greater Bridgeport Planning Region, Connecticut, USA',
            'Greater Vancouver Regional district, British Columbia, Canada',
            'Greece',
            'Greeley MSA, CO, USA',
            'Green Bay MSA, WI, USA',
            'Green Bay-Appleton WI Media Market, USA',
            'Greene County, Missouri, USA',
            'Greensboro [Urban Area], NC, USA',
            'Greensboro city, North Carolina, USA',
            'Greensboro-High Point MSA, NC, USA',
            'Greensboro-High Point-Winston Salem NC Media Market, USA',
            'Greenville County, South Carolina, USA',
            'Greenville [Urban Area], SC, USA',
            'Greenville-Anderson-Mauldin MSA, SC, USA',
            'Greenville-New Bern-Washington NC Media Market, USA',
            'Greenville-Spartanburg SC-Asheville NC-Anderson SC Media Market, USA',
            'Guadalajara Urban Center, Mexico',
            'Guanajuato, Mexico',
            'Guangdong Sheng, China',
            'Guangxi Zhuangzu Zizhiqu, China',
            'Guangzhou Urban Center, China',
            'Guatemala',
            'Guatemala City Urban Center, Guatemala',
            'Guatemala, Guatemala',
            'Guayaquil Urban Center, Ecuador',
            'Guayas, Ecuador',
            'Guerrero, Mexico',
            'Guilford County, North Carolina, USA',
            'Guinea',
            'Guiyang (Guizhou Sheng) Urban Center, China',
            'Guizhou Sheng, China',
            'Gujar\u0101t, India',
            'Gujranwala Urban Center, Pakistan',
            'Gulfport-Biloxi-Pascagoula MSA, MS, USA',
            'Gwinnett County, Georgia, USA',
            'Gyeonggi-do, South Korea',
            'Gyeongsangbuk-do, South Korea',
            'Gyeongsangnam-do, South Korea',
            'HI-01 (2023), USA',
            'HI-02 (2023), USA',
            'HaMerkaz, Israel',
            'Hagerstown-Martinsburg MSA, MD-WV, USA',
            'Hainan Sheng, China',
            'Haiti',
            'Halifax CMA, NS, Canada',
            'Halifax County, Nova Scotia, Canada',
            'Halifax Population Center, NS, Canada',
            'Halifax Regional Municipality, Halifax County, Nova Scotia, Canada',
            'Halton Regional municipality, Ontario, Canada',
            'Hamgy\u014fng-namdo [Hamkyeongnamto], North Korea',
            'Hamilton CDR, Ontario, Canada',
            'Hamilton CMA, ON, Canada',
            'Hamilton City, Hamilton CDR, Ontario, Canada',
            'Hamilton County, Indiana, USA',
            'Hamilton County, Ohio, USA',
            'Hamilton County, Tennessee, USA',
            'Hamilton Population Center, ON, Canada',
            'Hampden County, Massachusetts, USA',
            'Hangzhou Urban Center, China',
            'Hanoi Urban Center, Vietnam',
            'Harbin Urban Center, China',
            'Harford County, Maryland, USA',
            'Harlingen-Weslaco-Brownsville-Mcallen TX Media Market, USA',
            'Harris County, Texas, USA',
            'Harrisburg [Urban Area], PA, USA',
            'Harrisburg-Carlisle MSA, PA, USA',
            'Harrisburg-Lancaster-Lebanon-York PA Media Market, USA',
            'Harrisonburg VA Media Market, USA',
            'Hartford & New Haven CT Media Market, USA',
            'Hartford Urban Center, USA',
            'Hartford [Urban Area], CT, USA',
            'Hartford-West Hartford-East Hartford MSA, CT, USA',
            'Hary\u0101na, India',
            'Hattiesburg-Laurel MS Media Market, USA',
            'Haut-Katanga, Democratic Republic of the Congo',
            'Haut-Lomami, Democratic Republic of the Congo',
            'Hauts-Bassins, Burkina Faso',
            'Hauts-de-France, France',
            'Hawaii, USA',
            'Hebei Sheng, China',
            'Hefei Urban Center, China',
            'Heilongjiang Sheng, China',
            'Henan Sheng, China',
            'Henderson city, Nevada, USA',
            'Hennepin County, Minnesota, USA',
            'Henrico County, Virginia, USA',
            'Heroica Nogales Urban Center, Mexico-USA',
            'Her\u0101t, Afghanistan',
            'Hessen, Germany',
            'Hickory-Lenoir-Morganton MSA, NC, USA',
            'Hidalgo County, Texas, USA',
            'Hidalgo, Mexico',
            'Hillsborough County, Florida, USA',
            'Hillsborough County, New Hampshire, USA',
            'Him\u0101chal Pradesh, India',
            'Hirosima, Japan',
            'Ho Chi Minh City Urban Center, Vietnam',
            'Hokkaid\u00f4, Japan',
            'Honduras',
            'Hong Kong Urban Center, China',
            'Hong Kong, China',
            'Honolulu County, Hawaii, USA',
            'Honolulu HI Media Market, USA',
            'Honolulu Urban Center, USA',
            'Honolulu [Urban Area], HI, USA',
            'Horry County, South Carolina, USA',
            'Houston TX Media Market, USA',
            'Houston Urban Center, USA',
            'Houston [Urban Area], TX, USA',
            'Houston city, Texas, USA',
            'Houston-The Woodlands-Sugar Land MSA, TX, USA',
            'Howard County, Maryland, USA',
            'Hubei Sheng, China',
            'Hudson County, New Jersey, USA',
            'Hukuoka, Japan',
            'Hunan Sheng, China',
            'Hungary',
            'Huntington-Ashland MSA, WV-KY-OH, USA',
            'Huntsville MSA, AL, USA',
            'Huntsville [Urban Area], AL, USA',
            'Huntsville-Decatur (Florence) AL Media Market, USA',
            'Hu\u00edla, Angola',
            'Hwanghae-namdo [Hwanghainamto], North Korea',
            'Hyderabad Urban Center, India',
            'Hyderabad Urban Center, Pakistan',
            'Hy\u00f4go, Japan',
            'H\u00e0 N\u1ed9i, Vietnam',
            'H\u1ed3 Ch\u00ed Minh, Vietnam',
            'IA-01 (2023), USA',
            'IA-02 (2023), USA',
            'IA-03 (2023), USA',
            'IA-04 (2023), USA',
            'ID-01 (2023), USA',
            'ID-02 (2023), USA',
            'IL-01 (2023), USA',
            'IL-02 (2023), USA',
            'IL-03 (2023), USA',
            'IL-04 (2023), USA',
            'IL-05 (2023), USA',
            'IL-06 (2023), USA',
            'IL-07 (2023), USA',
            'IL-08 (2023), USA',
            'IL-09 (2023), USA',
            'IL-10 (2023), USA',
            'IL-11 (2023), USA',
            'IL-12 (2023), USA',
            'IL-13 (2023), USA',
            'IL-14 (2023), USA',
            'IL-15 (2023), USA',
            'IL-16 (2023), USA',
            'IL-17 (2023), USA',
            'IN-01 (2023), USA',
            'IN-02 (2023), USA',
            'IN-03 (2023), USA',
            'IN-04 (2023), USA',
            'IN-05 (2023), USA',
            'IN-06 (2023), USA',
            'IN-07 (2023), USA',
            'IN-08 (2023), USA',
            'IN-09 (2023), USA',
            'Ibadan Urban Center, Nigeria',
            'Ibaraki, Japan',
            'Ibb, Yemen',
            'Idaho Falls-Pocatello ID Media Market, USA',
            'Idaho, USA',
            'Illinois, USA',
            'Iloilo, Philippines',
            'Imo, Nigeria',
            'Incheon-gwangyeoksi, South Korea',
            'India',
            'Indiana, USA',
            'Indianapolis IN Media Market, USA',
            'Indianapolis Urban Center, USA',
            'Indianapolis [Urban Area], IN, USA',
            'Indianapolis city (balance), Indiana, USA',
            'Indianapolis-Carmel-Anderson MSA, IN, USA',
            'Indio Urban Center, USA',
            'Indio-Palm Desert-Palm Springs [Urban Area], CA, USA',
            'Indonesia',
            'Indore Urban Center, India',
            'Ingham County, Michigan, USA',
            'Iowa, USA',
            'Iran',
            'Iraq',
            'Ireland',
            'Irvine city, California, USA',
            'Irving city, Texas, USA',
            'Israel',
            'Istanbul Urban Center, Turkey',
            'Italy',
            'Ituri, Democratic Republic of the Congo',
            'Ivory Coast',
            'Izmir Urban Center, Turkey',
            'Jackson County, Missouri, USA',
            'Jackson MS Media Market, USA',
            'Jackson MSA, MS, USA',
            'Jackson [Urban Area], MS, USA',
            'Jacksonville FL Media Market, USA',
            'Jacksonville MSA, FL, USA',
            'Jacksonville Urban Center, USA',
            'Jacksonville [Urban Area], FL, USA',
            'Jacksonville city, Florida, USA',
            'Jaipur Urban Center, India',
            'Jakarta Raya, Indonesia',
            'Jakarta Urban Center, Indonesia',
            'Jalisco, Mexico',
            'Jamaica',
            'Jambi, Indonesia',
            'Jammu and Kashm\u012br, India',
            'Jan\u016bb D\u0101rf\u016br, Sudan',
            'Japan',
            'Jawa Barat, Indonesia',
            'Jawa Tengah, Indonesia',
            'Jawa Timur, Indonesia',
            'Jeddah Urban Center, Saudi Arabia',
            'Jefferson County, Alabama, USA',
            'Jefferson County, Colorado, USA',
            'Jefferson County, Kentucky, USA',
            'Jefferson County, Texas, USA',
            'Jefferson Parish, Louisiana, USA',
            'Jersey City city, New Jersey, USA',
            'Jh\u0101rkhand, India',
            'Jiangsu Sheng, China',
            'Jiangxi Sheng, China',
            'Jieyang Urban Center, China',
            'Jigawa, Nigeria',
            'Jilin Sheng, China',
            'Jinan Urban Center, China',
            'Johannesburg Urban Center, South Africa',
            'Johnson County, Kansas, USA',
            'Johnstown-Altoona PA Media Market, USA',
            'Johor, Malaysia',
            'Joplin MO-Pittsburg KS Media Market, USA',
            'Jordan',
            'KS-01 (2023), USA',
            'KS-02 (2023), USA',
            'KS-03 (2023), USA',
            'KS-04 (2023), USA',
            'KY-01 (2023), USA',
            'KY-02 (2023), USA',
            'KY-03 (2023), USA',
            'KY-04 (2023), USA',
            'KY-05 (2023), USA',
            'KY-06 (2023), USA',
            'Kabul Urban Center, Afghanistan',
            'Kaduna, Nigeria',
            'Kafr ash Shaykh, Egypt',
            'Kagera, Tanzania',
            'Kalamazoo County, Michigan, USA',
            'Kalamazoo-Portage MSA, MI, USA',
            'Kalimantan Barat, Indonesia',
            'Kalimantan Selatan, Indonesia',
            'Kalimantan Tengah, Indonesia',
            'Kalimantan Timur, Indonesia',
            'Kampala Urban Center, Uganda',
            'Kanagawa, Japan',
            'Kane County, Illinois, USA',
            'Kankan, Guinea',
            'Kano Urban Center, Nigeria',
            'Kano, Nigeria',
            'Kanpur Urban Center, India',
            'Kansas City MO Media Market, USA',
            'Kansas City MSA, MO-KS, USA',
            'Kansas City Urban Center, USA',
            'Kansas City [Urban Area], MO-KS, USA',
            'Kansas City city, Missouri, USA',
            'Kansas, USA',
            'Karachi Urban Center, Pakistan',
            'Karn\u0101taka, India',
            'Kasa\u00ef Central, Democratic Republic of the Congo',
            'Kasa\u00ef Oriental, Democratic Republic of the Congo',
            'Kasa\u00ef, Democratic Republic of the Congo',
            'Kathmandu Urban Center, Nepal',
            'Katsina, Nigeria',
            'Kayes, Mali',
            'Kazakhstan',
            'Kebbi, Nigeria',
            'Kemerovskaya oblast\', Russia',
            'Kennewick-Richland MSA, WA, USA',
            'Kennewick-Richland-Pasco [Urban Area], WA, USA',
            'Kent County, Michigan, USA',
            'Kentucky, USA',
            'Kenya',
            'Kerala, India',
            'Kerm\u0101n, Iran',
            'Kern County, California, USA',
            'Kharkivska oblast, Ukraine',
            'Khartoum Urban Center, Sudan',
            'Khatlon, Tajikistan',
            'Khor\u0101s\u0101n-e Ra\u1e95av\u012b, Iran',
            'Khulna, Bangladesh',
            'Khyber Pakhtunkhwa, Pakistan',
            'Kh\u016bzest\u0101n, Iran',
            'Kigoma, Tanzania',
            'Killeen [Urban Area], TX, USA',
            'Killeen-Temple MSA, TX, USA',
            'Kindia, Guinea',
            'King County, Washington, USA',
            'Kings County, New York, USA',
            'Kingsport-Bristol-Bristol MSA, TN-VA, USA',
            'Kinshasa Urban Center, Democratic Republic of the Congo',
            'Kinshasa, Democratic Republic of the Congo',
            'Kissimmee-St. Cloud [Urban Area], FL, USA',
            'Kitchener - Cambridge - Waterloo CMA, ON, Canada',
            'Kitchener City, Waterloo Regional municipality, Ontario, Canada',
            'Kitchener Population Center, ON, Canada',
            'Kitchener Urban Center, Canada',
            'Kitsap County, Washington, USA',
            'Knox County, Tennessee, USA',
            'Knoxville MSA, TN, USA',
            'Knoxville TN Media Market, USA',
            'Knoxville [Urban Area], TN, USA',
            'Kochi Urban Center, India',
            'Kogi, Nigeria',
            'Kolkata Urban Center, India',
            'Kollam Urban Center, India',
            'Kongo Central, Democratic Republic of the Congo',
            'Koshi, Nepal',
            'Koulikoro, Mali',
            'Kozhikode Urban Center, India',
            'Krasnodyarskiy kray, Russia',
            'Krasnoyarskiy kray, Russia',
            'Krung Thep Maha Nakhon, Thailand',
            'Kuala Lumpur Urban Center, Malaysia',
            'Kumasi Urban Center, Ghana',
            'Kunming Urban Center, China',
            'Kuwait',
            'Kuwait City Urban Center, Kuwait',
            'Kwara, Nigeria',
            'Kwazulu-Natal, South Africa',
            'Kwilu, Democratic Republic of the Congo',
            'Kyiv Urban Center, Ukraine',
            'Kyiv, Ukraine',
            'Kyrgyzstan',
            'K\u0101bul, Afghanistan',
            'LA-01 (2025), USA',
            'LA-02 (2025), USA',
            'LA-03 (2025), USA',
            'LA-04 (2025), USA',
            'LA-05 (2025), USA',
            'LA-06 (2025), USA',
            'La Crosse-Eau Claire WI Media Market, USA',
            'La Paz, Bolivia',
            'Lafayette LA Media Market, USA',
            'Lafayette MSA, LA, USA',
            'Lagos Urban Center, Nigeria',
            'Lagos, Nigeria',
            'Laguna, Philippines',
            'Lahore Urban Center, Pakistan',
            'Lake Charles LA Media Market, USA',
            'Lake County, Florida, USA',
            'Lake County, Illinois, USA',
            'Lake County, Indiana, USA',
            'Lakeland [Urban Area], FL, USA',
            'Lakeland-Winter Haven MSA, FL, USA',
            'Lampung, Indonesia',
            'Lancaster County, Nebraska, USA',
            'Lancaster County, Pennsylvania, USA',
            'Lancaster MSA, PA, USA',
            'Lancaster-Manheim [Urban Area], PA, USA',
            'Lane County, Oregon, USA',
            'Lansing MI Media Market, USA',
            'Lansing [Urban Area], MI, USA',
            'Lansing-East Lansing MSA, MI, USA',
            'Lanzhou Urban Center, China',
            'Laos',
            'Laredo MSA, TX, USA',
            'Laredo TX Media Market, USA',
            'Laredo [Urban Area], TX, USA',
            'Laredo city, Texas, USA',
            'Larimer County, Colorado, USA',
            'Las Vegas NV Media Market, USA',
            'Las Vegas Urban Center, USA',
            'Las Vegas city, Nevada, USA',
            'Las Vegas-Henderson-Paradise MSA, NV, USA',
            'Las Vegas-Henderson-Paradise [Urban Area], NV, USA',
            'Laval Territory, Quebec, Canada',
            'Laval Ville, Laval Territory, Quebec, Canada',
            'Lazio, Italy',
            'Lebanon',
            'Lee County, Florida, USA',
            'Lehigh County, Pennsylvania, USA',
            'Leinster, Ireland',
            'Leon County, Florida, USA',
            'Lexington County, South Carolina, USA',
            'Lexington KY Media Market, USA',
            'Lexington Urban Center, USA',
            'Lexington-Fayette MSA, KY, USA',
            'Lexington-Fayette [Urban Area], KY, USA',
            'Lexington-Fayette urban county, Kentucky, USA',
            'Liaoning Sheng, China',
            'Liberia',
            'Libya',
            'Lilongwe, Malawi',
            'Lima Urban Center, Peru',
            'Limpopo, South Africa',
            'Lincoln & Hastings-Kearney NE Media Market, USA',
            'Lincoln MSA, NE, USA',
            'Lincoln [Urban Area], NE, USA',
            'Lincoln city, Nebraska, USA',
            'Lithuania',
            'Little Rock [Urban Area], AR, USA',
            'Little Rock-North Little Rock-Conway MSA, AR, USA',
            'Little Rock-Pine Bluff AR Media Market, USA',
            'Littoral, Cameroon',
            'Lomami, Democratic Republic of the Congo',
            'Lombardia, Italy',
            'London CMA, ON, Canada',
            'London City, Middlesex County, Ontario, Canada',
            'London Population Center, ON, Canada',
            'London Urban Center, Canada',
            'London Urban Center, United Kingdom',
            'Long Beach city, California, USA',
            'Longueuil Territory, Quebec, Canada',
            'Longueuil Ville, Longueuil Territory, Quebec, Canada',
            'Lorain County, Ohio, USA',
            'Los Angeles CA Media Market, USA',
            'Los Angeles County, California, USA',
            'Los Angeles Urban Center, USA',
            'Los Angeles city, California, USA',
            'Los Angeles-Long Beach-Anaheim MSA, CA, USA',
            'Los Angeles-Long Beach-Anaheim [Urban Area], CA, USA',
            'Loudoun County, Virginia, USA',
            'Louisiana, USA',
            'Louisville KY Media Market, USA',
            'Louisville Urban Center, USA',
            'Louisville/Jefferson County MSA, KY-IN, USA',
            'Louisville/Jefferson County [Urban Area], KY-IN, USA',
            'Louisville/Jefferson County metro government (balance), Kentucky, USA',
            'Lualaba, Democratic Republic of the Congo',
            'Luanda Urban Center, Angola',
            'Luanda, Angola',
            'Lubbock County, Texas, USA',
            'Lubbock MSA, TX, USA',
            'Lubbock TX Media Market, USA',
            'Lubbock [Urban Area], TX, USA',
            'Lubbock city, Texas, USA',
            'Lucas County, Ohio, USA',
            'Lucknow Urban Center, India',
            'Lumbin\u012b, Nepal',
            'Lusaka Urban Center, Zambia',
            'Lusaka, Zambia',
            'Luzerne County, Pennsylvania, USA',
            'Lynchburg MSA, VA, USA',
            'MA-01 (2023), USA',
            'MA-02 (2023), USA',
            'MA-03 (2023), USA',
            'MA-04 (2023), USA',
            'MA-05 (2023), USA',
            'MA-06 (2023), USA',
            'MA-07 (2023), USA',
            'MA-08 (2023), USA',
            'MA-09 (2023), USA',
            'MD-01 (2023), USA',
            'MD-02 (2023), USA',
            'MD-03 (2023), USA',
            'MD-04 (2023), USA',
            'MD-05 (2023), USA',
            'MD-06 (2023), USA',
            'MD-07 (2023), USA',
            'MD-08 (2023), USA',
            'ME-01 (2023), USA',
            'ME-02 (2023), USA',
            'MI-01 (2023), USA',
            'MI-02 (2023), USA',
            'MI-03 (2023), USA',
            'MI-04 (2023), USA',
            'MI-05 (2023), USA',
            'MI-06 (2023), USA',
            'MI-07 (2023), USA',
            'MI-08 (2023), USA',
            'MI-09 (2023), USA',
            'MI-10 (2023), USA',
            'MI-11 (2023), USA',
            'MI-12 (2023), USA',
            'MI-13 (2023), USA',
            'MN-01 (2023), USA',
            'MN-02 (2023), USA',
            'MN-03 (2023), USA',
            'MN-04 (2023), USA',
            'MN-05 (2023), USA',
            'MN-06 (2023), USA',
            'MN-07 (2023), USA',
            'MN-08 (2023), USA',
            'MO-01 (2023), USA',
            'MO-02 (2023), USA',
            'MO-03 (2023), USA',
            'MO-04 (2023), USA',
            'MO-05 (2023), USA',
            'MO-06 (2023), USA',
            'MO-07 (2023), USA',
            'MO-08 (2023), USA',
            'MS-01 (2023), USA',
            'MS-02 (2023), USA',
            'MS-03 (2023), USA',
            'MS-04 (2023), USA',
            'MT-01 (2023), USA',
            'MT-02 (2023), USA',
            'Macomb County, Michigan, USA',
            'Macon GA Media Market, USA',
            'Madagascar',
            'Madhesh, Nepal',
            'Madhya Pradesh, India',
            'Madhyama pa\u1e37\u0101ta, Sri Lanka',
            'Madison County, Alabama, USA',
            'Madison County, Illinois, USA',
            'Madison MSA, WI, USA',
            'Madison Urban Center, USA',
            'Madison WI Media Market, USA',
            'Madison [Urban Area], WI, USA',
            'Madison city, Wisconsin, USA',
            'Madrid Urban Center, Spain',
            'Madrid, Comunidad de, Spain',
            'Magway, Myanmar',
            'Mah\u0101r\u0101shtra, India',
            'Maine, USA',
            'Makkah al Mukarramah, Saudi Arabia',
            'Malawi',
            'Malaysia',
            'Mali',
            'Manatee County, Florida, USA',
            'Manchester-Nashua MSA, NH, USA',
            'Mandalay, Myanmar',
            'Manipur, India',
            'Manitoba, Canada',
            'Maputo (City), Mozambique',
            'Maputo Urban Center, Mozambique',
            'Maradi, Niger',
            'Maranh\u00e3o, Brazil',
            'Maricopa County, Arizona, USA',
            'Marin County, California, USA',
            'Marion County, Florida, USA',
            'Marion County, Indiana, USA',
            'Marion County, Oregon, USA',
            'Maritime (R\u00e9gion), Togo',
            'Markham City, York Regional municipality, Ontario, Canada',
            'Marrakech-Safi, Morocco',
            'Maryland, USA',
            'Mashhad Urban Center, Iran',
            'Massachusetts, USA',
            'Mato Grosso do Sul, Brazil',
            'Mato Grosso, Brazil',
            'Mauritania',
            'Mazowieckie, Poland',
            'Ma\u0142opolskie, Poland',
            'Mbuji-Mayi Urban Center, Democratic Republic of the Congo',
            'McAllen [Urban Area], TX, USA',
            'McAllen-Edinburg-Mission MSA, TX, USA',
            'McHenry County, Illinois, USA',
            'McKinney-Frisco [Urban Area], TX, USA',
            'McLennan County, Texas, USA',
            'Mecklenburg County, North Carolina, USA',
            'Medan Urban Center, Indonesia',
            'Medell\u00edn Urban Center, Colombia',
            'Medford-Klamath Falls OR Media Market, USA',
            'Megh\u0101laya, India',
            'Melbourne Urban Center, Australia',
            'Memphis MSA, TN-MS-AR, USA',
            'Memphis TN Media Market, USA',
            'Memphis Urban Center, USA',
            'Memphis [Urban Area], TN-MS-AR, USA',
            'Memphis city, Tennessee, USA',
            'Merced County, California, USA',
            'Merced MSA, CA, USA',
            'Mercer County, New Jersey, USA',
            'Mesa city, Arizona, USA',
            'Mexicali Urban Center, Mexico-USA',
            'Mexico',
            'Mexico City Urban Center, Mexico',
            'Miami Urban Center, USA',
            'Miami city, Florida, USA',
            'Miami-Dade County, Florida, USA',
            'Miami-Fort Lauderdale [Urban Area], FL, USA',
            'Miami-Fort Lauderdale-West Palm Beach MSA, FL, USA',
            'Miami-Ft. Lauderdale FL Media Market, USA',
            'Michigan, USA',
            'Michoac\u00e1n de Ocampo, Mexico',
            'Middlesex County, Massachusetts, USA',
            'Middlesex County, New Jersey, USA',
            'Middlesex County, Ontario, Canada',
            'Milan Urban Center, Italy',
            'Milwaukee County, Wisconsin, USA',
            'Milwaukee Urban Center, USA',
            'Milwaukee WI Media Market, USA',
            'Milwaukee [Urban Area], WI, USA',
            'Milwaukee city, Wisconsin, USA',
            'Milwaukee-Waukesha-West Allis MSA, WI, USA',
            'Minas Gerais, Brazil',
            'Minneapolis [Saint Paul] Urban Center, USA',
            'Minneapolis city, Minnesota, USA',
            'Minneapolis-St. Paul MN Media Market, USA',
            'Minneapolis-St. Paul [Urban Area], MN, USA',
            'Minneapolis-St. Paul-Bloomington MSA, MN-WI, USA',
            'Minnesota, USA',
            'Minot-Bismarck-Dickinson ND Media Market, USA',
            'Mission Viejo-Lake Forest-Laguna Niguel [Urban Area], CA, USA',
            'Mississauga City, Peel Regional municipality, Ontario, Canada',
            'Mississippi, USA',
            'Missoula MT Media Market, USA',
            'Missouri, USA',
            'Miyagi, Japan',
            'Mobile AL-Pensacola (Ft. Walton Beach) FL Media Market, USA',
            'Mobile County, Alabama, USA',
            'Mobile MSA, AL, USA',
            'Mobile [Urban Area], AL, USA',
            'Modesto MSA, CA, USA',
            'Modesto Urban Center, USA',
            'Modesto [Urban Area], CA, USA',
            'Mogadishu Urban Center, Somalia',
            'Moldova',
            'Mongolia',
            'Monmouth County, New Jersey, USA',
            'Monroe County, New York, USA',
            'Monroe LA-El Dorado AR Media Market, USA',
            'Montagnes, Ivory Coast',
            'Montana, USA',
            'Monterey County, California, USA',
            'Monterey-Salinas CA Media Market, USA',
            'Monterrey Urban Center, Mexico',
            'Montgomery (Selma) AL Media Market, USA',
            'Montgomery County, Maryland, USA',
            'Montgomery County, Ohio, USA',
            'Montgomery County, Pennsylvania, USA',
            'Montgomery County, Texas, USA',
            'Montgomery MSA, AL, USA',
            'Montgomery [Urban Area], AL, USA',
            'Montreal Urban Center, Canada',
            'Montr\u00e9al CMA, QC, Canada',
            'Montr\u00e9al Population Center, QC, Canada',
            'Montr\u00e9al Territory, Quebec, Canada',
            'Montr\u00e9al Ville, Montr\u00e9al Territory, Quebec, Canada',
            'Mopti, Mali',
            'Morocco',
            'Morogoro, Tanzania',
            'Morris County, New Jersey, USA',
            'Moscow Urban Center, Russia',
            'Moskovskaya oblast\', Russia',
            'Moskva, Russia',
            'Mozambique',
            'Mpumalanga, South Africa',
            'Multan Urban Center, Pakistan',
            'Multnomah County, Oregon, USA',
            'Mumbai Urban Center, India',
            'Municipalidad Metropolitana de Lima, Peru',
            'Mwanza, Tanzania',
            'Myanmar',
            'Mymensingh, Bangladesh',
            'Myrtle Beach-Conway-North Myrtle Beach MSA, SC-NC, USA',
            'Myrtle Beach-Florence SC Media Market, USA',
            'Myrtle Beach-North Myrtle Beach [Urban Area], SC-NC, USA',
            'M\u00e9xico, Mexico',
            'M\u0101zandar\u0101n, Iran',
            'NC-01 (2025), USA',
            'NC-02 (2025), USA',
            'NC-03 (2025), USA',
            'NC-04 (2025), USA',
            'NC-05 (2025), USA',
            'NC-06 (2025), USA',
            'NC-07 (2025), USA',
            'NC-08 (2025), USA',
            'NC-09 (2025), USA',
            'NC-10 (2025), USA',
            'NC-11 (2025), USA',
            'NC-12 (2025), USA',
            'NC-13 (2025), USA',
            'NC-14 (2025), USA',
            'ND-AL (2023), USA',
            'NE-01 (2023), USA',
            'NE-02 (2023), USA',
            'NE-03 (2023), USA',
            'NH-01 (2023), USA',
            'NH-02 (2023), USA',
            'NJ-01 (2023), USA',
            'NJ-02 (2023), USA',
            'NJ-03 (2023), USA',
            'NJ-04 (2023), USA',
            'NJ-05 (2023), USA',
            'NJ-06 (2023), USA',
            'NJ-07 (2023), USA',
            'NJ-08 (2023), USA',
            'NJ-09 (2023), USA',
            'NJ-10 (2023), USA',
            'NJ-11 (2023), USA',
            'NJ-12 (2023), USA',
            'NM-01 (2023), USA',
            'NM-02 (2023), USA',
            'NM-03 (2023), USA',
            'NV-01 (2023), USA',
            'NV-02 (2023), USA',
            'NV-03 (2023), USA',
            'NV-04 (2023), USA',
            'NY-01 (2025), USA',
            'NY-02 (2025), USA',
            'NY-03 (2025), USA',
            'NY-04 (2025), USA',
            'NY-05 (2025), USA',
            'NY-06 (2025), USA',
            'NY-07 (2025), USA',
            'NY-08 (2025), USA',
            'NY-09 (2025), USA',
            'NY-10 (2025), USA',
            'NY-11 (2023), USA',
            'NY-12 (2025), USA',
            'NY-13 (2025), USA',
            'NY-14 (2025), USA',
            'NY-15 (2025), USA',
            'NY-16 (2025), USA',
            'NY-17 (2025), USA',
            'NY-18 (2025), USA',
            'NY-19 (2025), USA',
            'NY-20 (2025), USA',
            'NY-21 (2025), USA',
            'NY-22 (2025), USA',
            'NY-23 (2025), USA',
            'NY-24 (2025), USA',
            'NY-25 (2025), USA',
            'NY-26 (2025), USA',
            'Nagoya Urban Center, Japan',
            'Nagpur Urban Center, India',
            'Nairobi City, Kenya',
            'Nairobi Urban Center, Kenya',
            'Namangan, Uzbekistan',
            'Nampula, Mozambique',
            'Nanchang (Northern Jiangxi Sheng) Urban Center, China',
            'Nanjing (Jiangsu Sheng) Urban Center, China',
            'Nanning Urban Center, China',
            'Naples Urban Center, Italy',
            'Naples-Immokalee-Marco Island MSA, FL, USA',
            'Nasarawa, Nigeria',
            'Nashville TN Media Market, USA',
            'Nashville Urban Center, USA',
            'Nashville-Davidson [Urban Area], TN, USA',
            'Nashville-Davidson metropolitan government (balance), Tennessee, USA',
            'Nashville-Davidson--Murfreesboro--Franklin MSA, TN, USA',
            'Nassau County, New York, USA',
            'National Capital Region, Philippines',
            'Naugatuck Valley Planning Region, Connecticut, USA',
            'Nebraska, USA',
            'Negros Occidental, Philippines',
            'Nei Mongol Zizhiqu, China',
            'Nepal',
            'Netherlands',
            'Nevada, USA',
            'New Brunswick, Canada',
            'New Castle County, Delaware, USA',
            'New Hampshire, USA',
            'New Haven [Urban Area], CT, USA',
            'New Haven-Milford MSA, CT, USA',
            'New Jersey, USA',
            'New Mexico, USA',
            'New Orleans LA Media Market, USA',
            'New Orleans Urban Center, USA',
            'New Orleans [Urban Area], LA, USA',
            'New Orleans city, Louisiana, USA',
            'New Orleans-Metairie MSA, LA, USA',
            'New South Wales, Australia',
            'New Taipei [Taipei] Urban Center, China',
            'New York County, New York, USA',
            'New York NY Media Market, USA',
            'New York Urban Center, USA',
            'New York city, New York, USA',
            'New York, USA',
            'New York-Jersey City-Newark [Urban Area], NY-NJ, USA',
            'New York-Newark-Jersey City MSA, NY-NJ-PA, USA',
            'New Zealand',
            'Newark city, New Jersey, USA',
            'Newfoundland and Labrador, Canada',
            'Newport News Urban Center, USA',
            'Ngh\u1ec7 An, Vietnam',
            'Niagara Regional municipality, Ontario, Canada',
            'Nicaragua',
            'Niedersachsen, Germany',
            'Niger',
            'Niger, Nigeria',
            'Nigeria',
            'Ningbo Urban Center, China',
            'Ningxia Zizhiiqu, China',
            'Nizhegorodskaya oblast\', Russia',
            'Noord-Brabant, Netherlands',
            'Noord-Holland, Netherlands',
            'Nord-Kivu, Democratic Republic of the Congo',
            'Nordrhein-Westfalen, Germany',
            'Norfolk County, Massachusetts, USA',
            'Norfolk-Portsmouth-Newport News VA Media Market, USA',
            'Normandie, France',
            'North Carolina, USA',
            'North Dakota, USA',
            'North Korea',
            'North Las Vegas city, Nevada, USA',
            'North Port-Sarasota-Bradenton MSA, FL, USA',
            'North, Cameroon',
            'North-West, South Africa',
            'Northampton County, Pennsylvania, USA',
            'Norway',
            'Norwich-New London MSA, CT, USA',
            'Nouvelle-Aquitaine, France',
            'Nova Scotia, Canada',
            'Novosibirskaya oblast\', Russia',
            'Nueces County, Texas, USA',
            'Nuevo Laredo Urban Center, Mexico-USA',
            'Nuevo Le\u00f3n, Mexico',
            'Nusa Tenggara Barat, Indonesia',
            'Nusa Tenggara Timur, Indonesia',
            'N\u012bnaw\u00e1, Iraq',
            'OH-01 (2023), USA',
            'OH-02 (2023), USA',
            'OH-03 (2023), USA',
            'OH-04 (2023), USA',
            'OH-05 (2023), USA',
            'OH-06 (2023), USA',
            'OH-07 (2023), USA',
            'OH-08 (2023), USA',
            'OH-09 (2023), USA',
            'OH-10 (2023), USA',
            'OH-11 (2023), USA',
            'OH-12 (2023), USA',
            'OH-13 (2023), USA',
            'OH-14 (2023), USA',
            'OH-15 (2023), USA',
            'OK-01 (2023), USA',
            'OK-02 (2023), USA',
            'OK-03 (2023), USA',
            'OK-04 (2023), USA',
            'OK-05 (2023), USA',
            'OR-01 (2023), USA',
            'OR-02 (2023), USA',
            'OR-03 (2023), USA',
            'OR-04 (2023), USA',
            'OR-05 (2023), USA',
            'OR-06 (2023), USA',
            'Oakland County, Michigan, USA',
            'Oakland city, California, USA',
            'Oaxaca, Mexico',
            'Ocala MSA, FL, USA',
            'Occitanie, France',
            'Ocean County, New Jersey, USA',
            'Odessa-Midland TX Media Market, USA',
            'Odisha, India',
            'Ogden Urban Center, USA',
            'Ogden-Clearfield MSA, UT, USA',
            'Ogden-Layton [Urban Area], UT, USA',
            'Ogun, Nigeria',
            'Ohio, USA',
            'Oklahoma City MSA, OK, USA',
            'Oklahoma City OK Media Market, USA',
            'Oklahoma City Urban Center, USA',
            'Oklahoma City [Urban Area], OK, USA',
            'Oklahoma City city, Oklahoma, USA',
            'Oklahoma County, Oklahoma, USA',
            'Oklahoma, USA',
            'Olympia-Tumwater MSA, WA, USA',
            'Omaha NE Media Market, USA',
            'Omaha Urban Center, USA',
            'Omaha [Urban Area], NE-IA, USA',
            'Omaha city, Nebraska, USA',
            'Omaha-Council Bluffs MSA, NE-IA, USA',
            'Oman',
            'Ondo, Nigeria',
            'Onitsha Urban Center, Nigeria',
            'Onondaga County, New York, USA',
            'Ontario, Canada',
            'Orange County, California, USA',
            'Orange County, Florida, USA',
            'Orange County, New York, USA',
            'Oregon, USA',
            'Orlando Urban Center, USA',
            'Orlando [Urban Area], FL, USA',
            'Orlando city, Florida, USA',
            'Orlando-Daytona Beach-Melbourne FL Media Market, USA',
            'Orlando-Kissimmee-Sanford MSA, FL, USA',
            'Orleans Parish, Louisiana, USA',
            'Orom\u012bya, Ethiopia',
            'Osaka [Kyoto] Urban Center, Japan',
            'Osceola County, Florida, USA',
            'Oshawa CMA, ON, Canada',
            'Oshawa Population Center, ON, Canada',
            'Oshawa Urban Center, Canada',
            'Osun, Nigeria',
            'Ottawa - Gatineau CMA, QC-ON, Canada',
            'Ottawa - Gatineau Population Center, QC-ON, Canada',
            'Ottawa CDR, Ontario, Canada',
            'Ottawa City, Ottawa CDR, Ontario, Canada',
            'Ottawa County, Michigan, USA',
            'Ottawa Urban Center, Canada',
            'Ouest, Haiti',
            'Ouest, Rwanda',
            'Oxnard Urban Center, USA',
            'Oxnard-San Buenaventura (Ventura) [Urban Area], CA, USA',
            'Oxnard-Thousand Oaks-Ventura MSA, CA, USA',
            'Oyo, Nigeria',
            'Ozama, Dominican Republic',
            'PA-01 (2023), USA',
            'PA-02 (2023), USA',
            'PA-03 (2023), USA',
            'PA-04 (2023), USA',
            'PA-05 (2023), USA',
            'PA-06 (2023), USA',
            'PA-07 (2023), USA',
            'PA-08 (2023), USA',
            'PA-09 (2023), USA',
            'PA-10 (2023), USA',
            'PA-11 (2023), USA',
            'PA-12 (2023), USA',
            'PA-13 (2023), USA',
            'PA-14 (2023), USA',
            'PA-15 (2023), USA',
            'PA-16 (2023), USA',
            'PA-17 (2023), USA',
            'Paducah KY-Cape Girardeau MO-Harrisburg IL Media Market, USA',
            'Pakistan',
            'Palm Bay Urban Center, USA',
            'Palm Bay-Melbourne [Urban Area], FL, USA',
            'Palm Bay-Melbourne-Titusville MSA, FL, USA',
            'Palm Beach County, Florida, USA',
            'Palm Springs CA Media Market, USA',
            'Palmdale Urban Center, USA',
            'Palmdale-Lancaster [Urban Area], CA, USA',
            'Pampanga, Philippines',
            'Panama',
            'Panama City FL Media Market, USA',
            'Pangasinan, Philippines',
            'Papua New Guinea',
            'Papua, Indonesia',
            'Paraguay',
            'Paran\u00e1, Brazil',
            'Para\u00edba, Brazil',
            'Paris Urban Center, France',
            'Par\u00e1, Brazil',
            'Pasco County, Florida, USA',
            'Passaic County, New Jersey, USA',
            'Patna Urban Center, India',
            'Pays de la Loire, France',
            'Peel Regional municipality, Ontario, Canada',
            'Pennsylvania, USA',
            'Pensacola [Urban Area], FL-AL, USA',
            'Pensacola-Ferry Pass-Brent MSA, FL, USA',
            'Peoria MSA, IL, USA',
            'Peoria [Urban Area], IL, USA',
            'Peoria-Bloomington IL Media Market, USA',
            'Perak, Malaysia',
            'Permskiy kray, Russia',
            'Pernambuco, Brazil',
            'Peru',
            'Peshawar Urban Center, Pakistan',
            'Philadelphia County, Pennsylvania, USA',
            'Philadelphia PA Media Market, USA',
            'Philadelphia Urban Center, USA',
            'Philadelphia [Urban Area], PA-NJ-DE-MD, USA',
            'Philadelphia city, Pennsylvania, USA',
            'Philadelphia-Camden-Wilmington MSA, PA-NJ-DE-MD, USA',
            'Philippines',
            'Phoenix (Prescott) AZ Media Market, USA',
            'Phoenix Urban Center, USA',
            'Phoenix West-Goodyear-Avondale [Urban Area], AZ, USA',
            'Phoenix city, Arizona, USA',
            'Phoenix-Mesa-Scottsdale MSA, AZ, USA',
            'Phoenix-Mesa-Scottsdale [Urban Area], AZ, USA',
            'Piau\u00ed, Brazil',
            'Pichincha, Ecuador',
            'Piemonte, Italy',
            'Pierce County, Washington, USA',
            'Pima County, Arizona, USA',
            'Pinal County, Arizona, USA',
            'Pinellas County, Florida, USA',
            'Pittsburgh MSA, PA, USA',
            'Pittsburgh PA Media Market, USA',
            'Pittsburgh Urban Center, USA',
            'Pittsburgh [Urban Area], PA, USA',
            'Pittsburgh city, Pennsylvania, USA',
            'Placer County, California, USA',
            'Plano city, Texas, USA',
            'Plateau, Nigeria',
            'Plymouth County, Massachusetts, USA',
            'Poland',
            'Polk County, Florida, USA',
            'Polk County, Iowa, USA',
            'Ponce MSA, PR, USA',
            'Port St. Lucie MSA, FL, USA',
            'Port St. Lucie [Urban Area], FL, USA',
            'Portland (Oregon-Washington) Urban Center, USA',
            'Portland OR Media Market, USA',
            'Portland [Urban Area], OR-WA, USA',
            'Portland city, Oregon, USA',
            'Portland-Auburn ME Media Market, USA',
            'Portland-South Portland MSA, ME, USA',
            'Portland-Vancouver-Hillsboro MSA, OR-WA, USA',
            'Portugal',
            'Poughkeepsie-Newburgh [Urban Area], NY, USA',
            'Prince George\'s County, Maryland, USA',
            'Prince William County, Virginia, USA',
            'Provence-Alpes-C\u00f4te d\'Azur, France',
            'Providence County, Rhode Island, USA',
            'Providence Urban Center, USA',
            'Providence [Urban Area], RI-MA, USA',
            'Providence-Warwick MSA, RI-MA, USA',
            'ProvidenceRi-New Bedford MA Media Market, USA',
            'Provo Urban Center, USA',
            'Provo-Orem MSA, UT, USA',
            'Provo-Orem [Urban Area], UT, USA',
            'Puebla, Mexico',
            'Puerto Rico, USA',
            'Puglia, Italy',
            'Pulaski County, Arkansas, USA',
            'Pune Urban Center, India',
            'Punjab, India',
            'Punjab, Pakistan',
            'Pyongyang Urban Center, North Korea',
            'P\u2019y\u014fngan-bukto [Phyeonganpukto], North Korea',
            'P\u2019y\u014fngan-namdo [Phyeongannamto], North Korea',
            'P\u2019y\u014fngyang [Phyeongyang], North Korea',
            'Qashqadaryo, Uzbekistan',
            'Qatar',
            'Qingdao Urban Center, China',
            'Qinghai Sheng, China',
            'Qin\u0101, Egypt',
            'Quanzhou (Fujian Sheng) Urban Center, China',
            'Quebec Urban Center, Canada',
            'Quebec, Canada',
            'Queens County, New York, USA',
            'Queensland, Australia',
            'Quezon City [Manila] Urban Center, Philippines',
            'QuincyIl-Hannibal MO-Keokuk IA Media Market, USA',
            'Qu\u00e9bec CMA, QC, Canada',
            'Qu\u00e9bec Population Center, QC, Canada',
            'Qu\u00e9bec Territory, Quebec, Canada',
            'Qu\u00e9bec Ville, Qu\u00e9bec Territory, Quebec, Canada',
            'RI-01 (2023), USA',
            'RI-02 (2023), USA',
            'Rabat-Sal\u00e9-K\u00e9nitra, Morocco',
            'Rajshahi, Bangladesh',
            'Raleigh MSA, NC, USA',
            'Raleigh Urban Center, USA',
            'Raleigh [Urban Area], NC, USA',
            'Raleigh city, North Carolina, USA',
            'Raleigh-Durham (Fayetteville) NC Media Market, USA',
            'Ramsey County, Minnesota, USA',
            'Rangpur, Bangladesh',
            'Rapid City SD Media Market, USA',
            'Rawalpindi [Islamabad] Urban Center, Pakistan',
            'Reading MSA, PA, USA',
            'Reading [Urban Area], PA, USA',
            'Recife Urban Center, Brazil',
            'Regi\u00f3n Metropolitana de Santiago, Chile',
            'Reno MSA, NV, USA',
            'Reno NV Media Market, USA',
            'Reno [Urban Area], NV-CA, USA',
            'Reno city, Nevada, USA',
            'Rheinland-Pfalz, Germany',
            'Rhode Island, USA',
            'Riau, Indonesia',
            'Richland County, South Carolina, USA',
            'Richmond County, New York, USA',
            'Richmond MSA, VA, USA',
            'Richmond Urban Center, USA',
            'Richmond [Urban Area], VA, USA',
            'Richmond-Petersburg VA Media Market, USA',
            'Rio Grande do Norte, Brazil',
            'Rio Grande do Sul, Brazil',
            'Rio de Janeiro Urban Center, Brazil',
            'Rio de Janeiro, Brazil',
            'Rivers, Nigeria',
            'Riverside County, California, USA',
            'Riverside city, California, USA',
            'Riverside-San Bernardino [Urban Area], CA, USA',
            'Riverside-San Bernardino-Ontario MSA, CA, USA',
            'Riyadh Urban Center, Saudi Arabia',
            'Rizal, Philippines',
            'Roanoke MSA, VA, USA',
            'Roanoke-Lynchburg VA Media Market, USA',
            'Rochester (New York) Urban Center, USA',
            'Rochester MN-Mason CityIa-Austin MN Media Market, USA',
            'Rochester MSA, NY, USA',
            'Rochester NY Media Market, USA',
            'Rochester [Urban Area], NY, USA',
            'Rockford IL Media Market, USA',
            'Rockford MSA, IL, USA',
            'Rockford [Urban Area], IL, USA',
            'Rockingham County, New Hampshire, USA',
            'Rockland County, New York, USA',
            'Romania',
            'Rostovskaya oblast\', Russia',
            'Round Lake Beach-McHenry-Grayslake [Urban Area], IL-WI, USA',
            'Russia',
            'Rutherford County, Tennessee, USA',
            'Rwanda',
            'R\u00edo Piedras [San Juan] Urban Center, USA',
            'R\u0101jasth\u0101n, India',
            'R\u012bf Dimashq, Syria',
            'SC-01 (2023), USA',
            'SC-02 (2023), USA',
            'SC-03 (2023), USA',
            'SC-04 (2023), USA',
            'SC-05 (2023), USA',
            'SC-06 (2023), USA',
            'SC-07 (2023), USA',
            'SD-AL (2023), USA',
            'Sabah, Malaysia',
            'Sachsen, Germany',
            'Sacramento County, California, USA',
            'Sacramento Urban Center, USA',
            'Sacramento [Urban Area], CA, USA',
            'Sacramento city, California, USA',
            'Sacramento--Roseville--Arden-Arcade MSA, CA, USA',
            'Sacramento-Stockton-Modesto CA Media Market, USA',
            'Sagaing, Myanmar',
            'Saint Petersburg Urban Center, Russia',
            'Saitama, Japan',
            'Salem MSA, OR, USA',
            'Salem [Urban Area], OR, USA',
            'Salinas MSA, CA, USA',
            'Salisbury MD Media Market, USA',
            'Salisbury MSA, MD-DE, USA',
            'Salt Lake City MSA, UT, USA',
            'Salt Lake City UT Media Market, USA',
            'Salt Lake City Urban Center, USA',
            'Salt Lake City [Urban Area], UT, USA',
            'Salt Lake County, Utah, USA',
            'Salvador Urban Center, Brazil',
            'Samarqand, Uzbekistan',
            'Samarskaya oblast\', Russia',
            'Samut Prakan, Thailand',
            'San Antonio TX Media Market, USA',
            'San Antonio Urban Center, USA',
            'San Antonio [Urban Area], TX, USA',
            'San Antonio city, Texas, USA',
            'San Antonio-New Braunfels MSA, TX, USA',
            'San Bernardino County, California, USA',
            'San Diego CA Media Market, USA',
            'San Diego County, California, USA',
            'San Diego [Urban Area], CA, USA',
            'San Diego city, California, USA',
            'San Diego-Carlsbad MSA, CA, USA',
            'San Francisco County, California, USA',
            'San Francisco city, California, USA',
            'San Francisco-Oakland [Urban Area], CA, USA',
            'San Francisco-Oakland-Hayward MSA, CA, USA',
            'San Francisco-Oakland-San Jose CA Media Market, USA',
            'San Joaquin County, California, USA',
            'San Jose Urban Center, USA',
            'San Jose [Urban Area], CA, USA',
            'San Jose city, California, USA',
            'San Jose-Sunnyvale-Santa Clara MSA, CA, USA',
            'San Juan Municipio, Puerto Rico, USA',
            'San Juan [Urban Area], PR, USA',
            'San Juan zona urbana, Puerto Rico, USA',
            'San Juan-Carolina-Caguas MSA, PR, USA',
            'San Luis Obispo County, California, USA',
            'San Luis Obispo-Paso Robles-Arroyo Grande MSA, CA, USA',
            'San Luis Potos\u00ed, Mexico',
            'San Mateo County, California, USA',
            'Sana\'a Urban Center, Yemen',
            'Sankt-Peterburg, Russia',
            'Santa Ana city, California, USA',
            'Santa Barbara County, California, USA',
            'Santa Barbara-Santa Maria-San Luis Obispo CA Media Market, USA',
            'Santa Catarina, Brazil',
            'Santa Clara County, California, USA',
            'Santa Clarita [Urban Area], CA, USA',
            'Santa Cruz County, California, USA',
            'Santa Cruz, Bolivia',
            'Santa Cruz-Watsonville MSA, CA, USA',
            'Santa Fe, Argentina',
            'Santa Maria-Santa Barbara MSA, CA, USA',
            'Santa Rosa MSA, CA, USA',
            'Santa Rosa [Urban Area], CA, USA',
            'Santiago Urban Center, Chile',
            'Santo Domingo Urban Center, Dominican Republic',
            'Sarasota County, Florida, USA',
            'Sarawak, Malaysia',
            'Saskatchewan, Canada',
            'Saskatoon CMA, SK, Canada',
            'Saskatoon City, Division No. 11, Saskatchewan, Canada',
            'Saskatoon Population Center, SK, Canada',
            'Sassandra-Marahou\u00e9, Ivory Coast',
            'Saudi Arabia',
            'Savannah GA Media Market, USA',
            'Savannah MSA, GA, USA',
            'Savannah [Urban Area], GA, USA',
            'Schleswig-Holstein, Germany',
            'Scotland, United Kingdom',
            'Scranton [Urban Area], PA, USA',
            'Scranton--Wilkes-Barre--Hazleton MSA, PA, USA',
            'Seattle Urban Center, USA',
            'Seattle city, Washington, USA',
            'Seattle-Tacoma WA Media Market, USA',
            'Seattle-Tacoma [Urban Area], WA, USA',
            'Seattle-Tacoma-Bellevue MSA, WA, USA',
            'Sedgwick County, Kansas, USA',
            'Selangor, Malaysia',
            'Semarang Urban Center, Indonesia',
            'Seminole County, Florida, USA',
            'Senegal',
            'Seoul Urban Center, South Korea',
            'Seoul-teukbyeolsi, South Korea',
            'Serbia',
            'Shaanxi Sheng, China',
            'Sham\u0101l D\u0101rf\u016br, Sudan',
            'Sham\u0101l Kurduf\u0101n, Sudan',
            'Shan, Myanmar',
            'Shandong Sheng, China',
            'Shanghai Shi, China',
            'Shanghai Urban Center, China',
            'Shanxi Sheng, China',
            'Shelby County, Tennessee, USA',
            'Shenyang Urban Center, China',
            'Sherman TX-Ada OK Media Market, USA',
            'Shijiazhuang Urban Center, China',
            'Shreveport LA Media Market, USA',
            'Shreveport [Urban Area], LA, USA',
            'Shreveport-Bossier City MSA, LA, USA',
            'Sichuan Sheng, China',
            'Sicilia, Italy',
            'Sierra Leone',
            'Sikasso, Mali',
            'Simcoe County, Ontario, Canada',
            'Sinaloa, Mexico',
            'Sindh, Pakistan',
            'Singapore',
            'Singapore Urban Center, Malaysia-Singapore',
            'Sioux City IA Media Market, USA',
            'Sioux Falls (Mitchell) SD Media Market, USA',
            'Sioux Falls MSA, SD, USA',
            'Sizuoka, Japan',
            'Slovakia',
            'Snohomish County, Washington, USA',
            'Sokoto, Nigeria',
            'Solano County, California, USA',
            'Somalia',
            'Somerset County, New Jersey, USA',
            'Sonoma County, California, USA',
            'Sonora, Mexico',
            'Souss-Massa, Morocco',
            'South Africa',
            'South Bend [Urban Area], IN-MI, USA',
            'South Bend-Elkhart IN Media Market, USA',
            'South Bend-Mishawaka MSA, IN-MI, USA',
            'South Carolina, USA',
            'South Central Connecticut Planning Region, Connecticut, USA',
            'South Dakota, USA',
            'South Korea',
            'South Sudan',
            'Southeastern Connecticut Planning Region, Connecticut, USA',
            'Spain',
            'Spartanburg County, South Carolina, USA',
            'Spartanburg MSA, SC, USA',
            'Spokane County, Washington, USA',
            'Spokane Urban Center, USA',
            'Spokane WA Media Market, USA',
            'Spokane [Urban Area], WA, USA',
            'Spokane-Spokane Valley MSA, WA, USA',
            'Springfield (Massachusetts) Urban Center, USA',
            'Springfield MO Media Market, USA',
            'Springfield MSA, MA, USA',
            'Springfield MSA, MO, USA',
            'Springfield [Urban Area], MA-CT, USA',
            'Springfield [Urban Area], MO, USA',
            'Springfield-Holyoke MA Media Market, USA',
            'Sri Lanka',
            'St. Catharines - Niagara CMA, ON, Canada',
            'St. Charles County, Missouri, USA',
            'St. Clair County, Illinois, USA',
            'St. Johns County, Florida, USA',
            'St. Joseph County, Indiana, USA',
            'St. Louis County, Missouri, USA',
            'St. Louis MO Media Market, USA',
            'St. Louis MSA, MO-IL, USA',
            'St. Louis Urban Center, USA',
            'St. Louis [Urban Area], MO-IL, USA',
            'St. Louis city, Missouri, USA',
            'St. Louis city-County, Missouri, USA',
            'St. Lucie County, Florida, USA',
            'St. Paul city, Minnesota, USA',
            'St. Petersburg city, Florida, USA',
            'St. Tammany Parish, Louisiana, USA',
            'Stanislaus County, California, USA',
            'Stark County, Ohio, USA',
            'State of Palestine',
            'Stavropol\'skiy kray, Russia',
            'Stockton Urban Center, USA',
            'Stockton [Urban Area], CA, USA',
            'Stockton city, California, USA',
            'Stockton-Lodi MSA, CA, USA',
            'Sud, Rwanda',
            'Sud-Kivu, Democratic Republic of the Congo',
            'Sudan',
            'Sud\u016br Pashchim, Nepal',
            'Suffolk County, Massachusetts, USA',
            'Suffolk County, New York, USA',
            'Sughd, Tajikistan',
            'Sulawesi Selatan, Indonesia',
            'Sulawesi Tengah, Indonesia',
            'Sulawesi Tenggara, Indonesia',
            'Sulawesi Utara, Indonesia',
            'Sumal\u0113, Ethiopia',
            'Sumatera Barat, Indonesia',
            'Sumatera Selatan, Indonesia',
            'Sumatera Utara, Indonesia',
            'Summit County, Ohio, USA',
            'Surabaya Urban Center, Indonesia',
            'Surat Urban Center, India',
            'Surrey City, Greater Vancouver Regional district, British Columbia, Canada',
            'Surxondaryo, Uzbekistan',
            'Suzhou (Jiangsu Sheng) Urban Center, China',
            'Sverdlovskaya oblast\', Russia',
            'Sweden',
            'Switzerland',
            'Sydney Urban Center, Australia',
            'Sylhet, Bangladesh',
            'Syracuse MSA, NY, USA',
            'Syracuse NY Media Market, USA',
            'Syracuse Urban Center, USA',
            'Syracuse [Urban Area], NY, USA',
            'Syria',
            'S\u00e3o Paulo Urban Center, Brazil',
            'S\u00e3o Paulo, Brazil',
            'S\u00e9gou, Mali',
            'S\u012bdama, Ethiopia',
            'S\u012bst\u0101n va Bal\u016bchest\u0101n, Iran',
            'S\u016bh\u0101j, Egypt',
            'TN-01 (2023), USA',
            'TN-02 (2023), USA',
            'TN-03 (2023), USA',
            'TN-04 (2023), USA',
            'TN-05 (2023), USA',
            'TN-06 (2023), USA',
            'TN-07 (2023), USA',
            'TN-08 (2023), USA',
            'TN-09 (2023), USA',
            'TX-01 (2023), USA',
            'TX-02 (2023), USA',
            'TX-03 (2023), USA',
            'TX-04 (2023), USA',
            'TX-05 (2023), USA',
            'TX-06 (2023), USA',
            'TX-07 (2023), USA',
            'TX-08 (2023), USA',
            'TX-09 (2023), USA',
            'TX-10 (2023), USA',
            'TX-11 (2023), USA',
            'TX-12 (2023), USA',
            'TX-13 (2023), USA',
            'TX-14 (2023), USA',
            'TX-15 (2023), USA',
            'TX-16 (2023), USA',
            'TX-17 (2023), USA',
            'TX-18 (2023), USA',
            'TX-19 (2023), USA',
            'TX-20 (2023), USA',
            'TX-21 (2023), USA',
            'TX-22 (2023), USA',
            'TX-23 (2023), USA',
            'TX-24 (2023), USA',
            'TX-25 (2023), USA',
            'TX-26 (2023), USA',
            'TX-27 (2023), USA',
            'TX-28 (2023), USA',
            'TX-29 (2023), USA',
            'TX-30 (2023), USA',
            'TX-31 (2023), USA',
            'TX-32 (2023), USA',
            'TX-33 (2023), USA',
            'TX-34 (2023), USA',
            'TX-35 (2023), USA',
            'TX-36 (2023), USA',
            'TX-37 (2023), USA',
            'TX-38 (2023), USA',
            'Tabasco, Mexico',
            'Tabora, Tanzania',
            'Tahoua, Niger',
            'Taiwan, China',
            'Taiyuan Urban Center, China',
            'Tajikistan',
            'Tallahassee FL-Thomasville GA Media Market, USA',
            'Tallahassee MSA, FL, USA',
            'Tallahassee [Urban Area], FL, USA',
            'Tamaulipas, Mexico',
            'Tamil N\u0101du, India',
            'Tampa Urban Center, USA',
            'Tampa city, Florida, USA',
            'Tampa-St. Petersburg [Urban Area], FL, USA',
            'Tampa-St. Petersburg-Clearwater MSA, FL, USA',
            'Tampa-St.Petersburg (Sarasota) FL Media Market, USA',
            'Tanga, Tanzania',
            'Tanganyika, Democratic Republic of the Congo',
            'Tanger-T\u00e9touan-Al Hoce\u00efma, Morocco',
            'Tanzania',
            'Taraba, Nigeria',
            'Tarrant County, Texas, USA',
            'Tashkent Urban Center, Uzbekistan',
            'Tasikmalaya Urban Center, Indonesia',
            'Tatarstan, Respublika, Russia',
            'Tehran Urban Center, Iran',
            'Tehr\u0101n, Iran',
            'Tel Aviv Urban Center, Israel',
            'Telang\u0101na, India',
            'Temecula-Murrieta-Menifee [Urban Area], CA, USA',
            'Tennessee, USA',
            'Terre Haute IN Media Market, USA',
            'Tete, Mozambique',
            'Texas, USA',
            'Thailand',
            'Thanh H\u00f3a, Vietnam',
            'The Gambia',
            'The Woodlands-Conroe [Urban Area], TX, USA',
            'Thiruvananthapuram Urban Center, India',
            'Thurston County, Washington, USA',
            'Tianjin Shi, China',
            'Tianjin Urban Center, China',
            'Tiba, Japan',
            'Tigray, Ethiopia',
            'Tijuana Urban Center, Mexico-USA',
            'Tillab\u00e9ri, Niger',
            'Togo',
            'Tokyo Urban Center, Japan',
            'Toledo MSA, OH, USA',
            'Toledo OH Media Market, USA',
            'Toledo [Urban Area], OH-MI, USA',
            'Toledo city, Ohio, USA',
            'Topeka KS Media Market, USA',
            'Toronto CDR, Ontario, Canada',
            'Toronto CMA, ON, Canada',
            'Toronto City, Toronto CDR, Ontario, Canada',
            'Toronto Population Center, ON, Canada',
            'Toronto Urban Center, Canada',
            'Toscana, Italy',
            'Toshkent (Province), Uzbekistan',
            'Traverse City-Cadillac MI Media Market, USA',
            'Travis County, Texas, USA',
            'Trenton MSA, NJ, USA',
            'Trenton [Urban Area], NJ, USA',
            'Tri-Cities TN-VA Media Market, USA',
            'Tripura, India',
            'Tshopo, Democratic Republic of the Congo',
            'Tucson (Sierra Vista) AZ Media Market, USA',
            'Tucson MSA, AZ, USA',
            'Tucson Urban Center, USA',
            'Tucson [Urban Area], AZ, USA',
            'Tucson city, Arizona, USA',
            'Tulare County, California, USA',
            'Tulsa County, Oklahoma, USA',
            'Tulsa MSA, OK, USA',
            'Tulsa OK Media Market, USA',
            'Tulsa Urban Center, USA',
            'Tulsa [Urban Area], OK, USA',
            'Tulsa city, Oklahoma, USA',
            'Tunisia',
            'Turkey',
            'Turkmenistan',
            'Tuscaloosa MSA, AL, USA',
            'Tyler-Longview (Lufkin & Nacogdoches) TX Media Market, USA',
            'T\u00f4ky\u00f4, Japan',
            'T\u0101\u2018izz, Yemen',
            'USA',
            'UT-01 (2023), USA',
            'UT-02 (2023), USA',
            'UT-03 (2023), USA',
            'UT-04 (2023), USA',
            'Uganda',
            'Ukraine',
            'Union County, New Jersey, USA',
            'United Arab Emirates',
            'United Kingdom',
            'Urban Honolulu CDP, Hawaii, USA',
            'Urban Honolulu MSA, HI, USA',
            'Uruguay',
            'Utah County, Utah, USA',
            'Utah, USA',
            'Utica NY Media Market, USA',
            'Utica-Rome MSA, NY, USA',
            'Uttar Pradesh, India',
            'Uttar\u0101khand, India',
            'Uzbekistan',
            'VA-01 (2023), USA',
            'VA-02 (2023), USA',
            'VA-03 (2023), USA',
            'VA-04 (2023), USA',
            'VA-05 (2023), USA',
            'VA-06 (2023), USA',
            'VA-07 (2023), USA',
            'VA-08 (2023), USA',
            'VA-09 (2023), USA',
            'VA-10 (2023), USA',
            'VA-11 (2023), USA',
            'VT-AL (2023), USA',
            'Valenciana, Comunidad, Spain',
            'Valle del Cauca, Colombia',
            'Vallejo-Fairfield MSA, CA, USA',
            'Vancouver CMA, BC, Canada',
            'Vancouver City, Greater Vancouver Regional district, British Columbia, Canada',
            'Vancouver Population Center, BC, Canada',
            'Vancouver Urban Center, Canada',
            'Varanasi Urban Center, India',
            'Vaughan City, York Regional municipality, Ontario, Canada',
            'Vayamba pa\u1e37\u0101ta, Sri Lanka',
            'Veneto, Italy',
            'Venezuela',
            'Ventura County, California, USA',
            'Veracruz de Ignacio de la Llave, Mexico',
            'Vermont, USA',
            'Victoria CMA, BC, Canada',
            'Victoria Population Center, BC, Canada',
            'Victoria, Australia',
            'Victorville-Hesperia-Apple Valley [Urban Area], CA, USA',
            'Vietnam',
            'Virginia Beach Urban Center, USA',
            'Virginia Beach city, Virginia, USA',
            'Virginia Beach city-County, Virginia, USA',
            'Virginia Beach-Norfolk [Urban Area], VA, USA',
            'Virginia Beach-Norfolk-Newport News MSA, VA-NC, USA',
            'Virginia, USA',
            'Visalia-Porterville MSA, CA, USA',
            'Vlaamse Gewest, Belgium',
            'Volgogradskaya oblast\', Russia',
            'Volusia County, Florida, USA',
            'WA-01 (2023), USA',
            'WA-02 (2023), USA',
            'WA-03 (2023), USA',
            'WA-04 (2023), USA',
            'WA-05 (2023), USA',
            'WA-06 (2023), USA',
            'WA-07 (2023), USA',
            'WA-08 (2023), USA',
            'WA-09 (2023), USA',
            'WA-10 (2023), USA',
            'WI-01 (2023), USA',
            'WI-02 (2023), USA',
            'WI-03 (2023), USA',
            'WI-04 (2023), USA',
            'WI-05 (2023), USA',
            'WI-06 (2023), USA',
            'WI-07 (2023), USA',
            'WI-08 (2023), USA',
            'WV-01 (2023), USA',
            'WV-02 (2023), USA',
            'WY-AL (2023), USA',
            'Waco MSA, TX, USA',
            'Waco-Temple-Bryan TX Media Market, USA',
            'Wake County, North Carolina, USA',
            'Wakiso, Uganda',
            'Wales, United Kingdom',
            'Washington County, Minnesota, USA',
            'Washington County, Oregon, USA',
            'Washington D.C. Urban Center, USA',
            'Washington DC (Hagerstown MD) Media Market, USA',
            'Washington city, District of Columbia, USA',
            'Washington, USA',
            'Washington-Arlington [Urban Area], DC-VA-MD, USA',
            'Washington-Arlington-Alexandria MSA, DC-VA-MD-WV, USA',
            'Washoe County, Nevada, USA',
            'Washtenaw County, Michigan, USA',
            'Waterloo Regional municipality, Ontario, Canada',
            'Watertown NY Media Market, USA',
            'Waukegan Urban Center, USA',
            'Waukesha County, Wisconsin, USA',
            'Wausau-Rhinelander WI Media Market, USA',
            'Wayne County, Michigan, USA',
            'Webb County, Texas, USA',
            'Weber County, Utah, USA',
            'Weld County, Colorado, USA',
            'Wenzhou Urban Center, China',
            'West Bengal, India',
            'West Palm Beach-Fort Pierce FL Media Market, USA',
            'West Virginia, USA',
            'Westchester County, New York, USA',
            'Western Australia, Australia',
            'Western Cape, South Africa',
            'Western Connecticut Planning Region, Connecticut, USA',
            'Westmoreland County, Pennsylvania, USA',
            'Wheeling WV-Steubenville OH Media Market, USA',
            'Wichita Falls TX & Lawton OK Media Market, USA',
            'Wichita MSA, KS, USA',
            'Wichita Urban Center, USA',
            'Wichita [Urban Area], KS, USA',
            'Wichita city, Kansas, USA',
            'Wichita-Hutchinson KS Media Market, USA',
            'Wielkopolskie, Poland',
            'Wilkes Barre-Scranton PA Media Market, USA',
            'Will County, Illinois, USA',
            'Williamson County, Texas, USA',
            'Wilmington MSA, NC, USA',
            'Wilmington NC Media Market, USA',
            'Wilmington [Urban Area], NC, USA',
            'Windsor CMA, ON, Canada',
            'Windsor Population Center, ON, Canada',
            'Winnebago County, Illinois, USA',
            'Winnipeg CMA, MB, Canada',
            'Winnipeg City, Division No. 11, Manitoba, Canada',
            'Winnipeg Population Center, MB, Canada',
            'Winnipeg Urban Center, Canada',
            'Winston-Salem MSA, NC, USA',
            'Winston-Salem [Urban Area], NC, USA',
            'Winter Haven [Urban Area], FL, USA',
            'Wisconsin, USA',
            'Worcester County, Massachusetts, USA',
            'Worcester MSA, MA-CT, USA',
            'Worcester [Urban Area], MA-CT, USA',
            'Wuhan Urban Center, China',
            'Wyoming, USA',
            'Xi\'an Urban Center, China',
            'Xiamen Urban Center, China',
            'Xinjiang Uygur Zizhiqu, China',
            'Xizang Zizhiqu, China',
            'Yakima County, Washington, USA',
            'Yakima MSA, WA, USA',
            'Yakima-Pasco-Richland-Kennewick WA Media Market, USA',
            'Yangon Urban Center, Myanmar',
            'Yangon, Myanmar',
            'Yaounde Urban Center, Cameroon',
            'YeDebub Bih\u0113roch Bih\u0113reseboch na Hizboch, Ethiopia',
            'Yemen',
            'Yobe, Nigeria',
            'Yogyakarta Urban Center, Indonesia',
            'Yogyakarta, Indonesia',
            'York County, Pennsylvania, USA',
            'York County, South Carolina, USA',
            'York Regional municipality, Ontario, Canada',
            'York-Hanover MSA, PA, USA',
            'Youngstown OH Media Market, USA',
            'Youngstown [Urban Area], OH, USA',
            'Youngstown-Warren-Boardman MSA, OH-PA, USA',
            'Yuma AZ-El Centro CA Media Market, USA',
            'Yunnan Sheng, China',
            'Zambia',
            'Zamb\u00e9zia, Mozambique',
            'Zamfara, Nigeria',
            'Zhejiang Sheng, China',
            'Zhengzhou Urban Center, China',
            'Zimbabwe',
            'Zinder, Niger',
            'Zuid-Holland, Netherlands',
            'Zulia, Venezuela',
            'nohiyahoi tobei jumhur\u00ed, Tajikistan',
            'wallonne, R\u00e9gion, Belgium',
            '\u00cele-de-France, France',
            '\u00d0\u1ed3ng Nai, Vietnam',
            '\u00d4saka, Japan',
            '\u00dcr\u00fcmqi Urban Center, China',
            '\u0100d\u012bs \u0100beba, Ethiopia',
            '\u0100mara, Ethiopia',
            '\u0100z\u0304arb\u0101yj\u0101n-e Gharb\u012b, Iran',
            '\u0100z\u0304arb\u0101yj\u0101n-e Sharq\u012b, Iran',
            '\u0130stanbul, Turkey',
            '\u0130zmir, Turkey',
            '\u015al\u0105skie, Poland',
            '\u015eal\u0101\u1e29 ad D\u012bn, Iraq',
            '\u1e28alab, Syria',
        ],
        allStatNames: [
            '2016 Presidential Election',
            '2016-2020 Swing',
            '2020 Presidential Election',
            'Arthritis %',
            'Binge drinking among adults %',
            'High blood pressure %',
            'Cancer (excluding skin cancer) %',
            'Current asthma %',
            'Coronary heart disease %',
            'Cognitive disability %',
            'COPD %',
            'Smoking %',
            'Diagnosed diabetes %',
            'Disability %',
            'Fair or poor self-rated health status %',
            'Hearing disability %',
            'Independent living disability %',
            'Chronic kidney disease %',
            'No leisure-time physical activity %',
            'Mobility disability %',
            'Obesity %',
            'Self-care disability %',
            'Sleeping less than 7 hours %',
            'Stroke %',
            'Vision disability %',
            'PW Density (r=1km)',
            'PW Density (r=1km) Change (2000-2020)',
            'PW Density (r=1km) Change (2010-2020)',
            'PW Density (r=4km)',
            'Asian %',
            'Born outside US %',
            'Born in us outside state %',
            'Born in state of residence %',
            'Black %',
            'Citizen by Birth %',
            'Citizen by Naturalization %',
            'Non-citizen %',
            'High temperature Above 90\u00b0F %',
            'High temperature Below 40\u00b0F %',
            'Undergrad Business %',
            'Undergrad Humanities %',
            'Undergrad STEM %',
            'Grad %',
            'Graduate degree [25-64] %',
            'High School %',
            'High school diploma [25-64] %',
            'Undergrad %',
            'Bachelor\'s degree [25-64] %',
            'Boomer %',
            'Gen Alpha %',
            'Gen X %',
            'Gen Z %',
            'Millennial %',
            'Silent %',
            'PW Mean Elevation',
            'Electricity heating %',
            'Utility gas heating %',
            'Hispanic %',
            'Racial Homogeneity %',
            'Racial Homogeneity Change (2000-2020) %',
            'Racial Homogeneity Change (2010-2020) %',
            'Mean sunny hours',
            'Household income > C$100k %',
            'Household Income > $100k %',
            'Household income < C$50k %',
            'Household Income < $50k %',
            'Housing Units per Adult',
            'Individual income > C$100k %',
            'Individual Income > $100k %',
            'Individual income < C$50k %',
            'Individual Income < $50k %',
            'Accommodation and food services %',
            'Administrative and support and waste management services %',
            'Agriculture, forestry, fishing and hunting %',
            'Arts, entertainment, and recreation %',
            'Construction %',
            'Educational services %',
            'Finance and insurance %',
            'Health care and social assistance %',
            'Information %',
            'Management of companies and enterprises %',
            'Manufacturing %',
            'Mining, quarrying, and oil and gas extraction %',
            'Other services, except public administration %',
            'Professional, scientific, and technical services %',
            'Public administration %',
            'Real estate and rental and leasing %',
            'Retail trade %',
            'Transportation and warehousing %',
            'Utilities %',
            'Wholesale trade %',
            'Public Insurance %',
            'Uninsured %',
            'Private Insurance %',
            'No internet access %',
            'Only English at Home %',
            'Spanish at Home %',
            'Within 1mi of a grocery store %',
            'LICO-AT %',
            'Divorced %',
            'Mean distance to nearest Active Superfund Site',
            'Mean distance to nearest Airport',
            'Mean distance to nearest Hospital',
            'Mean distance to nearest Public School',
            'Mean high heat index',
            'Mean high temp',
            'Mean high temperature in fall',
            'Mean high temperature in spring',
            'Mean high temperature in summer',
            'Mean high temperature in winter',
            'Architecture and engineering occupations %',
            'Arts, design, entertainment, sports, and media occupations %',
            'Building and grounds cleaning and maintenance occupations %',
            'Business and financial operations occupations %',
            'Community and social service occupations %',
            'Computer and mathematical occupations %',
            'Construction and extraction occupations %',
            'Educational instruction, and library occupations %',
            'Farming, fishing, and forestry occupations %',
            'Firefighting and prevention, and other protective service workers including supervisors %',
            'Food preparation and serving related occupations %',
            'Health diagnosing and treating practitioners and other technical occupations %',
            'Health technologists and technicians %',
            'Healthcare support occupations %',
            'Installation, maintenance, and repair occupations %',
            'Law enforcement workers including supervisors %',
            'Legal occupations %',
            'Life, physical, and social science occupations %',
            'Management occupations %',
            'Material moving occupations %',
            'Office and administrative support occupations %',
            'Personal care and service occupations %',
            'Sales and related occupations %',
            'Transportation occupations %',
            'PW Mean % of parkland within 1km',
            'PW Mean PM2.5 Pollution',
            'Population',
            'Population Change (2000-2020)',
            'Population Change (2010-2020)',
            'Poverty %',
            'Rainfall',
            '1BR Rent > $1500 %',
            '1BR Rent < $750 %',
            '2BR Rent > $1500 %',
            '2BR Rent < $750 %',
            'Rent/Income > 40%',
            'Rent/Income < 20%',
            'Renter %',
            'Snowfall [rain-equivalent]',
            'Cohabiting With Partner (Gay) %',
            'Traffic Fatalities Per Capita Per Year',
            'Pedestrian/Cyclist Fatalities Per Capita Per Year',
            'Commute Time > 60 min %',
            'Commute Time < 15 min %',
            'Commute Bike %',
            'Commute Car %',
            'Commute Transit %',
            'Commute Walk %',
            'Commute Work From Home %',
            'Vacancy %',
            'Households With 1+ Vehicles %',
            'White %',
            'High windspeed (>10mph) days %',
            '% units built pre-1970',
            '% units built in 2010s+',
        ],
        statPaths: [
            '2016 Presidential Election-margin',
            '2016-2020 Swing-margin',
            '2020 Presidential Election-margin',
            'ARTHRITIS_cdc_2',
            'BINGE_cdc_2',
            'BPHIGH_cdc_2',
            'CANCER_cdc_2',
            'CASTHMA_cdc_2',
            'CHD_cdc_2',
            'COGNITION_cdc_2',
            'COPD_cdc_2',
            'CSMOKING_cdc_2',
            'DIABETES_cdc_2',
            'DISABILITY_cdc_2',
            'GHLTH_cdc_2',
            'HEARING_cdc_2',
            'INDEPLIVE_cdc_2',
            'KIDNEY_cdc_2',
            'LPA_cdc_2',
            'MOBILITY_cdc_2',
            'OBESITY_cdc_2',
            'SELFCARE_cdc_2',
            'SLEEP_cdc_2',
            'STROKE_cdc_2',
            'VISION_cdc_2',
            'ad_1',
            'ad_1_change_2000',
            'ad_1_change_2010',
            'ad_4',
            'asian',
            'birthplace_non_us',
            'birthplace_us_not_state',
            'birthplace_us_state',
            'black',
            'citizenship_citizen_by_birth',
            'citizenship_citizen_by_naturalization',
            'citizenship_not_citizen',
            'days_above_90_4',
            'days_below_40_4',
            'education_field_business',
            'education_field_humanities',
            'education_field_stem',
            'education_grad',
            'education_grad_canada',
            'education_high_school',
            'education_high_school_canada',
            'education_ugrad',
            'education_ugrad_canada',
            'generation_boomer',
            'generation_genalpha',
            'generation_genx',
            'generation_genz',
            'generation_millenial',
            'generation_silent',
            'gridded_elevation',
            'heating_electricity',
            'heating_utility_gas',
            'hispanic',
            'homogeneity_250_2020',
            'homogeneity_250_diff_2000',
            'homogeneity_250_diff_2010',
            'hours_sunny_4',
            'household_income_above_100_cad',
            'household_income_over_100k',
            'household_income_under_50cad',
            'household_income_under_50k',
            'housing_per_pop',
            'individual_income_above_100_cad',
            'individual_income_over_100k',
            'individual_income_under_50cad',
            'individual_income_under_50k',
            'industry_accommodation_and_food_services',
            'industry_administrative_and_support_and_waste_management_services',
            'industry_agriculture,_forestry,_fishing_and_hunting',
            'industry_arts,_entertainment,_and_recreation',
            'industry_construction',
            'industry_educational_services',
            'industry_finance_and_insurance',
            'industry_health_care_and_social_assistance',
            'industry_information',
            'industry_management_of_companies_and_enterprises',
            'industry_manufacturing',
            'industry_mining,_quarrying,_and_oil_and_gas_extraction',
            'industry_other_services,_except_public_administration',
            'industry_professional,_scientific,_and_technical_services',
            'industry_public_administration',
            'industry_real_estate_and_rental_and_leasing',
            'industry_retail_trade',
            'industry_transportation_and_warehousing',
            'industry_utilities',
            'industry_wholesale_trade',
            'insurance_coverage_govt',
            'insurance_coverage_none',
            'insurance_coverage_private',
            'internet_no_access',
            'language_english_only',
            'language_spanish',
            'lapop1share_usda_fra_1',
            'lico_at_canada',
            'marriage_divorced',
            'mean_dist_Active Superfund Site_updated',
            'mean_dist_Airport_updated',
            'mean_dist_Hospital_updated',
            'mean_dist_Public School_updated',
            'mean_high_heat_index_4',
            'mean_high_temp_4',
            'mean_high_temp_fall_4',
            'mean_high_temp_spring_4',
            'mean_high_temp_summer_4',
            'mean_high_temp_winter_4',
            'occupation_architecture_and_engineering_occupations',
            'occupation_arts,_design,_entertainment,_sports,_and_media_occupations',
            'occupation_building_and_grounds_cleaning_and_maintenance_occupations',
            'occupation_business_and_financial_operations_occupations',
            'occupation_community_and_social_service_occupations',
            'occupation_computer_and_mathematical_occupations',
            'occupation_construction_and_extraction_occupations',
            'occupation_educational_instruction,_and_library_occupations',
            'occupation_farming,_fishing,_and_forestry_occupations',
            'occupation_firefighting_and_prevention,_and_other_protective_service_workers_including_supervisors',
            'occupation_food_preparation_and_serving_related_occupations',
            'occupation_health_diagnosing_and_treating_practitioners_and_other_technical_occupations',
            'occupation_health_technologists_and_technicians',
            'occupation_healthcare_support_occupations',
            'occupation_installation,_maintenance,_and_repair_occupations',
            'occupation_law_enforcement_workers_including_supervisors',
            'occupation_legal_occupations',
            'occupation_life,_physical,_and_social_science_occupations',
            'occupation_management_occupations',
            'occupation_material_moving_occupations',
            'occupation_office_and_administrative_support_occupations',
            'occupation_personal_care_and_service_occupations',
            'occupation_sales_and_related_occupations',
            'occupation_transportation_occupations',
            'park_percent_1km_v2',
            'pm_25_2018_2022',
            'population',
            'population_change_2000',
            'population_change_2010',
            'poverty_below_line',
            'rainfall_4',
            'rent_1br_over_1500',
            'rent_1br_under_750',
            'rent_2br_over_1500',
            'rent_2br_under_750',
            'rent_burden_over_40',
            'rent_burden_under_20',
            'rent_or_own_rent',
            'snowfall_4',
            'sors_cohabiting_partnered_gay',
            'traffic_fatalities_last_decade_per_capita',
            'traffic_fatalities_ped_last_decade_per_capita',
            'transportation_commute_time_over_60',
            'transportation_commute_time_under_15',
            'transportation_means_bike',
            'transportation_means_car',
            'transportation_means_transit',
            'transportation_means_walk',
            'transportation_means_worked_at_home',
            'vacancy',
            'vehicle_ownership_at_least_1',
            'white',
            'wind_speed_over_10mph_4',
            'year_built_1969_or_earlier',
            'year_built_2010_or_later',
        ],
        statQuestionNames: [
            '!FULL Which voted more for Clinton in the 2016 presidential election?',
            '!FULL Which swung towards Democrats more from 2016 to 2020?',
            '!FULL Which voted more for Biden in the 2020 presidential election?',
            'higher % of adults with arthritis',
            'higher % of adults who binge drink',
            'higher % of adults with high blood pressure',
            'higher % of adults with cancer (excluding skin cancer)',
            'higher % of adults with asthma',
            'higher % of adults with coronary heart disease',
            'higher % of adults with a cognitive disability',
            'higher % of adults with COPD !TOOLTIP (Chronic obstructive pulmonary disease, a lung disease linked to smoking)',
            'higher % of adults who smoke',
            'higher % of adults with diagnosed diabetes',
            'higher % of adults with a disability',
            'higher % of adults with a fair or poor self-rated health status',
            'higher % of adults with a hearing disability',
            'higher % of adults with an independent living disability',
            'higher % of adults with chronic kidney disease',
            'higher % of adults who don\'t exercise (do leisure-time physical activity)',
            'higher % of adults with a mobility disability',
            'higher % of adults with obesity',
            'higher % of adults with a self-care disability',
            'higher % of adults who sleep less than 7 hours',
            'higher % of adults who have had a stroke',
            'higher % of adults with a vision disability',
            'higher population-weighted density (r=1km)!TOOLTIP Population-weighted density is computed by computing the density within the given radius for each person in the region and then averaging the results. This is a better measure of the density that people actually experience.',
            'higher % increase in population-weighted density (r=1km) from 2000 to 2020!TOOLTIP Population-weighted density is computed by computing the density within the given radius for each person in the region and then averaging the results. This is a better measure of the density that people actually experience.',
            'higher % increase in population-weighted density (r=1km) from 2010 to 2020!TOOLTIP Population-weighted density is computed by computing the density within the given radius for each person in the region and then averaging the results. This is a better measure of the density that people actually experience.',
            'higher population-weighted density (r=4km)!TOOLTIP Population-weighted density is computed by computing the density within the given radius for each person in the region and then averaging the results. This is a better measure of the density that people actually experience.',
            'higher % of people who are Asian',
            'higher % of people who were born outside the US',
            'higher % of people who were born in the US and outside their state of residence',
            'higher % of people who were born in their state of residence',
            'higher % of people who are Black',
            'higher % of residents who are citizens by birth',
            'higher % of residents who are citizens by naturalization',
            'higher % of residents who are non-citizens',
            'higher % of hot days (days with high temp over 90\u00b0F, population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher % of cold days (days with high temp under 40\u00b0F, population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            '!FULL Which has more people with a business degree, as a percentage of the overall population?',
            '!FULL Which has more people with a humanities degree, as a percentage of the overall population?',
            '!FULL Which has more people with a STEM degree, as a percentage of the overall population?',
            'higher % of people who have a graduate degree',
            'higher % of people between 25 and 64 who have a graduate degree',
            'higher % of people who have at least a high school diploma',
            'higher % of people between 25 and 64 who have at least a high school diploma',
            'higher % of people who have at least an undergrad degree',
            'higher % of people between 25 and 64 who have at least an undergrad degree',
            'higher % of people who are boomers!TOOLTIP born between 1946 and 1966',
            'higher % of people who are gen alpha!TOOLTIP born between 2012 and 2021',
            'higher % of people who are gen x!TOOLTIP born between 1967 and 1981',
            'higher % of people who are gen z!TOOLTIP born between 1997 and 2011',
            'higher % of people who are millennials!TOOLTIP born between 1982 and 1996',
            'higher % of people who are silent generation!TOOLTIP born before 1946',
            'higher population-weighted mean elevation !TOOLTIP Population weighted elevation/hilliness statistics are calculated by computing the statistic for each person in the region and then averaging the results.',
            '!FULL Which has a larger % of homes heated using electricity?',
            '!FULL Which has a larger % of homes heated using utility gas?',
            'higher % of people who are Hispanic',
            'lower racial diversity!TOOLTIP We define racial diversity as the average probability a person selecting a random person in a 250m radius will select someone of a different race',
            '!FULL Which diversified less between 2000 and 2020?!TOOLTIP We define racial diversity as the average probability a person selecting a random person in a 250m radius will select someone of a different race',
            '!FULL Which diversified less between 2010 and 2020?!TOOLTIP We define racial diversity as the average probability a person selecting a random person in a 250m radius will select someone of a different race',
            '!FULL Which has more hours of sun per day on average? (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher % of households that have household income over C$100k',
            'higher % of households who have household income over $100k',
            'higher % of households that have household income under C$50k',
            'higher % of households who have household income under $50k',
            'higher number of housing units per adult',
            'higher % of people who have individual income over C$100k',
            'higher % of people who have individual income over $100k',
            'higher % of people who have individual income under C$50k',
            'higher % of people who have individual income under $50k',
            'higher % of workers employed in the accommodation and food services industry!TOOLTIP hotels, restaurants, bars, caterers, food services contractors, etc.',
            'higher % of workers employed in the administrative/support/waste management services industries!TOOLTIP office administrative services, employment services, business support services, waste management and remediation services, etc.',
            'higher % of workers employed in the agriculture, forestry, fishing, and hunting industries!TOOLTIP agriculture, forestry, logging, fishing, and hunting',
            'higher % of workers employed in the arts, entertainment, and recreation industry!TOOLTIP performing arts companies, sports, museums, amusement parks, gambling industries, etc.',
            'higher % of workers employed in the construction industry!TOOLTIP construction',
            'higher % of workers employed in the educational services industry!TOOLTIP elementary and secondary schools, colleges, universities,etc.',
            'higher % of workers employed in the finance and insurance industry!TOOLTIP banks, credit unions, insurance carriers, securities and commodity contracts, etc.',
            'higher % of workers employed in the health care and social assistance industry!TOOLTIP hospitals, nursing care facilities, offices of physicians, dentists, day care centers, etc.',
            'higher % of workers employed in the information industry!TOOLTIP publishing industries, software publishers, data processing services, broadcasting, libraries, etc.',
            'higher % of workers employed in the management industry!TOOLTIP management of companies and enterprises',
            'higher % of workers employed in the manufacturing industry!TOOLTIP metal manufacturing, machinery manufacturing, cement and concrete product manufacturing, sawmills, wood product manufacturing, food and beverage manufacturing, textile mills, apparel manufacturing, paper manufacturing, printing, chemical manufacturing, plastics and rubber products manufacturing, etc.',
            'higher % of workers employed in the mining, quarrying, and oil/gas extraction industries!TOOLTIP mining, quarrying, and oil and gas extraction',
            'higher % of workers employed in other service industries, except public administration!TOOLTIP repair and maintenance, personal care services, beauty salons, funeral homes, religious organizations, civic and social organizations, etc.',
            'higher % of workers employed in the professional, scientific, and technical services industry!TOOLTIP legal services, accounting services, architectural services, engineering services, computer systems design services, management consulting services, etc.',
            'higher % of workers employed in public administration!TOOLTIP executive offices, legislative bodies, public finance activities, public order and safety activities, etc.',
            'higher % of workers employed in the real estate and rental and leasing industry!TOOLTIP real estate, rental and leasing, lessors of real estate, etc.',
            'higher % of workers employed in the retail trade industry!TOOLTIP retailers of all kinds of goods, including stores, gas stations, florists, etc.',
            'higher % of workers employed in the transportation and warehousing industry!TOOLTIP transportation by all means, taxis, buses, sightseeing transportation, warehousing, etc.',
            'higher % of workers employed in the utilities industry!TOOLTIP electrical power generation, transmission, and distribution; natural gas distribution; water, sewage, and other systems',
            'higher % of workers employed in the wholesale trade industry!TOOLTIP wholesalers of all kinds of goods',
            'higher % of people who are on public insurance',
            'higher % of people who are uninsured',
            'higher % of people who are on private insurance',
            'higher % of people who have no internet access',
            'higher % of people who only speak english at home',
            'higher % of people who speak spanish at home',
            '!FULL Which has more access to grocery stores (higher % of people within 1mi of a grocery store)?!TOOLTIP The USDA defines a grocery store as a \'supermarket, supercenter, or large grocery store.\'',
            'higher % of people who are low income based on the Low-income cut-offs, after tax (LICO-AT)',
            'higher % of people who are divorced',
            '!FULL Which has less exposure to active EPA superfund sites (higher population-weighted mean distance)?!TOOLTIP EPA superfund sites are hazardous waste sites identified by the Environmental Protection Agency.',
            '!FULL Which has less access to airports (higher population-weighted mean distance)?',
            '!FULL Which has less access to hospitals (higher population-weighted mean distance)?',
            '!FULL Which has less access to public schools (higher population-weighted mean distance)?',
            'higher mean daily high heat index (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher mean daily high temperature (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher mean daily high temperature in fall (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher mean daily high temperature in spring (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher mean daily high temperature in summer (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher mean daily high temperature in winter (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher % of workers employed as architects and engineers!TOOLTIP all kinds of engineers except software engineers, architects, surveyors, drafters, etc.',
            'higher % of workers employed in arts, design, entertainment, sports, and media occupations!TOOLTIP artists, designers, musicians, actors, dancers, athletes, journalists, editors, writers, photographers, etc.',
            'higher % of workers employed in building and grounds cleaning and maintenance occupations!TOOLTIP janitors, maids, groundskeepers (gardeners), pest control workers, etc.',
            'higher % of workers employed in business and financial operations occupations!TOOLTIP sales agents, insurance agents, compliance officers, consultants, fundraisers, accountants, auditors, HR workers, etc.',
            'higher % of workers employed in community and social service occupations!TOOLTIP social workers, therapists, counselors, probation officers, clergy, etc.',
            'higher % of workers employed in computer and mathematical occupations!TOOLTIP software developers, software QA engineers, system administrators, actuaries, operations researchers',
            'higher % of workers employed in construction and extraction occupations!TOOLTIP carpenters, electricians, plumbers, roofers, miners, etc.',
            'higher % of workers employed in educational instruction, and library occupations!TOOLTIP teachers, tutors, professors, librarians, and archivists',
            'higher % of workers employed in farming, fishing, and forestry occupations!TOOLTIP farmers, ranchers, fishers, loggers, forest workers, etc.',
            'higher % of workers employed as firefighting and prevention, and other protective service workers including supervisors!TOOLTIP firefighters, fire inspectors, correctional officers, bailiffs, etc. (effectively, all protective service workers except police officers)',
            'higher % of workers employed as food preparers or servers!TOOLTIP cooks, waiters, bartenders, fast food workers, etc.',
            'higher % of workers employed in health diagnosing and treating practitioners and other technical occupations!TOOLTIP doctors, registered nurses, physical therapists, pharmacists, dietitians, veterinarians, paramedics, etc.',
            'higher % of workers employed as health technologists and technicians!TOOLTIP radiology technicians, lab technicians, hospital machinery technicians, etc.',
            'higher % of workers employed in healthcare support occupations!TOOLTIP nursing assistants, orderlies, home health aides, massage therapists, dental assistants, etc.',
            'higher % of workers employed in installation, maintenance, and repair occupations!TOOLTIP mechanics, HVAC technicians, electricians, plumbers, etc.',
            'higher % of workers employed as law enforcement workers including supervisors!TOOLTIP police officers, detectives, etc.',
            'higher % of workers employed in legal occupations!TOOLTIP lawyers, judges, paralegals, legal assistants, etc.',
            'higher % of workers employed in life, physical, and social science occupations!TOOLTIP biological scientists, chemists, physicists, geologists, food scientists, economists, phychologists, urban planners, sociologists, scientific technicians, etc.',
            'higher % of workers employed as managers!TOOLTIP a variety of roles titled \'managers\'',
            'higher % of workers employed as material movers!TOOLTIP forklift operators, stock clerks, conveyor operators, etc.',
            'higher % of workers employed as office and administrative support workers!TOOLTIP secretaries, receptionists, data entry clerks, office clerks, mail carriers, shipping clerks, etc.',
            'higher % of workers employed in personal care and service occupations!TOOLTIP hairdressers, childcare workers, fitness trainers, funeral service workers, travel guides, animal trainers, etc.',
            'higher % of workers employed in sales and related occupations!TOOLTIP retail salespersons, cashiers, telemarketers, real estate agents, travel agents, travelling salespeople, etc.',
            'higher % of workers employed in transportation occupations!TOOLTIP truck drivers, bus drivers, taxi drivers, pilots, flight attendants, sailors, etc.',
            '!FULL Which has more access to parks (higher % of area within 1km of a park, population weighted)?',
            'higher population-weighted PM2.5 pollution !TOOLTIP Population weighted pollution statistics are calculated by computing the statistic for each person in the region and then averaging the results.',
            'higher population',
            'higher % increase in population from 2000 to 2020',
            'higher % increase in population from 2010 to 2020',
            'higher % of people who are in poverty',
            'higher rainfall (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher % of units with 1br rent over $1500',
            'higher % of units with 1br rent under $750',
            'higher % of units with 2br rent over $1500',
            'higher % of units with 2br rent under $750',
            'higher % of people whose rent is greater than 40% of their income',
            'higher % of people whose rent is less than 20% of their income',
            'higher % of people who are renters',
            'higher snowfall (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher % of people who are gay and cohabiting with a partner/spouse',
            'higher traffic fatalities per capita between 2013 and 2022!TOOLTIP traffic fatalities in the region, divided by the population of the region',
            'higher pedestrian/cyclist fatalities per capita between 2013 and 2022!TOOLTIP pedestrian and cyclist fatalities in the region, divided by the population of the region',
            'higher % of people who have commute time over 60 min',
            'higher % of people who have commute time under 15 min',
            'higher % of people who commute by bike',
            'higher % of people who commute by car',
            'higher % of people who commute by transit',
            'higher % of people who commute by walking',
            'higher % of people who work from home',
            'higher % of units that are vacant!TOOLTIP Vacancy is the % of housing units that were not occupied on April 1, 2020 (census night). This includes vacation homes but does *not* include units that were not occupied due to the pandemic, the census attempted to account for this.',
            'higher % of households with at least 1 vehicle',
            'higher % of people who are White',
            'higher % of days with wind speed over 10mph (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
            'higher % of housing units built before 1970',
            'higher % of housing units built in 2010 or later',
        ],
        questionDistribution: [
            [
                {
                    path: 'quiz_sampling_info/3/q1/0.gz',
                    totalP: 0.13623590767383575,
                },
                {
                    path: 'quiz_sampling_info/3/q1/1.gz',
                    totalP: 0.08928211778402328,
                },
                {
                    path: 'quiz_sampling_info/3/q1/2.gz',
                    totalP: 0.0917254090309143,
                },
                {
                    path: 'quiz_sampling_info/3/q1/3.gz',
                    totalP: 0.09154392033815384,
                },
                {
                    path: 'quiz_sampling_info/3/q1/4.gz',
                    totalP: 0.12622790038585663,
                },
                {
                    path: 'quiz_sampling_info/3/q1/5.gz',
                    totalP: 0.0821574479341507,
                },
                {
                    path: 'quiz_sampling_info/3/q1/6.gz',
                    totalP: 0.1030486673116684,
                },
                {
                    path: 'quiz_sampling_info/3/q1/7.gz',
                    totalP: 0.10827527940273285,
                },
                {
                    path: 'quiz_sampling_info/3/q1/8.gz',
                    totalP: 0.10842063277959824,
                },
                {
                    path: 'quiz_sampling_info/3/q1/9.gz',
                    totalP: 0.06308268010616302,
                },
            ],
            [
                {
                    path: 'quiz_sampling_info/3/q2/0.gz',
                    totalP: 0.13131484389305115,
                },
                {
                    path: 'quiz_sampling_info/3/q2/1.gz',
                    totalP: 0.09905184060335159,
                },
                {
                    path: 'quiz_sampling_info/3/q2/2.gz',
                    totalP: 0.07480847835540771,
                },
                {
                    path: 'quiz_sampling_info/3/q2/3.gz',
                    totalP: 0.10630888491868973,
                },
                {
                    path: 'quiz_sampling_info/3/q2/4.gz',
                    totalP: 0.1607486605644226,
                },
                {
                    path: 'quiz_sampling_info/3/q2/5.gz',
                    totalP: 0.07513666152954102,
                },
                {
                    path: 'quiz_sampling_info/3/q2/6.gz',
                    totalP: 0.12421596795320511,
                },
                {
                    path: 'quiz_sampling_info/3/q2/7.gz',
                    totalP: 0.09779663383960724,
                },
                {
                    path: 'quiz_sampling_info/3/q2/8.gz',
                    totalP: 0.13061821460723877,
                },
            ],
            [
                {
                    path: 'quiz_sampling_info/3/q3/0.gz',
                    totalP: 0.11234261095523834,
                },
                {
                    path: 'quiz_sampling_info/3/q3/1.gz',
                    totalP: 0.10779332369565964,
                },
                {
                    path: 'quiz_sampling_info/3/q3/2.gz',
                    totalP: 0.0570625476539135,
                },
                {
                    path: 'quiz_sampling_info/3/q3/3.gz',
                    totalP: 0.08818469941616058,
                },
                {
                    path: 'quiz_sampling_info/3/q3/4.gz',
                    totalP: 0.11018142104148865,
                },
                {
                    path: 'quiz_sampling_info/3/q3/5.gz',
                    totalP: 0.12087813019752502,
                },
                {
                    path: 'quiz_sampling_info/3/q3/6.gz',
                    totalP: 0.051383960992097855,
                },
                {
                    path: 'quiz_sampling_info/3/q3/7.gz',
                    totalP: 0.04178374633193016,
                },
                {
                    path: 'quiz_sampling_info/3/q3/8.gz',
                    totalP: 0.09936020523309708,
                },
                {
                    path: 'quiz_sampling_info/3/q3/9.gz',
                    totalP: 0.1149931401014328,
                },
                {
                    path: 'quiz_sampling_info/3/q3/10.gz',
                    totalP: 0.09603607654571533,
                },
            ],
            [
                {
                    path: 'quiz_sampling_info/3/q4/0.gz',
                    totalP: 0.10038938373327255,
                },
                {
                    path: 'quiz_sampling_info/3/q4/1.gz',
                    totalP: 0.1286654770374298,
                },
                {
                    path: 'quiz_sampling_info/3/q4/2.gz',
                    totalP: 0.08157636225223541,
                },
                {
                    path: 'quiz_sampling_info/3/q4/3.gz',
                    totalP: 0.0920821875333786,
                },
                {
                    path: 'quiz_sampling_info/3/q4/4.gz',
                    totalP: 0.12280640006065369,
                },
                {
                    path: 'quiz_sampling_info/3/q4/5.gz',
                    totalP: 0.06713693588972092,
                },
                {
                    path: 'quiz_sampling_info/3/q4/6.gz',
                    totalP: 0.0656910166144371,
                },
                {
                    path: 'quiz_sampling_info/3/q4/7.gz',
                    totalP: 0.06602691113948822,
                },
                {
                    path: 'quiz_sampling_info/3/q4/8.gz',
                    totalP: 0.03093761019408703,
                },
                {
                    path: 'quiz_sampling_info/3/q4/9.gz',
                    totalP: 0.053399670869112015,
                },
                {
                    path: 'quiz_sampling_info/3/q4/10.gz',
                    totalP: 0.07799641042947769,
                },
                {
                    path: 'quiz_sampling_info/3/q4/11.gz',
                    totalP: 0.07585617899894714,
                },
                {
                    path: 'quiz_sampling_info/3/q4/12.gz',
                    totalP: 0.0374356247484684,
                },
            ],
            [
                {
                    path: 'quiz_sampling_info/3/q5/0.gz',
                    totalP: 0.023104967549443245,
                },
                {
                    path: 'quiz_sampling_info/3/q5/1.gz',
                    totalP: 0.02655787579715252,
                },
                {
                    path: 'quiz_sampling_info/3/q5/2.gz',
                    totalP: 0.027206314727663994,
                },
                {
                    path: 'quiz_sampling_info/3/q5/3.gz',
                    totalP: 0.025876862928271294,
                },
                {
                    path: 'quiz_sampling_info/3/q5/4.gz',
                    totalP: 0.02655944600701332,
                },
                {
                    path: 'quiz_sampling_info/3/q5/5.gz',
                    totalP: 0.031802743673324585,
                },
                {
                    path: 'quiz_sampling_info/3/q5/6.gz',
                    totalP: 0.014891847968101501,
                },
                {
                    path: 'quiz_sampling_info/3/q5/7.gz',
                    totalP: 0.0188493262976408,
                },
                {
                    path: 'quiz_sampling_info/3/q5/8.gz',
                    totalP: 0.013388480059802532,
                },
                {
                    path: 'quiz_sampling_info/3/q5/9.gz',
                    totalP: 0.01567312888801098,
                },
                {
                    path: 'quiz_sampling_info/3/q5/10.gz',
                    totalP: 0.021389944478869438,
                },
                {
                    path: 'quiz_sampling_info/3/q5/11.gz',
                    totalP: 0.018431775271892548,
                },
                {
                    path: 'quiz_sampling_info/3/q5/12.gz',
                    totalP: 0.016867179423570633,
                },
                {
                    path: 'quiz_sampling_info/3/q5/13.gz',
                    totalP: 0.028961552307009697,
                },
                {
                    path: 'quiz_sampling_info/3/q5/14.gz',
                    totalP: 0.01211261935532093,
                },
                {
                    path: 'quiz_sampling_info/3/q5/15.gz',
                    totalP: 0.024042589589953423,
                },
                {
                    path: 'quiz_sampling_info/3/q5/16.gz',
                    totalP: 0.028731245547533035,
                },
                {
                    path: 'quiz_sampling_info/3/q5/17.gz',
                    totalP: 0.0261471476405859,
                },
                {
                    path: 'quiz_sampling_info/3/q5/18.gz',
                    totalP: 0.029162811115384102,
                },
                {
                    path: 'quiz_sampling_info/3/q5/19.gz',
                    totalP: 0.03466250002384186,
                },
                {
                    path: 'quiz_sampling_info/3/q5/20.gz',
                    totalP: 0.06188034266233444,
                },
                {
                    path: 'quiz_sampling_info/3/q5/21.gz',
                    totalP: 0.016723867505788803,
                },
                {
                    path: 'quiz_sampling_info/3/q5/22.gz',
                    totalP: 0.01920993998646736,
                },
                {
                    path: 'quiz_sampling_info/3/q5/23.gz',
                    totalP: 0.02311781607568264,
                },
                {
                    path: 'quiz_sampling_info/3/q5/24.gz',
                    totalP: 0.01925158128142357,
                },
                {
                    path: 'quiz_sampling_info/3/q5/25.gz',
                    totalP: 0.015719318762421608,
                },
                {
                    path: 'quiz_sampling_info/3/q5/26.gz',
                    totalP: 0.020009946078062057,
                },
                {
                    path: 'quiz_sampling_info/3/q5/27.gz',
                    totalP: 0.0741887167096138,
                },
                {
                    path: 'quiz_sampling_info/3/q5/28.gz',
                    totalP: 0.0084755914285779,
                },
                {
                    path: 'quiz_sampling_info/3/q5/29.gz',
                    totalP: 0.008115768432617188,
                },
                {
                    path: 'quiz_sampling_info/3/q5/30.gz',
                    totalP: 0.014220046810805798,
                },
                {
                    path: 'quiz_sampling_info/3/q5/31.gz',
                    totalP: 0.03653659671545029,
                },
                {
                    path: 'quiz_sampling_info/3/q5/32.gz',
                    totalP: 0.009454810060560703,
                },
                {
                    path: 'quiz_sampling_info/3/q5/33.gz',
                    totalP: 0.007596228737384081,
                },
                {
                    path: 'quiz_sampling_info/3/q5/34.gz',
                    totalP: 0.007941873744130135,
                },
                {
                    path: 'quiz_sampling_info/3/q5/35.gz',
                    totalP: 0.009005038067698479,
                },
                {
                    path: 'quiz_sampling_info/3/q5/36.gz',
                    totalP: 0.009450814686715603,
                },
                {
                    path: 'quiz_sampling_info/3/q5/37.gz',
                    totalP: 0.03148370981216431,
                },
                {
                    path: 'quiz_sampling_info/3/q5/38.gz',
                    totalP: 0.028162356466054916,
                },
                {
                    path: 'quiz_sampling_info/3/q5/39.gz',
                    totalP: 0.02219657227396965,
                },
                {
                    path: 'quiz_sampling_info/3/q5/40.gz',
                    totalP: 0.022786542773246765,
                },
                {
                    path: 'quiz_sampling_info/3/q5/41.gz',
                    totalP: 0.07005183398723602,
                },
            ],
        ],
        juxtaVersion: 3,
    },
] as const
